/*
This is a canary to detect possible phishing sites being set up to mimic gemini.com.
If someone takes our JS and runs it on a different domain, but doesn't see and remove
this code, it'll ping canarytokens.com from the other site and the Security team will get an alert.

What lies below is the code below, run through https://javascriptobfuscator.herokuapp.com/ with only the
following settings enabled (e.g. Enable Source Map = Off):
 * Rename Globals
 * String Array
 * String Array Threshold: 1
 * String Array Indexes Type: Hexadecimal Number
 * String Array Wrappers Count: 1
 * String Array Wrappers Type: Variable
 * String Array Encoding: None
 * Unicode Escape Sequence
 * Seed: 5
 * Target: Browser No Eval

You can test the obfuscated code pretty easily by just clicking the 'Evaluate' checkbox on the obfuscator site.
That should trigger an alert right away.

function ValidateIPaddress(ipaddress) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
    return (true)
  }
  return (false)
}

function endsWith(haystack, needle) {
  return haystack.substr(-needle.length, needle.length) === needle;
}
if (!endsWith(document.domain, ".aurora7.net") && ValidateIPaddress(document.domain) !== true
&& !endsWith(document.domain, ".gemini.com") && !endsWith(document.domain, ".gemini.com.") && !endsWith(document.domain, ".projecticeland.net") && !endsWith(document.domain, ".gem.link")
&& document.domain !== "translate.googleusercontent.com" && !endsWith(document.domain, ".translate.goog") && document.domain !== "localhost"
&& document.domain !== "tr.snapchat.com" && document.domain !== "a4793faec64193924.awsglobalaccelerator.com" && document.domain !== "qa1-exchange.service.aws-qa" && document.domain !== "alldataops.agg.cashedge.com/AOT/securedData" && document.domain.indexOf(".ny2.") === -1 && document.domain.indexOf(".ny5.") === -1)  {
    var l = location.href;
    var r = document.referrer;
    var m = new Image();
    m.src = "https://canarytokens.org/"+
            "gfaecph2fnb7uxxbsa59m3jh6.jpg?l="+
            encodeURI(l) + "&amp;r=" + encodeURI(r);
}
 */


// prettier-ignore
var _0x5ecd = [
    '\x74\x65\x73\x74',
    '\x73\x75\x62\x73\x74\x72',
    '\x6c\x65\x6e\x67\x74\x68',
    '\x64\x6f\x6d\x61\x69\x6e',
    '\x2e\x61\x75\x72\x6f\x72\x61\x37\x2e\x6e\x65\x74',
    '\x2e\x67\x65\x6d\x69\x6e\x69\x2e\x63\x6f\x6d',
    '\x2e\x67\x65\x6d\x69\x6e\x69\x2e\x63\x6f\x6d\x2e',
    '\x2e\x70\x72\x6f\x6a\x65\x63\x74\x69\x63\x65\x6c\x61\x6e\x64\x2e\x6e\x65\x74',
    '\x2e\x67\x65\x6d\x2e\x6c\x69\x6e\x6b',
    '\x74\x72\x61\x6e\x73\x6c\x61\x74\x65\x2e\x67\x6f\x6f\x67\x6c\x65\x75\x73\x65\x72\x63\x6f\x6e\x74\x65\x6e\x74\x2e\x63\x6f\x6d',
    '\x2e\x74\x72\x61\x6e\x73\x6c\x61\x74\x65\x2e\x67\x6f\x6f\x67',
    '\x6c\x6f\x63\x61\x6c\x68\x6f\x73\x74',
    '\x74\x72\x2e\x73\x6e\x61\x70\x63\x68\x61\x74\x2e\x63\x6f\x6d',
    '\x61\x34\x37\x39\x33\x66\x61\x65\x63\x36\x34\x31\x39\x33\x39\x32\x34\x2e\x61\x77\x73\x67\x6c\x6f\x62\x61\x6c\x61\x63\x63\x65\x6c\x65\x72\x61\x74\x6f\x72\x2e\x63\x6f\x6d',
    '\x71\x61\x31\x2d\x65\x78\x63\x68\x61\x6e\x67\x65\x2e\x73\x65\x72\x76\x69\x63\x65\x2e\x61\x77\x73\x2d\x71\x61',
    '\x61\x6c\x6c\x64\x61\x74\x61\x6f\x70\x73\x2e\x61\x67\x67\x2e\x63\x61\x73\x68\x65\x64\x67\x65\x2e\x63\x6f\x6d\x2f\x41\x4f\x54\x2f\x73\x65\x63\x75\x72\x65\x64\x44\x61\x74\x61',
    '\x69\x6e\x64\x65\x78\x4f\x66',
    '\x2e\x6e\x79\x32\x2e',
    '\x2e\x6e\x79\x35\x2e',
    '\x68\x72\x65\x66',
    '\x72\x65\x66\x65\x72\x72\x65\x72',
    '\x73\x72\x63',
    '\x68\x74\x74\x70\x73\x3a\x2f\x2f\x63\x61\x6e\x61\x72\x79\x74\x6f\x6b\x65\x6e\x73\x2e\x6f\x72\x67\x2f',
    '\x35\x73\x71\x71\x32\x65\x74\x70\x65\x38\x68\x38\x79\x78\x76\x6b\x71\x32\x73\x6f\x65\x74\x6e\x6b\x38\x2e\x6a\x70\x67\x3f\x6c\x3d',
    '\x26\x61\x6d\x70\x3b\x72\x3d'
];

var _0x39baf1 = _0x1bab;
function _0x412b70(_0x27684e) {
	var _0x59ace5 = _0x1bab;
	if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/[_0x59ace5(412)](_0x27684e))
		return !![];
	return ![];
}
function _0x1bab(_0x2ab38f, _0x5ecdaa) {
	_0x2ab38f = _0x2ab38f - 412;
	var _0x1bab47 = _0x5ecd[_0x2ab38f];
	return _0x1bab47;
}
function _0x74d76b(_0x49443f, _0x421ab1) {
	var _0x4ab1f9 = _0x1bab;
	return _0x49443f[_0x4ab1f9(413)](-_0x421ab1[_0x4ab1f9(414)], _0x421ab1[_0x4ab1f9(414)]) === _0x421ab1;
}
if (!_0x74d76b(document[_0x39baf1(415)], _0x39baf1(416)) && _0x412b70(document[_0x39baf1(415)]) !== !![] && !_0x74d76b(document[_0x39baf1(415)], _0x39baf1(417)) && !_0x74d76b(document[_0x39baf1(415)], _0x39baf1(418)) && !_0x74d76b(document[_0x39baf1(415)], _0x39baf1(419)) && !_0x74d76b(document[_0x39baf1(415)], _0x39baf1(420)) && document[_0x39baf1(415)] !== _0x39baf1(421) && !_0x74d76b(document[_0x39baf1(415)], _0x39baf1(422)) && document[_0x39baf1(415)] !== _0x39baf1(423) && document[_0x39baf1(415)] !== _0x39baf1(424) && document[_0x39baf1(415)] !== _0x39baf1(425) && document[_0x39baf1(415)] !== _0x39baf1(426) && document[_0x39baf1(415)] !== _0x39baf1(427) && document[_0x39baf1(415)][_0x39baf1(428)](_0x39baf1(429)) === -1 && document[_0x39baf1(415)][_0x39baf1(428)](_0x39baf1(430)) === -1) {
	var _0x14bbab = location[_0x39baf1(431)], _0x2df9b7 = document[_0x39baf1(432)], _0x1067a3 = new Image();
	_0x1067a3[_0x39baf1(433)] = _0x39baf1(434) + _0x39baf1(435) + encodeURI(_0x14bbab) + _0x39baf1(436) + encodeURI(_0x2df9b7);
}
