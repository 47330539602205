/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { GrowAsset, GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData, usePageRefresh } from "@gemini-ui/contexts";
import { Button, Flex } from "@gemini-ui/design-system";
import Success from "@gemini-ui/pages/Earn/Deposit/Success";
import { DepositModalView, SourceType, TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import StakingDepositForm from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositForm";
import { DepositFormFields } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositForm/constants";
import { createPaymentMethodOptions } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { StakingDepositReview } from "@gemini-ui/pages/Earn/GrowBuy/screens/Review";
import { ControlledStakingFundingSourcesScreen } from "@gemini-ui/pages/Earn/GrowBuy/screens/StakingFundingSourcesScreen/ControlledStakingFundingSourcesScreen";
import { DepositFormValues, useStakingDepositForm } from "@gemini-ui/pages/Earn/GrowBuy/useStakingDepositForm";
import { getPaymentMethodId } from "@gemini-ui/pages/Earn/GrowBuy/utils";
import { OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { UseAccountBalances } from "@gemini-ui/services/account/balances";
import { isInstantOrderQuote, isInstantOrderQuoteError, isTransferQuote } from "@gemini-ui/services/retail/utils";
import { StakingDepositMethod } from "@gemini-ui/services/staking/constants";
import { useStakingDeposit } from "@gemini-ui/services/staking/stakingDeposit";
import { useSinglePairDetails } from "@gemini-ui/services/trading/getSinglePairDetails";
import { UsePaymentData } from "@gemini-ui/services/transfer/types";
import { useIntl } from "@gemini-ui/utils/intl";

export const StakingDepositController = ({
  asset,
  providerType,
  initialFormValues,
  paymentMethodData,
  balancesData,
}: {
  asset: GrowAsset;
  providerType: GrowProviderType;
  initialFormValues: Partial<DepositFormValues>;
  paymentMethodData: UsePaymentData;
  balancesData: UseAccountBalances;
}) => {
  const { intl } = useIntl();
  const { showAlert } = useAlert();
  const { requestRefresh } = usePageRefresh();

  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  const {
    router: { currentView },
    goBack,
    resetQuote, // TODO: We should NOT be using this quote
    updateDepositStatus,
  } = useGrowBuy();

  const lastTradePrice = useSinglePairDetails(asset.currency + defaultFiat).data?.lastTradePrice;
  const isLoadingPaymentData = paymentMethodData.isLoading || balancesData.loading;

  const { loading: isSubmitting, submitDeposit } = useStakingDeposit();

  const provider = asset.interestProviders.find(p => p.providerType === providerType);
  const fundingSourceOptions = React.useMemo(() => {
    if (isLoadingPaymentData) return [];

    return createPaymentMethodOptions({
      providerType,
      paymentMethodData,
      balances: balancesData.data,
      currency: asset.currency,
      defaultFiat,
      intl,
    });
  }, [providerType, paymentMethodData, defaultFiat, isLoadingPaymentData, balancesData.data, asset.currency, intl]);

  const formController = useStakingDepositForm({
    currency: asset.currency,
    providerType,
    paymentMethodId: getPaymentMethodId(fundingSourceOptions?.[0]?.value),
    ...initialFormValues,
  });
  const { reset } = formController;
  const formValues = formController.getValues();

  const selectedPaymentMethod = formValues?.paymentMethodId
    ? fundingSourceOptions.find(o => getPaymentMethodId(o.value) === formValues.paymentMethodId)
    : null;

  const handleDepositFormSubmit = (data: DepositFormValues) => {
    updateDepositStatus({
      currency: data.currency,
      view: DepositModalView.REVIEW_DEPOSIT,
    });
  };

  const handleExitReviewDeposit = () => {
    goBack();
    resetQuote();
  };

  const handleSubmitDeposit = (quote: OrderQuote | TransferQuote) => {
    let orderType, queryParams;

    if (isInstantOrderQuote(quote)) {
      orderType = StakingDepositMethod.BUY_AND_STAKE;
      queryParams = quote.quote.formData;
    } else if (isTransferQuote(quote)) {
      orderType = StakingDepositMethod.TRANSFER;
      queryParams = {
        providerId: provider?.id,
        currency: quote.amount.currency,
        amount: quote.amount.value,
      };
    }

    submitDeposit(orderType, queryParams)
      .then(res => {
        updateDepositStatus({
          view: DepositModalView.SUCCESS,
          // TODO: Set these values here to satisfy the success screen that consumes them from the mega useGrow hook
          // This should be refactored to not require setting these values this way.
          quote,
          source: isInstantOrderQuote(quote) ? SourceType.BANK_OR_CARD : SourceType.TRADING_BALANCE,
        });
      })
      .catch(err => {
        formController.setError(DepositFormFields.AMOUNT, err);
        handleExitReviewDeposit();
      })
      .finally(() => {
        requestRefresh();
      });
  };

  return (
    <React.Fragment>
      {currentView === DepositModalView.PLACE_DEPOSIT && (
        <StakingDepositForm
          asset={asset}
          formController={formController}
          onSubmit={handleDepositFormSubmit}
          lastTradePrice={lastTradePrice}
          isLoadingFundingSources={isLoadingPaymentData}
          selectedFundingSource={selectedPaymentMethod}
          onFundingSourceClick={() => {
            updateDepositStatus({
              view: DepositModalView.SELECT_PAYMENT_METHOD,
            });
          }}
        />
      )}

      {currentView === DepositModalView.SELECT_PAYMENT_METHOD && (
        <ControlledStakingFundingSourcesScreen
          transactionType={GrowTransactionType.STAKE}
          providerType={providerType}
          name={DepositFormFields.PAYMENT_METHOD_ID}
          control={formController.control}
          onBack={goBack}
          options={fundingSourceOptions}
          paymentMethodData={paymentMethodData}
        />
      )}

      {(currentView === DepositModalView.REVIEW_DEPOSIT || currentView === DepositModalView.REVIEW_BUY_DEPOSIT) && (
        <React.Fragment>
          <Flex mb={2}>
            <Button.Secondary
              size="sm"
              cta={intl.formatMessage({ defaultMessage: "Edit" })}
              onClick={handleExitReviewDeposit}
            />
          </Flex>
          <StakingDepositReview
            provider={provider}
            currency={formValues.currency}
            amount={formValues.amount}
            paymentMethod={selectedPaymentMethod?.value}
            onSubmit={handleSubmitDeposit}
            isLoading={isSubmitting}
            onQuoteError={err => {
              if (isInstantOrderQuoteError(err)) {
                formController.setError(DepositFormFields.AMOUNT, { type: "custom", message: err.data });
              } else {
                showAlert({
                  type: AlertTypes.ERROR,
                  message: err,
                });
              }
            }}
          />
        </React.Fragment>
      )}

      {currentView === DepositModalView.SUCCESS && (
        <Success
          onDone={() => {
            updateDepositStatus({ view: DepositModalView.PLACE_DEPOSIT });
            reset();
          }}
        />
      )}
    </React.Fragment>
  );
};
