import React, { useState } from "react";
import { ToggleText } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/Disclaimer/styles";
import { getComponentCopy } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { useIntl } from "@gemini-ui/utils/intl";

type Props = {
  short: React.ReactNode;
  long?: React.ReactNode;
  readMoreToggleElement?: React.ReactElement;
  isExpanded?: boolean;
};

const ReadMore = ({ short, long, readMoreToggleElement = <ToggleText />, isExpanded = false }: Props) => {
  const { intl } = useIntl();

  const [showFullDisclaimer, setShowFullDisclaimer] = useState<boolean>(isExpanded);

  const copy = getComponentCopy(intl);

  return (
    <React.Fragment>
      {short}
      {showFullDisclaimer && long}
      {!showFullDisclaimer
        ? React.cloneElement(
            readMoreToggleElement,
            {
              onClick: () => setShowFullDisclaimer(true),
            },
            copy.READ_MORE
          )
        : React.cloneElement(
            readMoreToggleElement,
            {
              onClick: () => setShowFullDisclaimer(false),
            },
            copy.READ_LESS
          )}
    </React.Fragment>
  );
};

export default ReadMore;
