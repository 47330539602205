import { grid } from "@hubble/tokens/web";

/**
 * **Media Queries**
 *
 * Naving convention: `[device][breakpoint][Up|Down]`
 *
 * @example
 * ```ts
 * const Container = styled.div`
 *   @media ${mediaQuery.tabletSmUp} {
 *     color: hotpink;
 *   }
 * ';
 * ```
 */
export const mediaQuery = {
  mobileXsDown: `(max-width: ${grid.main.xs.maxWidth})`,
  tabletSmUp: `(min-width: ${grid.main.sm.minWidth})`,
  tabletSmDown: `(max-width: ${grid.main.sm.maxWidth})`,
  tabletMdUp: `(min-width: ${grid.main.md.minWidth})`,
  tabletMdDown: `(max-width: ${grid.main.md.maxWidth})`,
  desktopLgUp: `(min-width: ${grid.main.lg.minWidth})`,
  desktopLgDown: `(max-width: ${grid.main.lg.maxWidth})`,
  desktopXlUp: `(min-width: ${grid.main.xl.minWidth})`,
} as const;
