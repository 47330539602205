/** @jsx jsx */
import React, { useRef } from "react";
import { jsx, useTheme } from "@emotion/react";
import MaskedInput from "react-text-mask";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { Flex, Message, Spacing, Text } from "@gemini-ui/design-system";
import { MaskedStakingInput } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/MaskedInput";
import { ScalableInput } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/styles";
import { sanitizeMaskedInputValue } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useCurrencyDetailsV2 } from "@gemini-ui/pages/RetailTrade/utils";

export interface InputProps {
  value?: string;

  /**
   * The approximate value to show below the validator display.
   */
  approxValue?: string | number;

  /**
   * The currencies to display in the input.
   */
  currency: CurrencyShortNameSupportedCryptos;

  /**
   * Any errors to display
   */
  error?: React.ReactNode;

  onChange?: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const StakingProInput = ({ value, approxValue, currency, error, onBlur, onChange }: InputProps) => {
  const { colorScheme } = useTheme();

  const inputRef = useRef<MaskedInput>();
  const currencyDetails = useCurrencyDetailsV2(currency);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _value = sanitizeMaskedInputValue(e.target.value);
    onChange?.(_value);
  };

  return (
    <React.Fragment>
      <Flex flexDirection="column" alignItems="center" pt={12} pb={12} gap={Spacing.scale[2]}>
        <MaskedStakingInput
          providerType={GrowProviderType.PRIVATE_STAKING}
          ref={inputRef}
          currencyDetails={currencyDetails.currency}
          value={value}
          placeholder="0 VAL"
          onChange={handleOnChange}
          onBlur={onBlur}
          render={(ref, inputProps) => (
            <ScalableInput data-testid={testIds.buyModule.input.inputAmount} ref={ref} {...inputProps} />
          )}
        />
        <Text.Body size="sm" color={colorScheme.content.secondary} bold>
          {approxValue && (
            <Money data-testid={testIds.buyModule.input.conversionAmount} currency={currency} value={approxValue} />
          )}
        </Text.Body>

        {error && <Message data-testid={testIds.buyModule.input.error} error={error} />}
      </Flex>
    </React.Fragment>
  );
};

export default StakingProInput;
