import styled from "@emotion/styled";
import { Flex } from "@gemini-ui/design-system";

export const PaymentMethodItemContainer = styled(Flex)`
  align-items: center;
  white-space: break-spaces;
  width: 100%;

  svg,
  img {
    flex-shrink: 0;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PaymentMethodInfo = styled(Flex)`
  overflow: hidden;
  min-width: 0;
  flex: 1;
`;
