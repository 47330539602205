import React, { useMemo } from "react";
import { IntlShape } from "react-intl";
import { Select } from "@gemini-ui/design-system";
import {
  BuyingFrequency,
  DAYS_ENUM,
  MONTHLY_NUM_MAP,
  RecurringFrequency,
  TWICE_MONTHLY_NUM_MAP,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

const { buySell: buySellTestIds } = testIds;

type RecurringOrderItem = { label: string; value: BuyingFrequency };

export const recurringOrderDropdownItems = (intl: IntlShape): RecurringOrderItem[] => {
  return [
    { value: BuyingFrequency.Once, label: intl.formatMessage({ defaultMessage: "Once" }) },
    {
      value: BuyingFrequency.Daily,
      label: intl.formatMessage({ defaultMessage: "Daily" }),
    },
    { value: BuyingFrequency.Weekly, label: intl.formatMessage({ defaultMessage: "Weekly" }) },
    {
      value: BuyingFrequency.Biweekly,
      label: intl.formatMessage({ defaultMessage: "Twice monthly" }),
    },
    { value: BuyingFrequency.Monthly, label: intl.formatMessage({ defaultMessage: "Monthly" }) },
  ];
};

export const weeklyScheduleItems = (intl: IntlShape) => {
  return [
    { label: intl.formatMessage({ defaultMessage: "Monday" }), value: DAYS_ENUM.Monday },
    { label: intl.formatMessage({ defaultMessage: "Tuesday" }), value: DAYS_ENUM.Tuesday },
    { label: intl.formatMessage({ defaultMessage: "Wednesday" }), value: DAYS_ENUM.Wednesday },
    { label: intl.formatMessage({ defaultMessage: "Thursday" }), value: DAYS_ENUM.Thursday },
    { label: intl.formatMessage({ defaultMessage: "Friday" }), value: DAYS_ENUM.Friday },
    { label: intl.formatMessage({ defaultMessage: "Saturday" }), value: DAYS_ENUM.Saturday },
    { label: intl.formatMessage({ defaultMessage: "Sunday" }), value: DAYS_ENUM.Sunday },
  ];
};

export const twiceMonthlyScheduleItems = (intl: IntlShape) => {
  return [
    { label: intl.formatMessage({ defaultMessage: "1st and 15th" }), value: TWICE_MONTHLY_NUM_MAP.FirstAndFifteenth },
    { label: intl.formatMessage({ defaultMessage: "2nd and 16th" }), value: TWICE_MONTHLY_NUM_MAP.SecondAndSixteenth },
    { label: intl.formatMessage({ defaultMessage: "3rd and 17th" }), value: TWICE_MONTHLY_NUM_MAP.ThirdAndSeventeenth },
    { label: intl.formatMessage({ defaultMessage: "4th and 18th" }), value: TWICE_MONTHLY_NUM_MAP.FourthAndEighteenth },
    { label: intl.formatMessage({ defaultMessage: "5th and 19th" }), value: TWICE_MONTHLY_NUM_MAP.FifthAndNineteenth },
    { label: intl.formatMessage({ defaultMessage: "6th and 20th" }), value: TWICE_MONTHLY_NUM_MAP.SixthAndTwentieth },
    {
      label: intl.formatMessage({ defaultMessage: "7th and 21st" }),
      value: TWICE_MONTHLY_NUM_MAP.SeventhAndTwentyFirst,
    },
    {
      label: intl.formatMessage({ defaultMessage: "8th and 22nd" }),
      value: TWICE_MONTHLY_NUM_MAP.EighthAndTwentySecond,
    },
    { label: intl.formatMessage({ defaultMessage: "9th and 23nd" }), value: TWICE_MONTHLY_NUM_MAP.NinthAndTwentyThird },
    {
      label: intl.formatMessage({ defaultMessage: "10th and 24th" }),
      value: TWICE_MONTHLY_NUM_MAP.TenthAndTwentyFourth,
    },
    {
      label: intl.formatMessage({ defaultMessage: "11th and 25th" }),
      value: TWICE_MONTHLY_NUM_MAP.EleventhAndTwentyFifth,
    },
    {
      label: intl.formatMessage({ defaultMessage: "12th and 26th" }),
      value: TWICE_MONTHLY_NUM_MAP.TwelfthAndTwentySixth,
    },
    {
      label: intl.formatMessage({ defaultMessage: "13th and 27th" }),
      value: TWICE_MONTHLY_NUM_MAP.ThirteenthAndTwentySeventh,
    },
    {
      label: intl.formatMessage({ defaultMessage: "14th and 28th" }),
      value: TWICE_MONTHLY_NUM_MAP.FourteenthAndTwentyEighth,
    },
  ];
};

export const monthlyScheduleItems = (intl: IntlShape) => {
  return [
    { label: intl.formatMessage({ defaultMessage: "1st" }), value: MONTHLY_NUM_MAP.First },
    { label: intl.formatMessage({ defaultMessage: "2nd" }), value: MONTHLY_NUM_MAP.Second },
    { label: intl.formatMessage({ defaultMessage: "3rd" }), value: MONTHLY_NUM_MAP.Third },
    { label: intl.formatMessage({ defaultMessage: "4th" }), value: MONTHLY_NUM_MAP.Fourth },
    { label: intl.formatMessage({ defaultMessage: "5th" }), value: MONTHLY_NUM_MAP.Fifth },
    { label: intl.formatMessage({ defaultMessage: "6th" }), value: MONTHLY_NUM_MAP.Sixth },
    { label: intl.formatMessage({ defaultMessage: "7th" }), value: MONTHLY_NUM_MAP.Seventh },
    { label: intl.formatMessage({ defaultMessage: "8th" }), value: MONTHLY_NUM_MAP.Eighth },
    { label: intl.formatMessage({ defaultMessage: "9th" }), value: MONTHLY_NUM_MAP.Ninth },
    { label: intl.formatMessage({ defaultMessage: "10th" }), value: MONTHLY_NUM_MAP.Tenth },
    { label: intl.formatMessage({ defaultMessage: "11th" }), value: MONTHLY_NUM_MAP.Eleventh },
    { label: intl.formatMessage({ defaultMessage: "12th" }), value: MONTHLY_NUM_MAP.Twelfth },
    { label: intl.formatMessage({ defaultMessage: "13th" }), value: MONTHLY_NUM_MAP.Thirteenth },
    { label: intl.formatMessage({ defaultMessage: "14th" }), value: MONTHLY_NUM_MAP.Fourteenth },
    { label: intl.formatMessage({ defaultMessage: "15th" }), value: MONTHLY_NUM_MAP.Fifteenth },
    { label: intl.formatMessage({ defaultMessage: "16th" }), value: MONTHLY_NUM_MAP.Sixteenth },
    { label: intl.formatMessage({ defaultMessage: "17th" }), value: MONTHLY_NUM_MAP.Seventeenth },
    { label: intl.formatMessage({ defaultMessage: "18th" }), value: MONTHLY_NUM_MAP.Eighteenth },
    { label: intl.formatMessage({ defaultMessage: "19th" }), value: MONTHLY_NUM_MAP.Nineteenth },
    { label: intl.formatMessage({ defaultMessage: "20th" }), value: MONTHLY_NUM_MAP.Twentieth },
    { label: intl.formatMessage({ defaultMessage: "21st" }), value: MONTHLY_NUM_MAP.TwentyFirst },
    { label: intl.formatMessage({ defaultMessage: "22nd" }), value: MONTHLY_NUM_MAP.TwentySecond },
    { label: intl.formatMessage({ defaultMessage: "23rd" }), value: MONTHLY_NUM_MAP.TwentyThird },
    { label: intl.formatMessage({ defaultMessage: "24th" }), value: MONTHLY_NUM_MAP.TwentyFourth },
    { label: intl.formatMessage({ defaultMessage: "25th" }), value: MONTHLY_NUM_MAP.TwentyFifth },
    { label: intl.formatMessage({ defaultMessage: "26th" }), value: MONTHLY_NUM_MAP.TwentySixth },
    { label: intl.formatMessage({ defaultMessage: "27th" }), value: MONTHLY_NUM_MAP.TwentySeventh },
    { label: intl.formatMessage({ defaultMessage: "28th" }), value: MONTHLY_NUM_MAP.TwentyEighth },
  ];
};

export interface RecurringBuyDropdownProps {
  schedule: BuyingFrequency;
  recurringFrequency: RecurringFrequency;
  disabled?: boolean;
  setSchedule: Function;
  setRecurringFrequency: Function;
}

export const RecurringBuyDropdowns = ({
  schedule,
  setSchedule,
  setRecurringFrequency,
  recurringFrequency,
  disabled,
}: RecurringBuyDropdownProps) => {
  const { intl } = useIntl();

  // We need to intercept these options so we can compare these values as strings
  const [modifiedTwiceMonthlyOptions, modifiedMonthlyOptions] = useMemo(
    () => [
      twiceMonthlyScheduleItems(intl).reduce(
        (acc, item) => [...acc, { ...item, value: JSON.stringify(item.value) }],
        []
      ),
      monthlyScheduleItems(intl).reduce((acc, item) => [...acc, { ...item, value: JSON.stringify(item.value) }], []),
    ],
    [intl]
  );

  const handleScheduleChange = (frequency: BuyingFrequency) => {
    if (frequency !== schedule) setSchedule(frequency);
  };

  return (
    <React.Fragment>
      <Select
        disabled={disabled}
        label={intl.formatMessage({
          defaultMessage: `Frequency`,
        })}
        options={recurringOrderDropdownItems(intl)}
        onChange={handleScheduleChange}
        mb={2}
        value={schedule}
        name="schedule"
        data-testid={buySellTestIds.scheduleDropdown}
        size="lg"
      />
      {schedule === BuyingFrequency.Weekly && (
        <Select<DAYS_ENUM>
          disabled={disabled}
          label={intl.formatMessage({ defaultMessage: "When?" })}
          options={weeklyScheduleItems(intl)}
          onChange={item => {
            setRecurringFrequency({ ...recurringFrequency, weekly: item });
          }}
          mb={2}
          value={recurringFrequency.weekly}
          name="dayOfWeek"
          data-testid={buySellTestIds.dayOfWeekDropdown}
          size="lg"
        />
      )}
      {schedule === BuyingFrequency.Biweekly && (
        <Select
          disabled={disabled}
          mb={2}
          label={intl.formatMessage({ defaultMessage: "When?" })}
          options={modifiedTwiceMonthlyOptions}
          onChange={item => {
            setRecurringFrequency({ ...recurringFrequency, twiceMonthly: JSON.parse(String(item)) });
          }}
          // Compare the string value to the stringified options value
          value={JSON.stringify(recurringFrequency.twiceMonthly)}
          name="biweekly-dates"
          data-testid={buySellTestIds.biweeklyDropdown}
          size="lg"
        />
      )}
      {schedule === BuyingFrequency.Monthly && (
        <Select
          disabled={disabled}
          label={intl.formatMessage({ defaultMessage: "When?" })}
          mb={2}
          options={modifiedMonthlyOptions}
          onChange={item => {
            setRecurringFrequency({ ...recurringFrequency, monthly: JSON.parse(String(item)) });
          }}
          // Compare the string value to the stringified options value
          value={JSON.stringify(recurringFrequency.monthly)}
          name="monthly-dates"
          data-testid={buySellTestIds.monthlyDropdown}
          size="lg"
        />
      )}
    </React.Fragment>
  );
};
