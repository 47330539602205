import * as React from "react";
import { FeedbackProps } from "@gemini-ui/design-system/Feedback/constants";
import { ActionIcon, FeedbackContainer, FeedbackContent, StatusIcon } from "@gemini-ui/design-system/Feedback/styles";
import { getActionIcon, getStatusIcon } from "@gemini-ui/design-system/Feedback/utils";
import { BaseTextProps, Text } from "@gemini-ui/design-system/Text";

const LG = "lg";

const FeedbackContext = React.createContext({
  variant: LG,
});

const Feedback = ({
  children,
  status = "info",
  showIcon = true,
  customStatusIcon,
  action,
  handleAction = () => {},
  variant = "lg",
  ...props
}: FeedbackProps) => {
  return (
    <FeedbackContainer status={status} variant={variant} {...props}>
      {showIcon && <StatusIcon variant={variant}>{getStatusIcon(status, customStatusIcon)}</StatusIcon>}
      <FeedbackContent>
        <FeedbackContext.Provider value={{ variant }}>{children}</FeedbackContext.Provider>
      </FeedbackContent>
      {action && (
        <ActionIcon variant={variant} data-testid="feedback-action" onClick={handleAction}>
          {getActionIcon(action)}
        </ActionIcon>
      )}
    </FeedbackContainer>
  );
};

const Title = (props: BaseTextProps) => {
  const { variant } = React.useContext(FeedbackContext);
  return <Text.Body size={variant === "md" ? "xs" : "sm"} bold {...props} />;
};
const Body = (props: BaseTextProps) => {
  const { variant } = React.useContext(FeedbackContext);
  return <Text.Body size={variant === "md" ? "xs" : "sm"} {...props} />;
};

Feedback.Title = Title;
Feedback.Body = Body;

/** @deprecated  please use the SectionMessage component instead */
export default Feedback;
