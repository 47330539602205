import styled from "@emotion/styled";
import { BASE_ZINDEX } from "@gemini-ui/components/Header/styles";
import { Badge, Colors, Spacing } from "@gemini-ui/design-system";

export const PositionedBadge = styled(Badge)`
  border-color: ${Colors.black};
  left: ${p => (p.variant === "pip" ? "24px" : "20px")};
  position: absolute;
  top: ${p => (p.variant === "pip" ? "-4px" : "-6px")};
  z-index: ${BASE_ZINDEX} - 1;
`;

export const AnimationContainer = styled.div`
  height: ${Spacing.scale[3]};
  width: ${Spacing.scale[3]};
`;
