import React from "react";
import Copyright from "@gemini-ui/components/Footers/Copyright";
import { StyledFooter } from "@gemini-ui/pages/register/OnboardingLayout/styles";

const SimpleFooter = () => (
  <StyledFooter>
    <Copyright />
  </StyledFooter>
);

export default SimpleFooter;
