import React, { useCallback, useEffect, useState } from "react";
import { orderBy } from "lodash";
import Media from "react-media";
import Body from "@gemini-ui/components/Table/Body";
import { Props, Sort } from "@gemini-ui/components/Table/constants";
import Head from "@gemini-ui/components/Table/Head";
import { ScrollBody, ScrollContainer, Table as StyledTable } from "@gemini-ui/components/Table/styles";
import Pagination, { usePagination } from "@gemini-ui/pages/settings/Pagination";

const DEFAULT_SORT = { field: null, direction: null };

function Table(props: Props) {
  const { className, children, fixedHeader, isRetailTrade, ["data-testid"]: dataTestId, ...tableProps } = props;

  const [data, setData] = useState(tableProps.data);
  const [sort, setSort] = useState<Sort>(tableProps.sort || DEFAULT_SORT);

  /**
   * Sets "sort" and since "useEffect" has dependency on "sort" it fires up and sorts the data.
   */
  const handleSortClick = useCallback(({ column, direction }) => {
    setSort({ field: column?.label, direction });
  }, []);

  useEffect(() => {
    const sortColumn = tableProps?.columns?.find(column => column?.label === sort?.field);
    const sortDirection = sort?.direction;

    /**
     * When props data gets updated, we want to make sure that we sort it if sort was previously applied.
     * Otherwise we just update the state with new data.
     */
    if (sortColumn && sortDirection && !tableProps.onSort) {
      if (!sortColumn.sortBy || typeof sortColumn.sortBy !== "function") {
        throw new Error(
          `Table: a property "sortBy" must be a function for tables that dont use a custom sort. column: ${sortColumn.label}`
        );
      }

      const sortedData = orderBy(tableProps.data, [d => sortColumn.sortBy(d)], sortDirection);
      setData(sortedData);
    } else {
      setData(tableProps?.data);
    }
  }, [sort.direction, sort.field, tableProps.columns, tableProps.data, tableProps.onSort]);

  const itemsPerPage = tableProps.itemsPerPage || 10;

  const { totalPageCount, currentPage, decrementPage, incrementPage, paginatedData, topOfTableRef } = usePagination({
    items: data,
    itemsPerPage: tableProps.isPaginated ? itemsPerPage : data.length,
  });

  const body = children ? (
    children({ ...tableProps, paginatedData })
  ) : (
    <Body {...tableProps} onRowClick={props.onRowClick} isRetailTrade={isRetailTrade} data={paginatedData} />
  );

  const pagination = tableProps.isPaginated && (
    <Pagination
      currentPage={currentPage}
      totalPageCount={totalPageCount}
      onLeftClick={decrementPage}
      onRightClick={incrementPage}
    />
  );

  if (fixedHeader && !tableProps.collapse) {
    return (
      <React.Fragment>
        <ScrollContainer data-testid={dataTestId} ref={topOfTableRef}>
          <StyledTable className={className}>
            <Head {...tableProps} sort={tableProps.sort || sort} onSort={tableProps.onSort || handleSortClick} />
          </StyledTable>
          <ScrollBody>
            <StyledTable className={className}>{body}</StyledTable>
          </ScrollBody>
        </ScrollContainer>
        {pagination}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <StyledTable className={className} data-testid={dataTestId} ref={topOfTableRef}>
          {!tableProps.collapse && (
            <Head {...tableProps} sort={tableProps.sort || sort} onSort={tableProps.onSort || handleSortClick} />
          )}
          {body}
        </StyledTable>
        {pagination}
      </React.Fragment>
    );
  }
}
/**
 * @deprecated Please use the Hubble Table instead.
 *
 * @example
 * import { table } from "@gemini-ui/design-system";
 *
 * @see
 * [Homepage Watchlist](https://github.com/gemini/gemini/blob/0ef0219880440e8d90238f31433a4dfff8abde28/web/src/main/assets/scripts/pages/RetailTrade/Homepage/Watchlist/index.tsx)
 */
export default function ResponsiveTable(props: Props) {
  const { mediaQuery, ...tableProps } = props;

  if (!mediaQuery) return <Table {...tableProps} />;

  return <Media query={mediaQuery}>{matches => <Table {...tableProps} collapse={matches} />}</Media>;
}
