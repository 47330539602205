import React from "react";
import { IconClockFilled } from "@hubble/icons";
import { Button, Feedback, Modal, Text } from "@gemini-ui/design-system";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { ModalIconContainer } from "@gemini-ui/pages/settings/BankSettings/debit/styles";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onToggle: ToggleDebitCardModal;
  requestRefresh?: (callback?: () => any | null) => any;
}

export const TransactionsSentModal = ({ onToggle, requestRefresh }: Props) => {
  const { intl } = useIntl();

  return (
    <Modal.MultiStep
      isOpen
      onClose={() => {
        onToggle("transactionsSentVisible", true)();
        requestRefresh();
      }}
    >
      <ModalIconContainer justifyContent="center" mb={2.5}>
        <IconClockFilled />
      </ModalIconContainer>
      <Text.Heading size="md" center>
        {intl.formatMessage({
          defaultMessage: "Transactions sent",
        })}
      </Text.Heading>
      <Text.Body size="sm" mt={2.5}>
        {intl.formatMessage({
          defaultMessage:
            "Transactions may take up to 2 business days to reflect on your account. Please come back to this screen to verify the transaction amounts.",
        })}
      </Text.Body>
      <Text.Body size="sm" mt={2.5}>
        {intl.formatMessage({
          defaultMessage: "Please complete verification as soon as possible.",
        })}
      </Text.Body>
      <Feedback status="info" mt={2.5}>
        <Feedback.Body>
          {intl.formatMessage({
            defaultMessage: "Micro-transactions are removed from your bank account within 14 days.",
          })}
        </Feedback.Body>
      </Feedback>
      <Button.Group type="stacked">
        <Button.Primary size="lg" data-testid="transactions-sent-btn" onClick={onToggle("verifyCardStepTwoVisible")}>
          {intl.formatMessage({
            defaultMessage: "Verify amounts",
          })}
        </Button.Primary>
        <Button.Tertiary
          size="lg"
          data-testid="back-to-verify-card-modal-btn"
          onClick={onToggle("verifyCardStepOneVisible")}
        >
          {intl.formatMessage({
            defaultMessage: "Resend transactions",
          })}
        </Button.Tertiary>
      </Button.Group>
    </Modal.MultiStep>
  );
};
