import React from "react";
import { IntlShape } from "@formatjs/intl";
import DatePicker from "@gemini-ui/components/DatePicker";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Button } from "@gemini-ui/design-system";
import { ButtonContainer } from "@gemini-ui/pages/Balances/components/history/styles";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getError } from "@gemini-ui/utils/error";
import { withIntl } from "@gemini-ui/utils/intl";
interface Props {
  transactionHistoryUrl: string;
  onDownload: (e?: any) => void;
  onChange: (state: any) => void;
  onCancel?: () => void;
  startDate?: Date;
  endDate?: Date;
  yearRange?: string[];
  subaccountHashid?: string;
}

interface State {
  isLoading: boolean;
}

class HistoryDownloadPicker extends React.Component<Props, State> {
  static contextType = AlertContext;
  declare context: React.ContextType<typeof AlertContext>;

  static defaultProps = {
    onDownload: () => {},
    onChange: () => {},
  };

  state = {
    isLoading: false,
  };

  handleDownload = () => {
    // No great way to detect when a file has been downloaded, so we're giving
    // the appearance of loading for a bit in hopes that the file comes not too long after
    const { onDownload, transactionHistoryUrl, subaccountHashid } = this.props;
    const { showAlert, hideAlert } = this.context;

    const options = subaccountHashid
      ? {
          headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
        }
      : {};

    hideAlert();
    this.setState({ isLoading: true });

    // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
    // Unfortunately, using the 'blob' approach drops any informative errors, so we can't use that.
    axios
      .get(transactionHistoryUrl, options)
      .then(() => {
        const link = document.createElement("a");
        link.href = transactionHistoryUrl;
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        showAlert({
          type: AlertTypes.ERROR,
          message: getError(err, "Unable to fetch transaction history."),
          timeout: 5000,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false }, () => onDownload?.());
      });
  };

  render() {
    const { intl } = this.props as unknown as { intl: IntlShape };

    const { onChange, onCancel, startDate, endDate, yearRange } = this.props;
    const { isLoading } = this.state;

    const isDisabled = Boolean(startDate) && Boolean(endDate) && new Date(startDate) > new Date(endDate);

    return (
      <div>
        <DatePicker
          onChange={onChange}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          showRange={false}
          yearRange={yearRange}
        />
        <ButtonContainer>
          <Button.Tertiary
            size="lg"
            data-testid="history-cancel-button"
            cta={intl.formatMessage({
              defaultMessage: "Cancel",
            })}
            onClick={onCancel}
            mr={1}
          />
          <Button.Primary
            size="lg"
            disabled={isDisabled}
            data-testid="history-download-button"
            loading={isLoading}
            onClick={this.handleDownload}
            cta={intl.formatMessage({
              defaultMessage: "Download .xlsx",
            })}
          />
        </ButtonContainer>
      </div>
    );
  }
}

export default withIntl(HistoryDownloadPicker);
