import { LangCodes } from "@gemini-ui/utils/intl";

export function formatNumber(value: number | string, locale: LangCodes, options: Intl.NumberFormatOptions): string {
  try {
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      return value?.toString();
    }
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(numericValue);
  } catch (error) {
    return value?.toString(); // Return original value in case of error
  }
}
