import React from "react";
import { ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives/Spacer";

export interface TabNavProps extends ShorthandSpacingCssProps {
  tabs: string[];
  children: React.ReactNode;
  defaultTab?: string;
  size?: "sm" | "md" | "lg";
  onChangeTab?: (tab: string) => void;
}

export type TabProps = {
  active: boolean;
  size: TabNavProps["size"];
  disabled?: boolean;
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const MENU_SIZE_TO_TEXT_SIZE_MAP = {
  sm: "xs",
  md: "sm",
  lg: "md",
} as const;
