import * as React from "react";
import { Button, Tooltip } from "@gemini-ui/design-system";
import { ButtonProps } from "@gemini-ui/design-system/Button";

type Props = {
  tooltipMessage: React.ReactNode;
} & ButtonProps;

const DisabledButtonWithTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  tooltipMessage,
  children,
  ...buttonProps
}) => {
  return (
    <Tooltip overlay={tooltipMessage}>
      <Button.Secondary disabled {...buttonProps}>
        {children}
      </Button.Secondary>
    </Tooltip>
  );
};

export default DisabledButtonWithTooltip;
