import styled, { CSSObject } from "@emotion/styled";
import { CardColorEnum } from "@gemini-ui/client/credit";
import { border, Colors, Flex } from "@gemini-ui/design-system";

type Sizes = "sm" | "md" | "lg";

const cardColorMap: Record<CardColorEnum, string> = {
  BLACK: Colors.creditCard.default.black,
  ROSE: Colors.creditCard.default.roseGold,
  SILVER: Colors.creditCard.default.silver,
};

const swatchSize: Record<Sizes, CSSObject> = {
  sm: {
    height: "24px",
    width: "24px",
  },
  md: {
    height: "32px",
    width: "32px",
  },
  lg: {
    height: "40px",
    width: "40px",
  },
};

export const StyledSwatch = styled(Flex)<{ color: CardColorEnum; size?: Sizes }>`
  align-items: center;
  justify-content: center;
  border-radius: ${border.radius.full};
  background-color: ${p => cardColorMap[p.color]};
  cursor: pointer;
  ${({ size = "md" }) => swatchSize[size]};
`;
