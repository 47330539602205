import styled from "@emotion/styled";
import { Colors, Flex } from "@gemini-ui/design-system";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";

export const GeminiLogoHeader = styled.a`
  display: block;
  margin: 0 auto;
  background-color: ${Colors.black};

  > img {
    padding-top: ${Spacing.scale[1.5]} !important;
    padding-bottom: ${Spacing.scale[1.5]};
  }
`;

export const HeaderFlexContainer = styled(Flex)`
  background-color: ${Colors.black};
  border-bottom: ${({ theme }) => `1px solid ${theme.colorScheme.border.primary};`};
  z-index: 101;
  height: ${Spacing.scale[9]};
  padding: ${Spacing.scale[2.5]} ${Spacing.scale[5]};
`;
