import React from "react";
import { PerpsOnboardingProps } from "@gemini-ui/components/Header/PerpOnboardingModal/constants";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const PerpOnboardingModal = LazyModalWrapper(() => import("./index"));

function LazyLoadPerpOnboardingModal({ onClose, isOpen, initialModalState }: PerpsOnboardingProps) {
  return <PerpOnboardingModal isOpen={isOpen} onClose={onClose} initialModalState={initialModalState} />;
}

export default LazyLoadPerpOnboardingModal;
