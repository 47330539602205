import * as Sentry from "@sentry/browser";
import { sentryOptions } from "@gemini-ui/sentry/options";

export const setupSentry = (email: string) => {
  Sentry.init(sentryOptions);

  if (email) {
    Sentry.setUser({ email });
  }
};
