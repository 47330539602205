import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { RedemptionStatus } from "@gemini-ui/pages/Earn/Redeem/types";
import axios from "@gemini-ui/services/axios";
import { StakingWithdrawQueryParams } from "@gemini-ui/services/staking/constants";
import { stakeSubmitWithdrawUrl } from "@gemini-ui/services/staking/endpoints";
import { getError } from "@gemini-ui/utils/error";

export type StakingWithdrawReturn = RedemptionStatus & {};

export const createStakingWithdraw = async (args: StakingWithdrawQueryParams): Promise<StakingWithdrawReturn> => {
  const params = {
    asset: args.currency,
    amount: args.amount,
    providerId: args.providerId,
    convert: false, // `convert` is an earn concept and should not be used for staking
    validatorCount: args.validatorCount,
    redeemSource: args.redeemSource,
  };

  return await axios
    .post(stakeSubmitWithdrawUrl(), params)
    .then(res => res.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useStakingWithdraw = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StakingWithdrawReturn>();
  const [error, setError] = useState<string>(null);

  const submitWithdrawal = useCallback(async (args: StakingWithdrawQueryParams) => {
    try {
      setLoading(true);
      const data = await createStakingWithdraw(args);
      setData(data);
      setError(null);
      return data;
    } catch (e) {
      const error = getError(e);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    submitWithdrawal,
    loading,
    error,
  };
};
