import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { MoneyProps } from "@gemini-ui/components/Money";
import { SENTRY_TAGS, SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import axios from "@gemini-ui/services/axios";
import { StakingTransferQueryParams } from "@gemini-ui/services/staking/constants";
import { stakeSubmitDepositUrl } from "@gemini-ui/services/staking/endpoints";
import { getError } from "@gemini-ui/utils/error";

export type StakingTransferReturn = {
  amountDeposited: MoneyProps;
};

export const createStakingTransfer = async (args: StakingTransferQueryParams): Promise<StakingTransferReturn> => {
  const params = {
    asset: args.currency,
    amount: args.amount,
    providerId: args.providerId,
  };

  return await axios
    .post(stakeSubmitDepositUrl(), params)
    .then(res => res.data)
    .catch(e => {
      Sentry.withScope(scope => {
        scope.setTag(SENTRY_TAGS.Team, SENTRY_TEAMS.Staking);
        Sentry.captureException(e);
      });
      throw e;
    });
};

export const useStakingTransfer = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StakingTransferReturn>();
  const [error, setError] = useState<string>(null);

  const submitTransfer = useCallback(async (args: StakingTransferQueryParams) => {
    try {
      setLoading(true);
      const data = await createStakingTransfer(args);
      setData(data);
      setError(null);
      return data;
    } catch (e) {
      const error = getError(e);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    submitTransfer,
    loading,
    error,
  };
};
