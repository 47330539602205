import * as React from "react";
import { addMonths, format, getMonth, isAfter, isBefore, parse, subMonths } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import _ from "lodash";
import {
  CURRENT_DATE,
  CURRENT_YEAR,
  GEMINI_OPEN_DATE,
  GEMINI_OPEN_YEAR,
} from "@gemini-ui/components/DatePicker/constants";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";

export interface DownloadActionRenderProps extends State {
  yearRange: string[];
  parsedAccountStatementDate: Date;
  toggleAccountStatementPicker: (e?: React.MouseEvent<HTMLElement>) => void;
  toggleTaxDocumentPicker: (e?: React.MouseEvent<HTMLElement>) => void;
  handleMonthChange: ({ accountStatementDate }: { accountStatementDate: string }) => void;
  handleDateChange: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
  toggleDatePicker: (e?: React.MouseEvent<HTMLElement>) => void;
}

export interface DownloadActionsProps {
  children?: (props: DownloadActionRenderProps) => React.ReactNode;
  createdAt?: number;
}

interface State {
  showDatePicker: boolean;
  startDate: Date;
  endDate: Date;
  showAccountStatementPicker: boolean;
  accountStatementDate: string;
  monthsAndYears: string[];
  showTaxDocumentPicker: boolean;
}

const currentDate = new Date();

class DownloadActions extends React.Component<DownloadActionsProps, State> {
  state = {
    showDatePicker: false,
    startDate: GEMINI_OPEN_DATE,
    endDate: CURRENT_DATE,
    showAccountStatementPicker: false,
    showTaxDocumentPicker: false,
    accountStatementDate: format(subMonths(currentDate, 1), DateFormats.MonthYear),
    monthsAndYears: [],
  };

  handleDateChange: DownloadActionRenderProps["handleDateChange"] = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  toggleDatePicker: DownloadActionRenderProps["toggleDatePicker"] = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState(state => ({ showDatePicker: !state.showDatePicker }));
  };

  private getMonthsAndYears = (dateStart: Date, dateEnd: Date) => {
    const monthsAndYears = [];
    while (isBefore(dateStart, dateEnd) || getMonth(dateStart) === getMonth(dateEnd)) {
      monthsAndYears.unshift(format(dateStart, DateFormats.MonthYear));
      dateStart = addMonths(dateStart, 1);
    }
    monthsAndYears.shift();
    this.setState({ monthsAndYears });
  };

  toggleAccountStatementPicker: DownloadActionRenderProps["toggleAccountStatementPicker"] = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { createdAt } = this.props;
    const sixMonthsBeforeCurrentDate = subMonths(currentDate, 6);
    const accountCreatedAt = new Date(createdAt) || sixMonthsBeforeCurrentDate;

    if (isAfter(accountCreatedAt, sixMonthsBeforeCurrentDate)) {
      this.getMonthsAndYears(accountCreatedAt, currentDate);
    } else {
      this.getMonthsAndYears(sixMonthsBeforeCurrentDate, currentDate);
    }

    this.setState(state => ({ showAccountStatementPicker: !state.showAccountStatementPicker }));
  };

  toggleTaxDocumentPicker: DownloadActionRenderProps["toggleTaxDocumentPicker"] = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(state => ({ showTaxDocumentPicker: !state.showTaxDocumentPicker }));
  };

  handleMonthChange: DownloadActionRenderProps["handleMonthChange"] = ({ accountStatementDate }) => {
    this.setState({ accountStatementDate });
  };

  render() {
    const {
      showDatePicker,
      startDate,
      endDate,
      showAccountStatementPicker,
      accountStatementDate,
      monthsAndYears,
      showTaxDocumentPicker,
    } = this.state;
    const yearRange = _.range(GEMINI_OPEN_YEAR, CURRENT_YEAR + 1).map(_.toString);

    const parsedAccountStatementDate = zonedTimeToUtc(
      parse(accountStatementDate, DateFormats.MonthYear, new Date()),
      "UTC"
    );

    return this.props.children({
      showDatePicker,
      startDate,
      endDate,
      showAccountStatementPicker,
      monthsAndYears,
      showTaxDocumentPicker,
      yearRange,
      accountStatementDate,
      parsedAccountStatementDate,
      toggleTaxDocumentPicker: this.toggleTaxDocumentPicker,
      toggleAccountStatementPicker: this.toggleAccountStatementPicker,
      handleMonthChange: this.handleMonthChange,
      handleDateChange: this.handleDateChange,
      toggleDatePicker: this.toggleDatePicker,
    });
  }
}

export default DownloadActions;
