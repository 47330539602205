import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { SpacerProps } from "@gemini-ui/design-system/primitives/Spacer";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { institutions } from "@gemini-ui/vendor/plaid/institutions";

export type InstitutionIconProps = {
  name: string;
  logo: string;
  primary_color: string;
};

export const wireMatchedNameToPlaidInstitutionMap = {
  "Jpmorgan Chase Bank": institutions.ins_3,
  "Jpmorgan Chase": institutions.ins_3,
  Citibank: institutions.ins_5,
  Citi: institutions.ins_5,
};

export type PaymentMethodIconBadgeProps = { size?: "sm" | "md" | "lg"; backgroundColor?: string } & SpacerProps;

export type PaymentMethodIconsProps = {
  defaultFiat?: CurrencyShortNameFiat | CurrencyShortName;
  paymentMethodType: PaymentMethodType | AccountType.BALANCE;
  institutionName?: string;
  cardIssuer?: string;
} & PaymentMethodIconBadgeProps;
