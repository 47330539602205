import React, { lazy, Suspense, useCallback, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";

type LazyRetryProps = {
  importFn: () => Promise<{
    default: React.ComponentType<React.PropsWithChildren<any>>;
  }>;
  LoaderView: (props: any) => React.ReactElement;
  ErrorRetryView: ({ retry, ...props }: { retry: () => void; [props: string]: any }) => React.ReactElement;
};

export const LazyRetry = <T extends object>({ importFn, LoaderView, ErrorRetryView }: LazyRetryProps) => {
  const RetryWrapper = (props: T) => {
    const [loading, setLoading] = useState(true);
    const retry = useCallback(() => setLoading(true), []);
    const LazyComponent = useMemo(
      () =>
        lazy(() =>
          importFn().catch(err => {
            setLoading(false);
            Sentry.captureException(err);
            return {
              default: () => <ErrorRetryView retry={retry} {...props} />,
            };
          })
        ),
      [importFn, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return (
      <Suspense fallback={<LoaderView {...props} />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };

  RetryWrapper.displayName = `LazyRetry(${RetryWrapper.displayName || RetryWrapper.name})`;

  return RetryWrapper;
};
