import { Theme } from "@emotion/react";

/**
 * getColor helper to support ThemeProvider dark scheme
 * @param lightColor Default (light) variable
 * @param darkColor Dark variant
 *
 * @deprecated Dynamic scheme-based colors are passed to all `Emotion.js` components via the `theme` prop or by using `useTheme`. Static colors are available as tokens from `@gemini-ui/design-system`.
 * @example
 * ### With the `theme` prop
 * ```
 * import { Colors } from "@gemini-ui/design-system";
 *
 * const MyComponent = styled.div`
 *   color: ${Colors.blue['400']}; // static color
 *   background: ${({ theme }) => theme.colorScheme.background.primary }; // Dynamic color based on the active color scheme.
 * `
 * ```
 * ### With `useTheme`
 * ```
 *
 * import { useTheme } from "@emotion/react"
 * import { Colors } from "@gemini-ui/design-system";
 *
 * const MyComponent = props => {
 *   const { colorScheme } = useTheme();
 *
 *   return (
 *     <div style={{
 *        color: Colors.blue["400"],
 *        background: colorScheme.background.primary,
 *     }}>
 *        {props.children}
 *     </div>
 *   );
 * };
 * ```
 */
export const getColor =
  (lightColor: string, darkColor?: string) =>
  ({ theme }: { theme: Theme }) =>
    theme.isDark ? darkColor : lightColor;
