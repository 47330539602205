import styled from "@emotion/styled";
import { Button, Spacing } from "@gemini-ui/design-system";

export const PayPalButtonGroup = styled(Button.Group)`
  position: relative;
`;

export const PayPalLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: ${Spacing.scale[1]};
`;

export const PayPalLoadingButton = styled(Button.Primary)<{ visible: Boolean }>`
  ${({ visible }) => !visible && `visibility: hidden;`}
`;
