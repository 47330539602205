import * as React from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { BankAccountType } from "@gemini-ui/constants/paymentMethods";
import { transferMethods } from "@gemini-ui/constants/wireFunding";
import { Button, Fieldset, Form, Input, Modal, Radio, Text, useForm } from "@gemini-ui/design-system";
import { AddWireFormData } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/DefaultAddWireFlow";
import { isBankFrickEnabledForUK } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/utils/isBankFrickEnabledForUK";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";
import { getFieldData } from "@gemini-ui/utils/wireFunding";

interface AddWireModalProps {
  currency: CurrencyShortNameFiat;
  defaultValues?: Partial<AddWireFormData>;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (formData: Partial<AddWireFormData>) => void;
}

const transferMethodsArray = Object.values(transferMethods);
const getComponentCopy = (intl: IntlShape) => ({
  MODAL_TITLE: intl.formatMessage({
    defaultMessage: "Add bank account for wire transfers",
  }),
  MODAL_BODY: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "<HelpCenterLinkArticlesAddBankAccount>Need help? See FAQ.</HelpCenterLinkArticlesAddBankAccount>",
    }),
    {
      HelpCenterLinkArticlesAddBankAccount: (v: React.ReactNode) => (
        <HelpCenterLink article={Articles.ADD_BANK_ACCOUNT}>{v}</HelpCenterLink>
      ),
    }
  ),
  ACTION_PRIMARY: intl.formatMessage({
    defaultMessage: "Continue",
  }),
});
const getComponentCopyForBankFrick = (intl: IntlShape) => ({
  MODAL_TITLE: intl.formatMessage({
    defaultMessage: "Add bank account",
  }),
  MODAL_BODY: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "This must be an individual bank account under your name. <HelpCenterLinkArticlesAddBankAccount>Need help? See FAQ.</HelpCenterLinkArticlesAddBankAccount>",
    }),
    {
      HelpCenterLinkArticlesAddBankAccount: (v: React.ReactNode) => (
        <HelpCenterLink article={Articles.ADD_BANK_ACCOUNT}>{v}</HelpCenterLink>
      ),
    }
  ),
  ACTION_PRIMARY: intl.formatMessage({
    defaultMessage: "Review",
  }),
});
const AddWireDetailsModal = ({
  currency,
  defaultValues = {
    swiftOrRoutingNumber: "",
    accountNumber: "",
    accountType: BankAccountType.Checking,
    bic: "",
  },
  isOpen,
  onBack,
  onClose,
  onSubmit,
}: AddWireModalProps) => {
  const { intl } = useIntl();
  const isBankFrickFlowEnabled = isBankFrickEnabledForUK(currency);
  const copy = isBankFrickFlowEnabled ? getComponentCopyForBankFrick(intl) : getComponentCopy(intl);
  const transferMethod = transferMethodsArray.find(method => method.supportedCurrencies.includes(currency));
  const { bic, accountNumber, accountType } = getFieldData(intl, false, isBankFrickFlowEnabled);
  const { handleSubmit, register, formState } = useForm({
    defaultValues,
  });

  return isBankFrickFlowEnabled ? (
    <Modal.MultiStep isOpen={isOpen} onBack={onBack} onClose={onClose} title={copy.MODAL_TITLE}>
      <Text.Body size="sm" mt={1} mb={3}>
        {copy.MODAL_BODY}
      </Text.Body>
      <Form onSubmit={handleSubmit(onSubmit)} errors={formState.errors} mb={0}>
        <Input
          {...register("accountNumber", accountNumber.validation)}
          data-testid={`${accountNumber.apiField}Input`}
          error={formState.errors?.accountNumber?.message}
          label={accountNumber.label}
          mask={accountNumber.mask}
        />

        <Input
          {...register("bic", bic.validation)}
          data-testid={`${bic.apiField}Input`}
          error={formState.errors?.bic?.message}
          label={bic.label}
          mask={bic.mask}
        />

        <Fieldset
          data-testid="account-selector"
          error={formState.errors?.accountType?.message}
          legend={accountType.label}
        >
          <Radio value={BankAccountType.Checking} {...register("accountType", accountType.validation)}>
            {intl.formatMessage({
              defaultMessage: "Checking",
            })}
          </Radio>
          <Radio value={BankAccountType.Savings} {...register("accountType", accountType.validation)}>
            {intl.formatMessage({
              defaultMessage: "Savings",
            })}
          </Radio>
        </Fieldset>
        <Button.Group>
          <Button.Primary data-testid="add-wire-detail-next-button" type="submit">
            {copy.ACTION_PRIMARY}
          </Button.Primary>
        </Button.Group>
      </Form>
    </Modal.MultiStep>
  ) : (
    <Modal.MultiStep isOpen={isOpen} onBack={onBack} onClose={onClose} title={copy.MODAL_TITLE}>
      <Text.Body size="sm" mt={1} mb={3}>
        {copy.MODAL_BODY}
      </Text.Body>
      <Form onSubmit={handleSubmit(onSubmit)} errors={formState.errors}>
        {transferMethod.schema.map((field, index) => {
          const { label, apiField, placeholder, helperText, mask, validation } = getFieldData(intl)[field];
          const props = {
            ...register(field as keyof AddWireFormData, validation),
            "data-testid": `${apiField}Input`,
            error: formState.errors[field]?.message,
            message: helperText,
            key: apiField,
            label,
            placeholder,
          };
          return <Input {...props} mask={mask} />;
        })}
        <Button.Group>
          <Button.Primary data-testid="add-wire-details-next" type="submit">
            {copy.ACTION_PRIMARY}
          </Button.Primary>
        </Button.Group>
      </Form>
    </Modal.MultiStep>
  );
};

export default AddWireDetailsModal;
