export const ACCOUNT_MENU_VIEWS = {
  default: "default",
  group: "group",
};

export const INTERFACE_MODES = {
  simple: "simple",
  activeTrader: "activeTrader",
};

export const TEST_IDS = {
  account_menu_default_view: "account-menu-default-view",
  simple_interface_button: "simple-interface-button",
  active_interface_button: "active-interface-button",
};
