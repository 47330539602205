import React from "react";
import { ProgressBarContainer, ProgressBarFiller } from "@gemini-ui/pages/register/OnboardingLayout/styles";

type ProgressBarProps = {
  progressBarCompletion: number;
  size?: "sm" | "md" | "lg";
  bgColor?: string;
  fillColor?: string;
};

const ProgressBar = ({ progressBarCompletion, size = "sm", bgColor, fillColor }: ProgressBarProps) => {
  return (
    <ProgressBarContainer size={size} bgColor={bgColor}>
      <ProgressBarFiller progressBarCompletion={progressBarCompletion} fillColor={fillColor} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
