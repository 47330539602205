/* eslint-disable */

export function consoleLogRecruitmentRocket(mode) {
  if (mode !== "dev" && mode !== "test") {
    console.info(`%c
       ^
      / \\
     /___\\
    |=   =|
    |     |
    |  G  |         Greetings, human. We come in peace.
    |  E  |
    |  M  |         We're on the lookout for new crew on board the Gemini spaceship.
    |  I  |
    |  N  |         Care to join our moon mission? https://www.gemini.com/careers
    |  I  |
    |     |
   /|--!--|\\
  / |--!--| \\
 /  |--!--|  \\
|  / ^ | ^ \\  |
| /  ( | )  \\ |
|/   ( | )   \\|
    ((   ))
   ((  :  ))
    ((   ))
     (( ))
      ( )
       .
`, "font-family: monospace;");
  }
}
