import styled from "@emotion/styled";
import { border, motion, Spacing } from "@gemini-ui/design-system/primitives";
import { shorthandSpacingCss } from "@gemini-ui/design-system/primitives/Spacer";

const TRANSITION_MS = "200ms";
const TOGGLE_KNOB_DIMENSIONS = Spacing.scale[3];
const TOGGLE_TRACK_WIDTH = Spacing.scale[5];

// overriding styles from web/src/main/assets/styles/components/_forms.scss
const MAIN_SCSS_OVERRIDES = `
  order: unset;
  clip: unset;
  margin: unset;
  position: static;

  + span:before {
    display: none;
  }

  + span {
    display: block;
    margin-left: unset;
    font-size: inherit;
    letter-spacing: unset;
    line-height: unset;
    cursor: unset;
  }
`;

export const ToggleLabel = styled.label`
  display: inline-flex;
  align-items: center;
  ${shorthandSpacingCss}
`;

export const ToggleInput = styled.input`
  ${MAIN_SCSS_OVERRIDES}

  appearance: none;
  background-color: ${({ theme }) => theme.colorScheme.toggle.background.unselected.enabled};
  border-radius: ${border.radius.full};
  cursor: pointer;
  height: ${TOGGLE_KNOB_DIMENSIONS};
  position: relative;
  transition: background-color ${TRANSITION_MS} ${motion.timing.easeInOut};
  width: ${TOGGLE_TRACK_WIDTH};

  &:checked {
    background-color: ${({ theme }) => theme.colorScheme.toggle.background.selected.enabled};
  }

  /* show focus outline when keyboard navigating, not when clicking */
  &:focus:not([data-focus-visible-added]) {
    outline: none;
  }

  &:disabled {
    cursor: unset;
    background-color: ${({ theme }) => theme.colorScheme.toggle.background.unselected.disabled};

    &::before {
      background-color: ${({ theme }) => theme.colorScheme.toggle.knob.unselected.disabled};
      border-color: ${({ theme }) => theme.colorScheme.toggle.background.unselected.disabled};
    }

    &::before,
    &:checked::before {
      background-color: ${({ theme }) => theme.colorScheme.toggle.knob.selected.disabled};
      border-color: ${({ theme }) => theme.colorScheme.toggle.background.selected.disabled};
    }
  }

  &::before {
    background-color: ${({ theme }) => theme.colorScheme.toggle.knob.unselected.enabled};
    border-radius: ${border.radius.full};
    border: 2px solid ${({ theme }) => theme.colorScheme.toggle.background.unselected.enabled};
    box-sizing: border-box;
    content: "";
    height: ${TOGGLE_KNOB_DIMENSIONS};
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(0);
    transition: transform ${TRANSITION_MS} ${motion.timing.easeInOut}, border-color ${TRANSITION_MS};
    width: ${TOGGLE_KNOB_DIMENSIONS};
  }

  &:checked::before {
    background-color: ${({ theme }) => theme.colorScheme.toggle.knob.selected.enabled};
    border: 2px solid ${({ theme }) => theme.colorScheme.toggle.background.selected.enabled};
    transform: translateX(calc(${TOGGLE_TRACK_WIDTH} - ${TOGGLE_KNOB_DIMENSIONS}));
  }
`;
