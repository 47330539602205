import * as React from "react";

export function ChaseIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#0975C8" />
      <path
        d="M16.449 10.706c0-.39.317-.706.707-.706l6.415.002 5.955 5.654H16.449v-4.95ZM10 22.847a.707.707 0 0 0 .706.707h4.95V10.476l-5.655 5.955L10 22.848ZM29.798 16.654a.705.705 0 0 1 .207.5l-.002 6.414-5.654 5.957V16.448h4.95c.187 0 .367.074.5.206ZM23.555 29.293c0 .39-.316.706-.705.707l-6.418-.001-5.954-5.655h13.077v4.95Z"
        fill="#fff"
      />
    </svg>
  );
}
