import React, { useEffect, useRef } from "react";
import * as Sentry from "@sentry/browser";
import { useForm } from "react-hook-form";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import GRecaptcha from "@gemini-ui/components/GRecaptcha";
import SupportLink from "@gemini-ui/components/SupportLink";
import { Checkbox, Form, Input, Spacer, Text } from "@gemini-ui/design-system";
import { useCardContext } from "@gemini-ui/pages/Credit/CreditApplication/v2/CardContext";
import { PasswordInput, SSNInput, SubmitButton } from "@gemini-ui/pages/Credit/CreditApplication/v2/components";
import {
  FIELD_NAMES,
  FormValues,
  NEURO_IDS,
  OnboardingComponentProps,
} from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { getNewUserFormCopy } from "@gemini-ui/pages/Credit/CreditApplication/v2/copy";
import { handleNewAppSubmitV3 } from "@gemini-ui/pages/Credit/CreditApplication/v2/handlers/handleNewAppSubmitV3";
import { useFormValidation, useSendEmailV3 } from "@gemini-ui/pages/Credit/CreditApplication/v2/hooks";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/v2/styles";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/v2/testIds";
import { StyledList } from "@gemini-ui/pages/Credit/CreditApplication/v2/Views/styles";
import { getIntlPrefix, getPhoneNumberMask } from "@gemini-ui/pages/register/ConfirmPhone/PhoneNumberInput/phoneUtils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const NewUserProvePrefill = ({ send, user }: OnboardingComponentProps) => {
  const {
    handleSubmit,
    setValue,
    register,
    setFocus,
    watch,
    trigger,
    control,
    formState: { errors, isValid, isSubmitting, touchedFields },
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      ssnLastFour: "",
      email: "",
      password: "",
      newAppTerms: false,
      g_recaptcha_response_v2: "",
      g_recaptcha_response_v3: "",
    },
  });
  const { showAlert } = useAlert();
  const validation = useFormValidation();
  const [, handleSendVerificationEmail] = useSendEmailV3();
  const registerFirstName = register(FIELD_NAMES.firstName, validation.firstName);
  const registerLastName = register(FIELD_NAMES.lastName, validation.lastName);
  const { intl } = useIntl();
  const copy = getNewUserFormCopy(intl);
  const recaptcha = useRef<GRecaptcha>();
  const { mask, numberPlaceholder } = getPhoneNumberMask(getIntlPrefix("US"));
  const siteKeyV2 = window.initialData?.pageProps?.siteKeyV2;
  const siteKeyV3 = window.initialData?.pageProps?.siteKeyV3;
  const [newAppTerms, g_recaptcha_response_v2, g_recaptcha_response_v3] = watch([
    FIELD_NAMES.newAppTerms,
    FIELD_NAMES.g_recaptcha_response_v2,
    FIELD_NAMES.g_recaptcha_response_v3,
  ]);

  useEffect(() => {
    setFocus(FIELD_NAMES.firstName);
  }, [setFocus]);

  const onRecaptchaResolve = () => {
    if (siteKeyV3) {
      recaptcha.current
        .executeV3()
        .then(token => {
          setValue(FIELD_NAMES.g_recaptcha_response_v2, recaptcha.current.getResponse());
          setValue(FIELD_NAMES.g_recaptcha_response_v3, token);
        })
        .catch(e => {
          showAlert({
            type: AlertTypes.ERROR,
            message: (
              <React.Fragment>
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage: "Unable to verify device, please <SupportLink></SupportLink>.",
                  }),
                  {
                    SupportLink: () => <SupportLink />,
                  }
                )}
              </React.Fragment>
            ),
            id: "credit-card-application-error",
          });
          Sentry.captureMessage(
            `Credit Card Application: Something went wrong with the recaptcha v3 execution on the client -- error: ${e}`,
            Sentry.Severity.Error
          );
        });
    } else {
      setValue(FIELD_NAMES.g_recaptcha_response_v2, recaptcha.current.getResponse());
    }
  };

  const onRecaptchaExpired = () => {
    setValue(FIELD_NAMES.g_recaptcha_response_v2, "");
    setValue(FIELD_NAMES.g_recaptcha_response_v3, "");
  };

  const hasCaptcha = g_recaptcha_response_v2 || g_recaptcha_response_v3;

  const onSubmit = async values => {
    await handleNewAppSubmitV3({ send, values, showAlert, isLoggedIn: user.isLoggedIn, handleSendVerificationEmail });
  };

  const { cardName, setCardName } = useCardContext();
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardName({ ...cardName, [event.target.name]: event.target.value });

    if (event.target.name === FIELD_NAMES.firstName) registerFirstName.onChange(event);
    if (event.target.name === FIELD_NAMES.lastName) registerLastName.onChange(event);
  };

  return (
    <div>
      <ViewTitle>
        <Text.Heading>{intl.formatMessage({ defaultMessage: "Let's get started!" })}</Text.Heading>
        <Text.Body>
          {intl.formatMessage({ defaultMessage: "Enter your information below to begin pre-qualification." })}
        </Text.Body>
      </ViewTitle>
      <Form data-testid={testIds.form.newUserApplication} onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...registerFirstName}
          data-testid={testIds.input.firstName}
          type="text"
          label={intl.formatMessage({ defaultMessage: "Legal first name" })}
          placeholder={intl.formatMessage({ defaultMessage: "William" })}
          error={touchedFields.firstName && errors?.firstName?.message}
          data-nid-target={NEURO_IDS.firstName}
          inputSize="lg"
          autoComplete="given-name"
          onChange={handleNameChange}
        />
        <Input
          {...register(FIELD_NAMES.middleName, validation.middleName)}
          data-testid={testIds.input.middleName}
          type="text"
          label={intl.formatMessage({ defaultMessage: "Legal middle name (optional)" })}
          error={touchedFields.middleName && errors?.middleName?.message}
          inputSize="lg"
          autoComplete="additional-name"
        />
        <Input
          {...registerLastName}
          data-testid={testIds.input.lastName}
          type="text"
          label={intl.formatMessage({ defaultMessage: "Legal last name" })}
          placeholder={intl.formatMessage({ defaultMessage: "Smith" })}
          error={touchedFields.lastName && errors?.lastName?.message}
          data-nid-target={NEURO_IDS.lastName}
          inputSize="lg"
          autoComplete="family-name"
          onChange={handleNameChange}
        />
        <Input
          {...register(FIELD_NAMES.phone, validation.phone)}
          data-testid={testIds.input.phone}
          label={copy.PHONE_NUMBER}
          mask={mask}
          placeholder={numberPlaceholder}
          error={touchedFields.phone && errors?.phone?.message}
          inputSize="lg"
          autoComplete="tel-national"
          leftElement={<div style={{ lineHeight: "normal" }}>+1</div>}
        />
        <SSNInput<FormValues>
          name={FIELD_NAMES.ssnLastFour}
          control={control}
          rules={validation.ssnLastFour}
          initialType="password"
          data-testid={testIds.input.nationalIdNumber}
          label={copy.SOCIAL_SECURITY_NUMBER}
          placeholder="XXXX"
          error={touchedFields.ssnLastFour && errors?.ssnLastFour?.message}
          inputSize="lg"
          message={copy.ENTER_SSN_LAST_FOUR}
          isLastFour
        />
        <Input
          {...register(FIELD_NAMES.email, validation.email)}
          data-testid={testIds.input.emailAddress}
          type="email"
          label={copy.EMAIL_ADDRESS}
          placeholder={copy.PLACEHOLDERS.EMAIL}
          error={touchedFields.email && errors?.email?.message}
          data-nid-target={NEURO_IDS.email}
          inputSize="lg"
          autoComplete="email"
        />
        <PasswordInput<FormValues>
          name={FIELD_NAMES.password}
          data-testid={testIds.input.pw}
          control={control}
          rules={validation.password}
          error={touchedFields.password && errors?.password?.message}
          inputSize="lg"
          autoComplete="new-password"
        />
        <Spacer mb={2}>
          <Text.Body size="xs" mb={1}>
            {copy.REVIEW_TERMS}
          </Text.Body>
          <Checkbox
            {...register(FIELD_NAMES.newAppTerms, {
              ...validation.newAppTerms,
              onChange: () => trigger(FIELD_NAMES.newAppTerms),
            })}
            data-testid={testIds.checkbox.newAppTerms}
            error={errors?.newAppTerms?.message}
            checked={Boolean(newAppTerms)}
          >
            <Text.Body size="xs">{copy.AGREE_TO_TERMS}</Text.Body>
          </Checkbox>
          <StyledList>
            <li>
              <Text.Body size="xs">{copy.ELECTRONIC_DELIVERY_CONSENT_STATEMENT}</Text.Body>
            </li>
            <li>
              <Text.Body size="xs">{copy.AUTOMATED_AUTHORIZATION}</Text.Body>
            </li>
          </StyledList>
          <Text.Body size="xs">{copy.AGREE_TO_GEMINI_ACCOUNT}</Text.Body>
          <StyledList>
            <li>
              <Text.Body size="xs">{copy.USER_AGREEMENT}</Text.Body>
            </li>
            <li>
              <Text.Body size="xs">{copy.PRIVACY_POLICY}</Text.Body>
            </li>
          </StyledList>
        </Spacer>
        <GRecaptcha
          ref={recaptcha}
          siteKeyV2={siteKeyV2}
          siteKeyV3={siteKeyV3}
          onResolved={onRecaptchaResolve}
          onExpired={onRecaptchaExpired}
          checkbox
        />
        <SubmitButton
          data-testid={testIds.button.submitNewApp}
          type="submit"
          loading={isSubmitting}
          disabled={!isValid || !hasCaptcha}
          cta={copy.CONTINUE}
        />
      </Form>
    </div>
  );
};
