import React from "react";
import { CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { GeminiAccount } from "@gemini-ui/constants/templateProps/account";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Modal, Spacer, Text } from "@gemini-ui/design-system";
import CurrencySelector from "@gemini-ui/pages/settings/BankSettings/components/CurrencySelector";
import { useIntl } from "@gemini-ui/utils/intl";

type Props = {
  defaultFiat: GeminiAccount["defaultFiat"];
  onSubmit: (currency: CurrencyShortNameFiat) => void;
  onClose: () => void;
  isOpen: boolean;
  onBack?: () => void;
};

const SelectCurrencyModal = ({ defaultFiat, onSubmit, onClose, isOpen, onBack }: Props) => {
  const {
    templateProps: {
      account: { supportedFiat },
    },
  } = usePageData();
  const [selectedCurrency, setSelectedCurrency] = React.useState<CurrencyShortNameFiat | null>(defaultFiat);
  const { intl } = useIntl();

  const currencies = supportedFiat.filter(item => !isCurrency.COP(item));

  return (
    <Modal.MultiStep
      title={intl.formatMessage({
        defaultMessage: "Select currency",
        description: "Selecting currency for adding a bank account",
      })}
      isOpen={isOpen}
      onClose={onClose}
      onBack={onBack}
    >
      <Text.Body size="sm" mt={1}>
        {intl.formatMessage({
          defaultMessage: "Select the native currency that your bank account holds a balance in.",
        })}
      </Text.Body>
      <Spacer mt={5}>
        <CurrencySelector onSelect={setSelectedCurrency} defaultCurrency={defaultFiat} currencies={currencies} />
      </Spacer>
      <Button.Group>
        <Button.Tertiary onClick={onClose} data-testid="add-wire-currency-cancel">
          {intl.formatMessage({ defaultMessage: "Cancel" })}
        </Button.Tertiary>
        <Button.Primary
          onClick={() => onSubmit(selectedCurrency)}
          disabled={!selectedCurrency}
          data-testid="add-wire-currency-submit"
        >
          {intl.formatMessage({ defaultMessage: "Next", description: "Go to next step for adding a bank account" })}
        </Button.Primary>
      </Button.Group>
    </Modal.MultiStep>
  );
};

export default SelectCurrencyModal;
