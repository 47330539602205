/** @jsx jsx */
import React from "react";
import { jsx, Theme } from "@emotion/react";
import { CardNumber, Cvv, ExpiryDate, Frames } from "frames-react";
import _ from "lodash";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiEntities, GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { Button, Colors, Input, Label, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { FormElementMessage } from "@gemini-ui/design-system/formElements";
import ProgressBar from "@gemini-ui/pages/register/OnboardingLayout/ProgressBar";
import { DebitCardInfo, ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { WORLDPAY_NETWORK_ERROR_MSG } from "@gemini-ui/pages/settings/BankSettings/constants";
import { handleDebitCardSubmit } from "@gemini-ui/pages/settings/BankSettings/debit/services/apiLayer";
import {
  CKOInputContainer,
  TwoColumnInputContainer,
  WorldpayInput,
} from "@gemini-ui/pages/settings/BankSettings/debit/styles";
import { DeviceDataInitialisation } from "@gemini-ui/pages/settings/BankSettings/debit/types";
import { getPostalCode } from "@gemini-ui/pages/settings/BankSettings/utils/getPostalCode";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import { DebitCardType } from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, IntlShape, withIntl } from "@gemini-ui/utils/intl";
import { withTheme } from "@gemini-ui/utils/withTheme";

export enum SupportedCards {
  VISA = "visa",
  MASTER_CARD = "mastercard",
}

const CHECKOUT_KEY = "pk_test_6e40a700-d563-43cd-89d0-f9bb17d35e73";
const CARDNUMBER_PLACEHOLDER = "XXXX XXXX XXXX XXXX";

interface AddDebitCardModalProps {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  selectedCurrency: CurrencyShortNameFiat;
  handleVerifyCard: (cardProps: DebitCardType, isExistingCard: boolean) => void;
  onToggle: ToggleDebitCardModal;
  updateCardInfo: ({ sessionToken, cardHolderName }: DebitCardInfo) => void;
  requestRefresh: (callback?: () => any | null) => any;
  intl: IntlShape;
  geminiEntity?: GeminiEntity;
  defaultCardHolderName?: string;
  isManualAddressMode?: boolean;
  openDebitCardErrorModal?: (debitCardErrorHeading: string, debitCardErrorMessage?: string) => void;
  selectedSubaccountHashid?: string;
  subaccountHashid?: string;
  theme?: Theme;
  setHandleDeviceDataResponse?: (response: DeviceDataInitialisation & { unVerifiedTokenID: string }) => void;
  showDeviceDataIframe?: boolean;
}

const isCheckoutEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_PAYMENTS_CHECKOUT);
const isWebDebitCard3DSEnabled = optimizelyClient.isFeatureEnabled(
  OPTIMIZELY_FEATURE_FLAGS.IS_WEB_DEBIT_CARD_3DS_ENABLED
);

const WORLD_PAY_PROD_CHECKOUT_ID = "2889b1dd-6caf-4273-b4d2-38c6a0c9671d";
const WORLD_PAY_QA_CHECKOUT_ID = "d87d8039-50c8-4861-b408-1332ec6c7e14";
const NAME_INPUT_ERROR_MESSAGE = "Please enter name on card.";
class AddDebitCardModal extends React.Component<AddDebitCardModalProps> {
  static contextType = AlertContext;
  declare context: React.ContextType<typeof AlertContext>;

  state = {
    cardHolderName: this.props.defaultCardHolderName || "",
    invalidCardTypeError: false,
    addingDebitCard: false,
    checkoutScriptLoaded: false,
    token: "",
    nextStepDisabled: true,
    nameInputErrorMessage: "",
    showChallengeFormIframe: false,
  };
  componentDidMount() {
    if (isCheckoutEnabled) {
      this.injectCheckoutScript();
    } else {
      this.injectWorldpayScript();
    }
  }
  isNewDebitCardFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DEBIT_CARD_FLOW_REVAMP);
  handleNameChange = e => {
    this.setState({ cardHolderName: e.target.value }, () => {
      if (this.isNewDebitCardFlowEnabled) {
        if (!this.state.cardHolderName.length) {
          this.setState({ nameInputErrorMessage: NAME_INPUT_ERROR_MESSAGE });
        } else {
          this.setState({ nameInputErrorMessage: "" });
        }
      }
    });
  };

  onSubmit = async (sessionState?: string) => {
    const {
      onToggle,
      updateCardInfo,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      selectedCurrency,
      handleVerifyCard,
      defaultCardHolderName,
    } = this.props;
    const { cardHolderName } = this.state;

    updateCardInfo({
      sessionToken: sessionState,
      cardHolderName: cardHolderName,
    });
    const payload = isCheckoutEnabled
      ? {
          billingAddress: {
            cardHolderName: defaultCardHolderName,
            streetLine1: address1,
            streetLine2: address2,
            city: city, // internationally named: locality
            state: state, // internationally named: region
            countryCode: country,
            postalCode: zip,
          },
          cardHolderName: defaultCardHolderName,
          verificationCurrency: selectedCurrency,
          ckoRef: { token: sessionState },
        }
      : {
          billingAddress: {
            cardHolderName: cardHolderName,
            streetLine1: address1,
            streetLine2: address2,
            city: city, // internationally named: locality
            state: state, // internationally named: region
            countryCode: country,
            postalCode: getPostalCode(zip),
          },
          cardHolderName: cardHolderName,
          verificationCurrency: selectedCurrency,
          sessionToken: sessionState,
        };
    await handleDebitCardSubmit(
      payload,
      selectedCurrency,
      country,
      this.props.selectedSubaccountHashid || this.props.subaccountHashid,
      onToggle,
      handleVerifyCard,
      this.props.openDebitCardErrorModal,
      isWebDebitCard3DSEnabled,
      this.props.setHandleDeviceDataResponse
    );
    this.setState({ addingDebitCard: false });
  };

  getWorldpayCheckoutUrl = () =>
    window.location.hostname === "exchange.gemini.com"
      ? "https://access.worldpay.com/access-checkout/v1/checkout.js"
      : "https://try.access.worldpay.com/access-checkout/v1/checkout.js";

  getWorldpayCheckoutIdentity = () =>
    window.location.hostname === "exchange.gemini.com" ? WORLD_PAY_PROD_CHECKOUT_ID : WORLD_PAY_QA_CHECKOUT_ID;

  injectCheckoutScript = () => {
    const SCRIPT_ID = "checkoutdotcom";
    const TEXT_JAVASCRIPT = "text/javascript";
    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.src = "https://cdn.checkout.com/js/framesv2.min.js";
    script.type = TEXT_JAVASCRIPT;
    script.async = true;
    script.onload = () => {
      this.setState({ checkoutScriptLoaded: true });
    };

    script.onerror = error => {
      console.error(error);
    };
    if (document.body) document.body.appendChild(script);
  };

  injectWorldpayScript = () => {
    const SCRIPT_ID = "worldpay";
    const TEXT_JAVASCRIPT = "text/javascript";
    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.src = this.getWorldpayCheckoutUrl();
    script.type = TEXT_JAVASCRIPT;
    script.async = true;
    script.onload = this.initWorldpay;

    script.onerror = error => {
      this.context.showAlert({
        type: AlertTypes.ERROR,
        message: WORLDPAY_NETWORK_ERROR_MSG,
        timeout: 5000,
      });
    };
    if (document.body) document.body.appendChild(script);
  };

  initWorldpay = () => {
    const form = document.getElementById("card-form");
    const { intl } = this.props;
    window.Worldpay.checkout.init(
      {
        id: this.getWorldpayCheckoutIdentity(),
        form: "#card-form",
        fields: {
          pan: {
            selector: "#card-pan",
            placeholder: "XXXX XXXX XXXX XXXX",
          },
          cvv: {
            selector: "#card-cvv",
            placeholder: this.isNewDebitCardFlowEnabled
              ? intl.formatMessage({ defaultMessage: "3 or 4 digit code" })
              : intl.formatMessage({ defaultMessage: "3 digit code" }),
          },
          expiry: {
            selector: "#card-expiry",
            placeholder: intl.formatMessage({
              defaultMessage: "MM/YY",
              description: "Debit card expiration date placeholder",
            }),
          },
        },
        enablePanFormatting: true,
        styles: {
          input: {
            color: this.props.theme.isDark ? Colors.white : Colors.gray[900],
            "font-weight": "200",
            "font-size": this.isNewDebitCardFlowEnabled ? "14px" : "16px",
            "line-height": "34px",
            "letter-spacing": "0",
          },
          "input#pan": {},
          "input.is-valid": {},
          "input.is-invalid": {},
          "input.is-onfocus": {
            color: this.props.theme.isDark ? "white" : "black",
          },
        },
      },

      (error, checkout) => {
        if (error) {
          this.context.showAlert({
            type: AlertTypes.ERROR,
            message: WORLDPAY_NETWORK_ERROR_MSG,
            timeout: 5000,
          });
          return;
        }

        if (this.isNewDebitCardFlowEnabled) {
          document.getElementById("card-pan-msg").setAttribute(
            "data-before",
            intl.formatMessage({
              defaultMessage: "Please check the card number",
            })
          );
          document.getElementById("card-expiry-msg").setAttribute(
            "data-before",
            intl.formatMessage({
              defaultMessage: "Please check the expiration date",
            })
          );
          document.getElementById("card-cvv-msg").setAttribute(
            "data-before",
            intl.formatMessage({
              defaultMessage: "Please check the security code",
            })
          );
        } else {
          document.getElementById("card-pan-msg").setAttribute(
            "data-before",
            intl.formatMessage({
              defaultMessage: "Debit Card number is invalid",
            })
          );
          document.getElementById("card-expiry-msg").setAttribute(
            "data-before",
            intl.formatMessage({
              defaultMessage: "Invalid expiration date",
            })
          );
          document.getElementById("card-cvv-msg").setAttribute(
            "data-before",
            intl.formatMessage({
              defaultMessage: "Invalid security code",
            })
          );
        }

        form.addEventListener("wp:form:change", (event: any) => {
          if (event.detail["is-valid"]) {
            this.setState({
              nextStepDisabled: false,
            });
          } else {
            this.setState({
              nextStepDisabled: true,
            });
          }
        });

        form.addEventListener("wp:field:change", (event: any) => {
          event.preventDefault();
          this.setState({ invalidCardTypeError: false });
          const { detail } = event;
          const { field } = detail;
          if (detail["is-valid"]) {
            field.$element.classList.add("valid-icon");
          } else {
            field.$element.classList.remove("valid-icon");
          }
        });
        form.addEventListener("submit", (event: any) => {
          event.preventDefault();

          this.setState({ addingDebitCard: true }, () => {
            const classNames = event.target.className;
            const approvedCards = Object.values(SupportedCards).filter(key => classNames.includes(key));

            if (approvedCards.length === 0) {
              document.getElementById("card-pan-msg").setAttribute(
                "data-before",
                intl.formatMessage({
                  defaultMessage: "Please try again with Visa or Mastercard.",
                })
              );
              this.setState({ addingDebitCard: false, invalidCardTypeError: true });
            }

            const IS_VALID_CARD = approvedCards.length === 0;
            const IS_NAME_FIELD_EMPTY = !this.state.cardHolderName.length;

            if ((IS_VALID_CARD || IS_NAME_FIELD_EMPTY) && this.isNewDebitCardFlowEnabled) {
              if (IS_VALID_CARD) {
                document.getElementById("card-pan-msg").setAttribute(
                  "data-before",
                  intl.formatMessage({
                    defaultMessage: "Please use a VISA or Mastercard.",
                  })
                );
                this.setState({ invalidCardTypeError: true });
              } else {
                this.setState({ nameInputErrorMessage: NAME_INPUT_ERROR_MESSAGE });
              }
              this.setState({ addingDebitCard: false });
              return;
            }

            // hack to get around generateSessionState being called more than once
            const handler = _.once((error, sessionState) => {
              if (error) {
                const errorMessage = intl.formatMessage({
                  defaultMessage:
                    "Information provided does not match the card issuer's information. Please try again.",
                });
                trackPaymentRegistrationFailure(LinkPaymentType.DEBIT, errorMessage);
                this.context.showAlert({
                  type: AlertTypes.ERROR,
                  message: errorMessage,
                  timeout: 5000,
                });
                this.setState({ addingDebitCard: false });
                return;
              }
              this.onSubmit(sessionState);
            });
            checkout.generateSessionState(handler);
          });
        });
      }
    );
  };

  determineBackPageToggle = (isNewDebitCardFlowEnabled, isManualAddressMode, onToggle) => {
    if (isNewDebitCardFlowEnabled && isManualAddressMode) {
      return onToggle("addBillingAddressManualVisible", false, true);
    } else {
      return onToggle("addBillingAddressManualVisible");
    }
  };

  render() {
    const { onToggle, intl, geminiEntity, isManualAddressMode, showDeviceDataIframe } = this.props;
    const { cardHolderName, addingDebitCard, invalidCardTypeError, checkoutScriptLoaded } = this.state;
    const currentlyCopyOrNot = geminiEntity === GeminiEntities.GeminiEurope ? "" : `currently`;

    return (
      <Modal.MultiStep
        isOpen
        onClose={() => {
          onToggle("addDebitCardVisible", true)();
          this.props.requestRefresh();
        }}
        onBack={this.determineBackPageToggle(this.isNewDebitCardFlowEnabled, isManualAddressMode, onToggle)}
        title={
          !this.isNewDebitCardFlowEnabled
            ? intl.formatMessage({
                defaultMessage: "Add debit card",
              })
            : undefined
        }
      >
        {this.isNewDebitCardFlowEnabled ? (
          <React.Fragment>
            <ProgressBar progressBarCompletion={66.66} />
            <Text.Heading size="md" mt={2} mb={1}>
              {intl.formatMessage({
                defaultMessage: "Complete debit card details",
              })}
            </Text.Heading>
            <Text.Body mb={2.5} size="sm">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "<HelpCenterLinkArticlesAddDebitCard>How do I add a debit card?</HelpCenterLinkArticlesAddDebitCard>",
                }),
                {
                  HelpCenterLinkArticlesAddDebitCard: (v: React.ReactNode) => (
                    <HelpCenterLink article={Articles.ADD_DEBIT_CARD}>{v}</HelpCenterLink>
                  ),
                }
              )}
            </Text.Body>
          </React.Fragment>
        ) : (
          <Text.Body mb={2.5} size="sm">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "Credit cards are {currentlyCopyOrNot} not supported. <HelpCenterLinkArticlesAddDebitCard>Need help? See FAQ.</HelpCenterLinkArticlesAddDebitCard>",
              }),
              {
                HelpCenterLinkArticlesAddDebitCard: (v: React.ReactNode) => (
                  <HelpCenterLink article={Articles.ADD_DEBIT_CARD}>{v}</HelpCenterLink>
                ),
                currentlyCopyOrNot,
              }
            )}
          </Text.Body>
        )}

        {isCheckoutEnabled && checkoutScriptLoaded && (
          <Frames
            config={{
              publicKey: CHECKOUT_KEY,
              localization: {
                cardNumberPlaceholder: CARDNUMBER_PLACEHOLDER,
                expiryMonthPlaceholder: "MM",
                expiryYearPlaceholder: "YY",
                cvvPlaceholder: "3 digit code",
              },
              style: {
                base: {
                  height: "60px",
                  borderRadius: "8px",
                  border: "1px solid #676868",
                  color: "#343536",
                  padding: "0 10px",
                },
                focus: {
                  color: "#000000",
                  border: "2px solid #000000",
                },
              },
            }}
            cardTokenized={e => this.setState({ token: e.token })}
          >
            <Text.Body mb={0.5} size="sm" bold>
              {intl.formatMessage({
                defaultMessage: "Card number",
              })}
            </Text.Body>
            <CKOInputContainer mb={2}>
              <CardNumber />
            </CKOInputContainer>
            <TwoColumnInputContainer>
              <div>
                <Text.Body mb={0.5} bold size="sm">
                  {intl.formatMessage({
                    defaultMessage: "Exp. date",
                  })}
                </Text.Body>
                <ExpiryDate />
              </div>
              <div>
                <Text.Body mb={0.5} bold size="sm">
                  {intl.formatMessage({
                    defaultMessage: "Security code",
                  })}
                </Text.Body>
                <Cvv />
              </div>
            </TwoColumnInputContainer>
            <Button.Group>
              <Button.Primary
                loading={addingDebitCard}
                data-testid="debit-modal-btn"
                type="submit"
                onClick={async () => {
                  await Frames.submitCard();

                  this.setState({ addingDebitCard: true }, () => {
                    this.onSubmit(this.state.token);
                  });
                }}
                cta={intl.formatMessage({
                  defaultMessage: "Next",
                })}
                size="lg"
              />
            </Button.Group>
          </Frames>
        )}
        {!isCheckoutEnabled && (
          <div>
            <Spacer mt={3}>
              <section className="card">
                <form className="checkout" id="card-form" css={{ marginBottom: 0 }}>
                  <Input
                    data-testid="debit-modal-full-name"
                    id="debit-modal-full-name"
                    name="name"
                    label={intl.formatMessage({
                      defaultMessage: "Name on card",
                    })}
                    error={this.state.nameInputErrorMessage}
                    value={cardHolderName}
                    onChange={this.handleNameChange}
                    inputSize={this.isNewDebitCardFlowEnabled ? "md" : "lg"}
                  />
                  <Spacer mt={1.5}>
                    <Label>
                      {intl.formatMessage({
                        defaultMessage: "Card number",
                      })}
                    </Label>
                    <WorldpayInput id="card-pan" className={`field ${invalidCardTypeError && "unknown is-invalid"}`} />
                    <FormElementMessage hideIcon error dataTestId="card-pan" id="card-pan-msg" />
                  </Spacer>

                  {this.isNewDebitCardFlowEnabled ? (
                    <React.Fragment>
                      <Label>
                        {intl.formatMessage({
                          defaultMessage: "Expiration date",
                        })}
                      </Label>
                      <WorldpayInput id="card-expiry" className="field" />
                      <FormElementMessage hideIcon error dataTestId="card-expiry" id="card-expiry-msg" />
                      <Label>
                        {intl.formatMessage({
                          defaultMessage: "Security code",
                        })}
                      </Label>
                      <WorldpayInput id="card-cvv" className="field" />
                      <FormElementMessage hideIcon error dataTestId="card-cvv" id="card-cvv-msg" />
                    </React.Fragment>
                  ) : (
                    <Spacer mt={1.5}>
                      <TwoColumnInputContainer>
                        <div>
                          <Label>
                            {intl.formatMessage({
                              defaultMessage: "Exp. date",
                            })}
                          </Label>
                          <WorldpayInput id="card-expiry" className="field" />
                          <FormElementMessage hideIcon error dataTestId="card-expiry" id="card-expiry-msg" />
                        </div>
                        <div>
                          <Label>
                            {intl.formatMessage({
                              defaultMessage: "Security code",
                            })}
                          </Label>
                          <WorldpayInput id="card-cvv" className="field" />
                          <FormElementMessage hideIcon error dataTestId="card-cvv" id="card-cvv-msg" />
                        </div>
                      </TwoColumnInputContainer>
                    </Spacer>
                  )}

                  <Button.Group>
                    <Button.Primary
                      loading={addingDebitCard || showDeviceDataIframe}
                      data-testid="debit-modal-btn"
                      type="submit"
                      cta={intl.formatMessage({
                        defaultMessage: "Next",
                      })}
                      size="md"
                      disabled={this.state.nextStepDisabled}
                    />
                  </Button.Group>
                </form>
              </section>
            </Spacer>
          </div>
        )}
      </Modal.MultiStep>
    );
  }
}

export default withIntl(withTheme(AddDebitCardModal));
