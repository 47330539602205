import React, { Dispatch } from "react";
import { StyledComponent } from "@emotion/styled";
import { Columns, Data } from "@gemini-ui/components/Table/constants";
import Row from "@gemini-ui/components/Table/Row";

interface Props {
  data: Data;
  columns: Columns;
  idAttribute: string | Function;
  renderContext?: Record<string, any>;
  collapsedRowRenderer?: Function;
  collapse?: boolean;
  customHeader?: boolean;
  rowColSpan?: number;
  isRetailTrade?: boolean;
  styledRow?: StyledComponent<Record<string, any>, any, any>;
  overrideFontSize?: string;
  onClick?: (id: any) => void | Dispatch<any>;
  onRowClick?: Function;
}

export default function Body(props: Props) {
  const {
    data,
    idAttribute,
    columns,
    renderContext,
    collapsedRowRenderer,
    collapse,
    customHeader,
    rowColSpan,
    isRetailTrade,
    styledRow,
    overrideFontSize,
    onRowClick,
    onClick,
  } = props;
  const len = data.length;
  const rows = data.map((d, i) => {
    const id = typeof idAttribute === "function" ? idAttribute(d, i) : d[idAttribute] || i;

    const clickHandler = onClick
      ? {
          onClick: () => onClick(id),
        }
      : onRowClick
      ? { onClick: () => onRowClick(d) }
      : {};
    return (
      <Row
        key={id}
        overrideFontSize={overrideFontSize}
        isRetailTrade={isRetailTrade}
        id={id}
        isFirst={i === 0}
        isLast={i === len - 1}
        data={d}
        columns={columns}
        renderContext={renderContext}
        collapsedRowRenderer={collapsedRowRenderer}
        collapse={collapse}
        customHeader={customHeader}
        colSpan={rowColSpan}
        styledRow={styledRow}
        {...clickHandler}
      />
    );
  });

  return <tbody>{rows}</tbody>;
}
