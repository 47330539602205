import * as Sentry from "@sentry/browser";
import axios from "@gemini-ui/services/axios";
import { UserPermissions } from "@gemini-ui/services/user/constants";

export const getUserPermissions = async () => {
  try {
    const response = await axios.get<UserPermissions>(jsRoutes.controllers.user.UserController.getPermissions().url, {
      retry: 3,
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
  }
};
