import { SupportedStakingPaymentMethod, SupportedUnstakingMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

/**
 * Parse a staking funding source to get a string value for the method id.
 *
 * For deposits: SupportedStakingPaymentMethod may be a string value or a PaymentMethod object.
 * For withdrawals: SupportedUnstakingMethod is already string value, but we'll explicity type it as a string for consistency.
 *
 * @param method SupportedStakingPaymentMethod | SupportedUnstakingMethod
 * @returns string | null
 */
export const getPaymentMethodId = (method?: SupportedStakingPaymentMethod | SupportedUnstakingMethod) => {
  if (!method) return null;
  return typeof method === "string" ? method : method.id;
};
