import React, { FormHTMLAttributes, forwardRef, ReactNode } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { FieldErrors } from "react-hook-form";
import { useFormErrors } from "@gemini-ui/design-system/forms/shared/hooks/use-form-errors";
import { Colors, mediaQuery, shorthandSpacingCss, ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";
import { SectionMessageFormErrors } from "@gemini-ui/design-system/SectionMessage/SectionMessageFormErrors";
import { Text } from "@gemini-ui/design-system/Text";
import { getColor } from "@gemini-ui/design-system/utils";

interface FormProps<T = {}> extends FormHTMLAttributes<HTMLFormElement>, ShorthandSpacingCssProps {
  children: ReactNode;
  errors?: FieldErrors<T> | Partial<Record<keyof T, string>>;
  heading?: ReactNode;
  subheading?: ReactNode;
}

const StyledForm = styled.form`
  margin-bottom: 0;
  ${shorthandSpacingCss};
  // Provides the proper vertical rhythm for the form elements
  > * + * {
    margin-top: ${Spacing.scale[3]};
  }
`;

const DEFAULT_COLUMN_VALUE = "1fr 1fr";

type ColumnValues =
  | typeof DEFAULT_COLUMN_VALUE // 2 elements, each shares 50% of row
  | "2fr 1fr" // 2 elements, the first has 2/3 of row, second has 1/3
  | "1fr 2fr"; // 2 elements, the first has 1/3 of row, second has 2/3

export const FormRow = styled.div<{ columnValues?: ColumnValues; mobileTemplateColumns?: string }>`
  column-gap: ${Spacing.scale[2]};
  display: grid;
  grid-template-columns: ${({ columnValues }) => columnValues};
  max-width: 448px;

  ${({ mobileTemplateColumns }) =>
    Boolean(mobileTemplateColumns) &&
    `
    @media ${mediaQuery.mobileXsDown} {
      grid-template-columns: ${mobileTemplateColumns};
      row-gap: ${Spacing.scale[3]};
    }
  `}
`;

FormRow.defaultProps = {
  columnValues: DEFAULT_COLUMN_VALUE,
};

const FormInner = <T,>({ children, errors, heading, subheading, onSubmit, ...props }: FormProps<T>, ref) => {
  const [hasErrors, formErrors] = useFormErrors<T>(errors);
  const theme = useTheme();

  return (
    <StyledForm onSubmit={onSubmit} ref={ref} {...props}>
      {Boolean(heading || subheading) && (
        <header>
          {Boolean(heading) && (
            <Text.Heading mb={1} size="lg">
              {heading}
            </Text.Heading>
          )}
          {Boolean(subheading) && (
            <Text.Body color={getColor(Colors.gray[600], Colors.gray[400])({ theme })} size="sm">
              {subheading}
            </Text.Body>
          )}
        </header>
      )}
      {hasErrors && <SectionMessageFormErrors formErrorLinks={formErrors} />}
      {children}
    </StyledForm>
  );
};

export const Form = forwardRef(FormInner);
