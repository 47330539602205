import React from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { usePageData } from "@gemini-ui/contexts";
import { Modal } from "@gemini-ui/design-system";
import useAjaxModal, { AjaxModalState } from "@gemini-ui/hooks/useAjaxModal";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";

interface WireVerifyProps {
  bankAccountUuid?: string;
  currency: CurrencyShortNameFiat;
  children?: (toggleWireModal: any) => React.ReactNode;
  bankNotSupported?: boolean;
}

interface AjaxWireModalProps {
  modalState: AjaxModalState;
  currency: CurrencyShortNameFiat;
  isOpen: boolean;
  closeModal: () => void;
  pageProps: any;
  pageName: string;
  setAjaxData: (ajaxData: any) => void;
  initialWireFormValues?: any;
  onBack?: () => void;
  isSettingsOrOnboarding?: boolean;
  subaccountHashid: string;
  scope?: PaymentTypeScope;
}

const WireVerify = ({ bankAccountUuid, currency, children, bankNotSupported = false }: WireVerifyProps) => {
  const controller = bankAccountUuid
    ? jsRoutes.controllers.settings.WireSettingsController.get(bankAccountUuid)
    : jsRoutes.controllers.settings.WireSettingsController.addBankAccountGet(bankNotSupported);
  const { pageName, isOpen, closeModal, toggleModal, pageProps, modalState, setAjaxData } = useAjaxModal({
    controller,
    isInitialOpen: false,
  });

  const { subaccountHashid } = usePageData().templateProps.user;

  return (
    <React.Fragment>
      <AjaxWireModal
        setAjaxData={setAjaxData}
        modalState={modalState}
        pageName={pageName}
        currency={currency}
        isOpen={isOpen}
        closeModal={closeModal}
        pageProps={pageProps}
        subaccountHashid={subaccountHashid}
      />
      {children(toggleModal)}
    </React.Fragment>
  );
};

export const AjaxWireModal = ({
  modalState,
  currency,
  isOpen,
  closeModal,
  pageProps,
  pageName,
  setAjaxData,
  initialWireFormValues = {},
  onBack,
  isSettingsOrOnboarding,
  subaccountHashid,
  scope,
}: AjaxWireModalProps) => {
  const { GeoRestrictBankRegistration } = useFeatureFlags();
  const pages = React.useMemo(() => {
    return {
      WireSettings: require("@gemini-ui/pages/settings/WireSettings").default,
      WireSuccess: require("@gemini-ui/pages/settings/WireSuccess").default,
      AddAccountViaWireSuccess:
        require("@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/CompleteDepositModal")
          .default,
    };
  }, []);
  const ModalComponent = pages[pageName];
  return (
    <React.Fragment>
      {Boolean(ModalComponent) && modalState === AjaxModalState.DEFAULT && (
        <ModalComponent
          onBack={onBack}
          setAjaxData={setAjaxData}
          currency={currency}
          geoRestrictBankRegistration={GeoRestrictBankRegistration}
          isOpen={isOpen}
          onClose={closeModal}
          initialWireFormValues={initialWireFormValues}
          isSettingsOrOnboarding={isSettingsOrOnboarding}
          subaccountHashid={subaccountHashid}
          {...pageProps}
        />
      )}
      {modalState === AjaxModalState.LOADING && <Modal isOpen={isOpen} onClose={closeModal} onBack={onBack} loading />}
    </React.Fragment>
  );
};

export default WireVerify;
