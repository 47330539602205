import * as React from "react";
import {
  Alert,
  AlertContext as AlertContextType,
  AlertTypes,
  GENERIC_FORM_ERROR_MESSAGE,
  GenericFormErrorAlert,
} from "@gemini-ui/components/GlobalAlert/constants";

export const AlertContext = React.createContext<AlertContextType>({
  alert: null,
  showAlert: (alert: Alert) => {},
  hideAlert: () => {},
  showGenericFormErrorAlert: (props: GenericFormErrorAlert) => {},
});

export const useAlert = () => {
  const context = React.useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

class AlertProvider extends React.Component<{ children?: React.ReactNode }, { alert: Alert }> {
  timeout: null | number;
  state = {
    alert: null,
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { children } = this.props;
    return (
      <AlertContext.Provider
        value={{
          ...this.state,
          showAlert: this.showAlert,
          hideAlert: this.hideAlert,
          showGenericFormErrorAlert: this.showGenericFormErrorAlert,
        }}
      >
        {children}
      </AlertContext.Provider>
    );
  }

  showAlert = (alert: Alert) => {
    // clear previous alert to avoid setTimeout making new alert disappear early
    clearTimeout(this.timeout);

    // show new alert
    this.setState({
      alert,
    });

    if (alert.timeout) {
      this.timeout = window.setTimeout(() => {
        this.setState({
          alert: null,
        });
      }, alert.timeout);
    }
  };
  /**
   * showGenericFormErrorAlert
   *
   * Displays backend errors sent back with an empty key instead of mapped to form name - e.g. formErrors: { "": "Invalid Sort Code for region" }
   * If an empty key is not present in the formErrors payload, displays a fallback form error message.
   */
  showGenericFormErrorAlert = ({ formErrors, message = GENERIC_FORM_ERROR_MESSAGE }: GenericFormErrorAlert) => {
    const genericError = formErrors[""];
    const errorMessage = (genericError && Array.isArray(genericError) && genericError.join("\n")) || message;

    this.showAlert({
      message: errorMessage,
      timeout: 8000,
      type: AlertTypes.ERROR,
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
}

export default AlertProvider;
