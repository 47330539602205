import styled from "@emotion/styled";

const Drawer = styled("section")`
  position: absolute;
  padding: 0;
  top: 50px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  min-height: 940px;
`;

export default Drawer;
