import React from "react";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { GrowAsset, GrowAssets } from "@gemini-ui/constants/earn";
import { parseWindowLocationProps } from "@gemini-ui/pages/Earn/GrowRoutes/utils";
import { mapGrowAssets } from "@gemini-ui/pages/Earn/utils";

interface StakingAssetsContext {
  assets: Partial<Record<CurrencyShortNameSupportedCryptos, GrowAsset>>;
  /**
   * The asset for the current page, based on the currency in the URL.
   * If the currency is not supported by Grow, this will be `undefined`,
   * or if the URL does not contain a currency.
   */
  pageAsset: GrowAsset;
}

const StakingAssetsContext = React.createContext<StakingAssetsContext>({
  assets: {},
  pageAsset: undefined,
});

export const StakingAssetsProvider = ({ children, assets }: { children: React.ReactNode; assets: GrowAssets[] }) => {
  const { currency: currencyInURL } = parseWindowLocationProps();

  const assetMap = mapGrowAssets(assets);
  const pageAsset = assetMap[currencyInURL];

  const values = {
    assets: assetMap,
    pageAsset,
  };

  return <StakingAssetsContext.Provider value={values}>{children}</StakingAssetsContext.Provider>;
};

export const useStakingAssets = () => {
  const context = React.useContext(StakingAssetsContext);
  if (!context) {
    throw new Error("useStakingAssets must be used within a StakingAssetsProvider");
  }
  return context;
};
