import _ from "lodash";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { User } from "@gemini-ui/constants/templateProps/users";

export function isMpcCustodyAccountSelected(user: User) {
  const subaccountHashid = _.get(user, "subaccountHashid", "");
  const subaccounts = _.get(user, "subaccounts", []);
  const currentAccount = subaccounts.find((acct: Subaccount) => acct.hashid === subaccountHashid);

  return Boolean(currentAccount?.isMpcCustody);
}
