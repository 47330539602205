import {
  SupportedBank,
  XfersStep,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/constants";
import axios from "@gemini-ui/services/axios";
import {
  XfersAccountsPagePropsType,
  XfersAccountType,
  XfersPagePropsType,
} from "@gemini-ui/transformers/PaymentMethods";
import { IntlShape } from "@gemini-ui/utils/intl";

const supportedBanksRoute = jsRoutes.controllers.settings.XfersSettingsController.getSupportedBanks().url;
const enableBankAccountRoute = jsRoutes.controllers.settings.XfersSettingsController.enableBankAccount().url;
const disableBankAccountRoute = jsRoutes.controllers.settings.XfersSettingsController.disableBankAccount().url;

export enum InternationalPrefix {
  Singapore = 65,
}

export const mapErrorToForm = formErrors => {
  const mappedFormErrors = formErrors as {
    phoneNumber?: string[];
    otp?: string[];
    intlPrefix?: string[];
  };

  if (formErrors["phone.number"]) {
    mappedFormErrors.phoneNumber = formErrors["phone.number"];
  }

  if (formErrors["phone.otp"]) {
    mappedFormErrors.otp = formErrors["phone.otp"];
  }

  if (formErrors["phone.intlPrefix"]) {
    mappedFormErrors.intlPrefix = formErrors["phone.intlPrefix"];
  }

  return mappedFormErrors;
};

export const handleGetSupportedBanks = () => axios.get(supportedBanksRoute);

export const validateAccountNumber = (value: string, bank: SupportedBank | undefined, intl: IntlShape) => {
  if (!value) {
    return intl.formatMessage({ defaultMessage: "Please enter the bank account number." });
  }

  if (bank && (bank.maxLength < value.length || bank.minLength > value.length)) {
    return `Bank account number must be ${bank.minLength} to ${bank.maxLength} characters.`;
  }
};

export const handleEnableXfersBank = (uuid: string) => axios.post(enableBankAccountRoute, { uuid });
export const handleDisableXfersBank = (uuid: string) => axios.post(disableBankAccountRoute, { uuid });

export const hasMultipleXfersAccounts = (xfersAccounts: XfersAccountType[]) => Boolean(xfersAccounts.length > 1);

export const initStep = (xferAccounts: Array<XfersAccountsPagePropsType>, xfersData: XfersPagePropsType) => {
  if (xferAccounts.length >= 10) {
    return XfersStep.MAX_ACCOUNTS;
  }
  if (xfersData?.hasAuthToken) {
    return XfersStep.ADD_BANK;
  }
  return XfersStep.INIT;
};
