import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { GrowAsset, GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";

export enum RedeemStep {
  PlaceRedeem = "placeRedeemVisible",
  ReviewRedeem = "reviewRedeemVisible",
  RedeemSuccess = "successVisible",
  SelectRedeemSource = "selectRedeemSourceVisible",
}

export interface RedemptionStatus {
  amountRedeemed: MoneyProps;
  redeemedInFull: boolean;
}

export interface RedeemFlowState {
  step: RedeemStep;
  asset?: GrowAsset;
  amount?: string;
  currency?: CurrencyShortNameSupportedCryptos;
  convertToFiat?: boolean;
  providerId?: string;
  providerType?: GrowProviderType;
  redemptionStatus?: RedemptionStatus;
  unstakeSourceType?: UnstakeSourceType;
  validatorCount?: number;
}

export interface PlaceRedeemInfo {
  asset?: GrowAsset;
  currency?: CurrencyShortNameSupportedCryptos;
  redeemableBalance?: MoneyProps;
  amount?: string;
  tradingPair?: string;
  provider?: InterestProvider;
  gusdToggle?: boolean;
  redemptionStatus?: RedemptionStatus;
  isGUSDConvert?: boolean;
  maxWithdrawDelay?: number;
  unstakeSource?: UnstakeSourceType;
  validatorCount?: number;
}

export interface RedeemProps {
  initialState?: Partial<RedeemFlowState>;
  onClose?: () => void;
  onBack?: () => void;
}

export enum UnstakeRedeemSource {
  DEPOSIT = "deposit",
  REWARD = "reward",
}
