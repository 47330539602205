import React from "react";
import { Flex, Spacing } from "@gemini-ui/design-system";
import { GrowBuyCard } from "@gemini-ui/pages/Earn/GrowBuy";
import { LearnMoreCard } from "@gemini-ui/pages/Earn/GrowSidebar/LearnMoreCard";
import { useGrowFeatureFlags, useGrowPageData } from "@gemini-ui/pages/Earn/hooks";

const GrowSidebar = () => {
  const { isGrowSidebarModuleEnabled } = useGrowFeatureFlags();

  const {
    pageProps: { assets },
  } = useGrowPageData();

  return (
    <Flex flexDirection="column" gap={Spacing.scale[2]}>
      {isGrowSidebarModuleEnabled && assets.length > 0 && <GrowBuyCard />}
      <LearnMoreCard />
    </Flex>
  );
};

export default GrowSidebar;
