import styled from "@emotion/styled";
import { border, Colors, Spacing, Text } from "@gemini-ui/design-system";

export const StyledList = styled.ul`
  list-style-type: disc;
  margin: ${Spacing.scale["1"]} 0 ${Spacing.scale["1"]} ${Spacing.scale["2"]};
`;

export const LegalTextBlock = styled(Text.Body)`
  margin-bottom: ${Spacing.scale["2"]};
  color: ${p => p.theme.colorScheme.content.secondary};

  & > a {
    color: ${p => p.theme.colorScheme.content.secondary};
  }
`;

export const StyledSchumerBox = styled.div`
  margin-bottom: ${Spacing.scale[3]};

  table {
    background-color: ${p => p.theme.colorScheme.background.secondary};
    border-collapse: unset;
    border-radius: ${border.radius.lg} ${border.radius.lg} 0 0;
    table-layout: fixed;
    width: 100%;
    border: 1px solid;

    thead p {
      font-size: 16px;
      line-height: 24px;
      border-radius: ${border.radius.lg} ${border.radius.lg} 0 0;
    }

    colgroup col:last-of-type {
      width: 60%;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: ${Colors.gray["900"]};
    }

    th p {
      background: ${Colors.black};
      color: ${Colors.white};
      padding: ${Spacing.scale[2]};
      text-align: left;
      font-weight: 600;
    }

    td {
      font-size: 14px;
      line-height: 20px;
      border: 1px solid ${Colors.white};
      padding: ${Spacing.scale[2]};
      overflow: hidden;

      a {
        word-wrap: break-word;
        color: ${Colors.black};
      }

      ul {
        padding-left: ${Spacing.scale[3]};
        list-style-type: disc;
      }
    }
  }

  table:last-of-type {
    border-radius: 0 0 ${border.radius.lg} ${border.radius.lg};
    margin-bottom: ${Spacing.scale["3"]};

    thead p {
      border-radius: 0;
    }

    td {
      border: none;
    }

    tr td:first-of-type {
      border-right: 2px solid ${Colors.white};
    }

    td[colspan="1"] {
      padding-bottom: 0;
    }

    tr:nth-of-type(3),
    tr:nth-of-type(6) {
      td {
        padding-top: 0;
        border-bottom: 2px solid ${Colors.white};
      }
    }
  }

  table tr.border {
    border-top: 1px solid;
  }

  sup {
    font-size: 10px;
    vertical-align: super;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: ${p => p.theme.colorScheme.content.secondary};
  }
`;

export const StyledSchumerBoxPlaceholder = styled.div`
  width: 100%;
  height: 2000px;
  border: 1px solid;
  border-radius: ${border.radius.lg};
`;
