import React, { createContext, FC, useContext } from "react";

export type RefreshContextType = {
  refreshCount: number;
  requestRefresh: (callback?: any) => any;
};

const RefresherContext = createContext<RefreshContextType | null>(null);
export const usePageRefresh = () => useContext(RefresherContext);

// In a future PR, move the AutoRefresh logic into this provider.
export const RefresherProvider: FC<React.PropsWithChildren<RefreshContextType>> = ({ children, ...refresherProps }) => (
  <RefresherContext.Provider value={refresherProps}>{children}</RefresherContext.Provider>
);
