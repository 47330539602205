import styled from "@emotion/styled";

export const TrimmedWrapper = styled.div<{ maxWidth: string }>`
  position: relative;
  white-space: nowrap;
  width: 100%;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;

export const FullHiddenText = styled.div`
  position: absolute;
  visibility: hidden;
  width: 100%;
`;

export const EllipsedText = styled.div<{ ellipsis: boolean }>`
  ${({ ellipsis }) => ellipsis && `text-overflow: ellipsis;`}

  overflow: hidden;
`;

export const LimitTextLengthWrapper = styled.span<{ withFixedChars: boolean; isOverflowing?: boolean }>`
  ${({ withFixedChars }) => (withFixedChars ? "display: inline-block" : "width: 100%;")}
  ${({ isOverflowing }) => isOverflowing && `> span { width: 100%; }`}
`;
