/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { IconChevronLeftLarge, IconClose } from "@hubble/icons";
import { Button } from "@gemini-ui/design-system/Button";
import { Flex } from "@gemini-ui/design-system/Flex";
import { ModalLayoutWrapper } from "@gemini-ui/design-system/Modal/styles";
import { Spacer } from "@gemini-ui/design-system/primitives/Spacer";
import { Text } from "@gemini-ui/design-system/Text";
import { useIntl } from "@gemini-ui/utils/intl";

interface ModalLayoutProps {
  title: React.ReactNode;
  headerImage?: React.ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  multiStep?: boolean;
  hasLargeTitle?: boolean;
  className?: string;
  hasDropdown?: boolean;
  centered?: boolean;
  hideCloseButton?: boolean;
}

const ModalLayout: React.FC<React.PropsWithChildren<ModalLayoutProps>> = ({
  children,
  title,
  headerImage,
  onBack,
  onClose,
  multiStep,
  hasLargeTitle,
  className,
  hasDropdown,
  centered,
  hideCloseButton,
}) => {
  const { intl } = useIntl();
  return (
    <ModalLayoutWrapper centered={centered} multiStep={multiStep} className={className} hasDropdown={hasDropdown}>
      <Flex justify={!onClose ? "flex-start" : "space-around"}>
        {Boolean(onBack) && (
          <Button.Secondary
            display="inline"
            size="sm"
            aria-label={intl.formatMessage({
              defaultMessage: "Go back modal button",
            })}
            data-testid="modal-back-button"
            onClick={onBack}
            icon={<IconChevronLeftLarge />}
          />
        )}
        {Boolean(onClose) && !hideCloseButton && (
          <Button.Secondary
            display="inline"
            size="sm"
            aria-label={intl.formatMessage({
              defaultMessage: "Close modal button",
            })}
            ml="auto"
            onClick={onClose}
            data-testid="dismiss-modal-x"
            icon={<IconClose />}
          />
        )}
      </Flex>
      <Spacer className="ModalHeader" pt={3}>
        {Boolean(headerImage) && headerImage}
        {Boolean(title) && (
          <Text.Heading size={hasLargeTitle ? "md" : "sm"} mb={multiStep ? 1 : 1.5} overflowWrap="break-word">
            {title}
          </Text.Heading>
        )}
      </Spacer>
      <div className="ModalContent">{children}</div>
    </ModalLayoutWrapper>
  );
};

export { ModalLayout };
