import React from "react";
import { Button, Flex, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import {
  AgreementButtonContainer,
  AgreementNumber,
  ScrollContainer,
  StyledMarkdown,
} from "@gemini-ui/pages/Earn/Deposit/AcceptAgreement/styles";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const consentUrl = jsRoutes.controllers.account.FeatureConsentController.post().url;

const AGREEMENT_RENDERERS = {
  p: ({ children }) => (
    <Text.Body size="sm" mb={1}>
      {children}
    </Text.Body>
  ),
};

interface Props {
  acceptOnClick: () => void;
  declineOnClick: () => void;
  title: string;
  number?: string;
  agreement: string;
  disabled?: boolean;
}

const Agreement = ({ title, acceptOnClick, declineOnClick, number, agreement, disabled }: Props) => {
  const { intl } = useIntl();

  const header = (
    <Flex flexDirection="column" mb={3}>
      {Boolean(number) && (
        <AgreementNumber>
          {intl.formatMessage(defineMessage({ defaultMessage: "{number} of 2", description: "Page number (of 2)" }), {
            number,
          })}
        </AgreementNumber>
      )}
      <Text.Heading size="md">{title}</Text.Heading>
      <Text.Body size="md">
        {intl.formatMessage({
          defaultMessage: "Please scroll down to read this entire agreement before you accept its terms.",
        })}
      </Text.Body>
    </Flex>
  );

  return (
    <React.Fragment>
      {header}
      <ScrollContainer data-testid={testIds.deposit.acceptAgreement.scrollSection}>
        <StyledMarkdown componentConfig={AGREEMENT_RENDERERS} source={agreement} />
        {!Boolean(agreement) ? (
          <Flex align="center" justify="center" mt={4}>
            <SpinnerAnimation />
          </Flex>
        ) : (
          <React.Fragment>
            {!disabled && (
              <AgreementButtonContainer type="action">
                <Button.Tertiary
                  data-testid={testIds.deposit.acceptAgreement.declineButton}
                  onClick={declineOnClick}
                  size="md"
                  cta={intl.formatMessage({
                    defaultMessage: "Decline",
                    description: "User does not accept terms and conditions",
                  })}
                />
                <Button.Primary
                  data-testid={testIds.deposit.acceptAgreement.acceptButton}
                  onClick={acceptOnClick}
                  size="md"
                  cta={intl.formatMessage({ defaultMessage: "Accept terms & continue" })}
                />
              </AgreementButtonContainer>
            )}
          </React.Fragment>
        )}
      </ScrollContainer>
    </React.Fragment>
  );
};

export default Agreement;
