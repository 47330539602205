import { TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import { OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { RetailTradePaymentMethodType } from "@gemini-ui/transformers/PaymentMethods";

export const isRetailTradePaymentMethodType = (paymentMethod: any): paymentMethod is RetailTradePaymentMethodType => {
  return Boolean((paymentMethod as RetailTradePaymentMethodType)?.paymentMethodType);
};

export const isInstantOrderQuote = (quote: OrderQuote | TransferQuote): quote is OrderQuote => {
  return Boolean((quote as OrderQuote)?.quote) && Boolean((quote as OrderQuote)?.quote?.formData);
};

export const isTransferQuote = (quote: OrderQuote | TransferQuote): quote is TransferQuote => {
  return (
    Boolean((quote as TransferQuote)?.amount) &&
    Boolean((quote as TransferQuote)?.notionalAmount) &&
    Boolean((quote as TransferQuote)?.annualInterestYieldTiers)
  );
};

export const isInstantOrderQuoteError = (
  error: string | OrderQuote["quoteError"]
): error is OrderQuote["quoteError"] => {
  return Boolean((error as OrderQuote["quoteError"])?.error) && Boolean((error as OrderQuote["quoteError"])?.data);
};
