import { EVENTS, track } from "@gemini-ui/analytics";
import { LinkPaymentAnalyticsType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";

export const trackPaymentRegistrationFailure = (linkPaymentType: LinkPaymentAnalyticsType, error?: string) => {
  const { PAYMENT_METHOD_REGISTRATION_FAILURE } = EVENTS;
  const { name, properties } = PAYMENT_METHOD_REGISTRATION_FAILURE;
  track(name, {
    [properties.PAYMENT_METHOD_TYPE]: linkPaymentType,
    [properties.ERROR]: error,
  });
};
