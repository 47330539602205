import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import ReactModal from "react-modal";
import { border, Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { SystemAlertWrapper } from "@gemini-ui/design-system/SystemAlert/styles";
import { getColor } from "@gemini-ui/design-system/utils/themeUtils";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

const MODAL_WIDTH = "496px";

export const StyledModal = styled(ReactModal)<{ multiStep: boolean }>`
  position: relative;
  margin: auto;
  display: flex;
  text-align: start;
  outline: none;

  ${props =>
    props.multiStep &&
    `
    margin-top: 5%;
    @media ${BREAKPOINTS.mobileDown} {
      height: 100%;
      padding-top: ${Spacing.scale[2]};
    }
  `}
  @media ${BREAKPOINTS.mobileDown} {
    width: 100%;
    position: sticky;
    margin-bottom: 0;
  }
`;

export const ModalLayoutWrapper = styled.div<{ multiStep: boolean; hasDropdown: boolean; centered: boolean }>`
  position: relative;
  width: 100%;
  min-width: ${MODAL_WIDTH};
  max-width: ${MODAL_WIDTH};
  margin: auto;
  padding: ${Spacing.scale[3]};
  padding-bottom: ${Spacing.scale[3]};
  background-color: ${getColor(Colors.white, Colors.gray[900])};
  border-radius: ${border.radius.lg};

  ${props =>
    props.multiStep &&
    `
    margin-bottom: ${Spacing.scale[8]};
    overflow-y: ${props.hasDropdown ? "visible" : "auto"};
    @media ${BREAKPOINTS.mobileDown} {
      max-height: none;
      overflow-y: auto;
      height: 100%;
      margin-bottom: 0;
    }
  `}
  ${props => props.centered && `text-align: center;`}
  @media ${BREAKPOINTS.mobileDown} {
    min-width: auto;
    max-width: none;
    position: relative;
    border-radius: ${Spacing.scale[1]} ${Spacing.scale[1]} 0 0;
  }
`;

export const globalModalStyles = (theme: Theme) => css`
  .ModalOverlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    text-align: center;
    overflow: auto;
    z-index: 9999;
    background-color: ${theme.colorScheme.elevated.background.overlay};
    opacity: 1;
    overscroll-behavior: contain;
  }

  .ReactModal__Body--open {
    overflow: hidden;

    /* trick to put alert message at top of screen when modal is open */
    & .MainLayout {
      .HeaderWrapper {
        z-index: unset;
        position: unset;
      }

      /* only make nav fixed when modal open so GlobalAlert can usually stack under header */
      & nav {
        z-index: 101;
        position: fixed;
      }

      ${SystemAlertWrapper} {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 10000; /* higher than ReactModal to show Alert over modal overlay */
      }
    }
  }
`;
