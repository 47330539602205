import styled from "@emotion/styled";
import { PaddingType } from "@gemini-ui/design-system/cards/Card/constants";
import { ColumnWidths } from "@gemini-ui/design-system/cards/CardMedia/constants";

export const COLUMN_WIDTHS = {
  // https://css-tricks.com/equal-width-columns-in-css-grid-are-kinda-weird/
  equal: "1fr 1fr",
  minMax: "min-content 1fr",
  maxMin: "1fr min-content",
};

export const CardMediaGrid = styled.div<{
  columnWidths?: ColumnWidths;
  hasTwoColumns?: boolean;
  padding?: PaddingType;
}>`
  display: grid;
  grid-template-columns: ${({ columnWidths, hasTwoColumns }) => (hasTwoColumns ? COLUMN_WIDTHS[columnWidths] : "1fr")};
`;
