import styled from "@emotion/styled";
import { Markdown } from "@gemini-ui/components/Markdown";
import { Button, Colors, Flex, Spacing } from "@gemini-ui/design-system";
import OneMoreStepIcon from "@gemini-ui/images/Earn/earn-one-more-step.svg";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

export const AgreementNumber = styled("div")`
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${Colors.gray[400]};
  margin-bottom: ${Spacing.scale[1]};
`;

export const HelperText = styled("div")`
  margin-top: ${Spacing.scale[3]};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: normal;
`;

export const ScrollContainer = styled("div")`
  min-height: 20vh;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.4;

  @media ${BREAKPOINTS.mobileUpLarge} {
    max-height: 400px;
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    padding-bottom: ${Spacing.scale[5]};
  }
`;

export const StyledMarkdown = styled(Markdown)`
  height: 100%;
  padding-right: ${Spacing.scale[1]};

  ul > li {
    list-style-type: disc;
    margin: ${Spacing.scale[0.5]} 0 ${Spacing.scale[0.5]} ${Spacing.scale[3]};
  }

  ul > li > ul > li {
    list-style-type: circle;
  }
`;

export const AgreementButtonContainer = styled(Button.Group)`
  position: static;
  margin-bottom: ${Spacing.scale[4]};
`;

export const SummaryHeadingContainer = styled("div")`
  text-align: center;
`;

export const SummaryRowsContainer = styled("div")`
  display: grid;
  grid-gap: ${Spacing.scale[3]};
  align-items: start;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
`;

export const SummaryItemRow = styled("div")`
  display: grid;
  grid-template-columns: ${Spacing.scale[3]} auto;
  align-items: start;
  grid-gap: ${Spacing.scale[2]};
`;

export const SummaryIcon = styled("div")`
  justify-self: center;
`;

export const SummaryImage = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 35vh;
  max-height: 225px;
  background-image: url(${OneMoreStepIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
