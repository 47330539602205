import React from "react";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Text } from "@gemini-ui/design-system";
import { TextLinkProps } from "@gemini-ui/design-system/Text";
import { IntlShape, useIntl } from "@gemini-ui/utils/intl";

export enum Articles {
  AUTHY_OVERVIEW = "AUTHY_OVERVIEW",
  PHONE = "PHONE",
  DOB = "DOB",
  ACCOUNT_VERIFICATION = "ACCOUNT_VERIFICATION",
  ADDRESS = "ADDRESS",
  ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT",
  ADD_XFERS_ACCOUNT = "ADD_XFERS_ACCOUNT",
  ADD_DEBIT_CARD = "ADD_DEBIT_CARD",
  WIRE_INSTRUCTIONS = "WIRE_INSTRUCTIONS",
  WITHDRAW = "WITHDRAW",
  ACCOUNT_ROLES = "ACCOUNT_ROLES",
  CANNOT_WITHDRAW = "CANNOT_WITHDRAW",
  WRAPPED_EFIL = "WRAPPED_EFIL",
  DIEM_FAQ = "DIEM_FAQ",
  INSTANT_TRADE = "INSTANT_TRADE",
  TRANSFER_EARN = "TRANSFER_EARN",
  KNC = "KNC",
  MULTI_DEVICE = "MULTI_DEVICE",
  TWO_FACTOR_AUTH = "TWO_FACTOR_AUTH",
  SECURITY_KEY = "SECURITY_KEY",
  ADD_USERS = "ADD_USERS",
  ATTEST_ADDRESS = "ATTEST_ADDRESS",
  ADD_ADDRESS = "ADD_ADDRESS",
  GEMINI_PAY_FLEXA = "GEMINI_PAY_FLEXA",
  SUPPORTED_NETWORKS = "SUPPORTED_NETWORKS",
  SOL_FAQ = "SOL_FAQ",
  PENDING_EARN_REDEMPTION = "PENDING_EARN_REDEMPTION",
  WRONG_EMAIL = "WRONG_EMAIL",
  WHAT_IS_EARN = "WHAT_IS_EARN",
  CREDIT_CARD_REWARDS_ADJUSTMENT = "CREDIT_CARD_REWARDS_ADJUSTMENT",
  MY_PORTFOLIO = "MY_PORTFOLIO",
  PENDING_UNSTAKING = "PENDING_UNSTAKING",
  WHAT_IS_STAKING = "WHAT_IS_STAKING",
  EARN_RISKS = "EARN_RISKS",
  EARN_HOW_TO_REDEEM = "EARN_HOW_TO_REDEEM",
  EARN_WHY_DO_RATES_CHANGE = "EARN_WHY_DO_RATES_CHANGE",
  EARN_INSURANCE = "EARN_INSURANCE",
  ATOM_MEMO = "ATOM_MEMO",
  WHAT_IS_APPROVED_ADDRESS = "WHAT_IS_APPROVED_ADDRESS",
  SINGAPORE_OUTBOUND_TRANSFERS = "SINGAPORE_OUTBOUND_TRANSFERS",
  XRP_TAG = "XRP_TAG",
  GUSD_NY = "GUSD_NY",
  XFERS_INSTRUCTIONS = "XFERS_INSTRUCTIONS",
}

export const ARTICLE_ID_MAP: Record<Articles, string> = {
  AUTHY_OVERVIEW: "360038849612",
  PHONE: "360038849552",
  DOB: "360039244511",
  ACCOUNT_VERIFICATION: "360001318986",
  ADDRESS: "360040141572",
  ADD_BANK_ACCOUNT: "115005752603",
  ADD_XFERS_ACCOUNT: "360053638291",
  ADD_DEBIT_CARD: "360057304011",
  WIRE_INSTRUCTIONS: "360006973312",
  WITHDRAW: "360045067751",
  ACCOUNT_ROLES: "360007016151",
  CANNOT_WITHDRAW: "115005752503",
  WRAPPED_EFIL: "360058466112",
  DIEM_FAQ: "4402888274331",
  INSTANT_TRADE: "360051355011",
  TRANSFER_EARN: "4408366750619",
  KNC: "4406412202139",
  MULTI_DEVICE: "115000053543",
  TWO_FACTOR_AUTH: "204732985",
  SECURITY_KEY: "360044275792",
  ADD_USERS: "115004709806",
  ATTEST_ADDRESS: "4416100550939",
  ADD_ADDRESS: "360027564891",
  GEMINI_PAY_FLEXA: "360039779012",
  SUPPORTED_NETWORKS: "4408420583835",
  SOL_FAQ: "4428489222171",
  PENDING_EARN_REDEMPTION: "4417509547931",
  WRONG_EMAIL: "115004709886",
  WHAT_IS_EARN: "360055836212",
  CREDIT_CARD_REWARDS_ADJUSTMENT: "4407607466907",
  MY_PORTFOLIO: "7270336171803",
  PENDING_UNSTAKING: "7818468550683",
  WHAT_IS_STAKING: "7329954040731",
  EARN_RISKS: "360056365391",
  EARN_HOW_TO_REDEEM: "360056280751",
  EARN_WHY_DO_RATES_CHANGE: "360058919092",
  EARN_INSURANCE: "360056367771",
  ATOM_MEMO: "11234768962459",
  WHAT_IS_APPROVED_ADDRESS: "360001411543",
  SINGAPORE_OUTBOUND_TRANSFERS: "18584201669787",
  XRP_TAG: "17683300509851",
  GUSD_NY: "19867557059483",
  XFERS_INSTRUCTIONS: "360053638291",
};

export enum Categories {
  CREDIT_CARD = "CREDIT_CARD",
}

const CATEGORY_ID_MAP: Record<Categories, string> = {
  CREDIT_CARD: "1260802420830",
};

export enum Sections {
  EARN = "EARN",
}

const SECTION_ID_MAP: Record<Sections, string> = {
  EARN: "360012099072",
};

type HelpCenterLinkPropsType = {
  article?: Articles;
  category?: Categories;
  section?: Sections;
  className?: string;
} & Omit<TextLinkProps, "href" | "to">;

interface LocalizedSupportUrlProps {
  article?: Articles;
  category?: Categories;
  section?: Sections;
  intl: IntlShape;
}

const BASE_URL = "https://support.gemini.com/hc";

export const generateHelpCenterUrl = ({ article, category, section, intl }: LocalizedSupportUrlProps) => {
  const locale = intl.locale.toLocaleLowerCase();
  return Boolean(article)
    ? `${BASE_URL}/${locale}/articles/${ARTICLE_ID_MAP[article]}`
    : Boolean(category)
    ? `${BASE_URL}/${locale}/categories/${CATEGORY_ID_MAP[category]}`
    : Boolean(section)
    ? `${BASE_URL}/${locale}/sections/${SECTION_ID_MAP[section]}`
    : `${BASE_URL}/${locale}/requests/new`;
};

const trackOpenSupportLink = (href: string) => {
  track(EVENTS.OPEN_SUPPORT_LINK.name, {
    [EVENTS.OPEN_SUPPORT_LINK.properties.SUPPORT_URL]: href,
  });
};

export const HelpCenterLink: React.FC<React.PropsWithChildren<HelpCenterLinkPropsType>> = ({
  article,
  category,
  section,
  children,
  className,
  target = "_blank",
  rel = "noopener noreferrer",
  ...rest
}) => {
  const { intl } = useIntl();
  const href = generateHelpCenterUrl({ article, category, section, intl });

  return (
    <Text.Link
      className={className}
      target={target}
      rel={rel}
      {...rest}
      href={href}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        trackOpenSupportLink(href);
      }}
    >
      {children}
    </Text.Link>
  );
};
