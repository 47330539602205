/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import _ from "lodash";
import { Column, Columns } from "@gemini-ui/components/Table/constants";
import ExpandableRow from "@gemini-ui/components/Table/ExpandableRow";
import { TableRow } from "@gemini-ui/components/Table/styles";
import { getDataTestId } from "@gemini-ui/components/Table/utils";
import { Text } from "@gemini-ui/design-system";
interface Props {
  columns: Columns;
  data: Record<string, any>;
  id: string | number;
  renderContext?: Record<string, any>;
  isFirst: boolean;
  isLast: boolean;
  collapsedRowRenderer?: Function;
  collapse?: boolean;
  customHeader?: boolean;
  colSpan?: number;
  isRetailTrade?: boolean;
  styledRow?: typeof TableRow;
  onClick?: any;
  overrideFontSize?: string;
}

interface ColumnProps {
  index: number;
  column: Column;
  overrideFontSize?: string;
}

class Row extends React.Component<Props> {
  static defaultProps = {
    renderContext: {},
  };

  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(_.omit(this.props, "renderContext"), _.omit(nextProps, "renderContext"));
  }

  renderColumn(columnProps: ColumnProps, withTd = true) {
    const { column, index, overrideFontSize } = columnProps;
    const { data, renderContext, id, isFirst, isLast } = this.props;
    const content =
      typeof column.renderer === "function"
        ? column.renderer({ data, context: renderContext, isFirst, isLast })
        : data[column.renderer];

    const overrideProps = {
      ...(overrideFontSize && { fontSize: overrideFontSize }),
    };

    return withTd ? (
      <td
        key={`${id}.${index}`}
        align={column.align}
        style={column.styles}
        className={column.className}
        data-testid={column.dataTestId || getDataTestId(column)}
      >
        <Text.Body as="span" {...overrideProps} size="xs">
          {content}
        </Text.Body>
      </td>
    ) : (
      <Text.Body as="span" size="xs">
        {content}
      </Text.Body>
    );
  }

  render() {
    const {
      columns,
      collapsedRowRenderer,
      collapse,
      renderContext,
      data,
      customHeader,
      isRetailTrade,
      styledRow,
      colSpan = 2,
      onClick,
      overrideFontSize,
    } = this.props;

    const StyledRow = styledRow || TableRow;

    if (collapse) {
      const header = collapsedRowRenderer ? collapsedRowRenderer({ data, context: renderContext }) : null;
      const content = (
        <td colSpan={colSpan}>
          <table css={{ width: "100%", tr: { border: 0 } }}>
            <tbody>
              {columns.map((column, i) => {
                const col = this.renderColumn({ column, index: i, overrideFontSize }, false);
                if (!col || !column.label) return;
                return (
                  <TableRow data={data} key={i}>
                    <td align="left">{column.label}</td>
                    <td align="right">{col}</td>
                  </TableRow>
                );
              })}
            </tbody>
          </table>
        </td>
      );

      if (isRetailTrade) {
        return header;
      }

      return (
        <ExpandableRow
          content={content}
          header={header}
          customHeader={customHeader}
          StyledRow={StyledRow}
          data={data}
        />
      );
    }
    const clickHandler = onClick
      ? {
          style: { cursor: "pointer" },
          onClick,
        }
      : {};

    return (
      <StyledRow {...clickHandler} data={data}>
        {columns.map((column, i) => this.renderColumn({ column, index: i, overrideFontSize }))}
      </StyledRow>
    );
  }
}

export default Row;
