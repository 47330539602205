import React from "react";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import HeaderMenuLink from "@gemini-ui/components/Header/HeaderMenuLink";
import { LinkName } from "@gemini-ui/components/Header/HeaderMenuLink/constants";
import { ItemLink, StyledListItem } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenu/styles";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { CC_PATH } from "@gemini-ui/pages/Credit/utils/constants";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  pageName: PageName;
  isMobile?: boolean;
}

const CardLink = ({ pageName, isMobile }: Props) => {
  const { intl } = useIntl();

  const { GeminiCreditCardEnabled } = useFeatureFlags();

  if (!GeminiCreditCardEnabled) return null;
  return isMobile ? (
    <StyledListItem>
      <ItemLink active={pageName === PageName.CreditDashboard} href={CC_PATH.DASHBOARD} big>
        {intl.formatMessage({
          defaultMessage: "Card",
        })}
      </ItemLink>
    </StyledListItem>
  ) : (
    <HeaderMenuLink
      data-testid="HeaderMenuLink"
      linkName={LinkName.CARD}
      name={intl.formatMessage({
        defaultMessage: "Card",
      })}
      href={CC_PATH.DASHBOARD}
      active={pageName === PageName.CreditDashboard}
      hideMobile
    />
  );
};

export default CardLink;
