import { uniq } from "lodash";
import { DepositModalView } from "@gemini-ui/pages/Earn/Deposit/types";
import { GrowBuyAction, GrowBuyActionType } from "@gemini-ui/pages/Earn/GrowBuy/context/actions";
import { defaultState } from "@gemini-ui/pages/Earn/GrowBuy/context/constants";
import { GrowBuyState, ModalStep } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

const oneTimeScreens: ModalStep[] = [
  DepositModalView.SELECT_PAYMENT_METHOD,
  DepositModalView.ADD_PAYMENT_METHOD,
  DepositModalView.ASSET_DISCLAIMER,
  DepositModalView.STAKING_AGREEMENT,
];

export const routerReducer = (state: GrowBuyState, action: GrowBuyAction): GrowBuyState => {
  switch (action.type) {
    case GrowBuyActionType.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case GrowBuyActionType.CLOSE_MODAL:
      return {
        ...defaultState,
      };
    case GrowBuyActionType.SEGUE_MODAL_VIEW:
      // Remove one-time screens from the view stack as we progress through the modal flow.
      const filteredViewStack = uniq(state.router.viewStack).filter(view => !oneTimeScreens.includes(view));

      return {
        ...state,
        router: {
          ...state.router,
          currentView: action.value,
          viewStack: [...filteredViewStack, action.value],
        },
      };
    case GrowBuyActionType.POP_MODAL_VIEW:
      const prevIndex = Math.max(state.router.viewStack.length - 2, 0);
      const prevView = state.router.viewStack[prevIndex];

      // If there is no previous view in the stack, then we can't pop.
      if (!prevView) return state;

      const nextViewStack = state.router.viewStack.slice(0, -1);
      const nextView = nextViewStack[nextViewStack.length - 1];

      return {
        ...state,
        router: {
          ...state.router,
          currentView: nextView,
          viewStack: nextViewStack,
        },
      };
    default:
      return state;
  }
};
