type SizeOption = "sm" | "md" | "lg";
interface INPUT_SIZES_TYPES {
  large: SizeOption;
  small: SizeOption;
  medium: SizeOption;
}

export const INPUT_SIZES: INPUT_SIZES_TYPES = {
  large: "lg",
  medium: "md",
  small: "sm",
};
