import React, { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { FormikActions } from "formik";
import { PlaceDepositInfo } from "@gemini-ui/pages/Earn/Deposit/types";

interface Props {
  value: string;
  setFieldValue: FormikActions<PlaceDepositInfo>["setFieldValue"];
  handleChange: (e) => void;
}

const ProviderInput = ({ value, setFieldValue, handleChange }: Props) => {
  useEffect(() => {
    const providerInput = document.getElementsByName("providerId")[0];
    const handler = e => {
      setFieldValue("providerId", e.target.value);
    };

    providerInput.addEventListener("input", handler);

    return () => {
      providerInput.removeEventListener("input", handler);
    };
  }, [setFieldValue]);

  // Allows QA to change the providerId through a DOM query
  return <input type="hidden" name="providerId" value={value} onChange={handleChange} />;
};

export default ProviderInput;
