import React from "react";
import styled from "@emotion/styled";
import { Flex } from "@gemini-ui/design-system/Flex";
import { PageControlIndicatorsProps } from "@gemini-ui/design-system/PageControl/constants";
import { border, Spacing } from "@gemini-ui/design-system/primitives";

const PageControlIndicator = styled.div<{ active: boolean }>`
  border-radius: ${border.radius.full};
  width: ${Spacing.scale[1]};
  height: ${Spacing.scale[1]};
  background: ${({ theme, active }) =>
    active ? theme.colorScheme.progress.foreground : theme.colorScheme.progress.background};
  margin-left: ${Spacing.scale[1]};

  &:first-of-type {
    margin-left: 0;
  }
`;

export const PageControlIndicators = ({ totalSteps, currentStep, p, ...props }: PageControlIndicatorsProps) => {
  return (
    <Flex display="inline-flex" p={p ?? 1.5} {...props}>
      {[...new Array(totalSteps)].map((_, i) => (
        <PageControlIndicator key={i} active={currentStep === i} data-testid="progress-indicator" />
      ))}
    </Flex>
  );
};
