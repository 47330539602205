import React from "react";
import GlobalAlert from "@gemini-ui/components/GlobalAlert";
import { SignedOutHeader } from "@gemini-ui/components/Header/SignedOutHeader";
import { MainLayoutProps } from "@gemini-ui/components/layouts/MainLayout";

// don't use this directly - use PublicLayout
const PublicLayoutSignedOut = ({ children, red, green, yellow, error }: MainLayoutProps) => {
  return (
    <div data-testid="PublicLayoutSignedOut">
      <SignedOutHeader centerLogo showAccountButtons={false} showBackToGeminiLink={false} />
      <GlobalAlert green={green} red={red || error} yellow={yellow} />
      {children}
    </div>
  );
};

export default PublicLayoutSignedOut;
