import React from "react";
import { Button, Modal, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

type DuplicateAccountProps = {
  onClose: () => void;
  subTitleText: string;
};

export const DuplicateAccountModal = ({ onClose, subTitleText }: DuplicateAccountProps) => {
  const { intl } = useIntl();

  return (
    <Modal
      isOpen
      title={intl.formatMessage({
        defaultMessage: "Bank account is already added to your account",
      })}
    >
      <Text.Body mb={6}>{subTitleText}</Text.Body>
      <Button.Group>
        <Button.Primary data-testid="duplicate-account-got-it" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: "Got it",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};
