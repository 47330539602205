import React from "react";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import { CreatePasskeyDialogProps } from "@gemini-ui/pages/CreatePasskey/index";

const CreatePasskeyDialog = LazyModalWrapper(() => import("./index"));

function LazyLoadCreatePasskeyDialog(props: CreatePasskeyDialogProps) {
  return <CreatePasskeyDialog {...props} />;
}

export default LazyLoadCreatePasskeyDialog;
