import React from "react";
import { Button, Modal, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type PaymentMethodSuccessModalProps = {
  onClose: () => void;
};

export const PaymentMethodSuccessModal = ({ onClose }: PaymentMethodSuccessModalProps) => {
  const { intl } = useIntl();

  return (
    <Modal
      isOpen
      title={intl.formatMessage({
        defaultMessage: "Payment method added",
      })}
    >
      <Text.Body mb={6}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Start trading now or make a deposit.",
          })
        )}
      </Text.Body>
      <Button.Group>
        <Button.Primary data-testid="giact-payment-method-added-got-it" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: "Got it",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};
