import React from "react";
import { useForm } from "react-hook-form";
import { MonthDayYearMask } from "@gemini-ui/components/DatePicker/utils";
import { Button, Form, Input, Modal, Text } from "@gemini-ui/design-system";
import { FIELD_NAMES, FormValues, NEURO_IDS } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { useFormValidation } from "@gemini-ui/pages/Credit/CreditApplication/v2/hooks";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/v2/styles";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/v2/testIds";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { useIntl } from "@gemini-ui/utils/intl";

interface EditDateOfBirthModalProps {
  onClose: () => void;
  dob: string;
  onSubmit: (values: FormValues) => void;
}

export const EditDateOfBirthModal = ({ onClose, dob, onSubmit }: EditDateOfBirthModalProps) => {
  const { intl } = useIntl();
  const { handleSubmit, register, formState } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: { dob },
  });
  const { errors, isValid, isSubmitting, touchedFields } = formState;
  const validation = useFormValidation();

  return (
    <Modal isOpen onClose={onClose}>
      <ViewTitle>
        <Text.Heading size="md">{intl.formatMessage({ defaultMessage: "Edit your date of birth" })}</Text.Heading>
      </ViewTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register(FIELD_NAMES.dob, validation.dob)}
          mask={MonthDayYearMask}
          data-testid={testIds.input.dateOfBirth}
          label={intl.formatMessage({ defaultMessage: "Date of birth" })}
          placeholder={DateFormats.MonthDayYear.toLowerCase()}
          title={DateFormats.MonthDayYear.toLowerCase()}
          error={touchedFields.dob && errors?.dob?.message}
          data-nid-target={NEURO_IDS.dob}
          inputSize="lg"
          autoComplete="bday"
        />
        <Button.Group>
          <Button.Tertiary data-testid={testIds.button.cancel} onClick={onClose}>
            {intl.formatMessage({ defaultMessage: "Cancel" })}
          </Button.Tertiary>
          <Button.Primary
            data-testid={testIds.button.saveDateOfBirth}
            type="submit"
            loading={isSubmitting}
            disabled={!isValid}
          >
            {intl.formatMessage({ defaultMessage: "Save date of birth" })}
          </Button.Primary>
        </Button.Group>
      </Form>
    </Modal>
  );
};
