export enum DebitCardModalState {
  selectDebitCurrencyVisible = "selectDebitCurrencyVisible",
  selectCountryVisible = "selectCountryVisible",
  addDebitCardVisible = "addDebitCardVisible",
  addBillingAddressVisible = "addBillingAddressVisible",
  addBillingAddressManualVisible = "addBillingAddressManualVisible",
  verifyCardStepOneVisible = "verifyCardStepOneVisible",
  transactionsSentVisible = "transactionsSentVisible",
  verifyCardStepTwoVisible = "verifyCardStepTwoVisible",
  tooManyAttemptsVisible = "tooManyAttemptsVisible",
  cardDeclinedVisible = "cardDeclinedVisible",
  showDebitCardSuccessModal = "showDebitCardSuccessModal",
  selectExchangeAccountVisible = "selectExchangeAccountVisible",
  showChallengeFormIframe = "showChallengeFormIframe",
}
