import React, { BaseSyntheticEvent, useCallback, useLayoutEffect, useRef, useState } from "react";
import { ColumnDef, flexRender, Row, Table } from "@tanstack/react-table";
import { getCellStyles, LoadingState, SIZE_MAP, TBody, TCell, TRow } from "@gemini-ui/design-system/Table/styles";

type RowData = {
  loading?: boolean;
};

interface TableBodyProps<TData extends RowData> {
  rowSize?: keyof typeof SIZE_MAP;
  userColDefsById: Record<string, ColumnDef<any>>;
  table: Table<TData>;
  loading?: boolean;
  visibleColumnCount: number;
  scrollRef?: React.MutableRefObject<any>;
}

export function TableBodyRegular<TData extends RowData>({
  rowSize,
  userColDefsById,
  table,
  loading,
  visibleColumnCount,
  scrollRef,
}: TableBodyProps<TData>) {
  return (
    <TBody ref={scrollRef}>
      {table.getRowModel().rows.map(row => (
        <TableRow
          key={row.id}
          row={row as any}
          rowSize={rowSize}
          loading={loading}
          userColDefsById={userColDefsById}
          visibleColumnCount={visibleColumnCount}
        />
      ))}
    </TBody>
  );
}

interface TableRowProps<TData extends RowData> {
  rowSize?: keyof typeof SIZE_MAP;
  userColDefsById: Record<string, ColumnDef<any>>;
  row: Row<TData>;
  loading?: boolean;
  visibleColumnCount: number;
}

const TableRow = React.memo(function TableRow<TData extends RowData>({
  rowSize,
  userColDefsById,
  row,
  loading,
  visibleColumnCount,
}: TableRowProps<TData>) {
  return (
    <TRow aria-hidden={loading}>
      {row.getVisibleCells().map(({ column, id, getContext }, i) => {
        return (
          <TCell
            key={id}
            justifyContent={column.columnDef.align}
            size={rowSize}
            style={getCellStyles(column, userColDefsById[column.id])}
            data-column-id={column.id}
            data-testid={`table-cell-${id}`}
          >
            {loading || row.original.loading ? (
              <LoadingState size={rowSize} variant={column.columnDef.loader} justifyContent={column.columnDef.align} />
            ) : (
              flexRender(column.columnDef.cell, getContext())
            )}
          </TCell>
        );
      })}
    </TRow>
  );
});

const onScroll = (
  e: BaseSyntheticEvent,
  rowHeight: number,
  from: number,
  setFrom: (from: number) => void,
  rowBuffer: number
) => {
  const newPos = Math.trunc(e.target.scrollTop / rowHeight);
  const delta = Math.abs(from - newPos);
  if (delta >= rowBuffer) {
    // throttle redraws
    if (e.target.renderTaskId) {
      cancelAnimationFrame(e.target.renderTaskId);
    }
    e.target.renderTaskId = requestAnimationFrame(() => setFrom(newPos));
  }
};

const rowBuffer = 12;

export function TableBodyWindowed<TData extends RowData>({
  rowSize,
  userColDefsById,
  table,
  loading,
  visibleColumnCount,
  scrollRef,
}: TableBodyProps<TData>) {
  const ref = useRef<HTMLElement | null>();
  const [height, setHeight] = useState(0);
  const rows = table.getRowModel().rows;
  const rowHeight = rowSize === "sm" ? 24 : rowSize === "md" ? 52 : 84;
  const [from, setFrom] = useState(0);
  const start = Math.max(from - rowBuffer, 0);
  const end = Math.min(from + Math.trunc(height / rowHeight) + rowBuffer, rows.length);
  const tHeight = rowHeight * start;
  const bHeight = rowHeight * (rows.length - end);
  const visibleRows = rows.slice(start, end);
  const columnCount = Object.keys(userColDefsById).length;

  useLayoutEffect(() => {
    const heightObserver = new ResizeObserver(entries => {
      const tableEl = entries[0]?.target;
      if (tableEl) {
        const height = tableEl.parentElement.clientHeight - (tableEl.previousSibling as HTMLElement)?.clientHeight;
        setHeight(height);
      }
    });

    heightObserver.observe(ref.current);

    return () => {
      if (ref.current) {
        heightObserver.unobserve(ref.current);
      } else {
        heightObserver.disconnect();
      }
    };
  }, []);

  const refSet = useCallback(
    (el: HTMLElement) => {
      if (scrollRef) {
        scrollRef.current = el;
      }
      ref.current = el;
    },
    [scrollRef]
  );

  return (
    <TBody ref={refSet} onScroll={e => onScroll(e, rowHeight, from, setFrom, rowBuffer)}>
      {tHeight > 0 && (
        <tr>
          <td colSpan={columnCount} style={{ height: tHeight }}></td>
        </tr>
      )}
      {visibleRows.map(row => (
        <TableRow
          key={row.id}
          row={row as any}
          rowSize={rowSize}
          loading={loading}
          userColDefsById={userColDefsById}
          visibleColumnCount={visibleColumnCount}
        />
      ))}
      {bHeight > 0 && (
        <tr>
          <td colSpan={columnCount} style={{ height: bHeight }}></td>
        </tr>
      )}
    </TBody>
  );
}
