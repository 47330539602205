import React, { useState } from "react";
import { EVENTS, track } from "@gemini-ui/analytics";
import AccountList from "@gemini-ui/components/Header/PerpOnboardingModal/Views/AccountTransfer/AccountList";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Dialog, Flex, List, SearchInput, SectionMessage } from "@gemini-ui/design-system";
import { getCopy, ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { useIntl } from "@gemini-ui/utils/intl";

interface SelectExchangeModalProps {
  subaccounts: Subaccount[];
  setSelectedSubaccountHashid: (hashid: string) => void;
  onOpen: boolean;
  onClose: () => void;
  onToggle: ToggleDebitCardModal;
  onBack: () => void;
}

const SelectExchangeModal = ({
  subaccounts: initialSubaccounts,
  onToggle,
  setSelectedSubaccountHashid,
  onOpen,
  onClose,
  onBack,
}: SelectExchangeModalProps) => {
  const { intl } = useIntl();
  const copy = getCopy(intl);
  const [searchValue, setSearchValue] = useState("");
  const [subaccounts, setSubaccounts] = useState<Subaccount[]>(initialSubaccounts);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = initialSubaccounts.filter(account => account.name.toLowerCase().includes(value.toLowerCase()));
    setSubaccounts(filtered);
  };

  const handleSelectAccount = (account: Subaccount) => {
    track(EVENTS.SELECT_EXCHANGE_ACCOUNT.name);
    setSelectedSubaccountHashid(account.hashid);
    onToggle("addBillingAddressManualVisible", false, true)();
  };

  return (
    <Flex.Column>
      <Dialog open={onOpen} onClose={onClose} onBack={onBack}>
        <Dialog.Container onInteractOutside={e => e.preventDefault()}>
          <Dialog.Header>
            <Dialog.Title size="sm">{copy.selectExchangeAccount}</Dialog.Title>
          </Dialog.Header>
          <Dialog.Content>
            <React.Fragment>
              <SectionMessage
                statusType="info"
                mb={2}
                heading={copy.addDebitCardToSpotMsg}
                children={copy.addDebitCardToSpotMsgDescription}
              />
              <SearchInput
                data-testid="exchange-search-input"
                className="search"
                name="spot-exchange-search"
                variant="filled"
                inputSize="sm"
                placeholder={copy.searchInputPlaceholder}
                value={searchValue}
                onChange={handleSearchInputChange}
                autoComplete="off"
                width="100%"
              />
              <List>
                <AccountList
                  accounts={subaccounts?.filter(account => !account.derivatives)}
                  heading={copy.exchange}
                  sourceAccountHash={undefined}
                  onAccountClick={handleSelectAccount}
                />
              </List>
            </React.Fragment>
          </Dialog.Content>
        </Dialog.Container>
      </Dialog>
    </Flex.Column>
  );
};

export default SelectExchangeModal;
