/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx } from "@emotion/react";
import { IconMinus, IconPlus } from "@hubble/icons";
import BigNumber from "bignumber.js";
// eslint-disable-next-line no-restricted-imports
import { Form, Formik } from "formik";
import { bigNumberToDecimalString, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { track } from "@gemini-ui/analytics";
import { Money } from "@gemini-ui/components/Money";
import { GrowProviderRestriction, GrowProviderType } from "@gemini-ui/constants/earn";
import { Button, Card, Flex, Input, Spacing } from "@gemini-ui/design-system";
import { GrowBuyHeader } from "@gemini-ui/pages/Earn/components/GrowBuyHeader";
import { UnstakeSourceSelect } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs/UnstakeSourceSelect";
import ProviderInput from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/ProviderInput";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { balanceValueToCurrencyReceiptDecimals, calculateValue, schema } from "@gemini-ui/pages/Earn/Deposit/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { PlaceRedeemInfo, RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import {
  calculateEthValidatorValue,
  calculateValidatorsAvailableToUnstake,
  GrowTrackingEvent,
} from "@gemini-ui/pages/Earn/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props extends PlaceRedeemInfo {
  defaultFiat: CurrencyShortNameFiat;
  lastTradePrice: string;
}

const PlaceRedeem: React.FC<React.PropsWithChildren<Props>> = ({
  defaultFiat,
  lastTradePrice,
  amount,
  provider,
  asset,
  unstakeSource = UnstakeSourceType.pooledBalance,
  validatorCount,
}) => {
  const { currency } = asset;
  const { providerType, id: providerId, restriction, availableBalance, earningInterest } = provider;

  const { intl } = useIntl();
  const { updateRedeemStatus } = useGrowBuy();

  const isValidatorUnstake = unstakeSource === UnstakeSourceType.validatorBalance;

  // TODO: The value should come from the BE. This is a temporary solution.
  // See: https://iceland.slack.com/archives/C03A8QEUQV6/p1683740892648309
  const maxValidatorCount = calculateValidatorsAvailableToUnstake(
    Number(availableBalance?.totalAvailableDepositBalance?.value ?? 0)
  );

  const maxRedeemableBalance =
    providerType === GrowProviderType.PRIVATE_STAKING ? availableBalance?.totalAvailableRewardBalance : earningInterest;

  const isUnstakeDisabled =
    restriction === GrowProviderRestriction.BLOCK_WITHDRAWALS || restriction === GrowProviderRestriction.BLOCK_ORDERS;

  const handleSubmit = useCallback(
    values => {
      const { name, properties } = GrowTrackingEvent.ENTER_WITHDRAW_AMOUNT[providerType] ?? {};
      if (Boolean(name)) track(name, { [properties.CURRENCY]: currency, [properties.AMOUNT]: values.amount });

      updateRedeemStatus({
        amount: values.amount,
        view: RedeemStep.ReviewRedeem,
        validatorCount: values.validatorCount,
      });
    },
    [currency, updateRedeemStatus, providerType]
  );

  const renderForm = ({ values, handleChange, setFieldValue, isSubmitting, touched, errors, handleReset }) => {
    const redeemApproxValue = calculateValue({
      value: values.amount,
      lastTradePrice,
    });

    const amountChangeHandler = e => {
      handleChange(e);
      if (isValidatorUnstake) {
        const newVal = Number(e.target.value);
        setFieldValue("validatorCount", newVal);
        setFieldValue("amount", String(calculateEthValidatorValue(newVal)));
      } else {
        setFieldValue("amount", String(e.target.value));
      }
    };

    const setAmountFromBalancePercent = (percent = 1) => {
      const sanitizedBalance = balanceValueToCurrencyReceiptDecimals(maxRedeemableBalance?.value ?? 0, currency);
      const newVal = bigNumberToDecimalString(new BigNumber(Number(sanitizedBalance) * percent), currency);

      setFieldValue("amount", newVal);
    };

    const setAmountFromValidatorStepper = (add: number) => {
      // Don't allow values below 0 or above the max validator count
      const newVal = Math.max(0, Math.min(maxValidatorCount, Number(values.validatorCount) + add));

      setFieldValue("validatorCount", newVal);
      setFieldValue("amount", String(calculateEthValidatorValue(newVal)));
    };

    return (
      <React.Fragment>
        <UnstakeSourceSelect
          unstakeSource={unstakeSource}
          providerType={providerType}
          onChange={source => {
            const newProviderType =
              source === UnstakeSourceType.pooledBalance
                ? GrowProviderType.POOLED_STAKING
                : GrowProviderType.PRIVATE_STAKING;

            updateRedeemStatus({
              unstakeSourceType: source,
              amount: "",
              validatorCount: 0,
              providerType: newProviderType,
            });

            handleReset();
          }}
          providers={asset?.interestProviders}
        />

        <Form css={{ margin: 0 }}>
          <Card variant="filled" padding="sm">
            <Flex flexDirection="column">
              <ProviderInput value={values.providerId} setFieldValue={setFieldValue} handleChange={handleChange} />

              <Input
                data-testid={testIds.redeem.placeRedeem.amountInput}
                name="amount"
                inputSize="lg"
                label={intl.formatMessage(
                  defineMessage({
                    defaultMessage: "Enter {isValidatorUnstake, select, true {validator} other {{currency}}} amount",
                  }),
                  {
                    isValidatorUnstake,
                    currency: currency,
                  }
                )}
                mb={2}
                placeholder="0"
                rightElement={isValidatorUnstake ? intl.formatMessage({ defaultMessage: "Validators" }) : currency}
                type="number"
                onChange={amountChangeHandler}
                value={isValidatorUnstake ? values.validatorCount.toString() : values.amount.toString()}
                error={touched.amount && (errors.amount || errors.validatorCount)}
                message={
                  isValidatorUnstake ? (
                    <React.Fragment>
                      <Money currency={currency} value={Number(values.amount ?? 0).toString()} />
                      {` • `}
                      <Money currency={defaultFiat} value={redeemApproxValue} hideTrailingSign />
                    </React.Fragment>
                  ) : (
                    <Money currency={defaultFiat} value={redeemApproxValue} hideTrailingSign />
                  )
                }
              />

              <Flex gap={Spacing.scale[1.5]}>
                {isValidatorUnstake ? (
                  <React.Fragment>
                    <Button.Secondary
                      data-testid={`${testIds.redeem.placeRedeem.amountInput}-decrement-validator-button`}
                      onClick={() => setAmountFromValidatorStepper(-1)}
                      aria-label={intl.formatMessage({ defaultMessage: "Decrement validator count" })}
                      disabled={Number(values.validatorCount) <= 0}
                      icon={<IconMinus />}
                      css={{ flex: 1 }}
                    />
                    <Button.Secondary
                      data-testid={`${testIds.redeem.placeRedeem.amountInput}-increment-validator-button`}
                      onClick={() => setAmountFromValidatorStepper(1)}
                      aria-label={intl.formatMessage({ defaultMessage: "Increment validator count" })}
                      disabled={Number(values.validatorCount) >= maxValidatorCount}
                      icon={<IconPlus />}
                      css={{ flex: 1 }}
                    />
                    <Button.Secondary
                      data-testid={`${testIds.redeem.placeRedeem.amountInput}-max-button`}
                      onClick={() => setAmountFromValidatorStepper(maxValidatorCount)}
                      cta={intl.formatMessage({ defaultMessage: "Unstake max" })}
                      css={{ flex: 1 }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button.Secondary
                      data-testid={`${testIds.redeem.placeRedeem.amountInput}-half-button`}
                      onClick={() => setAmountFromBalancePercent(0.5)}
                      cta={intl.formatMessage({ defaultMessage: "Unstake half" })}
                      css={{ flex: 1 }}
                    />
                    <Button.Secondary
                      data-testid={`${testIds.redeem.placeRedeem.amountInput}-max-button`}
                      onClick={() => setAmountFromBalancePercent(1)}
                      cta={intl.formatMessage({ defaultMessage: "Unstake max" })}
                      css={{ flex: 1 }}
                    />
                  </React.Fragment>
                )}
              </Flex>
            </Flex>
          </Card>
          <Button.Group type="action">
            <Button.Primary
              data-testid={testIds.redeem.placeRedeem.continueButton}
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || isUnstakeDisabled}
              size="md"
              cta={intl.formatMessage({ defaultMessage: "Review unstake" })}
            />
          </Button.Group>
        </Form>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <GrowBuyHeader currency={currency} providerType={providerType} />

      <Formik
        initialValues={{
          amount: amount ?? "",
          providerId: providerId,
          validatorCount: validatorCount ?? 0,
        }}
        onSubmit={handleSubmit}
        render={renderForm}
        validationSchema={schema({
          assetInfo: asset,
          redeemableBalance: maxRedeemableBalance,
          unstakeSource,
          maxValidatorCount,
          isRedeem: true,
          intl,
        })}
      />
    </React.Fragment>
  );
};

export default PlaceRedeem;
