import React from "react";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const DepositCryptoFlow = LazyModalWrapper(() => import("./index"));

function LazyLoadDepositCryptoFlow({ onClose }) {
  return <DepositCryptoFlow onClose={onClose} />;
}

export default LazyLoadDepositCryptoFlow;
