import React from "react";
import { useTheme } from "@emotion/react";
import { IconWarningOutlined } from "@hubble/icons";
import { Button, Card, IconBadge, ListItem, Text } from "@gemini-ui/design-system";
import { CardProps } from "@gemini-ui/design-system/cards/Card/constants";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const NoFundingSourceCard = ({
  onClick,
  transactionType,
}: { transactionType: GrowTransactionType } & CardProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <Card as="button" onClick={onClick} padding="none">
      <ListItem
        padding="sm"
        hasDivider={false}
        left={<IconBadge icon={<IconWarningOutlined />} />}
        right={<Button.Secondary as="div" size="sm" cta={intl.formatMessage({ defaultMessage: "Choose" })} />}
        alignItems="center"
      >
        <Text.Body size="sm" color={colorScheme.content.secondary}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "{transactionType, select, stake {No payment method selected} unstake {No unstake source selected} other {No source selected}}",
            }),
            {
              transactionType: transactionType,
            }
          )}
        </Text.Body>
      </ListItem>
    </Card>
  );
};
