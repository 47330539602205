import React from "react";
import { GrowAsset, GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex } from "@gemini-ui/design-system";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { createUnstakeSourceOptions } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/utils";
import { StakingWithdrawForm } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingWithdrawForm";
import { WithdrawalFormFields } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingWithdrawForm/constants";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { ControlledStakingFundingSourcesScreen } from "@gemini-ui/pages/Earn/GrowBuy/screens/StakingFundingSourcesScreen/ControlledStakingFundingSourcesScreen";
import { useStakingWithdrawalForm, WithdrawalFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingWithdrawalForm";
import ReviewRedeem from "@gemini-ui/pages/Earn/Redeem/ReviewRedeem";
import Success from "@gemini-ui/pages/Earn/Redeem/Success/index";
import { RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { useSinglePairDetails } from "@gemini-ui/services/trading/getSinglePairDetails";
import { useIntl } from "@gemini-ui/utils/intl";

export const StakingWithdrawController = ({
  asset,
  providerType,
  initialFormValues,
}: {
  asset: GrowAsset;
  providerType: GrowProviderType;
  initialFormValues: Partial<WithdrawalFormValues>;
}) => {
  const { intl } = useIntl();

  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const {
    redeemStatus: { redemptionStatus },
    router: { currentView },
    closeModal,
    setModalView,
    goBack,
  } = useGrowBuy();

  const lastTradePrice = useSinglePairDetails(asset.currency + defaultFiat).data?.lastTradePrice;

  const defaultProviderForProviderType = asset.interestProviders.find(p => p.providerType === providerType);
  const unstakeSources = React.useMemo(
    () => createUnstakeSourceOptions(providerType, { intl, provider: defaultProviderForProviderType }),
    [providerType, intl, defaultProviderForProviderType]
  );

  const formController = useStakingWithdrawalForm({
    currency: asset.currency,
    providerType,
    unstakeSourceId: unstakeSources?.[0]?.value,
    ...initialFormValues,
  });
  const { reset, resetField } = formController;
  const formValues = formController.getValues();

  const selectedUnstakeSource = unstakeSources.find(s => s.value === formValues.unstakeSourceId);

  const handleExitReviewWithdrawal = () => {
    goBack();
  };

  const handleWithdrawFormSubmit = (data: WithdrawalFormValues) => {
    setModalView(RedeemStep.ReviewRedeem);
  };

  return (
    <React.Fragment>
      {currentView === RedeemStep.PlaceRedeem && (
        <StakingWithdrawForm
          asset={asset}
          formController={formController}
          onSubmit={handleWithdrawFormSubmit}
          onWithdrawSourceClick={() => setModalView(RedeemStep.SelectRedeemSource)}
          lastTradePrice={lastTradePrice}
          fundingSource={selectedUnstakeSource}
          provider={defaultProviderForProviderType}
        />
      )}

      {currentView === RedeemStep.SelectRedeemSource && (
        <ControlledStakingFundingSourcesScreen
          providerType={providerType}
          transactionType={GrowTransactionType.UNSTAKE}
          name={WithdrawalFormFields.UNSTAKE_SOURCE_ID}
          control={formController.control}
          onBack={goBack}
          options={unstakeSources}
          onDidSelectFundingSource={value => {
            // Reset amount inputs when the redemption source changes
            resetField(WithdrawalFormFields.AMOUNT);
            resetField(WithdrawalFormFields.VALIDATOR_COUNT);
          }}
        />
      )}

      {currentView === RedeemStep.ReviewRedeem && (
        <React.Fragment>
          <Flex mb={2}>
            <Button.Secondary
              size="sm"
              cta={intl.formatMessage({ defaultMessage: "Edit" })}
              onClick={handleExitReviewWithdrawal}
            />
          </Flex>
          <ReviewRedeem
            defaultFiat={defaultFiat}
            lastTradePrice={lastTradePrice}
            asset={asset}
            currency={formValues.currency}
            amount={formValues.amount}
            validatorCount={formValues.validatorCount ? Number(formValues.validatorCount) : null}
            unstakeSource={formValues.unstakeSourceId as UnstakeSourceType}
            provider={defaultProviderForProviderType}
          />
        </React.Fragment>
      )}

      {currentView === RedeemStep.RedeemSuccess && (
        <Success
          redemptionStatus={redemptionStatus}
          currency={formValues.currency}
          amount={formValues.amount}
          provider={defaultProviderForProviderType}
          onDone={() => {
            closeModal(); // TODO: We need to call this for now to reset the view stack b/c it's shared with the modal context
            setModalView(RedeemStep.PlaceRedeem);
            reset();
          }}
        />
      )}
    </React.Fragment>
  );
};
