import { Buffer } from "buffer";

function base64Encode(data: string) {
  return new Buffer(data).toString("base64");
}

function base64Decode(data: string) {
  return Buffer.from(data, "base64").buffer;
}

function base64UrlEncode(data: string) {
  return base64Encode(data).replace(/\//g, "_").replace(/\+/g, "-").replace(/=/g, "");
}

function base64UrlDecode(data: string) {
  const temp = data.replace(/_/g, "/").replace(/\-/g, "+");

  const padded = temp + " ".repeat(4 - (temp.length % 4));
  return base64Decode(padded);
}

export { base64UrlDecode, base64UrlEncode };
