import React, { useState } from "react";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { PaymentMethodIcon } from "@gemini-ui/components/Icons/PaymentMethod";
import { PaymentMethodItem } from "@gemini-ui/components/PaymentMethodItem";
import { PaymentMethodName } from "@gemini-ui/components/PaymentMethodName";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Flex, Toggle } from "@gemini-ui/design-system";
import { BankCardContainer } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/styles";
import {
  handleDisableXfersBank,
  handleEnableXfersBank,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/utils";
import { XfersAccountType } from "@gemini-ui/transformers/PaymentMethods";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  account: XfersAccountType;
  handleChangeBank: (bankAccount: XfersAccountType) => void;
}

export function ToggleBankCard({ account, handleChangeBank }: Props) {
  const { intl } = useIntl();

  const [isBankEnabled, setIsBankEnabled] = useState(account.enabled);
  const { showAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const handleToggleChange = () => {
    const route = isBankEnabled ? handleDisableXfersBank : handleEnableXfersBank;
    setIsLoading(true);
    setIsBankEnabled(!isBankEnabled);

    route(account.id)
      .then(res => {
        handleChangeBank({
          ...account,
          enabled: !account.enabled,
        });
      })
      .catch(err => {
        setIsBankEnabled(account.enabled);
        showAlert({
          type: AlertTypes.ERROR,
          message: getError(err),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { lastFour, displayName: rawName, institutionName, accountType } = account;
  return (
    <BankCardContainer onClick={handleToggleChange} isLoading={isLoading}>
      <Flex alignItems="center">
        <PaymentMethodItem
          displayText={<PaymentMethodName {...{ lastFour, rawName, institutionName, accountType }} />}
          icon={
            <PaymentMethodIcon institutionName={account.institutionName} paymentMethodType={PaymentMethodType.XFERS} />
          }
          infoText={
            isLoading
              ? intl.formatMessage({
                  defaultMessage: "Linking...",
                })
              : isBankEnabled
              ? intl.formatMessage({
                  defaultMessage: "Linked",
                })
              : intl.formatMessage({
                  defaultMessage: "Unlinked",
                })
          }
        />
      </Flex>
      <div>
        <Toggle
          data-testid="xfers-bank-toggle"
          aria-label={`Check to link ${rawName}`}
          checked={isBankEnabled}
          onChange={handleToggleChange}
        />
      </div>
    </BankCardContainer>
  );
}
