import styled from "@emotion/styled";
import { Button, Spacer, Spacing } from "@gemini-ui/design-system";

export const BankDetails = styled(Spacer)`
  display: flex;
  justify-content: space-between;

  > * {
    max-width: 50%;
  }
`;

export const EditButton = styled(Button.Secondary)`
  position: absolute;
  top: ${Spacing.scale[3]};
`;
