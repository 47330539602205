import { DepositModalView } from "@gemini-ui/pages/Earn/Deposit/types";
import { GrowBuyAction, GrowBuyActionType } from "@gemini-ui/pages/Earn/GrowBuy/context/actions";
import { depositStatusReducer } from "@gemini-ui/pages/Earn/GrowBuy/context/reducers/depositReducer";
import { redeemStatusReducer } from "@gemini-ui/pages/Earn/GrowBuy/context/reducers/redeemReducer";
import { routerReducer } from "@gemini-ui/pages/Earn/GrowBuy/context/reducers/routerReducer";
import { GrowBuyState, GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";

export const reducer = (state: GrowBuyState, action: GrowBuyAction): GrowBuyState => {
  switch (action.type) {
    // TODO: This should be simpler to instantiate
    // - required: currency
    // - required: providerType || providerId - or should this be optional to support Staking/Staking Pro?
    // - optional: direction
    case GrowBuyActionType.INSTANTIATE_GROW_TRANSACTION:
      const initialView = Boolean(action.value?.view)
        ? action.value?.view
        : action.value?.transactionType === GrowTransactionType.UNSTAKE
        ? RedeemStep.PlaceRedeem
        : DepositModalView.PLACE_DEPOSIT;

      return {
        ...state,
        ...action.value,
        depositStatus: {
          ...state.depositStatus,
          ...action.value.depositStatus,
        },
        redeemStatus: {
          ...state.redeemStatus,
          ...action.value.redeemStatus,
        },
        router: {
          ...state.router,
          currentView: initialView,
          viewStack: [initialView],
        },
      };
    case GrowBuyActionType.SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.value,
        router: {
          ...state.router,
          viewStack: [
            ...(action.value === GrowTransactionType.STAKE
              ? [DepositModalView.PLACE_DEPOSIT] // TODO: Handle ETH picker here
              : [RedeemStep.PlaceRedeem]),
          ],
        },
      };
    case GrowBuyActionType.SET_CURRENCY:
      return {
        ...state,
        currency: action.value,
      };
    // Deposit state
    case "UPDATE_DEPOSIT_STATUS":
    case "SET_QUOTE":
    case "RESET_QUOTE":
    case "SET_DEPOSIT_ERROR":
    case "SET_PAYMENT_METHOD":
      return depositStatusReducer(state, action);
    // Redeem state
    case "UPDATE_REDEEM_STATUS":
      return redeemStatusReducer(state, action);
    // Modal and Router state
    case "OPEN_MODAL":
    case "CLOSE_MODAL":
    case "SEGUE_MODAL_VIEW":
    case "POP_MODAL_VIEW":
      return routerReducer(state, action);
    default:
      return state;
  }
};
