import { format } from "date-fns";
import _ from "lodash";
import { CurrencyShortName, isCurrency, quantityCurrency } from "@gemini-common/scripts/constants/currencies";
import { TransferType } from "@gemini-ui/constants/custody";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import {
  GrowTransfer,
  GrowTransferType,
  MarketOrder,
  Order,
  OrderUtils,
  RecurringOrder,
} from "@gemini-ui/constants/orders";
import { getDate, getOrderTypeDisplay, getTotal } from "@gemini-ui/pages/ActiveTrader/Spot/MyOrders/utils";
import { TransferTypeEnum } from "@gemini-ui/pages/transfers/constants";

// Transfer type getters
export const TransferUtils = {
  isEarn: (data: Order | GrowTransfer | TransferType) => {
    return Boolean(
      data?.transferType &&
        (data.transferType === GrowTransferType.EARN_REDEEM ||
          data.transferType === GrowTransferType.EARN_ADMIN_REDEEM ||
          data.transferType === GrowTransferType.EARN_DEPOSIT ||
          data.transferType === GrowTransferType.EARN_INTEREST)
    );
  },
  isEarnInterest: (data: Order | GrowTransfer | TransferType) =>
    Boolean(data?.transferType === GrowTransferType.EARN_INTEREST),
  isEarnDeposit: (data: Order | GrowTransfer | TransferType) =>
    Boolean(data?.transferType === GrowTransferType.EARN_DEPOSIT),
  isEarnWithoutInterest: (data: Order) => Boolean(data?.transferType !== GrowTransferType.EARN_INTEREST),
  isEarnRedeem: (data: Order | GrowTransfer | TransferType) => {
    return Boolean(
      data?.transferType === GrowTransferType.EARN_REDEEM || data?.transferType === GrowTransferType.EARN_ADMIN_REDEEM
    );
  },
  isPromotional: (data: Order | GrowTransfer | TransferType) =>
    Boolean(data?.providerType === GrowProviderType.PROMOTIONAL),
  isGUSDPromotional: (data: Order | GrowTransfer | TransferType) =>
    Boolean(data?.providerType === GrowProviderType.PROMOTIONAL) && isCurrency.GUSD(data?.amount?.currency),
};

export const getDateRange = (minDate: number, maxDate: number) => {
  const startDate = format(minDate, "MM/dd/yy");
  const endDate = format(maxDate, "MM/dd/yy");

  return `${startDate} - ${endDate}`;
};

export const createdAt = ({ data }: { data: Order | TransferType }) => {
  const date = getDate(data);

  if (!date) return;

  return date;
};

export const getAmountProps = (data: Order | TransferType) => {
  if (data?.type === "transfer") {
    const transfer = data as TransferType;

    return {
      total: transfer?.amount || transfer?.amountWithFee.amount,
    };
  }

  if (data.type === "order") {
    const order = data as Order;
    // Pending orders
    if (!order?.isInstant) {
      return {
        total: order.totalPrice ?? getTotal(order),
      };
    }

    // Completed orders
    if (order?.side === "buy" || order?.side === "sell" || order?.side === "convert") {
      return {
        total: order.totalPrice,
      };
    }
  }

  // Market Order
  if (data.type === "marketBuy" || data.type === "marketSell") {
    const marketOrder = data as MarketOrder;

    return {
      total: marketOrder.totalSpend,
    };
  }

  // Earn redemption
  if (TransferUtils.isEarnRedeem(data)) {
    const { redeemAmountNotional } = data as GrowTransfer;

    // Don't display a total notional amount if this is an Earn redemption
    if (data.providerType === GrowProviderType.EARN) {
      return {
        total: null,
      };
    }

    return {
      total: redeemAmountNotional,
    };
  }

  // Something else but don't blow up
  return {
    total: null,
  };
};

export const getTotalQuantityProps = (data: Order | TransferType) => {
  if (TransferUtils.isEarn(data)) {
    return {
      total: data.amount,
    };
  }

  if (data.type === "order") {
    const order = data as Order;

    if (order?.quantity) {
      return {
        total: order.quantity,
      };
    }
  }

  if (data.type === "marketBuy" || data.type === "marketSell") {
    const marketOrder = data as MarketOrder;

    return { total: marketOrder.quantityReceived };
  }

  return { total: null };
};

export const filterRecurring = (orderData: RecurringOrder[], currency?: CurrencyShortName) => {
  return orderData.filter(order => !currency || quantityCurrency(order.tradingPair) === currency);
};

export const filterAllTransactions = (data: (Order | TransferType)[], currency?: CurrencyShortName) => {
  return _.filter<Order | TransferType>(data, transaction => {
    // If this table is being filtered by currency (i.e. it's on the AssetDetails page)
    // Only show transactions that involve our currency

    // GUSD promotional transactions will have duplicates: one for the deposit and one for the interest.
    // We only want to show the deposit transaction, so drop any GUSD promotional `earnInterest` records
    if (TransferUtils.isGUSDPromotional(transaction) && transaction?.transferType === GrowTransferType.EARN_INTEREST) {
      return false;
    }
    if (transaction.type !== "transfer") {
      const order = transaction as Order;
      if (
        currency &&
        currency !==
          (order.type === "order"
            ? order?.quantity?.currency
            : order?.quantityReceived?.currency || order.amount?.currency || order?.price?.currency)
      ) {
        return false;
      }

      if (currency && currency === order?.price?.currency) {
        return true;
      }
      if (TransferUtils.isEarn(order) && (!currency || currency === order.amount?.currency)) {
        return true;
      }

      if (getOrderTypeDisplay(order, true) === "Stop Limit") {
        return true;
      }
      if (OrderUtils.isRecurringOrder(order) && order.isInstant) {
        return true;
      }
      if (Boolean(order?.remainingQuantity)) {
        return true;
      }
    }

    return true;
  });
};

export const isTransactionEnabledForNewModalView = (type?: TransferTypeEnum) => {
  if (
    (type === "fiat" ||
      type === "internalexchangetransferin" ||
      type === "internalexchangetransferout" ||
      type?.toLowerCase()?.includes("withdrawal") ||
      type?.toLowerCase()?.includes("deposit")) &&
    type !== "earnDeposit" &&
    type !== "rewardsDeposit"
  ) {
    return true;
  }
  return false;
};

export const getAmountAfterFee = (data: Order | TransferType) => {
  if (data?.type === "transfer") {
    const transfer = data as TransferType;

    return {
      total: transfer?.amountWithFee?.amount || transfer?.amount,
    };
  }

  if (data.type === "order") {
    const order = data as Order;
    // Pending orders
    if (!order?.isInstant) {
      return {
        total: order.totalPrice ?? getTotal(order),
      };
    }

    // Completed orders
    if (order?.side === "buy" || order?.side === "sell" || order?.side === "convert") {
      return {
        total: order.totalPrice,
      };
    }
  }

  // Market Order
  if (data.type === "marketBuy" || data.type === "marketSell") {
    const marketOrder = data as MarketOrder;

    return {
      total: marketOrder.totalSpend,
    };
  }

  // Something else but don't blow up
  return {
    total: null,
  };
};

export const isTransactionNonInstant = (data: TransferType) => {
  const transferInfo = data?.transferInfo;
  if (!transferInfo) return false;
  if (
    transferInfo === "CardDeposit" ||
    transferInfo === "AchDeposit" ||
    transferInfo === "AchWithdrawal" ||
    transferInfo === "PayPalDeposit" ||
    transferInfo === "WireDeposit" ||
    transferInfo === "WireWithdrawal" ||
    transferInfo === "XfersDeposit" ||
    transferInfo === "XfersWithdrawal" ||
    transferInfo === "CbitDeposit" ||
    transferInfo === "CbitWithdrawal"
  ) {
    return true;
  }
  return false;
};
