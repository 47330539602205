import React from "react";
import { ThemeContext } from "@emotion/react";

export const withTheme = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return class WithTheme extends React.Component<Omit<P, "theme">> {
    render() {
      const { ...props } = this.props;
      return (
        <ThemeContext.Consumer>{theme => <WrappedComponent {...(props as P)} theme={theme} />}</ThemeContext.Consumer>
      );
    }
  };
};
