import styled from "@emotion/styled";
import { InputSize } from "@gemini-ui/design-system/forms/shared/constants";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils";

function getSizeStyles(size?: InputSize) {
  if (size === "sm") {
    return `
      font-size: 12px;
      line-height: 16px;
    `;
  }
  if (size === "md") {
    return `
      font-size: 14px;
      line-height: 20px;
    `;
  }
}

const BaseMessage = styled.div<{ mt?: number; size?: InputSize }>`
  margin-top: ${({ mt }) => (mt ? Spacing.scale[mt] : Spacing.scale[1])};

  ${({ size }) => getSizeStyles(size)};
`;

export const StyledMessage = styled(BaseMessage)<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) =>
    disabled
      ? getColor(Colors.gray[300], Colors.gray[600])({ theme })
      : getColor(Colors.gray[600], Colors.gray[400])({ theme })};
`;

export const StyledErrorMessage = styled(BaseMessage)`
  display: grid;
  grid-template-columns: min-content 1fr;
  color: ${getColor(Colors.red[600], Colors.red[400])};
`;
