import isPropValid from "@emotion/is-prop-valid";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { border, motion, opacity, Spacer, SpacerProps, text } from "@gemini-ui/design-system/primitives";

const loading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(100% + 200px);
  }
`;

interface SkeletonLoaderProps extends SpacerProps {
  /**
   * @default 75%
   */
  width?: string;
  /**
   * @default 14px
   */
  height?: string;
  /**
   * The size variant for the border-radius:
   * - `sm`
   * - `md`
   * - `lg`
   * - `full`
   *
   * @default sm
   */
  radiusSize?: keyof typeof border.radius;
}

export const SkeletonLoader = styled(Spacer, {
  shouldForwardProp: (propName: string) =>
    isPropValid(propName) && !["height", "radiusSize", "width"].includes(propName),
})<SkeletonLoaderProps>`
  opacity: ${({ theme }) => (theme.isDark ? opacity.skeleton.darkMode : opacity.skeleton.lightMode)};
  background-size: 200px;
  background-color: ${({ theme }) => theme.colorScheme.skeleton.background};
  height: ${({ height = text.typesets.body.sm.fontSize }) => height};
  width: ${({ width = "75% " }) => width};
  border-radius: ${({ radiusSize = "sm" }) => border.radius[radiusSize]};
  flex: none;

  background-repeat: no-repeat;
  background-image: ${({ theme }) => {
    const background = theme.colorScheme.skeleton.background;
    const foreground = theme.colorScheme.skeleton.foreground;

    return `linear-gradient(
        90deg,
        ${background},
        ${foreground},
        ${background},
        ${background}
      );`;
  }};
  animation: ${loading} ${motion.duration.skeleton} infinite ease-in-out;

  /* https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion */
  @media (prefers-reduced-motion) {
    animation: ${loading} 8s infinite ease-in-out;
  }
`;
