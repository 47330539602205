export const NAME_ON_ACCOUNT = "nameOnAccount";
export const ACCOUNT_NUMBER = "accountNumber";
export const ROUTING_NUMBER = "routingNumber";
export const IBAN = "iban";
export const BIC = "bic";
export const TRANSIT_NUMBER = "transitNumber";
export const INSTITUTION_NUMBER = "institutionNumber";
export const SORT_CODE = "sortCode";
export const BANK_NAME = "bankName";
export const ACCOUNT_TYPE = "accountType";

export const transferMethods = {
  ach: {
    supportedCurrencies: ["USD"],
    schema: [NAME_ON_ACCOUNT, ACCOUNT_NUMBER, ROUTING_NUMBER],
  },
  emea: {
    supportedCurrencies: ["EUR", "CHF"],
    schema: [NAME_ON_ACCOUNT, IBAN, BIC],
  },
  apac: {
    supportedCurrencies: ["SGD", "AUD", "JPY", "HKD"],
    schema: [NAME_ON_ACCOUNT, BIC, ACCOUNT_NUMBER],
  },
  canada: {
    supportedCurrencies: ["CAD"],
    schema: [NAME_ON_ACCOUNT, BIC, ACCOUNT_NUMBER, TRANSIT_NUMBER, INSTITUTION_NUMBER],
  },
  uk: {
    supportedCurrencies: ["GBP"],
    schema: [NAME_ON_ACCOUNT, ACCOUNT_NUMBER, SORT_CODE],
  },
  credit: {
    supportedCurrencies: ["USD"],
    schema: [NAME_ON_ACCOUNT, ACCOUNT_NUMBER, ROUTING_NUMBER, BANK_NAME, ACCOUNT_TYPE],
  },
  giact: {
    supportedCurrencies: ["USD"],
    schema: [ACCOUNT_NUMBER, ROUTING_NUMBER, ACCOUNT_TYPE],
  },
  bankfrick: {
    supportedCurrencies: ["USD"],
    schema: [NAME_ON_ACCOUNT, ACCOUNT_NUMBER, ROUTING_NUMBER],
  },
};
