import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { GrowAsset, GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import {
  DepositFlowModal,
  DepositModalView,
  PlaceDepositInfo,
  SourceType,
  TransferQuote,
  UnstakeSourceType,
} from "@gemini-ui/pages/Earn/Deposit/types";
import { RedeemFlowState, RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { RetailTradePaymentMethodType } from "@gemini-ui/transformers/PaymentMethods";

const StakeUnstakeModalView = {
  ...DepositModalView,
  ...RedeemStep,
};

export type ModalStep = (typeof StakeUnstakeModalView)[keyof typeof StakeUnstakeModalView];

export enum GrowTransactionType {
  STAKE = "stake",
  UNSTAKE = "unstake",
}

export type GrowQuote = OrderQuote | TransferQuote;

export type SupportedStakingPaymentMethod =
  | RetailTradePaymentMethodType
  | AccountType.BALANCE
  | SourceType.TRADING_BALANCE;

// Convenience type for the supported unstaking methods
export type SupportedUnstakingMethod = UnstakeSourceType;

/**
 * The deposit payment options for Grow/Staking are a combination of retail payment methods and existing trading balances.
 *
 * Conceptually, payment methods can be split into 2 categories: existing balances and new purchaes.
 *
 * Existing balances can either be a fiat balance or a crypto balance. For deposits of this kind, we only need to know the
 * currency symbol and the balance of that currency.
 *
 * For new crypto purchases, users can either use a bank account (ACH) or debit card. If either of these payment types is
 * selected, we will need to add additional metadata to the context to handle the display information for the payment
 * type as well as the `id` for requesting a quote.
 */
export type GrowPaymentMethod<T extends SupportedStakingPaymentMethod = RetailTradePaymentMethodType> = {
  type: PaymentMethodType | AccountType | SourceType;
} & Partial<T>;

// TODO: This helper type is messy. Ideally we could clean this up on the GrowBuyState type with a generic.
export type GrowBuyDepositType<T extends OrderQuote | TransferQuote> = GrowBuyState["depositStatus"] & {
  quote?: T;
};

/** Internal state of the useGrowBuy hook */
export interface GrowBuyState {
  asset: GrowAsset;
  assetProviders: InterestProvider[];
  currency: CurrencyShortNameSupportedCryptos;
  tradingPair: string;
  providerType: GrowProviderType;
  provider: InterestProvider;

  /**
   * Set the type of transaction.
   * e.g. stake, unstake
   */
  transactionType: GrowTransactionType;

  isOpen: boolean;

  depositStatus: {
    quote?: GrowQuote;
    error?: string;
    paymentMethod?: GrowPaymentMethod;
  } & Omit<PlaceDepositInfo, "currency" | "providerType">;

  // Redeem status is likely unnecessary, but it's an easy temporary addition to be on parity with the depositStatus.
  redeemStatus?: Partial<
    Pick<RedeemFlowState, "amount" | "redemptionStatus" | "unstakeSourceType" | "validatorCount" | "convertToFiat">
  >;
  router: {
    currentView: ModalStep;
    viewStack: ModalStep[];
  };

  stakingAssets: GrowAsset[];
}

/** Returned values of the useGrowBuy hook */
export interface GrowBuyContextValues extends GrowBuyState {
  /**
   * Start a new GrowBuy session.
   *
   * @param currency - The currency to stake/unstake
   * @param transactionType {GrowTransactionType} [GrowTransactionType.STAKE] - The direction, either stake or unstake
   * @param view {ModalStep} [DepositModalView.PLACE_DEPOSIT] - The view to start on
   */
  instantiateGrowTransaction: (
    value: Partial<GrowBuyState> & Pick<GrowBuyState, "currency" | "transactionType"> & { view?: ModalStep }
  ) => void;

  setTransactionType: (value: GrowTransactionType) => void;
  setPaymentMethod: (value: SupportedStakingPaymentMethod) => void;

  // Deposit Status
  openModal: () => void;
  closeModal: () => void;

  updateDepositStatus: (
    args: Partial<GrowBuyState["depositStatus"]> &
      Partial<Pick<GrowBuyState, "currency" | "providerType">> & {
        view?: DepositFlowModal;
      }
  ) => void;
  updateRedeemStatus: (
    args: Partial<GrowBuyState["redeemStatus"]> &
      Partial<Pick<GrowBuyState, "currency" | "providerType">> & {
        view?: RedeemStep;
      }
  ) => void;

  setModalView: (view: GrowBuyState["router"]["currentView"]) => void;
  setCurrency: (currency: GrowBuyState["currency"]) => void;
  setQuote: (quote: GrowBuyState["depositStatus"]["quote"]) => void;
  resetQuote: () => void;
  setDepositError: (error: GrowBuyState["depositStatus"]["error"]) => void;

  // Navigation
  goBack: () => void | null;

  shouldDisplayStakingMethodPicker?: boolean;
}
