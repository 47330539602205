import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Card, Colors, Flex, mediaQuery, Spacing } from "@gemini-ui/design-system";
import { OptionIconWrapper, SelectWrapper } from "@gemini-ui/design-system/forms/Select/styles";
import { Action, Step } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";

const fadeTransition = css`
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
`;

export const ContentContainer = styled(Flex, {
  shouldForwardProp: (prop: string) => prop !== "action" && prop !== "isLimitOrder",
})<{ action: Action; isLimitOrder: boolean }>`
  ${({ action, isLimitOrder }) =>
    (action === Action.BUY || (isLimitOrder && action !== Action.CONVERT)) && `min-height: 486px;`}

  > div {
    width: 100%;
  }
`;

export const Container = styled(Card, {
  shouldForwardProp: (prop: string) => prop !== "action" && prop !== "step",
})<{ action: Action; step: Step }>`
  ${fadeTransition}

  ${({ action, step }) => (action === Action.BUY || step === Step.SELECT_ORDER_TYPE) && `min-height: 649px;`}

  @media ${mediaQuery.tabletMdUp} {
    margin-bottom: ${Spacing.scale[4]};
  }
`;

export const Row = styled(Flex)<{ noMargin?: boolean }>`
  flex-direction: column;
  margin-bottom: ${p => (p.noMargin ? 0 : Spacing.scale[3])};
`;

export const ToggleRow = styled(Flex)<{ isBuySellV2?: boolean }>`
  flex-direction: row;
  align-items: center;
  ${({ isBuySellV2 }) =>
    isBuySellV2 &&
    `
    background-color: ${({ theme }) => theme.colorScheme.background.secondary};
    border-radius: ${Spacing.scale[2.5]};
    padding: ${Spacing.scale[2]};
  `}
`;

export const AccountError = styled(Flex)`
  padding: ${Spacing.scale[2]};
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${Colors.red[500]};
  border-radius: ${Spacing.scale[1]};
  line-height: 20px;
  background-color: rgba(208, 2, 27, 0.06);

  > div > a {
    color: ${Colors.red[500]};
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${Colors.red[600]};
    }
  }
`;

export const IconWrapper = styled.div<{ earnStyle?: boolean }>`
  margin-top: ${Spacing.scale[0.5]};
  margin-right: ${p => (p.earnStyle ? Spacing.scale[1] : Spacing.scale[0.5])};
`;

export const FooterButtonText = styled(Button.Tertiary)`
  color: ${({ theme }) => theme.colorScheme.content.disabled};
`;

export const AssetSelectorCard = styled(Card)`
  overflow: visible;
  ${SelectWrapper} {
    margin-bottom: ${Spacing.scale[1]};
  }
  img,
  svg,
  & ${OptionIconWrapper} {
    min-width: ${Spacing.scale[4]};
  }
`;

export const ErrorLink = styled.button`
  outline: none;
  border: none;
  background-color: inherit;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  padding: 0;
  margin-top: ${Spacing.scale[1]};
`;
