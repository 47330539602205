import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@gemini-ui/design-system/Button";
import { FeedbackProps, FeedbackStatus, FeedbackVariant } from "@gemini-ui/design-system/Feedback/constants";
import { Colors, Spacer, Spacing } from "@gemini-ui/design-system/primitives";
import { BaseText } from "@gemini-ui/design-system/Text/styles";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

const getFeedbackColor = (status: FeedbackStatus) => {
  switch (status) {
    case "error": {
      return "#FAF1F1";
    }
    case "warning": {
      return "#FFF8E3";
    }
    case "success": {
      return "#F1FAF5";
    }
    case "info": {
      return "#EFF4FE";
    }
    case "tip":
    default: {
      return Colors.gray[50];
    }
  }
};

export const FeedbackContainer = styled(Spacer)<FeedbackProps>`
  border-radius: ${Spacing.scale[1]};
  background: ${({ status }) => getFeedbackColor(status)};
  display: ${({ variant }) => (variant === "md" ? "inline-flex" : "flex")};
  align-items: center;
  padding: ${({ variant }) => (variant === "md" ? `${Spacing.scale[0.5]} ${Spacing.scale[1]}` : Spacing.scale[2])};
`;

const iconStyles = (variant: FeedbackVariant) => css`
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  width: ${variant === "md" ? Spacing.scale[2] : Spacing.scale[3]};
`;

export const StatusIcon = styled(Spacer)<{ variant?: FeedbackVariant }>`
  ${({ variant }) => iconStyles(variant)}

  margin-right: ${({ variant }) => (variant === "md" ? Spacing.scale[1] : Spacing.scale[2])};
`;

export const ActionIcon = styled(Button)<{ variant?: FeedbackVariant }>`
  ${({ variant }) => iconStyles(variant)}

  min-width: auto;
  padding: 0;
  height: auto;
  margin-left: auto;

  @media ${BREAKPOINTS.mobileDown} {
    width: auto;
  }
`;

export const FeedbackContent = styled("div")`
  /* Respect new-lines sent from server */
  white-space: pre-line;

  > ${BaseText} {
    margin-bottom: ${Spacing.scale[1]};
  }

  > ${BaseText}:last-child {
    margin-bottom: 0;
  }
`;
