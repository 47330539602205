export const provinceList = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Québec",
  SK: "Saskatchewan",
  YT: "Yukon",
} as const;

type ProvinceList = typeof provinceList;
export type CAProvinceAbbreviation = keyof ProvinceList;

// TODO: Clean up the usage of these
export const abbrevs: CAProvinceAbbreviation[] = Object.keys(provinceList) as CAProvinceAbbreviation[];

export const abbrevMenu: { [province in CAProvinceAbbreviation]?: CAProvinceAbbreviation } = {};
for (const abbrev of abbrevs) {
  abbrevMenu[abbrev] = abbrev;
}
