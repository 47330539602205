import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import axios from "@gemini-ui/services/axios/cache";
import { HEADERS } from "@gemini-ui/services/constants";

export const getIsUserInOnboarding = (lockout: LOCKOUT_TYPES) => {
  return lockout === LOCKOUT_TYPES.UNVERIFIED || lockout === LOCKOUT_TYPES.PENDING;
};

export const getLockout = async (subaccountHashid?: string) => {
  const config = subaccountHashid ? { headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid } } : {};
  try {
    const response = await axios.get<{ lockout: LOCKOUT_TYPES | null }>(
      jsRoutes.controllers.security.LockoutController.getLockout().url,
      config
    );

    return response.data.lockout;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const useLockout = () => {
  const [isLoadingLockout, setIsLoadingLockout] = useState(() => true);
  const [lockout, setLockout] = useState<LOCKOUT_TYPES>(null);

  // fetchLockout called on initial mount and exposed to support requering if needed.
  const fetchLockout = useCallback(async () => {
    const lockoutResponse = await getLockout();
    if (typeof lockoutResponse !== "undefined") setLockout(lockoutResponse);
    setIsLoadingLockout(false);
  }, []);

  useEffect(() => {
    fetchLockout();
  }, [fetchLockout]);

  return {
    lockout,
    fetchLockout,
    isLoadingLockout,
  };
};
