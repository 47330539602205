import { useCallback, useState } from "react";
import { GrowQuote } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { getQuoteError } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { OrderQuoteError } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { getInstantOrderQuote, InstantOrderQuoteRouteQueryParams } from "@gemini-ui/services/retail/instantOrderQuote";
import { StakingDepositMethod, StakingTransferQueryParams } from "@gemini-ui/services/staking/constants";
import { getStakingTransferQuote } from "@gemini-ui/services/staking/transferQuote";
import { useIntl } from "@gemini-ui/utils/intl";

const quoteFetcherFnMap: Record<StakingDepositMethod, (args?: any) => Promise<GrowQuote>> = {
  [StakingDepositMethod.TRANSFER]: (args: StakingTransferQueryParams) => getStakingTransferQuote(args),
  [StakingDepositMethod.BUY_AND_STAKE]: (args: InstantOrderQuoteRouteQueryParams) => getInstantOrderQuote(args),
};

type QueryParams = StakingTransferQueryParams | InstantOrderQuoteRouteQueryParams;

export const useStakingQuote = <T extends GrowQuote = GrowQuote>() => {
  const { intl } = useIntl();

  const [data, setData] = useState<T>();
  const [error, setError] = useState<string | OrderQuoteError>(null);
  const [loading, setLoading] = useState(false);

  const fetchQuote = useCallback(
    async (quoteType: StakingDepositMethod, queryParams: QueryParams) => {
      const fetcher = quoteFetcherFnMap[quoteType];
      if (!fetcher) return;

      try {
        setLoading(true);
        const data = await fetcher(queryParams);
        setData(data as T);
        setError(null);

        // Returning the data and setting the data to local state is redundant but may be helpful depending on how this function is called.
        return data as T;
      } catch (err) {
        setError(getQuoteError(err, intl));
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [intl]
  );

  return {
    data,
    loading,
    error,
    fetchQuote,
  };
};
