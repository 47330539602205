import * as React from "react";
import { IconBrandPayPal } from "@hubble/icons";
import { PaymentMethodIconBadgeProps } from "@gemini-ui/components/Icons/PaymentMethod/constants";
import { IconBadge } from "@gemini-ui/design-system";

export const PayPalIcon = ({ size, ...props }: PaymentMethodIconBadgeProps) => {
  return (
    <IconBadge backgroundColor="#003087" size={size || "lg"} icon={<IconBrandPayPal color="white" />} {...props} />
  );
};
