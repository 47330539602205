import React from "react";
import { IconCheck } from "@hubble/icons";
import { CardColorEnum } from "@gemini-ui/client/credit";
import { Tooltip } from "@gemini-ui/design-system";
import { StyledSwatch } from "@gemini-ui/pages/Credit/CreditApplication/v2/components/CardColorSwatch/styles";

interface CardColorSwatchProps {
  color: CardColorEnum;
  isChecked: boolean;
  onChange: () => void;
}

export const CardColorSwatch = ({ color, isChecked, onChange }: CardColorSwatchProps) => {
  const tooltipText: Record<CardColorEnum, string> = {
    BLACK: "Black",
    SILVER: "Silver",
    ROSE: "Rose Gold",
  };

  return (
    <Tooltip data-testid={`card-color-tooltip-${color}`} overlay={tooltipText[color]} key={color}>
      <label>
        <input
          checked={isChecked}
          value={color}
          type="radio"
          name="card-select"
          data-testid={`card-color-selection-${color}`}
          onChange={onChange}
        />
        <StyledSwatch color={color}>{isChecked && <IconCheck size="sm" color="white" />}</StyledSwatch>
      </label>
    </Tooltip>
  );
};
