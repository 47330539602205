import React from "react";
import BigNumber from "bignumber.js";
import { useController, UseControllerProps } from "react-hook-form";
import { bigNumberToDecimalString, CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { Flex } from "@gemini-ui/design-system";
import { balanceValueToCurrencyReceiptDecimals } from "@gemini-ui/pages/Earn/Deposit/utils";
import StakingInput, { InputProps } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/StakingInput";
import {
  StakingPresets,
  StakingPresetsProps,
} from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositPresets/StakingPresets";
import { DepositFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingDepositForm";
import { WithdrawalFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingWithdrawalForm";

type UseStakingFormControllerType = UseControllerProps<DepositFormValues | WithdrawalFormValues>;

export interface CurrencyAmountInputsProps extends InputProps, StakingPresetsProps {
  currency: CurrencyShortName;
  maxValue: string | number;
  controls: {
    amount: UseStakingFormControllerType;
  };
}

export const CurrencyAmountInputs = ({
  maxValue,
  controls,
  currency,
  fundingType,
  ...rest
}: CurrencyAmountInputsProps) => {
  const {
    field: { value: amount, onChange, onBlur },
    fieldState: { error },
  } = useController(controls.amount);

  const handleUpdateFields = (newAmount: number | string) => {
    const _amount = newAmount.toString();
    onChange(_amount);
  };

  const handlePercent = (percent: number) => {
    const sanitizedBalance = balanceValueToCurrencyReceiptDecimals(maxValue, currency);
    const _amount = bigNumberToDecimalString(new BigNumber(Number(sanitizedBalance) * percent), currency);
    onChange(_amount);
  };

  return (
    <Flex flexDirection="column">
      <StakingInput
        {...rest}
        value={amount}
        onBlur={onBlur}
        error={error ? error.message : null}
        onChange={handleUpdateFields}
      />

      <StakingPresets fundingType={fundingType} onClickPercent={handlePercent} onClickAmount={handleUpdateFields} />
    </Flex>
  );
};
