import React from "react";
import { IconBankFilled, IconCardOutlined, IconSpendingTransfers } from "@hubble/icons";
import { ColumnDef } from "@tanstack/react-table";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { PayPalIcon } from "@gemini-ui/components/Icons/PaymentMethod/PaypalIcon";
import { Money } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { Flex, IconBadge, Text } from "@gemini-ui/design-system";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import {
  DEPOSIT,
  getTransferLimitAndTimingModalConstants,
  TRADE,
  WITHDRAW,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/transferLimitAndTiming/constants";
import { getPayPalPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";
import { DepositLimits, TransferLimits, WithdrawalLimits } from "@gemini-ui/services/transfer/types";
import { IntlShape } from "@gemini-ui/utils/intl";

const ICON_BY_TRANSFER_TYPE = {
  [LinkPaymentType.PLAID_OR_GIACT]: <IconBadge icon={<IconBankFilled />} />,
  [LinkPaymentType.PLAID]: <IconBadge icon={<IconBankFilled />} />,
  [LinkPaymentType.PAYPAL]: <PayPalIcon size="md" />,
  [LinkPaymentType.DEBIT]: <IconBadge icon={<IconCardOutlined />} />,
  [LinkPaymentType.WIRE]: <IconBadge icon={<IconSpendingTransfers />} />,
  [LinkPaymentType.XFERS]: <IconBadge icon={<IconSpendingTransfers />} />,
};

const getTransferTypeLabelsById = (intl: IntlShape) => ({
  [LinkPaymentType.PLAID_OR_GIACT]: intl.formatMessage({ defaultMessage: "Bank account" }),
  [LinkPaymentType.PLAID]: intl.formatMessage({ defaultMessage: "Bank account" }),
  [LinkPaymentType.PAYPAL]: intl.formatMessage({ defaultMessage: "PayPal" }),
  [LinkPaymentType.DEBIT]: intl.formatMessage({ defaultMessage: "Debit card" }),
  [LinkPaymentType.WIRE]: intl.formatMessage({ defaultMessage: "Wire transfer" }),
  [LinkPaymentType.XFERS]: intl.formatMessage({ defaultMessage: "FAST transfer" }),
});

export interface TransferLimitAndTimingsTableDataTypes {
  transferType: string;
  transferTime: string;
  dailyLimit?: string;
}

export const getWirelimitsAndTimings = (
  isSingaporeUser: boolean,
  wireTransferTimingAndLimit: TransferLimitAndTimingsTableDataTypes,
  xfersTransferTimingAndLimit: TransferLimitAndTimingsTableDataTypes
) => (!isSingaporeUser ? wireTransferTimingAndLimit : xfersTransferTimingAndLimit);

export const getColumns = (intl: IntlShape): ColumnDef<TransferLimitAndTimingsTableDataTypes>[] => {
  return [
    {
      id: "transferType",
      accessorFn: rowData => rowData.transferType,
      header: "",
      cell: ({ row }) => (
        <Flex data-testid={row.original.transferType} align="center">
          {ICON_BY_TRANSFER_TYPE[row.original.transferType]}
          <Text.Body size="sm" bold ml={0.5}>
            {getTransferTypeLabelsById(intl)[row.original.transferType]}
          </Text.Body>
        </Flex>
      ),
      size: 180,
      enableSorting: false,
    },
    {
      id: "transferTime",
      accessorFn: rowData => rowData.transferTime,
      header: intl.formatMessage({ defaultMessage: "Transfer time" }),
      cell: ({ row }) => <Text.Body size="sm">{row.original.transferTime}</Text.Body>,
      enableSorting: false,
    },
    {
      id: "dailyLimit",
      header: intl.formatMessage({ defaultMessage: "Limit/Day" }),
      accessorFn: rowData => rowData.dailyLimit,
      cell: ({ row }) => (
        <Flex align="center" width="100%" justifyContent="center">
          <Text.Body size="sm">{row.original.dailyLimit}</Text.Body>
        </Flex>
      ),
      size: 100,
      enableSorting: false,
    },
  ];
};

export const getTransferLimitAndTimingLabelsByTabId = (intl: IntlShape) => ({
  [DEPOSIT]: intl.formatMessage({ defaultMessage: "Deposit" }),
  [WITHDRAW]: intl.formatMessage({ defaultMessage: "Withdraw" }),
  [TRADE]: intl.formatMessage({ defaultMessage: "Trade" }),
});

export const getTabDataById = (
  depositLimits: DepositLimits,
  withdrawalLimits: WithdrawalLimits,
  currency: CurrencyShortNameFiat,
  countryCode: CountryAbbreviation,
  geminiEntity: GeminiEntity,
  intl: IntlShape
) => {
  const isSingaporeUser = countryCode === "sg" && currency === CURRENCIES_DETAIL.SGD.symbol;
  const isUKUser =
    countryCode === "gb" && (currency === CURRENCIES_DETAIL.GBP.symbol || currency === CURRENCIES_DETAIL.EUR.symbol);
  const isUSUser = countryCode === "us";
  const isPayPalEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.PAYPAL_ENABLED);
  const WIRE_TRANSFER_LIMIT_AND_TIMING = {
    transferType: LinkPaymentType.WIRE,
    transferTime: getTransferLimitAndTimingModalConstants(intl).wireTransferTiming,
    dailyLimit: getTransferLimitAndTimingModalConstants(intl).noLimit,
  };

  const XFERS_TRANSFER_LIMIT_AND_TIMING = {
    transferType: LinkPaymentType.XFERS,
    transferTime: getTransferLimitAndTimingModalConstants(intl).xfersTransferTiming,
    dailyLimit: getTransferLimitAndTimingModalConstants(intl).xfersLimit,
  };

  if (!depositLimits || !withdrawalLimits) {
    return { [DEPOSIT]: [], [WITHDRAW]: [], [TRADE]: [] };
  }

  const getAchOrPpiPaymentType = (isUKUser: boolean, isUSUser: boolean) => {
    if (isUKUser) {
      return LinkPaymentType.PLAID;
    }
    if (isUSUser) {
      return LinkPaymentType.PLAID_OR_GIACT;
    }
    return undefined;
  };

  const DEPOSIT_PAYMENT_TYPES = [
    getAchOrPpiPaymentType(isUKUser, isUSUser),
    ...(isPayPalEnabled ? getPayPalPaymentType(geminiEntity, currency, countryCode) : []),
    LinkPaymentType.WIRE,
  ];
  const WITHDRAW_PAYMENT_TYPES = [getAchOrPpiPaymentType(isUKUser, isUSUser), , LinkPaymentType.WIRE];
  const TRADE_PAYMENT_TYPES = [
    getAchOrPpiPaymentType(isUKUser, isUSUser),
    ,
    LinkPaymentType.DEBIT,
    ...(isPayPalEnabled ? getPayPalPaymentType(geminiEntity, currency, countryCode) : []),
  ];

  const getTableData = (limits: TransferLimits, currency: CurrencyShortNameFiat, paymentTypes: LinkPaymentType[]) => {
    return paymentTypes
      ?.map(paymentType => {
        switch (paymentType) {
          case LinkPaymentType.PLAID_OR_GIACT:
            return {
              transferType: paymentType,
              transferTime: getTransferLimitAndTimingModalConstants(intl).achAndPaypalTransferTiming,
              dailyLimit: limits?.ach?.daily?.total?.value ? (
                <Money value={limits?.ach?.daily?.total?.value} currency={currency} hideTrailingSign />
              ) : (
                "-"
              ),
            };

          case LinkPaymentType.PLAID:
            return {
              transferType: paymentType,
              transferTime: getTransferLimitAndTimingModalConstants(intl).achAndPaypalTransferTiming,
              dailyLimit: limits?.ppi?.daily?.total?.value ? (
                <Money value={limits?.ppi?.daily?.total?.value} currency={currency} hideTrailingSign />
              ) : (
                "-"
              ),
            };

          case LinkPaymentType.PAYPAL:
            return {
              transferType: paymentType,
              transferTime: getTransferLimitAndTimingModalConstants(intl).achAndPaypalTransferTiming,
              dailyLimit: limits?.payPal?.daily?.total?.value ? (
                <Money value={limits?.payPal?.daily?.total?.value} currency={currency} hideTrailingSign />
              ) : (
                "-"
              ),
            };
          case LinkPaymentType.DEBIT:
            return {
              transferType: paymentType,
              transferTime: getTransferLimitAndTimingModalConstants(intl).debitTransferTiming,
              dailyLimit: limits?.card?.daily?.total?.value ? (
                <Money value={limits?.card?.daily?.total?.value} currency={currency} hideTrailingSign />
              ) : (
                "-"
              ),
            };

          case LinkPaymentType.WIRE:
            return getWirelimitsAndTimings(
              isSingaporeUser,
              WIRE_TRANSFER_LIMIT_AND_TIMING,
              XFERS_TRANSFER_LIMIT_AND_TIMING
            );
        }
      })
      .filter(data => data);
  };
  return {
    [DEPOSIT]: getTableData(depositLimits as TransferLimits, currency, DEPOSIT_PAYMENT_TYPES),
    [WITHDRAW]: getTableData(withdrawalLimits as TransferLimits, currency, WITHDRAW_PAYMENT_TYPES),
    [TRADE]: getTableData(depositLimits as TransferLimits, currency, TRADE_PAYMENT_TYPES),
  };
};
