import React, { createContext, useMemo } from "react";
import {
  CURRENCIES_DETAIL,
  CurrencyDetail,
  CurrencyPairDetail,
  isCurrency,
  isFiatCurrency,
  SupportedCurrencyPairs,
} from "@gemini-common/scripts/constants/currencies";
import { usePageData } from "@gemini-ui/contexts/PageData";
import CryptoIcon from "@gemini-ui/images/icons/cdn/CryptoIcon";
import { Action } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountError, CurrencyIconProps, RouteAction } from "@gemini-ui/pages/RetailTrade/constants";
import { IntlShape } from "@gemini-ui/utils/intl";

export const routeActionToAction = (routeAction: RouteAction): Action => {
  switch (routeAction) {
    default:
    case "convert":
      return Action.CONVERT;
    case "buy":
      return Action.BUY;
    case "sell":
      return Action.SELL;
  }
};

export const formatPercentDelta = percentDelta => {
  const numPercentDelta = Number(percentDelta) * 100;
  if (numPercentDelta < 0 && numPercentDelta > -0.01) {
    return "-0.01";
  }
  if (numPercentDelta < 0.01 && numPercentDelta > 0) {
    return "0.01";
  }
  return numPercentDelta.toFixed(2);
};

export function getCurrencyRoute(
  symbol: CurrencyDetail["symbol"],
  tradingPair?: SupportedCurrencyPairs,
  advancedTradeUIEnabled?: boolean
) {
  if (isFiatCurrency(symbol) || !tradingPair) {
    if (isCurrency.GUSD(symbol)) {
      return `/convert/${symbol}`;
    }
    return "/transfer";
  }
  if (advancedTradeUIEnabled) {
    return `/trade/${tradingPair}`;
  }
  return `/buy/${symbol}`;
}

/**
 *
 * @deprecated Please use `useCurrencyDetailsV2` when possible to avoid re-rendering unnecessarily
 */
export const CURRENCY_DETAILS_V2 = (
  symbol: CurrencyDetail["symbol"],
  tradingPair?: SupportedCurrencyPairs,
  advancedTradeUIEnabled?: boolean
): CurrencyIconProps => {
  const currency = CURRENCIES_DETAIL[symbol];
  const Icon = props => <CryptoIcon {...props} symbol={symbol}></CryptoIcon>;
  const route = getCurrencyRoute(symbol, tradingPair, advancedTradeUIEnabled);

  return { name: currency?.name || "", Icon, route, currency };
};

export const useCurrencyDetailsV2 = (symbol: CurrencyDetail["symbol"], tradingPair?: SupportedCurrencyPairs) => {
  const {
    templateProps: {
      user: { advancedTradeUIEnabled },
    },
  } = usePageData();
  return useMemo(
    () => CURRENCY_DETAILS_V2(symbol, tradingPair, advancedTradeUIEnabled),
    [symbol, tradingPair, advancedTradeUIEnabled]
  );
};

export const RefreshContext = createContext(null);

export function isCurrencyPairSupported(pairs: CurrencyPairDetail[], symbol: string): boolean {
  return Boolean(pairs.find(pair => pair.symbol === symbol));
}

export const getAccountErrors = (intl: IntlShape): { [key: string]: AccountError } =>
  ({
    emailUnconfirmed: {
      error: intl.formatMessage({ defaultMessage: "You must confirm your email before you can deposit or trade." }),
      buttonText: intl.formatMessage({ defaultMessage: "Resend email confirmation" }),
      action: "resendEmail",
    },
    needsFundingSource: {
      error: intl.formatMessage({
        defaultMessage: "You have no payment method linked to your account. Add a payment method to continue.",
      }),
      link: jsRoutes.controllers.settings.BankSettingsController.get().url,
      buttonText: intl.formatMessage({ defaultMessage: "Add payment method" }),
    },
  } as const);
