import styled from "@emotion/styled";
import { IconStaticStatusAlert, IconStaticStatusSuccess, IconTimelineIndicator } from "@hubble/icons";
import { Flex } from "@gemini-ui/design-system/Flex";
import { List as HubbleList } from "@gemini-ui/design-system/List";
import { Spacing } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";

export const Metadata = styled(Text.Body)`
  color: ${({ theme }) => theme.colorScheme.content.secondary};
  margin-top: ${Spacing.scale[0.5]};
`;

export const StepBody = styled.div`
  margin-left: ${Spacing.scale[2.5]};
  margin-bottom: ${Spacing.scale[3]};
`;

export const List = styled(HubbleList)`
  li:last-of-type {
    ${StepBody} {
      margin-bottom: 0;
    }
  }
`;

export const Connector = styled.div<{ testID: string }>`
  flex: 1;
  background-color: ${({ theme }) => theme.colorScheme.border.secondary};
  width: 2px;
  margin-top: -6px;
  margin-bottom: -6px;
`;

export const ConnectorProgress = styled.div<{ stepComplete: boolean }>`
  background-color: ${({ theme }) => theme.colorScheme.content.primary};
  width: 2px;
  height: ${props => (props.stepComplete ? "100%" : "0")};
  transition: height 0.3s;
  transition-delay: 0.2s;
`;

export const NotStarted = styled(IconTimelineIndicator)`
  fill: ${({ theme }) => theme.colorScheme.border.secondary};
`;

export const InProgress = styled(IconTimelineIndicator)`
  fill: ${({ theme }) => theme.colorScheme.content.primary};
  z-index: 1;
`;

export const StepStatus = styled(Flex)`
  width: ${Spacing.scale[3]};
`;

export const StatusSuccess = styled(IconStaticStatusSuccess)`
  z-index: 1;
  & > path:first-of-type {
    fill: ${({ theme }) => theme.colorScheme.content.primary};
  }
  & > path:nth-of-type(2) {
    fill: ${({ theme }) => theme.colorScheme.background.primary};
  }
`;

export const StatusAlert = styled(IconStaticStatusAlert)`
  z-index: 1;
  & > path:first-of-type {
    fill: ${({ theme }) => theme.colorScheme.content.primary};
  }
  & > path:nth-of-type(2) {
    fill: ${({ theme }) => theme.colorScheme.background.primary};
  }
`;

export const StorybookList = styled.ul`
  list-style-type: disc;
  margin: ${Spacing.scale[1]} 0 ${Spacing.scale[1]} ${Spacing.scale[2]};
`;

export const FadeInScaleIcon = styled.div<{ animate: boolean }>`
  opacity: ${props => (props.animate ? 1 : 0)};
  transition: all 0.4s;
  transform: ${props => (props.animate ? "scale(1)" : "scale(0)")};
`;

export const IconTransition = styled.div`
  display: flex;
  width: ${Spacing.scale[3]};
  height: ${Spacing.scale[3]};
  ${NotStarted} {
    position: absolute;
  }
`;
