import React from "react";
import { IconChevronRightSmall } from "@hubble/icons";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { PaymentMethodItem } from "@gemini-ui/components/PaymentMethodItem";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import CryptoIcon from "@gemini-ui/images/icons/cdn/CryptoIcon";
import { PaymentMethodButton } from "@gemini-ui/pages/RetailTrade/PaymentMethod/styles";
import { getGeminiBalance } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";

interface BalanceMethodProps {
  onClick: () => void;
  balances: CurrencyBalances;
  defaultFiat: CurrencyShortNameFiat;
}

const BalanceMethod: React.FC<React.PropsWithChildren<BalanceMethodProps>> = props => {
  const { onClick, balances, defaultFiat } = props;
  const geminiBalanceValue = getGeminiBalance(balances, defaultFiat);
  return (
    <React.Fragment>
      <PaymentMethodButton
        data-testid={testIds.buySell.paymentMethodBtn}
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        <PaymentMethodItem
          displayText={`${defaultFiat} balance`}
          infoText={<Money truncate={10} currency={defaultFiat} value={geminiBalanceValue} />}
          icon={<CryptoIcon size="md" symbol={defaultFiat} />}
        />
        <IconChevronRightSmall />
      </PaymentMethodButton>
    </React.Fragment>
  );
};

export default BalanceMethod;
