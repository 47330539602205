import React from "react";
import {
  LoadingScrim,
  SectionHeader,
  SwitcherButtonContainer,
  TradingInterfaceSwitcherContainer,
} from "@gemini-ui/components/Header/navigation/TradingInterfaceSwitcher/styles";
import { useTradeUI } from "@gemini-ui/components/Header/navigation/TradingInterfaceSwitcher/utils";
import { usePageData } from "@gemini-ui/contexts";
import { Badge, Colors, Flex, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { isDerivativesEnabled } from "@gemini-ui/utils/derivativeAccountUtils";
import { useIntl } from "@gemini-ui/utils/intl";

export const TradingInterfaceSwitcher = () => {
  const { intl } = useIntl();

  const {
    templateProps: { user },
  } = usePageData();
  const isDerivativesAccount = isDerivativesEnabled(user);

  const { isActiveTrader, isUpdatingUI, switchTradeUI } = useTradeUI();

  return (
    <React.Fragment>
      <TradingInterfaceSwitcherContainer>
        <Flex flexDirection="column">
          <SectionHeader bold color={Colors.white}>
            {intl.formatMessage({ defaultMessage: "Trading interface" })}
          </SectionHeader>
          {!isDerivativesAccount && (
            <SwitcherButtonContainer
              role="button"
              tabIndex={0}
              active={!isActiveTrader}
              onClick={() => switchTradeUI()}
              data-testid="gemini-retail-switch-btn"
            >
              <Flex>
                <Text.Body size="sm" bold color={Colors.white} mr={1}>
                  {intl.formatMessage({ defaultMessage: "Gemini" })}
                </Text.Body>
              </Flex>
              <Text.Body size="xs" color={Colors.gray[400]}>
                {intl.formatMessage({ defaultMessage: "Simple and easy-to-use" })}
              </Text.Body>

              <LoadingScrim isLoading={isUpdatingUI && !isActiveTrader}>
                <SpinnerAnimation />
              </LoadingScrim>
            </SwitcherButtonContainer>
          )}
          <SwitcherButtonContainer
            role="button"
            tabIndex={0}
            active={isActiveTrader}
            onClick={() => switchTradeUI()}
            data-testid="active-trader-switch-btn"
          >
            <Flex>
              <Text.Body size="sm" bold color={Colors.white} mr={1}>
                {intl.formatMessage({ defaultMessage: "ActiveTrader™" })}
              </Text.Body>
              <Badge backgroundColor={Colors.blue[800]} variant="flat">
                {intl.formatMessage({ defaultMessage: "Advanced" })}
              </Badge>
            </Flex>
            <Text.Body size="xs" color={Colors.gray[400]}>
              {intl.formatMessage({ defaultMessage: "Access advanced orders and charts" })}
            </Text.Body>

            <LoadingScrim isLoading={isUpdatingUI && isActiveTrader}>
              <SpinnerAnimation color={Colors.white} />
            </LoadingScrim>
          </SwitcherButtonContainer>
        </Flex>
      </TradingInterfaceSwitcherContainer>
    </React.Fragment>
  );
};
