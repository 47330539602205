/* eslint-disable no-restricted-imports */
import React from "react";
import { isFiatCurrency } from "@gemini-common/scripts/constants/currencies";
import CommonMoney from "@gemini-common/scripts/Money";
import { MoneyComponentProps, PriceProps, Round } from "@gemini-common/scripts/Money/constants";
import { useIntl } from "@gemini-ui/utils/intl";

/**
 * Proxy Money component to the one found in @gemini-common in order to
 * provide localization support with the useIntl hook. This is solely for
 * localization purposes and any new money behavior or utils, etc. belongs
 * in @gemini-common. Please do not modify this file unless there's a
 * specific reason you need to.
 */

function Price(props: PriceProps) {
  const { intl } = useIntl();
  return <CommonMoney.Price locale={intl.locale} {...props} />;
}

function Quantity(props: PriceProps) {
  const { intl } = useIntl();
  return <CommonMoney.Quantity locale={intl.locale} {...props} />;
}

function Money(props: MoneyComponentProps) {
  const { intl } = useIntl();
  const { currency } = props;

  const commonProps = props?.round
    ? {}
    : {
        round: isFiatCurrency(currency) ? Round.Down : Round.None,
      };

  return <CommonMoney locale={intl.locale} {...commonProps} {...props} />;
}

Money.priceCurrency = CommonMoney.priceCurrency;
Money.fromCurrency = CommonMoney.priceCurrency;
Money.toCurrency = CommonMoney.quantityCurrency;
Money.quantityCurrency = CommonMoney.quantityCurrency;
Money.Price = Price;
Money.Quantity = Quantity;

export * from "@gemini-common/scripts/Money/constants";
export { Money };
