import React from "react";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import { SiteLockout } from "@gemini-ui/components/Lockout/utils";
import SupportLinkComponent from "@gemini-ui/components/SupportLink";
import { Text } from "@gemini-ui/design-system";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

const settingsLink = jsRoutes.controllers.settings.ProfileSettingsController.get().url;
const SupportLink = () => <SupportLinkComponent />;
const getComponentCopy = (intl: IntlShape) => ({
  DEFAULT: intl.formatMessage(
    defineMessage({
      defaultMessage: "You must have sufficient privileges to complete this action.",
    }),
    { SupportLink }
  ),
  [LOCKOUT_TYPES.CONSENT_WITHDRAWALS_ONLY]: intl.formatMessage(
    defineMessage({
      defaultMessage: `Unfortunately, this account is restricted from trading. You can lift this restriction by accepting all of the <TextLink>user policies</TextLink>. Otherwise, please withdraw your funds or <SupportLink></SupportLink> for further assistance.`,
    }),
    {
      SupportLink,
      TextLink: text => (
        <Text.Link href={jsRoutes.controllers.account.ConsentInterstitialController.get().url}>{text}</Text.Link>
      ),
    }
  ),
  [LOCKOUT_TYPES.DEPOSIT_AND_CRYPTO_SALES_ONLY]: intl.formatMessage({
    defaultMessage:
      "One or more of your deposits failed, so we had to cover some of your purchases. To unfreeze your account, please deposit funds or sell crypto to cover the balance.",
  }),
  [LOCKOUT_TYPES.DIEM_OPERATING_AREA]: intl.formatMessage({
    defaultMessage: `Diem is not yet available in your country or state of residence. We're working with regulators
    to get you started as soon as possible!`,
  }),
  [LOCKOUT_TYPES.FIAT_WITHDRAWALS_ONLY]: intl.formatMessage(
    defineMessage({
      defaultMessage: `Your account is restricted to fiat currency withdrawals and cryptoasset sales. <SupportLink></SupportLink> for further
      assistance.`,
    }),
    { SupportLink }
  ),
  [LOCKOUT_TYPES.FROZEN]: intl.formatMessage({
    defaultMessage: "This action is unavailable as your account is currently frozen.",
  }),
  [LOCKOUT_TYPES.GUSD_OPERATING_AREA]: intl.formatMessage({
    defaultMessage: `Gemini dollar is not yet available in your country or state of residence. We're working with regulators
    to get you started as soon as possible!`,
  }),
  [LOCKOUT_TYPES.LOOKING]: intl.formatMessage({
    defaultMessage: `Unfortunately, we are not operational in your area. You will be able to transfer funds and trade on Gemini
    once we are licensed and operational in your area.`,
  }),
  [LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS]: intl.formatMessage({
    defaultMessage:
      "This action is unavailable as your user doesn't have sufficient permissions to manage payment methods.",
  }),
  [LOCKOUT_TYPES.PENDING]: intl.formatMessage({
    defaultMessage: `We are verifying your identity and will be in touch shortly. Gemini is currently experiencing extremely high
    application volume and there may be delays as the compliance team completes the review of your application.`,
  }),
  [LOCKOUT_TYPES.SANDBOX_WITH_WALLET]: intl.formatMessage({
    defaultMessage: `You are on the Gemini Sandbox site. All trades are conducted in play money, and you can only deposit or
      withdraw testnet BTC.`,
  }),
  [LOCKOUT_TYPES.SANDBOX]: intl.formatMessage({
    defaultMessage: `You are on the Gemini Sandbox site. All trades are conducted in play money, and there is no deposit or
      withdrawal functionality available.`,
  }),
  [LOCKOUT_TYPES.SCHEDULED_FRAUD_CLOSURE]: intl.formatMessage(
    defineMessage({
      defaultMessage: `Your account is restricted to fiat currency withdrawals and cryptoasset sales. <SupportLink></SupportLink> for further
        assistance.`,
    }),
    { SupportLink }
  ),
  [LOCKOUT_TYPES.UNVERIFIED]: intl.formatMessage(
    defineMessage({
      defaultMessage: `You must <TextLink>verify your identity</TextLink> before transferring funds or trading
      on Gemini.`,
    }),
    { TextLink: (text: React.ReactNode) => <Text.Link href={settingsLink}>{text}</Text.Link> }
  ),
  [LOCKOUT_TYPES.WIRE_DEPOSIT_FOR_VERIFICATION]: intl.formatMessage(
    defineMessage({
      defaultMessage: `Your account has been approved, but is restricted from trading until you send in your first <TextLink>deposit via wire transfer</TextLink>.`,
    }),
    {
      TextLink: (text: React.ReactNode) => (
        <Text.Link href={jsRoutes.controllers.transfer.page.TransferPageController.get().url}>{text}</Text.Link>
      ),
    }
  ),
  [LOCKOUT_TYPES.WIRE_DEPOSIT_FOR_UNFREEZE]: intl.formatMessage(
    defineMessage({
      defaultMessage: `Your account is currently restricted from trading/withdrawals until you send in a <TextLink>deposit via wire transfer</TextLink>.`,
    }),
    {
      TextLink: (text: React.ReactNode) => (
        <Text.Link href={jsRoutes.controllers.transfer.page.TransferPageController.get().url}>{text}</Text.Link>
      ),
    }
  ),
  [LOCKOUT_TYPES.WITHDRAWALS_ONLY]: intl.formatMessage(
    defineMessage({
      defaultMessage: `Unfortunately, this account is restricted from trading. Please withdraw your funds or <SupportLink></SupportLink> for
                      further assistance.`,
    }),
    { SupportLink }
  ),
});

export interface LockoutMessageProps {
  lockout: SiteLockout;
}

function LockoutMessage({ lockout }: LockoutMessageProps) {
  const { intl } = useIntl();
  const copy = getComponentCopy(intl);
  const message = copy[lockout] || copy.DEFAULT;

  return message as JSX.Element;
}

export default LockoutMessage;
