import React, { useEffect, useRef } from "react";
import { ClassNames } from "@emotion/react";
import AuthCode, { AuthCodeProps, AuthCodeRef } from "react-auth-code-input";
import {
  authCodeInputStyles,
  autoCodeWrapperStyle,
  loadingWrapperStyle,
} from "@gemini-ui/components/AuthCodeInput/styles";
import { ElementWrapper } from "@gemini-ui/design-system/Button/styles";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";

export const AuthCodeInput = ({
  ariaLabel,
  onChange,
  length,
  clear,
  error,
  loading,
  noMarginTop,
  ...rest
}: AuthCodeProps & { clear?: boolean; error?: boolean; loading?: boolean; noMarginTop?: boolean }) => {
  const AuthInputRef = useRef<AuthCodeRef>(null);

  useEffect(() => {
    let timeout = null;

    if (clear) {
      AuthInputRef.current?.clear();

      // Hack! Need to do a quick timeout to refocus on input
      setTimeout(() => {
        timeout = AuthInputRef.current?.focus();
      }, 0);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [clear]);

  useEffect(() => {
    if (AuthInputRef.current) {
      const inputs = document.querySelectorAll(".authy-code");

      // attached test id to each input
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        input.setAttribute("data-testid", `authy-code-${i + 1}`);
      }
    }
  }, []);

  return (
    <ClassNames>
      {({ css, cx, theme }) => {
        return (
          <div className={css(autoCodeWrapperStyle)}>
            {loading && (
              <ElementWrapper className={css(loadingWrapperStyle)} size="lg" justify="center">
                <SpinnerAnimation size="lg" color={theme.colorScheme.content.primary} />
              </ElementWrapper>
            )}

            <AuthCode
              ariaLabel={ariaLabel}
              length={length}
              onChange={onChange}
              allowedCharacters="numeric"
              inputClassName={cx("authy-code", css(authCodeInputStyles(theme)), {
                negative: error,
                ["no-margin-top"]: noMarginTop,
              })}
              containerClassName={css`
                display: flex;
                justify-content: space-between;
              `}
              ref={AuthInputRef}
              {...rest}
            />
          </div>
        );
      }}
    </ClassNames>
  );
};
