import { SyntheticEvent } from "react";
import { EVENTS, optimizelyClient } from "@gemini-ui/analytics";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export const IS_CASH_WITHDRAW_FLOW_ENABLED = optimizelyClient.isFeatureEnabled(
  OPTIMIZELY_FEATURE_FLAGS.WEB_CASH_WITHDRAW_FLOW
);

export const handleCashWithdrawClick = (
  e: SyntheticEvent,
  handleOpenModal: () => void,
  initiatedFrom: string,
  href?: string
) => {
  e.preventDefault();
  const { name, properties } = EVENTS.WITHDRAW_START;
  const isCashWithdrawFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CASH_WITHDRAW_FLOW);
  if (isCashWithdrawFlowEnabled) {
    handleOpenModal();
  } else {
    mixpanelClient.trackLinkClickAndRedirectManually(href, name, {
      [properties.INITIATED_FROM]: initiatedFrom,
    });
  }
};
