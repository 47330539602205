import { Spacing } from "@gemini-ui/design-system/primitives/spacing";

export type SpacerOption = keyof typeof Spacing.scale;

export const SHORTHAND_SPACER_MAP = {
  m: "margin",
  mt: "margin-top",
  mr: "margin-right",
  mb: "margin-bottom",
  ml: "margin-left",
  p: "padding",
  pt: "padding-top",
  pr: "padding-right",
  pb: "padding-bottom",
  pl: "padding-left",
} as const;
