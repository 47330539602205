import * as Sentry from "@sentry/browser";
import { WatchlistResponseItem, WatchlistTypes } from "@gemini-ui/constants/watchlist";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

const watchlistUrl = `/watchlist`;
const enableUrl = currency => `/watchlist/${currency}/FavoritesWatch/enable`;
const disableUrl = currency => `/watchlist/${currency}/FavoritesWatch/disable`;

export const getWatchlistIsEnabled = currency => {
  return axios.get(watchlistUrl).then(res => {
    if (res.status === 200 && res.data) {
      const currencyObject = res.data.find(
        item => item.currency === currency && item.watchType === WatchlistTypes.FAVORITES_WATCH
      );

      if (currencyObject.watchType === WatchlistTypes.FAVORITES_WATCH) {
        return currencyObject.isEnabled;
      }
    }
  });
};

export const getAllEnabledWatchlist = async (): Promise<WatchlistResponseItem[]> => {
  let returnData = [];

  try {
    const { data } = await axios.get(watchlistUrl);

    returnData = data.filter(asset => asset.isEnabled);
  } catch (error) {
    const message = getError(error);
    Sentry.captureMessage(`Error fetching /watchlist: ${message}`);
  }

  return returnData;
};

export const putEnableWatchlistFavorite = (enabled, currency) => {
  const url = enabled ? disableUrl(currency) : enableUrl(currency);

  return axios.put(url).then(res => res.status === 200);
};
