export enum FeatureElectionStatus {
  OptIn = "opt-in",
  OptOut = "opt-out",
  NoDecision = "no-decision",
}
export interface FeatureElection {
  electiveFeature: string;
  hashId: string;
  status: FeatureElectionStatus;
}

export interface FeatureElectionResponse {
  featureElections: FeatureElection[];
}

export type LeaderboardValueType = "percentage" | "gusd";
export interface LeaderboardMetadataStandings {
  localizedName: string;
  valueType: LeaderboardValueType;
  order: number;
}

export type LeaderboardMetadata = {
  type: CompetitionType;
  standings: Record<string, LeaderboardMetadataStandings>;
};

export interface Leaderboard {
  // YYYY-MM-DDTHH:mm:ss.sssZ
  asOf: string;
  //YYYY-MM-DD
  forDate: string;
  type: CompetitionType;
  metadata: LeaderboardMetadata;
  standings: LeaderboardRankResponsesUnionType[];
}

export type CompetitionType = "DerivativeDaily" | "DerivativeDailyByVolume" | "DerivativeDailyPnl";

// TODO Define the exact type of the response
export interface CompetitionInfo {
  type: CompetitionType;
  startDate: string;
  endDate: string;
  visible: boolean;
  localizedName: string;
  localizedDescription: string;
  supportArticleId: string;
  cardImageUrl?: string;
  cardImageWebUrl?: string;
  cardImageSquaredUrl?: string;
  localizedValueDescription: string;
  // awards is an array where index 0 is the first place, index 1 is the second place, etc.
  awards: number[];
  monthly: boolean;
  monthlyAwards?: number[];
  bgColor: string;
  icon: string;
}

export interface LeaderboardValue {
  id: string;
  value: number;
}

interface LeaderboardRankingBase {
  rank: number;
  values: LeaderboardValue[];
}

export interface LeaderboardRankStandingResponse extends LeaderboardRankingBase {
  profileId: number;
  profile: string;
  displayName?: string;
  avatar?: string;
  winsInMonth?: number;
  accountName?: string;
}

export interface LeaderboardAccountRankStandingResponse extends LeaderboardRankingBase {
  accountHashId: string;
  forDate: string;
  type: CompetitionType;
  metadata: {
    standings: Record<string, LeaderboardMetadataStandings>;
  };
}

export type LeaderboardRankStanding<T> = T & { type: CompetitionType };

export interface DerivativeDailyData {
  percentReturn?: number;
  pnl?: number;
}

export interface VolumeVictorData {
  volume?: number;
}
export type DerivativeDailyResponse = DerivativeDailyData & LeaderboardRankStandingResponse;
export type VolumeVictorResponse = VolumeVictorData & LeaderboardRankStandingResponse;

export type DerivativeDailyAccountResponse = DerivativeDailyData & LeaderboardAccountRankStandingResponse;
export type VolumeVictorAccountResponse = VolumeVictorData & LeaderboardAccountRankStandingResponse;

export type LeaderboardRankResponsesUnionType = DerivativeDailyResponse | VolumeVictorResponse;
export type LeaderboardAccountRankResponsesUnionType = DerivativeDailyAccountResponse | VolumeVictorAccountResponse;

export interface HistoryRank {
  accountHashId: string;
  forDate: string;
  type: CompetitionType;
  rank: number;
  localizedName: string;
  rewardAmount: number;
  rewardCurrency: string;
}

export interface HistoryInfo {
  totalRewardAmount: number;
  totalRewardCurrency: string;
  ranks: HistoryRank[];
}

export interface LeaderboardDateInfo {
  forDate: string;
  types: CompetitionType[];
}
export interface LeaderboardDateResponse {
  forDate: string;
  type: CompetitionType;
  localizedName: string;
}

export interface RankStanding {
  accountHashId: string;
  rank: number;
  values: LeaderboardValue[];
}

export interface MonthlyData {
  myWins: number;
  topWins: number;
  // TODO, unify all the SocialProfile types
  topProfile: {
    profileId: number;
    profile: string;
    displayName?: string;
    avatar?: string;
  };
}

export interface RankResponse {
  forDate: string;
  type: CompetitionType;
  metadata: {
    standings: Record<string, LeaderboardMetadataStandings>;
  };
  standings: RankStanding[];
  winsInMonth?: MonthlyData;
}

export const EVENTS_ERROR_TRACKING_LABEL_PREFIX = "TRACKING_ERROR: ";
