import * as Sentry from "@sentry/browser";
import { differenceInYears, parse } from "date-fns";
import axios from "@gemini-ui/services/axios";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { formatNumber } from "@gemini-ui/utils/formatNumber";

const isLoggedIn = Boolean(window.initialData?.templateProps?.user);
const isEmailVerified = Boolean(window.initialData?.templateProps?.user?.emailConfirmed);
const hasEligibleTier =
  Boolean(window.initialData?.templateProps?.user?.isFullyVerified) ||
  Boolean(window.initialData?.templateProps?.user?.isBasicPlusTier);

const firstNamePattern = /^[A-Za-z\s]+$/;
const lastNamePattern = /^[A-Za-z\-\'\.\s]+$/;
const dobPattern = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;
const ssnPattern = /^(?!123456789)(?!9|666|000)\d{3}-?(?!00)\d{2}-?(?!0000)\d{4}$/;
const phonePattern = /\(?[0-9]{3}\) ?[0-9]{3}-?[0-9]{4}/;
const zipPattern = /[0-9]{5}(-[0-9]{4})?/;
const poBoxPattern = /\b[p]*(ost)*\.*\s*[o|0]*(ffice)*\.*\s*b[o|0]x\b/i;
const streetPattern = /^[\w\.\s]+$/;
const aptPattern = /^[\w\.\#\/\s]+$/;

const getYearDiff = (value: string, dateFormat: DateFormats = DateFormats.MonthDayYear) => {
  return differenceInYears(new Date(), parse(value, dateFormat, new Date()));
};

const isCreditGeoElibible = (detectedCountryCode: string, userCountryCode: string): boolean => {
  const eligibleJurisdictions = ["US", "PR"];
  const geoIsEligible = eligibleJurisdictions.includes(detectedCountryCode?.toUpperCase());
  const residenceAddressIsEligible = eligibleJurisdictions.includes(userCountryCode?.toUpperCase());
  return !detectedCountryCode || geoIsEligible || residenceAddressIsEligible;
};

const formatPercent = (
  val: string | number | bigint,
  opt: {
    style: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  } = {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
) => {
  return formatNumber(Number(val), `en-US`, opt);
};

const formatCurrency = (
  val: string | number,
  opt = { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 }
) => {
  return new Intl.NumberFormat("en-US", opt).format(Number(val));
};

const getDashboardProps = async () => {
  try {
    const { data } = await axios.get(jsRoutes.controllers.dashboard.DashboardController.main().url);
    return data;
  } catch (error) {
    return null;
  }
};

const logAffiliateTrackingId = (isSubmitting?: boolean) => {
  const clickId = new URLSearchParams(window.location.search).get("~click_id");

  if (clickId) {
    Sentry.captureMessage(
      `User ${isSubmitting ? "submitted" : "entered"} Credit Card application with click_id ${clickId}`,
      Sentry.Severity.Info
    );
  }
};

export {
  aptPattern,
  dobPattern,
  firstNamePattern,
  formatCurrency,
  formatPercent,
  getDashboardProps,
  getYearDiff,
  hasEligibleTier,
  isCreditGeoElibible,
  isEmailVerified,
  isLoggedIn,
  lastNamePattern,
  logAffiliateTrackingId,
  phonePattern,
  poBoxPattern,
  ssnPattern,
  streetPattern,
  zipPattern,
};
