import * as React from "react";
// eslint-disable-next-line no-restricted-imports
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import MASKS from "@gemini-ui/constants/masks";
import { Button, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getError } from "@gemini-ui/utils/error";
import { getRequiredFieldErrorString } from "@gemini-ui/utils/getRequiredFieldErrorString";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  bankAccountUuid: string;
  isInternational: boolean;
  onSubmit: (data: unknown) => void;
  subaccountHashid: string;
};

const EnableWireModal = ({ isOpen, onClose, bankAccountUuid, isInternational, onSubmit, subaccountHashid }: Props) => {
  const { intl } = useIntl();

  const { showAlert } = useAlert();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleSubmit = async values => {
    try {
      setIsSubmitting(true);
      const { data } = await axios.post(
        jsRoutes.controllers.settings.WireSettingsController.post(bankAccountUuid).url,
        {
          routingNumber: values.routingNumber,
        },
        {
          headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
        }
      );

      setIsSubmitting(false);
      onSubmit(data);
    } catch (e) {
      setIsSubmitting(false);
      showAlert({
        type: AlertTypes.ERROR,
        message: getError(e),
        timeout: 8000,
      });
    }
  };

  return (
    <Modal.MultiStep
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({
        defaultMessage: "Enable wire transfers with your financial institution",
      })}
    >
      <Text.Body size="sm" mt={1}>
        {intl.formatMessage({
          defaultMessage:
            "To receive or send wires from/to your account, Gemini needs the wire routing number for your bank account.",
        })}
      </Text.Body>
      {!isInternational && (
        <Text.Body size="sm" mt={2}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Your wire routing number is often <TextBody>different</TextBody> than the bank transfer (ACH) routing number found at the bottom of your checks. Please contact your bank or visit their website to learn your wire routing number.",
            }),
            {
              TextBody: (v: React.ReactNode) => (
                <Text.Body bold as="strong">
                  {v}
                </Text.Body>
              ),
            }
          )}
        </Text.Body>
      )}
      <Spacer mt={4}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ routingNumber: "" }}
          validationSchema={Yup.object().shape({
            routingNumber: Yup.string().required(getRequiredFieldErrorString(intl)),
          })}
        >
          {({ handleBlur, handleChange, values, errors, touched }) => (
            <Form>
              <Input.Mask
                mask={MASKS.WIRE_ROUTING}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.routingNumber ? errors.routingNumber : null}
                value={values.routingNumber}
                data-testid="enable-wire-routing-input"
                name="routingNumber"
                label={intl.formatMessage({
                  defaultMessage: "Wire routing number",
                })}
                placeholder=""
              />
              <Button.Group>
                <Button.Tertiary data-testid="add-wire-routing-cancel" onClick={onClose}>
                  {intl.formatMessage({
                    defaultMessage: "Cancel",
                  })}
                </Button.Tertiary>
                <Button.Primary
                  data-testid="add-wire-routing-submit"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!values.routingNumber}
                >
                  {intl.formatMessage({
                    defaultMessage: "Next",
                  })}
                </Button.Primary>
              </Button.Group>
            </Form>
          )}
        </Formik>
      </Spacer>
    </Modal.MultiStep>
  );
};

export default EnableWireModal;
