import React from "react";
import styled from "@emotion/styled";
import { usePageData } from "@gemini-ui/contexts";
import { Colors, getColor, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const CopyrightContainer = styled.div`
  ul > li {
    font-size: 9px;
    color: ${getColor(Colors.gray[600], Colors.gray[200])};
  }
`;

const Copyright = () => {
  const { intl, buildGeminiPublicLink } = useIntl();
  const {
    templateProps: { user },
  } = usePageData();
  const artemis = user?.subaccounts.find(f => f.hashid === user?.subaccountHashid)?.derivatives;
  const company = artemis
    ? intl.formatMessage({ defaultMessage: "Gemini Artemis Pte. Ltd." })
    : intl.formatMessage({ defaultMessage: "Gemini Trust Company, LLC." });
  const year = new Date().getFullYear();

  return (
    <CopyrightContainer>
      <ul className="links">
        <li>
          {intl.formatMessage({
            defaultMessage: "Trust is Our Product™",
          })}
        </li>
        <li>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "For trademarks and patents, please see our <TextLink>Legal Notice</TextLink>.",
            }),
            {
              TextLink: (v: React.ReactNode) => (
                <Text.Link href={buildGeminiPublicLink("legal/legal-notice")} target="_blank" rel="noopener noreferrer">
                  {v}
                </Text.Link>
              ),
            }
          )}
        </li>
        <li>
          {intl.formatMessage({
            defaultMessage: "NMLS #1518126",
            description: "Copyright licensing system number",
          })}
        </li>
        <li>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "© Copyright {year} {company}",
            }),
            {
              year,
              company,
            }
          )}
        </li>
      </ul>
    </CopyrightContainer>
  );
};

export default Copyright;
