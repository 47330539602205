import React from "react";

export default function formatFormErrors(errors, errorRenderers = {}, lastValues = {}, validationAttempts = 0) {
  if (typeof errors === "string" || React.isValidElement(errors) || !errors || Array.isArray(errors)) {
    return errors;
  }

  const errs = [];

  for (const key of Object.keys(errors || {})) {
    const msgs = errors[key];
    for (let idx = 0; idx < msgs.length; idx++) {
      const msg = msgs[idx];
      const renderedMsg = __guardMethod__(errorRenderers, msg, (o, m) => o[m](lastValues[key])) || msg;
      if (renderedMsg) {
        // skip over null or "", if you want to highlight a field but show no error message
        errs.push(<li key={key + "." + idx}>{renderedMsg}</li>);
      }
    }
  }

  if (errs.length > 0) {
    // using a different `key` for each validation attempt alerts the layout renderer that the alert should
    // be reshown, even if it's been hidden.
    return <ul key={validationAttempts}>{errs}</ul>;
  } else {
    return null;
  }
}

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
