import { Theme } from "@emotion/react";
import { selectStyles } from "@gemini-ui/design-system/forms/Select/styles";

export const styledSelectControl = (theme: Theme) => ({
  control: (provided, state) => {
    return {
      ...selectStyles(theme).control(provided, state),
      // Override the icon display to match designs
      [".transfer-type-icon"]: {
        display: "none",
      },
    };
  },
});
