import { IntlShape } from "react-intl";
import { GrowAsset } from "@gemini-ui/constants/earn";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

export const createStakingDirectionOptions = (
  asset: GrowAsset,
  intl: IntlShape
): SelectOptionProps<GrowTransactionType>[] => {
  const options: SelectOptionProps<GrowTransactionType>[] = [
    {
      label: intl.formatMessage({ defaultMessage: "Stake" }),
      value: GrowTransactionType.STAKE,
    },
    {
      label: intl.formatMessage({ defaultMessage: "Unstake" }),
      value: GrowTransactionType.UNSTAKE,
      isDisabled: !(Number(asset.earningInterest.value) > 0),
    },
  ];

  return options;
};
