import { IconSize } from "@hubble/icons/web";
import { FormElementSize } from "@gemini-ui/design-system/forms/shared/constants";
import { motion, size as sizeTokens, text } from "@gemini-ui/design-system/primitives";

const { body, numeric } = text.typesets;

const FONT_SIZE = {
  sm: body.xs.fontSize,
  md: body.sm.fontSize,
  lg: body.md.fontSize,
};

const NUMERIC_LETTER_SPACING = {
  sm: numeric.body.xs.letterSpacing,
  md: numeric.body.sm.letterSpacing,
  lg: numeric.body.md.letterSpacing,
};

export const ICON_SIZE: Record<FormElementSize, IconSize> = {
  sm: "xs",
  md: "sm",
  lg: "md",
};

export const FORM_ELEMENT_TRANSITION_VALUE = `background 300ms ${motion.timing.easeInOut}, border-color 300ms ${motion.timing.easeInOut}, box-shadow 300ms ${motion.timing.easeInOut}`;

export function getSize(size: FormElementSize) {
  return sizeTokens.control[size];
}

export function getFontSize(size: FormElementSize) {
  return FONT_SIZE[size];
}

export function getNumericLetterSpacing(size: FormElementSize) {
  return NUMERIC_LETTER_SPACING[size];
}

export function getIconSize(size: FormElementSize): IconSize {
  return ICON_SIZE[size];
}
