import React, { createContext, FC, useContext, useMemo } from "react";
import { FeatureFlagProvider } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { InitialData } from "@gemini-ui/constants/initialData";
import { pagePropsTransformers } from "@gemini-ui/transformers";

export type InitialDataType<PagePropsType = unknown> = Omit<InitialData, "pageProps"> & {
  /**
   * @deprecated Don't use pageProps from the usePageData hook. As part of DEMONO efforts, the frontend codebase is moving away from pageProps; favoring requesting data from separate services when it's needed, not before.
   *
   * Additionally, using pageProps from this hook has weak typesafety. Components imported into pages where the pageProps assumed by this hook aren't available could fail.
   *
   *  Instead, fetch the data from GraphQL or a route (see web-server/conf/routes). If both of these options are unavailable, prop drill pageProps from the page level (so we have clearer tracing for refactor efforts).
   * */
  pageProps: PagePropsType;
};

function createPageDataContext<PagePropsType>() {
  return createContext<InitialDataType<PagePropsType> | null>(null);
}

function isPageData<T>(pageData: InitialDataType<T> | null): pageData is InitialDataType<T> {
  return (
    pageData !== null &&
    pageData.hasOwnProperty("pageName") &&
    pageData.hasOwnProperty("pageProps") &&
    pageData.hasOwnProperty("templateProps")
  );
}

const PageDataContext = createPageDataContext();

export function usePageData<T>() {
  const pageData = useContext(PageDataContext) as InitialDataType<T>;
  if (!isPageData<T>(pageData)) {
    throw new Error("Your component must be wrapped in PageDataProvider");
  }
  return pageData;
}

export const PageDataProvider: FC<{ pageData: InitialDataType<unknown>; children?: React.ReactNode }> = ({
  pageData,
  children,
}) => {
  const { pageName, pageProps, ...restOfPageData } = pageData;

  const transformedPageProps = Boolean(pagePropsTransformers[pageName])
    ? pagePropsTransformers[pageName](pageProps)
    : pageProps;

  const value = useMemo(
    () => ({ pageName, pageProps: transformedPageProps, ...restOfPageData }),
    [pageName, restOfPageData, transformedPageProps]
  );

  const featureFlags = useMemo(() => pageData.templateProps?.featureFlags, [pageData.templateProps?.featureFlags]);

  return (
    <PageDataContext.Provider value={value}>
      <FeatureFlagProvider featureFlags={featureFlags}>{children}</FeatureFlagProvider>
    </PageDataContext.Provider>
  );
};

export const usePageDataAndCallOnRefresh = (fn: () => void) => {
  const result = usePageData();
  const now = new Date().getTime();
  const lastRun = React.useRef(now);
  if (now - lastRun.current > 5000) {
    fn();
    lastRun.current = now;
  }
  return result;
};
