import * as React from "react";
import { IconAlertFilled, IconInfoFilled, IconProps, IconWarningFilled } from "@hubble/icons";
import { HubbleProvider } from "@hubble/web";
import { Button } from "@gemini-ui/design-system/Button";
import { Flex } from "@gemini-ui/design-system/Flex";
import {
  SystemAlertProps,
  SystemAlertVariant,
  variantToThemeSchemeMap,
} from "@gemini-ui/design-system/SystemAlert/constants";
import {
  SystemAlertButtonContainer,
  SystemAlertText,
  SystemAlertWrapper,
} from "@gemini-ui/design-system/SystemAlert/styles";
import { useIntl } from "@gemini-ui/utils/intl";

const AlertIconMap: { [key in SystemAlertVariant]: React.FunctionComponent<React.PropsWithChildren<IconProps>> } = {
  informational: IconInfoFilled,
  warning: IconWarningFilled,
  negative: IconAlertFilled,
};

export function SystemAlert({
  variant = "negative",
  onClose,
  children,
  "data-testid": dataTestId,
  actionButtonProps,
  secondaryButtonProps,
}: SystemAlertProps) {
  const { intl } = useIntl();

  const scheme = variantToThemeSchemeMap[variant];
  const AlertIcon = AlertIconMap[variant];
  const hasActionButton = Boolean(actionButtonProps);
  return (
    <HubbleProvider scheme={scheme}>
      <SystemAlertWrapper
        data-testid={dataTestId}
        variant={variant}
        p={1.5}
        hasActionButton={hasActionButton}
        justifyContent="space-between"
      >
        <Flex align="center">
          <Flex align="flex-start">
            <Flex shrink={0}>
              <AlertIcon />
            </Flex>
            <SystemAlertText size="md" ml={1.5} mr={1.5} as="div" data-testid={dataTestId && `${dataTestId}-text`}>
              {children}
            </SystemAlertText>
          </Flex>
        </Flex>
        {(Boolean(onClose) || hasActionButton) && (
          <SystemAlertButtonContainer hasActionButton={hasActionButton}>
            {hasActionButton && (
              <Button.Primary
                {...actionButtonProps}
                mr={1}
                display={actionButtonProps.href ? "inline-flex" : "inline"}
                data-testid={dataTestId && `${dataTestId}-action-button`}
                size="sm"
              />
            )}
            {(Boolean(onClose) || Boolean(secondaryButtonProps)) && (
              <Button.Secondary
                data-testid={dataTestId && `${dataTestId}-dismiss-button`}
                display={secondaryButtonProps?.href ? "inline-flex" : "inline"}
                cta={intl.formatMessage({ defaultMessage: "Dismiss" })}
                size="sm"
                onClick={onClose}
                {...secondaryButtonProps}
              />
            )}
          </SystemAlertButtonContainer>
        )}
      </SystemAlertWrapper>
    </HubbleProvider>
  );
}
