import { CurrencyPairDetail } from "@gemini-common/scripts/constants/currencies";
import { OrderType, Side } from "@gemini-ui/constants/orders";
import { LimitOrderDisplayType } from "@gemini-ui/pages/ActiveTrader/Spot/BuySell/Ticket/FormFields/constants";
import { PriceShortcuts } from "@gemini-ui/pages/ActiveTrader/Spot/BuySell/Ticket/FormFields/Shortcuts/constants";
import { InputNumberT } from "@gemini-ui/utils/inputNumber";

export interface CommonFieldProps {
  type: string;
  onChange: (e: any) => void;
  onFocus: (e: any) => void;
  onBlur: (e: any) => void;
  autoComplete: string;
}

export const POLLING_INTERVAL = 5000;

export interface OrderFormPersistedState {
  pair?: CurrencyPairDetail;
  side?: Side;
  price?: InputNumberT;
  stopPrice?: InputNumberT;
  quantity?: InputNumberT;
  order?: InputNumberT;
  orderType?: OrderType;
  limitOrderAmtDisplayType?: LimitOrderDisplayType;
  limitPriceShortcut?: PriceShortcuts;
}

export const DEFAULT_ORDER_FORM_STATE: OrderFormPersistedState = {
  pair: undefined,
  side: "buy",
  price: undefined,
  stopPrice: undefined,
  quantity: undefined,
  order: undefined,
  orderType: undefined,
  limitOrderAmtDisplayType: undefined,
  limitPriceShortcut: undefined,
} as const;
