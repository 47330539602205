export const countriesList = {
  af: "Afghanistan",
  ax: "Åland",
  al: "Albania",
  dz: "Algeria",
  ad: "Andorra",
  ao: "Angola",
  ai: "Anguilla",
  aq: "Antarctica",
  ag: "Antigua and Barbuda",
  ar: "Argentina",
  am: "Armenia",
  aw: "Aruba",
  au: "Australia",
  at: "Austria",
  az: "Azerbaijan",
  bh: "Bahrain",
  bd: "Bangladesh",
  bb: "Barbados",
  by: "Belarus",
  be: "Belgium",
  bz: "Belize",
  bj: "Benin",
  bm: "Bermuda",
  bt: "Bhutan",
  bo: "Bolivia",
  bq: "Bonaire, Saint Eustatius and Saba",
  ba: "Bosnia and Herzegovina",
  bw: "Botswana",
  bv: "Bouvet Island",
  br: "Brazil",
  io: "British Indian Ocean Territory",
  bn: "Brunei",
  bg: "Bulgaria",
  bf: "Burkina Faso",
  bi: "Burundi",
  kh: "Cambodia",
  cm: "Cameroon",
  ca: "Canada",
  cv: "Cape Verde",
  ky: "Cayman Islands",
  cf: "Central African Republic",
  td: "Chad",
  cl: "Chile",
  cn: "China",
  cx: "Christmas Island",
  cc: "Cocos(Keeling) Islands",
  co: "Colombia",
  km: "Comoros",
  cg: "Congo",
  cd: "DRC",
  ck: "Cook Islands",
  cr: "Costa Rica",
  ci: "Cote d'Ivoire",
  hr: "Croatia",
  cu: "Cuba",
  cw: "Curacao",
  cy: "Cyprus",
  cz: "Czech Republic",
  dk: "Denmark",
  dj: "Djibouti",
  dm: "Dominica",
  do: "Dominican Republic",
  ec: "Ecuador",
  eg: "Egypt",
  sv: "El Salvador",
  gq: "Equatorial Guinea",
  er: "Eritrea",
  ee: "Estonia",
  et: "Ethiopia",
  fk: "Falkland Islands (Islas Malvinas)",
  fo: "Faroe Islands",
  fj: "Fiji",
  fi: "Finland",
  fr: "France",
  gf: "French Guiana",
  pf: "French Polynesia",
  tf: "French Southern and Antartic Lands",
  ga: "Gabon",
  ge: "Georgia",
  de: "Germany",
  gh: "Ghana",
  gi: "Gibraltar",
  gr: "Greece",
  gl: "Greenland",
  gd: "Grenada",
  gp: "Guadeloupe",
  gu: "Guam",
  gt: "Guatemala",
  gg: "Guernsey",
  gn: "Guinea",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  ht: "Haiti",
  hm: "Heard Island and McDonald Islands",
  va: "Holy See (Vatican City)",
  hn: "Honduras",
  hk: "Hong Kong",
  hu: "Hungary",
  is: "Iceland",
  in: "India",
  id: "Indonesia",
  ir: "Iran",
  iq: "Iraq",
  ie: "Republic of Ireland",
  im: "Isle of Man",
  il: "Israel",
  it: "Italy",
  jm: "Jamaica",
  jp: "Japan",
  je: "Jersey",
  jo: "Jordan",
  kz: "Kazakhstan",
  ke: "Kenya",
  ki: "Kiribati",
  kp: "North Korea",
  kr: "South Korea",
  kw: "Kuwait",
  kg: "Kyrgyzstan",
  la: "Laos",
  lv: "Latvia",
  lb: "Lebanon",
  ls: "Lesotho",
  lr: "Liberia",
  ly: "Libya",
  li: "Liechtenstein",
  lt: "Lithuania",
  lu: "Luxembourg",
  mo: "Macau",
  mk: "Macedonia",
  mg: "Madagascar",
  mw: "Malawi",
  my: "Malaysia",
  mv: "Maldives",
  ml: "Mali",
  mt: "Malta",
  mh: "Marshall Islands",
  mq: "Martinique",
  mr: "Mauritania",
  mu: "Mauritius",
  yt: "Mayotte",
  mx: "Mexico",
  fm: "Micronesia, Federated States of",
  md: "Moldova",
  mc: "Monaco",
  mn: "Mongolia",
  me: "Montenegro",
  ms: "Montserrat",
  ma: "Morocco",
  mz: "Mozambique",
  mm: "Myanmar",
  na: "Namibia",
  nr: "Nauru",
  np: "Nepal",
  nl: "Netherlands",
  nc: "New Caledonia",
  nz: "New Zealand",
  ni: "Nicaragua",
  ne: "Niger",
  ng: "Nigeria",
  nu: "Niue",
  nf: "Norfolk Island",
  mp: "Northern Mariana Islands",
  no: "Norway",
  ps: "Gaza Strip and West Bank",
  om: "Oman",
  pk: "Pakistan",
  pw: "Palau",
  pa: "Panama",
  pg: "Papua New Guinea",
  py: "Paraguay",
  pe: "Peru",
  ph: "Philippines",
  pn: "Pitcairn Islands",
  pl: "Poland",
  pt: "Portugal",
  qa: "Qatar",
  re: "Reunion",
  ro: "Romania",
  ru: "Russia",
  rw: "Rwanda",
  bl: "Saint Barthelemy",
  sh: "Saint Helena, Ascension and Tristan da Cunha",
  kn: "Saint Kitts and Nevis",
  lc: "Saint Lucia",
  mf: "Saint Martin",
  pm: "Saint Pierre and Miquelon",
  vc: "Saint Vincent and The Grenadines",
  ws: "Samoa",
  sm: "San Marino",
  st: "Sao Tome and Principe",
  sa: "Saudi Arabia",
  sn: "Senegal",
  rs: "Serbia",
  sc: "Seychelles",
  sl: "Sierra Leone",
  sg: "Singapore",
  sx: "Sint Maarten",
  sk: "Slovakia",
  si: "Slovenia",
  sb: "Solomon Islands",
  so: "Somalia",
  za: "South Africa",
  gs: "South Georgia and the South Sandwich Islands",
  es: "Spain",
  lk: "Sri Lanka",
  sd: "Sudan",
  sr: "Suriname",
  sj: "Svalbard and Jan Mayen",
  sz: "Swaziland",
  se: "Sweden",
  ch: "Switzerland",
  sy: "Syria",
  tw: "Taiwan",
  tj: "Tajikistan",
  tz: "Tanzania",
  th: "Thailand",
  bs: "The Bahamas",
  gm: "The Gambia",
  tl: "Timor-Leste",
  tg: "Togo",
  tk: "Tokelau",
  to: "Tonga",
  tt: "Trinidad and Tobago",
  tn: "Tunisia",
  tr: "Turkey",
  tm: "Turkmenistan",
  tc: "Turks and Caicos Islands",
  tv: "Tuvalu",
  ug: "Uganda",
  ua: "Ukraine",
  ae: "United Arab Emirates",
  gb: "United Kingdom",
  us: "United States",
  uy: "Uruguay",
  uz: "Uzbekistan",
  vu: "Vanuatu",
  ve: "Venezuela",
  vn: "Vietnam",
  vg: "British Virgin Islands",
  wf: "Wallis et Futuna",
  eh: "Western Sahara",
  ye: "Yemen",
  zm: "Zambia",
  zw: "Zimbabwe",
} as const;

const ofacCountriesEnum = {
  af: "Afghanistan",
  cu: "Cuba",
  ir: "Iran",
  ly: "Libya",
  kp: "North Korea",
  sd: "Sudan",
  ss: "South Sudan",
  sy: "Syria",
  by: "Belarus",
  bi: "Burundi",
  cf: "Central African Republic",
  cg: "Congo",
  iq: "Iraq",
  lb: "Lebanon",
  ru: "Russia",
  so: "Somalia",
  ua: "Ukraine",
  ve: "Venezuela",
  ye: "Yemen",
  zw: "Zimbabwe",
} as const;

export const countryCodeToNationality = {
  ie: "Irish",
};
// region not allowed during onboarding
export const noRegionAllowedCountries = ["gb", "sg"];

// https://en.wikipedia.org/wiki/List_of_postal_codes
export const noZipcodeRequiredCountries = [
  "ao",
  "ag",
  "aw",
  "bs",
  "bz",
  "bj",
  "bm",
  "bo",
  "bq",
  "bw",
  "bf",
  "bi",
  "cm",
  "cf",
  "td",
  "cg",
  "cd",
  "ck",
  "ci",
  "co",
  "cw",
  "dj",
  "dm",
  "tl",
  "gq",
  "er",
  "fj",
  "tf",
  "ga",
  "gm",
  "gh",
  "gd",
  "gy",
  "hk",
  "ie",
  "ki",
  "kp",
  "ly",
  "mo",
  "mw",
  "ml",
  "mr",
  "na",
  "nr",
  "nl",
  "nu",
  "qa",
  "rw",
  "kn",
  "st",
  "sc",
  "sl",
  "sx",
  "sb",
  "ss",
  "sr",
  "sy",
  "tg",
  "tk",
  "to",
  "tv",
  "ug",
  "ae",
  "vu",
  "ye",
  "zw",
] as const;

export const stateRequiredCountries = ["us", "ca"] as const;

// via https://github.com/melwynfurtado/postcode-validator
const postalCodeRegexes: Map<string, RegExp> = new Map([
  [
    countriesList.gb,
    /^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){1}$/i,
  ],
  [countriesList.je, /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/],
  [countriesList.gg, /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/],
  [countriesList.im, /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/],
  [countriesList.us, /^([0-9]{5})$/],
  [countriesList.ca, /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i],
  [countriesList.ie, /^$|^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/],
  [countriesList.de, /^\d{5}$/],
  [countriesList.jp, /^\d{3}-\d{4}$/],
  [countriesList.fr, /^\d{2}[ ]?\d{3}$/],
  [countriesList.au, /^\d{4}$/],
  [countriesList.it, /^\d{5}$/],
  [countriesList.ch, /^\d{4}$/],
  [countriesList.at, /^(?!0)\d{4}$/],
  [countriesList.es, /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/],
  [countriesList.nl, /^\d{4}[ ]?[A-Z]{2}$/],
  [countriesList.be, /^\d{4}$/],
  [countriesList.dk, /^\d{4}$/],
  [countriesList.se, /^(SE-)?\d{3}[ ]?\d{2}$/],
  [countriesList.no, /^\d{4}$/],
  [countriesList.br, /^\d{5}[\-]?\d{3}$/],
  [countriesList.pt, /^\d{4}([\-]\d{3})?$/],
  [countriesList.fi, /^(FI-|AX-)?\d{5}$/],
  [countriesList.ax, /^22\d{3}$/],
  [countriesList.kr, /^\d{5}$/],
  [countriesList.cn, /^\d{6}$/],
  [countriesList.tw, /^\d{3}(\d{2})?$/],
  [countriesList.sg, /^\d{6}$/],
  [countriesList.dz, /^\d{5}$/],
  [countriesList.ad, /^AD\d{3}$/],
  [countriesList.ar, /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/],
  [countriesList.am, /^(37)?\d{4}$/],
  [countriesList.az, /^\d{4}$/],
  [countriesList.bh, /^((1[0-2]|[2-9])\d{2})?$/],
  [countriesList.bd, /^\d{4}$/],
  [countriesList.bb, /^(BB\d{5})?$/],
  [countriesList.by, /^\d{6}$/],
  [countriesList.bm, /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/],
  [countriesList.ba, /^\d{5}$/],
  [countriesList.io, /^BBND 1ZZ$/],
  [countriesList.bn, /^[A-Z]{2}[ ]?\d{4}$/],
  [countriesList.bg, /^\d{4}$/],
  [countriesList.kh, /^\d{5}$/],
  [countriesList.cv, /^\d{4}$/],
  [countriesList.cl, /^\d{7}$/],
  [countriesList.cr, /^(\d{4,5}|\d{3}-\d{4})$/],
  [countriesList.hr, /^(HR-)?\d{5}$/],
  [countriesList.cy, /^\d{4}$/],
  [countriesList.cz, /^\d{3}[ ]?\d{2}$/],
  [countriesList.do, /^\d{5}$/],
  [countriesList.ec, /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/],
  [countriesList.eg, /^\d{5}$/],
  [countriesList.ee, /^\d{5}$/],
  [countriesList.fo, /^\d{3}$/],
  [countriesList.ge, /^\d{4}$/],
  [countriesList.gr, /^\d{3}[ ]?\d{2}$/],
  [countriesList.gl, /^39\d{2}$/],
  [countriesList.gt, /^\d{5}$/],
  [countriesList.ht, /^\d{4}$/],
  [countriesList.hn, /^(?:\d{5})?$/],
  [countriesList.hu, /^\d{4}$/],
  [countriesList.is, /^\d{3}$/],
  [countriesList.in, /^\d{6}$/],
  [countriesList.id, /^\d{5}$/],
  [countriesList.il, /^\d{5,7}$/],
  [countriesList.jo, /^\d{5}$/],
  [countriesList.kz, /^\d{6}$/],
  [countriesList.ke, /^\d{5}$/],
  [countriesList.kw, /^\d{5}$/],
  [countriesList.la, /^\d{5}$/],
  [countriesList.lv, /^(LV-)?\d{4}$/],
  [countriesList.lb, /^(\d{4}([ ]?\d{4})?)?$/],
  [countriesList.li, /^(948[5-9])|(949[0-7])$/],
  [countriesList.lt, /^(LT-)?\d{5}$/],
  [countriesList.lu, /^(L-)?\d{4}$/],
  [countriesList.mk, /^\d{4}$/],
  [countriesList.my, /^\d{5}$/],
  [countriesList.mv, /^\d{5}$/],
  [countriesList.mt, /^[A-Z]{3}[ ]?\d{2,4}$/],
  [countriesList.mu, /^((\d|[A-Z])\d{4})?$/],
  [countriesList.mx, /^\d{5}$/],
  [countriesList.md, /^\d{4}$/],
  [countriesList.mc, /^980\d{2}$/],
  [countriesList.ma, /^\d{5}$/],
  [countriesList.np, /^\d{5}$/],
  [countriesList.nz, /^\d{4}$/],
  [countriesList.ni, /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/],
  [countriesList.ng, /^(\d{6})?$/],
  [countriesList.om, /^(PC )?\d{3}$/],
  [countriesList.pk, /^\d{5}$/],
  [countriesList.py, /^\d{4}$/],
  [countriesList.ph, /^\d{4}$/],
  [countriesList.pl, /^\d{2}-\d{3}$/],
  // [countriesList.pr, /^00[679]\d{2}([ \-]\d{4})?$/],
  [countriesList.ro, /^\d{6}$/],
  [countriesList.ru, /^\d{6}$/],
  [countriesList.sm, /^4789\d$/],
  [countriesList.sa, /^\d{5}$/],
  [countriesList.sn, /^\d{5}$/],
  [countriesList.sk, /^\d{3}[ ]?\d{2}$/],
  [countriesList.si, /^(SI-)?\d{4}$/],
  [countriesList.za, /^\d{4}$/],
  [countriesList.lk, /^\d{5}$/],
  [countriesList.tj, /^\d{6}$/],
  [countriesList.th, /^\d{5}$/],
  [countriesList.tn, /^\d{4}$/],
  [countriesList.tr, /^\d{5}$/],
  [countriesList.tm, /^\d{6}$/],
  [countriesList.ua, /^\d{5}$/],
  [countriesList.uy, /^\d{5}$/],
  [countriesList.uz, /^\d{6}$/],
  [countriesList.va, /^00120$/],
  [countriesList.ve, /^\d{4}$/],
  [countriesList.zm, /^\d{5}$/],
  // [countriesList.as, /^96799$/],
  [countriesList.cc, /^6799$/],
  [countriesList.ck, /^\d{4}$/],
  [countriesList.rs, /^\d{5,6}$/],
  [countriesList.me, /^8\d{4}$/],
  // [countriesList.cs, /^\d{5}$/],
  // [countriesList.yu, /^\d{5}$/],
  [countriesList.cx, /^6798$/],
  [countriesList.et, /^\d{4}$/],
  [countriesList.fk, /^FIQQ 1ZZ$/],
  [countriesList.nf, /^2899$/],
  [countriesList.fm, /^(9694[1-4])([ \-]\d{4})?$/],
  [countriesList.gf, /^9[78]3\d{2}$/],
  [countriesList.gn, /^\d{3}$/],
  [countriesList.gp, /^9[78][01]\d{2}$/],
  [countriesList.gs, /^SIQQ 1ZZ$/],
  [countriesList.gu, /^969[123]\d([ \-]\d{4})?$/],
  [countriesList.gq, /^\d{4}$/],
  [countriesList.hm, /^\d{4}$/],
  [countriesList.iq, /^\d{5}$/],
  [countriesList.kg, /^\d{6}$/],
  [countriesList.lr, /^\d{4}$/],
  [countriesList.ls, /^\d{3}$/],
  [countriesList.mg, /^\d{3}$/],
  [countriesList.mh, /^969[67]\d([ \-]\d{4})?$/],
  [countriesList.mn, /^\d{6}$/],
  [countriesList.mp, /^9695[012]([ \-]\d{4})?$/],
  [countriesList.mq, /^9[78]2\d{2}$/],
  [countriesList.nc, /^988\d{2}$/],
  [countriesList.ne, /^\d{4}$/],
  // [countriesList.vi, /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/],
  [countriesList.pf, /^987\d{2}$/],
  [countriesList.pg, /^\d{3}$/],
  [countriesList.pm, /^9[78]5\d{2}$/],
  [countriesList.pn, /^PCRN 1ZZ$/],
  [countriesList.pw, /^96940$/],
  [countriesList.re, /^9[78]4\d{2}$/],
  [countriesList.sh, /^(ASCN|STHL) 1ZZ$/],
  [countriesList.sj, /^\d{4}$/],
  [countriesList.so, /^\d{5}$/],
  [countriesList.sz, /^[HLMS]\d{3}$/],
  [countriesList.tc, /^TKCA 1ZZ$/],
  [countriesList.wf, /^986\d{2}$/],
  // [countriesList.xk, /^\d{5}$/],
  [countriesList.yt, /^976\d{2}$/],
  ["international", /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/i],
]);

export const getPostalCodeRegexes = countryCode => {
  return postalCodeRegexes.has(countriesList[countryCode])
    ? postalCodeRegexes.get(countriesList[countryCode])
    : postalCodeRegexes.get("international");
};

type OfacCountryList = typeof ofacCountriesEnum;
type OfacCountryAbbreviation = keyof OfacCountryList;
const ofacCountries: OfacCountryAbbreviation[] = Object.keys(ofacCountriesEnum) as OfacCountryAbbreviation[];

const all = {};
for (const [code, name] of Object.entries(countriesList)) {
  all[code] = { name };
}

export type CountryList = typeof countriesList;
export type CountryAbbreviation = keyof CountryList;
export type CountryName = CountryList[CountryAbbreviation];

const menuItems = countriesList;

export { all, menuItems, ofacCountries, ofacCountriesEnum, OfacCountryAbbreviation };
