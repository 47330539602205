import React, { forwardRef } from "react";
import { Flex } from "@gemini-ui/design-system/Flex";
import { ListItemDefaultElement, ListItemPolymorphic } from "@gemini-ui/design-system/ListItem/constants";
import { ListItemHr, ListItemInner, ListItemWrapper, StyledFlex } from "@gemini-ui/design-system/ListItem/styles";
import { cssShorthandPropsFilter, Spacer } from "@gemini-ui/design-system/primitives";

export const calculateSpacingHorizontalScale = padding => {
  let spacingHorizontalScale;
  if (padding === "md") {
    spacingHorizontalScale = 3;
  } else if (padding === "none") {
    spacingHorizontalScale = 0;
  } else {
    spacingHorizontalScale = 2;
  }
  return spacingHorizontalScale;
};

export const ListItem = forwardRef(function ForwardedListItem(
  {
    alignItems,
    as = ListItemDefaultElement,
    bottom,
    centerProps,
    children,
    hasDivider = true,
    left,
    leftProps,
    rightProps,
    padding = "md",
    right,
    size = "default",
    parentAs = "li",
    parentAriaSetsize,
    parentAriaPosinset,
    ...props
  },
  ref
) {
  const spacingHorizontalScale = calculateSpacingHorizontalScale(padding);
  const spacingVerticalScale = size === "default" ? 2.5 : 2;

  const shorthandPadding = {
    pb: spacingVerticalScale,
    pl: spacingHorizontalScale,
    pr: spacingHorizontalScale,
    pt: spacingVerticalScale,
  } as const;

  const shorthandMargin = {
    ml: spacingHorizontalScale,
    mr: spacingHorizontalScale,
  } as const;

  return (
    <ListItemWrapper
      as={parentAs}
      {...cssShorthandPropsFilter(props)}
      aria-setsize={parentAriaSetsize}
      aria-posinset={parentAriaPosinset}
    >
      <ListItemInner as={as} ref={ref} {...props}>
        <Flex {...shorthandPadding}>
          {left && (
            <Flex alignItems={alignItems} mr={1.5} {...leftProps}>
              {left}
            </Flex>
          )}
          <Flex justifyContent={alignItems} flexDirection="column" {...centerProps}>
            {children}
            {bottom && <Spacer mt={1.5}>{bottom}</Spacer>}
          </Flex>
          {right && (
            <StyledFlex alignItems={alignItems} ml="auto" pl={1.5} overflowX="hidden" {...rightProps}>
              {right}
            </StyledFlex>
          )}
        </Flex>
        {hasDivider && <ListItemHr as="hr" m={0} {...shorthandMargin} />}
      </ListItemInner>
    </ListItemWrapper>
  );
}) as ListItemPolymorphic;
