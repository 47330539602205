import {
  StakingDataUrlParams,
  StakingDepositUrlParams,
  StakingTransferQuoteUrlParams,
  StakingValidatorDetailsUrlParams,
  StakingValidatorsListUrlParams,
  StakingWithdrawUrlParams,
} from "@gemini-ui/services/staking/constants";

/**
 * Retrieve staking tab data:
 *
 * GET `/stake`
 *
 * This endpoint will return a `JsPageData` response.
 *
 * Reference: `jsRoutes.controllers.earn.EarnController`
 */
export const stakingDataUrl = (_?: StakingDataUrlParams) => `/stake`;

/**
 * Retrieve a staking deposit quote for transfer or Buy & Stake order:
 *
 * GET `/stake/deposit/quote?providerId=:providerId&asset=:currency&amount=:amount`
 *
 * Reference: `jsRoutes.controllers.earn.EarnController`
 */
export const stakingTransferQuoteUrl = ({ providerId, currency, amount }: StakingTransferQuoteUrlParams) =>
  `/stake/deposit/quote?providerId=${providerId}&asset=${currency}&amount=${amount}`;

/**
 * Retrieve details for a single Staking Pro validator:
 *
 * GET `/stake/api/:currency/validators/:validatorId`
 *
 * Reference: `jsRoutes.controllers.earn.EarnController`
 */
export const stakingValidatorDetailsUrl = ({ currency, validatorId }: StakingValidatorDetailsUrlParams) =>
  `/stake/api/${currency}/validators/${validatorId}`;

/**
 * Retrieve all Staking Pro validators for a currency:
 *
 * GET `/stake/api/:currency/validators`
 *
 * Reference: `jsRoutes.controllers.earn.EarnController`
 */
export const stakingValidatorsUrl = ({ currency }: StakingValidatorsListUrlParams) =>
  `/stake/api/${currency}/validators`;

/**
 * Transfer to staking deposits:
 *
 * POST `/stake/deposit`
 *
 * This endpoints requires the `StakingTransferQueryParams` parameters for a successful staking deposit.
 *
 * Reference: `jsRoutes.controllers.earn.EarnDepositController`
 *
 * For Buy and Stake, see `jsRoutes.controllers.retail.InstantOrderController.post().url`
 */
export const stakeSubmitDepositUrl = (_?: StakingDepositUrlParams) => `/stake/deposit`;

/**
 * Withdraw from staking:
 *
 * POST `/stake/redeem`
 *
 * This endpoints requires the `StakingWithdrawQueryParams` parameters for a successful staking withdrawal.
 *
 * Reference: `jsRoutes.controllers.earn.EarnRedeemController`
 */
export const stakeSubmitWithdrawUrl = (_?: StakingWithdrawUrlParams) => `/stake/redeem`;
