import { CurrencyPairDetail, isPerpetualPair } from "@gemini-common/scripts/constants/currencies";
import {
  DEFAULT_ORDER_FORM_STATE,
  OrderFormPersistedState,
} from "@gemini-ui/pages/ActiveTrader/Spot/BuySell/constants";
import { LayoutMarket } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/types";
import storage from "@gemini-ui/utils/storage";

// ********************* AT ORDER FORM PERSISTED STATE ********************* //

const ORDER_FORM_STORAGE_KEY = "trade/AT-OrderForm-PersistedState";

export const ORDER_FORM_PERSISTED_KEYS = {
  pair: "pair",
  side: "side",
  price: "price",
  stopPrice: "stopPrice",
  quantity: "quantity",
  order: "order",
  orderType: "orderType",
  limitOrderAmtDisplayType: "limitOrderAmtDisplayType",
  limitPriceShortcut: "limitPriceShortcut",
} as const;

export const clearATOrderFormPersistedState = () => {
  storage.remove(`${ORDER_FORM_STORAGE_KEY}/PRIMARY`, "sessionStorage");
  storage.remove(`${ORDER_FORM_STORAGE_KEY}/SECONDARY`, "sessionStorage");
};

function parsePersistedState(layoutMarket: LayoutMarket): OrderFormPersistedState {
  const persistedState: string | null = storage.get(`${ORDER_FORM_STORAGE_KEY}/${layoutMarket}`, "sessionStorage");
  if (!persistedState) return {};
  try {
    return JSON.parse(persistedState);
  } catch (e) {
    storage.remove(`${ORDER_FORM_STORAGE_KEY}/${layoutMarket}`, "sessionStorage");
    return {};
  }
}

export const getATOrderFormPersistedState = (
  pair: CurrencyPairDetail,
  layoutMarket: LayoutMarket
): OrderFormPersistedState => {
  const persistedState = parsePersistedState(layoutMarket);

  if (persistedState.pair?.symbol !== pair.symbol) {
    // check if pair is perps pair, if so reset limitOrderAmtDisplayType
    const isPerps = isPerpetualPair(pair.symbol);
    if (isPerps) persistedState.limitOrderAmtDisplayType = undefined;

    const persistedPriceCurrency = persistedState.pair?.priceCurrency;
    const newPriceCurrency = pair.priceCurrency;
    // reset currency display if the price currency has changed
    const newLimitOrderAmtDisplayType =
      persistedPriceCurrency !== newPriceCurrency
        ? DEFAULT_ORDER_FORM_STATE.limitOrderAmtDisplayType
        : persistedState.limitOrderAmtDisplayType;

    // explicitly clear / keep certain values when new pair is found
    const newPairState: OrderFormPersistedState = {
      ...DEFAULT_ORDER_FORM_STATE,
      side: persistedState.side,
      orderType: persistedState.orderType,
      limitOrderAmtDisplayType: newLimitOrderAmtDisplayType,
      pair,
    };
    storage.set(`${ORDER_FORM_STORAGE_KEY}/${layoutMarket}`, JSON.stringify(newPairState), "sessionStorage");
    return newPairState;
  }
  return persistedState;
};

export const setATOrderFormPersistedState = <K extends keyof OrderFormPersistedState>(
  key: keyof OrderFormPersistedState,
  value: OrderFormPersistedState[K],
  pair: CurrencyPairDetail,
  layoutMarket: LayoutMarket
) => {
  const persistedState = getATOrderFormPersistedState(pair, layoutMarket);
  const newPersistedState: OrderFormPersistedState = { ...persistedState, [key]: value, pair };
  storage.set(`${ORDER_FORM_STORAGE_KEY}/${layoutMarket}`, JSON.stringify(newPersistedState), "sessionStorage");
};
