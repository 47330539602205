import {
  CurrencyShortName,
  CurrencyShortNameFiat,
  CurrencyShortNameSupportedCryptos,
} from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { GrowTransfer } from "@gemini-ui/constants/orders";
import { StakingBalanceState } from "@gemini-ui/pages/Earn/StakingAssetDetailsPage/types";
import { CurrencyOrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { Totals } from "@gemini-ui/pages/RetailTrade/constants";

export interface GrowPageProps {
  summary: Totals;
  assets: GrowAssets[];
}

export enum CONSENTED_FORMS {
  EARN_TERMS_AND_CONDITIONS = "EarnTermsAndConditions",
  EARN_MASTER_LOAN_AGREEMENT = "EarnMasterLoanAgreement",
  STAKING_AGREEMENT = "StakingTermsAndConditions",
}

export type EarnConsentForms = CONSENTED_FORMS[];

type EarnFormsByProvider = Record<
  string, // e.g. 5fc5257b-f5bf-40b4-8094-0631c4877824
  { allForms: EarnConsentForms; consentFormsAgreed: Partial<EarnConsentForms> }
>;

export interface EarnEligibilityInfo {
  byProvider: EarnFormsByProvider;
  consentFormsAgreed: Partial<EarnConsentForms>;
  eligibleForEarn: boolean;
  eligibleForGrow: boolean;
  eligibleForStaking: boolean;
  eligibleForPrivateStaking: boolean;
  formDetails: { formName: CONSENTED_FORMS; pageTitle: string }[];
}

export type GrowAssets = { [currency in CurrencyShortName]?: GrowAsset };

export interface GrowAsset {
  currency: CurrencyShortNameSupportedCryptos;
  interestProviders: InterestProvider[];
  availableForEarningInterest: MoneyProps;
  availableForEarningInterestNotional: MoneyProps<CurrencyShortNameFiat>;
  earningInterest: MoneyProps;
  earningInterestNotional: MoneyProps<CurrencyShortNameFiat>;
  interestAvailableToRedeem: MoneyProps;
  interestAvailableToRedeemNotional: MoneyProps<CurrencyShortNameFiat>;
  interestEarnedToDate: MoneyProps;
  interestEarnedToDateNotional: MoneyProps<CurrencyShortNameFiat>;
  interestEarnedToDatePast30Days: MoneyProps;
  interestEarnedToDatePast30DaysNotional: MoneyProps<CurrencyShortNameFiat>;
  earnUnfulfilledRedeemTransactionsTotalNotional: MoneyProps<CurrencyShortNameFiat>;
  earnUnfulfilledRedeemTransactionsTotal: MoneyProps;
  earnUnfulfilledRedeemTransactionsCount?: number | null;
  highestYieldAsset: HighestYieldAsset;
  startEarningDateMillis?: number;
  ask: string;
}

export type RetailGrowAsset = Partial<GrowAsset>;
export type RetailGrowAssets = { [currency in CurrencyShortName]?: RetailGrowAsset };

/**
 * Type utility to check if a string is a valid GrowProviderType.
 *
 * @param providerType - The string to check
 * @returns boolean
 */
export const isGrowProviderType = (providerType: string): providerType is GrowProviderType => {
  return Object.values(GrowProviderType).includes(providerType as GrowProviderType);
};

export enum GrowProviderType {
  EARN = "Earn",
  POOLED_STAKING = "Staking",
  PRIVATE_STAKING = "PrivateStaking",
  PROMOTIONAL = "Promotional",
  /** NOTE: defi is not currently in use, but it is mentioned in the API docs */
  // DEFI = "Defi",
}

/**
 * Provider restrictions indicate a blocked action on an interest provider.
 * Not all providers have restrictions, but if a restriction is provided it must be adhered to.
 *
 * Possible restrictions are:
 * - BlockDeposits: Block incoming Grow deposits
 * - BlockOrders: Block all Grow deposit and withdrawal actions
 * - BlockWithdrawals: Block outgoing Grow withdrawals
 */
export enum GrowProviderRestriction {
  BLOCK_DEPOSITS = "BlockDeposits",
  BLOCK_ORDERS = "BlockOrders",
  BLOCK_WITHDRAWALS = "BlockWithdrawals",
}

export interface InterestProvider extends Partial<PrivateStakingProviderData> {
  id: string;
  name: string;
  fullName?: string;
  annualInterestYieldTiers?: AnnualInterestYieldTier[];
  grossAnnualInterestYieldTiers?: AnnualInterestYieldTier[];
  earningInterest?: MoneyProps;
  earningInterestNotional?: MoneyProps;

  interestEarnedToDate?: MoneyProps;
  interestEarnedToDateNotional?: MoneyProps;
  interestEarnedToDatePast30Days?: MoneyProps;
  interestEarnedToDatePast30DaysNotional?: MoneyProps;
  earnUnfulfilledRedeemTransactionsCount?: number | null;
  earnUnfulfilledRedeemTransactionsTotal?: MoneyProps;
  earnUnfulfilledRedeemTransactionsTotalNotional?: MoneyProps;
  startEarningDateMillis?: number;
  maxAccrueDelay: number;
  maxWithdrawDelay: number;

  projectedOneYearInterest?: MoneyProps;
  projectedOneYearInterestNotional?: MoneyProps;
  providerType?: GrowProviderType;

  supportsWeekendOperations?: boolean;
  restriction?: GrowProviderRestriction;

  // TODO: This can likely be removed.
  annualPercentageRatePast30Days?: number;
}

type FeeDetails = {
  feePct: number;
  totalFee: MoneyProps<CurrencyShortNameSupportedCryptos>;
  totalFeeNotional: MoneyProps<CurrencyShortNameFiat>;
  totalUnpaidFee: MoneyProps<CurrencyShortNameSupportedCryptos>;
  totalUnpaidFeeNotional: MoneyProps<CurrencyShortNameFiat>;
  lifetimeFees: MoneyProps<CurrencyShortNameSupportedCryptos>;
  lifetimeFeesNotional: MoneyProps<CurrencyShortNameFiat>;
  feeRequestsPast30Days: MoneyProps<CurrencyShortNameSupportedCryptos>;
};

type InterestBreakdown = {
  consensusRewards?: MoneyProps<CurrencyShortNameSupportedCryptos>;
  consensusRewardsNotional?: MoneyProps<CurrencyShortNameFiat>;
  executionRewards?: MoneyProps<CurrencyShortNameSupportedCryptos>;
  executionRewardsNotional?: MoneyProps<CurrencyShortNameFiat>;
  genericRewards: MoneyProps<CurrencyShortNameSupportedCryptos>;
  genericRewardsNotional: MoneyProps<CurrencyShortNameFiat>;
  availableRewards?: MoneyProps<CurrencyShortNameSupportedCryptos>;
  availableRewardsNotional?: MoneyProps<CurrencyShortNameFiat>;
};

type AvailableBalance = {
  totalAvailableDepositBalance: MoneyProps<CurrencyShortNameSupportedCryptos>;
  totalAvailableDepositBalanceNotional: MoneyProps<CurrencyShortNameFiat>;
  totalAvailableRewardBalance: MoneyProps<CurrencyShortNameSupportedCryptos>;
  totalAvailableRewardBalanceNotional: MoneyProps<CurrencyShortNameFiat>;
};

type ProviderWithdrawals = {
  totalRewardWithdrawals: MoneyProps<CurrencyShortNameSupportedCryptos>;
  totalDepositWithdrawals: MoneyProps<CurrencyShortNameSupportedCryptos>;
};

export interface PrivateStakingProviderData {
  availableBalance: AvailableBalance;
  feeDetails: FeeDetails;
  interestEarnedToDateByType: InterestBreakdown;
  validatorDetails: ValidatorSummary;
  netRewards: MoneyProps<CurrencyShortNameSupportedCryptos>;
  withdrawals: ProviderWithdrawals;
}

export interface AnnualInterestYieldTier {
  amountAtTier?: MoneyProps;
  threshold?: MoneyProps;
  yield: number;
}

export interface HighestYieldAsset {
  providerType?: GrowProviderType;
  assetYield: number;
  currency: CurrencyShortName;
  tierRates?: AnnualInterestYieldTier[];
}
export type HighestAssetYieldByProviderType = Partial<Record<GrowProviderType, HighestYieldAsset>>;

export enum GROW_ASSET_ENUM {
  HasEarnBalance = "HasEarnBalance",
  HasNoEarnBalance = "HasNoEarnBalance",
  HasStakeBalance = "HasStakeBalance",
  HasNoStakeBalance = "HasNoStakeBalance",
}

// Product names are proper nouns and do not need to be translated.
export enum YieldProduct {
  EARN = "Gemini Earn",
  STAKING = "Gemini Staking",
  STAKING_PRO = "Gemini Staking Pro",
}

export type GeminiYieldProductInfo = {
  type: GrowProviderType;
  icon: React.ReactElement;
  name: YieldProduct;
};

export const ProviderProductMap = {
  [GrowProviderType.EARN]: YieldProduct.EARN,
  [GrowProviderType.POOLED_STAKING]: YieldProduct.STAKING,
  [GrowProviderType.PRIVATE_STAKING]: YieldProduct.STAKING_PRO,
  [GrowProviderType.PROMOTIONAL]: null,
} satisfies Record<GrowProviderType, YieldProduct>;

export enum ValidatorStatus {
  RESERVED = "Reserved",
  PENDING = "Pending",
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated",
  DEACTIVATING = "Deactivating",
}

export type ValidatorBalance = {
  balance: MoneyProps<CurrencyShortNameSupportedCryptos>;
  balanceNotional: MoneyProps<CurrencyShortNameFiat>;
  availableForWithdrawal: MoneyProps<CurrencyShortNameSupportedCryptos>;
};

export type ValidatorInterestBreakdown = {
  lifetimeRewardsNotional: MoneyProps<CurrencyShortNameFiat>;
  rewards: MoneyProps<CurrencyShortNameSupportedCryptos>;
  rewardsNotional: MoneyProps<CurrencyShortNameFiat>;
  rewardsPast30Days: MoneyProps<CurrencyShortNameSupportedCryptos>;
  rewardsPast30DaysNotional: MoneyProps<CurrencyShortNameFiat>;
  consensusRewards: MoneyProps<CurrencyShortNameSupportedCryptos>;
  consensusRewardsNotional: MoneyProps<CurrencyShortNameFiat>;
  executionRewards: MoneyProps<CurrencyShortNameSupportedCryptos>;
  executionRewardsNotional: MoneyProps<CurrencyShortNameFiat>;
  genericRewards: MoneyProps<CurrencyShortNameSupportedCryptos>;
  genericRewardsNotional: MoneyProps<CurrencyShortNameFiat>;
};

export type ValidatorSummary = {
  pendingValidatorCount: number;
  activeValidatorCount: number;
  validators: Validator[];
};

export type ValidatorMetadata = {
  value: string;
};

export type Validator = {
  activeSince?: number;
  providerId: string;
  providerName: string;
  status: ValidatorStatus;
  validatorAddress?: ValidatorMetadata;
  validatorId: string;
  validatorIndex?: string;
  validatorPublicKey?: ValidatorMetadata;
  balanceDetails: ValidatorBalance;
  interestEarnedToDateByType: ValidatorInterestBreakdown;
  feeDetails: FeeDetails;
  apr: number;
};

type PrefixT<prefix, T> = {
  [K in keyof T as `${string & prefix}${Capitalize<string & K>}`]: T[K];
};

interface SharedStakingSnapshotProviderProps
  extends Pick<
    InterestProvider,
    | "annualInterestYieldTiers"
    | "interestEarnedToDatePast30Days"
    | "interestEarnedToDatePast30DaysNotional"
    | "interestEarnedToDate"
    | "interestEarnedToDateNotional"
  > {
  stakingBalance: InterestProvider["earningInterest"];
  stakingBalanceNotional: InterestProvider["earningInterestNotional"];
}

// These props will have all 3 variants
// (e.g. "combined", "pooled", "private")
interface CombinedStakingSnapshotProps
  extends PrefixT<"combined", SharedStakingSnapshotProviderProps>,
    PrefixT<"pooled", SharedStakingSnapshotProviderProps>,
    PrefixT<"private", SharedStakingSnapshotProviderProps> {}

// Props from private staking providers only
// These will only be "combined" prefixed
interface PooledStakingSnapshotProps
  extends PrefixT<"pooled", Pick<InterestProvider, "annualPercentageRatePast30Days">> {}

// Props from private staking providers only
// These will only be "combined" prefixed
interface PrivateStakingSnapshotProps extends PrefixT<"private", PrivateStakingProviderData> {}

/**
 * StakingBalanceSnapshot is a snapshot of the user's staking balances,
 * individually and combined, across all staking provider types.
 *
 * Each key has a `combined`, `pooled`, and `private` version.
 *
 * `combined` is the sum across all provider types.
 * `pooled` is the sum across all pooled staking providers.
 * `private` is the sum across all private staking providers.
 */
export interface StakingBalanceSnapshot
  extends CombinedStakingSnapshotProps,
    PooledStakingSnapshotProps,
    PrivateStakingSnapshotProps {
  balanceState: StakingBalanceState;
  hasPendingPooledUnstakes: boolean;
  hasPendingPrivateUnstakes: boolean;
  hasPooledStakingBalance: boolean;
  hasPrivateStakingBalance: boolean;
  pooledProviders: InterestProvider[];
  privateProviders: InterestProvider[];
}

export enum StakingTransactionStatus {
  PENDING = "Pending",
  COMPLETE = "Complete",
  CANCELLED = "Cancelled",
}

export enum StakingTransactionProgress {
  INITIATED = "Initiated",
  PENDING = "Pending",
  ACTIVATING = "Activating",
  STAKED = "Staked",
  COMPLETE = "Complete",
  EXITING = "Exiting",
  UNSTAKED = "Unstaked",
}

export type GrowStakeTransaction = {
  orderQuote?: CurrencyOrderQuote;
} & GrowTransfer;
