import styled from "@emotion/styled";
import { Colors, Spacer, Spacing } from "@gemini-ui/design-system";

export const PaginationContainer = styled(Spacer)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationButton = styled.button<{ hidden: boolean }>`
  outline: none;
  border: none;
  width: ${Spacing.scale[2.5]};
  height: ${Spacing.scale[2.5]};
  border-radius: ${Spacing.scale[0.5]};
  background-color: ${Colors.white};
  box-shadow: 0 0 5px 1px rgba(16, 16, 26, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 1px rgba(16, 16, 26, 0.06);
  }

  svg {
    width: ${Spacing.scale[0.5]};
    height: ${Spacing.scale[1]};
  }
  ${({ hidden }) => hidden && "visibility: hidden;"}
`;
