import { AmountInput } from "@gemini-ui/design-system/__deprecated__/Input/Amount";
import {
  AmountInputProps,
  InputElementProps,
  InputWithOptionalElementProps,
  MaskInputProps,
  PasswordInputProps,
  PhoneNumberInputProps,
} from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { Input as BaseInput } from "@gemini-ui/design-system/__deprecated__/Input/Input";
import { InputLeftElement, InputRightElement } from "@gemini-ui/design-system/__deprecated__/Input/InputElements";
import { InputWithMask } from "@gemini-ui/design-system/__deprecated__/Input/Masked";
import { PasswordInput } from "@gemini-ui/design-system/__deprecated__/Input/Password";

interface CompoundInput
  extends React.ForwardRefExoticComponent<InputWithOptionalElementProps & React.RefAttributes<HTMLInputElement>> {
  LeftElement?: (props: InputElementProps) => JSX.Element;
  RightElement?: (props: InputElementProps) => JSX.Element;
  Password?: (props: PasswordInputProps) => JSX.Element;
  Mask?: (props: MaskInputProps) => JSX.Element;
  Amount?: (props: AmountInputProps) => JSX.Element;
  PhoneNumber?: (props: PhoneNumberInputProps) => JSX.Element;
}

/** @deprecated please use the latest Hubble Input component instead */
export const Input = BaseInput as CompoundInput;

Input.LeftElement = InputLeftElement;
Input.RightElement = InputRightElement;
Input.Password = PasswordInput;
Input.Mask = InputWithMask;
Input.Amount = AmountInput;

const INPUT = "input";
Input.displayName = INPUT;
