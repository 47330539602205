export enum SENTRY_TAGS {
  Team = "team",
}
export enum SENTRY_TEAMS {
  CryptoBack = "cryptoback",
  ActiveTrader = "active-trader",
  Leaderboard = "leaderboard",
  ConsumerExperience = "consumer-experience",
  Payments = "payments",
  Staking = "staking",
  Transfers = "transfers",
  Custody = "custody",
  InstitutionalStrategies = "institutional-strategies",
}
