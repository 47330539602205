import { format } from "date-fns";
import uuidv4 from "uuid/v4";
import { Address, Disclosures, Name, SSN, ValidationIds } from "@gemini-ui/client/credit";
import { FormValues } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";

const MIXPANEL_UNIQUE_ID = window?.initialData?.templateProps?.user?.mixpanelId || uuidv4();
const formatDate = (mmDdYyyy: string) => format(new Date(mmDdYyyy), DateFormats.YearMonthDay);

export const mapFormValuesToApplicationV3 = (values: FormValues) => {
  const today = new Date().toISOString();
  const {
    g_recaptcha_response_v2,
    g_recaptcha_response_v3,
    firstName,
    middleName,
    lastName,
    phone,
    email,
    street,
    apt,
    city,
    state,
    zip,
    dob: mmDdYyyy,
    ssn: fullSsn,
    ssnLastFour,
    password,
    softPullTerms,
    hardPullTerms,
    creditReportTerms,
    socureId,
    creditPull,
    grossAnnualIncome,
  } = values;

  const softPullDisclosures: Disclosures = softPullTerms
    ? {
        auto_dialer: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
        esign_agreement: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
        privacy_policy: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
        site_policy: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
        credit_report_authorization: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
      }
    : undefined;

  const creditReportDisclosure: Disclosures = creditReportTerms
    ? {
        credit_report_authorization: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
      }
    : undefined;

  const hardPullDisclosures: Disclosures = hardPullTerms
    ? {
        cardholder_agreement: {
          consent: true,
          date: today,
          docVersion: "0.1",
        },
      }
    : undefined;

  const validationIds: ValidationIds =
    g_recaptcha_response_v2 || g_recaptcha_response_v3
      ? {
          g_recaptcha_response_v2,
          g_recaptcha_response_v3,
        }
      : undefined;

  const name: Name =
    firstName || middleName || lastName
      ? {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          ...(middleName && { middleName: middleName.trim() }),
        }
      : undefined;

  const address: Address =
    street || apt || city || state || zip
      ? {
          street,
          apt,
          city,
          state,
          zip,
        }
      : undefined;

  const ssn: SSN = fullSsn
    ? {
        // Submitting while unmasked sends SSN with - dashes and gets rejected by BE
        full: fullSsn.replace(/\D/g, ""),
        last4: fullSsn.slice(-4),
      }
    : undefined;

  const dob = mmDdYyyy ? formatDate(mmDdYyyy) : undefined;
  const passwordEntered = password && password !== "" ? password : undefined;
  const affiliateClickId = new URLSearchParams(window.location.search).get("~click_id") || undefined;

  const app = {
    mixpanelId: MIXPANEL_UNIQUE_ID,
    ...(name && { name }),
    ...(phone && { phone }),
    ...(softPullDisclosures && { disclosures: softPullDisclosures }),
    ...(creditReportDisclosure && { disclosures: creditReportDisclosure }),
    ...(hardPullDisclosures && { disclosures: hardPullDisclosures }),
    ...(validationIds && { validationIds }),
    ...(address && { address }),
    ...(ssn && { ssn }),
    ...(dob && { dob }),
    ...(ssnLastFour && { ssnLastFour }),
    ...(email && { email }),
    ...(password && { password: passwordEntered }),
    ...(affiliateClickId && { affiliateClickId }),
    ...(socureId && { didSessionId: socureId }),
    ...(creditPull && { creditPull }),
    ...(grossAnnualIncome && { grossAnnualIncome }),
  };

  return app;
};
