import styled from "@emotion/styled";
import { Flex } from "@gemini-ui/design-system/Flex";
import { motion, shorthandSpacingCss, Spacer } from "@gemini-ui/design-system/primitives";

export const StyledFlex = styled(Flex)`
  text-align: right;
`;

export const ListItemHr = styled(Spacer)`
  border-bottom: 1px solid ${({ theme }) => theme.colorScheme.border.primary};
`;

export const ListItemWrapper = styled.li`
  list-style: none;

  > a,
  > button {
    display: block;
    cursor: pointer;
    transition: background-color ${motion.duration.fast} ${motion.timing.easeInOut};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colorScheme.list.background.hover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colorScheme.list.background.active};
    }
  }

  ${shorthandSpacingCss}
`;

export const ListItemInner = styled.span`
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
`;
