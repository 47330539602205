export enum LinkName {
  GROW = "GROW",
  EARN = "EARN",
  STAKE = "STAKE",
  HOME = "HOME",
  MARKET = "MARKET",
  PORTFOLIO = "PORTFOLIO",
  TRADE = "TRADE",
  DASHBOARD = "DASHBOARD",
  CLEARING = "CLEARING",
  BALANCES = "BALANCES",
  CARD = "CARD",
  ADVANCED_MARKETS = "ADVANCED_MARKETS",
  SETTLEMENT = "SETTLEMENT",
  EOTC = "eOTC",
  Rewards = "Rewards",
}

export const HEADER_EVENTS: Record<LinkName, string> = {
  [LinkName.TRADE]: "Active Trader Tab Opened",
  [LinkName.EARN]: "Earn Tab Opened",
  [LinkName.GROW]: "Grow Tab Opened",
  [LinkName.STAKE]: "Stake Tab Opened",
  [LinkName.HOME]: "Home Tab Opened",
  [LinkName.MARKET]: "Market Tab Opened",
  [LinkName.PORTFOLIO]: "Portfolio Tab Opened",
  [LinkName.DASHBOARD]: "Dashboard Tab Opened",
  [LinkName.CLEARING]: "Clearing Tab Opened",
  [LinkName.BALANCES]: "Balances Tab Opened",
  [LinkName.CARD]: "Card Tab Opened",
  [LinkName.ADVANCED_MARKETS]: "Advanced Markets Tab Opened",
  [LinkName.SETTLEMENT]: "Settlement tab opened",
  [LinkName.EOTC]: "EOTC tab opened",
  [LinkName.Rewards]: "Rewards Tab Opened",
};
