import React from "react";
import { InstitutionIconProps, PaymentMethodIconBadgeProps } from "@gemini-ui/components/Icons/PaymentMethod/constants";
import createIconComponent, {
  iconSizeToDimension,
} from "@gemini-ui/components/Icons/PaymentMethod/createIconComponent";
import { IconBadge } from "@gemini-ui/design-system";
import { ICON_SIZE } from "@gemini-ui/design-system/forms/shared/utils";

export type BankIconProps = {
  institution: InstitutionIconProps;
} & PaymentMethodIconBadgeProps;

export function BankIcon({ institution, size = "lg", ...props }: BankIconProps) {
  const iconSize = ICON_SIZE[size];
  const pixels = iconSizeToDimension[iconSize];

  const Icon = createIconComponent(
    <image href={institution.logo} height={pixels} width={pixels} />,
    parseInt(pixels),
    parseInt(pixels)
  );
  return <IconBadge size={size} icon={<Icon />} backgroundColor={institution.primary_color} {...props} />;
}
