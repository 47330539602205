import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Colors } from "@gemini-ui/design-system";

const ONFIDO_MOBILE_BREAKPOINT = "479px";

// need to expose this as global css for the portal (modal mode)
export const commonOnfidoCss = css`
  .ods-button.-action--primary,
  .ods-button.-action--secondary {
    border-radius: 999px;
    width: 100%;
    font-weight: 600;
  }
  // remove the blue border and shadow
  .ods-button.-action--primary:focus,
  .ods-button.-action--secondary:focus {
    box-shadow: none;
    border-color: ${Colors.white};
  }
  .onfido-sdk-ui-Modal-inner .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option:hover {
    background-color: rgb(153, 154, 155);
  }
  .onfido-sdk-ui {
    &-Theme-modalOverlay {
      z-index: 102;
    }

    &-Theme-link:hover {
      opacity: 56%;
    }

    &-PageTitle-titleSpan {
      line-height: 0;
    }
    &-PageTitle-titleWrapper {
      margin: 0;
      margin-top: 16px;
      line-height: 40px;
    }
    &-PageTitle-subTitle {
      display:none;
    }
    &-DocumentSelector-label {
      color: ${Colors.black};
      font-weight: 400;
      font-size: 16px;
    }
    &-DocumentSelector-hint {
      color: ${Colors.gray[600]};
      font-weight: 400;
    }
    &-DocumentSelector-option {
      border-radius: 20px;
    }
    &-Confirm-btn-secondary {
      margin-right: 10px;
    }
    &-Confirm-actions {
      align-items: center;
      flex-direction: column;
      // move the upload button above the redo
      & :first-of-type {
        width: 100%;
        order: 2;
        margin: 0;
      }
      & :last-child {
        width: 100%;
        order: 1;
        margin-bottom: 12px;
      }
    }

    &-Uploader-crossDeviceInstructionsContainer > div:last-child,
    .onfido-sdk-ui-Uploader-buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    &-crossDevice-CrossDeviceLink-numberInputSection {
      display: flex;
      flex-direction: column;
      height: unset;
    }

    &-crossDevice-CrossDeviceLink-inputContainer {
      height: 100%;
      width: 100%;
      background-color: #fff;
      float: left;
      border: 1px solid ${Colors.gray[600]};
      border-radius: 12px;

      & input:focus {
        outline: unset; // remove blue border when focusing into phone number field
      }
    }

    &-crossDevice-CrossDeviceLink-btn {
      margin-top: 24px; // adds space above secure link input
      @media (max-width: ${ONFIDO_MOBILE_BREAKPOINT}) {
        width: 50%; // fix text wrapping on mobile screens
      }
    }
`;
export const Uploader = styled.div<{ hideTitles?: boolean }>`
  ${commonOnfidoCss}
  // only applies to the regular onfido mode
  .onfido-sdk-ui {
    &-Modal-inner {
      height: ${p => (p.hideTitles ? "29.5em" : "37.5em")};
      border-radius: 20px;
      width: 100%;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
      border: none;
      @media (max-width: ${ONFIDO_MOBILE_BREAKPOINT}) {
        position: relative;
      }
    }
  }
`;

export const OnfidoLoadingPlaceholder = styled.div`
  height: 600px;
  width: 423px;
  border-radius: 20px;
  background: ${Colors.gray[50]};
`;
