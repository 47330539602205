import { useCallback, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { useDebounce } from "react-use";
import { ErrorCode } from "@gemini-ui/services/axios";
import {
  DEBOUNCE_INTERVAL_MS,
  fetchSubaccountsWithBalances,
  SubaccountSearchParams,
  SubaccountSearchResponse,
  SubaccountWithBalances,
} from "@gemini-ui/services/subaccounts";
const SUBACCOUNTS_RETRY_COUNT = 3;

const INITIAL_STATE = {
  data: { currentPage: 0, limit: 10, offset: 0, sortAsc: true, total: 0, totalPages: 0, results: [] },
  error: null,
  loading: false,
};

interface SubaccountWithBalanceState {
  data: SubaccountSearchResponse<SubaccountWithBalances>;
  error: AxiosError;
  loading: boolean;
}

export function useSubaccountWithBalance(
  searchParams?: Partial<SubaccountSearchParams>,
  limit?: number,
  hasDedupedGet?: boolean
) {
  const [state, setState] = useState<SubaccountWithBalanceState>(INITIAL_STATE);
  const [params, setParams] = useState({ filter: "", offset: 0 });

  const { filter, offset } = params;
  const { data, loading, error } = state;

  const interval = filter ? DEBOUNCE_INTERVAL_MS : 10;

  useDebounce(
    () => {
      setState(prev => ({ ...prev, loading: true }));

      let params = { ...searchParams };

      if (filter) params = { name: filter, ...params };
      if (offset) params = { offset, ...params };

      fetchSubaccounts(params);
    },
    interval,
    [filter, offset, params]
  );

  const fetchSubaccounts = useCallback(
    (params: Partial<SubaccountSearchParams & { loading: boolean }> = {}) => {
      setState(prev => ({ ...prev, loading: params.loading === undefined ? true : params.loading }));
      fetchSubaccountsWithBalances(params, { retry: SUBACCOUNTS_RETRY_COUNT }, limit, hasDedupedGet)
        .then(response => {
          const accounts = response.results.filter(account => Boolean(account.balances));
          const shouldReset = offset === 0 || (filter && response.currentPage === 1);
          setState(prev => ({
            ...prev,
            loading: false,
            error: null,
            data: {
              ...response,
              results: shouldReset ? accounts : [...prev.data.results, ...accounts],
            },
          }));
        })
        .catch(error => {
          if (error?.code !== ErrorCode.CANCEL_ERROR) {
            setState(prev => ({ ...prev, error, loading: false }));
          }
        });
    },
    [filter, offset, limit, hasDedupedGet]
  );

  const values = useMemo(
    () => ({ data, filter, loading, setParams, params, error, fetchSubaccounts }),
    [data, filter, loading, setParams, params, error, fetchSubaccounts]
  );

  return values;
}
