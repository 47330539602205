import React, { useState } from "react";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { ModalStep, SenderType } from "@gemini-ui/components/TransferControl/types";
import { usePageData } from "@gemini-ui/contexts";
import { Card, Colors, Flex, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onStepChange: (step: ModalStep, sender: SenderType) => void;
  dateReceived: string;
}

function SelectSender({ onStepChange, dateReceived }: Props) {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();
  const [selected, setSelected] = useState<SenderType | null>(null);
  const { transferControl } = testIds;

  const isUkTravelRuleEnabled = getIsUkInboundEnabled(geminiEntity);

  const handleChange = (e: React.MouseEvent) => {
    const sender = e.currentTarget.id as SenderType;
    setSelected(sender);
    onStepChange(ModalStep.ConfirmOwnership, sender);
  };

  return (
    <Flex flexDirection="column">
      <Text.Body size="sm" mb={3} data-testid={transferControl.attestationDescription}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Please provide additional information for your crypto deposit received {dateReceived}.",
          }),
          { dateReceived }
        )}
      </Text.Body>

      <Text.Body size="sm" mb={1} bold data-testid={transferControl.selectSenderTitle}>
        {intl.formatMessage({
          defaultMessage: "Select sender",
        })}
      </Text.Body>

      <Card
        isRadio
        as="button"
        padding="sm"
        id={SenderType.MY_SELF}
        mb={2}
        aria-checked={selected === SenderType.MY_SELF}
        onClick={handleChange}
        data-testid={transferControl.selectSenderMyselfOption}
      >
        <Flex flexDirection="column">
          <Text.Body bold as="span">
            {intl.formatMessage({ defaultMessage: "Myself" })}
          </Text.Body>

          <Text.Body size="sm" color={Colors.gray[600]}>
            {intl.formatMessage({ defaultMessage: "You are the owner of the sending wallet." })}
          </Text.Body>
        </Flex>
      </Card>

      <Card
        isRadio
        as="button"
        padding="sm"
        id={SenderType.SOMEONE_ELSE}
        mb={2}
        aria-checked={selected === SenderType.SOMEONE_ELSE}
        onClick={handleChange}
        data-testid={transferControl.selectSenderSomeoneElseOption}
      >
        <Flex flexDirection="column">
          <Text.Body bold as="span">
            {intl.formatMessage({ defaultMessage: "From someone else" })}
          </Text.Body>

          <Text.Body size="sm" color={Colors.gray[600]}>
            {intl.formatMessage({ defaultMessage: "Someone else is the owner of the sending wallet." })}
          </Text.Body>
        </Flex>
      </Card>

      {!isUkTravelRuleEnabled && (
        <Card
          isRadio
          as="button"
          padding="sm"
          id={SenderType.NOT_RECOGNIZED}
          mb={3}
          aria-checked={selected === SenderType.NOT_RECOGNIZED}
          onClick={handleChange}
          data-testid={transferControl.selectSenderNotRecognizedOption}
        >
          <Flex flexDirection="column">
            <Text.Body bold as="span">
              {intl.formatMessage({ defaultMessage: "I don't recognize this" })}
            </Text.Body>

            <Text.Body size="sm" color={Colors.gray[600]}>
              {intl.formatMessage({ defaultMessage: "You do not know the owner of the sending wallet." })}
            </Text.Body>
          </Flex>
        </Card>
      )}

      <Text.Body size="xs" color={Colors.gray[600]}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: `Questions? Please {faqLink} or {supportLink}.`,
          }),
          {
            faqLink: (
              // TODO: Update with FAQ article from Legal
              <HelpCenterLink color={Colors.gray[600]} article={Articles.ATTEST_ADDRESS}>
                {intl.formatMessage({
                  defaultMessage: "see our FAQs",
                })}
              </HelpCenterLink>
            ),
            supportLink: (
              <HelpCenterLink color={Colors.gray[600]} article={Articles.ATTEST_ADDRESS}>
                {intl.formatMessage({
                  defaultMessage: "contact support",
                })}
              </HelpCenterLink>
            ),
          }
        )}
      </Text.Body>
    </Flex>
  );
}

export default SelectSender;
