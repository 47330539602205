import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { SENTRY_TAGS } from "@gemini-ui/sentry/teams";

export const useSentryTeam = (team: string) => {
  useEffect(() => {
    Sentry.setTag(SENTRY_TAGS.Team, team);
    return () => Sentry.setTag(SENTRY_TAGS.Team, "");
  }, [team]);
};
