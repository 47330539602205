import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import { usePageData } from "@gemini-ui/contexts";
import { useToaster } from "@gemini-ui/design-system";
import { FeatureElectionStatus } from "@gemini-ui/utils/constants";
import { useCheckPerpEligibility } from "@gemini-ui/utils/derivativeAccountUtils";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";
import { getFeatureElection } from "@gemini-ui/utils/leaderboard/leaderboardService";

export interface LeaderboardOptInServiceResponse {
  optInLeaderboardStatus: FeatureElectionStatus;
  setOptInLeaderboardStatus: React.Dispatch<React.SetStateAction<FeatureElectionStatus>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
}

export const FEATURE_ELECTION_LEADERBOARD_KEY = "derivatives-leaderboard";

export const useLeaderboardOptInService = (
  // optional to see the account status, if not, the response will be user based
  hashId = null
): LeaderboardOptInServiceResponse => {
  const [optInLeaderboardStatus, setOptInLeaderboardStatus] = useState<FeatureElectionStatus>(null);
  // Countdown will be removed once the feature is enabled
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { LeaderboardOptInEnabled, TradePerpEnabled, PerpsUiEnabled } = useFeatureFlags();

  const { showToast } = useToaster();
  const { intl } = useIntl();
  const {
    templateProps: { user },
  } = usePageData();
  const isPerpsEligible = useCheckPerpEligibility({
    user,
    TradePerpEnabled,
    PerpsUiEnabled,
  });

  const getLeaderboardSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getFeatureElection();
      if (hashId) {
        // if some account is opted in, the user will be opted in too
        // if there is no optIn account but there is at least one optOut, teh user will be optOut too
        // No decision means all the accounts are no decision
        setOptInLeaderboardStatus(
          data?.featureElections?.find(
            featureElection =>
              featureElection.electiveFeature === FEATURE_ELECTION_LEADERBOARD_KEY && featureElection.hashId === hashId
          )?.status
        );
      } else {
        const filteredLeaderboardfeatureElections = data?.featureElections?.filter(
          featureElection => featureElection.electiveFeature === FEATURE_ELECTION_LEADERBOARD_KEY
        );
        const existAnyOptIn = filteredLeaderboardfeatureElections?.some(
          featureElection => featureElection.status === FeatureElectionStatus.OptIn
        );
        const existAnyOptOut = filteredLeaderboardfeatureElections?.some(
          featureElection => featureElection.status === FeatureElectionStatus.OptOut
        );
        setOptInLeaderboardStatus(
          existAnyOptIn
            ? FeatureElectionStatus.OptIn
            : existAnyOptOut
            ? FeatureElectionStatus.OptOut
            : FeatureElectionStatus.NoDecision
        );
      }
    } catch (error) {
      const message = getError(error);
      Sentry.captureMessage(`Error getting leaderboard settings: ${message}`);
      showToast({ message: intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." }) });
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [hashId, intl, showToast]);

  useEffect(() => {
    const controller = new AbortController();
    if (LeaderboardOptInEnabled && isPerpsEligible) getLeaderboardSettings();
    return () => controller.abort();
  }, [getLeaderboardSettings, LeaderboardOptInEnabled, isPerpsEligible]);

  return {
    optInLeaderboardStatus,
    setOptInLeaderboardStatus,
    isLoading,
    setIsLoading,
    error,
  };
};
