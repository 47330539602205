import { GrowBuyAction, GrowBuyActionType } from "@gemini-ui/pages/Earn/GrowBuy/context/actions";
import { GrowBuyState } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

export const redeemStatusReducer = (state: GrowBuyState, action: GrowBuyAction): GrowBuyState => {
  switch (action.type) {
    case GrowBuyActionType.UPDATE_REDEEM_STATUS:
      const { currency = null, providerType = null, view: nextView = null, ...newVals } = action?.value ?? {};

      return {
        ...state,
        ...(Boolean(currency) && { currency }),
        ...(Boolean(providerType) && { providerType }),
        redeemStatus: { ...state.redeemStatus, ...newVals },
        router: {
          ...state.router,
          // If we have a new modal view, add it to the stack
          ...(Boolean(nextView) && {
            currentView: nextView,
            viewStack: [...state.router.viewStack, nextView],
          }),
        },
      };
    default:
      return state;
  }
};
