import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import { SENTRY_TAGS, SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import axios from "@gemini-ui/services/axios";
import { StakingTransferQueryParams } from "@gemini-ui/services/staking/constants";
import { stakingTransferQuoteUrl } from "@gemini-ui/services/staking/endpoints";
import { getError } from "@gemini-ui/utils/error";

export const getStakingTransferQuote = async (args: StakingTransferQueryParams) => {
  return await axios
    .get<TransferQuote>(stakingTransferQuoteUrl(args))
    .then(res => res.data)
    .catch(e => {
      Sentry.withScope(scope => {
        scope.setTag(SENTRY_TAGS.Team, SENTRY_TEAMS.Staking);
        Sentry.captureException(e);
      });
      throw e;
    });
};

export const useStakingTransferQuote = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TransferQuote>();
  const [error, setError] = useState<string>(null);

  const fetchQuote = useCallback(async (args: StakingTransferQueryParams) => {
    try {
      setLoading(true);
      const data = await getStakingTransferQuote(args);
      setData(data);
      setError(null);
      return data;
    } catch (err) {
      setError(getError(err));
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    fetchQuote,
    loading,
    error,
  };
};
