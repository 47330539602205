import React from "react";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import { usePageData } from "@gemini-ui/contexts";
import { WithdrawFiatFeesProvider } from "@gemini-ui/contexts/WithdrawFiatFees";

const CashWithdrawFlow = LazyModalWrapper(() => import("./index"));

function LazyLoadCashWithdrawFlow({ isOpen, onClose, defaultCurrency }) {
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  return (
    <WithdrawFiatFeesProvider defaultCurrency={defaultCurrency || defaultFiat}>
      <CashWithdrawFlow isOpen={isOpen} onClose={onClose} defaultCurrency={defaultCurrency || defaultFiat} />
    </WithdrawFiatFeesProvider>
  );
}

export default LazyLoadCashWithdrawFlow;
