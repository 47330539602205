import * as React from "react";
import { Button, Modal, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

const BankBlockedModal = ({ isOpen, onClose }) => {
  const { intl } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Gemini cannot process transactions involving this bank",
      })}
    >
      <Text.Body size="xs">
        {intl.formatMessage({
          defaultMessage: "Please try an account from a different bank or another payment method.",
        })}
      </Text.Body>
      <Button.Group>
        <Button.Primary data-testid="confirm-cannot-add-bank" size="lg" onClick={onClose}>
          {intl.formatMessage({ defaultMessage: "Got it" })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

export default BankBlockedModal;
