import {
  ActiveOrCompletedOrExpiredReferralsItem,
  PendingReferralOrInProgressReferralItem,
  RewardsSummary,
  RewardTransaction,
} from "@gemini-ui/client/rewards-v2";
import { ReferralClubLevels } from "@gemini-ui/pages/RewardsHub/ReferralProgress/constants";

export const enum RewardType {
  REFERRAL = "REFERRAL",
  PROMO = "PROMO",
}

export const enum LoadingState {
  LOADING = "LOADING",
  DEFAULT = "DEFAULT",
  ERROR = "ERROR",
}

export type ReferralClub = ActiveOrCompletedOrExpiredReferralsItem & {
  clubLevel: ReferralClubLevels;
};

export type PendingHistory = {
  results: PendingReferralOrInProgressReferralItem[];
};

export type ReferralClubHistory = {
  results: ReferralClub[];
};

export type EligibleRewardData = {
  rewards: EligibleReward[];
};
export type EligibleReward = {
  rewardType: RewardType;
  description: string;
};

export type PendingTableState = {
  loadingState: LoadingState;
  data: PendingHistory;
};

export type RewardTableTransactionState = {
  loadingState: LoadingState;
  data: RewardTransaction[];
};
export type RewardState = {
  rewardTransactions: RewardTableTransactionState;
  rewardSummary: {
    loadingState: LoadingState;
    data: RewardsSummary;
  };
  referralClub: {
    loadingState: LoadingState;
    data: ReferralClubHistory;
  };
  pendingRewards: PendingTableState;
};
