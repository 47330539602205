import styled from "@emotion/styled";
import { Button, Colors, getColor } from "@gemini-ui/design-system";
import { ButtonProps } from "@gemini-ui/design-system/Button/constants";

// Custom <Button.Primary /> styling that merges disabled and loading state
export const ConfirmButton = styled(Button.Primary)<ButtonProps>`
  width: 100%;

  ${({ disabled, loading, ...props }) =>
    disabled &&
    loading &&
    `
      background-color: ${getColor(Colors.gray[50], Colors.gray[900])(props)};
      color: ${getColor(Colors.gray[300], Colors.gray[700])(props)};

      svg * {
        stroke: ${getColor(Colors.gray[300], Colors.gray[700])(props)} !important;
      } 
    `}
`;
