import { AxiosError } from "axios";
import { CREDIT_APP_LOGIN } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/v2/machines/creditAppMachine";
import { CreditError } from "@gemini-ui/pages/Credit/utils/errorCodes";

export const handleCreditExceptions = ({ e, send }: { e: AxiosError<{ code: CreditError }>; send: any }): any => {
  const res = e.response;

  if (res) {
    const status = res.status;
    const code = res.data.code;

    if (code === CreditError.CBNotInWaitlistQueue) {
      return send(ClientState.NOT_IN_WAITLIST_QUEUE);
    }

    if (code === CreditError.CBReappliedTooSoon) {
      return send(ClientState.RECENT_DENIED);
    }

    if (code === CreditError.MaxDailyApplicationLimit) {
      return send(ClientState.MAX_APPLICATION_LIMIT);
    }

    if (status === 409 && code === CreditError.TUserAlreadyExists) {
      window.location.pathname = CREDIT_APP_LOGIN;
    }
  }
};
