// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// - numeral.js is unmaintained and buggy
// - numbro is maintained and not buggy, but still has odd corners, and the formatting in particular is poorly-documented:
//   > p = numbro("0.01")
//   { [Number: 0.01] _value: 0.01 }
//   > p.format("0[.00]")
//   '0.010'
//   > p.format("0[.0]")
//   '0.01'
//   > p.format("0.0")
//   '0.0'
//   > p.format("0")
//   '0'
//   > p.format("0.")
//   '0'
//   > p.format("0.[0]")
//   '0'
//   > p.format("0.[00]")
//   '0.01'
//   > n("-10").format("+$0.0")
//   '-$10.0'
//   > n("10").format("+$0.0")
//   '$+10.0'
// So I hope to bring together our use-cases for number parsing into one place, and provide a saner method for formatting
import _ from "lodash";
import numbro from "numbro";

import { CurrencyDetail } from "./constants/currencies";
import { DEFAULT_PRECISION } from "./Money/constants";

const nfold = function (memo: (string | null) | (number | null), vals: (string | number)[], fn: Function) {
  for (const val of vals) {
    if (isFinite(Number(val))) {
      memo = fn(memo, val);
    }
  }
  return new Num(memo);
};

// same regex exists in DecimalOps.scala, please fix any bugs in both places!
const strictParsedNumber = /^[\-\+]?[\d,]*(?:\.\d*)?(?:[eE][\-\+]?\d+)?$/;
const scientificNotation = /[\-\+]?\d*(?:\.\d*)?[eE][\-\+]?\d+/;

class Num {
  num: numbro;
  val: number;

  constructor(input: string | number | Function, strict: boolean | null = false) {
    if (strict == null) {
      strict = false;
    }
    if (typeof input === "function") {
      input = input();
    }

    if (strict && typeof input === "string" && !strictParsedNumber.test(input)) {
      input = NaN;
    } else if (typeof input === "string" && scientificNotation.test(input)) {
      input = numbro(parseFloat(input));
    }

    this.num = numbro(input);
    this.val = this.num._value;
  }

  isNumber() {
    return typeof this.val === "number";
  }
  isFinite() {
    return isFinite(this.val);
  }
  isNan() {
    return isNaN(this.val);
  }
  isInfinite() {
    return this.val === Infinity || this.val === -Infinity;
  }
  or(alt: number) {
    if (!isFinite(this.val)) {
      return new Num(alt);
    } else {
      return this;
    }
  }

  // new Num("3").add(4, 5, NaN, "foo").val === 12
  add(...vals: (string | number)[]) {
    return nfold(this.val, vals, (a, b) => a + b);
  }
  // new Num("12").subtract(4, "5", "foo").val == 3
  subtract(...vals: any[]) {
    return nfold(this.val, vals, (a, b) => a - b);
  }
  // new Num("4").subtractFrom(12).val == 8
  subtractFrom(other: number) {
    return new Num(other - this.val);
  }

  multiply(...vals: any[]) {
    return nfold(this.val, vals, (a, b) => a * b);
  }

  abs() {
    return new Num(Math.abs(this.val));
  }

  gt(other: number) {
    return this.val > other;
  }
  lt(other: number) {
    return this.val < other;
  }
  isPositive() {
    return this.val > 0;
  }
  isNegative() {
    return this.val < 0;
  }
  isZero() {
    return this.val === 0;
  }

  // https://stackoverflow.com/a/9539746/17697
  significantPlaces(to: number) {
    if (to == null) {
      to = DEFAULT_PRECISION;
    }
    return this.val
      .toFixed(to)
      .replace(/^-?\d*\.?/, "")
      .replace(/0*$/, "").length;
  }

  round(places: number, direction: string | null = null) {
    return new Num(Num.round(this.val, places, direction));
  }

  getDecimals(decimals: number, currencyDetail: CurrencyDetail) {
    if (decimals != null) return decimals;

    if (currencyDetail && currencyDetail.leadingSymbol) return currencyDetail.decimals;

    return undefined;
  }

  getMaxDecimals(decimalSetting: number | undefined, currencyDetail: CurrencyDetail) {
    if (typeof decimalSetting === "number") {
      // Decimals override may be greater than a currencies max decimals so take the max of the two
      return _.max([currencyDetail?.maxDecimals, decimalSetting]);
    }

    return DEFAULT_PRECISION;
  }

  // if string: pro(tm) edition, pass straight to numbro
  // if object:
  // zero: to display if the value is 0
  // NaN: to display if the value is not a number
  // sep: format with a separator for thousands, currently a comma
  // decimals:
  //   - *number*: the number of decimal places to round to (see *round*)
  //   else, displays significant decimal places, up to 8
  // round: 'up' or 'down' or 'half-up', rounds the number.  if 'decimals' is not a number, it'll round only if the
  //   number has more than 8 decimal places.
  // currency: CurrencyDetail,
  //   sets 'decimals' to 2 if not otherwise specified
  //   '$': displays before number

  format(opts: Record<string, any> | string = ""): string {
    let decimals, lessThan, sign;
    if (opts == null) {
      opts = {};
    }
    if (typeof opts === "string") {
      return this.num.format(opts);
    } else if (opts.zero && this.val === 0) {
      return opts.zero;
    } else if (opts.NaN && isNaN(this.val)) {
      return opts.NaN;
    }

    const str = ["0"];
    let { val } = this;
    if (opts.sep) {
      str.push(",0");
    }

    if (this.isNegative()) {
      sign = "-";
    }

    const currencyDetail = opts.currency;

    const decimalSetting = this.getDecimals(opts.decimals, currencyDetail);
    const maxDecimals = this.getMaxDecimals(decimalSetting, currencyDetail);

    const round = opts.round != null ? opts.round : "half-up";
    const smallestNumber = Math.pow(10, -maxDecimals);
    const tooSmallFunc = () => {
      switch (round) {
        case "down":
          return smallestNumber;
        case "half-up":
          return smallestNumber / 2;
        case "up":
          return 0;
        default:
          return 0;
      }
    };
    const tooSmallBounds = tooSmallFunc();
    if (0 < val && val < tooSmallBounds) {
      val = smallestNumber;
      decimals = maxDecimals;
      lessThan = "<";
    } else if (0 > val && val > -tooSmallBounds) {
      val = -smallestNumber;
      decimals = maxDecimals;
      lessThan = ">";
    } else if (typeof decimalSetting === "number") {
      decimals = decimalSetting;
    } else {
      decimals = this.significantPlaces(maxDecimals);
    }

    if (decimals > 0) {
      const zeroes = __range__(1, decimals, true)
        .map(i => "0")
        .join("");
      str.push(`.${zeroes}`);
    }

    const value = numbro(Math.abs(val)).format(str.join(""), Num.roundingFunction(round));
    const result = [];
    if (lessThan) {
      result.push(lessThan + " ");
    }
    if (sign) {
      result.push(sign);
    }
    if (currencyDetail && currencyDetail.leadingSymbol && currencyDetail.isNotional) {
      result.push(currencyDetail.leadingSymbol);
    }

    result.push(value);
    return result.join("");
  }

  static from(input: string | number) {
    return new Num(input);
  }
  static fromStrict(input: number | string) {
    return new Num(input, true);
  }
  static format(input: string | number, opts: Record<string, any> | string) {
    return new Num(input).format(opts);
  }
  static parse(input: string | number) {
    return new Num(input).val;
  }
  static isPositive(input: string | number) {
    return new Num(input).isPositive();
  }

  static round(input: number, places: number, direction: string | null) {
    const method = Num.roundingFunction(direction);
    const scale = Math.pow(10, places);
    return method(input * scale) / scale;
  }

  static roundingFunction(direction: string | null) {
    const method = (() => {
      switch (direction) {
        case "up":
          return "ceil";
        case "down":
          return "floor";
        default:
          return "round";
      }
    })();
    return Math[method];
  }

  // String operation to avoid scientific notation
  // e.g. 1 / Math.pow(10, 8) => 1e-8 instead of .00000001
  static formatIncrement = (places: number): string => `.${_.repeat("0", places - 1)}1`;

  // Can this utility be accomplished with ICU formatting alone?
  // https://unicode-org.github.io/icu/userguide/format_parse/numbers/skeletons.html
  static formatInterestRate = (input: string | number): string => {
    if (!input) return "0.00";

    if (typeof input === "string") {
      return parseFloat(input).toFixed(2);
    }
    return input.toFixed(2);
  };
}

export default Num;

function __range__(left, right, inclusive) {
  const range = [];
  const ascending = left < right;
  const end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
