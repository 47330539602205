import _ from "lodash";

declare global {
  interface Window {
    _sift: Array<any>;
  }
}

export const initializeSift = ({ sift, templateProps }) => {
  const userId = _.get(templateProps, "user.siftUserData.userId", "");
  const sessionId = _.get(templateProps, "user.siftUserData.sessionId", "");
  const beaconToken = sift.beaconToken;
  if (userId) {
    const _sift = (window._sift = window._sift || []);
    _sift.push(["_setAccount", beaconToken]);
    _sift.push(["_setUserId", userId]);
    _sift.push(["_setSessionId", sessionId]);
    _sift.push(["_trackPageview"]);

    (function () {
      function ls() {
        const e = document.createElement("script");
        e.src = "https://cdn.sift.com/s.js";
        if (document.body) document.body.appendChild(e);
      }
      window.addEventListener("load", ls, false);
    })();
  }
};
