export enum ModalStep {
  SelectSender = "SELECT_SENDER",
  ConfirmOwnership = "CONFIRM_OWNERSHIP",
  Complete = "COMPLETE",
  ComplyAdvantageMismatch = "COMPLY_ADVANTAGE_MISMATCH",
  Error = "ERROR",
}

export enum SenderType {
  MY_SELF = "MY_SELF",
  SOMEONE_ELSE = "SOMEONE_ELSE",
  NOT_RECOGNIZED = "NOT_RECOGNIZED",
}

export enum OwnerType {
  INDIVIDUAL = "INDIVIDUAL",
  ENTITY = "ENTITY",
}

export enum OwnershipStep {
  ReviewSelf = "REVIEW_SELF",
  ReviewThirdParty = "REVIEW_THIRD_PARTY",
  ReviewUnknown = "REVIEW_UNKOWN",
  ConfirmOwner = "CONFIRM_OWNER",
  ConfirmAddress = "CONFIRM_ADDRESS",
}
