import { useCallback, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import { minutesToMilliseconds } from "date-fns";
import axios from "@gemini-ui/services/axios";

const FIRST_CHECK_DELAY_MS = minutesToMilliseconds(10);
const SHOW_WARNING_MS = minutesToMilliseconds(2);
const CLOCK_SKEW_MS = 100; // this plus the round-trip time should give plenty of leeway to hit the Warning Time and Too Late points

const checkTimeoutUrl = jsRoutes.controllers.settings.LiveSettingsController.checkTimeout().url;
const pingUrl = jsRoutes.controllers.settings.LiveSettingsController.ping().url;

export const useActivityTimeout = () => {
  const checkTimer = useRef<number>();
  const [isOpen, setIsOpen] = useState(false);

  const handlePing = () => {
    window.clearTimeout(checkTimer.current);
    try {
      axios.get(pingUrl, { withCredentials: true });
    } catch (e) {}
    checkTimer.current = window.setTimeout(check, FIRST_CHECK_DELAY_MS);
    setIsOpen(false);
  };

  const check = useCallback(async () => {
    let response: AxiosResponse<{ millisRemaining: number }>;

    try {
      response = await axios.get(checkTimeoutUrl, { withCredentials: true });
    } catch (e) {}

    if (!response) return;

    const { millisRemaining: remaining } = response.data;

    if (typeof remaining !== "number") {
      // nothing
    } else if (remaining < 0) {
      window.location.reload();
    } else if (remaining < SHOW_WARNING_MS) {
      setIsOpen(true);
      checkTimer.current = window.setTimeout(() => window.location.reload(), remaining + CLOCK_SKEW_MS);
    } else {
      const timeUntilWarning = remaining - SHOW_WARNING_MS + CLOCK_SKEW_MS;
      checkTimer.current = window.setTimeout(check, timeUntilWarning);
    }
  }, []);

  useEffect(() => {
    checkTimer.current = window.setTimeout(check, FIRST_CHECK_DELAY_MS);
    return () => {
      window.clearTimeout(checkTimer.current);
    };
  }, [check]);

  return {
    handlePing,
    isOpen,
  };
};
