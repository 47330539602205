// Reusable password input for react-hook-form
// Based on https://github.com/gemini/gemini/blob/main/web/src/main/assets/scripts/pages/register/Register/PasswordInput.tsx
import React, { useState } from "react";
import styled from "@emotion/styled";
import { IconCheckCircleFilled } from "@hubble/icons";
import { Control, Controller, Path, RegisterOptions } from "react-hook-form";
import passwordValidation from "@gemini-ui/components/Form/utils/passwordValidation";
import { Colors, Flex, InputPassword, Spacing, Text } from "@gemini-ui/design-system";
import { PasswordInputProps } from "@gemini-ui/design-system/forms/Input/InputPassword/constants";
import { PasswordValidationState } from "@gemini-ui/pages/register/Register/constants";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props<TFormValues> extends Omit<PasswordInputProps, "name"> {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  rules?: RegisterOptions;
}

type TPasswordValidation = {
  description: string;
  validation: keyof typeof PasswordValidationState;
};

const PasswordValidationItem = ({ description, validation }: TPasswordValidation) => {
  return (
    <Flex align="center">
      <IconCheckCircleFilled
        size="xs"
        color={validation === PasswordValidationState.PASS ? Colors.green[600] : Colors.gray[300]}
      />
      <Text.Body
        size="xs"
        ml={0.5}
        color={validation === PasswordValidationState.PASS ? Colors.green[600] : Colors.gray[600]}
      >
        {description}
      </Text.Body>
    </Flex>
  );
};

const ValidationContainer = styled(Flex.Column)`
  margin-top: ${Spacing.scale[0.5]};
  margin-bottom: -${Spacing.scale[0.5]};
`;

export const PasswordInput = <TFormValues extends Record<string, unknown>>(props: Props<TFormValues>) => {
  const { name, control, rules = {}, ...rest } = props;
  const [validation, setValidation] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const { intl } = useIntl();
  const passwordDefault = intl.formatMessage({ defaultMessage: "Password" });

  const validate = (name: string): keyof typeof PasswordValidationState => {
    if (validation[name]) {
      return PasswordValidationState.PASS;
    } else {
      return PasswordValidationState.INVALID;
    }
  };

  const passwordHelperMessage = showValidation && (
    <ValidationContainer>
      <PasswordValidationItem
        description={intl.formatMessage({ defaultMessage: "8 or more characters" })}
        validation={validate("isLongEnough")}
      />
      <PasswordValidationItem
        description={intl.formatMessage({ defaultMessage: "Has a symbol, number, or upper-case letter" })}
        validation={validate("hasCharClasses")}
      />
    </ValidationContainer>
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { name, onChange, onBlur } }) => (
        <InputPassword
          name={name}
          label={props.label || passwordDefault}
          placeholder={props.placeholder || passwordDefault}
          data-testid={props["data-testid"] || "password"}
          message={props.message || passwordHelperMessage}
          onBlur={onBlur}
          onFocus={() => !showValidation && setShowValidation(true)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValidation(passwordValidation.validate(e.target.value));
            onChange(e);
          }}
          {...rest}
        />
      )}
    />
  );
};
