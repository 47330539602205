import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";

/**
 * Helper hook to verify the user is allowed to see Grow/Staking/Earn features
 */
export const useGrowFeatureFlags = () => {
  const { templateProps } = usePageData();

  // @ts-expect-error If we're in storybook mode, it's there, trust me
  if (Boolean(window?.IS_STORYBOOK)) {
    return {
      eligibleForPrivateStaking: true,
      eligibleForStaking: true,
      eligibleForEarn: true,
      eligibleForGrow: true,
      isGrowSidebarModuleEnabled: true,
      isEthAssetActivationQueueDisplayEnabled: true,
      isGrowAssetCarouselEnabled: true,
    };
  }

  const eligibleForEarn = Boolean(templateProps?.eligibilityInfo?.eligibleForEarn);
  const eligibleForGrow = Boolean(templateProps?.eligibilityInfo?.eligibleForGrow);
  const eligibleForStaking = Boolean(templateProps?.eligibilityInfo?.eligibleForStaking);
  const eligibleForPrivateStaking = Boolean(templateProps?.eligibilityInfo?.eligibleForPrivateStaking);

  const isGrowSidebarModuleEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.GROW_ENABLE_SIDEBAR_BUY_MODULE
  );
  const isEthAssetActivationQueueDisplayEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ENABLE_ETH_ASSET_ACTIVATION_QUEUE_DISPLAY
  );

  const isGrowAssetCarouselEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ENABLE_GROW_ASSET_CAROUSEL
  );

  return {
    eligibleForPrivateStaking,
    eligibleForStaking,
    eligibleForEarn,
    eligibleForGrow,
    isGrowSidebarModuleEnabled,
    isEthAssetActivationQueueDisplayEnabled,
    isGrowAssetCarouselEnabled,
  };
};
