import React from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  TableOptions,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import { HeaderCell } from "@gemini-ui/design-system/Table/HeaderCell";
import {
  ButtonCell,
  getCellStyles,
  INTERACTIVE_CELL_DATA_ATTR,
  INTERACTIVE_CELL_TEXT_UNDERLINE_DATA_ATTR,
  LinkCell,
  SIZE_MAP,
  Table as StyledTable,
  THeaderRow,
} from "@gemini-ui/design-system/Table/styles";
import { TableBodyRegular, TableBodyWindowed } from "@gemini-ui/design-system/Table/TableBody";
import { TableHeader } from "@gemini-ui/design-system/Table/TableHeader";
import { TablePagination } from "@gemini-ui/design-system/Table/TablePagination";

// Redefining native TableOptions props for storybook props table documentation
export interface TableProps<TData extends RowData>
  extends Omit<
    TableOptions<TData>,
    | "getCoreRowModel"
    | "getSortedRowModel"
    | "columns"
    | "data"
    | "state"
    | "filterFns"
    | "sortingFns"
    | "aggregationFns"
  > {
  /**
   * The array of column defs to use for the table. For more info, see the Column Def [section](https://github.com/TanStack/table/blob/main/docs/api/core/column-def.md)
   *
   *
   * 🧠 _When the `columns` prop changes reference (compared via `Object.is`), the table will re-render. We recommend using a constant, `React.useMemo`, or the `React` state to store `columns` and avoid unnecessary updates._
   */
  columns: TableOptions<TData>["columns"];
  /**
   * The data for the table to display. This array should match the type you provided to the `TData` generic. (ex: `<Table<RowData> data={rowData> ... />`)
   *
   *
   * 🧠 _When the `data` prop changes reference (compared via `Object.is`), the table will reprocess the data. Make sure `data` is only changing when you want the table to refresh._
   */
  data: TableOptions<TData>["data"];
  /**
   * If `true`, the table head element containing all column headers is visibly hidden but remains in the accessibility tree.
   */
  hideHead?: boolean;
  /**
   * The state option can be used to optionally control part or all of the table state.
   *
   *
   * The state you pass here will merge with and overwrite the internal automatically-managed state to produce the final state for the table.
   */
  state?: TableOptions<TData>["state"];
  /**
   * The size preset for the row height.
   *
   * @default lg
   *
   */
  rowSize?: keyof typeof SIZE_MAP;
  /**
   * When defined, `position: sticky` is applied to the table header row.
   *
   * The value sets the `top` offset and can be 0.
   */
  stickyHeaderTopOffset?: number;

  className?: string;
  "data-testid"?: string;
  handlePaginationChange?: (pageIndex: number) => void;
  /**
   * If `true`, the table renders loading rows instead of data row content.
   */
  loading?: boolean;
  /**
   * The number for rows rendered with a loading state. When using pagination,
   * it is recommended to use the same number as the pagination page size (via `tableProps.initialState.pagination.pageSize`) to minimize "content jumping".
   * @default 4
   */
  loadingRowCount?: number;

  /**
   * If true, only visible rows are rendered.
   * This is useful where there are many rows to reduce browser load
   */
  windowed?: boolean;

  /**
   * Integrate with an infinite scroll API rather than a classic pagination
   */
  infiniteScroll?: {
    loading: boolean;
    loadingRowCount?: number;
    scrollRef: React.MutableRefObject<any>;
  };

  /**
   * whether to show total no of pages in the UI
   */
  hideTotalPageCount?: boolean;
}

function Component<TData extends RowData>({
  ["data-testid"]: dataTestId,
  className,
  columns,
  data,
  hideHead,
  loading,
  loadingRowCount = 4,
  rowSize = "lg",
  state,
  stickyHeaderTopOffset,
  handlePaginationChange,
  onSortingChange,
  windowed,
  infiniteScroll,
  hideTotalPageCount,
  ...rest
}: TableProps<TData>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const handleSortingChange = React.useCallback(
    (sortingState: Updater<SortingState>) => {
      setSorting(sortingState);
      if (typeof onSortingChange === "function") onSortingChange(sortingState);
    },
    [onSortingChange]
  );

  const hasPagination = Boolean(rest.initialState?.pagination);

  const rowData = React.useMemo(() => {
    if (loading && loadingRowCount > 0) {
      return Array.from({ length: loadingRowCount }, (v, i) => ({ index: i })) as TData[];
    } else if (infiniteScroll?.loading) {
      return [
        ...data,
        ...(Array.from({ length: infiniteScroll.loadingRowCount ?? loadingRowCount }, (v, i) => ({
          index: i,
          loading: true,
        })) as TData[]),
      ];
    } else {
      return data;
    }
  }, [data, loadingRowCount, loading, infiniteScroll?.loading, infiniteScroll?.loadingRowCount]);

  const table = useReactTable<TData>({
    data: rowData,
    columns,
    // Forces ascending sort first, overriding auto-sort logic:
    // https://github.com/TanStack/table/blob/0a9e808ee60b95efc650c261152b4d14836c6df1/packages/table-core/src/features/Sorting.ts#L165-L174
    sortDescFirst: false,
    state: {
      sorting,
      ...state,
    },
    onSortingChange: handleSortingChange,
    ...rest,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: hasPagination && !rest.manualPagination ? getPaginationRowModel() : undefined,
  });

  const paginationState = table.getState().pagination;

  const userColDefsById = React.useMemo(
    () =>
      // get all columns regardless of visibility state
      table.getAllColumns().reduce<{ [id: string]: ColumnDef<any> }>((acc, col, i) => {
        // Get resolved col.id leveraging table's built-in logic
        // table._getColumnDefs() returns column defs from props.columns
        acc[col.id] = table._getColumnDefs()[i];
        return acc;
      }, {}),
    // Track changes to props.columns to avoid stale data
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, columns]
  );
  const previousPage = () => {
    rest.manualPagination && handlePaginationChange && handlePaginationChange(paginationState.pageIndex - 1);
    table.previousPage();
  };
  const nextPage = () => {
    rest.manualPagination && handlePaginationChange && handlePaginationChange(paginationState.pageIndex + 1);
    table.nextPage();
  };

  const TableBody = windowed ? TableBodyWindowed : TableBodyRegular;

  return (
    <React.Fragment>
      <StyledTable className={className} data-testid={dataTestId}>
        <TableHeader offset={stickyHeaderTopOffset} visiblyHidden={hideHead}>
          {table.getHeaderGroups().map(headerGroup => (
            <THeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(({ column, colSpan, id, getContext }, i) => {
                return (
                  <HeaderCell
                    visiblyHidden={column.columnDef.hideHeader}
                    key={id}
                    colSpan={colSpan}
                    justifyContent={column.columnDef.align}
                    sortDirection={column.getIsSorted()}
                    style={getCellStyles(column, userColDefsById[column.id])}
                    onSortingChange={column.getCanSort() ? column.getToggleSortingHandler() : undefined}
                    data-column-id={column.id}
                  >
                    {flexRender(column.columnDef.header, getContext())}
                  </HeaderCell>
                );
              })}
            </THeaderRow>
          ))}
        </TableHeader>
        <TableBody
          table={table}
          rowSize={rowSize}
          loading={loading}
          userColDefsById={userColDefsById}
          visibleColumnCount={table.getVisibleFlatColumns().length}
          scrollRef={infiniteScroll?.scrollRef}
        />
      </StyledTable>
      {hasPagination && (
        <TablePagination
          loading={loading}
          nextPage={nextPage}
          previousPage={previousPage}
          getCanNextPage={table.getCanNextPage}
          getCanPreviousPage={table.getCanPreviousPage}
          getPageCount={table.getPageCount}
          paginationState={paginationState}
          hideTotalPageCount={hideTotalPageCount}
        />
      )}
    </React.Fragment>
  );
}

export const Table = Object.assign(Component, {
  /**
   * Clickable Link cell that fills the entire area of the table cell.
   */
  LinkCell,
  /**
   * Clickable Button cell that fills the entire area of the table cell.
   */
  ButtonCell,
  /**
   * Data attribute to make the text of an interactive cell
   * display an underline when hovered or focused
   **/
  INTERACTIVE_CELL_TEXT_UNDERLINE_DATA_ATTR,
  /**
   * Data attribute to make the entire cell area clickable
   **/
  INTERACTIVE_CELL_DATA_ATTR,
});
