import styled from "@emotion/styled";

// https://webaim.org/techniques/css/invisiblecontent/#techniques
const screenReaderOnlyStyles = `
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const ScreenReaderOnly = styled.div`
  ${screenReaderOnlyStyles}
`;
