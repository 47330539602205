import styled from "@emotion/styled";
import { Button } from "@gemini-ui/design-system/Button";
import { CircleVariation } from "@gemini-ui/design-system/FileUploader/constants";
import { Flex } from "@gemini-ui/design-system/Flex";
import { formElementTextStyles } from "@gemini-ui/design-system/formElements";
import { Colors, Spacer, Spacing } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";
import { getColor } from "@gemini-ui/design-system/utils";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

const baseFileUploaderStylesObj = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  borderRadius: Spacing.scale[1],
  padding: `0 ${Spacing.scale[1.5]}`,
} as const;

export const EmptyZone = styled.div`
  ${baseFileUploaderStylesObj};
  height: 88px;
  border: 1px dashed ${getColor(Colors.gray[200], Colors.gray[400])};
`;

export const DropzoneWrapper = styled.div<{
  error: React.ReactNode;
  dropzone: boolean;
  disabled: boolean;
}>`
  ${formElementTextStyles};
  ${baseFileUploaderStylesObj};
  height: 88px;
  border: 1px dashed ${getColor(Colors.gray[200], Colors.gray[400])};

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
    background-color: ${getColor(Colors.gray[50], Colors.gray[900])(p)};
    border-color: ${getColor(Colors.gray[100], Colors.gray[800])(p)};
    color: ${getColor(Colors.gray[300], Colors.gray[600])(p)};
  `}
  &:hover {
    ${p =>
      !p.disabled &&
      `
    cursor: pointer;
    border-color: ${getColor(Colors.gray[300], Colors.gray[400])(p)};`}
  }
  ${p =>
    p.dropzone &&
    !p.disabled &&
    `
    border-color: ${getColor(Colors.blue[500], Colors.blue[300])(p)};
    background-color: ${getColor(Colors.blue[50], Colors.blue[800])(p)};
  `}
  ${p =>
    Boolean(p.error) &&
    `
    border-color: ${getColor(Colors.red[600], Colors.red[400])(p)};
    background-color: ${getColor(Colors.red[50], Colors.red[800])(p)};
  `}
  &:focus {
    ${p => `border-color: ${p.error ? Colors.red[600] : getColor(Colors.black, Colors.white)(p)};`}
    border-width: 2px;
    padding: 0 11px;
    outline: none;
  }
`;

export const FileWrapper = styled.div<{
  error: React.ReactNode;
  readOnly: boolean;
}>`
  ${formElementTextStyles};
  ${baseFileUploaderStylesObj};
  height: 88px;
  border: 1px solid ${getColor(Colors.gray[200], Colors.gray[400])};
  position: relative;
  ${p =>
    !p.readOnly &&
    `
    &:focus {
      ${p => `border-color: ${p.error ? Colors.red[600] : getColor(Colors.black, Colors.white)(p)};`}
      border-width: 2px;
      padding: 0 11px;
      outline: none;
    }
  `}
  ${p =>
    Boolean(p.error) &&
    `
    border-color: ${getColor(Colors.red[600], Colors.red[400])(p)};
    background-color: ${getColor(Colors.red[50], Colors.red[800])(p)};
  `}
`;

export const FlexWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  > * {
    padding: 0 ${Spacing.scale[0.5]};
  }
`;

export const FileUploaderWrapper = styled(Spacer)`
  min-width: 288px;
  @media ${BREAKPOINTS.tabletUp} {
    min-width: 370px;
  }
`;

export const IconButton = styled(Button.Tertiary)`
  padding: 0;
  border-radius: ${Spacing.scale[7]};
  min-width: ${Spacing.scale[5]};
  height: ${Spacing.scale[5]};
  @media ${BREAKPOINTS.mobileDownLarge} {
    width: auto;
  }
`;

export const AnimatedCircle = styled.circle<{ duration: number; variation: CircleVariation }>`
  ${({ duration }) => `transition: all ${duration}ms linear;`}
  ${p => p.variation === "base" && `stroke: ${getColor(Colors.gray[50], Colors.gray[700])(p)};`}
  ${p => p.variation === "loading" && `stroke: ${getColor(Colors.gray[900], Colors.white)(p)};`}
  ${p => p.variation === "success" && `stroke: ${getColor(Colors.green[600], Colors.green[300])(p)};`}
`;

export const InnerLoader = styled.div`
  position: absolute;
  top: 0;
`;

export const ToggleItem = styled.div<{ isHidden: boolean }>`
  position: absolute;
  top: 0;
  transition: opacity 600ms, transform 600ms;
  ${({ isHidden }) =>
    isHidden &&
    `
    opacity: 0;
    transform: scale(0.7);
  `}
`;

export const CircleProgressWrapper = styled.div<{ size: number; duration: number }>`
  position: relative;
  ${({ size }) => `
    width: ${size}px; height: ${size}px;
    
    > div {
      position: 0;
      top: 0;
    }
    > div:first-of-type {
      width: ${size}px; height: ${size}px;
    }
   
    ${ToggleItem} {
      ${({ duration }) => `transition: opacity ${duration}ms, transform ${duration}ms;`}
    }
  `}
`;

export const TextDiv = styled(Text.Body)`
  width: 100%;
`;

export const Underlined = styled(Text.Body)`
  text-decoration: underline;
  color: inherit;
`;

export const SmallDeviceContent = styled(Text.Body)`
  display: none;
  @media ${BREAKPOINTS.tabletDown} {
    display: inline-block;
  }
`;

export const WideDeviceContent = styled(Text.Body)`
  @media ${BREAKPOINTS.tabletDown} {
    display: none;
  }
`;
