import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { XfersPagePropsType } from "@gemini-ui/transformers/PaymentMethods";

export type SupportedBank = {
  minLength: number;
  maxLength: number;
  bankName: string;
  bankAbbreviation: string;
};

export type SupportedBanks = SupportedBank[];

export type RegisteredXfersAccount = {
  lastUsedAt: string;
  name: string;
  uuid: string;
  xfersCurrency: CurrencyShortNameFiat;
};

export enum XfersStep {
  INIT = "INIT",
  TOGGLE_LIST = "TOGGLE_LIST",
  SUCCESS_MODAL = "SUCCESS_MODAL",
  MAX_ACCOUNTS = "MAX_ACCOUNTS",
  ADD_BANK = "ADD_BANK",
  OTP_VERIFY = "OTP_VERIFY",
  PHONE_VERIFY = "PHONE_VERIFY",
  SHOW_DEPOSIT_INSTRUCTIONS = "SHOW_DEPOSIT_INSTRUCTIONS",
}
export interface XfersBankRegFlowProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  currency: CurrencyShortNameFiat;
  xfers?: XfersPagePropsType;
  isSettingsOrOnboarding?: boolean;
  scope?: PaymentTypeScope;
  subaccountHashid?: string;
}
