/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { track3DSReturnURLMismatch } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";

interface ChallengeFormProps {
  handleSubmit: () => void;
  jwt: string;
  md: string;
  url: string;
}

class ChallengeForm extends React.Component<ChallengeFormProps> {
  componentDidMount() {
    window.addEventListener("message", this.handleMessage, false);
    (document.getElementById("challengeForm") as HTMLFormElement).submit();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage, false);
  }

  handleMessage = (x: MessageEvent) => {
    if (x.origin.endsWith(window.location.origin) && x?.data === "success") {
      this.props.handleSubmit();
    }
    track3DSReturnURLMismatch();
  };

  render() {
    const { jwt, md, url } = this.props;
    return (
      <React.Fragment>
        <iframe id="challenge" name="challenge" title="challenge" height="400" width="390">
          <form id="challengeForm" name="challengeForm" method="POST" action={url} target="challenge">
            <input type="hidden" name="JWT" value={jwt} />
            <input type="hidden" name="MD" value={md} />
          </form>
        </iframe>
      </React.Fragment>
    );
  }
}

export default ChallengeForm;
