import { PageName } from "@gemini-ui/constants/initialData/pageName";

export const CUSTODY_ONLY_PAGES = [PageName.CustodyDashboard, PageName.ClearingDashboard, PageName.WrongAccountType];
export const EXCHANGE_ONLY_PAGES = [
  PageName.Home,
  PageName.Portfolio,
  PageName.RetailTrade,
  PageName.Dashboard,
  PageName.Market,
  PageName.Trade,
  PageName.ClearingDashboard,
  PageName.Earn,
  PageName.Grow,
];
