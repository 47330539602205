import React from "react";
import ReactMarkdown, { Components, Options } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkDirective from "remark-directive";
import remarkGfm from "remark-gfm";
import remarkDirectiveRehype from "@gemini-ui/components/Markdown/remark-directive-rehype";
import { Text } from "@gemini-ui/design-system";

type MarkdownProps = {
  source?: string;
  children?: string;
  componentConfig?: Components;
} & Partial<Options>;

const defaultHeader = ({ children }) => (
  <Text.Heading size="xs" mt={2} mb={1}>
    {children}
  </Text.Heading>
);

const headers = {
  h1: defaultHeader,
  h2: defaultHeader,
  h3: defaultHeader,
  h4: defaultHeader,
  h5: defaultHeader,
  h6: defaultHeader,
};

export const Markdown = ({ source, componentConfig, children, ...componentProps }: MarkdownProps) => (
  <ReactMarkdown
    children={source || children}
    rehypePlugins={[rehypeRaw]}
    remarkPlugins={[remarkGfm, remarkDirective, remarkDirectiveRehype]}
    components={{
      p: ({ children }) => <Text.Body size="sm">{children}</Text.Body>,
      a: ({ href, children, target }) => (
        <Text.Link href={href} target={target}>
          {children}
        </Text.Link>
      ),
      ...headers,
      ...componentConfig,
    }}
    {...componentProps}
  />
);

export default Markdown;
