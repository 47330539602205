import React, { useEffect, useRef, useState } from "react";
import { IconArrowsCircle, IconClose, IconDeleteOutlined } from "@hubble/icons";
import axios, { AxiosRequestConfig } from "axios";
import { CircularProgress } from "@gemini-ui/design-system/FileUploader/CircularProgress";
import { FileProps } from "@gemini-ui/design-system/FileUploader/constants";
import { FileIcon } from "@gemini-ui/design-system/FileUploader/FileIcon";
import { getFileUploaderCopy } from "@gemini-ui/design-system/FileUploader/i18n";
import {
  FileWrapper,
  FlexWrapper,
  IconButton,
  TextDiv,
  WideDeviceContent,
} from "@gemini-ui/design-system/FileUploader/styles";
import { isStoredFile, needsToBeUploaded } from "@gemini-ui/design-system/FileUploader/utils";
import { Flex } from "@gemini-ui/design-system/Flex";
import { Message } from "@gemini-ui/design-system/forms/Message";
import { Spacer } from "@gemini-ui/design-system/primitives/Spacer";
import { LimitTextLength } from "@gemini-ui/design-system/utils/LimitTextLength";
import { useIntl } from "@gemini-ui/utils/intl";
const CancelToken = axios.CancelToken;

const TIME_TO_HIDE_LOADING_PROGRESS = 3000;

export const File = ({
  ["data-testid"]: dataTestId,
  error,
  file,
  maxSize,
  message,
  onRemove,
  onUpload,
  uploadFile,
  readOnly,
  ...spacerOptions
}: FileProps) => {
  const { intl } = useIntl();
  const { ARIA_LABELS, CANCELLED, FAILED } = getFileUploaderCopy(intl);
  const [progress, setProgress] = useState<number>(
    needsToBeUploaded(file, error) ? 0 : isStoredFile(file) ? 100 : null
  );
  const [showLoadingProgress, setShowLoadingProgress] = useState<boolean>(false);
  const [innerErrorMessage, setInnerErrorMessage] = useState<string>("");
  const timer = useRef<NodeJS.Timeout>(null);
  const source = useRef(CancelToken.source());
  useEffect(() => {
    if (!isStoredFile(file) && !error) {
      handleUpload(file as File);
    }
    return () => {
      clearTimeout(timer.current);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileName = file.name;
  const extension = fileName.split(".").pop();
  const handleCancel = () => {
    setProgress(null);
    source.current.cancel(CANCELLED);
    source.current = CancelToken.source();
  };
  const loading = progress !== null && progress >= 0 && progress < 100;
  const hasError = Boolean(innerErrorMessage) || Boolean(error);
  const handleUpload = (file: File) => {
    if (file) {
      setInnerErrorMessage("");
      setShowLoadingProgress(true);
      const axiosConfig: AxiosRequestConfig = {
        cancelToken: source.current.token,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // This tracks the axios progress while uploading a file, but not the full request. 100% will be only set inside then
          setProgress(percentCompleted === 100 ? 99 : percentCompleted);
        },
      };
      uploadFile(file, axiosConfig)
        .then(uploadedFile => {
          setProgress(100);
          timer.current = setTimeout(() => {
            setShowLoadingProgress(false);
          }, TIME_TO_HIDE_LOADING_PROGRESS);
          onUpload(uploadedFile);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log(CANCELLED, err.message);
            setInnerErrorMessage(CANCELLED);
          } else {
            console.error(err);
            setInnerErrorMessage(FAILED);
          }
          onUpload(null);
          setProgress(null);
        });
    }
  };

  return (
    <Spacer as="div" {...spacerOptions}>
      <FileWrapper data-testid={dataTestId} readOnly={readOnly} error={hasError}>
        <FlexWrapper>
          <Flex alignItems="center" minWidth={0}>
            <FileIcon extension={extension} />
          </Flex>
          <Flex flex="1" minWidth={0}>
            <TextDiv as="div">
              <LimitTextLength text={fileName} middleTrim={true} addParentheses={false} />
            </TextDiv>
          </Flex>
          {showLoadingProgress && !hasError && (
            <React.Fragment>
              <WideDeviceContent as="div">{progress}%</WideDeviceContent>
              <div>
                <CircularProgress percentage={progress} size={20} />
              </div>
            </React.Fragment>
          )}
          {!readOnly && (
            <React.Fragment>
              {loading ? (
                <IconButton aria-label={ARIA_LABELS.CLOSE} onClick={handleCancel} data-testid="close-button">
                  <IconClose />
                </IconButton>
              ) : (
                <React.Fragment>
                  {Boolean(innerErrorMessage) && (
                    <IconButton
                      aria-label={ARIA_LABELS.RETRY}
                      onClick={() => handleUpload(file as File)}
                      data-testid="retry-button"
                    >
                      <IconArrowsCircle />
                    </IconButton>
                  )}
                  <IconButton aria-label={ARIA_LABELS.REMOVE} onClick={onRemove} data-testid="remove-button">
                    {Boolean(innerErrorMessage) ? <IconClose /> : <IconDeleteOutlined />}
                  </IconButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </FlexWrapper>
      </FileWrapper>
      {hasError && <Message error={innerErrorMessage || error} data-testid={`${dataTestId}-message-text`} />}
    </Spacer>
  );
};
