import React from "react";
import { Button } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

interface LazyModalFooterButtonGroupProps {
  retry: () => void;
}

const LazyModalFooterButtonGroup: React.FC<React.PropsWithChildren<LazyModalFooterButtonGroupProps>> = ({ retry }) => {
  const { intl } = useIntl();

  return (
    <Button.Group>
      <Button.Secondary data-testid="refresh-lazy-load-failure-modal" onClick={() => window.location.reload()}>
        {intl.formatMessage({
          defaultMessage: "Refresh page",
        })}
      </Button.Secondary>
      <Button.Primary data-testid="close-lazy-load-failure-modal" onClick={retry}>
        {intl.formatMessage({
          defaultMessage: "Retry",
        })}
      </Button.Primary>
    </Button.Group>
  );
};

export default LazyModalFooterButtonGroup;
