import _ from "lodash";
import numbro from "numbro";
import {
  CURRENCIES_DETAIL,
  CurrencyShortName,
  getDecimalsForCurrency,
} from "@gemini-common/scripts/constants/currencies";

export interface InputNumberT {
  value: string;
  num: number;
  formatted: string;
  currency: CurrencyShortName;
}

export const formatCurrency = (
  value: string | number,
  currency: CurrencyShortName,
  withCommas = true,
  decimalsOverride?: number
): string => {
  const { minDecimals } = CURRENCIES_DETAIL[currency];
  const decimals = decimalsOverride || getDecimalsForCurrency(currency);
  const minZeroes = _.repeat("0", minDecimals);
  const maxZeroes = _.repeat("0", decimals - minDecimals);
  const formatNum = numbro(value);
  return formatNum.format(`0${withCommas ? ",0" : ""}.${minZeroes}[${maxZeroes}]`);
};

// When using form inputs with numbers, we sometimes want a string (so users can type in a `.` for example), and
// sometimes want numbers, and sometimes want formatted numbers. This makes that a little simpler to deal with.
export const InputNumber = (
  value: string | number,
  currency: CurrencyShortName,
  decimalsOverride?: number
): InputNumberT => {
  // allow numbers and exponent notation (GEM-17853)
  let val = value.toString().replace(/[^0-9e\-.]/g, "");
  const valueNum = Number(val);
  // We want to deal with this explicitly because we want placeholders to display for '0', not an actual value of '0'
  const isZero = valueNum === 0;

  if (!currency) {
    // supporting data structure when currency unknown or null (e.g. Percent Change)
    return {
      value: isZero ? "" : val.toString(),
      num: Number(val.replace(",", "")),
      formatted: isZero ? "" : val.toString(),
      currency: null,
    };
  }

  const currencyDecimals = decimalsOverride || getDecimalsForCurrency(currency);

  if (isNaN(valueNum)) {
    val = value.toString().replace(/[^0-9.,]/g, "");
  } else if (decimalsOverride === 0) {
    val = value.toString().replace(/[.]/g, "");
  } else {
    const idx = val.indexOf(".");
    const decimalCount = val.length - idx - 1;
    if (idx !== -1 && decimalCount > currencyDecimals) {
      val = val.slice(0, idx + currencyDecimals + 1);
    }
  }
  const isExponent = val.indexOf("e") !== -1;
  return {
    // Shown in the input field when focused/editing
    value: value === 0 ? "" : isExponent ? formatCurrency(val, currency, false, currencyDecimals) : val.toString(),
    // Used for actual calculations
    num: Number(val.replace(",", "")),
    // Display when unfocused
    formatted: isZero ? "" : formatCurrency(val, currency, true, currencyDecimals),
    currency,
  };
};
