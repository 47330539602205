import styled from "@emotion/styled";
import { Button } from "@gemini-ui/design-system/Button";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

export const MenuContainer = styled.div<{ hideOnMobile?: boolean }>`
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    @media ${BREAKPOINTS.mobileDownLarge} {
      display: none;
    }
    `}
`;

export const MenuUL = styled.ul`
  min-width: 200px;
  border-radius: ${Spacing.scale[1]};
  padding: ${Spacing.scale[1]} 0;
  background-color: ${Colors.white};
  box-shadow: 0 0 5px 1px rgba(16, 16, 26, 0.06);
  overflow: hidden;
`;

export const MenuItem = styled(Button.Tertiary)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  border-radius: 0;
  outline: 0;
  height: auto;

  &:hover,
  &:focus {
    background-color: ${Colors.gray[50]};
  }
`;

export const MenuButtonWrapper = styled.div`
  & > ${Button.Tertiary} {
    display: flex;
    align-items: center;
    min-width: auto;
    z-index: 1;
  }
`;
