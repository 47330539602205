/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Button, Card, Colors, Flex, Spacing, Text } from "@gemini-ui/design-system";
import { LearnMoreBlockGraphic, StakingIcon } from "@gemini-ui/pages/Earn/GrowSidebar/styles";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { STAKING_LEARN_MORE_ROUTE } from "@gemini-ui/pages/Earn/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export const LearnMoreCard = () => {
  const { intl } = useIntl();

  const { eligibleForStaking, eligibleForPrivateStaking } = useGrowFeatureFlags();

  const shouldUseColorVariant = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ENABLE_GROW_COLOR_SIDEBAR_CARDS
  );

  const Illustration = React.useMemo(
    () => (shouldUseColorVariant ? LearnMoreBlockGraphic : StakingIcon),
    [shouldUseColorVariant]
  );

  return (
    <React.Fragment>
      <Card variant="filled" customFilledBgColor={shouldUseColorVariant ? Colors.lime[100] : null}>
        <Flex flexDirection="row" justify="space-between">
          <Flex flexDirection="column" align="start" justify="start" gap={Spacing.scale[1]}>
            <Text.Heading size="sm">{intl.formatMessage({ defaultMessage: "What is Staking?" })}</Text.Heading>
            <Text.Body size="sm" pb={1}>
              {intl.formatMessage({ defaultMessage: "Put your crypto to work on the blockchain and earn rewards." })}
            </Text.Body>

            <Button.Primary
              size="md"
              data-testid={testIds.sidebar.learnMoreCard.actionBtn}
              cta={intl.formatMessage({ defaultMessage: "Learn more" })}
              href={STAKING_LEARN_MORE_ROUTE}
              target="_blank"
              onClick={() => {
                track(EVENTS.GROW_LEARN_MORE_CARD_CLICK.name, {
                  [EVENTS.GROW_LEARN_MORE_CARD_CLICK.properties.ELIGIBLE_FOR_STAKING]: eligibleForStaking,
                  [EVENTS.GROW_LEARN_MORE_CARD_CLICK.properties.ELIGIBLE_FOR_STAKING_PRO]: eligibleForPrivateStaking,
                });
                return true;
              }}
            />
          </Flex>
          <Flex flexDirection="column">
            <Illustration />
          </Flex>
        </Flex>
      </Card>
    </React.Fragment>
  );
};
