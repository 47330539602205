import React, { createContext } from "react";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { createIntl, createIntlCache, IntlConfig, IntlShape } from "react-intl";
import { optimizelyClient, track } from "@gemini-ui/analytics";
import { EVENTS } from "@gemini-ui/analytics/constants/events";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export const SPANISH_ONLY_DISCLOSURE =
  "Gemini proveerá la mayoría de sus productos, servicios y servicio al cliente en español. Sin embargo, nuestra tarjeta de crédito y los términos legales y el servicio al cliente relacionado a esta no estarán disponibles en español. Comuníquese con el equipo de servicio al cliente de Gemini si tiene alguna pregunta sobre la cobertura del idioma español.";

export const LANG_COOKIE_NAME = "lang";
const LANG_COOKIE_EXPIRY = 365 * 10;

const pseudoLocales = {
  long: "xx-LS",
  uppercase: "xx-AC",
  accented: "en-XA",
} as const;

const pseudoSupportedLocales = {
  [pseudoLocales.long]: "Long",
  [pseudoLocales.uppercase]: "Uppercase",
  [pseudoLocales.accented]: "Accented",
} as const;

export const locales = {
  english: "en-US",
  brazilian_portuguese: "pt-BR",
  spanish: "es-419",
  italian: "it-IT",
  turkish: "tr-TR",
  french: "fr-FR",
  ...(window.__DEV__ ? pseudoLocales : {}),
} as const;
const publicSiteTranslations = {
  [locales.brazilian_portuguese]: "pt-br",
  [locales.spanish]: "es-la",
  [locales.italian]: "it-it",
  [locales.turkish]: "tr-tr",
  [locales.french]: "fr-fr",
} as const;

export const getSupportedLocales = () => {
  const isSpanishEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.SPANISH_LOCALIZATION);
  const isPortugueseEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.PORTUGUESE_LOCALIZATION);
  const isItalianEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ITALIAN_LOCALIZATION);
  const isTurkishEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.TURKISH_LOCALIZATION);
  const isFrenchEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.FRENCH_LOCALIZATION);

  let supported: Partial<Record<(typeof locales)[keyof typeof locales], string>> = { [locales.english]: "English" };
  if (isSpanishEnabled) {
    supported = { ...supported, [locales.spanish]: "Español (Latinoamérica)" };
  }
  if (isPortugueseEnabled) {
    supported = { ...supported, [locales.brazilian_portuguese]: "Português (Brasil)" };
  }
  if (isItalianEnabled) {
    supported = { ...supported, [locales.italian]: "Italiano" };
  }
  if (isTurkishEnabled) {
    supported = { ...supported, [locales.turkish]: "Türkçe" };
  }
  if (isFrenchEnabled) {
    supported = { ...supported, [locales.french]: "Français" };
  }

  return { ...supported, ...(window.__DEV__ ? pseudoSupportedLocales : {}) };
};

export const onfidoLocales = {
  [locales.english]: "en_US",
  [locales.brazilian_portuguese]: "pt_PT",
  [locales.spanish]: "es_ES",
  [locales.italian]: "it_IT",
  [locales.turkish]: "tr_TR",
  [locales.french]: "fr_FR",
} as const;

type PseudoLocales = typeof pseudoLocales;
type Locales = typeof locales;
export type LangCodes = Locales[keyof Locales];

export type LocaleFiles = Omit<{ [key in LangCodes]: string }, PseudoLocales[keyof PseudoLocales]>;
export type Messages = IntlConfig["messages"];
export type { IntlShape } from "react-intl";
export { defineMessage } from "react-intl";

const cache = createIntlCache();
let _intl: ReturnType<typeof createIntl>;

export async function getIntl(locale: LangCodes = locales.english) {
  if (_intl?.locale === locale) return _intl;

  let response: AxiosResponse<Messages>;

  try {
    response = await axios.get(window.__ENTRY_LOCALE_FILES__[locale]);
  } catch (e) {}

  if (!response && _intl) return _intl;

  _intl = createIntl(
    {
      locale,
      messages: response ? response.data : {},
      defaultLocale: locales.english,
    },
    cache
  );

  return _intl;
}

/**
 * Immediately returns an intl object. Used in testing.
 */
export function getIntlSync(locale: LangCodes = locales.english, initialMessages: Messages) {
  if (_intl?.locale === locale) return _intl;

  _intl = createIntl(
    {
      locale,
      messages: initialMessages,
      defaultLocale: locales.english,
    },
    cache
  );

  return _intl;
}

export function reset() {
  _intl = undefined;
}

export type IntlContextType = {
  intl: IntlShape;
  locale: LangCodes;
  setLocale: React.Dispatch<React.SetStateAction<LangCodes>>;
  buildGeminiPublicLink: (route?: string) => string;
};
const IntlContext = createContext<IntlContextType>(undefined);

export function useIntl() {
  const context = React.useContext(IntlContext);
  if (context === undefined) {
    throw new Error("useIntl must be used within an IntlProvider");
  }
  return context;
}

export const withIntl = <
  C extends React.ComponentClass<any>,
  P = React.ComponentProps<C>,
  /** We `Omit` the `intl` props from our returned props since we don't want people passing those in */
  WithoutIntlProps = Omit<P, "intl" | "locale" | "buildGeminiPublicLink" | "setLocale">
>(
  Component: C
) => {
  return React.forwardRef((props: WithoutIntlProps, ref: any) => {
    const { intl, locale, buildGeminiPublicLink } = useIntl();

    return (
      <Component
        intl={intl}
        locale={locale}
        buildGeminiPublicLink={buildGeminiPublicLink}
        {...(props as any)}
        ref={ref}
      />
    );
  });
};

export interface IntlProviderProps {
  children: React.ReactNode;
  userLocale?: LangCodes;
}

export function IntlProvider({ children, userLocale }: IntlProviderProps) {
  const isCredit = window.location.pathname.startsWith("/credit-card/");

  // browser language may be set on initial load, default to english if it's not supported
  const supportedLocales = getSupportedLocales();

  const defaultLocale = supportedLocales[userLocale] && !isCredit ? userLocale : locales.english;
  const [locale, setLocale] = React.useState<LangCodes>(defaultLocale);
  const [intl, setIntl] = React.useState<IntlShape>(_intl);
  const localeEndpoint = jsRoutes.controllers.user.UserController.setLang().url;
  const [userUpdated, setUserUpdated] = React.useState(false);
  const userLoggedIn = Boolean(window?.initialData?.templateProps?.user);
  const buildGeminiPublicLink = (route = "") => {
    if (locale === locales.english) {
      return `https://www.gemini.com/${route}`;
    } else {
      const publicLocale = publicSiteTranslations[locale] ?? "en-us";
      return `https://www.gemini.com/${publicLocale}/${route}`;
    }
  };
  React.useEffect(() => {
    const initialLocale = locale === defaultLocale;

    async function run() {
      const _intl = await getIntl(locale);
      setIntl(_intl);
    }
    run();

    Cookies.set(LANG_COOKIE_NAME, locale, { expires: LANG_COOKIE_EXPIRY });

    if (!initialLocale && !userUpdated && userLoggedIn) {
      setUserUpdated(true);
    }
  }, [locale, userUpdated, defaultLocale, userLoggedIn]);

  React.useEffect(() => {
    if (userUpdated) {
      async function updateLocale() {
        await axios.post(localeEndpoint, { lang: locale });
      }

      updateLocale();
    }
  }, [userUpdated, locale, localeEndpoint]);

  const value = { intl, locale, setLocale, buildGeminiPublicLink };
  return intl ? <IntlContext.Provider value={value}>{children}</IntlContext.Provider> : null;
}

export const trackChangeLanguage = (prevLocale, newLocale, isOnboarding = false) => {
  if (prevLocale === newLocale) {
    return;
  }

  const { name, properties } = isOnboarding ? EVENTS.CHANGE_LANGUAGE_ONBOARDING : EVENTS.CHANGE_LANGUAGE;

  const supportedLocales = getSupportedLocales();
  const analyticsProperties = {
    [properties.PREV_LANGUAGE]: supportedLocales[prevLocale] ?? "",
    [properties.NEW_LANGUAGE]: supportedLocales[newLocale] ?? "",
  };

  track(name, analyticsProperties);
};
