import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/browser";
import {
  CURRENCIES_DETAIL,
  CurrencyPairDetail,
  CurrencyShortName,
  CurrencyShortNameFiat,
} from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { SupportLink } from "@gemini-ui/components/SupportLink";
import { WatchlistResponseItem } from "@gemini-ui/constants/watchlist";
import { usePageRefresh } from "@gemini-ui/contexts";
import { getPairDetail } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { getTradingPair, WatchlistContext } from "@gemini-ui/pages/RetailTrade/AssetDetail/utils";
import {
  getAllEnabledWatchlist,
  getWatchlistIsEnabled,
  putEnableWatchlistFavorite,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/WatchlistButton/client";
import { PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import { isCurrencyPairSupported } from "@gemini-ui/pages/RetailTrade/utils";
import { AssetInfoResponse, fetchContentfulAssetInfo } from "@gemini-ui/services/assetDetail/fetchContentfulAssetInfo";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

enum WATCH_STATES {
  LOADING = "loading",
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export type TradableAssets = Record<CurrencyShortName, { isTradable: boolean; displayName: string }>;

export const useTradableAssets = (
  list: CurrencyShortName[],
  allPairDetails: PairDetail[],
  defaultFiat: CurrencyShortNameFiat,
  supportedPairs: CurrencyPairDetail[]
) => {
  return useMemo(
    () =>
      list.reduce((prev, currency) => {
        const tradingPair = getTradingPair(currency, defaultFiat);
        const pairDetail = getPairDetail(allPairDetails, tradingPair);
        const hasPairDetail = Boolean(pairDetail);
        const isPairSupported = isCurrencyPairSupported(supportedPairs, tradingPair);
        const isTradable = hasPairDetail && pairDetail.instantMarketOpen && isPairSupported;

        prev[currency] = {
          isTradable,
          displayName: pairDetail?.toDisplayName || "",
        };

        return prev;
      }, {} as TradableAssets),
    [allPairDetails, defaultFiat, list, supportedPairs]
  );
};

export const useAllEnabledWatchlist = () => {
  const [watchlist, setWatchlist] = useState<WatchlistResponseItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchWatchlist = useCallback(async (setLoadingState = true) => {
    if (setLoadingState) {
      setLoading(true);
    }
    const data = await getAllEnabledWatchlist();
    setWatchlist(data);

    if (setLoadingState) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWatchlist();
  }, [fetchWatchlist]);

  return { watchlist, loading, refetch: fetchWatchlist };
};

export const useWatchlist = (
  currency: CurrencyShortName,
  disabled = false,
  disableFetch = false,
  isEnabled = false,
  onToggleComplete?: () => void
) => {
  const watchlist = useContext(WatchlistContext);
  const { requestRefresh } = usePageRefresh();
  const { showAlert } = useAlert();
  const [isToggling, setIsToggling] = useState(false);

  const [enabledState, setEnabled] = useState<WATCH_STATES>(() => {
    if (isEnabled) return WATCH_STATES.ENABLED;
    if (disabled) return WATCH_STATES.DISABLED;
    return WATCH_STATES.LOADING;
  });

  const cancelRef = useRef(false);

  useEffect(() => {
    if (disabled || disableFetch) return;

    cancelRef.current = false;

    getWatchlistIsEnabled(currency)
      .then(isEnabled => {
        if (cancelRef.current) return;
        if (isEnabled) setEnabled(WATCH_STATES.ENABLED);
        else setEnabled(WATCH_STATES.DISABLED);
      })
      .catch(err => {
        if (cancelRef.current) return;
        setEnabled(WATCH_STATES.DISABLED);
      });

    return function cleanup() {
      cancelRef.current = true;
    };
  }, [currency, enabledState, disabled, disableFetch]);

  const handleToggleIsWatching = useCallback(() => {
    const isEnabled = enabledState === WATCH_STATES.ENABLED;
    setIsToggling(true);
    putEnableWatchlistFavorite(isEnabled, currency)
      .then(success => {
        const successState = isEnabled ? WATCH_STATES.DISABLED : WATCH_STATES.ENABLED;
        if (success) {
          const { name, properties } = EVENTS.ADD_TO_WATCHLIST;
          const watchlistSize = watchlist.length;
          const updatedSize = isEnabled ? watchlistSize - 1 : watchlistSize + 1;

          track(name, {
            [properties.CURRENCY]: currency,
            [properties.PREVIOUS_SIZE]: watchlist.length,
            [properties.UPDATED_SIZE]: updatedSize,
          });
          setEnabled(successState);
          requestRefresh();
        }
      })
      .catch(err => {
        showAlert({ type: AlertTypes.ERROR, message: getError(err) });
      })
      .finally(() => {
        if (onToggleComplete) {
          onToggleComplete();
        }
        setIsToggling(false);
      });
  }, [enabledState, currency, watchlist.length, requestRefresh, showAlert, onToggleComplete]);

  return {
    isWatching: enabledState === WATCH_STATES.ENABLED,
    isNotWatching: enabledState === WATCH_STATES.DISABLED,
    loading: enabledState === WATCH_STATES.LOADING,
    isToggling: isToggling,
    handleToggleIsWatching,
  };
};

const trackToggle = (currency: CurrencyShortName, enabled: boolean) => {
  let eventName: string;
  const eventProperties = EVENTS.ENABLE_MARKET_ALERT.properties;

  if (enabled) {
    eventName = EVENTS.DISABLE_MARKET_ALERT.name;
  } else {
    eventName = EVENTS.ENABLE_MARKET_ALERT.name;
  }

  track(eventName, {
    [eventProperties.ASSET]: currency,
  });
};

export const useMarketAlerts = (currency: CurrencyShortName, disabled = false, isEnabled = false) => {
  const { intl } = useIntl();
  const { showAlert } = useAlert();

  const [marketAlertEnabled, setMarketAlertEnabled] = useState<boolean>(isEnabled);
  const [loading, setLoading] = useState<boolean>();

  const cancelRef = useRef(false);

  useEffect(() => {
    if (disabled) return;

    cancelRef.current = false;

    setLoading(true);
    axios
      .get<WatchlistResponseItem[]>("/watchlist")
      .then(res => {
        if (cancelRef.current) return;
        if (res.status === 200 && res.data) {
          const marketMovementWatches = res.data.filter(
            item => item.watchType === "MarketMovementWatch" && item.currency === currency
          );

          if (marketMovementWatches.length > 0) {
            setMarketAlertEnabled(marketMovementWatches[0].isEnabled);
          }
        }
      })
      .catch(e => {
        if (cancelRef.current) return;

        Sentry.captureException(e);

        // Swallow it for now
        setMarketAlertEnabled(false);
      })
      .finally(() => setLoading(false));

    return function cleanup() {
      cancelRef.current = true;
    };
  }, [currency, disabled]);

  const handleToggleChange = useCallback(() => {
    const url = !marketAlertEnabled
      ? `/watchlist/${currency}/MarketMovementWatch/enable`
      : `/watchlist/${currency}/MarketMovementWatch/disable`;

    axios
      .put(url)
      .then(() => {
        trackToggle(currency, !marketAlertEnabled);
        setMarketAlertEnabled(!marketAlertEnabled);
      })
      .catch(e => {
        Sentry.captureException(e);
        showAlert({
          type: AlertTypes.ERROR,
          message: (
            <span>
              {intl.formatMessage(
                defineMessage({ defaultMessage: "Something went wrong. Please <SupportLink></SupportLink>." }),
                {
                  SupportLink: () => <SupportLink />,
                }
              )}
            </span>
          ),
        });
      });
  }, [currency, marketAlertEnabled, intl, showAlert]);

  return { handleToggleChange, loading, marketAlertEnabled };
};

export function useFetchAssetInfo(cryptoShortName = "") {
  const [assetInfo, setAssetInfo] = React.useState({
    contentLong: "",
    contentShort: "",
    assetName: "",
  } as AssetInfoResponse);

  React.useEffect(() => {
    let cancelRequest = false;

    fetchContentfulAssetInfo(cryptoShortName)
      .then((response: AssetInfoResponse) => {
        if (cancelRequest) return;
        setAssetInfo(response);
      })
      .catch(err => {
        return err;
      });

    return function cleanup() {
      cancelRequest = true;
    };
  }, [cryptoShortName]);

  const config: AssetInfoResponse = assetInfo;
  return config;
}

export const getCurrencyTitle = (currency: CurrencyShortName) => {
  return Boolean(currency) ? `${CURRENCIES_DETAIL[currency].name} (${currency})` : "";
};
