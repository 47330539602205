import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import get from "lodash/get";
import mixpanelLib from "mixpanel-browser";
import queryString from "query-string";
import uuidv4 from "uuid/v4";
import {
  AnalyticsEventName,
  AnalyticsProperty,
  EVENTS,
  PEOPLE,
  PeopleProperty,
  SUPER_PROPERTIES,
  SuperProperty,
  UTM_PARAMETERS,
  UTM_PROPERTIES,
} from "@gemini-ui/analytics/constants/events";
import { mixpanelMock } from "@gemini-ui/analytics/mixpanelMock";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { User } from "@gemini-ui/constants/templateProps/users";

// Uncomment the below test token whenever you need to test a new analytics integration
const MIXPANEL_TEST_TOKEN = ""; //"391f3d614bc492e253143711eac7df8b";

// BE overwrites this for authenticated users
const ANALYTICS_UUID_COOKIE_NAME = "AnalyticsId";
export const getAnalyticsIdFromCookie = () => {
  const analyticsCookie = Cookies.get(ANALYTICS_UUID_COOKIE_NAME);
  return analyticsCookie?.split("=")[1];
};
const ANALYTICS_UUID_COOKIE_EXPIRY = 365 * 5; // 5 years
const UUIDV4_STRING_LENGTH = 36;

let mixpanel = mixpanelLib;
let analyticsId;
let mixpanelLoaded = false;
const baseConfig = {
  api_host: "https://api.mixpanel.com",
  secure_cookie: !window.__DEV__,
  ip: false,
  loaded: () => {
    mixpanelLoaded = true;
    // Remove optimizely features from the mixpanel cookie
    Object.keys(mixpanel.cookie?.props || {})
      .filter(feature => feature.startsWith("Feature: "))
      .map(feature => mixpanel.unregister(feature));
  },
};

function getActiveInterface(user: User) {
  const isActiveTrader = get(user, "advancedTradeUIEnabled", null);
  if (isActiveTrader) return "ActiveTrader";
  return "Retail UI";
}

export const mixpanelClient = {
  initializeMixpanel: (mixpanelToken: string) => {
    const token = mixpanelToken || MIXPANEL_TEST_TOKEN;
    const mixpanelConfig = {
      ...baseConfig,
      debug: window.initialData?.mode === "dev",
    };
    // If no token (Dev or Test) then we will log tracking rather than sending to Mixpanel
    if (!token) {
      mixpanel = mixpanelMock;
      mixpanelLoaded = true;
    } else {
      try {
        mixpanel.init(token, mixpanelConfig);
      } catch (reason) {
        console.error(reason);
      }
    }
  },
  track: (eventName: AnalyticsEventName, properties?: AnalyticsProperty) => {
    if (mixpanelLoaded) {
      mixpanel.track(eventName, properties);
    }
  },
  setSuperProperty: (superProperty: SuperProperty) => mixpanelLoaded && mixpanel.register(superProperty),
  setSuperPropertyOnce: (superProperty: SuperProperty) => mixpanelLoaded && mixpanel.register_once(superProperty),
  incrementSuperProperty: (superProperty: string) => {
    if (mixpanelLoaded) {
      const value = mixpanel.get_property(superProperty);
      const update = {};

      if (value && typeof value === "number") {
        update[superProperty] = value + 1;
      } else {
        update[superProperty] = 1;
      }
      mixpanel.register(update);
    }
  },
  setPeopleProperty: (peopleProperties: PeopleProperty) => mixpanelLoaded && mixpanel.people.set(peopleProperties),
  setPeoplePropertyOnce: (peopleProperties: PeopleProperty) =>
    mixpanelLoaded && mixpanel.people.set_once(peopleProperties),
  incrementPeopleProperty: (peopleProperty: string, incrementBy = 1) =>
    mixpanelLoaded && mixpanel.people.increment(peopleProperty, incrementBy),
  aliasUser: (newTrackingId: string) => mixpanelLoaded && mixpanel.alias(newTrackingId),
  getMixpanelId: (): string => {
    analyticsId = getAnalyticsIdFromCookie();
    if (!analyticsId) {
      mixpanelClient.setGeminiAnalyticId(uuidv4());
    }
    return analyticsId;
  },
  setGeminiAnalyticId: (id: string): void => {
    if (id?.length === UUIDV4_STRING_LENGTH) {
      analyticsId = id;
      // stays consistent with BE format
      const cookieValue = `analyticsId=${id}`;
      Cookies.set(ANALYTICS_UUID_COOKIE_NAME, cookieValue, {
        expires: ANALYTICS_UUID_COOKIE_EXPIRY,
        domain: `.${window.location.hostname}`,
      });
    } else {
      Sentry.captureException(`mixpanelClient expected a uuid string but received ${id} instead.`);
    }
  },
  reset: () => mixpanelLoaded && mixpanel.reset(),
  optOut: () => mixpanelLoaded && mixpanel.opt_out_tracking(),
  trackLinkClickAndRedirectManually: (
    href: string,
    eventName: AnalyticsEventName,
    properties?: AnalyticsProperty,
    timeoutOpt = 300
  ) => {
    // Manual version of mixpanel's track links that doesn't rely on passing in a query selector.
    // Simulates behavior by adding timeout if mixpanel server doesnt respond and forces a redirect: https://github.com/mixpanel/mixpanel-js/blob/master/src/dom-trackers.js#L46
    if (mixpanelLoaded) {
      const timeout = setTimeout(() => {
        window.location.href = href;
      }, timeoutOpt);
      mixpanel.track(eventName, properties, () => {
        clearTimeout(timeout);
        window.location.href = href;
      });
    } else {
      window.location.href = href;
    }
  },
  trackLinks: (elementId: string, name: string, properties: {}) => mixpanel.track_links(elementId, name, properties),
};

export const setUtmPeopleProperties = (mixpanelId: string) => {
  if (mixpanelLoaded && mixpanelId) {
    const {
      CAMPAIGN_FIRST_TOUCH,
      CAMPAIGN_LAST_TOUCH,
      MEDIUM_FIRST_TOUCH,
      MEDIUM_LAST_TOUCH,
      SOURCE_FIRST_TOUCH,
      SOURCE_LAST_TOUCH,
      CONTENT_LAST_TOUCH,
      TERM_LAST_TOUCH,
      AD_GROUP_FIRST_TOUCH,
      AD_GROUP_LAST_TOUCH,
      AD_GROUP_ID_FIRST_TOUCH,
      AD_GROUP_ID_LAST_TOUCH,
      CAMPAIGN_ID_FIRST_TOUCH,
      CAMPAIGN_ID_LAST_TOUCH,
      COUNTRY_FIRST_TOUCH,
      COUNTRY_LAST_TOUCH,
      CREATIVE_FIRST_TOUCH,
      CREATIVE_LAST_TOUCH,
      CREATIVE_ID_FIRST_TOUCH,
      CREATIVE_ID_LAST_TOUCH,
    } = UTM_PROPERTIES;

    const query = queryString.parse(window.location.search);

    const campaign = query[UTM_PARAMETERS.UTM_CAMPAIGN] || mixpanel.get_property(CAMPAIGN_LAST_TOUCH);
    const medium = query[UTM_PARAMETERS.UTM_MEDIUM] || mixpanel.get_property(MEDIUM_LAST_TOUCH);
    const source = query[UTM_PARAMETERS.UTM_SOURCE] || mixpanel.get_property(SOURCE_LAST_TOUCH);
    const content = query[UTM_PARAMETERS.UTM_CONTENT] || mixpanel.get_property(CONTENT_LAST_TOUCH);
    const term = query[UTM_PARAMETERS.UTM_TERM] || mixpanel.get_property(TERM_LAST_TOUCH);
    const adGroup = query[UTM_PARAMETERS.UTM_ADGROUP] || mixpanel.get_property(AD_GROUP_LAST_TOUCH);
    const adGroupId = query[UTM_PARAMETERS.UTM_ADGROUP_ID] || mixpanel.get_property(AD_GROUP_ID_LAST_TOUCH);
    const campaignId = query[UTM_PARAMETERS.UTM_CAMPAIGN_ID] || mixpanel.get_property(CAMPAIGN_ID_LAST_TOUCH);
    const country = query[UTM_PARAMETERS.UTM_COUNTRY] || mixpanel.get_property(COUNTRY_LAST_TOUCH);
    const creative = query[UTM_PARAMETERS.UTM_CREATIVE] || mixpanel.get_property(CREATIVE_LAST_TOUCH);
    const creativeId = query[UTM_PARAMETERS.UTM_CREATIVE_ID] || mixpanel.get_property(CREATIVE_ID_LAST_TOUCH);

    // set people property
    mixpanel.people.set_once({
      ...(campaign && { [CAMPAIGN_FIRST_TOUCH]: campaign }),
      ...(medium && { [MEDIUM_FIRST_TOUCH]: medium }),
      ...(source && { [SOURCE_FIRST_TOUCH]: source }),
      ...(adGroup && { [AD_GROUP_FIRST_TOUCH]: adGroup }),
      ...(adGroupId && { [AD_GROUP_ID_FIRST_TOUCH]: adGroupId }),
      ...(campaignId && { [CAMPAIGN_ID_FIRST_TOUCH]: campaignId }),
      ...(country && { [COUNTRY_FIRST_TOUCH]: country }),
      ...(creative && { [CREATIVE_FIRST_TOUCH]: creative }),
      ...(creativeId && { [CREATIVE_ID_FIRST_TOUCH]: creativeId }),
    });
    mixpanel.people.set({
      ...(campaign && { [CAMPAIGN_LAST_TOUCH]: campaign }),
      ...(medium && { [MEDIUM_LAST_TOUCH]: medium }),
      ...(source && { [SOURCE_LAST_TOUCH]: source }),
      ...(term && { [TERM_LAST_TOUCH]: term }),
      ...(content && { [CONTENT_LAST_TOUCH]: content }),
      ...(adGroup && { [AD_GROUP_LAST_TOUCH]: adGroup }),
      ...(adGroupId && { [AD_GROUP_ID_LAST_TOUCH]: adGroupId }),
      ...(campaignId && { [CAMPAIGN_ID_LAST_TOUCH]: campaignId }),
      ...(country && { [COUNTRY_LAST_TOUCH]: country }),
      ...(creative && { [CREATIVE_LAST_TOUCH]: creative }),
      ...(creativeId && { [CREATIVE_ID_LAST_TOUCH]: creativeId }),
    });

    // set event super property
    mixpanel.register({
      ...(campaign && { [CAMPAIGN_LAST_TOUCH]: campaign }),
      ...(medium && { [MEDIUM_LAST_TOUCH]: medium }),
      ...(source && { [SOURCE_LAST_TOUCH]: source }),
      ...(term && { [TERM_LAST_TOUCH]: term }),
      ...(content && { [CONTENT_LAST_TOUCH]: content }),
      ...(adGroup && { [AD_GROUP_LAST_TOUCH]: adGroup }),
      ...(adGroupId && { [AD_GROUP_ID_LAST_TOUCH]: adGroupId }),
      ...(campaignId && { [CAMPAIGN_ID_LAST_TOUCH]: campaignId }),
      ...(country && { [COUNTRY_LAST_TOUCH]: country }),
      ...(creative && { [CREATIVE_LAST_TOUCH]: creative }),
      ...(creativeId && { [CREATIVE_ID_LAST_TOUCH]: creativeId }),
    });
  }
};

export const identifyMixpanelUser = (pageName: PageName, user: User) => {
  if (mixpanelLoaded && user.mixpanelId) {
    mixpanelClient.setGeminiAnalyticId(user.mixpanelId);
    const selectedInterface = getActiveInterface(user);
    mixpanel.identify(user.mixpanelId);
    setUtmPeopleProperties(user.mixpanelId);
    mixpanelClient.setPeopleProperty({
      [PEOPLE.UNIQUE_ID]: user.mixpanelId,
      [PEOPLE.ACCOUNT_TYPE]: user.isInstitutional ? "Institutional" : "Personal",
      [PEOPLE.BRAZE_EXTERNAL_ID]: user.brazeExternalId,
      [PEOPLE.ACTIVE_TRADER_ENABLED]: selectedInterface === "ActiveTrader",
      [PEOPLE.EXCHANGE_INTERFACE]: selectedInterface,
    });
    mixpanelClient.setSuperProperty({ [SUPER_PROPERTIES.EXCHANGE_INTERFACE]: selectedInterface });
    mixpanelClient.track(EVENTS.PAGE_VIEW.name, { [EVENTS.PAGE_VIEW.properties.PAGE_NAME]: pageName });
  }
};
