import { useEffect, useState } from "react";
export const useWindowSize = () => {
  const [windowState, setWindowState] = useState(() => ({ width: window.innerWidth, height: window.innerHeight }));

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return windowState;
};
