import {
  CurrencyShortName,
  getDecimalsForPrice,
  isCurrency,
  isFiatCurrency,
} from "@gemini-common/scripts/constants/currencies";

export const shouldUseDecimalOverride = (toCurrency: CurrencyShortName, priceCurrency: string, pair: string) => {
  if ((isCurrency.SHIB(toCurrency) || isCurrency.PEPE(toCurrency)) && isFiatCurrency(priceCurrency)) {
    return getDecimalsForPrice(pair);
  }
};
