/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import * as React from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationReferral = create(({ black, bgPrimary, contentPrimary, white }) => (
  <React.Fragment>
    <path
      d="M35.4807 113.371C32.0094 97.7642 31.1062 83.512 34.3236 82.5242C37.5127 81.5364 42.0565 93.6438 46.9954 109.364C51.9343 125.083 53.4301 138.884 50.1845 139.674C45.2738 140.831 38.8956 128.611 35.4807 113.371Z"
      fill={black}
    />
    <path
      d="M49.5075 140.069C48.7737 140.069 48.0117 139.815 47.2497 139.336C42.8753 136.598 38.0211 126.184 35.1706 113.427C33.449 105.638 32.3484 98.1874 32.0661 92.4583C31.925 89.5514 32.0097 87.1807 32.3201 85.4874C32.6588 83.54 33.2797 82.4676 34.211 82.1853C37.6824 81.1129 41.9722 92.2325 47.3062 109.251C51.6524 123.051 53.374 135.582 51.4266 138.997C51.1162 139.533 50.7211 139.872 50.2413 139.985C50.0155 140.041 49.7615 140.069 49.5075 140.069ZM35.8197 113.286C38.6419 125.873 43.3833 136.118 47.5884 138.771C48.4633 139.336 49.31 139.505 50.072 139.336C50.3542 139.279 50.6082 139.053 50.834 138.686C52.6684 135.469 50.8622 122.91 46.6571 109.476C44.2864 101.885 37.9364 81.762 34.4086 82.8627C33.7877 83.0602 32.3484 84.3867 32.7435 92.4583C32.9975 98.1309 34.0981 105.525 35.8197 113.286Z"
      fill={white}
    />
    <path
      d="M64.6386 91.1322C56.0308 94.2931 49.7937 95.6478 38.5894 97.9056C38.5894 97.9056 38.2508 103.832 40.9037 113.371C42.9921 120.963 46.2095 128.922 46.2095 128.922L77.2258 118.649L97.0943 116.956L110.274 89.5518L99.2392 72.6466C99.2392 72.6184 73.2464 87.9713 64.6386 91.1322Z"
      fill={white}
    />
    <path
      d="M46.1822 129.261C46.0411 129.261 45.9282 129.176 45.8717 129.035C45.8435 128.95 42.6262 120.963 40.5659 113.428C37.9413 103.945 38.2517 97.9056 38.2517 97.8492C38.2517 97.6799 38.3646 97.567 38.5339 97.5388C50.1897 95.1963 56.1729 93.8416 64.5267 90.7936C72.9934 87.6892 98.7885 72.4774 99.0707 72.308C99.2401 72.2234 99.4376 72.2516 99.5223 72.4209L110.557 89.3261C110.614 89.439 110.642 89.5519 110.585 89.6647L97.3774 117.097C97.321 117.21 97.2081 117.266 97.0952 117.294L77.2831 118.988L46.2951 129.261C46.2668 129.261 46.2104 129.261 46.1822 129.261ZM38.9291 98.1879C38.9008 99.4297 38.9573 105.046 41.2433 113.287C43.0777 119.947 45.8153 126.975 46.408 128.499L77.142 118.339C77.1702 118.339 77.1985 118.339 77.2267 118.31L96.8976 116.645L109.908 89.5801L99.1554 73.0983C96.305 74.7634 72.8522 88.5076 64.7807 91.471C56.4269 94.4908 50.4437 95.8454 38.9291 98.1879Z"
      fill={black}
    />
    <path
      d="M84.644 110.521L84.2771 117.859C84.2771 117.859 90.3449 117.549 97.0618 116.787L84.644 110.521Z"
      fill={black}
    />
    <path
      d="M137.988 119.241C129.663 118.818 119.39 117.463 119.39 120.935C119.361 124.406 129.098 127.906 138.214 128.555"
      fill={white}
    />
    <path
      d="M138.213 128.866C138.213 128.866 138.185 128.866 138.213 128.866C133.669 128.555 128.956 127.539 125.259 126.1C122.945 125.197 119.05 123.334 119.05 120.907C119.05 120.371 119.248 119.919 119.671 119.524C121.534 117.802 127.461 118.198 133.754 118.621C135.165 118.706 136.633 118.819 138.016 118.875C138.213 118.875 138.354 119.044 138.326 119.242C138.326 119.439 138.157 119.581 137.959 119.552C136.576 119.496 135.109 119.383 133.698 119.298C127.827 118.903 121.76 118.508 120.095 120.032C119.841 120.286 119.699 120.568 119.699 120.907C119.699 122.375 121.788 124.04 125.457 125.451C129.097 126.862 133.754 127.85 138.185 128.16C138.383 128.16 138.524 128.329 138.495 128.527C138.524 128.725 138.383 128.866 138.213 128.866Z"
      fill={black}
    />
    <path
      d="M219.748 168.63C214.611 184.971 207.669 198.631 203.972 197.84C200.274 197.022 202.448 183.362 206.201 166.119C209.955 148.875 216.051 135.272 219.72 136.316C225.167 137.868 224.771 152.657 219.748 168.63Z"
      fill={black}
    />
    <path
      d="M204.279 198.208C204.138 198.208 204.025 198.18 203.912 198.18C200.497 197.446 201.062 188.133 205.859 166.035C209.105 151.077 214.242 138.574 218.023 136.317C218.644 135.95 219.237 135.837 219.801 135.978C220.902 136.288 221.805 137.107 222.482 138.349C225.192 143.344 224.204 155.564 220.084 168.716C217.515 176.872 214.524 184.351 211.645 189.798C208.033 196.599 205.69 198.208 204.279 198.208ZM219.293 136.599C219.011 136.599 218.701 136.712 218.362 136.909C214.778 139.054 209.669 151.641 206.509 166.176C204.702 174.501 199.876 196.599 204.025 197.503C204.11 197.531 204.194 197.531 204.279 197.531C207.609 197.531 214.298 184.774 219.406 168.518C223.47 155.536 224.458 143.542 221.862 138.687C221.269 137.587 220.507 136.909 219.604 136.655C219.491 136.599 219.406 136.599 219.293 136.599Z"
      fill={white}
    />
    <path
      d="M206.708 185.536C206.708 185.536 212.155 176.589 214.695 168.094C217.235 159.599 217.884 154.35 217.884 154.35L167.507 127.68C167.507 127.68 151.815 160.333 156.416 165.978C161.016 171.566 177.272 170.014 177.272 170.014L206.708 185.536Z"
      fill={white}
    />
    <path
      d="M206.708 185.874C206.651 185.874 206.595 185.874 206.538 185.846L177.215 170.409C175.691 170.55 160.677 171.735 156.161 166.232C154.158 163.776 155.428 156.523 159.915 144.614C163.217 135.836 167.168 127.624 167.196 127.539C167.225 127.454 167.309 127.398 167.394 127.37C167.479 127.341 167.591 127.341 167.676 127.398L218.053 154.068C218.166 154.124 218.25 154.266 218.222 154.407C218.222 154.463 217.517 159.769 215.005 168.207C212.493 176.646 207.046 185.62 206.99 185.733C206.933 185.818 206.82 185.874 206.708 185.874ZM177.3 169.703C177.356 169.703 177.413 169.703 177.469 169.731L206.595 185.056C207.526 183.476 212.126 175.489 214.384 167.982C216.585 160.616 217.376 155.648 217.545 154.548L167.704 128.16C166.942 129.769 163.527 137.078 160.592 144.839C155.117 159.346 155.456 164.228 156.726 165.78C161.157 171.171 177.131 169.703 177.272 169.703C177.272 169.703 177.272 169.703 177.3 169.703Z"
      fill={black}
    />
    <path
      d="M171.252 139.592L162.165 110.523C161.487 108.321 159.145 107.079 156.944 107.757L133.547 114.925C131.346 115.603 130.076 117.945 130.782 120.146L145.711 168.265C146.389 170.467 148.731 171.708 150.932 171.031L174.329 163.863C176.558 163.185 177.772 160.843 177.094 158.642L174.667 150.739"
      fill={white}
    />
    <path
      d="M149.722 171.567C147.775 171.567 145.969 170.325 145.376 168.378L130.446 120.287C130.08 119.13 130.192 117.916 130.757 116.844C131.321 115.771 132.281 114.981 133.438 114.614L156.834 107.446C157.991 107.107 159.205 107.22 160.277 107.784C161.35 108.349 162.14 109.308 162.479 110.466L171.566 139.535C171.623 139.704 171.51 139.901 171.34 139.958C171.171 140.014 170.974 139.901 170.917 139.732L161.83 110.663C161.519 109.675 160.87 108.885 159.967 108.405C159.064 107.926 158.02 107.813 157.032 108.123L133.636 115.292C132.648 115.602 131.858 116.251 131.378 117.183C130.898 118.086 130.813 119.13 131.096 120.089L146.025 168.18C146.646 170.212 148.791 171.341 150.823 170.72L174.219 163.552C176.251 162.931 177.38 160.786 176.759 158.754L174.332 150.852C174.276 150.682 174.389 150.485 174.558 150.428C174.727 150.372 174.925 150.485 174.981 150.654L177.408 158.556C178.142 160.927 176.787 163.467 174.417 164.201L151.049 171.369C150.597 171.51 150.146 171.567 149.722 171.567Z"
      fill={black}
    />
    <path
      d="M168.515 139.705L159.23 110.099C158.552 107.954 156.238 106.741 154.037 107.418L130.641 114.587C128.411 115.264 127.169 117.55 127.847 119.723L142.466 166.713C143.143 168.858 145.457 170.072 147.659 169.394L171.055 162.198C173.256 161.52 174.526 159.234 173.849 157.061L171.478 149.357"
      fill={white}
    />
    <path
      d="M146.392 169.987C145.686 169.987 144.981 169.817 144.332 169.479C143.259 168.943 142.497 168.011 142.13 166.882L127.511 119.892C126.778 117.55 128.16 115.066 130.531 114.304L153.927 107.136C155.085 106.769 156.298 106.882 157.371 107.418C158.443 107.954 159.205 108.886 159.572 110.014L168.857 139.62L168.208 139.817L158.895 110.212C158.584 109.252 157.935 108.49 157.032 108.011C156.129 107.559 155.085 107.446 154.125 107.757L130.729 114.925C128.697 115.546 127.54 117.663 128.16 119.638L142.808 166.657C143.118 167.616 143.767 168.378 144.67 168.858C145.574 169.309 146.618 169.422 147.577 169.112L170.974 161.915C171.99 161.605 172.808 160.927 173.288 160.024C173.739 159.149 173.852 158.162 173.57 157.23L171.199 149.526L171.849 149.328L174.219 157.033C174.558 158.133 174.445 159.319 173.909 160.363C173.344 161.435 172.385 162.226 171.199 162.593L147.803 169.789C147.323 169.902 146.872 169.987 146.392 169.987Z"
      fill={black}
    />
    <path
      d="M165.503 141.34L157.883 117.182C157.319 115.46 155.484 114.501 153.791 115.065L135.39 120.738C133.669 121.246 132.681 123.052 133.189 124.774L144.986 162.648C145.55 164.369 147.385 165.329 149.078 164.765L167.479 159.092C169.2 158.584 170.188 156.778 169.68 155.056L165.503 141.34Z"
      fill={black}
    />
    <path
      d="M148.063 165.273C147.498 165.273 146.934 165.132 146.426 164.878C145.551 164.426 144.93 163.693 144.62 162.761L132.823 124.887C132.258 122.996 133.359 120.992 135.25 120.4L153.651 114.727C154.554 114.445 155.542 114.501 156.388 114.953C157.263 115.404 157.884 116.138 158.195 117.069L165.815 141.228L169.992 154.972C170.556 156.863 169.455 158.867 167.565 159.459L149.164 165.132C148.797 165.217 148.43 165.273 148.063 165.273ZM154.78 115.263C154.469 115.263 154.187 115.32 153.905 115.404L135.476 121.049C133.924 121.5 133.049 123.137 133.5 124.661L145.297 162.536C145.523 163.269 146.059 163.89 146.736 164.257C147.414 164.624 148.204 164.68 148.938 164.426L167.367 158.754C168.919 158.302 169.794 156.665 169.343 155.141L165.166 141.425L157.546 117.267C157.32 116.533 156.784 115.912 156.106 115.545C155.711 115.376 155.26 115.263 154.78 115.263Z"
      fill={black}
    />
    <path
      d="M146.113 114.417L137.59 116.9C137.392 116.957 137.166 116.844 137.082 116.618C137.025 116.42 137.138 116.195 137.364 116.11L145.887 113.626C146.085 113.57 146.31 113.683 146.395 113.909C146.423 114.134 146.282 114.36 146.113 114.417Z"
      fill={black}
    />
    <path
      d="M148.918 113.485C148.981 113.116 148.733 112.766 148.364 112.704C147.995 112.641 147.645 112.889 147.583 113.258C147.52 113.626 147.768 113.976 148.137 114.039C148.505 114.102 148.855 113.854 148.918 113.485Z"
      fill={black}
    />
    <path
      d="M174.306 163.72L170.58 170.042C170.58 170.042 162.876 169.788 159.828 168.123L174.306 163.72Z"
      fill={black}
    />
    <path
      d="M176.991 139.224C169.992 136.119 157.461 132.507 155.768 136.148C154.047 139.788 168.835 147.803 176.427 151.444"
      fill={white}
    />
    <path
      d="M176.426 151.782C176.37 151.782 176.313 151.782 176.285 151.754C174.394 150.851 167.79 147.606 162.4 144.078C156.868 140.437 154.582 137.784 155.429 136.006C156.276 134.2 159.324 133.805 164.432 134.821C169.032 135.724 173.999 137.53 177.104 138.913C177.273 138.998 177.358 139.195 177.273 139.365C177.188 139.534 176.991 139.619 176.821 139.534C169.286 136.176 157.546 133.099 156.022 136.288C155.655 137.079 156.022 139.082 162.738 143.513C168.073 147.013 174.648 150.23 176.539 151.133C176.709 151.218 176.793 151.416 176.709 151.585C176.68 151.698 176.567 151.782 176.426 151.782Z"
      fill={black}
    />
    <path
      d="M145.147 153.221C143.707 145.657 134.761 145.093 133.434 145.008C128.693 144.726 129.624 150.935 131.29 152.798C133.011 154.66 146.191 158.724 145.147 153.221Z"
      fill={white}
    />
    <path
      d="M141.706 156.355C137.811 156.355 132.139 154.238 131.066 153.053C129.825 151.67 129.119 148.424 130.05 146.449C130.474 145.574 131.405 144.529 133.465 144.671C135.667 144.812 137.67 145.263 139.42 145.997C142.807 147.436 144.867 149.863 145.488 153.137C145.798 154.718 144.98 155.48 144.274 155.847C143.597 156.213 142.694 156.355 141.706 156.355ZM133.155 145.348C131.969 145.348 131.123 145.828 130.699 146.759C129.825 148.593 130.615 151.5 131.603 152.601C132.76 153.871 141.057 156.75 143.992 155.282C144.754 154.915 145.036 154.266 144.839 153.307C143.597 146.731 136.457 145.574 133.465 145.376C133.352 145.348 133.239 145.348 133.155 145.348Z"
      fill={black}
    />
    <path
      d="M142.973 145.404C143.538 138.715 133.519 137.473 132.192 137.304C127.507 136.655 127.451 142.469 129.003 144.444C130.527 146.42 142.634 149.355 142.973 145.404Z"
      fill={white}
    />
    <path
      d="M139.052 147.718C137.161 147.718 135.185 147.295 134.226 147.069C132.165 146.589 129.484 145.658 128.722 144.642C127.678 143.315 127.339 140.437 128.384 138.602C128.92 137.671 130.02 136.627 132.222 136.937H132.25C135.213 137.332 137.274 138.066 138.459 138.602C141.874 140.154 143.539 142.497 143.313 145.404C143.257 146.053 142.89 146.956 141.394 147.379C140.689 147.633 139.898 147.718 139.052 147.718ZM131.488 137.586C130.359 137.586 129.512 138.038 128.976 138.969C128.073 140.549 128.384 143.089 129.258 144.218C130.416 145.686 137.923 147.746 141.225 146.758C142.128 146.476 142.579 146.025 142.636 145.375C142.862 142.751 141.366 140.691 138.205 139.251C137.048 138.715 135.072 138.009 132.194 137.643H132.165C131.911 137.614 131.686 137.586 131.488 137.586Z"
      fill={black}
    />
    <path
      d="M139.986 136.628C142.696 133.1 132.959 128.782 131.519 128.584C126.468 127.907 126.411 134.144 128.076 136.289C129.741 138.377 137.079 140.381 139.986 136.628Z"
      fill={white}
    />
    <path
      d="M134.905 139.083C132.111 139.083 128.95 137.926 127.821 136.487C126.72 135.047 126.325 131.999 127.454 130.024C128.018 129.036 129.204 127.907 131.574 128.246C132.76 128.415 139.336 131.209 140.577 134.285C140.972 135.217 140.86 136.063 140.267 136.854C138.856 138.688 136.598 139.083 134.961 139.111C134.933 139.083 134.905 139.083 134.905 139.083ZM130.756 128.867C129.514 128.867 128.611 129.375 128.047 130.362C127.059 132.084 127.397 134.822 128.357 136.063C129.26 137.192 132.082 138.378 134.905 138.378C134.933 138.378 134.933 138.378 134.961 138.378C136.457 138.378 138.489 138.011 139.731 136.402C140.182 135.838 140.239 135.217 139.956 134.539C138.828 131.83 132.534 129.064 131.49 128.923C131.236 128.895 130.982 128.867 130.756 128.867Z"
      fill={black}
    />
    <path
      d="M125.432 90.4845C133.701 89.4967 144.058 89.1298 143.494 85.6867C142.929 82.2718 132.741 80.4656 123.654 81.3405"
      fill={white}
    />
    <path
      d="M125.43 90.8235C125.26 90.8235 125.119 90.7106 125.091 90.513C125.063 90.3155 125.204 90.1462 125.373 90.1462C126.784 89.9768 128.252 89.8357 129.691 89.6664C135.533 89.0737 141.545 88.4528 142.899 86.6748C143.125 86.3926 143.182 86.0821 143.125 85.7435C142.899 84.3041 140.529 83.0341 136.662 82.2439C132.824 81.4537 128.083 81.2561 123.652 81.7077C123.454 81.7359 123.313 81.5948 123.285 81.3972C123.257 81.1997 123.398 81.0586 123.595 81.0303C128.111 80.5788 132.937 80.7763 136.832 81.5948C139.259 82.1028 143.436 83.2881 143.831 85.6588C143.915 86.195 143.802 86.6748 143.464 87.0981C141.94 89.1019 136.041 89.7228 129.776 90.3437C128.337 90.4848 126.869 90.6542 125.486 90.8235C125.458 90.8235 125.458 90.8235 125.43 90.8235Z"
      fill={black}
    />
    <path
      d="M91.7577 87.2669L105.587 60.1453C106.631 58.0851 109.143 57.2667 111.203 58.2827L133.047 69.2893C135.107 70.3336 135.926 72.8454 134.91 74.9056L112.106 119.835C111.062 121.896 108.55 122.714 106.49 121.698L84.6456 110.691C82.5854 109.647 81.767 107.135 82.783 105.075L86.5083 97.681"
      fill={white}
    />
    <path
      d="M108.379 122.488C107.673 122.488 106.996 122.319 106.347 122.008L84.5027 111.001C82.2731 109.873 81.37 107.135 82.4989 104.905L86.2243 97.5112C86.3089 97.3419 86.5065 97.2854 86.6758 97.3701C86.8451 97.4548 86.9016 97.6523 86.8169 97.8217L83.0916 105.216C82.1602 107.107 82.9222 109.421 84.7849 110.381L106.629 121.387C107.532 121.839 108.576 121.923 109.564 121.613C110.552 121.303 111.342 120.597 111.794 119.694L134.625 74.764C135.077 73.8609 135.162 72.8167 134.851 71.8289C134.541 70.8411 133.835 70.0509 132.932 69.5994L111.088 58.5927C109.197 57.6613 106.883 58.4233 105.923 60.286L92.0945 87.4076C92.0098 87.577 91.8123 87.6334 91.6429 87.5487C91.4736 87.4641 91.4172 87.2665 91.5018 87.0972L105.331 59.9756C106.46 57.746 109.197 56.8429 111.427 57.9718L133.271 68.9785C134.343 69.5147 135.162 70.446 135.529 71.6031C135.895 72.7603 135.811 73.9738 135.275 75.0745L112.443 120.004C111.907 121.077 110.975 121.895 109.818 122.262C109.338 122.403 108.859 122.488 108.379 122.488Z"
      fill={black}
    />
    <path
      d="M94.4054 87.8298L108.517 60.2002C109.533 58.1964 112.016 57.4062 114.105 58.4504L135.949 69.4571C138.009 70.5014 138.884 72.9567 137.868 74.9887L115.572 118.874C114.556 120.878 112.073 121.668 109.984 120.624L88.14 109.617C86.0798 108.573 85.2049 106.118 86.2209 104.086L89.8616 96.861"
      fill={white}
    />
    <path
      d="M111.934 121.443C111.229 121.443 110.523 121.273 109.846 120.935L88.002 109.928C86.9013 109.392 86.0829 108.432 85.716 107.275C85.3491 106.146 85.4338 104.961 85.9418 103.945L89.5824 96.72L90.2033 97.0304L86.5627 104.255C86.1111 105.13 86.0546 106.118 86.3651 107.078C86.7038 108.065 87.3811 108.856 88.3124 109.335L110.156 120.342C111.088 120.822 112.132 120.906 113.12 120.568C114.051 120.257 114.841 119.608 115.265 118.733L137.56 74.8477C138.012 73.9446 138.068 72.9286 137.73 71.969C137.391 71.0095 136.714 70.2192 135.782 69.7677L113.938 58.761C113.007 58.2812 111.963 58.1965 110.975 58.5352C110.044 58.8457 109.253 59.4948 108.83 60.3697L94.7189 87.9993L94.098 87.6888L108.209 60.0592C108.745 59.015 109.648 58.253 110.749 57.8861C111.906 57.5192 113.148 57.6039 114.249 58.1683L136.093 69.175C137.165 69.7112 137.984 70.6426 138.379 71.7715C138.774 72.9004 138.689 74.1139 138.153 75.1864L115.857 119.072C115.321 120.116 114.418 120.878 113.317 121.245C112.894 121.386 112.414 121.443 111.934 121.443Z"
      fill={black}
    />
    <path
      d="M97.1167 89.9472L108.66 67.3976C109.478 65.789 111.454 65.1681 113.062 66.0147L130.278 74.679C131.887 75.4692 132.564 77.4165 131.774 79.0252L113.796 114.416C112.978 116.025 111.002 116.646 109.393 115.799L92.1778 107.135C90.5691 106.344 89.8918 104.397 90.682 102.788L97.1167 89.9472Z"
      fill={black}
    />
    <path
      d="M110.889 116.533C110.325 116.533 109.76 116.392 109.252 116.138L92.0369 107.474C90.2589 106.599 89.5251 104.454 90.3718 102.648L96.8064 89.8064L108.349 67.2568C108.801 66.4101 109.535 65.761 110.466 65.4788C111.397 65.1965 112.357 65.2812 113.204 65.7045L130.419 74.3688C131.294 74.7921 131.943 75.5259 132.254 76.429C132.564 77.3321 132.508 78.3199 132.084 79.1666L114.107 114.557C113.655 115.404 112.921 116.053 111.99 116.335C111.623 116.476 111.256 116.533 110.889 116.533ZM97.4273 90.1168L90.9927 102.958C90.2871 104.397 90.8798 106.147 92.3473 106.853L109.591 115.517C110.268 115.884 111.059 115.94 111.821 115.714C112.554 115.489 113.175 114.981 113.514 114.275L131.492 78.8843C131.83 78.1788 131.887 77.3886 131.633 76.6548C131.379 75.921 130.843 75.3283 130.165 74.9897L112.95 66.3254C112.272 65.9585 111.482 65.9021 110.72 66.1279C109.986 66.3537 109.365 66.8617 109.027 67.5672L97.4273 90.1168Z"
      fill={black}
    />
    <path
      d="M117.437 64.4908L125.424 68.3855C125.622 68.4983 125.706 68.7241 125.622 68.9217C125.509 69.1192 125.283 69.2039 125.085 69.1192L117.098 65.2246C116.901 65.1117 116.816 64.8859 116.901 64.6883C117.014 64.4626 117.268 64.3779 117.437 64.4908Z"
      fill={black}
    />
    <path
      d="M127.401 70.4741C127.775 70.4741 128.078 70.1709 128.078 69.7968C128.078 69.4227 127.775 69.1195 127.401 69.1195C127.027 69.1195 126.724 69.4227 126.724 69.7968C126.724 70.1709 127.027 70.4741 127.401 70.4741Z"
      fill={black}
    />
    <path
      d="M84.5337 99.9089C94.3832 98.3849 111.768 95.478 111.119 90.2569C110.442 85.064 93.2826 85.2051 82.417 86.5598"
      fill={white}
    />
    <path
      d="M84.5324 100.248C84.3631 100.248 84.222 100.135 84.1938 99.9656C84.1655 99.768 84.2784 99.5987 84.476 99.5705C90.0358 98.7238 96.414 97.6514 101.663 96.1274C106.01 94.8856 111.09 92.8536 110.751 90.2854C110.158 85.6005 93.8176 85.4593 82.444 86.8705C82.2464 86.8987 82.0771 86.7576 82.0771 86.5882C82.0489 86.3907 82.19 86.2213 82.3593 86.2213C88.6247 85.4311 95.4262 85.2336 100.591 85.6287C107.449 86.1649 111.118 87.7171 111.428 90.2289C111.739 92.7125 108.606 94.8856 101.833 96.8047C96.5551 98.3005 90.1487 99.4011 84.5607 100.248C84.5607 100.248 84.5324 100.248 84.5324 100.248Z"
      fill={black}
    />
    <path
      d="M112.922 109.252C112.781 112.921 123.336 113.654 124.55 113.231C128.896 111.848 128.106 105.752 126.045 104.764C124.013 103.805 113.12 103.805 112.922 109.252Z"
      fill={white}
    />
    <path
      d="M123.364 113.683C120.936 113.683 115.998 113.09 113.796 111.425C112.95 110.804 112.554 110.07 112.583 109.252C112.639 107.756 113.429 106.514 114.953 105.611C118.453 103.523 124.634 103.748 126.186 104.482C127.371 105.047 128.161 106.994 128.02 109.026C127.851 111.284 126.637 112.949 124.634 113.57C124.436 113.626 123.984 113.683 123.364 113.683ZM113.26 109.252C113.232 109.844 113.542 110.381 114.191 110.86C116.76 112.78 123.476 113.203 124.408 112.892C126.835 112.13 127.23 109.873 127.315 108.941C127.428 107.248 126.779 105.498 125.875 105.047C124.464 104.369 118.538 104.2 115.292 106.147C113.994 106.966 113.316 108.01 113.26 109.252Z"
      fill={black}
    />
    <path
      d="M115.86 101.15C114.9 105.158 126.499 105.92 127.798 105.553C132.37 104.311 130.535 98.4411 128.362 97.2275C126.161 96.014 117.13 95.7882 115.86 101.15Z"
      fill={white}
    />
    <path
      d="M126.696 105.977C124.099 105.977 118.37 105.356 116.254 103.352C115.548 102.675 115.294 101.913 115.492 101.094C115.915 99.3729 117.128 98.0182 119.048 97.1715C122.35 95.7322 126.922 96.0709 128.474 96.9458C129.857 97.7078 131.183 100.191 130.901 102.506C130.703 104.227 129.603 105.412 127.853 105.892C127.684 105.949 127.26 105.977 126.696 105.977ZM116.197 101.236C116.056 101.828 116.254 102.364 116.762 102.844C119.048 105.046 126.78 105.497 127.712 105.215C129.603 104.707 130.139 103.409 130.252 102.393C130.506 100.417 129.349 98.1593 128.163 97.5102C126.893 96.8046 122.547 96.3531 119.33 97.7642C117.608 98.5544 116.536 99.7115 116.197 101.236Z"
      fill={black}
    />
    <path
      d="M120.851 94.2359C119.779 98.2152 129.657 98.4975 130.955 98.187C135.555 97.0017 133.41 91.6112 131.209 90.3694C129.008 89.1277 122.009 89.9461 120.851 94.2359Z"
      fill={white}
    />
    <path
      d="M129.515 98.6108C127.116 98.6108 122.77 98.131 121.133 96.4659C120.484 95.8168 120.287 94.9983 120.512 94.1517C121.077 92.0632 122.883 90.9343 124.294 90.3417C126.693 89.3539 129.882 89.2128 131.378 90.0594C132.846 90.8779 134.257 93.3897 133.974 95.4781C133.833 96.5506 133.212 97.9335 131.039 98.4979C130.785 98.5543 130.221 98.6108 129.515 98.6108ZM121.161 94.321C120.992 94.9419 121.133 95.5063 121.613 95.9861C122.432 96.8046 124.181 97.4255 126.58 97.7359C128.64 97.9899 130.447 97.9617 130.87 97.8488C132.309 97.4819 133.128 96.6634 133.297 95.3934C133.523 93.5872 132.281 91.3577 131.039 90.6521C129.854 89.9748 126.975 89.9466 124.548 90.9626C123.278 91.4706 121.669 92.4866 121.161 94.321Z"
      fill={black}
    />
  </React.Fragment>
));
IllustrationReferral.displayName = "IllustrationReferral";
