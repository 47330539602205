import styled from "@emotion/styled";
import { Colors, Flex, Spacing } from "@gemini-ui/design-system";
import colorVariables from "@gemini-ui/styles/colorVariables";

export const BREAKPOINTS = {
  phoneOnly: "(max-width: 774px)",
  tabletUp: "(min-width: 775px)",
  desktop: "(min-width: 900px)",
  tabletDown: "(max-width: 1100px)",
} as const;

export const BASE_ZINDEX = 9990;

const HEADER_HEIGHT = 52;

export const Header = styled.h1`
  font-size: 36px;
  letter-spacing: 0;
  color: ${colorVariables.blue["11"]};
  font-weight: 600;
  margin-bottom: ${Spacing.scale[1]};
`;

export const Subheader = styled.h2`
  color: ${colorVariables.blue["11"]};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
`;

export const HeaderLeftNav = styled(Flex)<{ isHeaderV2Enabled: boolean }>`
  display: inline-flex;
  @media ${BREAKPOINTS.phoneOnly} {
    ${p =>
      !p.isHeaderV2Enabled &&
      `
      flex-direction: column;
      align-self: center;
    `}
  }
`;
export const HeaderRightNav = styled(Flex)<{ isHeaderV2Enabled: boolean }>`
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: ${BASE_ZINDEX};
  @media ${BREAKPOINTS.phoneOnly} {
    ${p =>
      p.isHeaderV2Enabled &&
      `
      padding-right: ${Spacing.scale[2]};
      gap: ${Spacing.scale[0.5]};
    `}
  }
`;

export const HeaderContainer = styled("nav")`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  background-color: ${Colors.black};
  border-bottom: 1px solid ${Colors.black};
  height: ${HEADER_HEIGHT}px;
  min-height: ${HEADER_HEIGHT}px;

  @media ${BREAKPOINTS.tabletUp} {
    padding: 0 ${Spacing.scale[4]};
  }
`;
