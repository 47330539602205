import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { AxiosResponse } from "axios";
import { format, parseISO, sub } from "date-fns";
import { optimizelyClient } from "@gemini-ui/analytics";
import { ActiveSettingsTab } from "@gemini-ui/components/layouts/SettingsLayout";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { User } from "@gemini-ui/constants/templateProps/users";
import { usePageData } from "@gemini-ui/contexts";
import axios from "@gemini-ui/services/axios";

export function computeHasCustodySelected(user: User) {
  const activeAccount = user?.subaccounts?.find(sub => sub?.hashid === user?.subaccountHashid);

  return Boolean(activeAccount.coldStorage);
}

interface CustodyBillingConfig {
  enabled: boolean;
  invoiceEmailAddress: string;
  paymentMethod: string;
  billingCurrency: string;
}

export function useIsQualifiedForCustodyBilling(active?: ActiveSettingsTab) {
  const custodyBillingEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BILLING_SETTINGS_PAGE);
  const [isQualified, setIsQualified] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const {
    templateProps: {
      user: { subaccountHashid, subaccounts },
    },
  } = usePageData();

  const selectedAccount = subaccounts.find(acct => acct.hashid === subaccountHashid);

  useEffect(() => {
    // Temporary blocking this on bank settings page until backend fix is created.
    // Side effect is custody accounts will not see "Billing" link while on bank settings page
    if (
      Boolean(selectedAccount?.coldStorage) &&
      subaccountHashid &&
      custodyBillingEnabled &&
      Boolean(active !== "bank") &&
      Boolean(active !== "card")
    ) {
      async function fetchHasCustodyBilling() {
        try {
          const url = jsRoutes.controllers.custody.CustodyDashboardController.getAccountConfig(subaccountHashid).url;
          const { data }: AxiosResponse<CustodyBillingConfig> = await axios.get(url, { withCredentials: true });

          setIsQualified(data.enabled);
        } catch (e) {
          Sentry.captureException(e);
        } finally {
          setHasFetched(true);
        }
      }

      fetchHasCustodyBilling();
    }
  }, [active, custodyBillingEnabled, selectedAccount?.coldStorage, subaccountHashid]);

  return { isQualified, hasFetched };
}

export function getAccountName(user: User) {
  const activeAccount = user?.subaccounts?.find(sub => sub?.hashid === user?.subaccountHashid);
  return activeAccount?.name;
}

export function formatAutopayDateMessage(date: string) {
  if (!date) {
    return null;
  }

  return format(parseISO(date), "MMM d, yyy");
}

export function formatInvoiceDateMessage(date: string) {
  if (!date) {
    return null;
  }

  return format(sub(parseISO(date), { months: 1 }), "MMM yyy");
}
