import styled from "@emotion/styled";
import { Card, Colors, Select, Spacer } from "@gemini-ui/design-system";

export const AccountSelectionDropdown = styled(Select)`
  max-width: 400px;
`;

export const PaymentMethodDetailsContainer = styled.div`
  border-bottom: 1px solid ${Colors.gray[50]};
`;

export const AvailableForCellContainer = styled.div`
  white-space: break-spaces;
`;

export const FlexContainer = styled(Spacer)<{ justifyContent?: string; alignItems?: string; flexWrap?: string }>`
  display: flex;
  ${({ alignItems }) => `align-items: ${alignItems ? alignItems : "center"};`}

  ${({ justifyContent }) => Boolean(justifyContent) && `justify-content: ${justifyContent};`}
  
  ${({ flexWrap }) => Boolean(flexWrap) && `flex-wrap: ${flexWrap};`}
`;

export const OverflowDiv = styled.div`
  overflow: hidden;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 352px;
`;
