import { ElementType, ReactNode } from "react";
import * as CSS from "csstype";
import { FlexProps } from "@gemini-ui/design-system/Flex";
import { ForwardRefComponent } from "@gemini-ui/design-system/polymorphic";
import { ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";

const ELEMENT = "span";

export const ListItemDefaultElement = ELEMENT;

export interface ListItemProps extends ShorthandSpacingCssProps {
  alignItems?: CSS.Property.AlignItems;
  bottom?: ReactNode;
  centerProps?: FlexProps;
  children?: ReactNode;
  hasDivider?: boolean;
  left?: ReactNode;
  leftProps?: FlexProps;
  rightProps?: FlexProps;
  padding?: "md" | "sm" | "none";
  parentAs?: ElementType;
  right?: ReactNode;
  size?: "default" | "dense";
  parentAriaSetsize?: number;
  parentAriaPosinset?: number;
}

export type ListItemPolymorphic = ForwardRefComponent<typeof ListItemDefaultElement, ListItemProps>;
