import { ETH_PER_VALIDATOR } from "@gemini-ui/pages/Earn/utils";

/**
 * Unmask the given value by removing all non-numeric characters from the input value.
 * This is necessary to remove the currency mask added to the display.
 *
 * @param value string - The value to unmask.
 * @returns string
 */
export function sanitizeMaskedInputValue(value: string): string {
  return value?.replace(/[^\d.]+/g, "");
}

export const sanitizedMaskedInputNumberValue = (value: string): number =>
  Number(sanitizeMaskedInputValue(value) || "0");

/**
 * Returns the approximate ETH value of the given validator count.
 *
 * @param validatorCount string - The number of validators.
 * @returns number
 */
export function approxValidatorETHValue(validatorCount: string): number {
  return sanitizedMaskedInputNumberValue(validatorCount) * ETH_PER_VALIDATOR;
}
