import React from "react";
import { LazyRetry } from "@gemini-ui/components/LazyLoading/LazyRetry";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { HeaderProps, ProHeaderProps } from "@gemini-ui/pages/Earn/GrowBuy/components/DepositReviewHeader/constants";

const LazyDepositReviewHeader = LazyRetry<HeaderProps>({
  importFn: () => import("./StakingHeader"),
  LoaderView: () => null,
  ErrorRetryView: () => null,
});

const LazyProDepositReviewHeader = LazyRetry<ProHeaderProps>({
  importFn: () => import("./StakingProHeader"),
  LoaderView: () => null,
  ErrorRetryView: () => null,
});

export const DepositReviewHeader = ({
  providerType,
  amount,
  notionalAmount,
  validatorCount,
}: { providerType: GrowProviderType } & Partial<HeaderProps> & Partial<ProHeaderProps>) => {
  const headerForProviderType = {
    [GrowProviderType.POOLED_STAKING]: <LazyDepositReviewHeader amount={amount} notionalAmount={notionalAmount} />,
    [GrowProviderType.PRIVATE_STAKING]: <LazyProDepositReviewHeader amount={amount} validatorCount={validatorCount} />,
  };

  return headerForProviderType[providerType];
};
