import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { PasswordInputProps } from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input/Input";
import { InputRightElement } from "@gemini-ui/design-system/__deprecated__/Input/InputElements";
import { EyeLink } from "@gemini-ui/design-system/__deprecated__/Input/styles";
import { ReactComponent as SecretEye } from "@gemini-ui/images/icons/eye.svg";
import { ReactComponent as SecretEyeCross } from "@gemini-ui/images/icons/eye-cross.svg";
import { useIntl } from "@gemini-ui/utils/intl";

const INPUT_TYPES = {
  password: "password",
  text: "text",
} as const;

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ initialType = "password", onToggleType, ...props }, forwardRef) => {
    const { intl } = useIntl();
    const [type, setType] = useState(initialType);
    const inputRef = useRef(null);
    useImperativeHandle(forwardRef, () => inputRef.current);

    useEffect(() => {
      onToggleType && onToggleType(type);
    }, [onToggleType, type]);

    const handleTypeSwitch = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setType(type === "password" ? INPUT_TYPES.text : INPUT_TYPES.password);
      inputRef.current.focus();
      // Moving cursor to the end
      // Pause to account for text dashes
      setTimeout(() => {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }, 10);
    };

    return (
      <Input
        type={type}
        pr={6}
        rightElement={
          <InputRightElement mr={2}>
            <EyeLink
              data-testid={`${props["data-testid"]}-toggle-visibility`}
              href="#"
              aria-label={intl.formatMessage({ defaultMessage: "Toggle password visibility" })}
              onClick={handleTypeSwitch}
              onMouseDown={e => e.preventDefault()}
            >
              {type === "password" ? <SecretEye /> : <SecretEyeCross />}
            </EyeLink>
          </InputRightElement>
        }
        ref={inputRef}
        {...props}
      />
    );
  }
);
