import * as React from "react";
import { Text } from "@gemini-ui/design-system";
import { SpacerProps } from "@gemini-ui/design-system/primitives/Spacer";
import { ReactComponent as ChevronLeft } from "@gemini-ui/images/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@gemini-ui/images/icons/chevron-right.svg";
import { PaginationButton, PaginationContainer } from "@gemini-ui/pages/settings/Pagination/styles";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type Props = {
  currentPage: number;
  totalPageCount: number;
  onLeftClick: () => void;
  onRightClick: () => void;
} & SpacerProps &
  React.HTMLAttributes<HTMLDivElement>;

const Pagination = ({ currentPage, totalPageCount, onLeftClick, onRightClick, ...props }: Props) => {
  const { intl } = useIntl();

  if (totalPageCount <= 1) {
    return null;
  }

  return (
    <PaginationContainer {...props}>
      <PaginationButton hidden={currentPage === 1} onClick={onLeftClick} data-testid="left-pagination-button">
        <ChevronLeft />
      </PaginationButton>

      <Text.Body size="xs" ml={1} mr={1}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "{currentPage} of {totalPageCount}",
          }),
          { currentPage, totalPageCount }
        )}
      </Text.Body>
      <PaginationButton
        hidden={currentPage === totalPageCount}
        onClick={onRightClick}
        data-testid="right-pagination-button"
      >
        <ChevronRight />
      </PaginationButton>
    </PaginationContainer>
  );
};

type PaginationOptions = {
  itemsPerPage?: number;
  initialPage?: number;
  items: any[];
  scrollToTopOfTable?: boolean;
  selfReset?: boolean;
};

export const usePagination = ({
  itemsPerPage = 10,
  items,
  initialPage = 1,
  scrollToTopOfTable = true,
  selfReset = true,
}: PaginationOptions) => {
  const [page, setPage] = React.useState(initialPage);
  const topOfTableRef = React.useRef<any>();
  const paginatedData = React.useMemo(() => {
    return items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  }, [page, items, itemsPerPage]);
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (scrollToTopOfTable && topOfTableRef && topOfTableRef.current && !isInitialMount.current) {
      topOfTableRef.current.scrollIntoView(true);
    }
    isInitialMount.current = false;
  }, [page, scrollToTopOfTable]);

  React.useEffect(() => {
    if (selfReset) {
      resetPagination();
    }
  }, [items.length, selfReset]);

  const totalPageCount = Math.ceil(items.length / itemsPerPage);
  const incrementPage = () => {
    setPage(p => p + 1);
  };
  const decrementPage = () => {
    setPage(p => p - 1);
  };
  const resetPagination = () => {
    setPage(1);
  };

  return {
    paginatedData,
    incrementPage,
    decrementPage,
    totalPageCount,
    currentPage: page,
    topOfTableRef,
    resetPagination,
  };
};

export default Pagination;
