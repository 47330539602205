import { BankAccountType } from "@gemini-ui/transformers/PaymentMethods/constants";

const lastFourRegex = new RegExp(/- ([0-9]{4})/);
const bankAccountTypes = Object.values(BankAccountType);

const getAccountTypeRegex = () => {
  const accountTypeRegexString = bankAccountTypes.reduce((acc, curr, i) => {
    if (i === bankAccountTypes.length - 1) {
      return acc + curr;
    }

    return acc + `${curr}|`;
  }, "");
  return new RegExp(`(${accountTypeRegexString})`, "i");
};
export const extractPaymentMethodMetaDataFromName = (name = "") => {
  let institutionName: string;
  let accountType: BankAccountType;
  let lastFour: string;
  let additionalText: string;
  const accountTypeMatchGroup = name.match(getAccountTypeRegex());
  const lastFourMatchGroup = name.match(lastFourRegex);
  if (accountTypeMatchGroup) {
    accountType = accountTypeMatchGroup[1] as BankAccountType;
  }

  if (lastFourMatchGroup) {
    lastFour = lastFourMatchGroup[1];
    additionalText = name.split(lastFour)[1];
  }

  if (accountType) {
    institutionName = name.split(accountType)[0].trim();
  } else if (lastFour) {
    institutionName = name.split(lastFourRegex)[0].trim();
  }

  return {
    institutionName: institutionName ?? name,
    lastFour,
    accountType,
    rawName: name,
    additionalText,
  };
};
