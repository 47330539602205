import { useForm, UseFormReturn } from "react-hook-form";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { GrowProviderType } from "@gemini-ui/constants/earn";

export interface WithdrawalFormValues {
  /**
   * The user-preferred currency entry.
   * This could be a token or a fiat currency.
   */
  currency: CurrencyShortNameSupportedCryptos;

  /**
   * The deposit amount.
   * This value is either a crypto amount or a fiat amount, depending on the selected payment method and/or provider type.
   */
  amount?: string;

  /**
   * The number of validators to stake if staking into Staking Pro (PrivateStaking provider type).
   */
  validatorCount?: string;

  /**
   * The desired provider type for the deposit.
   */
  providerType: GrowProviderType;

  /**
   * The funding source for the withdrawal.
   */
  unstakeSourceId?: string;
}

export const defaultFormValues: WithdrawalFormValues = {
  currency: null,
  amount: undefined,
  providerType: GrowProviderType.POOLED_STAKING,
  unstakeSourceId: undefined,
  validatorCount: undefined,
};

export const useStakingWithdrawalForm = (
  initialFormValues?: WithdrawalFormValues
): UseFormReturn<WithdrawalFormValues> => {
  const form = useForm<WithdrawalFormValues>({
    defaultValues: defaultFormValues,
    values: initialFormValues,
  });

  return form;
};
