import React, { useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { FileUploaderFile, FileUploaderProps, StoredFile } from "@gemini-ui/design-system/FileUploader/constants";
import { Dropzone } from "@gemini-ui/design-system/FileUploader/Dropzone";
import { File } from "@gemini-ui/design-system/FileUploader/File";
import { getFileUploaderCopy } from "@gemini-ui/design-system/FileUploader/i18n";
import { EmptyZone, FileUploaderWrapper } from "@gemini-ui/design-system/FileUploader/styles";
import { composeKey, getReadableFileSize, isStoredFile } from "@gemini-ui/design-system/FileUploader/utils";
import { Label } from "@gemini-ui/design-system/forms/Label";
import { Message } from "@gemini-ui/design-system/forms/Message";
import { Text } from "@gemini-ui/design-system/Text";
import { ScreenReaderOnly } from "@gemini-ui/design-system/utils/ScreenReaderOnly";
import { useIntl } from "@gemini-ui/utils/intl";
export const FileUploader = ({
  accept,
  className,
  ["data-testid"]: dataTestId,
  disabled,
  error,
  id,
  isMulti = false,
  label,
  labelDescription,
  maxFiles,
  maxSize,
  maxPolicySize,
  message,
  onFileChange,
  onRemove,
  readOnly,
  uploadFile,
  value,
  ...spacerOptions
}: FileUploaderProps) => {
  const [files, setFiles] = useState<FileUploaderFile<StoredFile | File>[]>(() =>
    !Array.isArray(value)
      ? Boolean(value)
        ? [{ file: value, id: composeKey(value) }]
        : []
      : value.map(val => ({ file: val, id: composeKey(val) }))
  );
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [innerErrorMessage, setInnerErrorMessage] = useState<string>("");
  const [reset, setReset] = useState<boolean>(true);
  const INPUT_ID_PREFIX = "input-";
  const inputId = useRef(Boolean(id) ? id : uniqueId(INPUT_ID_PREFIX));
  const { intl } = useIntl();
  // necessary to reset input values in order to allow triggering onChange even if the file is the same
  useEffect(() => {
    if (!reset) setReset(true);
  }, [reset]);
  // only triggers the onFileChange with the finished StoredFiles
  useEffect(() => {
    if (onFileChange) {
      onFileChange(
        isMulti
          ? (files.filter(item => isStoredFile(item.file)).map(({ file }) => ({ ...file })) as StoredFile[])
          : files.length && isStoredFile(files[0].file)
          ? ({ ...files[0].file } as StoredFile)
          : null
      );
    }
    // onFileChange could changes everytime in the parent, We don't want to trigger the effect when onFileChange changes even is a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, isMulti]);
  const fileInputRef = useRef(null);
  const { EXISTS, MAX_FILES, NO_FILES, MAX_SIZE, MAX_POLICY_SIZE } = getFileUploaderCopy(intl);
  const LOADING = {
    INCREMENT: (num = 1) => setLoadingCount(previous => previous + num),
    DECREMENT: (num = 1) => setLoadingCount(previous => previous - num),
    IS_LOADING: () => loadingCount !== 0,
  };
  const cleanErrorMessages = () => setInnerErrorMessage("");
  const internalDisabled = () => disabled || files.length >= maxFiles;
  const shouldShowDropzone = () => (isMulti && !disabled && !readOnly) || (!files.length && !readOnly);
  const shouldShowEmptyState = () => readOnly && !files.length;
  const shouldShowErrorMessage = () => Boolean(innerErrorMessage) || (Boolean(error) && !LOADING.IS_LOADING());
  const runUploadGuards = (items: File[]): FileUploaderFile<File>[] => {
    // cleanErrorMessages();
    const existsWithoutError = (item: File): boolean =>
      files.some(file => {
        return composeKey(file.file) === composeKey(item) && !file.error;
      });
    const exceedsMaxSize = (item: File): boolean => item.size > maxSize;
    const exceedsMaxPolicySize = (item: File): boolean => item.size > maxPolicySize;
    const exceedsMaxFiles = (index: number): boolean => maxFiles && index + files.length >= maxFiles;
    const guardedFiles: FileUploaderFile<File>[] = items.map((item, index) => {
      let error;
      if (exceedsMaxSize(item)) error = MAX_SIZE(getReadableFileSize(maxSize));
      if (exceedsMaxPolicySize(item)) error = MAX_POLICY_SIZE;
      if (exceedsMaxFiles(index)) error = MAX_FILES(maxFiles);
      if (existsWithoutError(item)) error = EXISTS(item.name);
      return {
        file: item,
        error,
        id: Boolean(error) ? uniqueId() : composeKey(item),
      };
    });
    const filesToUpload = guardedFiles.filter(({ error }) => !Boolean(error));
    LOADING.INCREMENT(filesToUpload.length);
    return guardedFiles;
  };

  // handlers
  const handleClick = () => fileInputRef.current.click();

  const handleRemove = (id?: string) => {
    cleanErrorMessages();
    const removedFile = files.find(item => id !== item.id);
    setFiles(previous => {
      return isMulti ? (previous as FileUploaderFile<StoredFile>[]).filter(item => id !== item.id) : [];
    });
    if (onRemove) onRemove(removedFile.file as StoredFile);
  };
  const mergeFiles = (fileList: FileList) => {
    const values: File[] = [...fileList];
    const guardedFiles = runUploadGuards(values);
    setFiles(
      previous => (isMulti ? [...previous, ...guardedFiles] : guardedFiles) as FileUploaderFile<StoredFile | File>[]
    );
    setReset(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.persist();
    const droppedFiles: FileList = event.dataTransfer.files;
    mergeFiles(droppedFiles);
  };

  const handleChange = event => {
    event.persist();
    const changedFiles: FileList = event.target.files;
    mergeFiles(changedFiles);
  };

  const handleOnUpload = (storedFile: StoredFile, id?: string) => {
    LOADING.DECREMENT();
    if (storedFile) {
      const fileUploaderfile: FileUploaderFile<StoredFile> = {
        file: storedFile,
        id: composeKey(storedFile),
      };
      setFiles(previous =>
        isMulti
          ? [...(previous as FileUploaderFile<StoredFile>[]).filter(item => id !== item.id), fileUploaderfile]
          : [fileUploaderfile]
      );
    }
  };

  return (
    <FileUploaderWrapper id={id} className={className} {...spacerOptions}>
      <Label disabled={disabled} htmlFor={inputId.current as string} mb={1}>
        {label}
      </Label>
      {message && <Message size="xs" disabled={disabled} message={message} />}
      {shouldShowDropzone() && (
        <Dropzone
          data-testid={dataTestId}
          disabled={disabled || files.length >= maxFiles}
          error={!internalDisabled() && (Boolean(error) || Boolean(innerErrorMessage)) && !LOADING.IS_LOADING()}
          onClick={!(disabled || readOnly) && handleClick}
          onDrop={!(disabled || readOnly) && handleDrop}
        />
      )}
      {shouldShowEmptyState() && (
        <EmptyZone>
          <Text.Body>{NO_FILES}</Text.Body>
        </EmptyZone>
      )}
      {shouldShowErrorMessage() && (
        <Message disabled={disabled} error={innerErrorMessage || error} data-testid={`${dataTestId}-error-text`} />
      )}
      {isMulti
        ? Boolean(files.length) &&
          files
            .sort((a, b) => {
              if (a.error) return 1;
              if (b.error) return -1;
              return a.file.name.localeCompare(b.file.name);
            })
            .map(item => {
              return (
                <File
                  data-testid={`${dataTestId}-file`}
                  error={item.error}
                  file={item.file}
                  key={item.id}
                  maxSize={maxSize}
                  mt={2}
                  onRemove={() => handleRemove(item.id)}
                  onUpload={storedFile => handleOnUpload(storedFile, item.id)}
                  uploadFile={uploadFile}
                  readOnly={readOnly || disabled}
                />
              );
            })
        : Boolean(files.length) && (
            <File
              data-testid={`${dataTestId}-file`}
              error={files[0].error}
              file={files[0].file}
              maxSize={maxSize}
              onRemove={handleRemove}
              uploadFile={uploadFile}
              onUpload={handleOnUpload}
              readOnly={readOnly || disabled}
            />
          )}
      <label aria-label={labelDescription}>
        {reset && (
          <ScreenReaderOnly>
            <input
              id={inputId.current}
              disabled={disabled}
              multiple={isMulti}
              onChange={ev => {
                handleChange(ev);
              }}
              accept={accept}
              ref={fileInputRef}
              readOnly={readOnly}
              type="file"
              style={{ display: "none" }}
            />
          </ScreenReaderOnly>
        )}
      </label>
    </FileUploaderWrapper>
  );
};
