import React from "react";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import Num from "@gemini-common/scripts/Num";
import { AnnualInterestYieldTier } from "@gemini-ui/constants/earn";
import { FormattedRateTier } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/RateDetailsModal/RateDetailsList";
import SummaryLineItem from "@gemini-ui/pages/Earn/Deposit/ReviewBuyDeposit/SummaryLineItem";
import { QUOTE_LOADING_PLACEHOLDER_TEXT } from "@gemini-ui/pages/Earn/Deposit/utils";
import { useRateDetailsFormatter } from "@gemini-ui/pages/Earn/hooks";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { FormattedInterestTier } from "@gemini-ui/pages/Earn/utils";
import { useIntl } from "@gemini-ui/utils/intl";

const RateLineItem = (props: {
  currency: CurrencyShortName;
  index: number;
  interestTier: FormattedInterestTier;
  maxThreshold: string | number;
  hasDivider: boolean;
}) => {
  const { intl } = useIntl();
  const { interestTier, index, hasDivider } = props;

  const label = index === 0 ? intl.formatMessage({ defaultMessage: "Annual % yield" }) : undefined;

  return (
    <SummaryLineItem
      hasDivider={hasDivider}
      label={label}
      labelTestId={Boolean(label) ? testIds.deposit.reviewDeposit.interestRateLabel : undefined}
      text={`${Num.formatInterestRate(interestTier.yield)}%`}
      textTestId={`${testIds.deposit.reviewDeposit.interestRate}-${index}`}
      subText={<FormattedRateTier {...props} />}
      subTextTestId={`${testIds.deposit.reviewDeposit.interestRateRange}-${index}`}
    />
  );
};

interface Props {
  isAwaitingQuote: boolean;
  currency: CurrencyShortName;
  interestTiers: AnnualInterestYieldTier[];
  /** Fallback value in case we don't have interestTiers available in prod yet */
  quoteInterestRate?: string | number;
}

export const RateDetailsSummary = ({ isAwaitingQuote, currency, interestTiers, quoteInterestRate }: Props) => {
  const { intl } = useIntl();

  const { onlyHasOneRateTier, formattedInterestTiers, maxThreshold, showTieredRateDetails } =
    useRateDetailsFormatter(interestTiers);

  if (!showTieredRateDetails || onlyHasOneRateTier || isAwaitingQuote) {
    const interestRateYield = Num.formatInterestRate(formattedInterestTiers?.[0]?.yield ?? quoteInterestRate);
    return (
      <SummaryLineItem
        label={intl.formatMessage({ defaultMessage: "Annual % yield" })}
        labelTestId={testIds.deposit.reviewDeposit.interestRateLabel}
        text={isAwaitingQuote ? QUOTE_LOADING_PLACEHOLDER_TEXT : `${interestRateYield}%`}
        textTestId={`${testIds.deposit.reviewDeposit.interestRate}-0`}
      />
    );
  }

  return (
    <React.Fragment>
      {formattedInterestTiers.map((_data, _i) => {
        return (
          <RateLineItem
            interestTier={_data}
            currency={currency}
            maxThreshold={maxThreshold}
            index={_i}
            key={_i}
            hasDivider={_i === formattedInterestTiers.length - 1 ? true : false}
          />
        );
      })}
    </React.Fragment>
  );
};
