import Axios from "axios";
import { AxiosCacheInstance, setupCache } from "axios-cache-interceptor";
import axios, {
  AXIOS_BASE_CONFIG,
  credentialsRequestInterceptor,
  loginRedirectInterceptor,
} from "@gemini-ui/services/axios";

export const CACHE_ID = {
  accountBalances: "accountBalances",
  marketFilters: "marketFilters",
} as const;

// create a new axios instance to hold the cache
const axiosInstance = Axios.create(AXIOS_BASE_CONFIG);
// Use the interceptors from the base axios instance, retaining existing functionality, aside from retry which has issues.
axiosInstance.interceptors.request.use(credentialsRequestInterceptor);
axiosInstance.interceptors.response.use(undefined, loginRedirectInterceptor.bind(axiosInstance));

// add the cache, bypass in test env
const axiosInstanceWithCache =
  process.env.NODE_ENV === "test"
    ? (axios as AxiosCacheInstance)
    : setupCache(axiosInstance, {
        ttl: 1000,
        interpretHeader: false,
      });

export default axiosInstanceWithCache;
