import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { Colors, Spacing, Text } from "@gemini-ui/design-system";
import { ReactComponent as GeminiIcon } from "@gemini-ui/images/gemini-logo-cyan.svg";

export const fullScreenStyles = css`
  height: 100%;
  width: 100%;

  .ModalLayout {
    border-radius: 0;
    height: 100%;
    max-width: initial;

    .ModalHeader,
    .ModalContent {
      padding: 0;
    }

    .ModalContent {
      height: 100%;
    }
  }
`;

export const SearchBar = styled.input`
  background-color: transparent;
  width: 100%;
  color: ${Colors.gray[100]};
  border-radius: ${Spacing.scale[1]};
  border: 1px solid ${Colors.gray[400]};
  margin: ${Spacing.scale[0.5]} 0;
  padding: ${Spacing.scale[1]} ${Spacing.scale[1]};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${Colors.gray[400]};
  }
`;

export const Form = styled("form")`
  margin-bottom: 0;
  width: 100%;
`;

export const Loader = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.scale[5]};
`;

export const Logo = styled(GeminiIcon)`
  width: 92px;
  height: 92px;
  margin-bottom: ${Spacing.scale[5]};

  @media ${BREAKPOINTS.tabletUp} {
    width: 200px;
    height: 200px;
    margin-bottom: ${Spacing.scale[11]};
  }
`;

export const Title = styled(Text.Subhead)`
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: ${Spacing.scale[3]};
  text-align: center;

  @media ${BREAKPOINTS.tabletUp} {
    font-size: 36px;
  }
`;

export const SubTitle = styled(Text.Subhead)`
  line-height: 1.5;
  text-align: center;
`;

export const AccountMenuTitleText = styled(Text.Body)`
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
