export enum DateFormats {
  /** @example 02/15/2022 12:00 EST */
  MonthDayYearTimeTZ = "MM/dd/yyyy HH:mm z",
  /** @example 02/15/2022 12:00:00 */
  MonthDayYearTime = "MM/dd/yyyy HH:mm:ss",
  /** @example "02/15/2022" */
  MonthDayYear = "MM/dd/yyyy",
  /** @example "15/02/2022" */
  DayMonthYear = "dd/MM/yyyy",
  /** @example "February 15, 2022" */
  MonthFullDayYear = "MMMM d, yyyy",
  /** @example "Feb 15, 2022" */
  MonthAbbrevDayYear = "MMM d, yyyy",
  /** @example "February 15" */
  MonthDay = "MMMM dd",
  /** @example "Feb 15" */
  AbbrevMonthAbbrevDay = "MMM d",
  /** @example "2022-02-15" */
  YearMonthDay = "yyyy-MM-dd",
  /** @example "2022-02" */
  YearMonth = "yyyy-MM",
  /** @example Feb 15, 2022 at 12:00:00 AM */
  MonthAbbrevDayYearAtTime = "MMM d, yyyy 'at' h:mm:ss a",
  /** @example Feb 15, 2022, 12:00 AM */
  MonthAbbrevDayYearWithTime = "MMM dd, yyyy, h:mm a",
  /** @example February 15, 2022, 12:00 AM */
  MonthFullDayYearWithTime = "MMMM d, yyyy, h:mm a",
  /** @example Feb 15, 2022 at 12:00am EST */
  MonthAbbrevDayYearAtTimeTZ = "MMM d, yyyy 'at' h:mmaaa z",
  /** @example February 2022 */
  MonthYear = "MMMM yyyy",
  /** @example Tuesday, Feb 15th  */
  DayFullMonthDay = "EEEE, MMM do",
  /** @example 12:00 AM - 02/15/22 */
  HourMinuteMonthDayYear = "h:mm a - MM/dd/yy",
  Year = "yyyy",
  Day = "dd",
  MonthAbbrevDayYearFullTime = "MMMM do, yyyy, hh:mm:ss a",
}

export enum TimeFormats {
  HourMinuteTZ = "hh:mm a z",
  Hour24MinuteSecondTZ = "HH:mm:ss z",
  Hour24MinuteSecond = "HH:mm:ss",
  Hour24Minute = "HH:mm",
  HourMinuteSecond = "hh:mm:ss a",
  HourAbbrevMinuteSecond = "h:mm:ss a",
  HourAbbrevMinuteSecondTZ = "h:mm:ss a z",
  HourAbbrevMinute = "h:mm a",
  HourMinute = "hh:mm a",
}
