import React from "react";
import * as Sentry from "@sentry/browser";
import useIsMounted from "@gemini-ui/hooks/useIsMounted";
import { PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

type RangeInterval = "1m" | "5m" | "15m" | "30m" | "1hr" | "6hr" | "1day";

export enum TimeFrame {
  HOUR = "1h",
  DAY = "1d",
  WEEK = "1w",
  MONTH = "1m",
  YEAR = "1y",
}

export const TIME_FRAMES_API_MAP: { [k in TimeFrame]: { apiTimeInterval: RangeInterval; intervalSkip: number } } = {
  [TimeFrame.HOUR]: {
    apiTimeInterval: "1m",
    intervalSkip: 1,
  },
  [TimeFrame.DAY]: {
    apiTimeInterval: "1m",
    intervalSkip: 6,
  },
  [TimeFrame.WEEK]: {
    apiTimeInterval: "5m",
    intervalSkip: 6,
  },
  [TimeFrame.MONTH]: {
    apiTimeInterval: "30m",
    intervalSkip: 4,
  },
  [TimeFrame.YEAR]: {
    apiTimeInterval: "1day",
    intervalSkip: 1,
  },
};

export const getSinglePairDetails = async (tradingPair: string, timeFrame: TimeFrame) => {
  const { intervalSkip, apiTimeInterval } = TIME_FRAMES_API_MAP[timeFrame];
  try {
    const response = await axios.get(
      `${
        jsRoutes.controllers.mobile.AssetDetailController.getSinglePairDetails(tradingPair, apiTimeInterval).url
      }?skip=${intervalSkip}`,
      {
        retry: 3,
      }
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const useSinglePairDetails = (tradingPair: string, timeFrame = TimeFrame.DAY) => {
  const _isMounted = useIsMounted();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string>(null);
  const [data, setData] = React.useState<PairDetail>(null);

  const fetchSinglePairDetails = React.useCallback(
    (showLoader = true) => {
      if (showLoader) setLoading(true);

      getSinglePairDetails(tradingPair, timeFrame)
        .then(data => {
          if (!_isMounted()) return;
          if (data) {
            setData(data);
            setError(null);
          }
          setLoading(false);
        })
        .catch(error => {
          if (!_isMounted()) return;
          setLoading(false);
          setError(getError(error));
        });
    },
    [tradingPair, timeFrame, _isMounted]
  );

  React.useEffect(() => {
    fetchSinglePairDetails(false);
  }, [fetchSinglePairDetails]);

  return {
    data,
    error,
    loading,
    fetchSinglePairDetails,
  };
};
