import { HTMLAttributes, ReactElement, ReactNode } from "react";
import { ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";

export const DEPRECATED_VARIANTS = { flat: "flat", "no-border": "no-border", raised: "raised" } as const;

export const BADGE_VARIANT = {
  label: "label",
  counter: "counter",
  pip: "pip",
  ...DEPRECATED_VARIANTS,
} as const;

export type BadgeVariantType = keyof typeof BADGE_VARIANT;

export type StatusType = "alert" | "info" | "success" | "warning" | "neutral" | "reward";

export type BadgeProps = {
  /** @deprecated please choose a status instead */
  backgroundColor?: string;
  /**
   * If `true`, a border inheriting the theme's primary background color is drawn around the Badge.
   */
  bordered?: boolean;
  children?: ReactNode;
  icon?: ReactElement;
  iconPosition?: "left" | "right";
  size?: "xs" | "sm" | "md";
  status?: StatusType;
  /** @deprecated please choose a status instead */
  variant?: BadgeVariantType;
} & ShorthandSpacingCssProps &
  HTMLAttributes<HTMLSpanElement>;
