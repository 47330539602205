import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosResponse, Cancel } from "axios";
import _ from "lodash";
import { ErrorCode } from "@gemini-ui/services/axios";
import { IntlShape } from "@gemini-ui/utils/intl";

const DEFAULT_ERROR = "Something went wrong. Please try again.";

export const getErrorCopy = (intl: IntlShape) => ({
  DEFAULT: intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." }),
  STALE_QUOTE: intl.formatMessage({
    defaultMessage:
      "The order details you submitted are either invalid or stale. Please verify the order details and try again.",
  }),
  INSUFFICIENT_PRIVILEGE: intl.formatMessage({
    defaultMessage: "Sorry, you do not have sufficient privilege to complete this action.",
  }),
  PRICE_MOVEMENT: intl.formatMessage({
    defaultMessage:
      "Due to price movement, the quoted price has changed and your order did not execute. Please review and try again.",
  }),
});

// Paths within the response that may contain an error message
const GLOBAL_ERROR_PATHS = ["data", "data.message", "error", "message"];

const getFormError = (response: AxiosResponse): string | undefined =>
  _.first(_.flatten(_.values(_.get(response.data, "form.errors"))));

export const getGlobalError = (response: AxiosResponse): string | undefined =>
  _.first(
    _.filter(
      _.map(GLOBAL_ERROR_PATHS, path => (_.isString(response.data) ? response.data : _.get(response.data, path))),
      _.isString
    )
  );

const handleMalformedServerErrors = (globalError: string | undefined, code: ErrorCode) => {
  // if the error is html (like a 500 scala internal server) log sentry but swallow the bad error
  // likewise, if globalError is "form" (but no form errors were caught in getFormError) don't show "form"
  if (typeof globalError === "string" && (globalError.toLowerCase().includes("<html") || globalError === "form")) {
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Warning);
      scope.setExtras({ code });
      Sentry.captureException(globalError);
    });
    // returning undefined to show defaultError, can return custom error message here
    return undefined;
  }

  return globalError;
};

/**
 * Consumes different error types (typically on a network call) and spits out the display.
 *
 * @param {AxiosError} error - check axios website for error type
 * @param {string} defaultError - in case error code doesn't match any of the handled, default to this error
 * @return {(response: AxiosResponse<T>) => string | undefined} callback which exposes the error response handled by server for custom error message.
 *
 * @example
 *
 *     getError(someError, "My default error", response => response.errors[0].myError.some.nested.error)
 */
export const getError = (
  error: AxiosError,
  defaultError: string = DEFAULT_ERROR,
  formErrorHandler: typeof getFormError = getFormError
): string => {
  switch (error.code) {
    case ErrorCode.SERVER_ERROR:
      // Guaranteed to have a response here since it's a server error
      const response = error.response;
      const formError = formErrorHandler(response);
      return formError || handleMalformedServerErrors(getGlobalError(response), error.code) || defaultError;
    case ErrorCode.CANCEL_ERROR:
      return (error as Cancel).message;
    case ErrorCode.NETWORK_ERROR:
    case ErrorCode.TIMEOUT_ERROR:
    case ErrorCode.REQUEST_ERROR:
    default:
      Sentry.withScope(scope => {
        scope.setExtras({ code: error.code });
        Sentry.captureException(error);
      });
      return defaultError;
  }
};

/* to be used directly with formik actions.setErrors(formErrors) */
export function getFormErrors<T = any>(err: AxiosError): T {
  return _.get(err, "response.data.form.errors");
}

export const handleNeedsRedirect = (e: AxiosError<{ error?: string; redirect?: string }>) => {
  if (e.response?.data?.error === "noAccess" && e.response?.config?.url) {
    window.location.href = e.response.config.url;
    return true;
  } else if (e.response?.data?.error === "needs2fa" || e.response?.data?.error === "needsUserPolicyConsentResponse") {
    window.location.href = e.response.data.redirect;
    return true;
  }

  return false;
};
