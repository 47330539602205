import React from "react";
import { Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  name: string;
}
function Complete({ name }: Props) {
  const { intl } = useIntl();

  return (
    <Text.Body size="sm" mb={5} align="left" data-testid={testIds.transferControl.completeMessage}>
      {intl.formatMessage(
        defineMessage({
          defaultMessage: `You have confirmed {name} as the owner of the sending wallet.`,
        }),
        { name }
      )}
    </Text.Body>
  );
}

export default Complete;
