/* eslint-disable @typescript-eslint/no-unused-vars */
import { BankSettingsInitialData } from "@gemini-ui/transformers/PaymentMethods";
import {
  BankSettingsPageDataType,
  BcoloDataType,
  PayPalDataType,
  XfersDataType,
} from "@gemini-ui/transformers/PaymentMethods/constants";
import { transformPaymentMethods } from "@gemini-ui/transformers/PaymentMethods/transformPaymentMethods";

export const transformBcoloData = (bcolo: BankSettingsInitialData["bcolo"]): BcoloDataType => {
  const { accounts, ...bcoloData } = bcolo;
  return bcoloData;
};

export const transformXfersData = (xfers: BankSettingsInitialData["xfers"]): XfersDataType => {
  const { accounts, ...xfersData } = xfers;
  return xfersData;
};

export const transformPayPalData = (payPal: BankSettingsInitialData["payPal"]): PayPalDataType => {
  const { accounts, ...payPalData } = payPal;
  return payPalData;
};

export const transformBankSettingsPageProps = (data: BankSettingsInitialData): BankSettingsPageDataType => {
  return {
    paymentMethods: transformPaymentMethods(data),
    bcoloData: transformBcoloData(data.bcolo),
    xfersData: transformXfersData(data.xfers),
    payPalData: transformPayPalData(data.payPal),
    canManagePaymentMethods: data.canManageBankAccounts,
    isPlaidRuxEnabled: data.isPlaidRuxEnabled,
    isPlaidSupported: data.isPlaidSupported,
    loadAddViaWireModal: data.loadAddViaWireModal,
    lockout: data.lockout,
    isVerifiedUser: Boolean(data.isVerifiedUser),
    userDetails: data.userDetails,
  };
};
