// TODO: Add logging if we are hitting the mock instnace
const mockOptimizelyInstance = {
  activate: (experimentName, mixpanelId, attributes) => null,
  track: (eventKey, userId, attributes, eventTags) => null,
  getVariation: (experimentKey, mixpanelId, attributes) => null,
  setForcedVariation: (experimentKey, mixpanelId, variationKey) => null,
  getForcedVariation: (experimentKey, mixpanelId) => null,
  isFeatureEnabled: (featureKey, mixpanelId, attributes) => null,
  getEnabledFeatures: (userId, attributes) => null,
  getFeatureVariableBoolean: (featureKey, variableKey, mixpanelId, attributes) => null,
  getFeatureVariableDouble: (featureKey, variableKey, mixpanelId, attributes) => null,
  getFeatureVariableInteger: (featureKey, variableKey, mixpanelId, attributes) => null,
  getFeatureVariableString: (featureKey, variableKey, mixpanelId, attributes) => null,
  notificationCenter: {
    addNotificationListener: (notificationType, callback) => null,
    removeNotificationListener: listenerId => null,
  },
  getFeatureVariableJSON: (featureKey, variableKey, mixpanelId, attributes) => null,
  createUserContext: (userId, attributes?) => null,
};

export default mockOptimizelyInstance;
