export const testIds = {
  button: {
    submitCreditHardPull: "submitCreditHardPull",
    emailVerify: "emailVerifyBtn",
    preQual: "preQual",
    grossAnnualIncome: "grossAnnualIncome",
    selectReward: "selectReward",
    uploadDocumentMock: "uploadDocumentMock",
    submitNewApp: "submitNewApp",
    help: "help",
    signIn: "signIn",
    editAddress: "editAddress",
    editDateOfBirth: "editDateOfBirth",
    saveAddress: "saveAddress",
    saveDateOfBirth: "saveDateOfBirth",
    cancel: "cancel",
    orderCard: "orderCard",
    exitApplication: "exitApplication",
    final: "final",
  },
  input: {
    firstName: "firstNameInput",
    lastName: "lastNameInput",
    middleName: "middleName",
    preferredName: "preferredNameInput",
    emailAddress: "emailAddressInput",
    dateOfBirth: "dateOfBirthInput",
    nationalIdNumber: "nationalIdNumberInput",
    addressStreet: "addressStreetInput",
    addressRegion: "addressRegionInput",
    addressPostalCode: "addressPostalCodeInput",
    addressApartment: "addressApartmentInput",
    addressCity: "addressCity",
    grossAnnualIncome: "grossAnnualIncomeInput",
    phone: "phone",
    emailVerify: "emailVerify",
    pw: "pw",
  },
  checkbox: {
    newAppTerms: "newAppTermsCheckbox",
  },
  radio: {
    useLegalName: "useLegalName",
    createPreferredName: "createPreferredName",
  },
  form: {
    newUserApplication: "newUserApplicationForm",
  },
  tooltip: {
    preferredName: "preferredNameTooltip",
    temporaryCreditLimit: "temporaryCreditLimitTooltip",
    annualIncome: "annualIncomeTooltip",
  },
  progressBar: "progressBar",
};
