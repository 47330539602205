import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga4";
import { CreditAnalyticsEvents, GoogleAnalyticsEvents } from "@gemini-ui/analytics/constants/events";
import { EVENTS_ERROR_TRACKING_LABEL_PREFIX } from "@gemini-ui/utils/constants";
export function trackGoogleAnalyticsEvent(
  eventAction: GoogleAnalyticsEvents | CreditAnalyticsEvents,
  additionalParams: object = {}
) {
  try {
    if (additionalParams) {
      ReactGA.event(eventAction, additionalParams);
    } else {
      ReactGA.event(eventAction);
    }
  } catch (e) {
    e.message = `${EVENTS_ERROR_TRACKING_LABEL_PREFIX} -- ${eventAction} -- GA event not tracked`;
    Sentry.captureException(e);
  }
}
