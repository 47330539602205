import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Spacing } from "@gemini-ui/design-system";
import { ReactComponent as EarnSVG } from "@gemini-ui/images/Earn/Illustration/EarnLogoIllustration.svg";
import { ReactComponent as GrowLearnMoreBlocks } from "@gemini-ui/images/Earn/Illustration/GrowLearnMoreBlocks.svg";
import { ReactComponent as GusdSVG } from "@gemini-ui/images/Earn/Illustration/GusdIllustration.svg";
import { ReactComponent as EthStakingSVG } from "@gemini-ui/images/Earn/staking-icon-eth.svg";

const iconCss = css`
  margin: 0 ${Spacing.scale[0.5]} 0 ${Spacing.scale[2.5]};
`;

export const StakingIcon = styled(EthStakingSVG)`
  ${iconCss}
  width: 100px;
  height: 100px;
`;

export const LearnMoreBlockGraphic = styled(GrowLearnMoreBlocks)`
  ${iconCss}
`;

export const EarnIcon = styled(EarnSVG)`
  ${iconCss}
  width: 100px;
  height: 100px;
`;

export const GusdSVGIcon = styled(GusdSVG)`
  ${iconCss}
  width: 100px;
  height: 100px;
`;
