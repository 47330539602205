import React from "react";
import { Articles, generateHelpCenterUrl } from "@gemini-ui/components/HelpCenterLink";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import Complete from "@gemini-ui/components/TransferControl/Complete";
import ComplyAdvantageMismatch from "@gemini-ui/components/TransferControl/ComplyAdvantageMismatch";
import ConfirmOwnership from "@gemini-ui/components/TransferControl/ConfirmOwnership";
import Error from "@gemini-ui/components/TransferControl/Error";
import { Action, ActionType, State } from "@gemini-ui/components/TransferControl/reducer";
import SelectSender from "@gemini-ui/components/TransferControl/SelectSender";
import { ModalStep, OwnershipStep, OwnerType, SenderType } from "@gemini-ui/components/TransferControl/types";
import { ButtonProps } from "@gemini-ui/design-system/Button";
import { ReactComponent as CompleteImage } from "@gemini-ui/images/complete_v2.svg";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type Views = {
  [key in ModalStep]: {
    component: React.ReactNode;
    title: string | React.ReactNode;
    ctas?: [ButtonProps, ButtonProps];
    headerImage?: React.ReactNode;
    centered?: boolean;
  };
};

interface Props {
  state: State;
  assetAmount: MoneyProps;
  dateReceived: string;
  dispatch: React.Dispatch<Action>;
  onClose: () => void;
  onStepChange: (step: ModalStep, value?: any) => void;
  submitAttestation: () => void;
  isUkTravelRuleEnabled: boolean;
}
export function useTransferControlConfig({
  state,
  assetAmount,
  dateReceived,
  dispatch,
  onClose,
  onStepChange,
  submitAttestation,
  isUkTravelRuleEnabled,
}: Props): Views {
  const { intl } = useIntl();
  const { transferControl } = testIds;

  const CANCEL_CTA = intl.formatMessage({ defaultMessage: "Cancel" });
  const CONTINUE_CTA = intl.formatMessage({ defaultMessage: "Continue" });
  const CONFIRM_CTA = intl.formatMessage({ defaultMessage: "Confirm" });
  const DONE_CTA = intl.formatMessage({ defaultMessage: "Done" });
  const CONTACT_SUPPORT_CTA = intl.formatMessage({ defaultMessage: "Contact support" });

  const selfCopy = intl.formatMessage({
    defaultMessage: "yourself",
    description: "When user attests the owner of the sending wallet as 'myself'",
  });
  const isSelf = state.senderType === SenderType.MY_SELF;
  const isIndividual = state.ownerType === OwnerType.INDIVIDUAL;
  let senderConfirmed = isSelf ? selfCopy : state.ownerName;

  if (isUkTravelRuleEnabled && isIndividual && !isSelf) {
    senderConfirmed = `${state.ownerFirstName} ${state.ownerLastName}`;
  }

  const getCTA = (modalStep: ModalStep): { cta1: string; cta2: string; cta1TestId: string; cta2TestId: string } => {
    const cancelConfirmCta = {
      cta1: CANCEL_CTA,
      cta2: CONFIRM_CTA,
      cta1TestId: transferControl.cancelCta,
      cta2TestId: transferControl.confirmCta,
    };

    if (modalStep === ModalStep.Error) {
      return {
        cta1: CONTACT_SUPPORT_CTA,
        cta2: DONE_CTA,
        cta1TestId: transferControl.contactSupportCta,
        cta2TestId: transferControl.doneCta,
      };
    }

    switch (state.senderType) {
      case SenderType.MY_SELF:
        if (modalStep === ModalStep.ConfirmOwnership && state.confirmOwnershipStep === OwnershipStep.ReviewSelf)
          return cancelConfirmCta;
      case SenderType.SOMEONE_ELSE:
        if (modalStep === ModalStep.ConfirmOwnership) {
          if ([OwnershipStep.ConfirmOwner, OwnershipStep.ConfirmAddress].includes(state.confirmOwnershipStep))
            return {
              cta1: CANCEL_CTA,
              cta2: CONTINUE_CTA,
              cta1TestId: transferControl.cancelCta,
              cta2TestId: transferControl.continueCta,
            };
          if (state.confirmOwnershipStep === OwnershipStep.ReviewThirdParty) return cancelConfirmCta;
        }
      case SenderType.NOT_RECOGNIZED:
        if (modalStep === ModalStep.ConfirmOwnership && state.confirmOwnershipStep === OwnershipStep.ReviewUnknown)
          return cancelConfirmCta;
        if (modalStep === ModalStep.ComplyAdvantageMismatch)
          return {
            cta1: CONTACT_SUPPORT_CTA,
            cta2: DONE_CTA,
            cta1TestId: transferControl.contactSupportCta,
            cta2TestId: transferControl.doneCta,
          };
    }

    return { cta1: "", cta2: "", cta1TestId: "", cta2TestId: "" };
  };

  const title =
    state.confirmOwnershipStep === OwnershipStep.ConfirmAddress
      ? intl.formatMessage({
          defaultMessage: "Sender address (Optional)",
          description: "When requesting optional address info from the sending wallet",
        })
      : intl.formatMessage(
          defineMessage({
            defaultMessage: "Confirm deposit of <amount></amount>",
          }),
          { amount: () => <Money {...assetAmount} /> }
        );

  return {
    [ModalStep.SelectSender]: {
      component: <SelectSender dateReceived={dateReceived} onStepChange={onStepChange} />,
      title,
    },
    [ModalStep.ConfirmOwnership]: {
      component: (
        <ConfirmOwnership
          state={state}
          dateReceived={dateReceived}
          dispatch={dispatch}
          getIsConfirmSenderIsValid={isValid => {
            dispatch({ type: ActionType.IS_CONFIRM_OWNERSHIP_VALID, payload: isValid });
          }}
          isUkTravelRuleEnabled={isUkTravelRuleEnabled}
        />
      ),
      title,
      ctas: [
        {
          "data-testid": getCTA(ModalStep.ConfirmOwnership).cta1TestId,
          onClick: onClose,
          cta: getCTA(ModalStep.ConfirmOwnership).cta1,
        },
        {
          cta: getCTA(ModalStep.ConfirmOwnership).cta2,
          disabled: state.confirmOwnershipStep === OwnershipStep.ConfirmOwner ? !state.isConfirmOwnershipValid : false,
          loading: state.isSubmitting,
          type: state.confirmOwnershipStep !== OwnershipStep.ReviewThirdParty ? "button" : "submit",
          "data-testid": getCTA(ModalStep.ConfirmOwnership).cta2TestId,
          onClick: () => {
            switch (state.confirmOwnershipStep) {
              case OwnershipStep.ReviewSelf:
              case OwnershipStep.ReviewThirdParty:
              case OwnershipStep.ReviewUnknown:
                // Submit form
                dispatch({ type: ActionType.IS_SUBMITTING, payload: true });
                submitAttestation();
                break;
              case OwnershipStep.ConfirmOwner:
              case OwnershipStep.ConfirmAddress:
                if (isUkTravelRuleEnabled && state.confirmOwnershipStep === OwnershipStep.ConfirmOwner) {
                  dispatch({ type: ActionType.CONFIRM_OWNERSHIP_STEP, payload: OwnershipStep.ConfirmAddress });
                } else {
                  dispatch({ type: ActionType.CONFIRM_OWNERSHIP_STEP, payload: OwnershipStep.ReviewThirdParty });
                }
                break;
            }
          },
        },
      ],
    },
    [ModalStep.ComplyAdvantageMismatch]: {
      component: <ComplyAdvantageMismatch />,
      title: intl.formatMessage({ defaultMessage: "We're looking into this" }),
      ctas: [
        {
          cta: getCTA(ModalStep.ComplyAdvantageMismatch).cta1,
          target: "_blank",
          href: generateHelpCenterUrl({ article: Articles.ATTEST_ADDRESS, intl }),
          "data-testid": getCTA(ModalStep.ComplyAdvantageMismatch).cta1TestId,
        },
        {
          cta: getCTA(ModalStep.ComplyAdvantageMismatch).cta2,
          "data-testid": getCTA(ModalStep.ComplyAdvantageMismatch).cta2TestId,
          onClick: onClose,
        },
      ],
    },
    [ModalStep.Complete]: {
      component: <Complete name={senderConfirmed} />,
      title: intl.formatMessage({ defaultMessage: "Attestation complete" }),
      headerImage: <CompleteImage />,
      centered: true,
      ctas: [
        {
          cta: intl.formatMessage({ defaultMessage: "View transfers" }),
          href: "/transfer",
          "data-testid": transferControl.viewTransfersCta,
        },
        {
          cta: DONE_CTA,
          "data-testid": transferControl.doneCta,
          onClick: onClose,
        },
      ],
    },
    [ModalStep.Error]: {
      component: <Error />,
      title: intl.formatMessage({ defaultMessage: "Oops, something went wrong" }),
      ctas: [
        {
          cta: getCTA(ModalStep.Error).cta1,
          target: "_blank",
          href: generateHelpCenterUrl({ article: Articles.ATTEST_ADDRESS, intl }),
          "data-testid": getCTA(ModalStep.Error).cta1TestId,
        },
        {
          cta: getCTA(ModalStep.Error).cta2,
          "data-testid": getCTA(ModalStep.Error).cta2TestId,
          onClick: onClose,
        },
      ],
    },
  };
}
