import { FundingSourceMethodType } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/utils";
import { GrowBuyAction, GrowBuyActionType } from "@gemini-ui/pages/Earn/GrowBuy/context/actions";
import { defaultState } from "@gemini-ui/pages/Earn/GrowBuy/context/constants";
import { GrowBuyState } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { getPaymentMethodType } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";

export const depositStatusReducer = (state: GrowBuyState, action: GrowBuyAction): GrowBuyState => {
  switch (action.type) {
    case GrowBuyActionType.UPDATE_DEPOSIT_STATUS:
      const { currency = null, providerType = null, view: nextView = null, ...newVals } = action?.value ?? {};

      return {
        ...state,
        ...(Boolean(currency) && { currency }),
        ...(Boolean(providerType) && { providerType }),
        depositStatus: { ...state.depositStatus, ...newVals },
        router: {
          ...state.router,
          // If we have a new modal view, add it to the stack
          ...(Boolean(nextView) && {
            currentView: nextView,
            viewStack: [...state.router.viewStack, nextView],
          }),
        },
      };
    case GrowBuyActionType.SET_QUOTE:
      return {
        ...state,
        depositStatus: {
          ...state.depositStatus,
          quote: action.value,
        },
      };
    case GrowBuyActionType.RESET_QUOTE:
      return {
        ...state,
        depositStatus: {
          ...state.depositStatus,
          quote: null,
          error: null,
        },
      };
    case GrowBuyActionType.SET_DEPOSIT_ERROR:
      return {
        ...state,
        depositStatus: {
          ...state.depositStatus,
          error: action.value,
        },
      };
    case GrowBuyActionType.SET_PAYMENT_METHOD:
      return {
        ...state,
        depositStatus: {
          ...state.depositStatus,
          // The `paymentMethod` action needs to be added in a semi-reverser order.
          // First we want to reset the payment method to the default state of `null`,
          // Then, if the action contains card or bank info, add that to the payment method.
          // Lastly, overrite the inheritied `type` with the correct type.
          paymentMethod: {
            ...defaultState.depositStatus.paymentMethod,
            ...(!FundingSourceMethodType.isTradingBalance(action.value) &&
              !FundingSourceMethodType.isFiatBalance(action.value) && { ...action.value }),
            type: FundingSourceMethodType.isTradingBalance(action.value)
              ? action.value
              : getPaymentMethodType(action.value),
          },
        },
      };
    default:
      return state;
  }
};
