import { IntlShape } from "react-intl";
import { VerifyContext } from "@gemini-ui/pages/register/Verify/types";
import { isBasicPlusTier } from "@gemini-ui/pages/register/Verify/utils";

export const getVariation = (user: VerifyContext) => {
  if (user.requireLivenessCheck) {
    return "livenessCheck";
  } else if (isBasicPlusTier(user.accountState.tier)) {
    return "basicPlus";
  } else {
    return "idUploadOnly";
  }
};

export const getCardText = (intl: IntlShape) => {
  return {
    livenessCheck: intl.formatMessage({ defaultMessage: "Photo ID + Selfie" }),
  };
};
export const getHeaderText = (intl: IntlShape) => {
  return {
    livenessCheck: intl.formatMessage({
      defaultMessage: "Almost done. We just need to verify your identity.",
    }),
    basicPlus: intl.formatMessage({
      defaultMessage: "You're all set to buy crypto. Provide photo ID to transfer crypto as well.",
    }),
    idUploadOnly: intl.formatMessage({
      defaultMessage: "Let's verify your photo ID",
    }),
  };
};
export const getSubText = (intl: IntlShape) => {
  return {
    livenessCheck: intl.formatMessage({
      defaultMessage:
        "This helps prevent someone else from creating a Gemini account in your name. Here’s what we’ll need:",
    }),
    basicPlus: null,
    idUploadOnly: intl.formatMessage({
      defaultMessage:
        "This information is used to verify your identity so no one else can create an account in your name.",
    }),
  };
};
export const getCtaText = (intl: IntlShape) => {
  return {
    livenessCheck: intl.formatMessage({ defaultMessage: "Upload" }),
    basicPlus: intl.formatMessage({ defaultMessage: "Add your ID" }),
    idUploadOnly: intl.formatMessage({ defaultMessage: "Add your ID" }),
  };
};
export const ONFIDO_LIVENESS_VARIANT = "standard";
