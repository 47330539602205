/* global gtag, dataLayer */
// prettier-ignore
import _ from "lodash";

(function () {

  const email = _.get(window.initialData.templateProps, "user.email", "");
  window.enhanced_conversion_data = (email) ? { "email": email } : {};

  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=AW-538501539";
  document.head.appendChild(script);
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {dataLayer.push(arguments);};
})();

gtag("js", new Date());

// AdWords
gtag("config", "AW-538501539", {'allow_enhanced_conversions':true});

