import BigNumber from "bignumber.js";

import { CURRENCIES_DETAIL, CurrencyDetail, CurrencyShortName, isFiatCurrency } from "../constants/currencies";
import Num from "../Num";

import { MoneyProps } from "./constants";

const fixDecimalPrecision = (value: BigNumber) => {
  return value.isInteger() ? 2 : value.toFixed().split(".")[1].length;
};

type FormatOpts = {
  decimals: number | null;
  currency: string;
  decimalsOverride: number;
  value: BigNumber;
  removeTrailingZeros: boolean;
};

export const formatNumOptions = ({
  decimals,
  currency,
  decimalsOverride,
  value,
  removeTrailingZeros,
}: FormatOpts): { sep: boolean; currency: CurrencyDetail; decimals: number } => {
  if (decimals == null) {
    decimals = undefined;
  }
  return {
    sep: true,
    currency: currency ? CURRENCIES_DETAIL[currency] : undefined,
    decimals: removeTrailingZeros ? fixDecimalPrecision(value) : decimalsOverride ?? decimals,
  };
};

const MAX_DECIMALS = 20;

type LocalizedMoney = {
  currency: string;
  locale: string;
  decimals: number | undefined;
  decimalsOverride?: number | undefined;
  numberFormatOptions?: Intl.NumberFormatOptions;
};

export const initMoneyFormat = ({ currency, locale, decimals, numberFormatOptions }: LocalizedMoney) => {
  if (isFiatCurrency(currency)) {
    const options: Intl.NumberFormatOptions = {
      style: "currency",
      currency,
      minimumFractionDigits: decimals ? Math.min(decimals, MAX_DECIMALS) : decimals === 0 ? 0 : undefined,
      ...numberFormatOptions,
    };

    try {
      return new Intl.NumberFormat(locale, {
        ...options,
        currencyDisplay: "narrowSymbol", // narrowSymbol prevents showing the currency twice in some locales ($US10 USD)
      });
    } catch (e) {
      console.log(`${e} for this browser version, using default "symbol" instead.`);
      return new Intl.NumberFormat(locale, {
        ...options,
        currencyDisplay: "symbol", // older versions of Safari doesn't support narrowSymbol, so we need a fallback
      });
    }
  } else {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: typeof decimals !== "undefined" ? Math.min(decimals, MAX_DECIMALS) : undefined,
      maximumFractionDigits: Math.min(MAX_DECIMALS, decimals ?? MAX_DECIMALS),
    });
  }
};

export const truncateValue = (
  formatted: string,
  truncate: number,
  currency: CurrencyShortName,
  num: BigNumber,
  decimalsOverride: number
) => {
  const decimalPoint: number = formatted.includes(".") ? formatted.indexOf(".") : formatted.length;
  const minimumAllowedDigitsAfterDecimal = isFiatCurrency(currency) ? 2 : 1;

  const minimumEnd = decimalPoint + 1 + minimumAllowedDigitsAfterDecimal;

  if (-1 < minimumEnd && minimumEnd <= truncate) {
    // there's room for a '.x' at the end of the number
    return formatted.slice(0, truncate);
  } else if (-1 < decimalPoint && decimalPoint <= truncate) {
    // slice to the whole number so there's no weird trailing .
    return formatted.slice(0, decimalPoint);
  } else {
    // have to abbreviate the number with a K, MM, or B
    const absVal = num.abs();
    const bp = absVal.isGreaterThan(1e9)
      ? { val: 1e9, abbr: "B" }
      : absVal.isGreaterThan(1e6)
      ? { val: 1e6, abbr: "MM" }
      : absVal.isGreaterThan(1e3)
      ? { val: 1e3, abbr: "K" }
      : { val: 1, abbr: "" };

    const scaledVal = num.div(bp.val);

    const wholeNumberOptions = formatNumOptions({
      decimals: 0,
      currency,
      value: num,
      removeTrailingZeros: false,
      decimalsOverride,
    });

    const wholeStr = Num.format(scaledVal.toString(10), wholeNumberOptions); // format as a whole number

    if (wholeStr.length + bp.abbr.length + 2 <= truncate) {
      // there's room to add a '.x' to the end, so do that
      const zeroesNumberOptions = formatNumOptions({
        decimals: truncate - wholeStr.length - bp.abbr.length - 1,
        currency,
        value: num,
        removeTrailingZeros: false,
        decimalsOverride,
      });

      return Num.format(scaledVal.toString(10), zeroesNumberOptions) + bp.abbr;
    } else {
      return wholeStr + bp.abbr;
    }
  }
};

export const getBigNumberFromValue = (value: number | string | BigNumber) => {
  if (typeof value === "undefined") {
    return new BigNumber("");
  }
  return typeof value === "string" || typeof value === "number" ? new BigNumber(value) : value;
};

export const hasMoreThanSmallestUnit = (moneyProps: MoneyProps) => {
  if (!Boolean(moneyProps?.currency)) return false;
  // Prevent dust from being allowed to redeem
  // e.g for GUSD this === "0.01"
  // for BTC this === "0.00000001" etc
  const maxDecimals = CURRENCIES_DETAIL[moneyProps.currency].receiptDecimals;
  const min = new BigNumber(`1e-${maxDecimals}`);
  return new BigNumber(moneyProps.value).isGreaterThanOrEqualTo(min);
};

export function countDecimals(value: number | string) {
  return value !== undefined ? new BigNumber(value).decimalPlaces() : 0;
}
