const loadGoogleTagManager = () => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
   const j = d.createElement(s),
       dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  
      // Append the script to the head instead of the body
      const head = d.head || d.getElementsByTagName('head')[0];
      head.appendChild(j);
    })(window, document, 'script', 'dataLayer', 'GTM-K4R83PJ');
  };
  
  loadGoogleTagManager();