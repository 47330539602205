import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconCheck } from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import { optimizelyClient } from "@gemini-ui/analytics";
import { FlowState, GENERIC_PAYPAL_ERROR, LinkData, PayPalErrors } from "@gemini-ui/components/PayPalModal/constants";
import { PayPalErrorModal } from "@gemini-ui/components/PayPalModal/PayPalErrorModal";
import {
  PayPalButtonGroup,
  PayPalLoadingButton,
  PayPalLoadingContainer,
} from "@gemini-ui/components/PayPalModal/styles";
import { usePayPalScripts } from "@gemini-ui/components/PayPalModal/usePayPalScripts";
import { getPayPalModalComponentCopy, renderPayPalButton } from "@gemini-ui/components/PayPalModal/utils";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { usePageData } from "@gemini-ui/contexts";
import { Flex, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import {
  LinkPaymentType,
  PaymentTypeScope,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import { trackPaymentRegistrationSuccess } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationSuccess";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { PaymentMethodDataType, PayPalAccountType } from "@gemini-ui/transformers/PaymentMethods";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";

interface PayPalModalProps {
  isOpen: boolean;
  handleClosePayPal: () => void;
  handlePayPalSuccess: () => void;
  onBack?: () => void;
  paymentMethods: PaymentMethodDataType[];
  isSettingsOrOnboarding?: boolean;
  openSelectPaymentMethodModal?: () => void;
  scope?: PaymentTypeScope;
  subaccountHashid: string;
  onSuccessCallback?: () => void;
}

export const PayPalModal = ({
  isOpen,
  handleClosePayPal,
  handlePayPalSuccess,
  onBack,
  paymentMethods,
  isSettingsOrOnboarding,
  scope,
  openSelectPaymentMethodModal,
  subaccountHashid,
  onSuccessCallback,
}: PayPalModalProps) => {
  const {
    templateProps: {
      user: { email },
    },
  } = usePageData();
  const paymentMethod = paymentMethods.find(
    method => method.paymentMethodType === PaymentMethodType.PAYPAL
  ) as PayPalAccountType;
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const [clientToken, setClientToken] = useState<string | null>(null);
  const [PayPalLoaderVisible, setPayPalLoaderVisible] = useState<boolean>(true);
  const [payerEmail, setPayerEmail] = useState<string>(email);
  const { braintreeReady, paypalReady, dataCollectorReady } = usePayPalScripts();
  const { intl } = useIntl();
  const copy = getPayPalModalComponentCopy(intl);

  const handlePayPalSuccessRef = useRef(handlePayPalSuccess);

  const [flowState, setFlowState] = useState<keyof typeof FlowState>(() =>
    Boolean(paymentMethod) ? FlowState.ERROR : FlowState.INIT
  );
  const [error, setError] = useState<keyof typeof PayPalErrors>(() =>
    Boolean(paymentMethod) ? PayPalErrors.OnePerAccount : PayPalErrors.NONE
  );

  useEffect(() => {
    if (flowState === FlowState.INIT && isOpen) {
      setFlowState(FlowState.LINKING);
      axios
        .get("/paypal/client-token", {
          headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
        })
        .then(res => {
          setClientToken(res.data.token);
        })
        .catch(err => {
          const { reason } = err.response.data;
          trackPaymentRegistrationFailure(LinkPaymentType.PAYPAL, reason || getError(err, GENERIC_PAYPAL_ERROR));
          Sentry.captureException(err);
          setFlowState(FlowState.ERROR);
          setError(Boolean(PayPalErrors[reason]) ? PayPalErrors[reason] : PayPalErrors.TokenGenerationFailed);
        });
    }
  }, [flowState, isOpen, subaccountHashid]);

  const onTokenizedSuccess = useCallback(
    ({ payload, deviceData }: LinkData) => {
      const { firstName, lastName, email, phone, countryCode, payerId } = payload.details;
      setFlowState(FlowState.LOADING);
      axios
        .post(
          "/settings/paypal/payment-methods",
          {
            nonce: payload.nonce,
            payerId,
            customer: { firstName, lastName, email, phone, countryCode },
            deviceData,
          },
          {
            headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          }
        )
        .then(async () => {
          if (isNewAddPaymentsFlowEnabled) {
            setFlowState(FlowState.SUCCESS);
          } else {
            await handlePayPalSuccessRef.current();
            setFlowState(FlowState.INIT);
          }
          trackPaymentRegistrationSuccess(LinkPaymentType.PAYPAL);
        })
        .catch(err => {
          const { reason } = err.response.data;
          if (reason === PayPalErrors.NonUniquePayerId) {
            setPayerEmail(payload.details.email);
            setError(PayPalErrors.NonUniquePayerId);
          } else {
            setError(Boolean(PayPalErrors[reason]) ? PayPalErrors[reason] : PayPalErrors.GENERIC);
          }
          setFlowState(FlowState.ERROR);
          trackPaymentRegistrationFailure(LinkPaymentType.PAYPAL, reason || getError(err, GENERIC_PAYPAL_ERROR));
        });
    },
    [isNewAddPaymentsFlowEnabled, subaccountHashid]
  );

  const onError = err => {
    Sentry.captureException(err);
    const errorString = Boolean(err?.message) ? `code: ${err.code}; message: ${err.message};` : err;
    trackPaymentRegistrationFailure(
      LinkPaymentType.PAYPAL,
      typeof errorString === "string" ? errorString : GENERIC_PAYPAL_ERROR
    );
    setError(PayPalErrors.GENERIC);
    setFlowState(FlowState.ERROR);
  };

  useEffect(() => {
    if (braintreeReady && paypalReady && dataCollectorReady && clientToken && isOpen) {
      renderPayPalButton({
        clientToken,
        setPayPalLoaderVisible,
        onTokenizedSuccess,
        onError,
        setFlowState,
      });
    }
  }, [clientToken, braintreeReady, paypalReady, dataCollectorReady, isOpen, onTokenizedSuccess]);

  if (flowState === FlowState.LOADING) {
    return (
      <Modal isOpen={isOpen} loading={true}>
        <Spacer mt={8} mb={8} />
      </Modal>
    );
  }

  if (flowState === FlowState.ERROR) {
    return (
      <PayPalErrorModal
        isOpen={isOpen}
        error={error}
        onClose={handleClosePayPal}
        setFlowState={setFlowState}
        payerEmail={payerEmail}
        paymentMethod={paymentMethod}
      />
    );
  }

  if (flowState === FlowState.LINKING) {
    return (
      <Modal title={copy.TITLE} isOpen={isOpen} onClose={handleClosePayPal} onBack={onBack}>
        <Text.Body>{copy.DESCRIPTION}</Text.Body>
        <Spacer as="ul" mt={2}>
          <Flex as="li" mt={0.5}>
            <IconCheck /> <Text.Body ml={1}>{copy.BANKS}</Text.Body>
          </Flex>
          <Flex as="li" mt={0.5}>
            <IconCheck /> <Text.Body ml={1}>{copy.DEBIT}</Text.Body>
          </Flex>
          <Flex as="li" mt={0.5}>
            <IconCheck /> <Text.Body ml={1}>{copy.BALANCE}</Text.Body>
          </Flex>
          <PayPalButtonGroup type="stacked">
            <PayPalLoadingContainer data-testid="paypal-button-container" id="paypal-button-container" />
            <PayPalLoadingButton data-testid="paypal-loading-button" loading visible={PayPalLoaderVisible} />
          </PayPalButtonGroup>
        </Spacer>
      </Modal>
    );
  }

  if (flowState === FlowState.INIT) return null;

  if (flowState === FlowState.SUCCESS && isNewAddPaymentsFlowEnabled)
    return (
      <AddPaymentMethodSuccessModal
        isOpen={isOpen}
        onClose={async () => {
          await handlePayPalSuccessRef.current();
          setFlowState(FlowState.INIT);
        }}
        paymentMethodType={AddPaymentMethodType.PAYPAL}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
        onSuccessCallback={onSuccessCallback}
      />
    );
};
