import React from "react";
import { CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { LazyModalWrapper, MODAL_TYPES } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const CryptoDepositFlow = LazyModalWrapper(() => import("./index"));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  preSelectedCrypto?: CurrencyShortNameCrypto;
}

function LazyLoadCryptoDepositFlow({ isOpen, onClose, preSelectedCrypto }: Props) {
  return (
    <CryptoDepositFlow
      isOpen={isOpen}
      onClose={onClose}
      modalType={MODAL_TYPES.Sheet}
      sheetConfigs={{
        side: "right",
        disableAnimation: true,
      }}
      preSelectedCrypto={preSelectedCrypto}
    />
  );
}

export default LazyLoadCryptoDepositFlow;
