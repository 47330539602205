import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faCaretDown,
  faCaretUp,
  faChevronRight,
  faExternalLink,
  faQuestionCircle,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

library.add(
  faAngleDown,
  faAngleUp,
  faCaretDown,
  faCaretUp,
  faChevronRight,
  faExternalLink,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faQuestionCircle
);
class FontAwesome extends React.Component<FontAwesomeIconProps> {
  render() {
    return <FontAwesomeIcon {...this.props} />;
  }
}

export default FontAwesome;
