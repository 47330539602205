import { useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useDebounce } from "react-use";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { DEBOUNCE_INTERVAL_MS, searchSubaccounts } from "@gemini-ui/services/subaccounts";

interface Props {
  withBalance?: boolean;
  subaccounts: Subaccount[];
}

const useAccountFilter = (props: Props) => {
  const { withBalance } = props;
  const [filter, setFilter] = useState("");
  const [subaccounts, setSubaccounts] = useState<Subaccount[]>([]);

  useDebounce(
    () => {
      if (!filter) {
        setSubaccounts(props.subaccounts);
        return;
      }
      searchSubaccounts({
        name: filter,
        limit: 20,
        offset: 0,
        withBalance,
        sortBy: "friendlyName",
      })
        .then(({ results: subaccounts }) => {
          setSubaccounts(subaccounts);
        })
        .catch(e => {
          Sentry.captureException(e);
          setSubaccounts([]);
        });
    },
    DEBOUNCE_INTERVAL_MS,
    [filter]
  );

  // if a filter is present, use the subaccounts from this component's state, otherwise use all
  // subaccounts passed in from props
  const filteredAccounts = useMemo(
    () => (filter ? subaccounts : props.subaccounts),
    [filter, props.subaccounts, subaccounts]
  );

  const values = useMemo(() => ({ filter, filteredAccounts, setFilter }), [filter, filteredAccounts]);

  return values;
};

export default useAccountFilter;
