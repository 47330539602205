import styled from "@emotion/styled";
import { Button } from "@gemini-ui/design-system/Button";
import { PaddingType, paddingTypes } from "@gemini-ui/design-system/cards/Card/constants";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";

export const CardCollapsibleHeader = styled.header<{
  padding: PaddingType;
}>`
  display: grid;
  grid-column-gap: ${({ padding }) => paddingTypes[padding]};
  grid-template-columns: 1fr min-content;
  align-items: center;
`;

export const CardCollapsibleHeading = styled.div<{
  isExpanded: boolean;
  padding: PaddingType;
}>`
  padding-top: ${({ padding }) => paddingTypes[padding]};
  padding-bottom: ${({ isExpanded, padding }) => (isExpanded ? `${Spacing.scale[2]};` : `${paddingTypes[padding]};`)};
`;

export const CardCollapsibleContent = styled.div<{
  padding: PaddingType;
}>`
  padding-bottom: ${({ padding }) => paddingTypes[padding]};
`;

export const CardCollapsibleButton = styled(Button.Tertiary)`
  appearance: none;
  border: none;
  background: inherit;

  > .selected {
    display: none;
  }

  &[aria-expanded="true"] {
    > .unselected {
      display: none;
    }
    > .selected {
      display: inline-flex;
    }
  }
`;
