import * as Sentry from "@sentry/browser";
import { assign, createMachine } from "xstate";
import { CreditAnalyticsEventLabels, CreditAnalyticsEvents } from "@gemini-ui/analytics/constants/events";
import { trackGoogleAnalyticsEvent } from "@gemini-ui/analytics/googleTracking";
import { applicationApi } from "@gemini-ui/client/api";
import { ApplicationStatusStateStateEnum } from "@gemini-ui/client/credit";
import { UserData, XStateEvent } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";

// View states driven by logged in/out status
export enum ClientState {
  INITIALIZING = "CLIENT_INITIALIZING",
  NEW_APPLICATION_V3 = "CLIENT_NEW_APPLICATION_V3",
  EMAIL_VERIFY_V3 = "CLIENT_EMAIL_VERIFY_V3",
  SMS_VERIFY_V3 = "CLIENT_SMS_VERIFY_V3",
  PREFILL_SUCCESS = "CLIENT_PREFILL_SUCCESS",
  MANUAL_ENTRY = "CLIENT_MANUAL_ENTRY",
  ENTER_INCOME = "CLIENT_ENTER_INCOME",
  REVIEW_TERMS = "CLIENT_REVIEW_TERMS",
  CARD_SELECTION = "CLIENT_CARD_SELECTION",
  REWARD_SELECTION = "CLIENT_REWARD_SELECTION",
  RECENT_DENIED = "CLIENT_RECENT_DENIED",
  AUTH_USER_INVITE = "CLIENT_AUTH_USER_INVITE",
  NOT_IN_WAITLIST_QUEUE = "CLIENT_NOT_IN_WAITLIST_QUEUE",
  NOT_IN_US = "CLIENT_NOT_IN_US",
  APPLICATIONS_CLOSED = "CLIENT_APPLICATIONS_CLOSED",
  FULL_TIER_REQUIRED = "CLIENT_FULL_TIER_REQUIRED",
  MAX_APPLICATION_LIMIT = "CLIENT_MAX_APPLICATION_LIMIT",
  INELIGIBLE_TO_APPLY = "CLIENT_INELIGIBLE_TO_APPLY",
  GENERIC_ERROR = "CLIENT_GENERIC_ERROR",
  UPLOAD_DOCUMENTS = "CLIENT_UPLOAD_DOCUMENTS",
  PREQUAL_DENIED = "CLIENT_PREQUAL_DENIED", // before soft pull users can be denied if they have a lockout on their account
}

export type ClientViews = ClientState | ApplicationStatusStateStateEnum;

export const FINAL = {
  type: "final",
} as const;

type UserEvent =
  | XStateEvent
  | { type: ApplicationStatusStateStateEnum.SoftPullApproved }
  | { type: ApplicationStatusStateStateEnum.HardPullApproved }
  | { type: ApplicationStatusStateStateEnum.OfferExtended }
  | { type: ApplicationStatusStateStateEnum.CardDesignSelected }
  | { type: ApplicationStatusStateStateEnum.SoftPullDenied }
  | { type: ApplicationStatusStateStateEnum.HardPullDenied }
  | { type: ApplicationStatusStateStateEnum.SsnBlocklisted }
  | { type: ApplicationStatusStateStateEnum.SoftPullFrozen }
  | { type: ApplicationStatusStateStateEnum.HardPullFrozen }
  | { type: ApplicationStatusStateStateEnum.SoftPullDeceased }
  | { type: ApplicationStatusStateStateEnum.KycMismatch }
  | { type: ApplicationStatusStateStateEnum.KycFailed }
  | { type: ApplicationStatusStateStateEnum.HardPullDeceased }
  | { type: ApplicationStatusStateStateEnum.MoreInfo }
  | { type: ApplicationStatusStateStateEnum.OfacDenied }
  | { type: ApplicationStatusStateStateEnum.KycManualReview }
  | { type: ApplicationStatusStateStateEnum.SoftPullServiceFailure }
  | { type: ApplicationStatusStateStateEnum.HardPullServiceFailure }
  | { type: ApplicationStatusStateStateEnum.KycServiceFailure }
  | { type: ApplicationStatusStateStateEnum.BiometricDenied }
  | { type: ApplicationStatusStateStateEnum.BiometricManual }
  | { type: ApplicationStatusStateStateEnum.BiometricPassed }
  | { type: ApplicationStatusStateStateEnum.PhoneVerificationFailed }
  | { type: ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate }
  | { type: ApplicationStatusStateStateEnum.PhoneTrustScoreFailed }
  | { type: ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate }
  | { type: ApplicationStatusStateStateEnum.PhoneTrustScoreVerified };

const renderWithAction = (target: ClientViews) => ({ target, actions: ["setUserData"] });

const softPullResult = {
  [ApplicationStatusStateStateEnum.SoftPullApproved]: renderWithAction(
    ApplicationStatusStateStateEnum.SoftPullApproved
  ),
  [ApplicationStatusStateStateEnum.SoftPullDenied]: ApplicationStatusStateStateEnum.SoftPullDenied,
  [ApplicationStatusStateStateEnum.SoftPullDeceased]: ApplicationStatusStateStateEnum.SoftPullDeceased,
  [ApplicationStatusStateStateEnum.SoftPullFrozen]: ApplicationStatusStateStateEnum.SoftPullFrozen,
  [ApplicationStatusStateStateEnum.MoreInfo]: ApplicationStatusStateStateEnum.MoreInfo,
  [ApplicationStatusStateStateEnum.OfacDenied]: ApplicationStatusStateStateEnum.OfacDenied,
  [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: ApplicationStatusStateStateEnum.SoftPullServiceFailure,
  [ApplicationStatusStateStateEnum.SsnBlocklisted]: ApplicationStatusStateStateEnum.SsnBlocklisted,
  [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]:
    ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert,
  [ApplicationStatusStateStateEnum.KycMismatch]: ApplicationStatusStateStateEnum.KycMismatch,
  [ApplicationStatusStateStateEnum.KycFailed]: ApplicationStatusStateStateEnum.KycFailed,
  [ApplicationStatusStateStateEnum.KycManualReview]: ApplicationStatusStateStateEnum.KycManualReview,
  [ApplicationStatusStateStateEnum.KycServiceFailure]: ApplicationStatusStateStateEnum.KycServiceFailure,
  [ClientState.PREQUAL_DENIED]: renderWithAction(ClientState.PREQUAL_DENIED),
  [ClientState.RECENT_DENIED]: ClientState.RECENT_DENIED,
  [ClientState.GENERIC_ERROR]: ClientState.GENERIC_ERROR,
};

const hardPullResult = {
  [ApplicationStatusStateStateEnum.KycMismatch]: ApplicationStatusStateStateEnum.KycMismatch,
  [ApplicationStatusStateStateEnum.KycFailed]: ApplicationStatusStateStateEnum.KycFailed,
  [ApplicationStatusStateStateEnum.HardPullDenied]: ApplicationStatusStateStateEnum.HardPullDenied,
  [ApplicationStatusStateStateEnum.HardPullDeceased]: ApplicationStatusStateStateEnum.HardPullDeceased,
  [ApplicationStatusStateStateEnum.HardPullFrozen]: ApplicationStatusStateStateEnum.HardPullFrozen,
  [ApplicationStatusStateStateEnum.HardPullApproved]: ApplicationStatusStateStateEnum.HardPullApproved,
  [ApplicationStatusStateStateEnum.OfferExtended]: renderWithAction(ApplicationStatusStateStateEnum.OfferExtended),
  [ApplicationStatusStateStateEnum.SsnBlocklisted]: ApplicationStatusStateStateEnum.SsnBlocklisted,
  [ApplicationStatusStateStateEnum.MoreInfo]: ApplicationStatusStateStateEnum.MoreInfo,
  [ApplicationStatusStateStateEnum.OfacDenied]: ApplicationStatusStateStateEnum.OfacDenied,
  [ApplicationStatusStateStateEnum.KycManualReview]: ApplicationStatusStateStateEnum.KycManualReview,
  [ApplicationStatusStateStateEnum.HardPullServiceFailure]: ApplicationStatusStateStateEnum.HardPullServiceFailure,
  [ApplicationStatusStateStateEnum.KycServiceFailure]: ApplicationStatusStateStateEnum.KycServiceFailure,
  [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]:
    ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert,
  [ClientState.GENERIC_ERROR]: ClientState.GENERIC_ERROR,
};

export const creditAppMachine = createMachine<UserData, UserEvent>(
  {
    predictableActionArguments: true,
    id: "CryptoBack",
    initial: ClientState.INITIALIZING,
    states: {
      [ClientState.INITIALIZING]: {
        entry: "getInitialState",
        on: {
          [ClientState.NEW_APPLICATION_V3]: renderWithAction(ClientState.NEW_APPLICATION_V3),
          [ClientState.NOT_IN_US]: ClientState.NOT_IN_US,
          [ClientState.INELIGIBLE_TO_APPLY]: ClientState.INELIGIBLE_TO_APPLY,
          [ClientState.APPLICATIONS_CLOSED]: ClientState.APPLICATIONS_CLOSED,
          [ClientState.GENERIC_ERROR]: ClientState.GENERIC_ERROR,
          [ClientState.MAX_APPLICATION_LIMIT]: ClientState.MAX_APPLICATION_LIMIT,

          // Re-entry flows
          [ClientState.EMAIL_VERIFY_V3]: renderWithAction(ClientState.EMAIL_VERIFY_V3),
          [ClientState.SMS_VERIFY_V3]: renderWithAction(ClientState.SMS_VERIFY_V3),
          [ApplicationStatusStateStateEnum.PhoneVerificationFailed]:
            ApplicationStatusStateStateEnum.PhoneVerificationFailed,
          [ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneTrustScoreFailed]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneTrustScoreVerified]: renderWithAction(ClientState.PREFILL_SUCCESS),
          [ClientState.PREFILL_SUCCESS]: renderWithAction(ClientState.PREFILL_SUCCESS),
          [ClientState.MANUAL_ENTRY]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ClientState.UPLOAD_DOCUMENTS]: renderWithAction(ClientState.UPLOAD_DOCUMENTS),
          [ApplicationStatusStateStateEnum.BiometricPassed]: renderWithAction(ClientState.UPLOAD_DOCUMENTS),
          [ApplicationStatusStateStateEnum.SoftPullApproved]: renderWithAction(
            ApplicationStatusStateStateEnum.SoftPullApproved
          ),
          [ApplicationStatusStateStateEnum.HardPullApproved]: renderWithAction(
            ApplicationStatusStateStateEnum.HardPullApproved
          ),
          [ApplicationStatusStateStateEnum.OfferExtended]: renderWithAction(
            ApplicationStatusStateStateEnum.OfferExtended
          ),
          [ApplicationStatusStateStateEnum.CardDesignSelected]: renderWithAction(ClientState.REWARD_SELECTION),

          // Rejection states
          [ApplicationStatusStateStateEnum.SoftPullDenied]: ApplicationStatusStateStateEnum.SoftPullDenied,
          [ApplicationStatusStateStateEnum.HardPullDenied]: ApplicationStatusStateStateEnum.HardPullDenied,
          [ApplicationStatusStateStateEnum.SsnBlocklisted]: ApplicationStatusStateStateEnum.SsnBlocklisted,

          // Frozen, resume previous step immediately
          [ApplicationStatusStateStateEnum.SoftPullFrozen]: ApplicationStatusStateStateEnum.SoftPullFrozen,
          [ApplicationStatusStateStateEnum.HardPullFrozen]: ApplicationStatusStateStateEnum.HardPullFrozen,

          // KYC fails, currently no way to resolve
          [ApplicationStatusStateStateEnum.SoftPullDeceased]: ApplicationStatusStateStateEnum.SoftPullDeceased,
          [ApplicationStatusStateStateEnum.KycMismatch]: ApplicationStatusStateStateEnum.KycMismatch,
          [ApplicationStatusStateStateEnum.KycFailed]: ApplicationStatusStateStateEnum.KycFailed,
          [ApplicationStatusStateStateEnum.HardPullDeceased]: ApplicationStatusStateStateEnum.HardPullDeceased,
          [ApplicationStatusStateStateEnum.MoreInfo]: ApplicationStatusStateStateEnum.MoreInfo,
          [ApplicationStatusStateStateEnum.OfacDenied]: ApplicationStatusStateStateEnum.OfacDenied,
          [ApplicationStatusStateStateEnum.KycManualReview]: ApplicationStatusStateStateEnum.KycManualReview,
          [ApplicationStatusStateStateEnum.SoftPullServiceFailure]:
            ApplicationStatusStateStateEnum.SoftPullServiceFailure,
          [ApplicationStatusStateStateEnum.HardPullServiceFailure]:
            ApplicationStatusStateStateEnum.HardPullServiceFailure,
          [ApplicationStatusStateStateEnum.KycServiceFailure]: ApplicationStatusStateStateEnum.KycServiceFailure,
          [ApplicationStatusStateStateEnum.BiometricManual]: ApplicationStatusStateStateEnum.BiometricDenied,
          [ApplicationStatusStateStateEnum.BiometricDenied]: ApplicationStatusStateStateEnum.BiometricDenied,
          [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]:
            ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert,
        },
      },
      [ClientState.NEW_APPLICATION_V3]: {
        on: {
          [ClientState.EMAIL_VERIFY_V3]: renderWithAction(ClientState.EMAIL_VERIFY_V3),
          [ClientState.SMS_VERIFY_V3]: renderWithAction(ClientState.SMS_VERIFY_V3),
        },
      },
      [ClientState.EMAIL_VERIFY_V3]: {
        on: {
          [ClientState.SMS_VERIFY_V3]: renderWithAction(ClientState.SMS_VERIFY_V3),
        },
      },
      [ClientState.SMS_VERIFY_V3]: {
        on: {
          [ClientState.PREFILL_SUCCESS]: renderWithAction(ClientState.PREFILL_SUCCESS),
          [ClientState.MANUAL_ENTRY]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneVerificationFailed]: [
            ApplicationStatusStateStateEnum.PhoneVerificationFailed,
          ],
          [ApplicationStatusStateStateEnum.PhoneVerificationIndeterminate]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneTrustScoreFailed]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneTrustScoreIndeterminate]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ApplicationStatusStateStateEnum.PhoneTrustScoreVerified]: renderWithAction(ClientState.PREFILL_SUCCESS),
        },
      },
      [ApplicationStatusStateStateEnum.PhoneVerificationFailed]: FINAL,
      [ClientState.PREFILL_SUCCESS]: {
        on: {
          [ClientState.PREFILL_SUCCESS]: renderWithAction(ClientState.PREFILL_SUCCESS),
          [ClientState.MANUAL_ENTRY]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ClientState.UPLOAD_DOCUMENTS]: renderWithAction(ClientState.UPLOAD_DOCUMENTS),
          ...softPullResult,
        },
      },
      [ClientState.MANUAL_ENTRY]: {
        on: {
          [ClientState.MANUAL_ENTRY]: renderWithAction(ClientState.MANUAL_ENTRY),
          [ClientState.UPLOAD_DOCUMENTS]: renderWithAction(ClientState.UPLOAD_DOCUMENTS),
          ...softPullResult,
        },
      },
      [ClientState.UPLOAD_DOCUMENTS]: {
        on: {
          [ClientState.MANUAL_ENTRY]: renderWithAction(ClientState.MANUAL_ENTRY),
          ...softPullResult,
        },
      },
      [ApplicationStatusStateStateEnum.SoftPullApproved]: {
        on: {
          [ClientState.ENTER_INCOME]: ClientState.ENTER_INCOME,
        },
      },
      [ClientState.ENTER_INCOME]: {
        on: {
          [ClientState.REVIEW_TERMS]: renderWithAction(ClientState.REVIEW_TERMS),
        },
      },
      [ClientState.REVIEW_TERMS]: {
        on: {
          ...hardPullResult,
        },
      },
      [ApplicationStatusStateStateEnum.HardPullApproved]: {
        on: {
          [ClientState.CARD_SELECTION]: renderWithAction(ClientState.CARD_SELECTION),
        },
      },
      [ApplicationStatusStateStateEnum.OfferExtended]: {
        on: {
          [ClientState.CARD_SELECTION]: renderWithAction(ClientState.CARD_SELECTION),
        },
      },
      [ClientState.CARD_SELECTION]: {
        on: {
          [ClientState.REWARD_SELECTION]: renderWithAction(ClientState.REWARD_SELECTION),
        },
      },
      [ClientState.REWARD_SELECTION]: {
        on: {
          [ClientState.AUTH_USER_INVITE]: renderWithAction(ClientState.AUTH_USER_INVITE),
        },
      },
      [ClientState.AUTH_USER_INVITE]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullDenied]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullDenied]: FINAL,
      [ApplicationStatusStateStateEnum.SsnBlocklisted]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullFrozen]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullDeceased]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullFrozen]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullDeceased]: FINAL,
      [ApplicationStatusStateStateEnum.MoreInfo]: FINAL,
      [ApplicationStatusStateStateEnum.OfacDenied]: FINAL,
      [ApplicationStatusStateStateEnum.KycManualReview]: FINAL,
      [ApplicationStatusStateStateEnum.KycMismatch]: FINAL,
      [ApplicationStatusStateStateEnum.KycFailed]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: FINAL,
      [ApplicationStatusStateStateEnum.HardPullServiceFailure]: FINAL,
      [ApplicationStatusStateStateEnum.KycServiceFailure]: FINAL,
      [ApplicationStatusStateStateEnum.BiometricDenied]: FINAL,
      [ApplicationStatusStateStateEnum.SoftPullExtendedFraudAlert]: FINAL,

      [ClientState.RECENT_DENIED]: FINAL,
      [ClientState.NOT_IN_WAITLIST_QUEUE]: FINAL,
      [ClientState.NOT_IN_US]: FINAL,
      [ClientState.APPLICATIONS_CLOSED]: FINAL,
      [ClientState.FULL_TIER_REQUIRED]: FINAL,
      [ClientState.MAX_APPLICATION_LIMIT]: FINAL,
      [ClientState.INELIGIBLE_TO_APPLY]: FINAL,
      [ClientState.GENERIC_ERROR]: FINAL,
      [ClientState.PREQUAL_DENIED]: {
        entry: async ({ appId }, { shouldTriggerAAN = false }: XStateEvent) => {
          trackGoogleAnalyticsEvent(CreditAnalyticsEvents.CompletePrequal, {
            eventLabel: CreditAnalyticsEventLabels.Denied,
          });

          if (shouldTriggerAAN) {
            try {
              await applicationApi.markKycDenied({ applicationId: appId });
            } catch (error) {
              Sentry.captureException(error);
            }
          }
        },
        type: "final",
      },
    },
  },
  {
    actions: {
      setUserData: assign<UserData, XStateEvent>({
        appData: (context, event) => ({
          ...context.appData,
          ...event.appData,
        }),
        appId: (context, event) => context.appId || event.appId,
        offerId: (context, event) => context.offerId || event.offerId,
        isNewUser: (context, event) => context.isNewUser || event.isNewUser,
        statusHistory: (context, event) => context.statusHistory || event.statusHistory,
        socureId: (context, event) => event.socureId || context.socureId,
      }),
    },
  }
);
