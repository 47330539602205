import { CONSENTED_FORMS } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";

export const useGrowAvailability = () => {
  const {
    templateProps: {
      user: { countryCode },
      eligibilityInfo,
    },
  } = usePageData();

  const isUK = countryCode.toLowerCase() === "gb";
  const isIE = countryCode.toLowerCase() === "ie";

  const agreementRequirements = () => {
    const requiresEarnAgreement = !eligibilityInfo?.consentFormsAgreed?.includes(
      CONSENTED_FORMS.EARN_TERMS_AND_CONDITIONS
    );
    const requiresMasterLoanAgreement = !eligibilityInfo?.consentFormsAgreed?.includes(
      CONSENTED_FORMS.EARN_MASTER_LOAN_AGREEMENT
    );

    /**
     * This can be used to determine if the user is a first time user but should
     not be used to check if the user needs to sign an agreement for the time being,
     b/c agreements are based on a providerId. In the future, the providerId
     constraint may be removed and this can be used to determine if the user
     needs to sign an agreement.
     * */
    const requiresStakingAgreement = !eligibilityInfo?.consentFormsAgreed?.includes(CONSENTED_FORMS.STAKING_AGREEMENT);

    const isFirstTimeUser = requiresStakingAgreement || (requiresEarnAgreement && requiresMasterLoanAgreement);

    const providersRequiringStakingAgreement = Object.entries(eligibilityInfo?.byProvider || {}).reduce(
      (acc, [providerId, info]) => {
        if (info?.consentFormsAgreed.includes(CONSENTED_FORMS.STAKING_AGREEMENT)) return acc;
        return [...acc, providerId];
      },
      []
    );

    return {
      providersRequiringStakingAgreement,
      requiresStakingAgreement: isUK && requiresStakingAgreement,
      requiresEarnAgreement,
      requiresMasterLoanAgreement,
      isFirstTimeUser,
    };
  };

  return {
    isUK,
    shouldShowAprDisclaimerBanner: isUK || isIE,
    ...agreementRequirements(),
  };
};
