/* global snaptr */
// prettier-ignore
export const initializeSnap = (email, pixelId) => {
  !(function (e, t, n) {
    if (e.snaptr) return;
    const a = (e.snaptr = function () {
      a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
    });
    a.queue = [];
    const s = "script";
    const r = t.createElement(s);
    r.async = !0;
    r.src = n;
    const u = t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r, u);
  })(window, document, "https://sc-static.net/scevent.min.js");

  const snapProps = email ? { user_email: email } : undefined;
  snaptr("init", pixelId, snapProps);
  snaptr("track", "PAGE_VIEW");
};
