import React from "react";
import IconLogo from "@gemini-ui/images/logo-cyan.svg";

const Mark = props => {
  const { size = 35 } = props;
  const GEMINI = "Gemini";
  return <img src={IconLogo} width={size} alt={GEMINI} />;
};

export default Mark;
