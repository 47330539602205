import React, { LabelHTMLAttributes } from "react";
import styled from "@emotion/styled";
import { InputSize } from "@gemini-ui/design-system/forms/shared/constants";
import { Colors, shorthandSpacingCss, ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";
import { getColor, ScreenReaderOnly } from "@gemini-ui/design-system/utils";

export const HIDDEN_TEST_ID = "hbl-hidden-label";

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement>, ShorthandSpacingCssProps {
  children: React.ReactNode;
  disabled?: boolean;
  hasInlineError?: boolean;
  hasMessage?: boolean;
  isBold?: boolean;
  isInline?: boolean;
  /**
   * If `true`, the label is rendered as a legend html tag
   */
  isLegend?: boolean;
  size?: InputSize;
  /**
   * If `true`, the label is visibly hidden but remains in the accessibility tree.
   */
  visiblyHidden?: boolean;
}

function getSizeStyles(size?: InputSize) {
  if (size === "sm") {
    return `
      font-size: 14px;
      line-height: 20px;
    `;
  }
  if (size === "md") {
    return `
      font-size: 16px;
      line-height: 24px;
    `;
  }
}
/**
 * The inline Label is also an implicit label which means it wraps the input and message.
 * As a result it provides layout styles as described below.
 */
function getInlineGridAreas(hasError?: boolean, hasMessage?: boolean) {
  if (hasError)
    return `
      grid-template-areas: "input label"
                           "message message";
    `;

  if (hasMessage)
    return `
      grid-template-areas: "input label"
                           "input message";
    `;

  return `grid-template-areas: "input label";`;
}

export const StyledLabel = styled.label<LabelProps>`
  color: ${({ disabled, theme }) =>
    disabled
      ? getColor(Colors.gray[300], Colors.gray[600])({ theme })
      : getColor(Colors.black, Colors.white)({ theme })};
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};

  ${({ size }) => getSizeStyles(size)};
  ${({ hasInlineError, hasMessage, isInline }) =>
    isInline &&
    `
    display: grid;
    grid-template-columns: min-content 1fr;
    ${getInlineGridAreas(hasInlineError, hasMessage)}
    
    > div:nth-of-type(1) {
      grid-area: input;
    }

    > div:nth-of-type(2) {
      grid-area: label;
    }

    > div:nth-of-type(3) {
      grid-area: message;
    }
  `}

  ${shorthandSpacingCss}
`;

export function Label({ isBold = true, children, isLegend, size = "sm", visiblyHidden, ...props }: LabelProps) {
  const labelEl = (
    <StyledLabel as={isLegend ? "legend" : null} isBold={isBold} size={size} {...props}>
      {children}
    </StyledLabel>
  );

  return visiblyHidden ? <ScreenReaderOnly data-testid={HIDDEN_TEST_ID}>{labelEl}</ScreenReaderOnly> : labelEl;
}
