import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";

interface LocationProps {
  address: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}

interface WireDepositEntityProps<T> {
  title: string;
  name: string;
  location?: T;
  swift?: Swift;
  routedViaSwift?: Swift;
}

type Swift = {
  id: string;
  bankCode: string;
  countryCode: string;
  locationCode: string;
  branchCode?: string;
};

type RoutingNumber = {
  id: string;
};

export interface WireTransferInfoAPIPayload {
  amount?: {
    amount: MoneyProps;
  };
  currency: CurrencyShortNameFiat;
  internationalBankInfo?: WireDepositBankInfo;
  domesticBankInfo?: WireDepositBankInfo;
  reference: string;
  isInternational: boolean;
  isInstitutional: boolean;
}

export interface XfersTransferInfoAPIPayload {
  currency: CurrencyShortNameFiat;
  internationalBankInfo: XfersTransferBankPayload;
}

export interface XfersTransferBankPayload {
  beneficiary: XfersBeneficiaryProps;
  currency: CurrencyShortNameFiat;
}

export interface XfersBeneficiaryProps {
  name: string;
  bankName: string;
  accountNumber: string;
}

interface RtpTransferBankPayload {
  beneficiary: RtpBeneficiaryProps;
  currency: CurrencyShortNameFiat;
}

export interface RtpTransferInfoAPIPayload {
  currency: CurrencyShortNameFiat;
  internationalBankInfo: RtpTransferBankPayload;
}

interface RtpBeneficiaryProps {
  name: string;
  bankName: string;
  accountNumber: string;
}

interface WireDepositBankInfo {
  receivingBank: WireBankPartnerInfoProps;
  beneficiary: WireBeneficiaryProps;
  intermediary?: WireDepositEntityProps<string>;
}

interface WireBankPartnerInfoProps {
  bankPartner: string;
  name: string;
  location: LocationProps;
  routingNumber?: RoutingNumber | string;
  swift?: Swift;
  attn?: string;
}

interface WireBeneficiaryProps {
  name: string;
  location: LocationProps;
  accountNumber: string;
  bankName?: string;
  bankCode?: string;
  parsedIBAN?: { iban: string };
  sortCode?: string;
}

const UNITED_STATES = "United States";

const bankCountryMap = {
  SWITZERLAND: "Switzerland",
  "UNITED KINGDOM": "United Kingdom",
  "UNITED STATES": UNITED_STATES,
  "United States Of America": UNITED_STATES,
  "UNITED STATES OF AMERICA": UNITED_STATES,
  US: UNITED_STATES,
} as const;

export const formatCountryString = (country: string): string => {
  return bankCountryMap[country] || country;
};

export interface CbitTransferInfoAPIPayload {
  publicDescription: string;
  walletAddress: string;
  walletCurrency: string;
  walletName: string;
  firmName: string;
}
