import React from "react";
import { BalanceIcon } from "@gemini-ui/components/Icons/PaymentMethod/BalanceIcon";
import { BankIcon } from "@gemini-ui/components/Icons/PaymentMethod/BankIcon";
import { BcoloIcon } from "@gemini-ui/components/Icons/PaymentMethod/BcoloIcon";
import { CardIcon } from "@gemini-ui/components/Icons/PaymentMethod/CardIcon";
import {
  InstitutionIconProps,
  PaymentMethodIconsProps,
  wireMatchedNameToPlaidInstitutionMap,
} from "@gemini-ui/components/Icons/PaymentMethod/constants";
import { GenericIcon } from "@gemini-ui/components/Icons/PaymentMethod/GenericIcon";
import { PayPalIcon } from "@gemini-ui/components/Icons/PaymentMethod/PaypalIcon";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { ReactComponent as CustomersBankIcon } from "@gemini-ui/images/icons/customers-bank_logo.svg";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { institutionsArray } from "@gemini-ui/vendor/plaid/institutions";

export const getPlaidInstitutionFromName = (name: string): InstitutionIconProps => {
  return (
    wireMatchedNameToPlaidInstitutionMap[name] ||
    institutionsArray.find(ins => name.toLowerCase() === ins.name.toLowerCase())
  );
};

export function PaymentMethodIcon({
  defaultFiat,
  paymentMethodType,
  institutionName,
  cardIssuer,
  ...props
}: PaymentMethodIconsProps) {
  switch (paymentMethodType) {
    case PaymentMethodType.BCOLO: {
      return <BcoloIcon {...props} />;
    }
    case PaymentMethodType.DEBIT_CARD: {
      return <CardIcon cardIssuer={cardIssuer} {...props} />;
    }
    case PaymentMethodType.BANK:
    case AccountType.BALANCE: {
      const institution = getPlaidInstitutionFromName(institutionName || "");
      if (institution) {
        return <BankIcon institution={institution} {...props} />;
      }

      if (defaultFiat) {
        return <BalanceIcon defaultFiat={defaultFiat} {...props} />;
      }

      return <GenericIcon {...props} />;
    }
    case PaymentMethodType.PAYPAL: {
      return <PayPalIcon {...props} />;
    }
    case PaymentMethodType.CBIT: {
      return <CustomersBankIcon />;
    }
    default: {
      return <GenericIcon {...props} />;
    }
  }
}
