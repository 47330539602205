import styled from "@emotion/styled";
import { Flex, ListItem, mediaQuery, Spacing } from "@gemini-ui/design-system";

export const PaymentMethodDescriptionContainer = styled.ul`
  list-style: none;
  max-width: 320px;
`;

export const AddPaymentMethodInnerContainer = styled(Flex)`
  flex: 1;
  @media ${mediaQuery.tabletMdUp} {
    button {
      align-self: center;
    }
    a {
      align-self: center;
      display: inline-flex;
    }
  }
  ${PaymentMethodDescriptionContainer} {
    margin: ${Spacing.scale[0.5]} ${Spacing.scale[1]} ${Spacing.scale[1.5]} ${Spacing.scale[1.5]};
    @media ${mediaQuery.tabletMdUp} {
      margin: 0 auto 0 ${Spacing.scale[1]};
    }
  }
`;

export const AddPaymentMethodTitle = styled.div`
  flex-basis: 30%;
`;

export const StyledListItem = styled(ListItem)<any>`
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;
