import { IntlShape } from "react-intl";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";

const getTransferMechanismText = (intl: IntlShape) => {
  return {
    FAST: intl.formatMessage({
      defaultMessage: "FAST",
      description: "Acronym for a payment system used in Singapore",
    }),
    BANK_TRANSFER_FASTER: intl.formatMessage({
      defaultMessage: "Bank transfer (Faster Payments)",
      description: "Type of payments used in the UK",
    }),
    BANK_TRANSFER_FASTER_CHAPS: intl.formatMessage({ defaultMessage: "Bank transfer (Faster Payments, CHAPS)" }),
    BANK_TRANSFER_SEPA: intl.formatMessage({ defaultMessage: "Bank transfer (SEPA)" }),
    SAME_DAY_WIRE: intl.formatMessage({ defaultMessage: "Same-day wire transfer" }),
    WIRE: intl.formatMessage({ defaultMessage: "Wire transfer" }),
    BANK_TRANSFER: intl.formatMessage({ defaultMessage: "Bank transfer" }),
  };
};

export function formatTransferMechanismText(
  currency: CurrencyShortNameFiat,
  isSGD: Boolean,
  geminiEntity: GeminiEntity,
  intl: IntlShape
): string {
  if (isSGD) return getTransferMechanismText(intl).FAST;
  if (currency === CURRENCIES_DETAIL.GBP.symbol) {
    if (geminiEntity === "GeminiPayments") {
      return getTransferMechanismText(intl).BANK_TRANSFER_FASTER;
    }
    return getTransferMechanismText(intl).BANK_TRANSFER_FASTER_CHAPS;
  } else if (currency === CURRENCIES_DETAIL.EUR.symbol) {
    return getTransferMechanismText(intl).BANK_TRANSFER_SEPA;
  } else if (currency === CURRENCIES_DETAIL.USD.symbol) {
    return getTransferMechanismText(intl).SAME_DAY_WIRE;
  } else if (
    ([CURRENCIES_DETAIL.HKD.symbol, CURRENCIES_DETAIL.AUD.symbol, CURRENCIES_DETAIL.CAD.symbol] as const).includes(
      currency
    )
  ) {
    return getTransferMechanismText(intl).WIRE;
  }

  return getTransferMechanismText(intl).BANK_TRANSFER;
}

const getCurrencyConversionText = (intl: IntlShape) => {
  return {
    UNSUPPORTED: intl.formatMessage({
      defaultMessage:
        "Deposits in other currencies will be returned at your expense, and may take up to 10 business days to process.",
    }),
    SUPPORTED: intl.formatMessage({
      defaultMessage: "Deposits in other currencies will be automatically converted at market rates.",
    }),
  };
};

const currencyConversionMap = {
  AUD: false,
  CAD: false,
  CHF: true,
  COP: false,
  EUR: true,
  GBP: false,
  HKD: false,
  JPY: true,
  SGD: false,
  USD: false,
} as const;

const canConvertCurrency = (currency: CurrencyShortNameFiat) => {
  return Boolean(currencyConversionMap[currency]);
};

export const formatCurrencyWarningText = (currency: CurrencyShortNameFiat, intl: IntlShape) => {
  return canConvertCurrency(currency)
    ? getCurrencyConversionText(intl).SUPPORTED
    : getCurrencyConversionText(intl).UNSUPPORTED;
};
