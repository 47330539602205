import React from "react";
import { Underlined } from "@gemini-ui/design-system/FileUploader/styles";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const getLocalizedHubbleStrings = (intl: IntlShape) => ({
  CardCollapsible: {
    toggle: intl.formatMessage({ defaultMessage: "Toggle collapsed content" }),
  },
  InputPassword: {
    showPassword: intl.formatMessage({ defaultMessage: "Show password text" }),
    hidePassword: intl.formatMessage({ defaultMessage: "Hide password text" }),
  },
  Select: {
    noResults: intl.formatMessage({ defaultMessage: "No results found." }),
    selectOption: intl.formatMessage({ defaultMessage: "Select option" }),
  },
  Modal: {
    backButton: intl.formatMessage({ defaultMessage: "Go back modal button" }),
    closeButton: intl.formatMessage({ defaultMessage: "Close modal button" }),
  },
  SearchInput: {
    defaultLabel: intl.formatMessage({ defaultMessage: "Search" }),
    clearButton: intl.formatMessage({ defaultMessage: "Remove value" }),
  },
  SectionMessage: {
    errorsFound: intl.formatMessage({ defaultMessage: "Errors found" }),
    dismiss: intl.formatMessage({ defaultMessage: "Dismiss" }),
  },
  SystemAlert: {
    dismiss: intl.formatMessage({ defaultMessage: "Dismiss" }),
  },
  Table: {
    previousPage: intl.formatMessage({ defaultMessage: "Previous page" }),
    nextPage: intl.formatMessage({ defaultMessage: "Next page" }),
    pageCount: ({ currentPage, pageCount }) =>
      intl.formatMessage(defineMessage({ defaultMessage: "{currentPage} of {pageCount}" }), { currentPage, pageCount }),
  },
  Timeline: {
    alert: intl.formatMessage({ defaultMessage: "Alert" }),
    inProgress: intl.formatMessage({ defaultMessage: "In progress" }),
    success: intl.formatMessage({ defaultMessage: "Success" }),
  },
  Toast: {
    close: intl.formatMessage({ defaultMessage: "Close toast" }),
  },
  FileUploader: {
    browseFiles: intl.formatMessage({ defaultMessage: "Browse files to upload" }),
    cancelUpload: intl.formatMessage({ defaultMessage: "Cancel upload" }),
    dragAndDrop: disabled =>
      intl.formatMessage(
        defineMessage({ defaultMessage: "Drag and drop or <underlined>browse files</underlined> to upload" }),
        {
          underlined: (str: React.ReactNode) => (
            <Underlined color={disabled && "inherit"} as="span">
              {str}
            </Underlined>
          ),
        }
      ),
    fileExists: fileName =>
      intl.formatMessage(defineMessage({ defaultMessage: "File {fileName} already exists." }), { fileName }),
    maxFilesReached: maxAmount =>
      intl.formatMessage(defineMessage({ defaultMessage: "You reached the maximum of {maxAmount} files." }), {
        maxAmount,
      }),
    maxPolicySize: intl.formatMessage({ defaultMessage: "File cannot exceed 15MB by Policy." }),
    maxSize: maxSize =>
      intl.formatMessage(defineMessage({ defaultMessage: "File cannot exceed {maxSize}." }), { maxSize }),
    noFilesUploaded: intl.formatMessage({ defaultMessage: "No files uploaded" }),
    removeFile: intl.formatMessage({ defaultMessage: "Remove file" }),
    retryUpload: intl.formatMessage({ defaultMessage: "Retry upload file" }),
    uploadCancelled: intl.formatMessage({ defaultMessage: "Upload canceled by the user." }),
    uploadFailed: intl.formatMessage({ defaultMessage: "Upload failed. Please try again." }),
  },
});
