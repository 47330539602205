import styled from "@emotion/styled";
import { border, Spacer, Spacing } from "@gemini-ui/design-system/primitives";
import { SectionMessageProps } from "@gemini-ui/design-system/SectionMessage";

const STATUS_THEME_MAP = {
  info: "informational",
  success: "positive",
  warning: "warning",
  alert: "negative",
  reward: "reward",
};

export const SectionMessageWrapper = styled(Spacer)<{ statusType: SectionMessageProps["statusType"] }>`
  background-color: ${({ statusType, theme }) =>
    theme.colorScheme.status.default.background[STATUS_THEME_MAP[statusType]]};
  border-radius: ${border.radius.lg};
  color: ${({ theme }) => theme.colorScheme.content.primary};
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: ${Spacing.scale[1.5]};
  padding: ${Spacing.scale[2]};
`;
