import debugFactory from "debug";
const debug = debugFactory("gemini:Analytics");

const baseStyles = ["padding: 5px 10px", "color: white", "display: block", "width: 100%"];
const eventStyles: string = ["background: #2B2B3C", ...baseStyles].join(";");
const eventAttrsStyles = ["background: #F3F3F3", "padding: 10px", "color: black", "display: block"].join(";");

export const mockLog = (eventType: string, eventName: string, eventAttrs: {}, backgroundColor = "#32ADC0") => {
  const styles = [eventStyles];
  let eventDisplayName = "";

  if (eventName) {
    eventDisplayName = `%c${eventName}`;
    const eventNameStyles: string = [`background: ${backgroundColor}`, ...baseStyles].join(";");
    styles.push(eventNameStyles);
  }
  debug(`%c${eventType}:${eventDisplayName}`, ...styles);
  if (eventAttrs) {
    debug(`%c${JSON.stringify(eventAttrs, null, 2)}`, eventAttrsStyles);
  }
};
