import styled from "@emotion/styled";
import { mediaQuery, Text } from "@gemini-ui/design-system";

export const TruncatedText = styled(Text.Body)`
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${mediaQuery.mobileXsDown} {
    width: 100px;
  }
`;
