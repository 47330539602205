import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { SupportedCurrencyPairs } from "@gemini-common/scripts/constants/currencies";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

export type RecurringOrderFormData = {
  achInfo: {
    uuid: string;
    displayName: string;
    usdBalance: string;
    achDayLimit: string;
    achThirtyDayLimit: string;
  };
  supportedPairs: SupportedCurrencyPairs[];
};

export const getRecurringOrderData = async (): Promise<RecurringOrderFormData> => {
  const url = jsRoutes.controllers.retail.RecurringOrdersController.formData().url;
  return await axios
    .get(url)
    .then(res => res.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useRecurringOrderData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<RecurringOrderFormData | null>(null);

  const fetchRecurringOrderData = useCallback(async () => {
    try {
      setLoading(true);
      const formData = await getRecurringOrderData();
      setData(formData);
      setError(null);

      // Returning the data and setting the data to local state is redundant but may be helpful depending on how this function is called.
      return formData;
    } catch (e) {
      const error = getError(e);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    fetchRecurringOrderData,
    loading,
    error,
  };
};
