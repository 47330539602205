import { IntlShape } from "react-intl";
import { DropdownProps, GroupedDropdownItemProps } from "@gemini-ui/design-system/Dropdown/constants";

export const getDisplayLabel = (
  selectedLabel: React.ReactNode,
  label: React.ReactNode,
  context: string
): React.ReactNode => {
  if (selectedLabel) {
    if (context === "menu") {
      return label;
    } else {
      return selectedLabel;
    }
  } else {
    return label;
  }
};

export function getIconAltText<ValueType>(label: React.ReactNode, value: ValueType, intl: IntlShape): string {
  if (typeof label === "string") {
    return label;
  } else if (typeof value === "string") {
    return value;
  } else {
    return intl.formatMessage({ defaultMessage: "Dropdown Icon" });
  }
}

export function isGroupedItems<ValueType>(items: DropdownProps<ValueType>["items"]) {
  const firstItem = items[0] as GroupedDropdownItemProps<ValueType>;
  return Array.isArray(firstItem?.options);
}
