import { AxiosError, AxiosResponse } from "axios";
import { CONSENTED_FORMS } from "@gemini-ui/constants/earn";
import axios from "@gemini-ui/services/axios";

export const fetchAgreement = (agreementType: CONSENTED_FORMS, providerId?: string) => {
  const controller = jsRoutes.controllers.account.FeatureConsentController;
  const url = providerId
    ? controller.getAgreementV3(agreementType, providerId).url // preferred new method of getting agreements
    : controller.getAgreementV2(agreementType).url; // legacy, backwards compatible

  return axios
    .get(url)
    .then((response: AxiosResponse) => {
      const { data } = response;
      return data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};
