const listify = (array = [], conjunction = "", lowerCase = true) => {
  if (!array.length) {
    return "-";
  }
  return array.reduce((acc, curr, index) => {
    if (index === 0) {
      acc += `${curr}`;
    } else if (Boolean(conjunction) && index === array.length - 1) {
      acc += ` ${conjunction} ${lowerCase ? curr.toLowerCase() : curr}`;
    } else {
      acc += `, ${lowerCase ? curr.toLowerCase() : curr}`;
    }

    return acc;
  }, "");
};

export default listify;
