import { PlaidLinkOnEventMetadata, PlaidLinkStableEvent } from "react-plaid-link";
import { EVENTS, track } from "@gemini-ui/analytics";
import { SELECT_INSTITUTION } from "@gemini-ui/components/PlaidModal/constants";
import { QueryParams } from "@gemini-ui/constants/queryParams";

export const isPlaidRedirect = () => {
  const params = new URLSearchParams(window.location.search);
  return params.has(QueryParams.OAUTH_STATE_ID);
};

export const trackPlaidSDKEvents = (event: string | PlaidLinkStableEvent, meta: PlaidLinkOnEventMetadata) => {
  const { PLAID_EVENT, SELECTED_PLAID_BANK } = EVENTS;
  if (event === SELECT_INSTITUTION) {
    track(SELECTED_PLAID_BANK.name);
  } else {
    const { properties, name } = PLAID_EVENT;
    track(name, {
      [properties.EVENT_NAME]: event,
      [properties.LINK_SESSION_ID]: meta.link_session_id,
      [properties.TIMESTAMP]: meta.timestamp,
      ...(properties.VIEW_NAME && { [properties.VIEW_NAME]: meta?.view_name }),
      ...(properties.MFA_TYPE && { [properties.MFA_TYPE]: meta?.mfa_type }),
      ...(properties.REQUEST_ID && { [properties.REQUEST_ID]: meta?.request_id }),
      ...(properties.ERROR_CODE && { [properties.ERROR_CODE]: meta?.error_code }),
      ...(properties.ERROR_MESSAGE && { [properties.ERROR_MESSAGE]: meta?.error_message }),
      ...(properties.ERROR_TYPE && { [properties.ERROR_TYPE]: meta?.error_type }),
      ...(properties.EXIT_STATUS && { [properties.EXIT_STATUS]: meta?.exit_status }),
      ...(properties.INSTITUTION_ID && { [properties.INSTITUTION_ID]: meta?.institution_id }),
      ...(properties.INSTITUTION_NAME && { [properties.INSTITUTION_NAME]: meta?.institution_name }),
      ...(properties.INSTITUTION_SEARCH_QUERY && {
        [properties.INSTITUTION_SEARCH_QUERY]: meta?.institution_search_query,
      }),
    });
  }
};
