import {
  FieldValues,
  useForm as useFormCore,
  UseFormProps as UseFormPropsCore,
  UseFormReturn as UseFromReturnCore,
} from "react-hook-form";

// TODO: When we upgrade yup and incorporate yupResolver, we can pass a yup schema directly to this wrapper hook instead of importing it every time
// type UseFormProps<TFieldValues, TContext> = {
//   yupSchema?: Yup.AnyObjectSchema;
// } & Omit<UseFormPropsCore<TFieldValues, TContext>, "resolver">;

export type UseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues = TFieldValues
> = UseFromReturnCore<TFieldValues, TContext, TTransformedValues>;

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues = TFieldValues
>({ ...props }: UseFormPropsCore<TFieldValues, TContext>): UseFormReturn<TFieldValues, TContext, TTransformedValues> {
  const useFormValues = useFormCore<TFieldValues, TContext, TTransformedValues>({
    mode: "onBlur",
    ...props,
  });

  return useFormValues;
}
