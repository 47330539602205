import * as yup from "yup";
import { getPostalCodeRegexes } from "@gemini-common/scripts/constants/Countries";
import { IntlShape } from "@gemini-ui/utils/intl";

/**
 * For testing purposes,
 * a valid Eircode is: D6W 0AC1
 */
export const irelandEircodeSchema = (intl: IntlShape) => {
  const EIRCODE = "eircode";
  return yup.string().test(EIRCODE, intl.formatMessage({ defaultMessage: "Please enter a valid eircode." }), val => {
    if (!val) return true; // Allow blank values
    return getPostalCodeRegexes("ie").test(val.toUpperCase());
  });
};
