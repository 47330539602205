import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system";

export const PaymentMethodButtonError = styled("span")`
  font-size: 11px;
  line-height: 14px;
  margin-bottom: -29px;
  margin-top: 15px;
  color: ${({ theme }) => theme.colorScheme.status.default.content.negative};
`;

export const PaymentMethodButton = styled("button")<{ error?: boolean }>`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background-color: ${Colors.white};
  cursor: pointer;
  border: 1px solid ${p => (p.error ? Colors.red[600] : Colors.gray[600])};
  margin-top: 0;
  padding: ${Spacing.scale[1]} ${Spacing.scale[2]} ${Spacing.scale[1]} ${Spacing.scale[1.5]};
  border-radius: 10px;
`;
