import React from "react";
import { Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

function Error() {
  const { intl } = useIntl();

  return (
    <React.Fragment>
      <Text.Body size="sm" mb={3}>
        {intl.formatMessage({
          defaultMessage: "Please try submitting your attestation again later.",
        })}
      </Text.Body>
    </React.Fragment>
  );
}

export default Error;
