import React from "react";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import { DepositCashModalProps } from "@gemini-ui/components/Transfer/CashDepositFlow/constants";

const CashDepositFlow = LazyModalWrapper(() => import("./index"));

function LazyLoadCashDepositFlow({
  onClose,
  isOpen,
  defaultCurrency,
  subaccountHashid,
  isProtailFromOnboarding,
}: DepositCashModalProps) {
  return (
    <CashDepositFlow
      isOpen={isOpen}
      onClose={onClose}
      defaultCurrency={defaultCurrency}
      subaccountHashid={subaccountHashid}
      isProtailFromOnboarding={isProtailFromOnboarding}
    />
  );
}

export default LazyLoadCashDepositFlow;
