import styled from "@emotion/styled";
import { border, Colors, Flex, mediaQuery, Spacing } from "@gemini-ui/design-system";
import { ReactComponent as GeminiIcon } from "@gemini-ui/images/gemini-logo-cyan.svg";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

export const OuterContainer = styled("div")`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.gray[50]};
`;

export const RegisterContainer = styled("div")`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const InnerContainer = styled("div")`
  flex: 1;
  display: flex;
  background-color: ${Colors.white};
  flex-direction: column;
  width: 635px;
  max-width: 100%;

  @media ${BREAKPOINTS.mobileDown} {
    padding: 0 ${Spacing.scale[3]};
    width: 100%;
  }
`;

export const MainContent = styled("div")`
  margin: auto;
  margin-top: ${Spacing.scale[4]};

  width: 66%;

  @media ${BREAKPOINTS.mobileDown} {
    width: 100%;
  }
`;

export const Logo = styled(GeminiIcon)`
  width: 50px;
  height: 50px;
  margin-bottom: ${Spacing.scale[2.5]};
`;

export const RegisterHeaderLogo = styled(GeminiIcon)`
  width: ${Spacing.scale[5]};
  height: ${Spacing.scale[5]};
  padding: ${Spacing.scale[1]};
`;

export const StyledFooter = styled("footer")`
  text-align: center;
  margin-top: ${Spacing.scale[10]};
  margin-bottom: ${Spacing.scale[6]};
  width: 100%;

  @media ${BREAKPOINTS.mobileDown} {
    font-size: 9px;
    color: ${Colors.gray[500]};

    a {
      &:visited,
      &:focus {
        color: ${Colors.gray[500]};
      }
    }
  }
`;

export const GeminiLogoContainer = styled(Flex)`
  padding: ${Spacing.scale[2]} ${Spacing.scale[0]};
  margin: ${Spacing.scale[0]} ${Spacing.scale[5]};

  @media ${mediaQuery.mobileXsDown} {
    margin: ${Spacing.scale[0]} ${Spacing.scale[2]};
  }
`;

export const ProgressBarContainer = styled("div")<{ size: "sm" | "md" | "lg"; bgColor: string }>`
  height: ${({ size }) => (size === "sm" ? Spacing.scale[0.25] : size === "md" ? "6px" : "16px")};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : Colors.gray[200])};
  border-radius: ${border.radius.full};
`;

export const ProgressBarFiller = styled("div")<{ progressBarCompletion: number; fillColor: string }>`
  height: 100%;
  width: ${({ progressBarCompletion }) => progressBarCompletion}%;
  background-color: ${({ fillColor }) => (fillColor ? fillColor : Colors.black)};
  border-radius: inherit;
`;

export const BackButtonContainer = styled("div")`
  margin-left: ${Spacing.scale[6]};
  display: inline-flex;
  margin-top: ${Spacing.scale[3]};

  @media ${mediaQuery.mobileXsDown} {
    margin-left: ${Spacing.scale[3]};
    margin-top: ${Spacing.scale[1]};
  }
`;
