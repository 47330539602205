export enum CreditError {
  CBReappliedTooSoon = 1003,
  CBTooRecentlySubmitted = 1004, // Recent open aplication, bounce to reentry
  CBAlreadyApproved = 1015, // "This identity has already been approved for this product"
  CBAlreadyRunThisStep = 1021, // "Desired qualification step has already been run"
  CBNotInWaitlistQueue = 1032,
  MaxDailyApplicationLimit = 1033,
  TUserAlreadyExists = 2016,
  VerifyPhoneOrRegister = 2037,
  BankAccountRejected = 2040,
  CardReplacementRequiresCharge = 3021,
  NewIncomeDoesNotMeetRequirement = 3052,
  DuplicatePayment = 3042,
  DuplicateAuthUserInvite = 3068, // "This user already has an active invition."
  MaxAuthUserInvite = 3067, // "You have already invited the maximum number of additional users..."
}
