import * as React from "react";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";
import { BaseText } from "@gemini-ui/design-system/Text/styles";

type LabelVariant = "primary" | "secondary" | "tertiary";
type CustomizableLabelProps = Omit<BaseTextProps, "color" | "italic" | "underline">;
interface LabelProps extends CustomizableLabelProps {
  variant?: LabelVariant;
}

const getLabelColor = (variant: LabelVariant) => {
  switch (variant) {
    case "primary": {
      return Colors.gray[600];
    }
    case "secondary": {
      return Colors.gray[800];
    }
    case "tertiary": {
      return Colors.gray[400];
    }
    default: {
      return Colors.gray[600];
    }
  }
};

const LabelText = React.forwardRef<HTMLParagraphElement, LabelProps>(
  ({ variant = "primary", ...restProps }: LabelProps, ref) => {
    return (
      <BaseText ref={ref} fontSize="12px" lineHeight={Spacing.scale[2]} color={getLabelColor(variant)} {...restProps} />
    );
  }
);

export default LabelText;
