import React from "react";
import ErrorBoundary from "@gemini-ui/components/ErrorBoundary";
import { Flex, Spacing } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import CreditCardPicker3d from "@gemini-ui/images/credit/CreditCardPicker3d";
import { Card3DProps } from "@gemini-ui/pages/Credit/CreditApplication/v2/components/Card3D";

export const Lazy3DCard = (props: Card3DProps) => {
  const [fallbackLoaded, setFallbackLoaded] = React.useState(false);
  const LazyComponent = React.useMemo(() => React.lazy(() => import("./")), []);

  return (
    <ErrorBoundary
      renderFallback={() => {
        return (
          <Flex
            height="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={Spacing.scale["2"]}
          >
            <CreditCardPicker3d
              cardImagesLoaded={fallbackLoaded}
              setCardImagesLoaded={setFallbackLoaded}
              variant={props.cardColor}
              name={props.name}
            />
          </Flex>
        );
      }}
    >
      <React.Suspense
        fallback={
          <Flex height="100%" alignItems="center" justifyContent="center">
            <SpinnerAnimation />
          </Flex>
        }
      >
        <LazyComponent {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
};
