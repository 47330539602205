import styled from "@emotion/styled";
import { CustomStylesConfig } from "@gemini-ui/design-system/Dropdown/constants";
import { FORM_ELEMENT_TEXT_STYLES } from "@gemini-ui/design-system/formElements";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { ReactComponent as Caret } from "@gemini-ui/images/dropdown_caret.svg";

export const ItemIconContainer = styled("div")`
  display: flex;
  width: ${Spacing.scale[5]};
  height: ${Spacing.scale[5]};
  align-items: center;
  margin: ${Spacing.scale[1]} ${Spacing.scale[1.5]} ${Spacing.scale[1]} 0;
  flex-shrink: 0;

  > svg {
    width: ${Spacing.scale[5]};
    height: ${Spacing.scale[5]};
  }
`;

export const CaretIcon = styled(Caret)`
  width: ${Spacing.scale[3]};
  height: ${Spacing.scale[3]};
`;

const getTextStyles = (provided: any = {}) => ({
  ...provided,
  ...FORM_ELEMENT_TEXT_STYLES,
});

const groupHeadingStyles = () => ({
  margin: `0 ${Spacing.scale[2]}`,
});

const groupStyles = () => ({
  "&:not(:first-of-type)": {
    borderTop: `1px solid ${Colors.gray[50]}`,
    paddingTop: Spacing.scale[0.5],
  },
});

const getBorderColor = ({ error, isFocused, isDisabled }) => {
  if (error) {
    return Colors.red[600];
  } else if (isFocused) {
    return Colors.gray[800];
  } else if (isDisabled) {
    return Colors.gray[100];
  } else {
    return Colors.gray[300];
  }
};

const getOptionBgColor = ({ isSelected, isFocused }) => {
  if (isSelected) {
    return Colors.gray[50];
  } else if (isFocused) {
    return Colors.gray[50];
  } else {
    return "transparent";
  }
};

export const dropdownStyles: CustomStylesConfig<any> = {
  control: (provided, state) => {
    const { isFocused, isDisabled, selectProps } = state;
    const { error, variant, readOnly } = selectProps;

    const borderColor = getBorderColor({
      error,
      isFocused,
      isDisabled,
    });

    return {
      ...getTextStyles(provided),
      border: `1px solid ${borderColor}`,
      borderRadius: `${variant === "md" ? Spacing.scale[1] : Spacing.scale[1.5]}`,
      cursor: readOnly ? "default" : "pointer",
      minHeight: variant === "md" ? "56px" : "60px",
      backgroundColor: isDisabled || readOnly ? Colors.gray[50] : Colors.white,
      transition: "border-color, box-shadow 250ms ease-in-out",
      boxShadow: "none",
      "&:hover": {
        borderColor: Colors.gray[600],
      },
      "&:focus-within": {
        outline: "none",
        boxShadow: `0 0 0 1px ${error ? Colors.red[600] : Colors.gray[900]}`,
      },
      "&:active": {
        boxShadow: `0 0 0 1px ${Colors.gray[900]}`,
      },
      "> div ": {
        overflow: "visible",
      },
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      minWidth: 0, // For firefox flex-shrink bug https://stackoverflow.com/questions/33790219/firefox-not-respecting-flex-shrink
    };
  },
  option: (provided, state) => {
    const { isFocused, isSelected, isDisabled } = state;
    return {
      ...getTextStyles(),
      padding: `0 ${Spacing.scale[2]}`,
      cursor: isDisabled ? "default" : "pointer",
      minHeight: Spacing.scale[6],
      opacity: isDisabled ? 0.3 : 1,
      backgroundColor: getOptionBgColor({ isSelected, isFocused }),
      "&:hover": {
        backgroundColor: Colors.gray[50],
      },
    };
  },
  placeholder: (provided: any, state: Record<string, any>) => {
    const { isDisabled } = state;
    return {
      ...provided,
      color: isDisabled ? Colors.gray[200] : Colors.gray[300],
    };
  },
  groupHeading: groupHeadingStyles,
  group: groupStyles,
  singleValue: getTextStyles,
  input: (provided: any, state: Record<string, any>) => {
    const {
      selectProps: { isMulti },
    } = state;
    return {
      ...getTextStyles(provided),
      ...(isMulti && { display: "inline-grid" }),
    };
  },
  noOptionsMessage: getTextStyles,
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "flex",
    padding: Spacing.scale[2],
  }),
  loadingMessage: (provided: any) => ({
    ...getTextStyles(provided),
    color: Colors.gray[200],
    padding: Spacing.scale["1.5"],
  }),
  indicatorsContainer: (provided: any, state: Record<string, any>) => {
    const {
      selectProps: { readOnly },
    } = state;
    return {
      ...provided,
      ...(readOnly && { display: "none" }),
    };
  },
  multiValue: provided => {
    return {
      ...provided,
      borderRadius: Spacing.scale[0.5],
      backgroundColor: Colors.gray[100],
    };
  },
};
