export const TIME = {
  SECONDS: {
    1: 1000,
    5: 5000,
    10: 10000,
  },
  MINUTES: {
    2.5: 180000,
  },
} as const;

export const GENERIC_CARDHOLDER_AGREEMENT = "https://www.gemini.com/legal/cardholder-agreement";
export const CARDHOLDER_AGREEMENT = "/credit-card/cardHolderAgreement";
