/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, useTheme } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports
import { Formik, FormikActions } from "formik";
import * as Yup from "yup";
import { getPostalCodeRegexes, stateRequiredCountries } from "@gemini-common/scripts/constants/Countries";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Button, Checkbox, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input";
import ProgressBar from "@gemini-ui/pages/register/OnboardingLayout/ProgressBar";
import { INPUT_SIZES } from "@gemini-ui/pages/register/Verify/constants";
import FormikAddressForm, { schema } from "@gemini-ui/pages/register/Verify/FormikAddressForm";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { TwoColumnInputContainer } from "@gemini-ui/pages/settings/BankSettings/debit/styles";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

interface FormValues {
  streetAddress?: string;
  apt?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  countryFullName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  setCountryDetails?: (country: string, countryFullName: string) => void;
}
interface AddBillingAddressManualModalProps extends FormValues {
  onToggle: ToggleDebitCardModal;
  updateBillingAddress: (BillingAddress) => void;
  useDefaultAddress?: boolean;
  setUseDefaultAddress?: (useDefaultAddress: boolean) => void;
  isManualAddressMode?: boolean;
  onBack?: () => void;
  openAddPaymentMethodsModal?: () => void;
}

const AddBillingAddressManualModal = (props: AddBillingAddressManualModalProps) => {
  const { intl } = useIntl();
  const {
    onToggle,
    streetAddress,
    apt,
    locality,
    region,
    postalCode,
    country,
    useDefaultAddress,
    setUseDefaultAddress,
    isManualAddressMode,
    countryFullName,
    address1,
    address2,
    city,
    state,
    zip,
    setCountryDetails,
    onBack,
    openAddPaymentMethodsModal,
  } = props;
  const [selectedState, setSelectedState] = useState(region);
  const formattedCountry = country?.toLowerCase();
  const stateRequired = stateRequiredCountries.includes(country);
  const theme = useTheme();
  const colorScheme = theme.colorScheme;

  const generateZipcodeLabel = (country: string, intl: IntlShape) => {
    switch (country) {
      case "ie":
        return intl.formatMessage({ defaultMessage: "Eircode", description: "Ireland Postal Code" });
      default:
        return intl.formatMessage({ defaultMessage: "Postal code" });
    }
  };
  const zipcodeLabel = generateZipcodeLabel(country, intl);

  const toggleUseDefaultAddress = useCallback(() => {
    const { name, properties } = EVENTS.ADD_DEBIT_CARD_ADDRESS;
    !useDefaultAddress
      ? track(name, {
          [properties.IS_DEFAULT_ADDRESS_CHECKED]: "Checked",
          [properties.MODE]: "Default",
          [properties.STATUS]: "Open",
        })
      : track(name, {
          [properties.IS_DEFAULT_ADDRESS_CHECKED]: "Unchecked",
          [properties.MODE]: "Manual",
          [properties.STATUS]: "Open",
        });
    setUseDefaultAddress(!useDefaultAddress);
  }, [setUseDefaultAddress, useDefaultAddress]);

  const isNewDebitCardFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DEBIT_CARD_FLOW_REVAMP);

  const USE_DEFAULT_ADDRESS_INITIAL_VALUES = {
    streetAddress,
    apt,
    locality,
    region,
    postalCode,
    countryFullName,
  };

  const countryFullNameSchemaValidation = Yup.object().shape({
    countryFullName: isManualAddressMode
      ? Yup.string().required(
          intl.formatMessage({
            defaultMessage: "This field is required.",
          })
        )
      : Yup.string(),
  });

  return isNewDebitCardFlowEnabled ? (
    <Modal.MultiStep
      isOpen
      onClose={() => {
        onToggle("addBillingAddressManualVisible", true, isManualAddressMode)();
        openAddPaymentMethodsModal();
      }}
      onBack={onBack}
    >
      <ProgressBar progressBarCompletion={33.33} />
      <Text.Heading size="md" mt={2} mb={3}>
        {intl.formatMessage({
          defaultMessage: "What's your billing address?",
        })}
      </Text.Heading>

      <Checkbox
        checked={useDefaultAddress}
        inputSize="md"
        onChange={toggleUseDefaultAddress}
        data-testid="default-address-checkbox"
      >
        <Text.Body size="md">
          {intl.formatMessage({
            defaultMessage: "Use the same address as on my Gemini account",
          })}
        </Text.Body>
      </Checkbox>

      <Spacer mt={3}>
        <Formik
          initialValues={USE_DEFAULT_ADDRESS_INITIAL_VALUES}
          validationSchema={Yup.object().shape({
            ...schema(formattedCountry, intl).fields,
            ...countryFullNameSchemaValidation.fields,
          })}
          onSubmit={(values: FormValues) => {
            props.updateBillingAddress({
              address1: values.streetAddress,
              address2: values.apt,
              city: values.locality,
              state: values.region,
              zip: values.postalCode.trim(),
              countryFullName: values.countryFullName,
            });
            onToggle("addDebitCardVisible")();
          }}
          enableReinitialize
        >
          {FormikAddressForm({
            selectedState,
            setSelectedState,
            formattedCountry,
            buttonText: intl.formatMessage({ defaultMessage: "Next" }),
            rightAlignedButton: true,
            isReadOnly: !isManualAddressMode,
            inputSize: INPUT_SIZES.medium,
            countryFieldProps: { isDisplayed: isManualAddressMode },
            setCountryDetails: setCountryDetails,
            streetAddressMessage:
              isManualAddressMode &&
              intl.formatMessage(
                defineMessage({
                  defaultMessage: "<StreetDescription>Address cannot be a P.O. Box.</StreetDescription>",
                }),
                {
                  StreetDescription: (v: React.ReactNode) => (
                    <Text.Body size="xs" color={colorScheme.content.secondary}>
                      {v}
                    </Text.Body>
                  ),
                }
              ),
          })}
        </Formik>
      </Spacer>
    </Modal.MultiStep>
  ) : (
    <Modal.MultiStep
      isOpen
      title={intl.formatMessage({
        defaultMessage: "Add billing address",
      })}
      onClose={onToggle("addBillingAddressManualVisible", true)}
      onBack={onToggle("addBillingAddressVisible")}
    >
      <Text.Body size="sm">
        {intl.formatMessage({
          defaultMessage: "Complete and verify your billing address. This address can’t be a P.O. Box.",
        })}
      </Text.Body>
      <Spacer mt={5}>
        <Formik
          initialValues={{ address1, address2, city, state: state || "", zip }}
          onSubmit={(values: FormValues, formikBag: FormikActions<FormValues>) => {
            props.updateBillingAddress({
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zip: values.zip,
            });
            props.onToggle("addDebitCardVisible")();
          }}
          validationSchema={Yup.object().shape({
            address1: Yup.string().required(
              intl.formatMessage({
                defaultMessage: "Required",
              })
            ),
            address2: Yup.string(),
            city: Yup.string().required(
              intl.formatMessage({
                defaultMessage: "Required",
              })
            ),
            state: stateRequired
              ? Yup.string().required(
                  intl.formatMessage({
                    defaultMessage: "Required",
                  })
                )
              : Yup.string(),
            zip: Yup.string()
              .matches(
                getPostalCodeRegexes(country),
                intl.formatMessage({
                  defaultMessage: "Invalid Postal code",
                })
              )
              .required(
                intl.formatMessage({
                  defaultMessage: "Required",
                })
              ),
          })}
          isInitialValid={Boolean(address1 && city && (state || !stateRequired) && zip)}
        >
          {formikBag => {
            const { values, touched, errors, isValid, isSubmitting, handleChange, handleBlur, handleSubmit } =
              formikBag;
            return (
              <form onSubmit={handleSubmit} css={{ marginBottom: 0 }}>
                <Input
                  helperText={intl.formatMessage({
                    defaultMessage: "This address can’t be a P.O. Box",
                  })}
                  name="address1"
                  label={intl.formatMessage({
                    defaultMessage: "Address line 1",
                  })}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Address line 1",
                  })}
                  value={values.address1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.address1) && errors.address1}
                  data-testid="billing-address-modal-street"
                />
                <Input
                  name="address2"
                  label={intl.formatMessage({
                    defaultMessage: "Address line 2 (Optional)",
                  })}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Apt., suite, or floor",
                  })}
                  value={values.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  data-testid="billing-address-modal-address-2"
                />
                <Input
                  name="city"
                  label={intl.formatMessage({
                    defaultMessage: "City",
                  })}
                  placeholder={intl.formatMessage({
                    defaultMessage: "City",
                  })}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.city) && errors.city}
                  data-testid="billing-address-modal-city"
                />
                <TwoColumnInputContainer>
                  {stateRequired && (
                    <Input
                      name="state"
                      label={intl.formatMessage({
                        defaultMessage: "State",
                      })}
                      placeholder={intl.formatMessage({
                        defaultMessage: "State",
                      })}
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.state) && errors.state}
                      data-testid="billing-address-modal-state"
                    />
                  )}
                  <Input
                    name="zip"
                    type="text"
                    label={zipcodeLabel}
                    placeholder={zipcodeLabel}
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.zip) && errors.zip}
                    data-testid="billing-address-modal-zip"
                  />
                </TwoColumnInputContainer>
                <Button.Group>
                  <Button.Primary
                    disabled={!isValid}
                    loading={isSubmitting}
                    data-testid="billing-address-modal-btn"
                    type="submit"
                    cta={intl.formatMessage({
                      defaultMessage: "Next",
                    })}
                    size="lg"
                  />
                </Button.Group>
              </form>
            );
          }}
        </Formik>
      </Spacer>
    </Modal.MultiStep>
  );
};

export default AddBillingAddressManualModal;
