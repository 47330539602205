export const StakingPresetFunding = {
  FIAT: "fiat",
  CRYPTO: "crypto",
} as const;

export type StakingPresetFundingType = (typeof StakingPresetFunding)[keyof typeof StakingPresetFunding];

export enum ValidatorIncrementDecrementDirection {
  INCREMENT = "increment",
  DECREMENT = "decrement",
}
