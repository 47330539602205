import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { InputStyleProps } from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { formElementTextStyles } from "@gemini-ui/design-system/formElements";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { shorthandSpacingCss } from "@gemini-ui/design-system/primitives/Spacer";
import { SpacerOption } from "@gemini-ui/design-system/primitives/Spacer/constants";

const inputElementSize = width => (width ? Spacing.scale[width] : Spacing.scale[6]);

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<{ styles: InputStyleProps; pl: SpacerOption; pr: SpacerOption }>`
  ${formElementTextStyles}

  width: 100%;
  padding: 0 ${Spacing.scale[1.5]};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.gray[300]};
  transition: border-color, box-shadow 250ms ease-in-out;

  ::placeholder {
    color: ${Colors.gray[300]};
  }

  &:hover {
    border-color: ${Colors.gray[600]};
  }

  &:active,
  &:focus {
    outline: none;
    border-color: ${Colors.gray[900]};
    box-shadow: 0 0 0 1px ${Colors.gray[900]};
  }

  &:disabled {
    pointer-events: none;
    border-color: ${Colors.gray[100]};
  }

  &:disabled,
  &:read-only {
    background-color: ${Colors.gray[50]};

    ::placeholder {
      color: ${Colors.gray[200]};
    }
  }

  &:read-only {
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  /* pl and pr are reserved to give space for left and right elements */
  ${({ pl }) => pl && `padding-left: ${inputElementSize(pl)};`}
  ${({ pr }) => pr && `padding-right: ${inputElementSize(pr)};`}

  ${({ styles }) => inputStyles(styles)}
`;

const inputStyles = ({ error, success, alignText = "left", variant }: InputStyleProps) => css`
  height: ${variant === "md" ? "56px" : "60px"};
  line-height: ${variant === "md" ? "56px" : "60px"};
  border-radius: ${variant === "md" ? Spacing.scale[1] : Spacing.scale[1.5]};
  text-align: ${alignText};

  ${error &&
  `
    border-color: ${Colors.red[600]};
    &:hover, &:focus {
      border-color: ${Colors.red[600]};
      box-shadow: 0 0 0 1px ${Colors.red[600]};
    }
    `}

  ${success &&
  !Boolean(error) &&
  `
    border-color: ${Colors.green[600]};
    &:hover, &:focus {
      border-color: ${Colors.green[600]};
      box-shadow: 0 0 0 1px ${Colors.green[600]};
    }
    `}
`;

/** InputElements styles */
const InputElement = styled.span<{ elementWidth?: SpacerOption }>`
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: ${({ elementWidth }) => inputElementSize(elementWidth)};
  top: 0;
  bottom: 0;

  ${shorthandSpacingCss}
`;

export const StyledLeftElement = styled(InputElement)<{ alignItems?: string }>`
  left: 0;
  justify-content: ${({ alignItems }) => alignItems || "flex-start"};
`;

export const StyledRightElement = styled(InputElement)<{ alignItems?: string }>`
  right: 0;
  justify-content: ${({ alignItems }) => alignItems || "flex-end"};
`;

export const EyeLink = styled.a`
  display: flex;

  svg {
    transition: opacity 250ms ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const AmountLabel = styled("div")`
  display: flex;
  justify-content: space-between;
`;
