/**
 * Possible values from window.initialData.pageName
 *
 * Note: This list is non-exhaustive. Please add new pageNames as required!
 */
export enum PageName {
  ActiveTrader = "ActiveTrader",
  AddNewAccount = "AddNewAccount",
  AdvancedMarkets = "AdvancedMarkets",
  ApiSettings = "ApiSettings",
  ApplicationSettings = "ApplicationSettings",
  AssetDetail = "AssetDetail",
  Authy = "Authy",
  Balances = "Balances",
  BalancesV2 = "BalancesV2",
  BankSettings = "BankSettings",
  CardSettings = "CardSettings",
  SettlementSettings = "SettlementSettings",
  ClearingDashboard = "ClearingDashboard",
  CreateApiKey = "CreateApiKey",
  CreditApp = "CreditApp",
  CreditDashboard = "CreditDashboard",
  CustodyDashboard = "CustodyDashboard",
  Dashboard = "Dashboard",
  DocumentSettings = "DocumentSettings",
  Earn = "Earn",
  ExchangeSettings = "ExchangeSettings",
  ForgotPassword = "ForgotPassword",
  FullVerification = "FullVerification",
  Grow = "Grow",
  Stake = "Stake",
  Home = "Home",
  LegalSettings = "LegalSettings",
  Market = "Market",
  Portfolio = "Portfolio",
  ProfileSettings = "ProfileSettings",
  Register = "Register",
  RedeemGift = "RedeemGift",
  RetailTrade = "RetailTrade",
  Rewards = "Rewards",
  SecuritySettings = "SecuritySettings",
  SendGift = "SendGift",
  Settings = "Settings",
  Settlement = "Settlement",
  ShowApiKey = "ShowApiKey",
  Trade = "Trade",
  Transfer = "Transfer",
  UserManagement = "UserManagement",
  Welcome = "Welcome",
  WhitelistSettings = "WhitelistSettings",
  WrongAccountType = "WrongAccountType",
  EOTC = "EOTC",
}
