import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system";

const loadingBorderAnimation = `
  position: relative;
  border-color: transparent;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;

  @keyframes rotate {
    0% {
      width: 200%;
      height: 200%;
    }
    100% {
      width: 200%;
      height: 200%;
      transform: rotate(1turn);
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: -50%;
    top: -50%;
    background-color: inherit;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(${Colors.brandCyan}, ${Colors.brandCyan}), linear-gradient(${Colors.brandCyan}, ${Colors.brandCyan}),
      linear-gradient(transparent, transparent), linear-gradient(transparent, transparent);
    animation: rotate 4s linear infinite;
    animation-delay: 1s;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: inherit;
    border-radius: 6px;
  }
`;

export const BankCardContainer = styled.div<{ isLoading?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  border: 1px solid transparent;
  border-radius: ${Spacing.scale[1]};
  padding: ${Spacing.scale[1.5]};
  margin: ${Spacing.scale[2]} 0;
  transition: border-color 300ms ease-in-out;
  box-shadow: 0 0 6px rgba(64, 64, 64, 0.2);
  cursor: pointer;

  &:hover {
    border: 1px solid ${Colors.gray[600]};
  }

  ${({ disabled }) => disabled && `opacity: 0.66; pointer-events: none;`}

  ${({ isLoading }) => isLoading && loadingBorderAnimation}
`;
