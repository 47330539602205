/* tslint:disable */
/* eslint-disable */
/**
 * Commission Referrals API Specification
 * Commission Referrals API Specification  **Documents**: - [Technical Design Document](https://docs.google.com/document/d/10CxrjTvQOXdhWM4GSImSa5aGkSz1gIPzH55r7Z10XD8/edit) - [Figma Design](https://www.figma.com/file/8JwcQjDlWFqtUOFd6ErvZK/Commission-Referrals?type=design&node-id=361-37388&mode=design&t=tLUTJyFK5XCJrgdG-0)  **NOTE**: The following APIs from previous iteration remains. This API spec is for new APIs targetted for Commission Referrals. - `GET /eligible` - `PUT /payout/currency` - `GET /referral/code`
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "https://exchange.gemini.com".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}
