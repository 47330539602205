import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mediaQuery, shorthandSpacingCss, text } from "@gemini-ui/design-system/primitives";

// Matches the data-emotion-id of the PageLayoutContainer component
const EMOTION_ID = "PageLayoutContainer";

type TextSize = keyof (typeof text)["typesets"]["heading"]["desktop"];
type TextType = Exclude<keyof (typeof text)["typesets"], "numeric" | "body">;

import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";

const getFontWeight = (fontWeight, bold) => {
  if (fontWeight) return fontWeight;
  if (bold) return text.base.weight.semiBold;
  return text.base.weight.regular;
};

const excludedPropsFromHtml = ["color", "fontSize", "letterSpacing", "fontWeight"];

export const BaseText = styled("p", {
  shouldForwardProp: (prop: string) => isPropValid(prop) && !excludedPropsFromHtml.includes(prop),
})<BaseTextProps>`
  font-weight: ${({ fontWeight, bold }) => getFontWeight(fontWeight, bold)};
  font-style: ${({ italic }) => (italic ? "italic" : "inherit")};
  text-decoration: ${({ underline }) => (underline ? "underline" : "inherit")};
  text-align: ${({ align, center }) => (center ? "center" : align ? align : "inherit")};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing = 0 }) => letterSpacing};
  margin: ${({ margin }) => (margin ? margin : 0)};
  color: ${({ color, theme }) => (color ? color : theme.colorScheme.content.primary)};
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  ${({ numeric }) => numeric && `font-feature-settings: 'tnum' on, 'lnum' on;`}
  ${({ overflowWrap }) => overflowWrap && `overflow-wrap: ${overflowWrap};`};
  ${({ truncated }) =>
    truncated &&
    `
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
  ${shorthandSpacingCss}
`;

export const BaseLinkText = styled(BaseText)`
  text-decoration: ${({ underline }) => (underline === false ? "none" : "underline")};
  text-underline-position: under;

  ${({ as }) =>
    as === "button" &&
    `
    background: none;
    border: none;
    padding: 0;
    outline: inherit;
    font-size: inherit;
  `}

  &:hover {
    opacity: 54%;
    cursor: pointer;
    color: inherit;
  }
`;

function getStyles(
  type: TextType,
  componentName: string,
  device: keyof (typeof text)["typesets"]["display"],
  size: TextSize,
  numeric: boolean
) {
  const tokens: {
    fontFamily: string;
    fontWeight: number;
    fontSize: string;
    lineHeight: string;
    letterSpacing?: string;
  } = numeric ? text.typesets.numeric[type][device][size as string] : text.typesets[type][device][size as string];

  if (!tokens) {
    throw new Error(`Unknown ${componentName} size prop: ${size}`);
  }
  const { fontSize, lineHeight, fontWeight, letterSpacing } = tokens;

  return { fontSize, lineHeight, fontWeight, letterSpacing };
}

export const getLargeTextStyles = (type: TextType, componentName: string, size: TextSize, numeric: boolean) => css`
  ${
    // Keep non-responsive styles when rendered outside of PageLayout
    getStyles(type, componentName, "desktop", size, numeric)
  };

  ${
    // TODO: Target emotion component once @swc/plugin-emotion is fixed and outputs consistent snapshots
    `div[data-emotion-id="${EMOTION_ID}"]`
  } & {
    @media ${mediaQuery.mobileXsDown} {
      ${getStyles(type, componentName, "mobile", size, numeric)};
    }
    @media ${mediaQuery.tabletSmUp} {
      ${getStyles(type, componentName, "tablet", size, numeric)};
    }
    @media ${mediaQuery.desktopLgUp} {
      ${getStyles(type, componentName, "desktop", size, numeric)};
    }
  }
`;
