/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports
import { Formik } from "formik";
import * as Countries from "@gemini-common/scripts/constants/Countries";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { Button, Dropdown, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { findObject, objectToDropdownArray } from "@gemini-ui/pages/register/Register/utils";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { IntlShape, withIntl } from "@gemini-ui/utils/intl";

interface FormValues {
  country?: string;
}
interface AddBillingAddressManualModalProps extends FormValues {
  onToggle: ToggleDebitCardModal;
  updateCountry: (country: string) => void;
  intl: IntlShape;
}

class SelectCountryModal extends React.Component<AddBillingAddressManualModalProps, {}> {
  static contextType = AlertContext;
  state = {
    country: null,
  };

  render() {
    const { onToggle, updateCountry, intl } = this.props;
    const { country } = this.state;

    const countryItems = objectToDropdownArray(Countries.menuItems);
    const countryItem = findObject(countryItems, country);

    return (
      <Modal.MultiStep
        isOpen
        title={intl.formatMessage({
          defaultMessage: "Add billing address",
        })}
        hasDropdown
        onClose={onToggle("selectCountryVisible", true)}
        onBack={onToggle("selectDebitCurrencyVisible")}
      >
        <Text.Body size="sm">
          {intl.formatMessage({
            defaultMessage: "Select the country of the billing address for your card.",
          })}
        </Text.Body>
        <Spacer mt={5}>
          <Formik
            initialValues={{ country }}
            onSubmit={(values: FormValues) => {
              updateCountry(this.state.country);
              onToggle("addBillingAddressManualVisible")();
            }}
            isInitialValid={Boolean(country)}
          >
            {formikBag => {
              const { isSubmitting, handleSubmit } = formikBag;
              return (
                <form onSubmit={handleSubmit} css={{ marginBottom: 0 }}>
                  <Dropdown
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select country",
                    })}
                    label={intl.formatMessage({
                      defaultMessage: "Country",
                    })}
                    items={countryItems}
                    data-testid="select-country-modal-country"
                    onChange={item => this.setState({ country: item.value })}
                    value={countryItem}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                  <Button.Group>
                    <Button.Primary
                      disabled={country === null}
                      loading={isSubmitting}
                      data-testid="select-country-modal-btn"
                      type="submit"
                      size="lg"
                      cta={intl.formatMessage({
                        defaultMessage: "Next",
                      })}
                    />
                  </Button.Group>
                </form>
              );
            }}
          </Formik>
        </Spacer>
      </Modal.MultiStep>
    );
  }
}

export default withIntl(SelectCountryModal);
