import { REGEX } from "@gemini-ui/constants";

const MASKS = {
  AUTHY_CODE: [REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC],
  REGEX: REGEX,

  GB_PASSPORT: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  CA_PASSPORT: [
    REGEX.ALPHA,
    REGEX.ALPHA,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  CA_ZIPCODE: [REGEX.ALPHA, REGEX.NUMERIC, REGEX.ALPHA, " ", REGEX.NUMERIC, REGEX.ALPHA, REGEX.NUMERIC],
  SSN: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    "-",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    "-",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  LAST4SSN: [REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC],
  SIN: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    " ",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    " ",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  NINO: [
    REGEX.ALPHA,
    REGEX.ALPHA,
    " ",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    " ",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    " ",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    " ",
    REGEX.ALPHA,
  ],
  SWIFT: [
    REGEX.ALPHA,
    REGEX.ALPHA,
    REGEX.ALPHA,
    REGEX.ALPHA,
    REGEX.ALPHA,
    REGEX.ALPHA,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC_OPTIONAL,
    REGEX.ALPHA_NUMERIC_OPTIONAL,
    REGEX.ALPHA_NUMERIC_OPTIONAL,
  ],
  SWIFT_OR_WIRE_ROUTING: [
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC_OPTIONAL,
    REGEX.ALPHA_NUMERIC_OPTIONAL,
    REGEX.ALPHA_NUMERIC_OPTIONAL,
  ],
  WIRE_ROUTING: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  // 17 numerics
  US_BANK_ACCOUNT_NUMBER: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  // 34 alphanumerics. Accommodates IBAN and US numbers
  BANK_ACCOUNT_NUMBER: [
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
    REGEX.ALPHA_NUMERIC,
  ],
  US_ZIPCODE: [REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC],
  BIRTHDATE: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    "/",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    "/",
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  SORT_CODE: [REGEX.NUMERIC, REGEX.NUMERIC, "-", REGEX.NUMERIC, REGEX.NUMERIC, "-", REGEX.NUMERIC, REGEX.NUMERIC],
  CAD_INSTITUTION_NUMBER: [REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC],
  CAD_TRANSIT_NUMBER: [REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC],
};

export default MASKS;

const MASKS_WITHOUT_DIVIDERS = {
  SSN: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  SIN: [
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
    REGEX.NUMERIC,
  ],
  XFERS_OTP: [REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC, REGEX.NUMERIC],
};

export { MASKS_WITHOUT_DIVIDERS };
