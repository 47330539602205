import React, { forwardRef, memo, useMemo } from "react";
import { areEqual, FixedSizeList } from "react-window";
import { ExtendedSubaccountWithBalances } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import {
  ActiveBar,
  ItemContainer,
  MenuItemName,
  MenuItemType,
  StyledListItem,
  TextContainer,
} from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/styles";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Badge, Flex } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

interface MenuItemProps {
  account: ExtendedSubaccountWithBalances;
  selectedAccount: Subaccount;
  onSelectAccount: (account: Subaccount) => void;
}

const MenuItem = ({ account, selectedAccount, onSelectAccount }: MenuItemProps) => {
  const { intl } = useIntl();
  const { toggleModal } = useGlobalModal();
  return (
    <StyledListItem>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          if (account.needsActivation) toggleModal(GlobalModalType.PerpsOnboardingAccountActivateFormModal);
          else onSelectAccount(account);
        }}
        data-testid={`menu-item-${account.hashid}`}
      >
        <ItemContainer>
          {account.hashid === selectedAccount.hashid && <ActiveBar />}
          <TextContainer>
            <MenuItemName size="sm" bold>
              {account.name}
            </MenuItemName>
            <MenuItemType size="sm">
              {account.trading
                ? account.derivatives
                  ? intl.formatMessage({
                      defaultMessage: "Derivatives",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Exchange",
                    })
                : intl.formatMessage({
                    defaultMessage: "Custody",
                  })}
            </MenuItemType>
          </TextContainer>
          {account.needsActivation && (
            <Flex flex={1} justifyContent="flex-end" pr={2}>
              <Badge status="info">{intl.formatMessage({ defaultMessage: "Activate now" })}</Badge>
            </Flex>
          )}
        </ItemContainer>
      </a>
    </StyledListItem>
  );
};

const ROW_SIZE = 82;

interface MenuItemsProps {
  subaccounts: ExtendedSubaccountWithBalances[];
  selectedAccount: Subaccount;
  onSelectAccount: (account: Subaccount) => void;
}

export const MenuItems = ({ selectedAccount, subaccounts, onSelectAccount }: MenuItemsProps) => {
  return useMemo(() => {
    return (
      <React.Fragment>
        {subaccounts.map(acct => (
          <MenuItem
            key={acct.hashid}
            account={acct}
            selectedAccount={selectedAccount}
            onSelectAccount={onSelectAccount}
          />
        ))}
      </React.Fragment>
    );
  }, [onSelectAccount, selectedAccount, subaccounts]);
};

interface RowProps {
  data: MenuItemsProps;
  index: number;
  style: Object;
}

const Row = memo(({ data, index, style }: RowProps) => {
  const { subaccounts, selectedAccount, onSelectAccount } = data;
  const account = subaccounts[index];
  return (
    <div style={style} key={account.hashid}>
      <MenuItem account={account} selectedAccount={selectedAccount} onSelectAccount={onSelectAccount} />
    </div>
  );
}, areEqual);

export const VirtualMenuItems = ({ subaccounts, selectedAccount, onSelectAccount }: MenuItemsProps) => {
  return useMemo(() => {
    return (
      <FixedSizeList
        height={subaccounts.length > 3 ? 300 : subaccounts.length * ROW_SIZE}
        itemCount={subaccounts.length}
        itemData={{ subaccounts, selectedAccount, onSelectAccount }}
        itemSize={ROW_SIZE}
        innerElementType={forwardRef((props, ref) => (
          <div ref={ref as any} data-testid="account-items-container" {...props} />
        ))}
      >
        {Row}
      </FixedSizeList>
    );
  }, [onSelectAccount, selectedAccount, subaccounts]);
};
