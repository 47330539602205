import React, { cloneElement, forwardRef, InputHTMLAttributes } from "react";
import { useTheme } from "@emotion/react";
import { Label } from "@gemini-ui/design-system/forms/Label";
import { Message } from "@gemini-ui/design-system/forms/Message";
import { SwitchProps } from "@gemini-ui/design-system/forms/shared/constants";
import { StyledInput } from "@gemini-ui/design-system/forms/shared/styles";
import { Colors, cssShorthandPropsFilter, Spacer } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils";

const SELECTED = "selected";
const INDETERMINATE = "indeterminate";
const UNSELECTED = "unselected";

export const BaseSwitch = forwardRef<HTMLInputElement, SwitchProps>(function ForwardedCheckbox(
  {
    ["aria-label"]: ariaLabel,
    ["data-testid"]: dataTestId,
    children,
    description,
    disabled,
    error,
    indeterminate,
    iconSelected,
    iconIndeterminate,
    iconUnselected,
    inputSize = "md",
    inputType,
    ...props
  },
  ref
) {
  const theme = useTheme();
  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    disabled,
    ...props,
  };

  const svgProps = {
    size: inputSize,
    color: disabled
      ? getColor(Colors.gray[300], Colors.gray[600])({ theme })
      : getColor(Colors.black, Colors.white)({ theme }),
  };
  const IconSelected = cloneElement(iconSelected, { className: SELECTED, ...svgProps });
  const IconIndeterminate =
    iconIndeterminate && cloneElement(iconIndeterminate, { className: INDETERMINATE, ...svgProps });
  const IconUnselected = cloneElement(iconUnselected, { className: UNSELECTED, ...svgProps });
  const hasMessage = Boolean(description) || Boolean(error);

  return (
    <Label
      aria-label={ariaLabel}
      data-testid={`${dataTestId}-label`}
      disabled={disabled}
      hasInlineError={Boolean(error)}
      hasMessage={hasMessage}
      isInline
      isBold={false}
      size={inputSize}
      {...cssShorthandPropsFilter(props)}
    >
      <Spacer pr={1}>
        <StyledInput data-testid={dataTestId} ref={ref} {...inputProps} type={inputType} />
        {indeterminate ? (
          IconIndeterminate
        ) : (
          <React.Fragment>
            {IconSelected}
            {IconUnselected}
          </React.Fragment>
        )}
      </Spacer>

      {children && <Spacer pr={2}>{children}</Spacer>}

      {hasMessage && <Message disabled={disabled} error={error} message={description} />}
    </Label>
  );
});
