import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Flex, Spacing } from "@gemini-ui/design-system";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

const baseButtonStyles = css`
  position: fixed;
  bottom: ${Spacing.scale["2"]};
  right: ${Spacing.scale["2"]};
  z-index: 1;
  margin: 0;

  @media ${BREAKPOINTS.mobileDownLarge} {
    width: initial;
  }
`;

export const StyledSubmitButton = styled(Button.Primary)`
  ${baseButtonStyles}
`;

export const StyledSkipButton = styled(Button.Secondary)`
  ${baseButtonStyles}
`;

export const ViewTitle = styled(Flex)<{ mb?: number }>`
  margin: ${p => `0 auto ${Spacing.scale[p.mb || 3]}`};
  gap: ${Spacing.scale["1"]};
  flex-direction: column;

  & > p {
    color: ${p => p.theme.colorScheme.content.secondary};
  }
`;
