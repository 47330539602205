import React, { forwardRef, Ref, useCallback } from "react";
import { useTheme } from "@emotion/react";
import { IconCheck } from "@hubble/icons";
import { uniqueId } from "lodash";
import ReactSelect, {
  components,
  GroupBase,
  MultiValue,
  OnChangeValue,
  SelectInstance,
  SingleValue,
} from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import { Badge } from "@gemini-ui/design-system/Badge";
import { Button } from "@gemini-ui/design-system/Button";
import { Flex } from "@gemini-ui/design-system/Flex";
import { Label } from "@gemini-ui/design-system/forms/Label";
import { Message } from "@gemini-ui/design-system/forms/Message";
import {
  BaseSelectProps,
  MultiSelectProps,
  SelectOptionProps,
  SelectProps,
} from "@gemini-ui/design-system/forms/Select/constants";
import {
  ClearIndicator,
  IndicatorIcon,
  MultiSelectV2CustomSelectStyles,
  MultiValueRemove,
  OptionIconWrapper,
  OptionLabel,
  OptionTextWrapper,
  selectStyles,
  SelectWrapper,
  SubLabel,
} from "@gemini-ui/design-system/forms/Select/styles";
import { cssShorthandPropsFilter, Spacer } from "@gemini-ui/design-system/primitives";
import { ScreenReaderOnly } from "@gemini-ui/design-system/utils";
import { useIntl } from "@gemini-ui/utils/intl";

function formatOptionLabel<T>(
  { label, icon, subLabel, "aria-label": ariaLabel, selectedLabel }: SelectOptionProps<T>,
  { context }
) {
  return (
    <OptionLabel aria-label={ariaLabel || (typeof label === "string" && label)}>
      {icon && <OptionIconWrapper>{typeof icon === "string" ? <img src={icon} alt="" /> : icon}</OptionIconWrapper>}
      <OptionTextWrapper justifyContent="center" flexDirection="column">
        {selectedLabel && context === "value" ? selectedLabel : label}
        {Boolean(subLabel) && (
          <SubLabel className="form-sublabel" mt={0.5}>
            {subLabel}
          </SubLabel>
        )}
      </OptionTextWrapper>
    </OptionLabel>
  );
}

const CustomClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <ClearIndicator size={props.selectProps.size} />
  </components.ClearIndicator>
);

const MultiSelectMenu = props => {
  const { intl } = useIntl();

  return (
    <components.Menu {...props}>
      {props.children}
      <Button.Secondary size="sm" mt={2} mb={2} onClick={props.clearValue}>
        {intl.formatMessage({ defaultMessage: "Clear all" })}
      </Button.Secondary>
    </components.Menu>
  );
};

const CustomDropdownIndicator = props => {
  return props.selectProps.hideDropdownIndicator ||
    (props.hasValue && props.selectProps.isMultiSelectV2 === "true") ? null : (
    <components.DropdownIndicator {...props}>
      <IndicatorIcon size={props.selectProps.size} disabled={props.selectProps.disabled} />
    </components.DropdownIndicator>
  );
};

const CustomMultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <MultiValueRemove />
  </components.MultiValueRemove>
);

const MultiSelectV2MultiValue = props => {
  const CHIPS_LIMIT = 0;
  const chipCount = props.getValue().length;

  return props.index < CHIPS_LIMIT ? (
    <components.MultiValue {...props} />
  ) : props.index === CHIPS_LIMIT ? (
    <React.Fragment>
      <Spacer ml={1} />
      {props.selectProps.placeholder}
      <Spacer mr={1} />
      <Badge variant="counter" status="neutral" size="sm">
        {chipCount}
      </Badge>
    </React.Fragment>
  ) : null;
};

const MultiSelectV2Option = props => (
  <components.Option {...props}>
    <Flex justify="space-between" align="center">
      {props.data.label}
      {props.isSelected && <IconCheck size="sm" />}
    </Flex>
  </components.Option>
);

function isMultiValue<T>(arg: MultiValue<T> | SingleValue<T>): arg is MultiValue<T> {
  return Array.isArray(arg);
}

function isGroupedOption<T>(
  arg: SelectOptionProps<T> | GroupBase<SelectOptionProps<T>>
): arg is GroupBase<SelectOptionProps<T>> {
  return "options" in arg;
}

export const Select = forwardRef(function SingleSelect<Value>(
  { onChange, value, options, ...props }: SelectProps<Value>,
  ref: Ref<SelectInstance<SelectOptionProps<Value>, boolean, GroupBase<SelectOptionProps<Value>>>>
) {
  const handleChange = (newValue: OnChangeValue<SelectOptionProps<Value>, boolean>) => {
    if (isMultiValue(newValue)) {
      throw new Error(
        "onChange value returned an array. If you want to use multi-select, please use the <MultiSelect/> component"
      );
    } else {
      // when clearing, this value is null
      onChange(newValue?.value);
    }
  };

  const valueProp = React.useMemo(() => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (isGroupedOption(option)) {
          const selectedOption = option.options.find(opt => opt.value === value);
          if (selectedOption) return selectedOption;
        } else if (option.value === value) {
          return option;
        }
      }
    }

    return null;
  }, [options, value]);

  return (
    <BaseSelect isMulti={false} value={valueProp} options={options} onChange={handleChange} {...props} ref={ref} />
  );
});

export const MultiSelect = forwardRef(function MultiSelect<Value>(
  { onChange, value, options, ...props }: MultiSelectProps<Value>,
  ref: Ref<SelectInstance<SelectOptionProps<Value>, boolean, GroupBase<SelectOptionProps<Value>>>>
) {
  const handleChange = (newValue: OnChangeValue<SelectOptionProps<Value>, boolean>) => {
    if (Array.isArray(newValue)) {
      const newValues = newValue.map(({ value }) => value);
      onChange(newValues);
    }
  };

  const instanceOfSelectOptionProps = useCallback(
    (object: any): object is SelectOptionProps<Value> => "value" in object,
    []
  );

  const valueProp = React.useMemo(() => {
    if (options?.length && instanceOfSelectOptionProps(options[0]) && value?.length) {
      return options.filter(option => value.includes((option as SelectOptionProps<Value>).value));
    }
    return null;
  }, [options, value, instanceOfSelectOptionProps]);

  return (
    <BaseSelect
      isMulti={true}
      value={valueProp as unknown as SelectOptionProps<Value>}
      options={options}
      onChange={handleChange}
      ref={ref}
      {...props}
    />
  );
});

export const MultiSelectV2 = forwardRef(function MultiSelectV2<Value>(
  { ...props }: MultiSelectProps<Value>,
  ref: Ref<SelectInstance<SelectOptionProps<Value>, boolean, GroupBase<SelectOptionProps<Value>>>>
) {
  const theme = useTheme();
  const styles = MultiSelectV2CustomSelectStyles(theme);

  return (
    <MultiSelect
      ref={ref}
      isMultiSelectV2
      customSelectStyles={styles}
      wrapperStyles={{ width: "auto" }}
      components={{
        MultiValue: MultiSelectV2MultiValue,
        Option: MultiSelectV2Option,
        Menu: MultiSelectMenu,
      }}
      hideSelectedOptions={false}
      isClearable={false} // Used to remove clear btn within the input, custom clear btn provided within the menu
      {...props}
    />
  );
});

const emptyOptions = [];
const emptyStyles = {};

const BaseSelect = forwardRef(function BaseSelect<Value>(
  {
    "data-testid": dataTestId,
    asyncLoadOptions,
    autoComplete = "nope",
    className,
    defaultMenuIsOpen,
    disabled,
    error,
    filterOption,
    id,
    isClearable,
    isCreatable,
    isMulti,
    isSearchable,
    options = emptyOptions,
    label,
    menuIsOpen,
    menuPortalTarget,
    message,
    onBlur,
    onChange,
    onMenuClose,
    onMenuOpen,
    formatOptionLabel: customFormatOptionLabel,
    formatGroupLabel,
    components: customSelectComponents = emptyStyles,
    customSelectStyles,
    placeholder,
    size = "md",
    hideDropdownIndicator,
    value,
    name,
    isMultiSelectV2,
    wrapperStyles = emptyStyles,
    hideSelectedOptions,
    menuHorizontalPosition = "left",
    ...props
  }: BaseSelectProps<Value>,
  ref: Ref<SelectInstance<SelectOptionProps<Value>, boolean, GroupBase<SelectOptionProps<Value>>>>
) {
  const { intl } = useIntl();
  const NO_RESULTS_FOUND = intl.formatMessage({ defaultMessage: "No results found." });
  const SELECT_OPTION = intl.formatMessage({ defaultMessage: "Select option" });

  const AUTO = "auto" as const;
  const INPUT_ID_PREFIX = "select-";
  const inputId = Boolean(id) ? id : uniqueId(INPUT_ID_PREFIX);

  const theme = useTheme();

  const reactSelectProps = {
    autoComplete,
    closeMenuOnSelect: !isMulti,
    components: {
      ClearIndicator: CustomClearIndicator,
      DropdownIndicator: CustomDropdownIndicator,
      MultiValueRemove: CustomMultiValueRemove,
      ...customSelectComponents,
    },
    "data-testid": `${dataTestId}-select`,
    defaultMenuIsOpen,
    formatGroupLabel,
    formatOptionLabel: customFormatOptionLabel || formatOptionLabel,
    inputId,
    isDisabled: disabled,
    isSearchable,
    maxMenuHeight: 284,
    menuIsOpen,
    menuPlacement: AUTO,
    menuPortalTarget,
    onBlur,
    onChange,
    onMenuClose,
    onMenuOpen,
    placeholder: placeholder || SELECT_OPTION,
    isMulti,
    styles: { ...selectStyles(theme), ...customSelectStyles },
    name,
    hideSelectedOptions,
  };

  const selectProps = {
    error,
    message,
    size,
    value,
    hideDropdownIndicator: hideDropdownIndicator ? "true" : "", // not a native attribute, need to convert to string
    isMultiSelectV2: isMultiSelectV2 ? "true" : "",
    menuHorizontalPosition,
  };

  const hasMessage = Boolean(message) || Boolean(error);

  return (
    <SelectWrapper
      data-testid={dataTestId}
      className={className}
      size={size}
      {...cssShorthandPropsFilter(props)}
      {...wrapperStyles}
    >
      {isMultiSelectV2 ? (
        <React.Fragment>
          {/*// @ts-expect-error */}
          <ScreenReaderOnly as="label" htmlFor={inputId}>
            {label}
          </ScreenReaderOnly>
        </React.Fragment>
      ) : (
        <Label disabled={disabled} htmlFor={inputId} mb={1}>
          {label}
        </Label>
      )}
      {asyncLoadOptions && (
        <AsyncSelect
          defaultOptions={options}
          filterOption={filterOption}
          isClearable={isClearable}
          loadOptions={asyncLoadOptions}
          noOptionsMessage={() => NO_RESULTS_FOUND}
          ref={ref}
          {...reactSelectProps}
          {...selectProps}
        />
      )}
      {!asyncLoadOptions && isCreatable && (
        <CreatableSelect
          filterOption={filterOption}
          options={options}
          ref={ref}
          isClearable={isClearable}
          {...reactSelectProps}
          {...selectProps}
        />
      )}
      {!asyncLoadOptions && !isCreatable && (
        <ReactSelect
          filterOption={filterOption}
          noOptionsMessage={() => NO_RESULTS_FOUND}
          options={options}
          ref={ref}
          isClearable={isClearable}
          {...reactSelectProps}
          {...selectProps}
        />
      )}
      {hasMessage && <Message disabled={disabled} error={error} message={message} mt={1} />}
    </SelectWrapper>
  );
});
