import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { GENERIC_3DS_ERROR } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { DebitCardModalState } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/types";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { WORLDPAY_NETWORK_ERROR_MSG } from "@gemini-ui/pages/settings/BankSettings/constants";
import {
  AuthenticateRespone,
  DeviceDataInitialisation,
  HandleDebitCardSubmitType,
} from "@gemini-ui/pages/settings/BankSettings/debit/types";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { DebitCardType } from "@gemini-ui/transformers/PaymentMethods";
import { transformDebitCard } from "@gemini-ui/transformers/PaymentMethods/transformPaymentMethods";
import { getError, getFormErrors, getGlobalError } from "@gemini-ui/utils/error";

export const handleDeviceDataInitialization = async (
  unVerifiedTokenID: string,
  currency: CurrencyShortNameFiat,
  country: string
): Promise<DeviceDataInitialisation> => {
  const { data } = await axios.post(
    jsRoutes.controllers.cards.VerificationsController.card3dsDeviceDataInitialization(unVerifiedTokenID).url,
    {
      amount: {
        amount: 0,
        currency: currency,
      },
      countryCode: country,
    }
  );
  const { jwt, url, bin } = data.form;
  const { internalReference } = data;
  return {
    deviceDataJWT: jwt,
    deviceDataURL: url,
    bin: bin,
    internalReference: internalReference,
    showDeviceDataIframe: true,
  };
};

export const handleAuthenticateTransaction = async (
  SessionId: string,
  unVerifiedTokenID: string,
  internalReference: string,
  country: string,
  currency: CurrencyShortNameFiat
): Promise<{
  displayName: string;
  authenticateResponse: AuthenticateRespone;
}> => {
  const { data } = await axios.post(
    jsRoutes.controllers.cards.VerificationsController.card3dsAuthenticateTransaction(unVerifiedTokenID).url,
    {
      internalReference: internalReference,
      countryCode: country,
      amount: {
        amount: 0,
        currency: currency,
      },
      collectionReference: SessionId,
    }
  );
  return {
    displayName: data?.challengeType?.displayName,
    authenticateResponse: data,
  };
};

export const handleVerifyChallenge = (unVerifiedTokenID, internalReference) =>
  axios.post(jsRoutes.controllers.cards.VerificationsController.card3dsVerifyChallenge(unVerifiedTokenID).url, {
    internalReference: internalReference,
  });

export const handleDebitCardSubmit = async (
  payload: HandleDebitCardSubmitType,
  selectedCurrency: CurrencyShortNameFiat,
  country: string,
  subaccountHashid: string,
  onToggle: ToggleDebitCardModal,
  handleVerifyCard: (cardProps: DebitCardType, isExistingCard?: boolean) => void,
  openDebitCardErrorModal: (debitCardErrorHeading: string, debitCardErrorDescription?: string) => void,
  isWebDebitCard3DSEnabled: boolean,
  setHandleDeviceDataResponse?: (
    handleDeviceDataResponse: DeviceDataInitialisation & { unVerifiedTokenID: string }
  ) => void,
  setShowDeviceDataIframe?: (showDeviceDataIframe: boolean) => void,
  showChallengeFormIframe?: boolean
) => {
  const { name, properties } = EVENTS.ADD_DEBIT_CARD_INFO;

  await axios
    .post(jsRoutes.controllers.cards.CardsController.post().url, payload, {
      headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
    })
    .then(res => {
      payload &&
        payload?.tokenUUID &&
        track(
          EVENTS.ADD_PAYMENT_3DS_CARD_ADDITION_SUCCESS.name,
          { [EVENTS.ADD_PAYMENT_3DS_CARD_ADDITION_SUCCESS.properties.METHOD]: "DebitCard" },
          { braze: true }
        );
      onToggle(DebitCardModalState.verifyCardStepOneVisible)();
      handleVerifyCard(transformDebitCard(res.data), false);
      track(name, { [properties.SUBMITTED_SUCCESSFULLY]: true }, { braze: true });
    })
    .catch(async err => {
      payload &&
        payload?.tokenUUID &&
        track(
          EVENTS.ADD_PAYMENT_3DS_CARD_ADDITION_FAILED.name,
          { [EVENTS.ADD_PAYMENT_3DS_CARD_ADDITION_FAILED.properties.METHOD]: "DebitCard" },
          { braze: true }
        );
      const unVerifiedTokenID = _.first(_.values(getFormErrors(err)?.unVerifiedTokenID));
      if (isWebDebitCard3DSEnabled && unVerifiedTokenID) {
        try {
          track(
            EVENTS.ADD_PAYMENT_3DS_STARTED.name,
            { [EVENTS.ADD_PAYMENT_3DS_STARTED.properties.METHOD]: "DebitCard" },
            { braze: true }
          );
          const { deviceDataJWT, deviceDataURL, bin, internalReference, showDeviceDataIframe } =
            await handleDeviceDataInitialization(unVerifiedTokenID, selectedCurrency, country);
          setHandleDeviceDataResponse({
            deviceDataJWT,
            deviceDataURL,
            bin,
            internalReference,
            showDeviceDataIframe,
            unVerifiedTokenID,
          });
          track(
            EVENTS.ADD_PAYMENT_3DS_DEVICE_DATA_SUCCESS.name,
            { [EVENTS.ADD_PAYMENT_3DS_DEVICE_DATA_SUCCESS.properties.METHOD]: "DebitCard" },
            { braze: true }
          );
        } catch (error) {
          const { name, properties } = EVENTS.ADD_PAYMENT_3DS_DEVICE_DATA_FAILED;
          track(name, { [properties.METHOD]: "DebitCard" }, { braze: true });
          const errorMessage = getError(error, GENERIC_3DS_ERROR);
          openDebitCardErrorModal(errorMessage);
        }
      } else {
        const errorMessage =
          _.first(_.values(getFormErrors(err)?.error)) || getGlobalError(err.response) || WORLDPAY_NETWORK_ERROR_MSG;
        if (setShowDeviceDataIframe) setShowDeviceDataIframe(false);
        if (showChallengeFormIframe) onToggle(DebitCardModalState.addDebitCardVisible)();
        openDebitCardErrorModal(errorMessage);
        Sentry.captureException(err);
        track(name, { [properties.SUBMITTED_SUCCESSFULLY]: false });
        trackPaymentRegistrationFailure(LinkPaymentType.DEBIT, errorMessage);
      }
    });
};
