import { useMemo } from "react";
import { last } from "lodash";
import { optimizelyClient } from "@gemini-ui/analytics";
import { AnnualInterestYieldTier } from "@gemini-ui/constants/earn";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { formatInterestTiers } from "@gemini-ui/pages/Earn/utils";

export const useRateDetailsFormatter = (interestTiers: AnnualInterestYieldTier[]) => {
  // Add a helpful `range` object to some tiers
  const formattedInterestTiers = useMemo(() => formatInterestTiers(interestTiers), [interestTiers]);

  // Find the highest threshold by reversing the sorted array and getting the first one
  const maxThreshold = useMemo(
    () => last(formattedInterestTiers.filter(x => Boolean(x?.threshold)))?.threshold?.value,
    [formattedInterestTiers]
  );

  const values = useMemo(
    () => ({
      formattedInterestTiers,
      maxThreshold,
      onlyHasOneRateTier: formattedInterestTiers.length === 1,
      showTieredRateDetails: optimizelyClient.isFeatureEnabled(
        OPTIMIZELY_FEATURE_FLAGS.WEB_SHOW_TIERED_RATES_DETAILS_IN_BUY_FLOW
      ),
    }),
    [formattedInterestTiers, maxThreshold]
  );

  return values;
};
