import { useInterval } from "react-use";
import { getSubaccountRedirect } from "@gemini-ui/components/Header/utils";
import { usePageData } from "@gemini-ui/contexts/PageData";
import storage from "@gemini-ui/utils/storage";

const ACCOUNT_MISMATCH_INTERVAL = 3000;

const SubaccountRedirector = () => {
  const {
    pageName,
    templateProps: {
      user: { subaccountHashid, subaccounts },
    },
  } = usePageData();

  const handleAccountMismatch = () => {
    const hashid = storage.get("subaccountHashid");

    if (hashid && subaccountHashid && hashid !== subaccountHashid) {
      const selectedAccount = subaccounts.find(acct => acct.hashid === hashid);
      const redirect = getSubaccountRedirect(selectedAccount, pageName);
      if (redirect) {
        window.location.assign(redirect);
      } else {
        window.location.reload();
      }
    }
  };

  useInterval(() => {
    handleAccountMismatch();
  }, ACCOUNT_MISMATCH_INTERVAL);

  return null;
};

export default SubaccountRedirector;
