import { CSSObject, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { border, Spacing, text } from "@gemini-ui/design-system/primitives";
import { CLS_PREFIX, TooltipProps } from "@gemini-ui/design-system/Tooltip/constants";

export const TooltipChildrenContainer = styled("span")`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const getStyles = ({
  theme,
  textAlign,
  maxWidth,
  width,
}: {
  theme: Theme;
  textAlign: TooltipProps["textAlign"];
  maxWidth: TooltipProps["maxWidth"];
  width: TooltipProps["width"];
}): CSSObject => {
  const { fontSize, fontWeight, lineHeight } = text.typesets.body.xs;

  return {
    /**
     * CSS rules applied to outer overlay node
     * Styles cherry-picked from:
     * @Link https://github.com/react-component/tooltip/blob/72554ebb0750990a36744158757a0a8cb969a616/assets/bootstrap_white.less#L31
     */
    position: "absolute",
    display: "block",

    [`.${CLS_PREFIX}-inner`]: {
      position: "relative",
      maxWidth: width ? undefined : maxWidth,
      width: width,
      borderRadius: border.radius.sm,
      padding: Spacing.scale[1],
      backgroundColor: theme.colorScheme.tooltip.background.primary,
      color: theme.colorScheme.tooltip.content.primary,
      fontSize,
      fontWeight,
      lineHeight,
      textAlign,
    },
  };
};

export const TooltipText = styled("span")<{ inline?: boolean }>`
  ${({ inline }) =>
    inline &&
    `
      > * {
        display: inline;
      }
    `}
`;

export const IconContainer = styled("span")<{ inline?: boolean }>`
  ${({ inline }) => !inline && "display: flex;"}
  > ${TooltipText} + * {
    vertical-align: middle;
    margin-left: ${Spacing.scale[0.5]};
  }
`;
