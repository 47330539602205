import { getBasePath } from "@gemini-ui/client/api/config";
import {
  AccountApi,
  ActivityApi,
  ApplicationApi,
  AuthApi,
  AvailableYearlyStatementsApi,
  BalanceApi,
  CardsApi,
  CreditCardSystemStatusApi,
  DoshApi,
  NotificationsApi,
  OfferApi,
  PaymentApi,
  PromotionTypeApi,
  RewardApi,
  StatementApi,
  TransactionApi,
  VGSApi,
  WarmWelcomeApi,
} from "@gemini-ui/client/credit";
import { LegalAgreementsApi } from "@gemini-ui/client/legal-agreements";
import { CommissionReferralsApi } from "@gemini-ui/client/rewards-v2";
import axios from "@gemini-ui/services/axios";

const BASE_PATH = getBasePath();

export const applicationApi = new ApplicationApi(undefined, BASE_PATH, axios);
export const authApi = new AuthApi(undefined, BASE_PATH, axios);
export const statementApi = new StatementApi(undefined, BASE_PATH, axios);
export const transactionApi = new TransactionApi(undefined, BASE_PATH, axios);
export const paymentApi = new PaymentApi(undefined, BASE_PATH, axios);
export const balanceApi = new BalanceApi(undefined, BASE_PATH, axios);
export const rewardApi = new RewardApi(undefined, BASE_PATH, axios);
export const cardsApi = new CardsApi(undefined, BASE_PATH, axios);
export const offerApi = new OfferApi(undefined, BASE_PATH, axios);
export const accountApi = new AccountApi(undefined, BASE_PATH, axios);
export const notificationsApi = new NotificationsApi(undefined, BASE_PATH, axios);
export const vgsApi = new VGSApi(undefined, BASE_PATH, axios);
export const doshApi = new DoshApi(undefined, BASE_PATH, axios);
export const availableYearlyStatementsApi = new AvailableYearlyStatementsApi(undefined, BASE_PATH, axios);
export const commissionRewardsApi = new CommissionReferralsApi(undefined, BASE_PATH, axios);
export const activityApi = new ActivityApi(undefined, BASE_PATH, axios);
export const promotionTypeApi = new PromotionTypeApi(undefined, BASE_PATH, axios);
export const legalAgreementsApi = new LegalAgreementsApi(undefined, BASE_PATH, axios);
export const systemStatusApi = new CreditCardSystemStatusApi(undefined, BASE_PATH, axios);
export const warmWelcomeApi = new WarmWelcomeApi(undefined, BASE_PATH, axios);
