import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { UserPermissions } from "@gemini-ui/services/user/constants";
import { getUserPermissions } from "@gemini-ui/services/user/getUserPermissions";

export const useGetUserPermissions = () => {
  const [userPermissions, setUserPermissions] = useState<UserPermissions | null>(null);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const response = await getUserPermissions();
        response && setUserPermissions(response);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchUserPermissions();
  }, []);

  return userPermissions;
};
