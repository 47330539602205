import { IntlShape } from "react-intl";

export const getEligibilityMapping = (intl: IntlShape) => {
  return {
    ppi: [
      {
        id: "deposit",
        label: intl.formatMessage({ defaultMessage: "Deposit" }),
      },
      {
        id: "withdrawal",
        label: intl.formatMessage({ defaultMessage: "Withdrawal" }),
      },
    ],
    plaid: [
      {
        id: "trade",
        label: intl.formatMessage({ defaultMessage: "Trade" }),
      },
      {
        id: "deposit",
        label: intl.formatMessage({ defaultMessage: "Deposit" }),
      },
      {
        id: "withdrawal",
        label: intl.formatMessage({ defaultMessage: "Withdrawal" }),
      },
    ],
    giact: [
      {
        id: "deposit",
        label: intl.formatMessage({ defaultMessage: "Deposit" }),
      },
      {
        id: "withdrawal",
        label: intl.formatMessage({ defaultMessage: "Withdrawal" }),
      },
    ],
    wire: [
      {
        id: "deposit",
        label: intl.formatMessage({ defaultMessage: "Deposit" }),
      },
      {
        id: "withdrawal",
        label: intl.formatMessage({ defaultMessage: "Withdrawal" }),
        instructions: intl.formatMessage({
          defaultMessage:
            "A deposit from this bank account is required to verify ownership before you can withdraw to it",
        }),
      },
    ],
    xfers: [
      {
        id: "deposit",
        label: intl.formatMessage({ defaultMessage: "Deposit" }),
      },
      {
        id: "withdrawal",
        label: intl.formatMessage({ defaultMessage: "Withdrawal" }),
        instructions: intl.formatMessage({
          defaultMessage:
            "A deposit from this bank account is required to verify ownership before you can withdraw to it",
        }),
      },
    ],
    debit: [
      {
        id: "trade",
        label: intl.formatMessage({ defaultMessage: "Trade" }),
      },
    ],
    paypal: [
      {
        id: "trade",
        label: intl.formatMessage({ defaultMessage: "Trade" }),
      },
      {
        id: "deposit",
        label: intl.formatMessage({ defaultMessage: "Deposit" }),
      },
    ],
  };
};

export const AddPaymentMethodType = {
  GIACT: "giact",
  WIRE: "wire",
  PAYPAL: "paypal",
  DEBIT: "debit",
  PLAID: "plaid",
  PPI: "ppi",
  XFERS: "xfers",
};
