import React from "react";
import { ReactComponentLike } from "prop-types";
import { CurrencyShortName, CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { PayPalIcon } from "@gemini-ui/components/Icons/PaymentMethod/PaypalIcon";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import { Money } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Colors, Flex, Text } from "@gemini-ui/design-system";
import { DefaultDropdownItemProps } from "@gemini-ui/design-system/Dropdown/constants";
import { ReactComponent as AchTransferIcon } from "@gemini-ui/images/icons/ach-transfer.svg";
import { ReactComponent as CustomersBankIcon } from "@gemini-ui/images/icons/customers-bank_logo.svg";
import { ReactComponent as WireTransferIcon } from "@gemini-ui/images/icons/wire-transfer.svg";
import {
  KeyOfTransferMechanismName,
  LimitType,
  TRANSFER_MECHANISM,
  TransferMechanism,
  TransferMechanismName,
} from "@gemini-ui/pages/transfers/constants";
import { getMechanismName } from "@gemini-ui/pages/transfers/utils/getMechanismName";
import {
  BankAccountTransferLevel,
  PaymentMethodDataType,
  TransfersFiatDetailsType,
} from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

const { ach, wire, sen, external, card, blinc, xfers, bcolo, plaid, paypal, rtp, cbit } = TRANSFER_MECHANISM;

const getIntlCopy = (intl: IntlShape) => ({
  WIRE: "Wire",
  CARD: intl.formatMessage({ defaultMessage: "Card" }),
  DIRECT_BANK_TRANSFER: intl.formatMessage({ defaultMessage: "Direct bank transfer" }),
  FAST_TRANSFER: "FAST Transfer",
  WIRE_TRANSFER: intl.formatMessage({ defaultMessage: "Wire transfer" }),
  MANUAL_BANK_TRANSFER: intl.formatMessage({ defaultMessage: "Manual bank transfer (FPS)" }),
  BANK_TRANSFER: intl.formatMessage({ defaultMessage: "Bank transfer" }),
  SEN_TRANSFER: intl.formatMessage({ defaultMessage: "SEN transfer" }),
  BANK_TRANSFER_ACH: intl.formatMessage({ defaultMessage: "Bank transfers (ACH)" }),
  EXTERNAL_ADDRESS: intl.formatMessage({ defaultMessage: "External address" }),
  BLINC: intl.formatMessage({ defaultMessage: "BLINC" }),
  PAYPAL_TRANSFER: intl.formatMessage({ defaultMessage: "PayPal transfer" }),
  FAST_TRANSFER_VIA_XFERS: intl.formatMessage({ defaultMessage: "FAST Transfer via Xfers" }),
  CBIT_TRANSFER: intl.formatMessage({ defaultMessage: "CBIT transfer" }),
});

const { DEPOSIT_AND_CRYPTO_SALES_ONLY } = LOCKOUT_TYPES;

const getDefaultDepositMechanismSublabel = (intl: IntlShape) =>
  intl.formatMessage({
    defaultMessage:
      "Your deposit will be credited to your Gemini account and will be available for trading immediately. It should fully clear into your Gemini account within 4-5 business days.",
  });

const getWireLabelText = (currency: CurrencyShortName, intl: IntlShape) => {
  const { WIRE_TRANSFER, MANUAL_BANK_TRANSFER, BANK_TRANSFER } = getIntlCopy(intl);
  if (isCurrency.USD(currency)) return WIRE_TRANSFER;
  if (isCurrency.GBP(currency)) return MANUAL_BANK_TRANSFER;
  return BANK_TRANSFER;
};

const getRtpLabelText = (currency: CurrencyShortName, intl: IntlShape) => {
  const { FAST_TRANSFER, BANK_TRANSFER } = getIntlCopy(intl);
  if (isCurrency.SGD(currency)) return FAST_TRANSFER;
  return BANK_TRANSFER;
};

export const formatTransferMechanismAnalyticsLabel = (
  transferMechanism: TransferMechanism,
  currency: CurrencyShortName
): string => {
  const BANK_TRANSFER = "Bank transfer";
  const WIRE = "Wire";
  const FAST_TRANSFER = "FAST Transfer";
  const PLAID = "Plaid PPI";
  switch (transferMechanism) {
    case ach:
      return "ACH";
    case wire:
      return isCurrency.USD(currency) ? WIRE : BANK_TRANSFER;
    case sen:
      return "SEN";
    case blinc:
      return "BLINC";
    case bcolo:
      return BANK_TRANSFER;
    case xfers:
      return FAST_TRANSFER;
    case plaid:
      return PLAID;
    case paypal:
      return "PayPal";
    case rtp:
      return isCurrency.SGD(currency) ? FAST_TRANSFER : BANK_TRANSFER;
    default:
      return `unhandled - ${transferMechanism}`;
  }
};

export const formatTransferMechanism = (
  transferMechanism: TransferMechanism,
  currency: CurrencyShortName,
  intl: IntlShape
) => {
  const { BANK_TRANSFER, FAST_TRANSFER, DIRECT_BANK_TRANSFER, PAYPAL_TRANSFER, CBIT_TRANSFER } = getIntlCopy(intl);
  switch (transferMechanism) {
    case ach:
      return "ACH";
    case wire:
      return getWireLabelText(currency, intl);
    case sen:
      return "SEN";
    case blinc:
      return "BLINC";
    case bcolo:
      return BANK_TRANSFER;
    case xfers:
      return FAST_TRANSFER;
    case plaid:
      return DIRECT_BANK_TRANSFER;
    case paypal:
      return PAYPAL_TRANSFER;
    case rtp:
      return getRtpLabelText(currency, intl);
    case cbit:
      return CBIT_TRANSFER;
  }
};

export const getTransferLabel = (
  transferMechanism: TransferMechanism | null,
  intl: IntlShape,
  currency?: CurrencyShortName,
  transferMechanismName?: KeyOfTransferMechanismName | "ExternalTransfer"
) => {
  const {
    BANK_TRANSFER_ACH,
    BANK_TRANSFER,
    SEN_TRANSFER,
    EXTERNAL_ADDRESS,
    CARD,
    BLINC,
    DIRECT_BANK_TRANSFER,
    PAYPAL_TRANSFER,
    FAST_TRANSFER,
    CBIT_TRANSFER,
  } = getIntlCopy(intl);

  const value = transferMechanismName || getMechanismName(transferMechanism);

  switch (value) {
    case TransferMechanismName.AchTransfer:
      return BANK_TRANSFER_ACH;
    case TransferMechanismName.WireTransfer:
      return getWireLabelText(currency, intl);
    case TransferMechanismName.SenTransfer:
      return SEN_TRANSFER;
    case TransferMechanismName.CardTransfer:
      return CARD;
    case TransferMechanismName.BlincTransfer:
      return BLINC;
    case TransferMechanismName.BcoloTransfer:
      return BANK_TRANSFER;
    case TransferMechanismName.XfersTransfer:
      return FAST_TRANSFER;
    case TransferMechanismName.PlaidPaymentInitiation:
      return DIRECT_BANK_TRANSFER;
    case TransferMechanismName.PayPalTransfer:
      return PAYPAL_TRANSFER;
    case TransferMechanismName.ExternalTransfer:
      return EXTERNAL_ADDRESS;
    case TransferMechanismName.RtpTransfer:
      return getRtpLabelText(currency, intl);
    case TransferMechanismName.CbitTransfer:
      return CBIT_TRANSFER;
    default:
      return "";
  }
};

export const getDefaultDepositMechanisms = (intl: IntlShape): DepositMechanismsShape => {
  const xfersLimit = "200,000";
  return {
    ach: {
      Icon: AchTransferIcon,
      available: intl.formatMessage({ defaultMessage: "Available to trade instantly" }),
      label: getTransferLabel(ach, intl),
      sublabel: getDefaultDepositMechanismSublabel(intl),
      value: ach,
    },
    wire: {
      Icon: WireTransferIcon,
      available: intl.formatMessage({ defaultMessage: "Available within 1-2 business days" }),
      depositLimit: intl.formatMessage({ defaultMessage: "No limit" }),
      label: getTransferLabel(wire, intl),
      sublabel: getDefaultDepositMechanismSublabel(intl),
      value: wire,
    },
    external: {
      available: "",
      label: getTransferLabel(external, intl),
      value: external,
    },
    sen: {
      available: "",
      label: getTransferLabel(sen, intl),
      value: sen,
    },
    card: {
      available: "",
      label: getTransferLabel(card, intl),
      value: card,
    },
    blinc: {
      available: "",
      label: getTransferLabel(blinc, intl),
      value: blinc,
    },
    bcolo: {
      available: intl.formatMessage({ defaultMessage: "Available in up to 1 business day" }),
      label: getTransferLabel(bcolo, intl),
      value: bcolo,
    },
    xfers: {
      Icon: WireTransferIcon,
      available: intl.formatMessage({ defaultMessage: "No limit on same day deposits" }),
      depositLimit: intl.formatMessage(
        defineMessage({
          defaultMessage: "{xfersLimit} SGD limit per deposit",
        }),
        {
          xfersLimit,
        }
      ),
      label: getTransferLabel(xfers, intl),
      sublabel: getDefaultDepositMechanismSublabel(intl),
      value: xfers,
    },
    plaid: {
      Icon: AchTransferIcon,
      available: intl.formatMessage({ defaultMessage: "Usually in minutes" }),
      label: getTransferLabel(plaid, intl),
      sublabel: getDefaultDepositMechanismSublabel(intl),
      value: plaid,
    },
    paypal: {
      Icon: () => <PayPalIcon size="lg" />,
      available: intl.formatMessage({ defaultMessage: "Available to trade instantly" }),
      label: getTransferLabel(paypal, intl),
      value: paypal,
    },
    rtp: {
      Icon: WireTransferIcon,
      available: intl.formatMessage({ defaultMessage: "No limit on same day deposits" }),
      depositLimit: intl.formatMessage({ defaultMessage: "$200,000 SGD limit per deposit" }),
      label: getTransferLabel(rtp, intl),
      value: rtp,
    },
    cbit: {
      Icon: CustomersBankIcon,
      available: intl.formatMessage({ defaultMessage: "Available instantly" }),
      label: getTransferLabel(cbit, intl),
      value: cbit,
    },
  };
};

interface DepositMechanism {
  Icon?: ReactComponentLike;
  available: string;
  depositLimit?: string;
  label: string;
  sublabel?: string;
  value: TransferMechanism;
}

type DepositMechanismsShape = Record<TransferMechanism, DepositMechanism>;

export function getDepositMechanisms(intl: IntlShape, lockout?: LOCKOUT_TYPES): DepositMechanismsShape {
  const { ach, ...rest } = getDefaultDepositMechanisms(intl);
  return {
    ...rest,
    ach: {
      ...ach,
      available:
        lockout === DEPOSIT_AND_CRYPTO_SALES_ONLY
          ? intl.formatMessage({ defaultMessage: "Available for trading when funds have settled in 4-5 business days" })
          : ach.available,
      sublabel:
        lockout === DEPOSIT_AND_CRYPTO_SALES_ONLY
          ? intl.formatMessage({
              defaultMessage:
                "Your deposit will be credited to your Gemini account and will be available for trading once your funds have settled. Your deposit should fully clear into your Gemini account within 4-5 business days.",
            })
          : ach.sublabel,
    },
  };
}

const wireDepositMechanism = (currency: CurrencyShortName, intl: IntlShape) => {
  const mechanism = getDepositMechanisms(intl).wire;
  return {
    ...mechanism,
    label: getTransferLabel(mechanism.value, intl, currency),
  };
};

export const createTransferMechanismItems = (
  paymentMethods: PaymentMethodDataType[],
  currency: CurrencyShortName,
  canDepositFiatWire: boolean,
  canDepositFiatAch: boolean,
  canDepositFiatXfers: boolean,
  canDepositFiatPlaidPayment: boolean,
  canDepositFiatPayPal: boolean,
  canDepositFiatRtp: boolean,
  canDepositFiatCbit: boolean,
  payPalLimits: TransfersFiatDetailsType["payPalData"]["limits"] | undefined,
  depositLimit: LimitType,
  intl: IntlShape
) => {
  if (!paymentMethods || !paymentMethods.length) return [];

  const transferMechanismItems = [];
  const mechanisms = getDepositMechanisms(intl);
  const paymentMethodsForCurrency = paymentMethods.filter(paymentMethod =>
    paymentMethod.currencies.includes(currency as CurrencyShortNameFiat)
  );

  // only add ach if it is supported (USD currency only), and we have a bank account with achApproved true
  if (
    canDepositFiatAch &&
    paymentMethodsForCurrency.find(
      paymentMethod =>
        paymentMethod.paymentMethodType === PaymentMethodType.BANK &&
        paymentMethod.achLevel === BankAccountTransferLevel.Approved
    )
  ) {
    transferMechanismItems.push({
      ...mechanisms.ach,
      depositLimit: (
        <React.Fragment>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "{moneyComponent} remaining daily limit",
            }),
            {
              moneyComponent: <Money {...depositLimit?.portion} />,
            }
          )}
        </React.Fragment>
      ),
    });
  }

  const currencyHasXfersBank = Boolean(
    paymentMethodsForCurrency.find(paymentMethod => paymentMethod.paymentMethodType === PaymentMethodType.XFERS)
  );

  const currencyHasRtpBank = Boolean(
    paymentMethodsForCurrency.find(
      paymentMethod =>
        paymentMethod.paymentMethodType === PaymentMethodType.BANK &&
        paymentMethod.rtpLevel === BankAccountTransferLevel.Approved
    )
  );

  const currencyHasPlaidPaymentBank = Boolean(
    paymentMethodsForCurrency.find(
      paymentMethod =>
        paymentMethod.paymentMethodType === PaymentMethodType.BANK && paymentMethod.supportsPlaidPaymentInitiation
    )
  );

  if (canDepositFiatPlaidPayment && currencyHasPlaidPaymentBank) {
    transferMechanismItems.push({
      ...mechanisms.plaid,
    });
  }

  if (
    canDepositFiatPayPal &&
    paymentMethodsForCurrency.find(paymentMethod => paymentMethod.paymentMethodType === PaymentMethodType.PAYPAL)
  ) {
    transferMechanismItems.push({
      ...mechanisms.paypal,
      depositLimit: (
        <React.Fragment>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "{moneyComponent} remaining daily limit",
            }),
            {
              moneyComponent: <Money {...payPalLimits?.deposit?.portion} />,
            }
          )}
        </React.Fragment>
      ),
    });
  }

  if (isCurrency.SGD(currency)) {
    const currencyHasWireBank = Boolean(
      paymentMethodsForCurrency.find(
        paymentMethod =>
          paymentMethod.paymentMethodType === PaymentMethodType.BANK &&
          paymentMethod.wireLevel === BankAccountTransferLevel.Approved
      )
    );

    if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_SGD_JPM_TRANSFERS_ENABLED)) {
      canDepositFiatRtp && currencyHasRtpBank && transferMechanismItems.push(mechanisms.rtp);
    } else {
      canDepositFiatXfers && currencyHasXfersBank && transferMechanismItems.push(mechanisms.xfers);
      // need canDepositFiatWire permission here because only market maker SGD users can do wires
    }

    canDepositFiatWire && currencyHasWireBank && transferMechanismItems.push(wireDepositMechanism(currency, intl));
  } else if (paymentMethodsForCurrency) {
    transferMechanismItems.push(wireDepositMechanism(currency, intl));
  }

  if (canDepositFiatCbit) {
    transferMechanismItems.push({
      ...mechanisms.cbit,
      depositLimit: <React.Fragment>{intl.formatMessage({ defaultMessage: "No limit" })}</React.Fragment>,
    });
  }
  return transferMechanismItems;
};

export const formatDepositDropdownItems = (
  items: { Icon: any; available: string; depositLimit?: string; label: string | React.ReactNode; value: string }[]
): DefaultDropdownItemProps[] =>
  items.map(({ Icon, available, depositLimit, label, value }) => {
    const Label = () => {
      return (
        <Flex flexDirection="column" pt={1} pb={1}>
          <Text.Body size="sm">{label}</Text.Body>
          <Text.Body size="xs" color={Colors.gray[600]}>
            {depositLimit}
          </Text.Body>
          <Text.Body size="xs" color={Colors.gray[600]}>
            {available}
          </Text.Body>
        </Flex>
      );
    };
    return {
      value,
      label: <Label />,
      selectedLabel: label,
      icon: <Icon />,
    };
  });
