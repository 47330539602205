import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system";

export const SparklineStyle = styled("div")<{ color: string; strokeWidth: number }>`
  margin-left: ${Spacing.scale[0.5]};
  display: inline-flex;
  vertical-align: middle;

  svg {
    stroke: ${p => (p.color ? p.color : Colors.green[500])};
    fill: transparent;
    stroke-width: ${p => `${p.strokeWidth}px`};
  }
`;
