import React from "react";
import { ClassNames, Global, SerializedStyles } from "@emotion/react";
import { Flex } from "@gemini-ui/design-system/Flex";
import { ModalLayout } from "@gemini-ui/design-system/Modal/Layout";
import { globalModalStyles, StyledModal } from "@gemini-ui/design-system/Modal/styles";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";
import { Text } from "@gemini-ui/design-system/Text";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onBack?: () => void;
  headerImage?: React.ReactNode;
  title?: string | React.ReactNode;
  multiStep?: boolean;
  hasLargeTitle?: boolean;
  hasCustomLayout?: boolean;
  children?: React.ReactNode;
  className?: string;
  shouldCloseOnOverlayClick?: boolean;
  hasDropdown?: boolean;
  centered?: boolean;
  testId?: string;
  loading?: boolean;
  loadingText?: React.ReactNode;
  hideCloseButton?: boolean;
  onAfterOpen?: ReactModal.OnAfterOpenCallback;
  // eslint-disable-next-line react/no-unused-prop-types
  css?: SerializedStyles;
}

function Modal({
  children,
  multiStep,
  hasLargeTitle,
  headerImage,
  title,
  isOpen,
  hasCustomLayout,
  onClose,
  onBack,
  className,
  shouldCloseOnOverlayClick = false,
  hasDropdown,
  centered,
  testId,
  loading,
  loadingText,
  hideCloseButton,
  onAfterOpen = () => {},
}: ModalProps) {
  return (
    <ClassNames>
      {({ cx }) => (
        <StyledModal
          onAfterOpen={onAfterOpen}
          isOpen={isOpen}
          onRequestClose={onClose}
          overlayClassName="ModalOverlay"
          className={cx(className, "Modal")}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          multiStep={multiStep}
          testId={testId}
        >
          <Global styles={globalModalStyles} />
          {hasCustomLayout ? (
            children
          ) : (
            <ModalLayout
              hasLargeTitle={hasLargeTitle}
              hasDropdown={hasDropdown}
              multiStep={multiStep}
              headerImage={headerImage}
              title={title}
              onClose={onClose}
              onBack={onBack}
              className="ModalLayout"
              centered={centered}
              hideCloseButton={hideCloseButton}
            >
              {loading ? (
                <Flex mt={6} mb={6} flexDirection="column" align="center">
                  <SpinnerAnimation size={Spacing.scale[4]} mt={2} mb={2} />
                  <Text.Body>{loadingText}</Text.Body>
                </Flex>
              ) : (
                children
              )}
            </ModalLayout>
          )}
        </StyledModal>
      )}
    </ClassNames>
  );
}

Modal.MultiStep = (props: ModalProps) => <Modal {...props} multiStep hasLargeTitle />;

export { Modal };
