import React from "react";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import _ from "lodash";
import { countDecimals } from "@gemini-common/scripts/Money/utils";
import { Money } from "@gemini-ui/components/Money";
import { RenderArgs, RenderArgsWithIntl } from "@gemini-ui/components/Table/Renderers/TransferHistoryRow/constants";
import { getDateRange, TransferUtils } from "@gemini-ui/components/TransactionTable/utils";
import { TransferType } from "@gemini-ui/constants/custody";
import { Button, Text } from "@gemini-ui/design-system";
import { LimitTextLength } from "@gemini-ui/design-system/utils/LimitTextLength";
import {
  transferErrorFormatter,
  transferMethodFormatter,
  transferTypeFormatter,
} from "@gemini-ui/utils/balanceHistory";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage } from "@gemini-ui/utils/intl";

export function toOrFrom(data: TransferType) {
  const maybeAddress = _.isObject(data.destination) ? data.destination.wrapped : data.destination;
  return data.providerType || maybeAddress || data.source || data.merchantName;
}
export const renderers = {
  date: ({ data }: RenderArgs) => {
    if (TransferUtils.isEarnInterest(data)) {
      return getDateRange(data.minDateAccrualForMonth, data.maxDateAccrualForMonth);
    }
    return format(data.timestamp, DateFormats.MonthDayYearTime);
  },
  amount: ({ data }: RenderArgs) => {
    if (data.amount) {
      return <Money {...data.amount} />;
    } else if (data.value && data.value.amount) {
      return <Money {...data.value.amount} />;
    } else if (data.amountWithFee && data.amountWithFee.amount) {
      return <Money {...data.amountWithFee.amount} />;
    } else {
      Sentry.captureMessage(`Unknown amount ${data}`, Sentry.Severity.Error);
      return null;
    }
  },
  fullAmount: ({ data }: RenderArgs) => {
    if (data.amount) {
      return <Money {...data.amount} decimalsOverride={countDecimals(data.amount.value)} />;
    } else if (data.value && data.value.amount) {
      return <Money {...data.value.amount} decimalsOverride={countDecimals(data.value.amount.value)} />;
    } else if (data.amountWithFee && data.amountWithFee.amount) {
      return <Money {...data.amountWithFee.amount} decimalsOverride={countDecimals(data.amountWithFee.amount.value)} />;
    } else {
      Sentry.captureMessage(`Unknown amount ${data}`, Sentry.Severity.Error);
      return null;
    }
  },
  fee: ({ data }: RenderArgs) => {
    if (data.fee && Number(data.fee.value) > 0) {
      return <Money {...data.fee} />;
    } else if (data.amountWithFee && data.amountWithFee.fee && Number(data.amountWithFee.fee.value) > 0) {
      return <Money {...data.amountWithFee.fee} />;
    } else {
      return <React.Fragment>&ndash;</React.Fragment>;
    }
  },
  type: ({ data, intl, lowercase, noFormat }: RenderArgsWithIntl) => {
    const type = transferTypeFormatter(data, intl);
    const method = transferMethodFormatter(data, intl);
    let text;
    if (type && method) {
      text = intl.formatMessage(defineMessage({ defaultMessage: "{type} via {method}" }), {
        type,
        method,
      });
    } else if (type) {
      text = type;
    } else {
      text = intl.formatMessage(defineMessage({ defaultMessage: "via {method}" }), {
        type,
        method,
      });
    }
    if (text.length > 18 && !noFormat) {
      return (
        <LimitTextLength text={text} addParentheses={false} finalTextLength={18} hasTooltip lowercase={lowercase} />
      );
    } else {
      return text;
    }
  },
  toFrom: ({ data, maxLength = 8 }: RenderArgs & { maxLength?: number }) => {
    if (data.bankName) return data.bankName;
    const destOrSource = toOrFrom(data);
    if (destOrSource) {
      return <LimitTextLength text={destOrSource} addParentheses={false} finalTextLength={maxLength} hasTooltip />;
    } else {
      return null;
    }
  },
  status: ({
    data,
    intl,
    onConfirmDeposit,
    onlyText,
    isTransactionHistoryRevampEnabled,
  }: RenderArgsWithIntl & { isTransactionHistoryRevampEnabled?: boolean }) => {
    if (data?.isAwaitingAttestation || data?.isAwaitingUkAttestation) {
      const text = intl.formatMessage({ defaultMessage: "Confirm deposit" });

      if (onlyText) return text;

      if (!onConfirmDeposit) {
        return <Text.Link href={`/transfer/deposit/${data.amount.currency.toLowerCase()}`}>{text}</Text.Link>;
      }

      return (
        <Button.Secondary size="sm" onClick={() => onConfirmDeposit(data)}>
          {text}
        </Button.Secondary>
      );
    } else if (data.currentStatus === "AttestationRequired") {
      return intl.formatMessage({ defaultMessage: "Attestation required" });
    }

    if (TransferUtils.isEarnDeposit(data) && (data as TransferType)?.accrueOn) {
      const currentTimestamp = new Date().getTime();

      if (data.accrueOn < currentTimestamp) {
        return intl.formatMessage({ defaultMessage: "Complete" });
      } else {
        return intl.formatMessage({ defaultMessage: "Pending" });
      }
    }

    if (TransferUtils.isEarnRedeem(data) && !data.redeemedInFull) {
      return intl.formatMessage({ defaultMessage: "Pending" });
    }

    if (!isTransactionHistoryRevampEnabled) {
      // This is for custody withdrawal that are not broadcast yet
      if (data.transferInfo === "PendingExecution") {
        if (data.transferError && data.transferError.length > 0) return transferErrorFormatter(data, intl);
        else return intl.formatMessage({ defaultMessage: "Pending Execution" });
      } else if (data.transferInfo === "WireWithdrawal") {
        switch (data.currentStatus) {
          case "Unsubmitted":
            return intl.formatMessage({ defaultMessage: "Initiated" });
          case "Pending":
            return intl.formatMessage({ defaultMessage: "Pending" });
          case "CancelRequested":
          case "Canceled":
          case "Failed":
            return intl.formatMessage({ defaultMessage: "Canceled" });
          case "Succeeded":
            return intl.formatMessage({ defaultMessage: "Complete" });
          default:
            return data.currentStatus;
        }
      } else if (data.transferInfo === "AchWithdrawal") {
        return data.currentStatus;
      } else if (
        data.currentStatus != null &&
        (data.transferInfo === "AchDeposit" || data.transferInfo === "XfersWithdrawal")
      ) {
        switch (data.currentStatus) {
          case "Unsubmitted":
            return intl.formatMessage({ defaultMessage: "Initiated" });
          case "Pending":
          case "Deposited":
          case "HeldForReview":
            return intl.formatMessage({ defaultMessage: "Pending" });
          case "CancelRequested":
          case "Canceled":
          case "Failed":
          case "Recalled":
            return intl.formatMessage({ defaultMessage: "Canceled" });
          case "Succeeded":
            return intl.formatMessage({ defaultMessage: "Complete" });
          default:
            return data.currentStatus;
        }
      }
    } else {
      if (data?.transferInfo === "WireWithdrawal") {
        switch (data?.currentStatus) {
          case "Unsubmitted":
          case "Pending":
            return intl.formatMessage({ defaultMessage: "Processing" });
          case "Canceled":
            return intl.formatMessage({ defaultMessage: "Canceled" });
          case "Failed":
            return intl.formatMessage({ defaultMessage: "Failed" });
          case "Succeeded":
            return intl.formatMessage({ defaultMessage: "Complete" });
          case "Returned":
            return intl.formatMessage({ defaultMessage: "Returned" });
          default:
            return data?.currentStatus;
        }
      } else if (data?.transferInfo === "AchWithdrawal") {
        switch (data?.currentStatus) {
          case "Unsubmitted":
          case "Pending":
            return intl.formatMessage({ defaultMessage: "Processing" });
          case "Canceled":
            return intl.formatMessage({ defaultMessage: "Canceled" });
          case "Failed":
            return intl.formatMessage({ defaultMessage: "Failed" });
          case "Deposited":
            return intl.formatMessage({ defaultMessage: "Complete" });
          case "Returned":
            return intl.formatMessage({ defaultMessage: "Returned" });
          default:
            return data?.currentStatus;
        }
      } else if (
        data?.currentStatus != null &&
        (data?.transferInfo === "AchDeposit" || data?.transferInfo === "XfersWithdrawal")
      ) {
        switch (data?.currentStatus) {
          case "Unsubmitted":
          case "Pending":
          case "Deposited":
            return intl.formatMessage({ defaultMessage: "Processing" });
          case "Failed":
            return intl.formatMessage({ defaultMessage: "Failed" });
          case "Recalled":
          case "Canceled":
            return intl.formatMessage({ defaultMessage: "Canceled" });
          case "Returned":
            return intl.formatMessage({ defaultMessage: "Returned" });
          case "Succeeded":
            return intl.formatMessage({ defaultMessage: "Complete" });
          default:
            return data?.currentStatus;
        }
      }
    }

    return data.complete == null
      ? intl.formatMessage({ defaultMessage: "Complete" })
      : data.precredit
      ? intl.formatMessage({ defaultMessage: "Pre-Credited" })
      : intl.formatMessage({ defaultMessage: "Complete" });
  },
};
