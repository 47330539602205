import * as React from "react";
import * as Sentry from "@sentry/browser";
import axios, { CancelTokenSource } from "axios";
import { CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
// import { ChangeCurrencyMenu } from "@gemini-ui/components/Transfer/CashDepositFlow/ChangeCurrencyMenu";
import {
  CbitTransferInfoAPIPayload,
  WireTransferInfoAPIPayload,
} from "@gemini-ui/components/WireDepositDetails/constants";
import { WireDepositDetails } from "@gemini-ui/components/WireDepositDetails/WireDepositDetails";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Button, Flex, Modal, Spacing, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { CbitDepositDetails } from "@gemini-ui/pages/transfers/Deposit/CbitDepositDetails";
import axiosService from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getFiatDepositCbitInstructions } from "@gemini-ui/services/transfer/deposit";
import { useIntl } from "@gemini-ui/utils/intl";

const { DepositWireController } = jsRoutes.controllers.transfer;
const { XfersSettingsController } = jsRoutes.controllers.settings;

type WireInstructionsModalPropsType = {
  customerBankName?: React.ReactNode;
  currency: CurrencyShortNameFiat;
  onChangeCurrencyClick?: (currency: CurrencyShortNameFiat) => void;
  onClose: () => void;
  isOpen: boolean;
  onBack?: () => void;
  title?: string;
  isRtpApproved?: boolean;
  paymentMethodType?: PaymentMethodType;
  subaccountHashid: string;
  isProtailFromOnboarding?: boolean;
};

enum ModalState {
  ERROR,
  LOADING,
  SUCCESS,
}

export const WireInstructionsModal = ({
  onClose,
  currency,
  title,
  isOpen,
  onBack,
  isRtpApproved,
  paymentMethodType,
  subaccountHashid,
  isProtailFromOnboarding,
}: WireInstructionsModalPropsType) => {
  const { intl } = useIntl();
  const {
    templateProps: {
      user: { emailConfirmed, isFullyVerified },
      account: { geminiEntity },
    },
  } = usePageData();
  const [modalState, setModalState] = React.useState(ModalState.LOADING);
  const [wireDetails, setWireDetails] = React.useState<WireTransferInfoAPIPayload>(null);
  const cancelTokenSource = React.useRef<CancelTokenSource>();

  const [cbitInstructionData, setCbitInstructionData] = React.useState<CbitTransferInfoAPIPayload>(null);
  const isCbitEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CBIT);
  const isCbit = paymentMethodType === PaymentMethodType.CBIT;

  const { isModalOpen } = useGlobalModal();
  const isCashDepositModalOpen = isModalOpen(GlobalModalType.CashDepositModal);
  const isXfersPaymentType = paymentMethodType && paymentMethodType === PaymentMethodType.XFERS;
  const isSGDCurrencyAndRtpNotApproved = isCurrency.SGD(currency) && !isRtpApproved;
  const isXfers = isCashDepositModalOpen ? isXfersPaymentType : isSGDCurrencyAndRtpNotApproved;

  const fetchWireDetails = React.useCallback(async () => {
    const { CancelToken } = axios;
    cancelTokenSource.current = CancelToken.source();
    setModalState(ModalState.LOADING);
    const isRtp = isCurrency.SGD(currency) && isRtpApproved;
    let url = DepositWireController.wireDepositDetails(currency)?.url;
    if (isXfers) url = XfersSettingsController.getStraitsxDepositInstructions(currency)?.url;
    if (isRtp) url = `/payments/deposit/instructions/RTP?currency=${currency}&sendEmail=true`;

    try {
      let res;
      if (isCbit && isCbitEnabled) {
        res = await getFiatDepositCbitInstructions(currency, subaccountHashid, true);
      } else if (isRtp) {
        res = await axiosService.post(url, undefined, {
          headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          cancelToken: cancelTokenSource.current.token,
        });
      } else {
        res = await axiosService.get(url, {
          headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          cancelToken: cancelTokenSource.current.token,
        });
      }

      let wireDetailsInfo = res.data;
      if (isXfers) wireDetailsInfo = { internationalBankInfo: res.data };
      if (isRtp) wireDetailsInfo = { internationalBankInfo: res.data?.data, currency };

      if (isCbit && isCbitEnabled) {
        wireDetailsInfo = res.data?.data;
        setCbitInstructionData(wireDetailsInfo);
      }

      setWireDetails(wireDetailsInfo);
      setModalState(ModalState.SUCCESS);
    } catch (e) {
      if (!axios.isCancel(e)) {
        Sentry.captureException(e);
        setModalState(ModalState.ERROR);
      }
    } finally {
      cancelTokenSource?.current?.cancel?.();
    }
  }, [currency, isRtpApproved, isXfers, isCbit, isCbitEnabled, subaccountHashid]);

  React.useEffect(() => {
    if (isOpen) {
      fetchWireDetails();
    } else {
      cancelTokenSource?.current?.cancel?.();
    }

    return () => {
      cancelTokenSource?.current?.cancel?.();
    };
  }, [fetchWireDetails, isOpen, intl]);

  const renderModalContent = () => {
    if (modalState === ModalState.ERROR) {
      return (
        <React.Fragment>
          <Text.Body size="md">
            {intl.formatMessage({
              defaultMessage:
                "There was an error getting wire instructions. Please try again or contact support for assistance.",
            })}
          </Text.Body>
          <Button.Group>
            <Button.Primary onClick={fetchWireDetails} data-testid="retry-get-wire-instructions-btn">
              {intl.formatMessage({ defaultMessage: "Try again" })}
            </Button.Primary>
          </Button.Group>
        </React.Fragment>
      );
    } else if (modalState === ModalState.LOADING) {
      return (
        <Flex mt={8} mb={8} justifyContent="center" alignItems="center">
          <SpinnerAnimation size={Spacing.scale[4]} />
        </Flex>
      );
    } else {
      return (
        <React.Fragment>
          {isCbit && isCbitEnabled ? (
            <CbitDepositDetails {...cbitInstructionData} />
          ) : (
            <WireDepositDetails
              {...wireDetails}
              currency={currency}
              isXfers={isXfers}
              isRtp={isCurrency.SGD(currency) && isRtpApproved}
              geminiEntity={geminiEntity}
              emailConfirmed={emailConfirmed}
              isFullyVerified={isFullyVerified}
              subaccountHashid={subaccountHashid}
              isProtailFromOnboarding={Boolean(isProtailFromOnboarding)}
            />
          )}

          <Button.Group>
            <Button.Primary data-testid="wire-instruction-got-it-btn" onClick={onClose}>
              {intl.formatMessage({
                defaultMessage: "Got it",
              })}
            </Button.Primary>
          </Button.Group>
        </React.Fragment>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onBack={onBack}
      onClose={onClose}
      multiStep
      title={
        modalState !== ModalState.ERROR ? (
          <Flex>
            <span>
              {title ||
                intl.formatMessage({
                  defaultMessage: "Deposit instructions",
                })}
            </span>
            {/* Commenting this piece as for now we don't want the currency dropdown */}
            {/* {Boolean(onChangeCurrencyClick) && paymentMethodType !== PaymentMethodType.CBIT && (
              <ChangeCurrencyMenu
                currency={currency}
                supportedFiat={supportedFiatCurrencies}
                onChangeCurrencyClick={onChangeCurrencyClick}
              />
            )} */}
          </Flex>
        ) : (
          intl.formatMessage({
            defaultMessage: "Oops, something went wrong",
          })
        )
      }
    >
      {renderModalContent()}
    </Modal>
  );
};
