import { Column } from "@gemini-ui/components/Table/constants";

export const getDataTestId = (column: Column) => {
  let dataTestId = "";

  if (typeof column.label === "string") {
    dataTestId = column.label.toLowerCase().replace(/\./g, "").split(" ").join("-");
  }

  return dataTestId;
};
