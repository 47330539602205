import React, { MouseEvent } from "react";
import { useTheme } from "@emotion/react";
import { NOTIFICATION_CENTER_EVENTS } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { FEED, Notifications } from "@gemini-ui/components/Header/navigation/NotificationCenter/constants";
import { NotificationListContainer } from "@gemini-ui/components/Header/navigation/NotificationCenter/NotificationList/styles";
import { ProductIconBadge } from "@gemini-ui/components/Header/navigation/NotificationCenter/ProductIconBadge";
import { TimeDistanceAbbreviated } from "@gemini-ui/components/TimeDistanceAbbreviated";
import { Badge, List, ListItem, Text } from "@gemini-ui/design-system";

interface NotificationListProps {
  notifications: Notifications[];
}

export function NotificationList({ notifications }: NotificationListProps) {
  const { colorScheme } = useTheme();

  const handleNotificationClick = (e: MouseEvent<HTMLAnchorElement>, notification: Notifications) => {
    e.preventDefault();

    const { action, title } = notification;

    trackTapNotificationAction(title, action);
    window.location.href = action;
  };

  const trackTapNotificationAction = (filterName: string, href: string) => {
    const { name, properties } = NOTIFICATION_CENTER_EVENTS.TAP_NOTIFICATION_ACTION;

    const analyticsProperties = {
      [properties.FILTER_NAME]: filterName,
      [properties.DEEPLINK]: href,
    };

    mixpanelClient.trackLinkClickAndRedirectManually(href, name, analyticsProperties);
  };

  return (
    <NotificationListContainer>
      <List role={FEED}>
        {notifications.map(notification => {
          const { action, id, product, text, time, title, unread } = notification;

          return (
            <ListItem
              as="a"
              href={action}
              data-testid={`notification-${id}`}
              onClick={e => handleNotificationClick(e, notification)}
              key={id}
              left={<ProductIconBadge product={product} />}
              padding="sm"
              right={
                <div>
                  <Text.Body color={colorScheme.content.secondary} mb={0.25} size="xs">
                    <TimeDistanceAbbreviated time={time} />
                  </Text.Body>
                  {unread && <Badge data-testid={`notification-center-pip-${id}`} status="info" variant="pip" />}
                </div>
              }
              size="dense"
              parentAriaSetsize={notifications.length || -1}
              parentAriaPosinset={id}
              role="article"
            >
              <Text.Body bold={unread} size="sm">
                {title}
              </Text.Body>
              <Text.Body color={colorScheme.content.secondary} size="sm">
                {text}
              </Text.Body>
            </ListItem>
          );
        })}
      </List>
    </NotificationListContainer>
  );
}
