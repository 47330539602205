import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Backdrop } from "@gemini-ui/components/Popover/styles";

const KEY_ESCAPE = 27;

interface PortalProps {
  id?: string;
  closeOnEsc?: boolean;
  closeOnOutsideClick?: boolean;
  hasBackdrop?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  doNotCloseIfTargetContainsClass?: string;
  portalRootSelector?: string;
}
// NOTE: This component could be extracted up a level if we want to use it anywhere else
const Portal: React.FC<React.PropsWithChildren<PortalProps>> = ({
  id = "portal",
  closeOnEsc = true,
  closeOnOutsideClick = true,
  hasBackdrop = false,
  onOpen,
  onClose,
  portalRootSelector = "body",
  children,
  doNotCloseIfTargetContainsClass,
}) => {
  const portalRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const reactContainer = document.getElementById("container");
    if (closeOnEsc) {
      reactContainer.addEventListener("keydown", handleKeydown);
    }
    if (closeOnOutsideClick) {
      reactContainer.addEventListener("click", handleOutsideMouseClick);
    }
    if (hasBackdrop) {
      document.body.classList.add("has-backdrop");
    }

    onOpen && onOpen();

    const cleanup = () => {
      if (closeOnEsc) {
        reactContainer.removeEventListener("keydown", handleKeydown);
      }
      if (closeOnOutsideClick) {
        reactContainer.removeEventListener("click", handleOutsideMouseClick);
      }
      if (hasBackdrop) {
        document.body.classList.remove("has-backdrop");
      }
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideMouseClick = e => {
    if (!portalRef.current) return;

    const portalContainsTarget = portalRef.current.contains(e.target);
    if (
      portalContainsTarget ||
      (!portalContainsTarget && !document.body.contains(e.target)) ||
      (e.button && e.button !== 0) ||
      (e.target.classList && e.target.classList.contains(doNotCloseIfTargetContainsClass))
    ) {
      return;
    }

    onClose && onClose();
  };

  const handleKeydown = e => {
    if (e.keyCode === KEY_ESCAPE) {
      onClose && onClose();
    }
  };

  return (
    <React.Fragment>
      {hasBackdrop && <Backdrop />}
      {ReactDOM.createPortal(
        <div id={id} data-testid={id} ref={portalRef}>
          {children}
        </div>,
        document.querySelector(portalRootSelector)
      )}
    </React.Fragment>
  );
};

export default Portal;
