import { CSSObject } from "@emotion/react";
import { Property } from "csstype";
import { TooltipProps as RCTooltipProps } from "rc-tooltip/lib/Tooltip";

type Trigger = "hover" | "click" | "focus";

export interface TooltipProps {
  /**
   * Adds a data-testid attribute to Tooltip container.
   */
  "data-testid"?: string;
  /**
   * An configuration object to fine-tune the placement of the overlay. The properties are merged with the default
   * @see {@link https://github.com/yiminghe/dom-align#usage DOM Align Docs}
   */
  align?: RCTooltipProps["align"];
  children?: React.ReactNode;
  /**
   * Adds a data-testid attribute to Tooltip container.
   * @deprecated Use the `data-testid` prop instead.
   */
  containerTestId?: string;
  css?: any;
  /**
   * If `true`, the popup is initially visible.
   * The tooltip returns to default behavior if mouse enters the trigger.
   */
  defaultVisible?: RCTooltipProps["defaultVisible"];
  /**
   * If `true`, `props.children` as passed through.
   */
  disabled?: boolean;
  /**
   * The `max-width` applied the popup container.
   * @default 250px
   */
  maxWidth?: string | number;
  /**
   * The delay time in seconds to show popup when mouse enters trigger.
   * @default 0.2
   */
  mouseEnterDelay?: RCTooltipProps["mouseEnterDelay"];
  /**
   * The content of the popup. Strings are rendered using default font sizes.
   * JSX elements are wrapped within a `HubbleThemeProvider` set to `dark`.
   */
  overlay: React.ReactNode;
  /**
   * Classname applied to the popup container.
   */
  overlayClassName?: string;
  /**
   * Styles applied to the popup inner content.
   */
  overlayInnerStyle?: RCTooltipProps["overlayInnerStyle"];
  /**
   * Styles applied to child content.
   */
  childrenStyle?: CSSObject;
  /**
   * Placement of the popup.
   * @default top
   */
  placement?: "left" | "right" | "top" | "bottom";
  /**
   * If `true`, the popup will display an arrow showing the exact element where the tooltip has been added.
   */
  showArrow?: RCTooltipProps["showArrow"];
  /**
   * If `true`, the popup is always visible. If a boolean value is defined, the `Tooltip` becomes controlled and no longer manages its own popup state.
   */
  visible?: RCTooltipProps["visible"];
  /**
   * The CSS `text-align` property applied to the text within the popup
   * @default start
   */
  textAlign?: Property.TextAlign;
  /**
   * An array of action types that trigger the popup open.
   * @example ```['hover', 'click']```
   *
   * @default ['hover']
   */
  trigger?: Trigger[];
  /**
   * The `width` applied the popup container. `width` overrides `maxWidth`.
   */
  width?: string | number;
  /**
   * The z-index applied to the popup.
   * @default 10000
   */
  zIndex?: number;
  /**
   * Callback called when the popup visibility has changed.
   */
  onVisibleChange?: RCTooltipProps["onVisibleChange"];
  /**
   * For internal use. Throw TS error if used rather than maxWidth.
   * Hide from docs.
   * @ignore
   */
  "max-width"?: never;
}

export type Placement = TooltipProps["placement"];

interface IconProps {
  icon: React.ReactNode;
  inline?: boolean;
}

export type TooltipWithIconProps = TooltipProps & IconProps;

const v = 8;
export const OFFSETS = {
  left: [-v, 0],
  right: [v, 0],
  top: [0, -v],
  bottom: [0, v],
};

export const CLS_PREFIX = "hbl-tooltip";
