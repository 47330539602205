import styled from "@emotion/styled";
import { Colors, Flex, Spacer } from "@gemini-ui/design-system";

export const IconWrapper = styled.div`
  svg {
    display: none;
  }
`;

export const ListItemWrapper = styled(Flex)`
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${Colors.gray[100]};
    display: block;
    svg {
      display: block;
    }
  }
`;

export const ListItemContent = styled(Spacer)`
  width: 100%;
`;

export const ListItemData = styled(Flex)`
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
