import * as React from "react";
import { isEmpty } from "lodash";
import formatFormErrors from "@gemini-ui/components/Form/utils/formatFormErrors";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import {
  AlertProps,
  AlertTypes,
  alertTypeToVariantMap,
  GlobalAlertTypesProps,
  PAGE_PROP_ALERT_TIMEOUT,
} from "@gemini-ui/components/GlobalAlert/constants";
import { usePageRefresh } from "@gemini-ui/contexts";
import { SystemAlert } from "@gemini-ui/design-system/SystemAlert";

export const AlertMessage: React.FunctionComponent<React.PropsWithChildren<AlertProps>> = ({ alert, hideAlert }) => {
  if (!alert) return null;

  const variant = alertTypeToVariantMap[alert.type];

  return (
    <SystemAlert data-testid={alert.id} variant={variant} onClose={hideAlert}>
      {formatFormErrors(alert.message)}
    </SystemAlert>
  );
};

const GlobalAlert = ({ green, yellow, red }: GlobalAlertTypesProps) => {
  const { alert, hideAlert, showAlert } = useAlert();
  // ensure subsequent duplicate server errors still trigger AlertMessage
  const refreshCount = usePageRefresh()?.refreshCount || 0;

  React.useEffect(() => {
    // handle server-side messages from initialData, map into Alert Types.
    if (!isEmpty(red)) {
      showAlert({
        type: AlertTypes.ERROR,
        message: red,
        timeout: PAGE_PROP_ALERT_TIMEOUT,
      });
    } else if (!isEmpty(green)) {
      showAlert({
        type: AlertTypes.INFO,
        message: green,
        timeout: PAGE_PROP_ALERT_TIMEOUT,
      });
    } else if (!isEmpty(yellow)) {
      showAlert({
        type: AlertTypes.WARNING,
        message: yellow,
        timeout: PAGE_PROP_ALERT_TIMEOUT,
      });
    }
  }, [red, green, yellow, showAlert, refreshCount]);

  return <AlertMessage alert={alert} hideAlert={hideAlert} />;
};

export default GlobalAlert;
