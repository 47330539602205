export const CRYPTO_DEPOSIT_STATES = {
  LOADING: "LOADING",
  SELECT_ASSETS: "SELECT_ASSETS",
  DEPOSIT_ALERT: "DEPOSIT_ALERT",
  ASSETS_ERROR: "ASSETS_ERROR",
  QR_SCREEN: "QR_SCREEN",
  SELECT_ADDRESS: "SELECT_ADDRESS",
  ADDRESS_DETAIL: "ADDRESS_DETAIL",
  PRE_CURRENCY_SELECTOR_ERROR: "PRE_CURRENCY_SELECTOR_ERROR",
  DATA_LOAD_ERROR: "DATA_LOAD_ERROR",
  EDIT_LABEL: "EDIT_LABEL",
} as const;

export const CRYPTO_DEPOSIT_EVENTS = {
  FETCH_INITIAL_DATA: "FETCH_INITIAL_DATA",
  GO_BACK: "GO_BACK",
  SET_CURRENCY: "SET_CURRENCY",
  SET_AMOUNT: "SET_AMOUNT",
  DEPOSIT_ERROR: "DEPOSIT_ERROR",
  GO_TO_QR_SCREEN: "GO_TO_QR_SCREEN",
  GO_TO_SELECT_ADDRESS: "GO_TO_SELECT_ADDRESS",
  ON_ADDRESS_CHANGE: "ON_ADDRESS_CHANGE",
  ON_LABEL_CHANGE: "ON_LABEL_CHANGE",
  GO_TO_ADDRESS_DETAIL: "GO_TO_ADDRESS_DETAIL",
  GO_TO_PRE_CURRENCY_SELECTOR_ERROR: "GO_TO_PRE_CURRENCY_SELECTOR_ERROR",
  CREATE_ADDRESS: "CREATE_ADDRESS",
  GO_TO_DATA_LOAD_ERROR: "GO_TO_DATA_LOAD_ERROR",
  GO_TO_ADDRESS_LABEL: "GO_TO_ADDRESS_LABEL",
} as const;

export const FINAL = "final" as const;
export const DATA_TEST_IDS = {
  CRYPTO_DEPOSIT_LOADING: "crypto-deposit-loading",
  ADDRESS_DETAIL: {
    ADDRESS: "address-detail-address",
    ADDRESS_LABEL: "address-detail-address-label",
    CREATED_AT: "address-detail-created-at",
    UPDATE_LABEL: "address-detail-update-label",
    ADDRESS_DETAIL_MODAL: "address-detail-modal",
    ADRESS_MEMO: "address-detail-memo",
  },
  ASSETS_ERROR: {
    ASSETS_ERROR_UNSUPPORTED: "assets-error-unsupported",
    TRANSACTION_DISABLED: "transaction-disabled",
    GO_BACK_FROM_ERROR: "go-back-from-error",
  },
  DEPOSIT_ERROR: {
    LOCKOUT_ERROR: "lockout-error",
    DOCS_NOT_APPROVED: "docs-not-approved",
    DERIVATIVES_ACCOUNT: "derivatives-account",
  },
  SELECT_ADDRESS: {
    MANAGE_ADDRESSES: "manage-addresses",
    SELECT_ADDRESS: "select-address",
    SHEET: "select-address-sheet",
    SEARCHABLE_ADDRESS: "searchable-address",
    ICON_CHECK: "icon-check",
  },
  SELECT_ASSET: {
    SHEET: "select-asset-sheet",
  },
  DEPOSIT_ALERT: {
    SHEET: "deposit-alert-sheet",
    CTA: "deposit-alert-cta",
  },
  COMPLETE_DEPOSIT: {
    SHEET: "complete-deposit-sheet",
    CHANGE_ADDRESS: "change-address",
    CHANGE_ASSETS: "change-assets",
    QR_CARD_SKELETON: "qr-card-skeleton",
    QR_IMAGE: "qr-image",
    QR_CARD: "qr-card",
    EMPTY_ADDRESS: "empty-address",
    QR_CARD_RETRY: "qr-card-retry",
  },
  EDIT_LABEL: {
    EDIT_LABEL_INPUT: "edit-label-input",
    EDIT_LABEL_SUBMIT: "edit-label-submit",
  },
  LOCKOUT_GENERATE_LEGACY_ADDRESS: "lockout-generate-legacy-address-tooltip",
  SOL_DEPOSIT_WARNING_LEARN_MORE: "sol-deposit-warning-learn-more",
} as const;

export const SELECT_ADDRESS_SCREEN_CONTENT = {
  SHOW_CUSTODY_DEPOSIT_MESSAGE_VALUE: "showCustodyDepositMessageValue",
  SHOW_NO_ADDRESS_AVAILABLE_VALUE: "showNoAddressAvailableValue",
  SHOW_LIST: "showlist",
} as const;

export const QR_STATE = {
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
} as const;

export const ADDRESS_TYPE = {
  MEMO: "memo",
  ADDRESS: "address",
  TAG: "tag",
} as const;

export const CRYPTO_DEPOSIT_MIXPANEL_EVENTS = {
  START: {
    name: "Deposit - Start",
    properties: {
      INITIATED_FROM: "Initiated From",
    },
  },
  SELECT_ASSET: {
    name: "Deposit - Asset Selected",
    properties: {
      CURRENCY: "Currency",
    },
  },
  VIEW_CONSENT_MESSAGE: {
    name: "Crypto deposit consent Confirmation Viewed",
    properties: {
      CURRENCY: "Currency",
    },
  },
  CLICKS_CONSENT_MESSAGE: {
    name: "Crypto deposit consent Confirmation Viewed",
    properties: {
      CURRENCY: "Currency",
    },
  },
  NOT_SUPPORTED_MESSAGE_VIEWED: {
    name: "Crypto deposit asset Not Supported Viewed",
    properties: {
      CURRENCY: "Currency",
    },
  },
  VIEW_QR_SCREEN: {
    name: "Crypto deposit confirmation viewed",
  },

  CLICK_EVENT_ON_QR_SCREEN: {
    name: "Crypto deposit confirmation change destination selector tapped",
    properties: {
      CTA: "cta",
    },
  },
  CLICK_EVENT_ON_ADDRESS_SELECTOR: {
    name: "Crypto deposit confirmation manage address tapped",
    properties: {
      CTA: "cta",
    },
  },

  EDIT_LABEL: {
    name: "User edits the label on deposit address detail screen",
  },
};
export const MIXPNAEL_PROPERTY_VALUE = {
  CHANGE_ADDRESS: "change destination address",
  SELECT_ADDRESS: "select address",
  MANAGE_ADDRESS: "manage address",
};
