import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Colors, ShorthandSpacingCssProps, Spacer } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils";

export type Props = {
  size?: string;
  center?: boolean;
  color?: string;
} & ShorthandSpacingCssProps;

const ARIA_LABEL = "spinner";

const SpinnerContainer = styled(Spacer)<Props>`
  height: ${props => props.size};
  width: ${props => props.size};
  ${({ center }) =>
    center &&
    `
    display: flex;
    justify-content: center;
    width: 100%;
  `}
`;

// The CSS keyframe animation adapted from https://codepen.io/supah/pen/BjYLdW
const Rotate = keyframes`
  100% {
      transform: rotate(360deg);
    }
`;

const Dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Spinner = styled.svg<{ color?: string }>`
  animation: ${Rotate} 2s linear infinite;

  circle {
    stroke: ${({ color }) => (color ? color : getColor(Colors.black, Colors.white))};
    stroke-linecap: round;
    animation: ${Dash} 1s ease-in-out infinite;
  }
`;

export const SpinnerAnimation = ({ center = false, color, size = "32px", ...spacingProps }: Props) => {
  return (
    <SpinnerContainer
      size={size}
      center={center}
      aria-label={ARIA_LABEL}
      {...spacingProps}
      data-testid="spinner-animation"
    >
      <Spinner color={color} viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="4"></circle>
      </Spinner>
    </SpinnerContainer>
  );
};
