import React from "react";
import { UseFormReturn } from "react-hook-form";
import { GrowAsset, GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, Spacing } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { CurrencyAmountInputs } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/CurrencyAmountInputs";
import { ValidatorAmountInputs } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/ValidatorAmountInputs";
import { StakingPresetFunding } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositPresets/constants";
import { FundingSource } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/FundingSource";
import { WithdrawalFormFields } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingWithdrawForm/constants";
import { GrowTransactionType, SupportedUnstakingMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { WithdrawalFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingWithdrawalForm";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { calculateValidatorsAvailableToUnstake } from "@gemini-ui/pages/Earn/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const StakingWithdrawForm = ({
  asset,
  formController,
  onSubmit,
  onWithdrawSourceClick,
  lastTradePrice,
  fundingSource,
  provider,
}: {
  asset: GrowAsset;
  formController: UseFormReturn<WithdrawalFormValues>;
  lastTradePrice: string;
  onSubmit: (data: WithdrawalFormValues) => void;
  onWithdrawSourceClick: () => void;
  fundingSource: SelectOptionProps<SupportedUnstakingMethod>;
  provider: InterestProvider;
}) => {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = formController;
  const { amount, providerType, unstakeSourceId, validatorCount } = formController.watch();

  const { currency } = asset;

  // Use `earningInterest` when the provider is a pooled staking provider
  const unstakableBalance = provider.earningInterest.value;

  // Staking pro providers have a separate reward balances and deposit balances
  // - `totalAvailableDepositBalance` is the total amount of staked funds that can be unstaked
  // - `totalAvailableRewardBalance` is the total amount of rewards that can be withdrawn
  const unstakableRewards = provider.availableBalance.totalAvailableRewardBalance.value;
  const unstakableValidators = calculateValidatorsAvailableToUnstake(
    Number(provider.availableBalance.totalAvailableDepositBalance.value)
  );

  return (
    <form data-testid={testIds.buyModule.screens.placeWithdraw} onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection="column" gap={Spacing.scale[2]}>
        {unstakeSourceId === UnstakeSourceType.validatorBalance ? (
          <ValidatorAmountInputs
            key={`withdraw-${currency}-validator-input`}
            currency={currency}
            maxValidators={unstakableValidators}
            controls={{
              amount: {
                name: WithdrawalFormFields.AMOUNT,
                control: control,
              },
              validatorCount: {
                name: WithdrawalFormFields.VALIDATOR_COUNT,
                control: control,
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Enter a valid amount" }),
                  max: {
                    value: unstakableValidators,
                    message: intl.formatMessage({ defaultMessage: "Amount exceeds validators available to unstake." }),
                  },
                },
              },
            }}
          />
        ) : (
          <CurrencyAmountInputs
            key={`withdraw-${currency}-amount-input`}
            currency={currency}
            displayCurrencies={[asset.currency, defaultFiat]}
            maxValue={providerType === GrowProviderType.POOLED_STAKING ? unstakableBalance : unstakableRewards}
            lastTradePrice={lastTradePrice}
            fundingType={StakingPresetFunding.CRYPTO}
            controls={{
              amount: {
                name: WithdrawalFormFields.AMOUNT,
                control: control,
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Enter a valid amount" }),
                  max: {
                    value: providerType === GrowProviderType.PRIVATE_STAKING ? unstakableRewards : unstakableBalance,
                    message: intl.formatMessage(
                      defineMessage({
                        defaultMessage:
                          "Amount exceeds {source, select, availableRewardsBalance {rewards} other {balance}} available to unstake.",
                      }),
                      { source: unstakeSourceId }
                    ),
                  },
                },
              },
            }}
          />
        )}

        <FundingSource
          isDisabled={providerType === GrowProviderType.POOLED_STAKING}
          transactionType={GrowTransactionType.UNSTAKE}
          value={unstakeSourceId}
          onClick={onWithdrawSourceClick}
          {...fundingSource}
        />

        <Button.Secondary
          data-testid={testIds.buyModule.form.reviewBtn}
          type="submit"
          size="lg"
          cta={intl.formatMessage({ defaultMessage: "Review" })}
          disabled={!(isDirty && (Number(amount) || Number(validatorCount)) && unstakeSourceId)}
        />
      </Flex>
    </form>
  );
};
