import React from "react";
import styled from "@emotion/styled";
import { getFilledPercent, isPartiallyFilled } from "@gemini-ui/components/StatusColumn/utils";
import { Order, OrderUtils } from "@gemini-ui/constants/orders";
import { Colors } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  order: Order;
  noColor?: boolean;
}

const CanceledText = styled("span")`
  color: ${Colors.red[600]};
`;

const StatusColumn: React.FC<React.PropsWithChildren<Props>> = ({ order, noColor }) => {
  const { intl } = useIntl();

  let status = null;
  if (order.cancelled) {
    if (isPartiallyFilled(order)) {
      status = intl.formatMessage({
        defaultMessage: "Partially Filled",
      });
    } else {
      status = noColor ? (
        intl.formatMessage({
          defaultMessage: "Canceled",
        })
      ) : (
        <CanceledText>
          {intl.formatMessage({
            defaultMessage: "Canceled",
          })}
        </CanceledText>
      );
    }
  } else if (order.type === "marketSell" || order.type === "marketBuy") {
    status = intl.formatMessage({
      defaultMessage: "Filled",
    });
  } else if (OrderUtils.isRecurringOrder(order)) {
    // Render completed recurring orders that aren't cancelled as filled
    status = intl.formatMessage({
      defaultMessage: "Filled",
    });
  } else if (order.type === "order") {
    const filledPct = getFilledPercent(order);
    const roundedPct = Math.round(filledPct);
    const filledContent = (
      <span>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "{roundedPct}% Filled",
          }),
          {
            roundedPct,
          }
        )}
      </span>
    );

    if (order.stopPrice) {
      status = intl.formatMessage({
        defaultMessage: "Triggered",
      });
    } else {
      if (filledPct < 100) {
        status = filledContent;
      } else {
        status = intl.formatMessage({
          defaultMessage: "Filled",
        });
      }
    }
  }

  return status;
};

export default StatusColumn;
