export const FlowState = {
  INIT: "INIT",
  LINKING: "LINKING",
  ERROR: "ERROR",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
} as const;

// Match PayPalAccount.scala Errors
export const PayPalErrors = {
  NONE: "NONE",
  CANNOT_ADD_ACCOUNT: "CANNOT_ADD_ACCOUNT",
  GENERIC: "GENERIC",
  /**
   * The requested payer id is already linked to a different exchange
   * account.
   */
  NonUniquePayerId: "NonUniquePayerId",

  /**
   * This exchange account already has a linked paypal payment method.
   */
  OnePerAccount: "OnePerAccount",

  /**
   * This name on the paypal account doesn't match the Gemini account
   */
  NameMismatch: "NameMismatch",

  /**
   * The call to the Braintree server failed
   */
  BraintreeError: "BraintreeError",

  /**
   * Failure when creating paypal token
   */
  TokenGenerationFailed: "TokenGenerationFailed",
} as const;

/* 
  PayPal types from braintree-web
*/
export interface AuthorizationData {
  payerId: string;
  paymentId?: string;
  billingToken?: string;
  vault?: boolean;
}

interface AuthorizationResponse {
  /**
   * The payment method nonce.
   */
  nonce: string;

  /**
   * The payment method type, always `PayPalAccount`.
   */
  type: string;

  /**
   * Additional PayPal account details.
   */
  details: AuthorizationResponseDetails;
}

interface AuthorizationResponseDetails {
  email: string;
  payerId: string;
  firstName: string;
  lastName: string;
  countryCode?: string;
  phone?: string;
  shippingAddress?: Address;
  billingAddress?: Address;
  creditFinancingOffered?: CreditFinancingOptions;
}

interface Address {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  phone?: string;
  recipientName?: string;
}

interface CreditFinancingOptions {
  totalCost: CurrencyAmount;
  term: number;
  monthlyPayment: CurrencyAmount;
  totalInterest: CurrencyAmount;
  payerAcceptance: boolean;
  cartAmountImmutable: boolean;
}

interface CurrencyAmount {
  /**
   * The three-character ISO-4217 currency code. PayPal does not support all currencies.
   */
  currency: string;

  /**
   * The amount the shipping option will cost. Includes the specified number of digits after
   * decimal separator for the ISO-4217 currency code.
   */
  value: string;
}

export enum FlowType {
  Vault = "vault",
  Checkout = "checkout",
}

export type LinkData = { payload: AuthorizationResponse; deviceData?: string };

export const GENERIC_PAYPAL_ERROR = "Unknown Paypal linking error";
