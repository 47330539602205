import React from "react";
import { THead } from "@gemini-ui/design-system/Table/styles";
import { testIds } from "@gemini-ui/design-system/Table/testIds";
import { ScreenReaderOnly } from "@gemini-ui/design-system/utils/ScreenReaderOnly";

export function TableHeader({
  visiblyHidden,
  ...rest
}: {
  children: React.ReactNode;
  offset?: number;
  visiblyHidden?: boolean;
}) {
  return visiblyHidden ? (
    <ScreenReaderOnly data-testid={testIds.hiddenTableHeader} as="thead">
      {rest.children}
    </ScreenReaderOnly>
  ) : (
    <THead {...rest} />
  );
}
