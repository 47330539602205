import React from "react";
import { IconChevronLeftLarge } from "@hubble/icons";
import GlobalAlert from "@gemini-ui/components/GlobalAlert";
import { Button, Flex, SectionMessage, Spacing, Text } from "@gemini-ui/design-system";
import OnboardingPageLayout from "@gemini-ui/pages/register/OnboardingLayout/OnboardingPageLayout";
import SimpleFooter from "@gemini-ui/pages/register/OnboardingLayout/SimpleFooter";
import {
  BackButtonContainer,
  GeminiLogoContainer,
  InnerContainer,
  MainContent,
  RegisterContainer,
  RegisterHeaderLogo,
} from "@gemini-ui/pages/register/OnboardingLayout/styles";
import { OnboardingProps } from "@gemini-ui/pages/register/OnboardingLayout/types";
import { useIntl } from "@gemini-ui/utils/intl";

const signOutRoute = jsRoutes.controllers.account.Application.signOut().url;

const OnboardingLayout = (props: OnboardingProps) => {
  const {
    title,
    customTitle,
    subTitle,
    children,
    rightHeader,
    showBackBtn,
    backBtnPropsOverride,
    showSecurityKeyError,
    ["data-testid"]: testID,
    activeOnboardingState,
  } = props;
  const { intl } = useIntl();

  const isVerticalNavigationEnabled = Boolean(activeOnboardingState);
  return (
    <React.Fragment>
      <GlobalAlert />
      <GeminiLogoContainer justify="space-between">
        <RegisterHeaderLogo />

        {rightHeader && (
          <Flex alignItems="center" gap={Spacing.scale[2]}>
            {rightHeader}
          </Flex>
        )}
      </GeminiLogoContainer>

      {showBackBtn && (
        <BackButtonContainer>
          <Button.Secondary
            display="inline-flex"
            size="sm"
            icon={<IconChevronLeftLarge />}
            aria-label={intl.formatMessage({ defaultMessage: "Back to login" })}
            href={signOutRoute}
            {...backBtnPropsOverride}
          />
        </BackButtonContainer>
      )}

      {isVerticalNavigationEnabled ? (
        <OnboardingPageLayout {...props} />
      ) : (
        <React.Fragment>
          <RegisterContainer data-testid={testID}>
            <InnerContainer>
              <MainContent>
                <Flex.Column>
                  {customTitle || (
                    <Text.Heading size="lg" data-testid="title" mb={Boolean(subTitle) ? 1.5 : 1}>
                      {title}
                    </Text.Heading>
                  )}

                  {showSecurityKeyError && (
                    <SectionMessage
                      heading={intl.formatMessage({ defaultMessage: "Oops! There was an error" })}
                      mb={2}
                      statusType="alert"
                    >
                      {intl.formatMessage({
                        defaultMessage: "The security key prompt was either cancelled or timed out. Please try again.",
                      })}
                    </SectionMessage>
                  )}

                  {subTitle && (
                    <Text.Body size="sm" mb={3} mt={1} data-testid="subtitle">
                      {subTitle}
                    </Text.Body>
                  )}
                </Flex.Column>
                <Flex.Column align="left">{children}</Flex.Column>
              </MainContent>
              <SimpleFooter />
            </InnerContainer>
          </RegisterContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OnboardingLayout;
