import * as React from "react";
import { PaymentMethodNameContainer, TruncateTextContainer } from "@gemini-ui/components/PaymentMethodName/styles";
import { BankAccountType } from "@gemini-ui/constants/paymentMethods";

type PaymentMethodNamePropsType = {
  lastFour?: string;
  rawName?: string;
  institutionName?: string;
  accountType?: BankAccountType | string;
  additionalText?: string;
};

export const PaymentMethodName = ({
  lastFour = "",
  rawName,
  institutionName = "",
  accountType = "",
  additionalText = "",
}: PaymentMethodNamePropsType) => {
  if (!lastFour) {
    return <TruncateTextContainer>{rawName}</TruncateTextContainer>;
  }

  const displayName = `${institutionName}${institutionName && accountType ? " " : ""}${accountType} -`;
  return (
    <PaymentMethodNameContainer data-testid="payment-method-name">
      <TruncateTextContainer>{displayName}&nbsp;</TruncateTextContainer>
      {lastFour}
      {Boolean(additionalText) && <TruncateTextContainer>{additionalText}</TruncateTextContainer>}
    </PaymentMethodNameContainer>
  );
};
