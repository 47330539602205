import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import { applicationApi } from "@gemini-ui/client/api";
import { Application } from "@gemini-ui/client/credit";
import { XStateSend } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/v2/machines/creditAppMachine";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";

const formatPrefillDob = (dob: string) => {
  const date = new Date(dob.replace(/-/g, "/"));
  const formattedDob = format(new Date(date), DateFormats.MonthDayYear);
  return formattedDob;
};

export const handleGetPrefill = async (send: XStateSend) => {
  try {
    const { data: prefillData } = await applicationApi.getPrefillCustomerInformation();
    const { email, phone, address, ssn, dob } = prefillData;
    const formattedDob = dob ? formatPrefillDob(dob) : undefined;
    const appData = {
      email,
      phone,
      address,
      dob: formattedDob,
      ssn: { full: prefillData.ssn },
    } as Application;
    const { street, city, state, zip } = address;

    if (email && phone && street && city && state && zip && ssn && dob) {
      send({ type: ClientState.PREFILL_SUCCESS, appData });
    } else {
      send({ type: ClientState.MANUAL_ENTRY, appData });
    }
  } catch (error) {
    send(ClientState.MANUAL_ENTRY);
    Sentry.captureException(error);
  }
};
