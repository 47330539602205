import styled from "@emotion/styled";
import { Flex } from "@gemini-ui/design-system/Flex";
import { mediaQuery } from "@gemini-ui/design-system/primitives/media-queries";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";
import { SystemAlertVariant } from "@gemini-ui/design-system/SystemAlert/constants";
import { Text } from "@gemini-ui/design-system/Text";

const flexColStyles = `
    flex-direction: column;
    justify-content: center;
`;

const stackedButtonSpacing = `
  margin-top: ${Spacing.scale[1.5]};
  margin-left: ${Spacing.scale[4]};
`;

export const SystemAlertWrapper = styled(Flex)<{ variant: SystemAlertVariant; hasActionButton: boolean }>`
  position: relative;
  width: 100%;
  z-index: 102;
  user-select: text;
  background: ${({ variant, theme }) => theme.colorScheme.status.bold.background[variant]};
  color: ${({ theme }) => theme.colorScheme.content.primary};

  ${({ hasActionButton }) => {
    return hasActionButton && flexColStyles;
  }}

  @media ${mediaQuery.mobileXsDown} {
    ${flexColStyles};
  }
`;

export const SystemAlertButtonContainer = styled.div<{ hasActionButton: boolean }>`
  ${({ hasActionButton }) => {
    return hasActionButton && stackedButtonSpacing;
  }}

  @media ${mediaQuery.mobileXsDown} {
    ${stackedButtonSpacing}
  }
`;

export const SystemAlertText = styled(Text.Body)`
  max-width: 800px;
`;
