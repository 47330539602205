import { MutableRefObject } from "react";
import * as Sentry from "@sentry/browser";
import axios from "@gemini-ui/services/axios";
import { IntlShape } from "@gemini-ui/utils/intl";

export const DELAY = 180000;
export const INITIAL_FRAME = 1;
export const UNREAD_COUNT_COOKIE_NAME = "unreadCount";

export type ElementRefType = MutableRefObject<any>;

export type LatestUnreadId = ReadRequest["readAllId"] | undefined;

/**
 * Notification products are directly correlated to what notifications we receive
 * categorized under the possible values from notification.product in the notification
 * response (don't update this enum unless the API spec changes)
 */
export enum NotificationProduct {
  MarketAlerts = "MarketAlerts",
  CustomPriceAlerts = "CustomPriceAlerts",
}

/**
 * Query parameters for the /web/notifications/read API
 */
export interface ReadRequest {
  readAllId?: number;
  ids?: number[]; // Currently not used but reserved for future use
}

/**
 * Return data structure for the /web/notifications/count API
 */
export interface NotificationCountResponse {
  unreadCount: number;
  unreadMore: boolean;
  lastNotificationId: number;
}

/**
 * Notifications object from the /web/notifications/list API return data structure
 */
export interface Notifications {
  id: number;
  title: string;
  product: NotificationProduct;
  text: string;
  time: string;
  unread?: boolean;
  action: string;
}

/**
 * Query parameters for the /web/notifications/list API
 */
export interface NotificationRequest {
  limit?: number;
  continuationToken?: string;
}

/**
 * Return data structure for the /web/notifications/list API
 */
export interface NotificationResponse {
  notificationCount: NotificationCountResponse;
  notifications: Notifications[];
  continuationToken: string;
}

export interface NotificationPanelState {
  listItems: Notifications[];
}

export interface NotificationCenterState {
  isOpen: boolean;
  isLoading: boolean;
  isResetting: boolean;
  notificationState: NotificationResponse;
  panelState: NotificationPanelState;
  readParams: ReadRequest;
}

export const getComponentCopy = (intl: IntlShape) => ({
  HEADING: intl.formatMessage({ defaultMessage: "Notifications" }),
  SETTINGS_LABEL: intl.formatMessage({ defaultMessage: "Settings" }),
  NULL_TEXT: intl.formatMessage({ defaultMessage: "You do not have any notifications" }),
  NULL_CTA: intl.formatMessage({ defaultMessage: "Manage notifications" }),
  NULL_IMAGE_ALT: intl.formatMessage({ defaultMessage: "Notification bell" }),
});

export const getLatestUnreadId = (notifications: Notifications[]): LatestUnreadId => {
  const latestUnreadId = notifications.reduce(
    (maxId: number, { id, unread }) => (id > maxId && unread === true ? id : maxId),
    0
  );
  return latestUnreadId > 0 ? latestUnreadId : undefined;
};

/**
 * Mark all notifications as read using the latest unread notification ID, if any are unread yet
 */
export const markAsRead = async (params: ReadRequest) => {
  if (!params.readAllId) {
    return;
  }

  try {
    await axios.post(`${NOTIFICATIONS_ENDPOINT}/read`, params, { withCredentials: true });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const NOTIFICATIONS_ENDPOINT = "/web/notifications";

export const INITIAL_NOTIFICATION_STATE = {
  notificationCount: {
    unreadCount: 0,
    unreadMore: false,
    lastNotificationId: 0,
  },
  notifications: [],
  continuationToken: "",
};

export const INITIAL_PANEL_STATE = {
  listItems: [],
};

export const INITIAL_STATE = {
  isOpen: false,
  isLoading: true,
  isResetting: false,
  notificationState: INITIAL_NOTIFICATION_STATE,
  panelState: INITIAL_PANEL_STATE,
  readParams: {},
};

export const FEED = "feed";
