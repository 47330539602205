import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";
import { Flex } from "@gemini-ui/design-system/Flex";
import { ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";
import StepIndicator from "@gemini-ui/design-system/Timeline/StepIndicator";
import { List, Metadata, StepBody, StepStatus } from "@gemini-ui/design-system/Timeline/styles";
import { TimelineStepStatus } from "@gemini-ui/design-system/Timeline/types";
import animationStepStatus from "@gemini-ui/design-system/Timeline/utils/animationStepStatus";
import { ScreenReaderOnly } from "@gemini-ui/design-system/utils";
import { IntlShape, useIntl } from "@gemini-ui/utils/intl";

const STEP_ANIMATION_DELTA = 450;

interface Step {
  isCurrentStep?: boolean;
  label: string | React.ReactNode;
  metadata?: React.ReactNode;
  status?: TimelineStepStatus;
  sublabel?: React.ReactNode;
}

interface RequestAnimationFrameRef {
  id: number;
  timestamp: number;
}

export interface TimelineProps extends ShorthandSpacingCssProps {
  /**
   * Whether or not to animate from step to step.
   */
  animate?: boolean;
  /**
   * A step to start the animation from.
   */
  animateFromStep?: number;
  "data-testid": string;
  /**
   * All of the steps to show on the timeline where a step has a required `label`, `sublabel`, and `isCurrentStep`, and optional `metadata` and/or `status`.
   * No more than one step should have `isCurrentStep` set to `true` at any time.
   */
  steps: Step[];
}

function getStatus(
  step: number,
  status: TimelineStepStatus,
  animationStep: number,
  currentStepNumber: number,
  stepsCount: number,
  intl: IntlShape
) {
  const currentAnimationStatus = animationStepStatus(step, status, animationStep, currentStepNumber, stepsCount);
  const localizations = {
    [TimelineStepStatus.Alert]: intl.formatMessage({
      defaultMessage: "Alert",
    }),
    [TimelineStepStatus.InProgress]: intl.formatMessage({
      defaultMessage: "In progress",
    }),
    [TimelineStepStatus.Success]: intl.formatMessage({
      defaultMessage: "Success",
    }),
  };

  return localizations[currentAnimationStatus];
}

export const Timeline = ({ animate = true, animateFromStep = 0, steps, ...rest }: TimelineProps) => {
  const currentStepIndex = steps.findIndex(step => step.isCurrentStep);
  const currentStepNumber = currentStepIndex === -1 ? 0 : currentStepIndex + 1;
  const { intl } = useIntl();
  const requestAnimationFrameRef = useRef<RequestAnimationFrameRef>({ id: 0, timestamp: 0 });
  const previousCurrentStep = usePrevious(currentStepNumber);
  const [animating, setAnimating] = useState(animate && currentStepNumber !== 0);
  const [animationStep, setAnimationStep] = useState(animate ? animateFromStep : currentStepNumber);
  const startAnimation = useCallback(() => {
    function playAnimation(timestamp: number) {
      if (!requestAnimationFrameRef.current.timestamp) {
        requestAnimationFrameRef.current.timestamp = timestamp;
      }

      const interval = timestamp - requestAnimationFrameRef.current.timestamp;

      if (interval > STEP_ANIMATION_DELTA) {
        requestAnimationFrameRef.current.timestamp = timestamp;
        if (animationStep < currentStepNumber) {
          setAnimationStep(animationStep + 1);
        } else if (animationStep > currentStepNumber) {
          setAnimationStep(animationStep - 1);
        } else {
          setAnimating(false);
        }
      } else if (animating) {
        requestAnimationFrameRef.current.id = requestAnimationFrame(playAnimation);
      }
    }

    requestAnimationFrameRef.current.id = requestAnimationFrame(playAnimation);
  }, [animationStep, currentStepNumber, animating]);

  useEffect(() => {
    const currentAnimationRef = requestAnimationFrameRef.current;
    if (animating) {
      startAnimation();
    } else {
      cancelAnimationFrame(currentAnimationRef.id);
    }
    return () => cancelAnimationFrame(currentAnimationRef.id);
  }, [animating, startAnimation]);

  useEffect(() => {
    if (animate && !animating && previousCurrentStep !== currentStepNumber) {
      setAnimating(true);
    }
  }, [animating, animate, previousCurrentStep, currentStepNumber]);

  return (
    <List {...rest}>
      {steps.map((step, index) => {
        const StepLabel = typeof step.label === "string" ? <Text.Body bold>{step.label}</Text.Body> : step.label;
        return (
          <li
            data-testid={`timeline-step-${index + 1}${animationStep === index + 1 ? "-current" : ""}`}
            key={`timeline-step-${step.label}`}
          >
            <Flex>
              <StepStatus flexDirection="column" alignItems="center">
                <StepIndicator
                  step={index + 1}
                  status={step.status}
                  stepsCount={steps.length}
                  animationStep={animationStep}
                  currentStepNumber={currentStepNumber}
                />
              </StepStatus>
              <StepBody>
                <ScreenReaderOnly>
                  {getStatus(index + 1, step.status, animationStep, currentStepNumber, steps.length, intl)}
                </ScreenReaderOnly>
                {StepLabel}
                <Text.Body size="sm">{step.sublabel}</Text.Body>
                {step.metadata ? <Metadata size="xs">{step.metadata}</Metadata> : null}
              </StepBody>
            </Flex>
          </li>
        );
      })}
    </List>
  );
};
