import { spacing } from "@hubble/tokens/web";

const scale = {
  /**
   * @deprecated Non-standard spacing.
   */
  0.25: "2px",
  0.5: spacing["4"],
  /**
   * @deprecated Non-standard spacing.
   */
  0: "0",
  1: spacing[8],
  1.5: spacing[12],
  2: spacing[16],
  2.5: spacing[20],
  3: spacing[24],
  /**
   * @deprecated Non-standard spacing.
   */
  3.5: "28px",
  4: spacing[32],
  5: spacing[40],
  6: spacing[48],
  /**
   * @deprecated Non-standard spacing.
   */
  7: "56px",
  8: spacing[64],
  /**
   * @deprecated Non-standard spacing.
   */
  9: "72px",
  10: spacing[80],
  /**
   * @deprecated Non-standard spacing.
   */
  11: "88px",
  12: spacing[96],
  /**
   * @deprecated Non-standard spacing.
   */
  13: "104px",
  /**
   * @deprecated Non-standard spacing.
   */
  14: "112px",
  /**
   * @deprecated Non-standard spacing.
   */
  15: "120px",
  /**
   * @deprecated Non-standard spacing.
   */
  16: "128px",
  /**
   * @deprecated Non-standard spacing.
   */
  17: "136px",
  /**
   * @deprecated Non-standard spacing.
   */
  18: "144px",
  /**
   * @deprecated Non-standard spacing.
   */
  19: "152px",
  /**
   * @deprecated Non-standard spacing.
   */
  20: "160px",
  auto: "auto",
} as const;

export const Spacing = {
  scale,
};
