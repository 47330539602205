import { orderBy } from "lodash";
import { BankType, DebitCardType } from "@gemini-ui/transformers/PaymentMethods";

/**
 * Order debit cards by the following criteria:
 *
 * 1.) "Approved" cards appear first
 *
 * 2.) Then sorted by "lastUsedAt"
 */
export const orderDebitCards = (cardProps: DebitCardType[]) => {
  return orderBy(
    cardProps,
    [
      x => x.approvalState === "3DSRequired" || x.approvalState === "Pending" || x.approvalState === "Rejected",
      x => x.lastUsedAt,
    ],
    ["asc", "desc"]
  );
};

/**
 * Order ACH by the following criteria:
 *
 * 1.) "Approved" accounts appear first
 *
 * 2.) Then sorted by "lastUsedAt"
 */
export const orderAchMethods = (banks: BankType[]) => {
  return orderBy(banks, [x => x.achLevel === "Approved", x => x.lastUsedAt], ["desc", "desc"]);
};
