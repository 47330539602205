/* tslint:disable */
/* eslint-disable */
/**
 * Cryptoback - OpenAPI 3.0
 * Cryptoback API specification  Docs: - [Wiki](https://gemini-spaceship.atlassian.net/wiki/spaces/DEV/pages/344064013/Credit+Card)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: vince.depalma@gemini.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {ActivityTypeEnum}
     * @memberof Activity
     */
    type: ActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    creditCardId: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    creditUserId: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    lastFourDigits?: string;
    /**
     * 
     * @type {Array<ActivityReward>}
     * @memberof Activity
     */
    rewards?: Array<ActivityReward>;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Activity
     */
    amount: ValueDetail;
    /**
     * 
     * @type {ActivityLogo}
     * @memberof Activity
     */
    logo?: ActivityLogo;
    /**
     * 
     * @type {ActivityStatusEnum}
     * @memberof Activity
     */
    status: ActivityStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    timestamp: string;
}
/**
 * 
 * @export
 * @interface ActivityLogo
 */
export interface ActivityLogo {
    /**
     * 
     * @type {string}
     * @memberof ActivityLogo
     */
    nameAndLocation: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogo
     */
    name?: string;
    /**
     * 
     * @type {MerchantCategory}
     * @memberof ActivityLogo
     */
    category?: MerchantCategory;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogo
     */
    logoUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogo
     */
    websiteUrl?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityResponse
 */
export interface ActivityResponse {
    /**
     * 
     * @type {ActivityResponsePagination}
     * @memberof ActivityResponse
     */
    pagination?: ActivityResponsePagination;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityResponse
     */
    activity?: Array<Activity>;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityResponse
     */
    pendingActivity?: Array<Activity>;
}
/**
 * 
 * @export
 * @interface ActivityResponsePagination
 */
export interface ActivityResponsePagination {
    /**
     * 
     * @type {number}
     * @memberof ActivityResponsePagination
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityResponsePagination
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityResponsePagination
     */
    hasNext?: boolean;
}
/**
 * 
 * @export
 * @interface ActivityReward
 */
export interface ActivityReward {
    /**
     * 
     * @type {number}
     * @memberof ActivityReward
     */
    rate?: number;
    /**
     * 
     * @type {RewardVendor}
     * @memberof ActivityReward
     */
    rewardVendor?: RewardVendor;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityReward
     */
    isInstantReward?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityStatusEnum {
    Settled = 'SETTLED',
    Disputed = 'DISPUTED',
    Pending = 'PENDING'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityTypeEnum {
    Transaction = 'TRANSACTION',
    Payment = 'PAYMENT',
    Interest = 'INTEREST',
    Fee = 'FEE'
}

/**
 * 
 * @export
 * @interface AddBankAccountInputValidationError
 */
export interface AddBankAccountInputValidationError {
    /**
     * 
     * @type {number}
     * @memberof AddBankAccountInputValidationError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountInputValidationError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AddBankAccountRequest
 */
export interface AddBankAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountRequest
     */
    creditCardId: string;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountRequest
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountRequest
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountRequest
     */
    routingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountRequest
     */
    nameOnAccount: string;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountRequest
     */
    accountType: AddBankAccountRequestAccountTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AddBankAccountRequestAccountTypeEnum {
    Savings = 'savings',
    Checking = 'checking'
}

/**
 * 
 * @export
 * @interface AddBankAccountResponse
 */
export interface AddBankAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountResponse
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddBankAccountResponse
     */
    referenceId?: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    apt?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    didSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    gRecaptchaResponse?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    id?: string;
    /**
     * 
     * @type {Product}
     * @memberof Application
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    creditPull?: ApplicationCreditPullEnum;
    /**
     * 
     * @type {Name}
     * @memberof Application
     */
    name?: Name;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    password?: string;
    /**
     * 
     * @type {Address}
     * @memberof Application
     */
    address?: Address;
    /**
     * 
     * @type {SSN}
     * @memberof Application
     */
    ssn?: SSN;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    grossAnnualIncome?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    cardDesign?: string;
    /**
     * 
     * @type {Disclosures}
     * @memberof Application
     */
    disclosures?: Disclosures;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    affiliateClickId?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    mixpanelId?: string;
    /**
     * 
     * @type {ValidationIds}
     * @memberof Application
     */
    validationIds?: ValidationIds;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationCreditPullEnum {
    SoftPull = 'SOFT_PULL',
    HardPull = 'HARD_PULL'
}

/**
 * 
 * @export
 * @interface ApplicationFromPrefill
 */
export interface ApplicationFromPrefill {
    /**
     * 
     * @type {Name}
     * @memberof ApplicationFromPrefill
     */
    name?: Name;
    /**
     * 
     * @type {Address}
     * @memberof ApplicationFromPrefill
     */
    address: Address;
    /**
     * 
     * @type {SSN}
     * @memberof ApplicationFromPrefill
     */
    ssn: SSN;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFromPrefill
     */
    dob: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFromPrefill
     */
    phone?: string;
    /**
     * 
     * @type {Disclosures}
     * @memberof ApplicationFromPrefill
     */
    disclosures: Disclosures;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFromPrefill
     */
    affiliateClickId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFromPrefill
     */
    mixpanelId?: string;
    /**
     * 
     * @type {ValidationIds}
     * @memberof ApplicationFromPrefill
     */
    validationIds?: ValidationIds;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFromPrefill
     */
    didSessionId?: string;
}
/**
 * 
 * @export
 * @interface ApplicationPrefill
 */
export interface ApplicationPrefill {
    /**
     * 
     * @type {Name}
     * @memberof ApplicationPrefill
     */
    name?: Name;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPrefill
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPrefill
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPrefill
     */
    ssnLastFour: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPrefill
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPrefill
     */
    affiliateClickId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPrefill
     */
    mixpanelId?: string;
    /**
     * 
     * @type {ValidationIds}
     * @memberof ApplicationPrefill
     */
    validationIds?: ValidationIds;
}
/**
 * 
 * @export
 * @interface ApplicationStatus
 */
export interface ApplicationStatus {
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    decision?: ApplicationStatusDecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    offerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    registrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    passToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationStatus
     */
    isNewUser?: boolean;
    /**
     * 
     * @type {Array<ApplicationStatusState>}
     * @memberof ApplicationStatus
     */
    statusHistory?: Array<ApplicationStatusState>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    didSessionId?: string;
    /**
     * 
     * @type {CardColorEnum}
     * @memberof ApplicationStatus
     */
    cardDesign?: CardColorEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplicationStatus
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    phone?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationStatusDecisionEnum {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    Denied = 'DENIED'
}

/**
 * 
 * @export
 * @interface ApplicationStatusState
 */
export interface ApplicationStatusState {
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatusState
     */
    state?: ApplicationStatusStateStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatusState
     */
    transitionedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationStatusStateStateEnum {
    NewApplication = 'NEW_APPLICATION',
    PhoneVerified = 'PHONE_VERIFIED',
    EmailVerified = 'EMAIL_VERIFIED',
    ConfirmIdentity = 'CONFIRM_IDENTITY',
    SoftPullApproved = 'SOFT_PULL_APPROVED',
    SoftPullDenied = 'SOFT_PULL_DENIED',
    SoftPullDeceased = 'SOFT_PULL_DECEASED',
    SoftPullFrozen = 'SOFT_PULL_FROZEN',
    KycMismatch = 'KYC_MISMATCH',
    KycFailed = 'KYC_FAILED',
    KycManualReview = 'KYC_MANUAL_REVIEW',
    HardPullDeceased = 'HARD_PULL_DECEASED',
    HardPullFrozen = 'HARD_PULL_FROZEN',
    HardPullDenied = 'HARD_PULL_DENIED',
    HardPullApproved = 'HARD_PULL_APPROVED',
    OfferExtended = 'OFFER_EXTENDED',
    CardDesignSelected = 'CARD_DESIGN_SELECTED',
    MoreInfo = 'MORE_INFO',
    MoreInfoPending = 'MORE_INFO_PENDING',
    MoreInfoDenied = 'MORE_INFO_DENIED',
    OfacApproved = 'OFAC_APPROVED',
    OfacDenied = 'OFAC_DENIED',
    SsnBlocklisted = 'SSN_BLOCKLISTED',
    KycServiceFailure = 'KYC_SERVICE_FAILURE',
    SoftPullServiceFailure = 'SOFT_PULL_SERVICE_FAILURE',
    HardPullServiceFailure = 'HARD_PULL_SERVICE_FAILURE',
    SoftPullExtendedFraudAlert = 'SOFT_PULL_EXTENDED_FRAUD_ALERT',
    BiometricPassed = 'BIOMETRIC_PASSED',
    BiometricDenied = 'BIOMETRIC_DENIED',
    BiometricManual = 'BIOMETRIC_MANUAL',
    PhoneVerificationFailed = 'PHONE_VERIFICATION_FAILED',
    PhoneVerificationIndeterminate = 'PHONE_VERIFICATION_INDETERMINATE',
    PhoneTrustScoreFailed = 'PHONE_TRUST_SCORE_FAILED',
    PhoneTrustScoreIndeterminate = 'PHONE_TRUST_SCORE_INDETERMINATE',
    PhoneTrustScoreVerified = 'PHONE_TRUST_SCORE_VERIFIED',
    PendingDocuments = 'PENDING_DOCUMENTS',
    ApplicationPreviouslyDenied = 'APPLICATION_PREVIOUSLY_DENIED'
}

/**
 * 
 * @export
 * @interface AuthUserApplicationPrefill
 */
export interface AuthUserApplicationPrefill {
    /**
     * 
     * @type {Name}
     * @memberof AuthUserApplicationPrefill
     */
    name?: Name;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    ssnLastFour: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    affiliateClickId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    mixpanelId?: string;
    /**
     * 
     * @type {ValidationIds}
     * @memberof AuthUserApplicationPrefill
     */
    validationIds?: ValidationIds;
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefill
     */
    invitationId: string;
}
/**
 * 
 * @export
 * @interface AuthUserApplicationPrefillAllOf
 */
export interface AuthUserApplicationPrefillAllOf {
    /**
     * 
     * @type {string}
     * @memberof AuthUserApplicationPrefillAllOf
     */
    invitationId: string;
}
/**
 * 
 * @export
 * @interface AuthUserInvitationResponse
 */
export interface AuthUserInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    birthdate: string;
    /**
     * 
     * @type {AuthUserInviteStatus}
     * @memberof AuthUserInvitationResponse
     */
    invitationStatus: AuthUserInviteStatus;
    /**
     * 
     * @type {InvitationDisplayStatus}
     * @memberof AuthUserInvitationResponse
     */
    displayStatus: InvitationDisplayStatus;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    primaryFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    primaryLastName?: string;
    /**
     * 
     * @type {AvatarTheme}
     * @memberof AuthUserInvitationResponse
     */
    avatarTheme: AvatarTheme;
    /**
     * Hashed credit user id, only present if invitationStatus is CARD_ISSUED
     * @type {string}
     * @memberof AuthUserInvitationResponse
     */
    creditUserId?: string;
}
/**
 * 
 * @export
 * @interface AuthUserInvite
 */
export interface AuthUserInvite {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvite
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvite
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvite
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInvite
     */
    dob: string;
}
/**
 * 
 * @export
 * @interface AuthUserInviteConfirmation
 */
export interface AuthUserInviteConfirmation {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInviteConfirmation
     */
    action: AuthUserInviteConfirmationActionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AuthUserInviteConfirmationActionEnum {
    Accept = 'ACCEPT',
    Decline = 'DECLINE'
}

/**
 * 
 * @export
 * @interface AuthUserInviteErrorDetails
 */
export interface AuthUserInviteErrorDetails {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInviteErrorDetails
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInviteErrorDetails
     */
    body: string;
}
/**
 * 
 * @export
 * @interface AuthUserInviteStatus
 */
export interface AuthUserInviteStatus {
    /**
     * 
     * @type {InvitationStatus}
     * @memberof AuthUserInviteStatus
     */
    status: InvitationStatus;
    /**
     * 
     * @type {AuthUserInviteErrorDetails}
     * @memberof AuthUserInviteStatus
     */
    error?: AuthUserInviteErrorDetails;
}
/**
 * 
 * @export
 * @interface AuthUserResponse
 */
export interface AuthUserResponse {
    /**
     * Hashed credit user id
     * @type {string}
     * @memberof AuthUserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserResponse
     */
    lastName: string;
    /**
     * 
     * @type {AvatarTheme}
     * @memberof AuthUserResponse
     */
    avatarTheme: AvatarTheme;
    /**
     * 
     * @type {string}
     * @memberof AuthUserResponse
     */
    email: string;
    /**
     * 
     * @type {Address}
     * @memberof AuthUserResponse
     */
    address: Address;
    /**
     * 
     * @type {InvitationStatus}
     * @memberof AuthUserResponse
     */
    invitationStatus: InvitationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserResponse
     */
    cardLocked: boolean;
    /**
     * Hashed credit card id
     * @type {string}
     * @memberof AuthUserResponse
     */
    cardId: string;
    /**
     * The last 4 digits of AU card number
     * @type {string}
     * @memberof AuthUserResponse
     */
    cardLastFour?: string;
    /**
     * 
     * @type {Array<NotificationSettings>}
     * @memberof AuthUserResponse
     */
    notifications: Array<NotificationSettings>;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserResponse
     */
    cashAdvance?: boolean;
    /**
     * 
     * @type {TransactionLimitRequest}
     * @memberof AuthUserResponse
     */
    transactionLimit?: TransactionLimitRequest;
}
/**
 * 
 * @export
 * @interface AuthorizedUser
 */
export interface AuthorizedUser {
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUser
     */
    dob: string;
    /**
     * 
     * @type {AvatarTheme}
     * @memberof AuthorizedUser
     */
    avatarTheme: AvatarTheme;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AvatarTheme {
    Blue = 'BLUE',
    Navy = 'NAVY',
    Lime = 'LIME',
    Orange = 'ORANGE',
    Purple = 'PURPLE'
}

/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    creditAccountId?: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Balance
     */
    currentBalance?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Balance
     */
    pastDueBalance?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Balance
     */
    availableCredit?: ValueDetail;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BalanceChangeReason {
    PurchaseReturn = 'PURCHASE_RETURN',
    AgeOff = 'AGE_OFF',
    AuthReversal = 'AUTH_REVERSAL',
    ClearingReversal = 'CLEARING_REVERSAL',
    PurchaseReversal = 'PURCHASE_REVERSAL',
    DisputeApproved = 'DISPUTE_APPROVED',
    Admin = 'ADMIN',
    AuthGreaterThanClearing = 'AUTH_GREATER_THAN_CLEARING'
}

/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    lastUpdatedAt?: string;
    /**
     * 
     * @type {BankAccountTypeEnum}
     * @memberof BankAccount
     */
    accountType?: BankAccountTypeEnum;
}
/**
 * Bank account type
 * @export
 * @enum {string}
 */
export enum BankAccountTypeEnum {
    Checking = 'Checking',
    Savings = 'Savings'
}

/**
 * 
 * @export
 * @interface BlockCardRequest
 */
export interface BlockCardRequest {
    /**
     * 
     * @type {string}
     * @memberof BlockCardRequest
     */
    cardId?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockCardRequest
     */
    reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BlockCardRequest
     */
    cardBlocked?: boolean;
}
/**
 * 
 * @export
 * @interface CBAlreadyApprovedForProduct
 */
export interface CBAlreadyApprovedForProduct {
    /**
     * 
     * @type {number}
     * @memberof CBAlreadyApprovedForProduct
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBAlreadyApprovedForProduct
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBApplicationBlocklisted
 */
export interface CBApplicationBlocklisted {
    /**
     * 
     * @type {number}
     * @memberof CBApplicationBlocklisted
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBApplicationBlocklisted
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBApplicationExpired
 */
export interface CBApplicationExpired {
    /**
     * 
     * @type {number}
     * @memberof CBApplicationExpired
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBApplicationExpired
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBApplicationNotFound
 */
export interface CBApplicationNotFound {
    /**
     * 
     * @type {number}
     * @memberof CBApplicationNotFound
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBApplicationNotFound
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBApplicationRateLimitHit
 */
export interface CBApplicationRateLimitHit {
    /**
     * 
     * @type {number}
     * @memberof CBApplicationRateLimitHit
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBApplicationRateLimitHit
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBCannotUpdateCompletedApplication
 */
export interface CBCannotUpdateCompletedApplication {
    /**
     * 
     * @type {number}
     * @memberof CBCannotUpdateCompletedApplication
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBCannotUpdateCompletedApplication
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBCorecardError
 */
export interface CBCorecardError {
    /**
     * 
     * @type {number}
     * @memberof CBCorecardError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBCorecardError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBDisclosureMismatch
 */
export interface CBDisclosureMismatch {
    /**
     * 
     * @type {number}
     * @memberof CBDisclosureMismatch
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBDisclosureMismatch
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof CBDisclosureMismatch
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CBDisclosureMismatch
     */
    expected_version?: string;
    /**
     * 
     * @type {string}
     * @memberof CBDisclosureMismatch
     */
    found_version?: string;
    /**
     * 
     * @type {string}
     * @memberof CBDisclosureMismatch
     */
    new_disclosure_text?: string;
}
/**
 * 
 * @export
 * @interface CBDoesNotMeetMinimumRequiredGeminiTier
 */
export interface CBDoesNotMeetMinimumRequiredGeminiTier {
    /**
     * 
     * @type {number}
     * @memberof CBDoesNotMeetMinimumRequiredGeminiTier
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBDoesNotMeetMinimumRequiredGeminiTier
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBGeminiAccountRequired
 */
export interface CBGeminiAccountRequired {
    /**
     * 
     * @type {number}
     * @memberof CBGeminiAccountRequired
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBGeminiAccountRequired
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBMissingApplicationFragments
 */
export interface CBMissingApplicationFragments {
    /**
     * 
     * @type {number}
     * @memberof CBMissingApplicationFragments
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBMissingApplicationFragments
     */
    message?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CBMissingApplicationFragments
     */
    missing_fragments?: Array<string>;
}
/**
 * 
 * @export
 * @interface CBMissingDisclosures
 */
export interface CBMissingDisclosures {
    /**
     * 
     * @type {number}
     * @memberof CBMissingDisclosures
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBMissingDisclosures
     */
    message?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CBMissingDisclosures
     */
    types?: Array<string>;
}
/**
 * 
 * @export
 * @interface CBMissingId
 */
export interface CBMissingId {
    /**
     * 
     * @type {number}
     * @memberof CBMissingId
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBMissingId
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBNewApplicationsDisabled
 */
export interface CBNewApplicationsDisabled {
    /**
     * 
     * @type {number}
     * @memberof CBNewApplicationsDisabled
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBNewApplicationsDisabled
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBPrerequisitesNotMet
 */
export interface CBPrerequisitesNotMet {
    /**
     * 
     * @type {number}
     * @memberof CBPrerequisitesNotMet
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBPrerequisitesNotMet
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBProductNotFound
 */
export interface CBProductNotFound {
    /**
     * 
     * @type {number}
     * @memberof CBProductNotFound
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBProductNotFound
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBReappliedTooSoon
 */
export interface CBReappliedTooSoon {
    /**
     * 
     * @type {number}
     * @memberof CBReappliedTooSoon
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBReappliedTooSoon
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBTooRecentlySubmitted
 */
export interface CBTooRecentlySubmitted {
    /**
     * 
     * @type {number}
     * @memberof CBTooRecentlySubmitted
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBTooRecentlySubmitted
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CBTransactionAlreadyDisputed
 */
export interface CBTransactionAlreadyDisputed {
    /**
     * 
     * @type {number}
     * @memberof CBTransactionAlreadyDisputed
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CBTransactionAlreadyDisputed
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    cardId: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    creditAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    panLast4: string;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    isActivated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    canBeActivated: boolean;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    expirationYear: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    expirationMonth: string;
    /**
     * 
     * @type {CardStatusEnum}
     * @memberof Card
     */
    status: CardStatusEnum;
    /**
     * 
     * @type {CardColorEnum}
     * @memberof Card
     */
    cardDesign: CardColorEnum;
    /**
     * optional value equal to n where n = the nth card for a given admin number.
     * @type {number}
     * @memberof Card
     */
    internalSequenceNumber: number;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    isNearExpiration?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    needsReProvision?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    hasExpired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    hasShipped: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    hasBeenReplaced: boolean;
}
/**
 * Color choice of card
 * @export
 * @enum {string}
 */
export enum CardColorEnum {
    Black = 'BLACK',
    Silver = 'SILVER',
    Rose = 'ROSE'
}

/**
 * 
 * @export
 * @interface CardSpendPromotionResponse
 */
export interface CardSpendPromotionResponse {
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    status?: CardSpendPromotionResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    userStartedPromoAt?: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CardSpendPromotionResponse
     */
    spendRemaining?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    spendRemainingAsOfDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CardSpendPromotionResponse
     */
    statementsCompleted?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CardSpendPromotionResponse
     */
    hasMetSpendThreshold?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    subtype?: CardSpendPromotionResponseSubtypeEnum;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CardSpendPromotionResponse
     */
    spendRequired?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CardSpendPromotionResponse
     */
    reward?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof CardSpendPromotionResponse
     */
    rewardDisbursedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CardSpendPromotionResponseStatusEnum {
    Pending = 'pending',
    Eligible = 'eligible',
    Credited = 'credited',
    RejectedLowSpend = 'rejected_low_spend',
    RejectedDelinquent = 'rejected_delinquent',
    RejectedClosedAccount = 'rejected_closed_account',
    RejectedOther = 'rejected_other'
}
/**
    * @export
    * @enum {string}
    */
export enum CardSpendPromotionResponseSubtypeEnum {
    NewUser = 'new_user',
    AuthUser = 'auth_user'
}

/**
 * Status type for card
 * @export
 * @enum {string}
 */
export enum CardStatusEnum {
    Blocked = 'BLOCKED',
    Active = 'ACTIVE',
    Closed = 'CLOSED',
    LockedByCustomer = 'LOCKED_BY_CUSTOMER'
}

/**
 * 
 * @export
 * @interface CashAdvanceRequest
 */
export interface CashAdvanceRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CashAdvanceRequest
     */
    cashAdvance: boolean;
}
/**
 * 
 * @export
 * @interface CreateAuthUserCardRequest
 */
export interface CreateAuthUserCardRequest {
    /**
     * 
     * @type {CardColorEnum}
     * @memberof CreateAuthUserCardRequest
     */
    cardDesign: CardColorEnum;
}
/**
 * 
 * @export
 * @interface CreditAccount
 */
export interface CreditAccount {
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    creditAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    creditUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    creditUserRole: CreditAccountCreditUserRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CreditAccount
     */
    apr: number;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccount
     */
    creditLimit: ValueDetail;
    /**
     * 
     * @type {boolean}
     * @memberof CreditAccount
     */
    cardDetailsUnlocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    status: CreditAccountStatusEnum;
    /**
     * 
     * @type {Array<StatusAlert>}
     * @memberof CreditAccount
     */
    alerts: Array<StatusAlert>;
    /**
     * 
     * @type {CreditAccountBalances}
     * @memberof CreditAccount
     */
    balances?: CreditAccountBalances;
    /**
     * 
     * @type {boolean}
     * @memberof CreditAccount
     */
    paperless: boolean;
    /**
     * 
     * @type {Name}
     * @memberof CreditAccount
     */
    name: Name;
    /**
     * 
     * @type {TermsOfAgreementResponse}
     * @memberof CreditAccount
     */
    termsOfAgreement?: TermsOfAgreementResponse;
    /**
     * 
     * @type {Address}
     * @memberof CreditAccount
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    openedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    doshUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditAccount
     */
    doshEnabled?: boolean;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccount
     */
    temporaryCreditLimit?: ValueDetail;
    /**
     * returns true if user is allowed to change the due date
     * @type {boolean}
     * @memberof CreditAccount
     */
    canChangeDueDate?: boolean;
    /**
     * returns true if it has been 90 days since last change
     * @type {boolean}
     * @memberof CreditAccount
     */
    hasChangedWithinCooldownPeriod?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditAccount
     */
    lastBillingCycleChangedAt?: string;
    /**
     * represents nth day of the month when payment is due. options are 1, 5, 9, 13, 17, 22, 26.
     * @type {number}
     * @memberof CreditAccount
     */
    dueDateDay?: number;
    /**
     * pending due date that will take effect in 1 to 2 billing cycles. options are 1, 5, 9, 13, 17, 22, 26.
     * @type {number}
     * @memberof CreditAccount
     */
    pendingDueDateDay?: number;
    /**
     * is an auto payment scheduled for this account
     * @type {boolean}
     * @memberof CreditAccount
     */
    isAutopayEnabled?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreditAccountCreditUserRoleEnum {
    Primary = 'primary',
    Secondary = 'secondary'
}
/**
    * @export
    * @enum {string}
    */
export enum CreditAccountStatusEnum {
    Active = 'ACTIVE',
    Delinquent = 'DELINQUENT',
    Closed = 'CLOSED',
    Suspended = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface CreditAccountBalances
 */
export interface CreditAccountBalances {
    /**
     * 
     * @type {Currency}
     * @memberof CreditAccountBalances
     */
    currency: Currency;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    totalAmountDue: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    minimumDue: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    currentBalance: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    availableBalance: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    statementRunningBalance: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof CreditAccountBalances
     */
    nextStatementDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreditAccountBalances
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    totalPastDue: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    amountOverLimit: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditAccountBalances
     */
    remainingCycleSpendLimit: ValueDetail;
}
/**
 * 
 * @export
 * @interface CreditAccountId
 */
export interface CreditAccountId {
    /**
     * 
     * @type {string}
     * @memberof CreditAccountId
     */
    creditAccountId?: string;
}
/**
 * 
 * @export
 * @interface CreditIncreaseEligibility
 */
export interface CreditIncreaseEligibility {
    /**
     * 
     * @type {boolean}
     * @memberof CreditIncreaseEligibility
     */
    isEligible: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditIncreaseEligibility
     */
    offerProgress?: CreditIncreaseEligibilityOfferProgressEnum;
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditIncreaseEligibility
     */
    maximumIncreaseOffered?: ValueDetail;
}

/**
    * @export
    * @enum {string}
    */
export enum CreditIncreaseEligibilityOfferProgressEnum {
    OfferNotSeen = 'OFFER_NOT_SEEN',
    OfferDismissed = 'OFFER_DISMISSED',
    OfferRequested = 'OFFER_REQUESTED',
    IncomeSubmitted = 'INCOME_SUBMITTED'
}

/**
 * 
 * @export
 * @interface CreditLineIncreaseSubmitSuccessResponse
 */
export interface CreditLineIncreaseSubmitSuccessResponse {
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditLineIncreaseSubmitSuccessResponse
     */
    newCreditLimit?: ValueDetail;
}
/**
 * 
 * @export
 * @interface CreditLineIncreaseValidateIncomeRequest
 */
export interface CreditLineIncreaseValidateIncomeRequest {
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditLineIncreaseValidateIncomeRequest
     */
    newIncome?: ValueDetail;
}
/**
 * 
 * @export
 * @interface CreditLineIncreaseValidateSuccessResponse
 */
export interface CreditLineIncreaseValidateSuccessResponse {
    /**
     * 
     * @type {ValueDetail}
     * @memberof CreditLineIncreaseValidateSuccessResponse
     */
    maximumIncreaseOffered?: ValueDetail;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Currency {
    Usd = 'USD',
    Zrx = 'ZRX',
    Dot = 'DOT',
    Aave = 'AAVE',
    Alcx = 'ALCX',
    Amp = 'AMP',
    Ankr = 'ANKR',
    Avax = 'AVAX',
    Api3 = 'API3',
    Axs = 'AXS',
    Bond = 'BOND',
    Bat = 'BAT',
    Bch = 'BCH',
    Btc = 'BTC',
    Ape = 'APE',
    Link = 'LINK',
    Chz = 'CHZ',
    Comp = 'COMP',
    Ctx = 'CTX',
    Crv = 'CRV',
    Dai = 'DAI',
    Mana = 'MANA',
    Doge = 'DOGE',
    Elon = 'ELON',
    Enj = 'ENJ',
    Eth = 'ETH',
    Ftm = 'FTM',
    Fet = 'FET',
    Fil = 'FIL',
    Grt = 'GRT',
    Gusd = 'GUSD',
    Knc = 'KNC',
    Ltc = 'LTC',
    Lpt = 'LPT',
    Lrc = 'LRC',
    Mkr = 'MKR',
    Mask = 'MASK',
    Matic = 'MATIC',
    Nmr = 'NMR',
    _1Inch = '1INCH',
    Oxt = 'OXT',
    Paxg = 'PAXG',
    Qrdo = 'QRDO',
    Qnt = 'QNT',
    Rad = 'RAD',
    Ren = 'REN',
    Shib = 'SHIB',
    Skl = 'SKL',
    Slp = 'SLP',
    Sol = 'SOL',
    Cube = 'CUBE',
    Storj = 'STORJ',
    Sushi = 'SUSHI',
    Snx = 'SNX',
    Xtz = 'XTZ',
    Sand = 'SAND',
    Uma = 'UMA',
    Uni = 'UNI',
    Usdc = 'USDC',
    Wcfg = 'WCFG',
    Yfi = 'YFI',
    Zec = 'ZEC'
}

/**
 * 
 * @export
 * @interface Disclosure
 */
export interface Disclosure {
    /**
     * 
     * @type {boolean}
     * @memberof Disclosure
     */
    consent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Disclosure
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Disclosure
     */
    docVersion?: string;
}
/**
 * 
 * @export
 * @interface Disclosures
 */
export interface Disclosures {
    /**
     * 
     * @type {Disclosure}
     * @memberof Disclosures
     */
    auto_dialer?: Disclosure;
    /**
     * 
     * @type {Disclosure}
     * @memberof Disclosures
     */
    esign_agreement?: Disclosure;
    /**
     * 
     * @type {Disclosure}
     * @memberof Disclosures
     */
    privacy_policy?: Disclosure;
    /**
     * 
     * @type {Disclosure}
     * @memberof Disclosures
     */
    site_policy?: Disclosure;
    /**
     * 
     * @type {Disclosure}
     * @memberof Disclosures
     */
    credit_report_authorization?: Disclosure;
    /**
     * 
     * @type {Disclosure}
     * @memberof Disclosures
     */
    cardholder_agreement?: Disclosure;
}
/**
 * 
 * @export
 * @interface DisplayReward
 */
export interface DisplayReward {
    /**
     * 
     * @type {string}
     * @memberof DisplayReward
     */
    transactionId?: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof DisplayReward
     */
    netFiatAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof DisplayReward
     */
    adjustmentAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof DisplayReward
     */
    netCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof DisplayReward
     */
    effectivePrice?: ValueDetail;
    /**
     * 
     * @type {number}
     * @memberof DisplayReward
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayReward
     */
    pricedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayReward
     */
    disputeId?: string;
    /**
     * 
     * @type {RewardVendor}
     * @memberof DisplayReward
     */
    rewardVendor?: RewardVendor;
}
/**
 * 
 * @export
 * @interface Dispute
 */
export interface Dispute {
    /**
     * 
     * @type {string}
     * @memberof Dispute
     */
    description?: string;
    /**
     * 
     * @type {DisputeReasonEnum}
     * @memberof Dispute
     */
    reason: DisputeReasonEnum;
}
/**
 * 
 * @export
 * @interface DisputeCancel
 */
export interface DisputeCancel {
    /**
     * 
     * @type {string}
     * @memberof DisputeCancel
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeCancel
     */
    reason?: DisputeCancelReasonEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DisputeCancelReasonEnum {
    ValidTransaction = 'VALID_TRANSACTION',
    MerchantResolved = 'MERCHANT_RESOLVED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DisputeReasonEnum {
    DontRecognizeCharge = 'DONT_RECOGNIZE_CHARGE',
    IncorrectRewardAmount = 'INCORRECT_REWARD_AMOUNT',
    IncorrectAmountCharged = 'INCORRECT_AMOUNT_CHARGED',
    RepeatedCharge = 'REPEATED_CHARGE',
    CancelledOrReturnedPurchase = 'CANCELLED_OR_RETURNED_PURCHASE',
    DidntMakePurchase = 'DIDNT_MAKE_PURCHASE',
    DidntReceiveGoodsOrServices = 'DIDNT_RECEIVE_GOODS_OR_SERVICES',
    GoodsNotAsDescribed = 'GOODS_NOT_AS_DESCRIBED',
    PurchaseDefective = 'PURCHASE_DEFECTIVE',
    PurchasedUsingDifferentMethod = 'PURCHASED_USING_DIFFERENT_METHOD',
    Other = 'OTHER'
}

/**
 * 
 * @export
 * @interface DisputeResponse
 */
export interface DisputeResponse {
    /**
     * 
     * @type {string}
     * @memberof DisputeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeResponse
     */
    lastUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeResponse
     */
    status?: DisputeResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DisputeResponseStatusEnum {
    Initiated = 'DISPUTE_INITIATED',
    Approved = 'DISPUTE_APPROVED',
    Declined = 'DISPUTE_DECLINED',
    Cancelled = 'DISPUTE_CANCELLED'
}

/**
 * 
 * @export
 * @interface DoshApplicationInfoResponse
 */
export interface DoshApplicationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof DoshApplicationInfoResponse
     */
    applicationId: string;
}
/**
 * 
 * @export
 * @interface DoshJwtTokenResponse
 */
export interface DoshJwtTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof DoshJwtTokenResponse
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof DoshJwtTokenResponse
     */
    encodedToken?: string;
    /**
     * 
     * @type {number}
     * @memberof DoshJwtTokenResponse
     */
    expiration?: number;
}
/**
 * 
 * @export
 * @interface DoshMarketingErrorResponse
 */
export interface DoshMarketingErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingErrorResponse
     */
    error: string;
}
/**
 * 
 * @export
 * @interface DoshMarketingOfferResponse
 */
export interface DoshMarketingOfferResponse {
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    subtitle: string;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    pill: string;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    image: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoshMarketingOfferResponse
     */
    isInstant: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoshMarketingOfferResponse
     */
    isOnline: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoshMarketingOfferResponse
     */
    isInStore: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    url?: string;
    /**
     * 
     * @type {OfferReward}
     * @memberof DoshMarketingOfferResponse
     */
    reward: OfferReward;
    /**
     * 
     * @type {number}
     * @memberof DoshMarketingOfferResponse
     */
    distanceInMiles?: number;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof DoshMarketingOfferResponse
     */
    offerId: string;
}
/**
 * 
 * @export
 * @interface FindAuthUserInvitationResponse
 */
export interface FindAuthUserInvitationResponse {
    /**
     * 
     * @type {AuthUserInvitationResponse}
     * @memberof FindAuthUserInvitationResponse
     */
    authUserInvitationResponse?: AuthUserInvitationResponse;
}
/**
 * 
 * @export
 * @interface GenericError
 */
export interface GenericError {
    /**
     * 
     * @type {number}
     * @memberof GenericError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof GenericError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface GetRewardStatsResponse
 */
export interface GetRewardStatsResponse {
    /**
     * 
     * @type {Currency}
     * @memberof GetRewardStatsResponse
     */
    fiatCurrency?: Currency;
    /**
     * 
     * @type {ValueDetail}
     * @memberof GetRewardStatsResponse
     */
    grandTotalDisbursedFiat?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof GetRewardStatsResponse
     */
    grandTotalFiatValueOfCrypto?: ValueDetail;
    /**
     * 
     * @type {object}
     * @memberof GetRewardStatsResponse
     */
    totalRewards?: object;
    /**
     * 
     * @type {object}
     * @memberof GetRewardStatsResponse
     */
    last30DayStats?: object;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    transaction_processing_active?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvitationDisplayStatus {
    Pending = 'Pending',
    Active = 'Active',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Failed = 'Failed',
    Rejected = 'Rejected',
    Expired = 'Expired'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvitationStatus {
    Pending = 'PENDING',
    Started = 'STARTED',
    Accepted = 'ACCEPTED',
    EmailVerified = 'EMAIL_VERIFIED',
    PhoneVerifiedManual = 'PHONE_VERIFIED_MANUAL',
    PhoneVerifiedPrefill = 'PHONE_VERIFIED_PREFILL',
    CardDesignPending = 'CARD_DESIGN_PENDING',
    CardIssued = 'CARD_ISSUED',
    Closed = 'CLOSED',
    Rejected = 'REJECTED',
    Cancelled = 'CANCELLED',
    FailedDobConfirm = 'FAILED_DOB_CONFIRM',
    FailedVerification = 'FAILED_VERIFICATION',
    Removed = 'REMOVED',
    Ineligible = 'INELIGIBLE'
}

/**
 * 
 * @export
 * @interface JsonInterestRatesResponse
 */
export interface JsonInterestRatesResponse {
    /**
     * 
     * @type {number}
     * @memberof JsonInterestRatesResponse
     */
    primeRate?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInterestRatesResponse
     */
    minimumApr?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInterestRatesResponse
     */
    maximumApr?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInterestRatesResponse
     */
    maxPenaltyApr?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInterestRatesResponse
     */
    extraPenaltyApr?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInterestRatesResponse
     */
    extraCashAdvanceApr?: number;
}
/**
 * 
 * @export
 * @interface JsonSchumerBoxContentResponse
 */
export interface JsonSchumerBoxContentResponse {
    /**
     * 
     * @type {string}
     * @memberof JsonSchumerBoxContentResponse
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface MarkKycDeniedRequest
 */
export interface MarkKycDeniedRequest {
    /**
     * 
     * @type {string}
     * @memberof MarkKycDeniedRequest
     */
    applicationId: string;
}
/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    merchantId: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    nameAndLocation: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    name?: string;
    /**
     * 
     * @type {MerchantCategory}
     * @memberof Merchant
     */
    category: MerchantCategory;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    logoUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    websiteUrl?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantCategory
 */
export interface MerchantCategory {
    /**
     * 
     * @type {number}
     * @memberof MerchantCategory
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantCategory
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    preferredFirstName?: string;
}
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {NotificationSettingsPurchaseAmount}
     * @memberof NotificationSettings
     */
    purchaseAmount?: NotificationSettingsPurchaseAmount;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    largePurchases?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    rewards?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    payments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    eCommerceTx?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    atmWithdrawals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    internationalTx?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsPurchaseAmount
 */
export interface NotificationSettingsPurchaseAmount {
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingsPurchaseAmount
     */
    value?: number;
    /**
     * 
     * @type {Currency}
     * @memberof NotificationSettingsPurchaseAmount
     */
    currency?: Currency;
}
/**
 * 
 * @export
 * @interface NotificationTypeSetting
 */
export interface NotificationTypeSetting {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationTypeSetting
     */
    isOn: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationTypeSetting
     */
    notificationType: NotificationTypeSettingNotificationTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationTypeSettingNotificationTypeEnum {
    Transactions = 'AUTH_TRANSACTIONS',
    MonthlySpendLimit = 'AUTH_MONTHLY_SPEND_LIMIT',
    AtmWithdrawals = 'AUTH_ATM_WITHDRAWALS',
    InternationalTransactions = 'AUTH_INTERNATIONAL_TRANSACTIONS'
}

/**
 * 
 * @export
 * @interface NotificationsRequest
 */
export interface NotificationsRequest {
    /**
     * 
     * @type {Array<NotificationTypeSetting>}
     * @memberof NotificationsRequest
     */
    notifications: Array<NotificationTypeSetting>;
}
/**
 * 
 * @export
 * @interface OK
 */
export interface OK {
    /**
     * 
     * @type {string}
     * @memberof OK
     */
    success?: string;
}
/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    offerId?: string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    extendedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    apr?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    expiresAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    temporaryCreditLimit?: number;
}
/**
 * 
 * @export
 * @interface OfferReward
 */
export interface OfferReward {
    /**
     * 
     * @type {string}
     * @memberof OfferReward
     */
    amountType: OfferRewardAmountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferReward
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof OfferReward
     */
    formattedAmount: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferRewardAmountTypeEnum {
    Percentage = 'percentage',
    CurrencyValue = 'currencyValue'
}

/**
 * 
 * @export
 * @interface OwedRewardsBalanceChange
 */
export interface OwedRewardsBalanceChange {
    /**
     * 
     * @type {ValueDetail}
     * @memberof OwedRewardsBalanceChange
     */
    amount: ValueDetail;
    /**
     * 
     * @type {BalanceChangeReason}
     * @memberof OwedRewardsBalanceChange
     */
    reason?: BalanceChangeReason;
    /**
     * 
     * @type {string}
     * @memberof OwedRewardsBalanceChange
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface OwedRewardsBalanceResponse
 */
export interface OwedRewardsBalanceResponse {
    /**
     * 
     * @type {ValueDetail}
     * @memberof OwedRewardsBalanceResponse
     */
    owedRewardAmount?: ValueDetail;
}
/**
 * 
 * @export
 * @interface PINRequest
 */
export interface PINRequest {
    /**
     * 
     * @type {string}
     * @memberof PINRequest
     */
    pin: string;
}
/**
 * 
 * @export
 * @interface PaperlessSettingsRequest
 */
export interface PaperlessSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaperlessSettingsRequest
     */
    creditAccountId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaperlessSettingsRequest
     */
    paperless: boolean;
}
/**
 * 
 * @export
 * @interface PaperlessSettingsResponse
 */
export interface PaperlessSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PaperlessSettingsResponse
     */
    paperless?: boolean;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    bankUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    bankDisplayName?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof Payment
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {PaymentType}
     * @memberof Payment
     */
    paymentType?: PaymentType;
    /**
     * 
     * @type {PaymentScheduleType}
     * @memberof Payment
     */
    scheduleType?: PaymentScheduleType;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Payment
     */
    amount?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    dayOfPeriod?: number;
}
/**
 * 
 * @export
 * @interface PaymentCreate
 */
export interface PaymentCreate {
    /**
     * 
     * @type {PaymentScheduleSchema}
     * @memberof PaymentCreate
     */
    schedule: PaymentScheduleSchema;
    /**
     * 
     * @type {PaymentType}
     * @memberof PaymentCreate
     */
    paymentType: PaymentType;
    /**
     * 
     * @type {ValueDetail}
     * @memberof PaymentCreate
     */
    amount?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreate
     */
    cardId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreate
     */
    bankUuid: string;
}
/**
 * 
 * @export
 * @interface PaymentResponse
 */
export interface PaymentResponse {
    /**
     * 
     * @type {PaymentResponsePagination}
     * @memberof PaymentResponse
     */
    pagination?: PaymentResponsePagination;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof PaymentResponse
     */
    payments?: Array<Payment>;
}
/**
 * 
 * @export
 * @interface PaymentResponsePagination
 */
export interface PaymentResponsePagination {
    /**
     * 
     * @type {string}
     * @memberof PaymentResponsePagination
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponsePagination
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponsePagination
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponsePagination
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentResponsePagination
     */
    hasNext?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentScheduleSchema
 */
export interface PaymentScheduleSchema {
    /**
     * 
     * @type {PaymentScheduleType}
     * @memberof PaymentScheduleSchema
     */
    scheduleType: PaymentScheduleType;
    /**
     * 
     * @type {string}
     * @memberof PaymentScheduleSchema
     * @deprecated
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentScheduleSchema
     */
    dayOfPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentScheduleSchema
     */
    dateScheduled?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentScheduleSchema
     */
    clientTimeZone?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentScheduleType {
    OneTime = 'ONE_TIME',
    Monthly = 'MONTHLY',
    Weekly = 'WEEKLY',
    DueDate = 'DUE_DATE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentStatus {
    Processed = 'PROCESSED',
    Cancelled = 'CANCELLED',
    Scheduled = 'SCHEDULED',
    Returned = 'RETURNED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentType {
    Statement = 'STATEMENT',
    Current = 'CURRENT',
    Minimum = 'MINIMUM',
    Fixed = 'FIXED'
}

/**
 * 
 * @export
 * @interface PhoneVerificationResponse
 */
export interface PhoneVerificationResponse {
    /**
     * 
     * @type {string}
     * @memberof PhoneVerificationResponse
     */
    phoneVerificationUrl?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Product {
    CryptobackCredit = 'CRYPTOBACK_CREDIT'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PromoParam {
    StatementSpend = 'statement_spend'
}

/**
 * 
 * @export
 * @interface ProvisionCardForGoogleResponse
 */
export interface ProvisionCardForGoogleResponse {
    /**
     * 
     * @type {string}
     * @memberof ProvisionCardForGoogleResponse
     */
    opc: string;
}
/**
 * 
 * @export
 * @interface ProvisionCardForSamsungResponse
 */
export interface ProvisionCardForSamsungResponse {
    /**
     * 
     * @type {string}
     * @memberof ProvisionCardForSamsungResponse
     */
    cardDetail: string;
}
/**
 * 
 * @export
 * @interface ProvisionSuccessRequest
 */
export interface ProvisionSuccessRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProvisionSuccessRequest
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface ReplaceCardRequest
 */
export interface ReplaceCardRequest {
    /**
     * 
     * @type {string}
     * @memberof ReplaceCardRequest
     */
    cardId: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceCardRequest
     */
    reason: ReplaceCardRequestReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceCardRequest
     */
    acceptCharges?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ReplaceCardRequestReasonEnum {
    Stolen = 'stolen',
    Lost = 'lost',
    Damaged = 'damaged',
    Reissue = 'reissue'
}

/**
 * 
 * @export
 * @interface Reward
 */
export interface Reward {
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    transactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof Reward
     */
    rate?: number;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    transactionAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    adjustmentAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    netFiatAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    rawFiatAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    rawCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    netCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {RewardState}
     * @memberof Reward
     */
    state?: RewardState;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Reward
     */
    spotPrice?: ValueDetail;
    /**
     * 
     * @type {RewardDisputeResponse}
     * @memberof Reward
     */
    dispute?: RewardDisputeResponse;
    /**
     * 
     * @type {string}
     * @memberof Reward
     */
    pricedAt?: string;
    /**
     * 
     * @type {Currency}
     * @memberof Reward
     */
    cryptoCurrency?: Currency;
}
/**
 * 
 * @export
 * @interface RewardCurrency
 */
export interface RewardCurrency {
    /**
     * 
     * @type {string}
     * @memberof RewardCurrency
     */
    currency?: string;
}
/**
 * 
 * @export
 * @interface RewardCurrencySupportedList
 */
export interface RewardCurrencySupportedList {
    /**
     * 
     * @type {Array<Currency>}
     * @memberof RewardCurrencySupportedList
     */
    currencies?: Array<Currency>;
}
/**
 * 
 * @export
 * @interface RewardDetail
 */
export interface RewardDetail {
    /**
     * hashed reward id
     * @type {string}
     * @memberof RewardDetail
     */
    id: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardDetail
     */
    netFiatAmount: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardDetail
     */
    rawFiatAmount: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardDetail
     */
    rawCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardDetail
     */
    netCryptoAmount: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof RewardDetail
     */
    pricedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof RewardDetail
     */
    rate: number;
    /**
     * 
     * @type {RewardDisputeResponse}
     * @memberof RewardDetail
     */
    dispute?: RewardDisputeResponse;
    /**
     * 
     * @type {RewardType}
     * @memberof RewardDetail
     */
    rewardType?: RewardType;
    /**
     * 
     * @type {RewardVendor}
     * @memberof RewardDetail
     */
    rewardVendor?: RewardVendor;
    /**
     * 
     * @type {boolean}
     * @memberof RewardDetail
     */
    instantRewardEligible?: boolean;
}
/**
 * 
 * @export
 * @interface RewardDispute
 */
export interface RewardDispute {
    /**
     * 
     * @type {string}
     * @memberof RewardDispute
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardDispute
     */
    reason: RewardDisputeReasonEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RewardDisputeReasonEnum {
    IncorrectRewardAmount = 'INCORRECT_REWARD_AMOUNT',
    RewardAdjustmentIssue = 'REWARD_ADJUSTMENT_ISSUE',
    MissingRewards = 'MISSING_REWARDS',
    RewardNotReflectedInExchange = 'REWARD_NOT_REFLECTED_IN_EXCHANGE',
    Other = 'OTHER'
}

/**
 * 
 * @export
 * @interface RewardDisputeResponse
 */
export interface RewardDisputeResponse {
    /**
     * 
     * @type {string}
     * @memberof RewardDisputeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardDisputeResponse
     */
    lastUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardDisputeResponse
     */
    status?: RewardDisputeResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RewardDisputeResponseStatusEnum {
    Initiated = 'DISPUTE_INITIATED',
    Approved = 'DISPUTE_APPROVED',
    Declined = 'DISPUTE_DECLINED',
    Cancelled = 'DISPUTE_CANCELLED'
}

/**
 * 
 * @export
 * @interface RewardInfo
 */
export interface RewardInfo {
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    transactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof RewardInfo
     */
    rate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RewardInfo
     */
    transactionAgedOff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    transactionTimestamp?: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    transactionAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    netFiatAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    rawFiatAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    adjustmentAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    rawCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    netCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardInfo
     */
    spotPrice?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    pricedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    merchantNameClean?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    merchantNameRaw?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    merchantCategoryDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardInfo
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface RewardLineItem
 */
export interface RewardLineItem {
    /**
     * 
     * @type {string}
     * @memberof RewardLineItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardLineItem
     */
    creditUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardLineItem
     */
    lifecycleId: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardLineItem
     */
    netFiatAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardLineItem
     */
    rawFiatAmount?: ValueDetail;
    /**
     * aggregate owed rewards balance changes for lifecycle
     * @type {Array<OwedRewardsBalanceChange>}
     * @memberof RewardLineItem
     */
    owedRewardsBalanceChanges: Array<OwedRewardsBalanceChange>;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardLineItem
     */
    rawCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardLineItem
     */
    netCryptoAmount?: ValueDetail;
    /**
     * 
     * @type {ValueDetail}
     * @memberof RewardLineItem
     */
    transactionAmount?: ValueDetail;
    /**
     * reward rate
     * @type {number}
     * @memberof RewardLineItem
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof RewardLineItem
     */
    pricedAt?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof RewardLineItem
     */
    merchant?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof RewardLineItem
     */
    timestamp?: string;
    /**
     * 
     * @type {RewardType}
     * @memberof RewardLineItem
     */
    rewardType?: RewardType;
    /**
     * 
     * @type {RewardVendor}
     * @memberof RewardLineItem
     */
    rewardVendor?: RewardVendor;
    /**
     * 
     * @type {boolean}
     * @memberof RewardLineItem
     */
    instantRewardEligible?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RewardState {
    PendingDisbursal = 'PENDING_DISBURSAL',
    CompletedDisbursal = 'COMPLETED_DISBURSAL'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RewardType {
    Transaction = 'transaction',
    Promotion = 'promotion',
    Remediation = 'remediation',
    DoshOffer = 'dosh_offer'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RewardVendor {
    Gemini = 'gemini',
    Dosh = 'dosh',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface SSN
 */
export interface SSN {
    /**
     * 
     * @type {string}
     * @memberof SSN
     */
    full?: string;
    /**
     * 
     * @type {string}
     * @memberof SSN
     */
    last4?: string;
}
/**
 * 
 * @export
 * @interface ScheduledPayment
 */
export interface ScheduledPayment {
    /**
     * 
     * @type {string}
     * @memberof ScheduledPayment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledPayment
     */
    bankUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledPayment
     */
    bankDisplayName?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof ScheduledPayment
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {PaymentType}
     * @memberof ScheduledPayment
     */
    paymentType?: PaymentType;
    /**
     * 
     * @type {PaymentScheduleType}
     * @memberof ScheduledPayment
     */
    scheduleType?: PaymentScheduleType;
    /**
     * 
     * @type {ValueDetail}
     * @memberof ScheduledPayment
     */
    amount?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof ScheduledPayment
     */
    dueDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledPayment
     */
    dayOfPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledPayment
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface SendPrefillDataResponse
 */
export interface SendPrefillDataResponse {
    /**
     * 
     * @type {string}
     * @memberof SendPrefillDataResponse
     */
    hashedUserId: string;
    /**
     * 
     * @type {AuthUserInviteStatus}
     * @memberof SendPrefillDataResponse
     */
    invitationStatus: AuthUserInviteStatus;
}
/**
 * 
 * @export
 * @interface SetDueDateRequest
 */
export interface SetDueDateRequest {
    /**
     * 
     * @type {string}
     * @memberof SetDueDateRequest
     */
    creditAccountId?: string;
    /**
     * 
     * @type {number}
     * @memberof SetDueDateRequest
     */
    day: SetDueDateRequestDayEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SetDueDateRequestDayEnum {
    DAY_1 = 1,
    DAY_5 = 5,
    DAY_9 = 9,
    DAY_13 = 13,
    DAY_17 = 17,
    DAY_22 = 22,
    DAY_26 = 26
}

/**
 * 
 * @export
 * @interface Statement
 */
export interface Statement {
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    begin?: string;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    end?: string;
    /**
     * 
     * @type {number}
     * @memberof Statement
     */
    transactionCount?: number;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Statement
     */
    amount?: ValueDetail;
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    pdfHref?: string;
}
/**
 * 
 * @export
 * @interface StatementResponse
 */
export interface StatementResponse {
    /**
     * 
     * @type {PaymentResponsePagination}
     * @memberof StatementResponse
     */
    pagination?: PaymentResponsePagination;
    /**
     * 
     * @type {Array<Statement>}
     * @memberof StatementResponse
     */
    statements?: Array<Statement>;
}
/**
 * 
 * @export
 * @interface StatusAlert
 */
export interface StatusAlert {
    /**
     * 
     * @type {string}
     * @memberof StatusAlert
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusAlert
     */
    enumValue: StatusAlertEnumValueEnum;
    /**
     * 
     * @type {string}
     * @memberof StatusAlert
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusAlert
     */
    priority?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StatusAlertEnumValueEnum {
    Delinquent = 'DELINQUENT',
    Suspended = 'SUSPENDED',
    ReturnedPayment = 'RETURNED_PAYMENT',
    ClosedAccount = 'CLOSED_ACCOUNT',
    LockedAccount = 'LOCKED_ACCOUNT',
    Overlimit = 'OVERLIMIT'
}

/**
 * 
 * @export
 * @interface SubmitCreditLineIncreaseRequest
 */
export interface SubmitCreditLineIncreaseRequest {
    /**
     * 
     * @type {ValueDetail}
     * @memberof SubmitCreditLineIncreaseRequest
     */
    increaseAmount?: ValueDetail;
}
/**
 * 
 * @export
 * @interface TAuthyAccountSuspended
 */
export interface TAuthyAccountSuspended {
    /**
     * 
     * @type {number}
     * @memberof TAuthyAccountSuspended
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TAuthyAccountSuspended
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TAuthyDuplicatePhone
 */
export interface TAuthyDuplicatePhone {
    /**
     * 
     * @type {number}
     * @memberof TAuthyDuplicatePhone
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TAuthyDuplicatePhone
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TAuthyInvalidNumber
 */
export interface TAuthyInvalidNumber {
    /**
     * 
     * @type {number}
     * @memberof TAuthyInvalidNumber
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TAuthyInvalidNumber
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TAuthySketchyEmail
 */
export interface TAuthySketchyEmail {
    /**
     * 
     * @type {number}
     * @memberof TAuthySketchyEmail
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TAuthySketchyEmail
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TAuthyTokenFailedToVerify
 */
export interface TAuthyTokenFailedToVerify {
    /**
     * 
     * @type {number}
     * @memberof TAuthyTokenFailedToVerify
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TAuthyTokenFailedToVerify
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TAuthyUserBlocked
 */
export interface TAuthyUserBlocked {
    /**
     * 
     * @type {number}
     * @memberof TAuthyUserBlocked
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TAuthyUserBlocked
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TBankAccountRejected
 */
export interface TBankAccountRejected {
    /**
     * 
     * @type {number}
     * @memberof TBankAccountRejected
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TBankAccountRejected
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TCryptobackError
 */
export interface TCryptobackError {
    /**
     * 
     * @type {number}
     * @memberof TCryptobackError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TCryptobackError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TDuplicatePaymentAttempt
 */
export interface TDuplicatePaymentAttempt {
    /**
     * 
     * @type {number}
     * @memberof TDuplicatePaymentAttempt
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TDuplicatePaymentAttempt
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TEmailTokenFailedToVerify
 */
export interface TEmailTokenFailedToVerify {
    /**
     * 
     * @type {number}
     * @memberof TEmailTokenFailedToVerify
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TEmailTokenFailedToVerify
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TEmailVerificationInternalError
 */
export interface TEmailVerificationInternalError {
    /**
     * 
     * @type {number}
     * @memberof TEmailVerificationInternalError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TEmailVerificationInternalError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TGoogleVoicePhoneNumber
 */
export interface TGoogleVoicePhoneNumber {
    /**
     * 
     * @type {number}
     * @memberof TGoogleVoicePhoneNumber
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TGoogleVoicePhoneNumber
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TInvalidJson
 */
export interface TInvalidJson {
    /**
     * 
     * @type {number}
     * @memberof TInvalidJson
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TInvalidJson
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TMissingData
 */
export interface TMissingData {
    /**
     * 
     * @type {number}
     * @memberof TMissingData
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TMissingData
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TSendEmailVerificationRateLimitExceeded
 */
export interface TSendEmailVerificationRateLimitExceeded {
    /**
     * 
     * @type {number}
     * @memberof TSendEmailVerificationRateLimitExceeded
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TSendEmailVerificationRateLimitExceeded
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TUnknownPhoneNumber
 */
export interface TUnknownPhoneNumber {
    /**
     * 
     * @type {number}
     * @memberof TUnknownPhoneNumber
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TUnknownPhoneNumber
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TUsCustomerWithNonUsPhoneNumber
 */
export interface TUsCustomerWithNonUsPhoneNumber {
    /**
     * 
     * @type {number}
     * @memberof TUsCustomerWithNonUsPhoneNumber
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TUsCustomerWithNonUsPhoneNumber
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TVoipPhoneNumber
 */
export interface TVoipPhoneNumber {
    /**
     * 
     * @type {number}
     * @memberof TVoipPhoneNumber
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TVoipPhoneNumber
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface TermsOfAgreementResponse
 */
export interface TermsOfAgreementResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreementResponse
     */
    accepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementResponse
     */
    lastUpdatedAt?: string;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    creditCardId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    creditUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    creditAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    lastFourDigits?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    lifecycleId?: string;
    /**
     * 
     * @type {DisplayReward}
     * @memberof Transaction
     */
    reward?: DisplayReward;
    /**
     * 
     * @type {Array<DisplayReward>}
     * @memberof Transaction
     */
    rewards?: Array<DisplayReward>;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    rewardAdjusted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    instantRewardEligible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    isMultipart: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Transaction
     */
    amount: ValueDetail;
    /**
     * 
     * @type {Merchant}
     * @memberof Transaction
     */
    merchant?: Merchant;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Transaction
     */
    totalFiatRewardAmount?: ValueDetail | null;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Transaction
     */
    totalCryptoRewardAmount?: ValueDetail | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    totalEffectiveRewardRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    potentialEffectiveRewardRate?: number | null;
    /**
     * 
     * @type {ValueDetail}
     * @memberof Transaction
     */
    owedRewardAmount?: ValueDetail;
    /**
     * 
     * @type {TxTypeEnum}
     * @memberof Transaction
     */
    txType: TxTypeEnum;
    /**
     * 
     * @type {TransactionTypeEnum}
     * @memberof Transaction
     */
    type: TransactionTypeEnum;
    /**
     * 
     * @type {TransactionStatusEnum}
     * @memberof Transaction
     */
    status: TransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    timestamp: string;
    /**
     * 
     * @type {DisputeResponse}
     * @memberof Transaction
     */
    dispute?: DisputeResponse;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    doshTransactionId?: string;
}
/**
 * 
 * @export
 * @interface TransactionDetail
 */
export interface TransactionDetail {
    /**
     * 
     * @type {string}
     * @memberof TransactionDetail
     */
    id: string;
    /**
     * 
     * @type {ValueDetail}
     * @memberof TransactionDetail
     */
    amount: ValueDetail;
    /**
     * Hashed credit card id
     * @type {string}
     * @memberof TransactionDetail
     */
    creditCardId: string;
    /**
     * Hashed credit user id
     * @type {string}
     * @memberof TransactionDetail
     */
    creditUserId?: string;
    /**
     * last 4 digits of credit card
     * @type {string}
     * @memberof TransactionDetail
     */
    lastFourDigits?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetail
     */
    unhashedLifecycleId?: string;
    /**
     * 
     * @type {TxTypeEnum}
     * @memberof TransactionDetail
     */
    txType: TxTypeEnum;
    /**
     * 
     * @type {TransactionStatusEnum}
     * @memberof TransactionDetail
     */
    status: TransactionStatusEnum;
    /**
     * 
     * @type {Merchant}
     * @memberof TransactionDetail
     */
    merchant?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetail
     */
    timestamp: string;
    /**
     * 
     * @type {TransactionTypeEnum}
     * @memberof TransactionDetail
     */
    type: TransactionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDetail
     */
    isMultipart: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDetail
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDetail
     */
    instantRewardEligible: boolean;
    /**
     * 
     * @type {DisputeResponse}
     * @memberof TransactionDetail
     */
    dispute?: DisputeResponse;
}
/**
 * 
 * @export
 * @interface TransactionLimitRequest
 */
export interface TransactionLimitRequest {
    /**
     * The amount of the transaction limit in Dollars
     * @type {number}
     * @memberof TransactionLimitRequest
     */
    amount: number;
    /**
     * Indicates whether the transaction limit should be turned on (isActive = true) or off (isActive = false)
     * @type {boolean}
     * @memberof TransactionLimitRequest
     */
    isActive: boolean;
}
/**
 * 
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
    /**
     * 
     * @type {PaymentResponsePagination}
     * @memberof TransactionResponse
     */
    pagination?: PaymentResponsePagination;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof TransactionResponse
     */
    transactions?: Array<Transaction>;
}
/**
 * used for both transaction details and rewards details endpoints
 * @export
 * @interface TransactionRewardsDetails
 */
export interface TransactionRewardsDetails {
    /**
     * all transactions in lifecycle
     * @type {Array<TransactionDetail>}
     * @memberof TransactionRewardsDetails
     */
    transactions: Array<TransactionDetail>;
    /**
     * aggregate owed rewards balance changes for lifecycle
     * @type {Array<OwedRewardsBalanceChange>}
     * @memberof TransactionRewardsDetails
     */
    owedRewardsBalanceChanges: Array<OwedRewardsBalanceChange>;
    /**
     * all rewards in lifecycle
     * @type {Array<RewardDetail>}
     * @memberof TransactionRewardsDetails
     */
    rewards: Array<RewardDetail>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionStatusEnum {
    Settled = 'SETTLED',
    Declined = 'DECLINED',
    Disputed = 'DISPUTED',
    Pending = 'PENDING'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionTypeEnum {
    Debit = 'DEBIT',
    Credit = 'CREDIT'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TxTypeEnum {
    Authorization = 'authorization',
    AuthReversal = 'auth_reversal',
    Clearing = 'clearing',
    ClearingReversal = 'clearing_reversal',
    PurchaseReversal = 'purchase_reversal',
    PurchaseReturn = 'purchase_return',
    Fee = 'fee',
    FeeReversal = 'fee_reversal',
    Interest = 'interest',
    InterestReversal = 'interest_reversal',
    PaymentTransaction = 'payment_transaction',
    PaymentReversal = 'payment_reversal',
    DisputeDebit = 'dispute_debit',
    DisputeCredit = 'dispute_credit',
    CreditRefund = 'credit_refund',
    CreditRefundReversal = 'credit_refund_reversal',
    CashAdvanceFee = 'cash_advance_fee',
    CashAdvanceFeeReversal = 'cash_advance_fee_reversal'
}

/**
 * 
 * @export
 * @interface UnmaskCardRequest
 */
export interface UnmaskCardRequest {
    /**
     * 
     * @type {string}
     * @memberof UnmaskCardRequest
     */
    pin?: string;
}
/**
 * 
 * @export
 * @interface UnmaskCardResponse
 */
export interface UnmaskCardResponse {
    /**
     * 
     * @type {string}
     * @memberof UnmaskCardResponse
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UnmaskCardResponse
     */
    cardCvc?: string;
    /**
     * 
     * @type {string}
     * @memberof UnmaskCardResponse
     */
    accessToken?: string;
}
/**
 * 
 * @export
 * @interface UpdateAddressRequest
 */
export interface UpdateAddressRequest {
    /**
     * 
     * @type {Address}
     * @memberof UpdateAddressRequest
     */
    address: Address;
    /**
     * Optional hashed credit user id associated with the address
     * @type {string}
     * @memberof UpdateAddressRequest
     */
    creditUserId?: string;
}
/**
 * 
 * @export
 * @interface UserPrefillDataResponse
 */
export interface UserPrefillDataResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPrefillDataResponse
     */
    phone?: string;
    /**
     * 
     * @type {Name}
     * @memberof UserPrefillDataResponse
     */
    name: Name;
    /**
     * 
     * @type {Address}
     * @memberof UserPrefillDataResponse
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof UserPrefillDataResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrefillDataResponse
     */
    ssn?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrefillDataResponse
     */
    dob?: string;
}
/**
 * 
 * @export
 * @interface VGSEnvironment
 */
export interface VGSEnvironment {
    /**
     * 
     * @type {string}
     * @memberof VGSEnvironment
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VGSEnvironment
     */
    vaultId?: string;
}
/**
 * 
 * @export
 * @interface ValidationIds
 */
export interface ValidationIds {
    /**
     * 
     * @type {string}
     * @memberof ValidationIds
     */
    g_recaptcha_response_v2?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationIds
     */
    g_recaptcha_response_v3?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationIds
     */
    android_recaptcha?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationIds
     */
    android_validation?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationIds
     */
    ios_recaptcha?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationIds
     */
    ios_validation?: string;
}
/**
 * 
 * @export
 * @interface ValueDetail
 */
export interface ValueDetail {
    /**
     * 
     * @type {string}
     * @memberof ValueDetail
     */
    value: string;
    /**
     * 
     * @type {Currency}
     * @memberof ValueDetail
     */
    currency: Currency;
}
/**
 * 
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequest
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface VerifyPhoneRequest
 */
export interface VerifyPhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyPhoneRequest
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface WarmWelcomeItem
 */
export interface WarmWelcomeItem {
    /**
     * 
     * @type {WarmWelcomeItemTypeEnum}
     * @memberof WarmWelcomeItem
     */
    itemType: WarmWelcomeItemTypeEnum;
    /**
     * 
     * @type {WarmWelcomeItemStatusType}
     * @memberof WarmWelcomeItem
     */
    status: WarmWelcomeItemStatusType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WarmWelcomeItemStatusType {
    Completed = 'COMPLETED',
    Incomplete = 'INCOMPLETE',
    Removed = 'REMOVED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WarmWelcomeItemTypeEnum {
    TodoListStatus = 'TODO_LIST_STATUS',
    ActivateCard = 'ACTIVATE_CARD',
    VerifyIdentity = 'VERIFY_IDENTITY',
    AccessFullCreditLine = 'ACCESS_FULL_CREDIT_LINE',
    AddApplePay = 'ADD_APPLE_PAY',
    AddGooglePay = 'ADD_GOOGLE_PAY',
    AddSamsungPay = 'ADD_SAMSUNG_PAY',
    ViewRewardCategories = 'VIEW_REWARD_CATEGORIES',
    AddPaymentMethod = 'ADD_PAYMENT_METHOD',
    MakeFirstPurchase = 'MAKE_FIRST_PURCHASE',
    ViewDosh = 'VIEW_DOSH',
    EnableNotifications = 'ENABLE_NOTIFICATIONS'
}

/**
 * 
 * @export
 * @interface WarmWelcomeItemUpdateRequest
 */
export interface WarmWelcomeItemUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WarmWelcomeItemUpdateRequest
     */
    creditUserId: string;
    /**
     * 
     * @type {WarmWelcomeItemTypeEnum}
     * @memberof WarmWelcomeItemUpdateRequest
     */
    welcomeTodoItem: WarmWelcomeItemTypeEnum;
    /**
     * 
     * @type {WarmWelcomeItemStatusType}
     * @memberof WarmWelcomeItemUpdateRequest
     */
    status: WarmWelcomeItemStatusType;
}
/**
 * 
 * @export
 * @interface WarmWelcomeTodoItemsResponse
 */
export interface WarmWelcomeTodoItemsResponse {
    /**
     * 
     * @type {Array<WarmWelcomeItem>}
     * @memberof WarmWelcomeTodoItemsResponse
     */
    items?: Array<WarmWelcomeItem>;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get credit account by id
         * @param {string} creditAccountId Credit account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getAccount.');
            }
            const localVarPath = `/credit-card/account/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the user\'s credit increase eligibility and their offer progress and increase offered if eligibile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditLineIncreaseEligibility: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account/credit-increase/eligibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the html content for the schumer box
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchumerBox: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account/content/schumerBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all credit accounts for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark credit line increase offer seen by user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditLineIncreaseOfferSeen: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account/credit-increase/seen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user account due date day
         * @param {SetDueDateRequest} setDueDateRequest Account due date day of the month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDueDate: async (setDueDateRequest: SetDueDateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setDueDateRequest' is not null or undefined
            if (setDueDateRequest === null || setDueDateRequest === undefined) {
                throw new RequiredError('setDueDateRequest','Required parameter setDueDateRequest was null or undefined when calling setDueDate.');
            }
            const localVarPath = `/credit-card/account/due-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setDueDateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setDueDateRequest !== undefined ? setDueDateRequest : {})
                : (setDueDateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start credit line increase for user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCreditLineIncrease: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account/credit-increase/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submits a user\'s requested credit line increase amount.
         * @param {SubmitCreditLineIncreaseRequest} submitCreditLineIncreaseRequest RequestedCreditLineIncreaseAmount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitCreditLineIncrease: async (submitCreditLineIncreaseRequest: SubmitCreditLineIncreaseRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitCreditLineIncreaseRequest' is not null or undefined
            if (submitCreditLineIncreaseRequest === null || submitCreditLineIncreaseRequest === undefined) {
                throw new RequiredError('submitCreditLineIncreaseRequest','Required parameter submitCreditLineIncreaseRequest was null or undefined when calling submitCreditLineIncrease.');
            }
            const localVarPath = `/credit-card/account/credit-increase/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submitCreditLineIncreaseRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submitCreditLineIncreaseRequest !== undefined ? submitCreditLineIncreaseRequest : {})
                : (submitCreditLineIncreaseRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept terms of of agreement for a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTermsOfAgreement: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account/terms-of-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user account address
         * @param {Address} address Customer address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress: async (address: Address, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling updateAddress.');
            }
            const localVarPath = `/credit-card/account/update-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof address !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(address !== undefined ? address : {})
                : (address || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user account address v2
         * @param {UpdateAddressRequest} updateAddressRequest Customer address and optionally customer\&#39;s hashed credit user id (e.g when a PU wants to update AU\&#39;s mailing address) If nothing is passed, we will use the logged in user\&#39;s exchange user ID to update their address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressV2: async (updateAddressRequest: UpdateAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAddressRequest' is not null or undefined
            if (updateAddressRequest === null || updateAddressRequest === undefined) {
                throw new RequiredError('updateAddressRequest','Required parameter updateAddressRequest was null or undefined when calling updateAddressV2.');
            }
            const localVarPath = `/credit-card/account/update-address/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateAddressRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateAddressRequest !== undefined ? updateAddressRequest : {})
                : (updateAddressRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate user\'s new income for credit line increase.
         * @param {CreditLineIncreaseValidateIncomeRequest} creditLineIncreaseValidateIncomeRequest New income
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreditLineIncrease: async (creditLineIncreaseValidateIncomeRequest: CreditLineIncreaseValidateIncomeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditLineIncreaseValidateIncomeRequest' is not null or undefined
            if (creditLineIncreaseValidateIncomeRequest === null || creditLineIncreaseValidateIncomeRequest === undefined) {
                throw new RequiredError('creditLineIncreaseValidateIncomeRequest','Required parameter creditLineIncreaseValidateIncomeRequest was null or undefined when calling validateCreditLineIncrease.');
            }
            const localVarPath = `/credit-card/account/credit-increase/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof creditLineIncreaseValidateIncomeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(creditLineIncreaseValidateIncomeRequest !== undefined ? creditLineIncreaseValidateIncomeRequest : {})
                : (creditLineIncreaseValidateIncomeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get credit account by id
         * @param {string} creditAccountId Credit account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditAccount>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).getAccount(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary returns the user\'s credit increase eligibility and their offer progress and increase offered if eligibile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditLineIncreaseEligibility(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditIncreaseEligibility>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).getCreditLineIncreaseEligibility(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the html content for the schumer box
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchumerBox(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonSchumerBoxContentResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).getSchumerBox(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all credit accounts for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreditAccount>>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).listAccounts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Mark credit line increase offer seen by user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markCreditLineIncreaseOfferSeen(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OK>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).markCreditLineIncreaseOfferSeen(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user account due date day
         * @param {SetDueDateRequest} setDueDateRequest Account due date day of the month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDueDate(setDueDateRequest: SetDueDateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).setDueDate(setDueDateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Start credit line increase for user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCreditLineIncrease(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OK>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).startCreditLineIncrease(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submits a user\'s requested credit line increase amount.
         * @param {SubmitCreditLineIncreaseRequest} submitCreditLineIncreaseRequest RequestedCreditLineIncreaseAmount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitCreditLineIncrease(submitCreditLineIncreaseRequest: SubmitCreditLineIncreaseRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditLineIncreaseSubmitSuccessResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).submitCreditLineIncrease(submitCreditLineIncreaseRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Accept terms of of agreement for a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTermsOfAgreement(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsOfAgreementResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).submitTermsOfAgreement(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user account address
         * @param {Address} address Customer address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddress(address: Address, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OK>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).updateAddress(address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user account address v2
         * @param {UpdateAddressRequest} updateAddressRequest Customer address and optionally customer\&#39;s hashed credit user id (e.g when a PU wants to update AU\&#39;s mailing address) If nothing is passed, we will use the logged in user\&#39;s exchange user ID to update their address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressV2(updateAddressRequest: UpdateAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OK>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).updateAddressV2(updateAddressRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate user\'s new income for credit line increase.
         * @param {CreditLineIncreaseValidateIncomeRequest} creditLineIncreaseValidateIncomeRequest New income
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateCreditLineIncrease(creditLineIncreaseValidateIncomeRequest: CreditLineIncreaseValidateIncomeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditLineIncreaseValidateSuccessResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).validateCreditLineIncrease(creditLineIncreaseValidateIncomeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get credit account by id
         * @param {string} creditAccountId Credit account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(creditAccountId: string, options?: any): AxiosPromise<CreditAccount> {
            return AccountApiFp(configuration).getAccount(creditAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the user\'s credit increase eligibility and their offer progress and increase offered if eligibile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditLineIncreaseEligibility(options?: any): AxiosPromise<CreditIncreaseEligibility> {
            return AccountApiFp(configuration).getCreditLineIncreaseEligibility(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the html content for the schumer box
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchumerBox(options?: any): AxiosPromise<JsonSchumerBoxContentResponse> {
            return AccountApiFp(configuration).getSchumerBox(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all credit accounts for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(options?: any): AxiosPromise<Array<CreditAccount>> {
            return AccountApiFp(configuration).listAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark credit line increase offer seen by user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markCreditLineIncreaseOfferSeen(options?: any): AxiosPromise<OK> {
            return AccountApiFp(configuration).markCreditLineIncreaseOfferSeen(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user account due date day
         * @param {SetDueDateRequest} setDueDateRequest Account due date day of the month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDueDate(setDueDateRequest: SetDueDateRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).setDueDate(setDueDateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start credit line increase for user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCreditLineIncrease(options?: any): AxiosPromise<OK> {
            return AccountApiFp(configuration).startCreditLineIncrease(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submits a user\'s requested credit line increase amount.
         * @param {SubmitCreditLineIncreaseRequest} submitCreditLineIncreaseRequest RequestedCreditLineIncreaseAmount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitCreditLineIncrease(submitCreditLineIncreaseRequest: SubmitCreditLineIncreaseRequest, options?: any): AxiosPromise<CreditLineIncreaseSubmitSuccessResponse> {
            return AccountApiFp(configuration).submitCreditLineIncrease(submitCreditLineIncreaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept terms of of agreement for a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTermsOfAgreement(options?: any): AxiosPromise<TermsOfAgreementResponse> {
            return AccountApiFp(configuration).submitTermsOfAgreement(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user account address
         * @param {Address} address Customer address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress(address: Address, options?: any): AxiosPromise<OK> {
            return AccountApiFp(configuration).updateAddress(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user account address v2
         * @param {UpdateAddressRequest} updateAddressRequest Customer address and optionally customer\&#39;s hashed credit user id (e.g when a PU wants to update AU\&#39;s mailing address) If nothing is passed, we will use the logged in user\&#39;s exchange user ID to update their address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressV2(updateAddressRequest: UpdateAddressRequest, options?: any): AxiosPromise<OK> {
            return AccountApiFp(configuration).updateAddressV2(updateAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate user\'s new income for credit line increase.
         * @param {CreditLineIncreaseValidateIncomeRequest} creditLineIncreaseValidateIncomeRequest New income
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCreditLineIncrease(creditLineIncreaseValidateIncomeRequest: CreditLineIncreaseValidateIncomeRequest, options?: any): AxiosPromise<CreditLineIncreaseValidateSuccessResponse> {
            return AccountApiFp(configuration).validateCreditLineIncrease(creditLineIncreaseValidateIncomeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get credit account by id
     * @param {string} creditAccountId Credit account id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(creditAccountId: string, options?: any) {
        return AccountApiFp(this.configuration).getAccount(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the user\'s credit increase eligibility and their offer progress and increase offered if eligibile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getCreditLineIncreaseEligibility(options?: any) {
        return AccountApiFp(this.configuration).getCreditLineIncreaseEligibility(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the html content for the schumer box
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSchumerBox(options?: any) {
        return AccountApiFp(this.configuration).getSchumerBox(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all credit accounts for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listAccounts(options?: any) {
        return AccountApiFp(this.configuration).listAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark credit line increase offer seen by user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public markCreditLineIncreaseOfferSeen(options?: any) {
        return AccountApiFp(this.configuration).markCreditLineIncreaseOfferSeen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user account due date day
     * @param {SetDueDateRequest} setDueDateRequest Account due date day of the month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public setDueDate(setDueDateRequest: SetDueDateRequest, options?: any) {
        return AccountApiFp(this.configuration).setDueDate(setDueDateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start credit line increase for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public startCreditLineIncrease(options?: any) {
        return AccountApiFp(this.configuration).startCreditLineIncrease(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submits a user\'s requested credit line increase amount.
     * @param {SubmitCreditLineIncreaseRequest} submitCreditLineIncreaseRequest RequestedCreditLineIncreaseAmount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public submitCreditLineIncrease(submitCreditLineIncreaseRequest: SubmitCreditLineIncreaseRequest, options?: any) {
        return AccountApiFp(this.configuration).submitCreditLineIncrease(submitCreditLineIncreaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept terms of of agreement for a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public submitTermsOfAgreement(options?: any) {
        return AccountApiFp(this.configuration).submitTermsOfAgreement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user account address
     * @param {Address} address Customer address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAddress(address: Address, options?: any) {
        return AccountApiFp(this.configuration).updateAddress(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user account address v2
     * @param {UpdateAddressRequest} updateAddressRequest Customer address and optionally customer\&#39;s hashed credit user id (e.g when a PU wants to update AU\&#39;s mailing address) If nothing is passed, we will use the logged in user\&#39;s exchange user ID to update their address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAddressV2(updateAddressRequest: UpdateAddressRequest, options?: any) {
        return AccountApiFp(this.configuration).updateAddressV2(updateAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate user\'s new income for credit line increase.
     * @param {CreditLineIncreaseValidateIncomeRequest} creditLineIncreaseValidateIncomeRequest New income
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public validateCreditLineIncrease(creditLineIncreaseValidateIncomeRequest: CreditLineIncreaseValidateIncomeRequest, options?: any) {
        return AccountApiFp(this.configuration).validateCreditLineIncrease(creditLineIncreaseValidateIncomeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all credit card activities (transactions, payments, fees, interest).
         * @summary List all credit card transaction and payment activities by the requesting user\'s creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
         * @param {string} [creditUserId] Credit user id
         * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1. On page 1, pending activity(authorizations and scheduled payments) is returned as a separate array in pendingActivity field; on subsequent pages pendingActivity will be empty array.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivities: async (creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling listActivities.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling listActivities.');
            }
            const localVarPath = `/credit-card/activity/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (creditUserId !== undefined) {
                localVarQueryParameter['creditUserId'] = creditUserId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * List all credit card activities (transactions, payments, fees, interest).
         * @summary List all credit card transaction and payment activities by the requesting user\'s creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
         * @param {string} [creditUserId] Credit user id
         * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1. On page 1, pending activity(authorizations and scheduled payments) is returned as a separate array in pendingActivity field; on subsequent pages pendingActivity will be empty array.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivities(creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityResponse>> {
            const localVarAxiosArgs = await ActivityApiAxiosParamCreator(configuration).listActivities(creditAccountId, pageSize, creditUserId, pageNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * List all credit card activities (transactions, payments, fees, interest).
         * @summary List all credit card transaction and payment activities by the requesting user\'s creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
         * @param {string} [creditUserId] Credit user id
         * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1. On page 1, pending activity(authorizations and scheduled payments) is returned as a separate array in pendingActivity field; on subsequent pages pendingActivity will be empty array.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivities(creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options?: any): AxiosPromise<ActivityResponse> {
            return ActivityApiFp(configuration).listActivities(creditAccountId, pageSize, creditUserId, pageNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * List all credit card activities (transactions, payments, fees, interest).
     * @summary List all credit card transaction and payment activities by the requesting user\'s creditAccountId
     * @param {string} creditAccountId Credit account id
     * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
     * @param {string} [creditUserId] Credit user id
     * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1. On page 1, pending activity(authorizations and scheduled payments) is returned as a separate array in pendingActivity field; on subsequent pages pendingActivity will be empty array.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public listActivities(creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options?: any) {
        return ActivityApiFp(this.configuration).listActivities(creditAccountId, pageSize, creditUserId, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check the authy token after phone verification
         * @param {string} applicationId id of the application
         * @param {VerifyPhoneRequest} [verifyPhoneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthyToken: async (applicationId: string, verifyPhoneRequest?: VerifyPhoneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling checkAuthyToken.');
            }
            const localVarPath = `/credit-card/application/verify-phone/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyPhoneRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyPhoneRequest !== undefined ? verifyPhoneRequest : {})
                : (verifyPhoneRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check the token after sending email verification
         * @param {string} applicationId id of the application
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailVerificationToken: async (applicationId: string, verifyEmailRequest?: VerifyEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling checkEmailVerificationToken.');
            }
            const localVarPath = `/credit-card/application/verify-email/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyEmailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyEmailRequest !== undefined ? verifyEmailRequest : {})
                : (verifyEmailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check the token after sending email verification for the currently logged in user
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailVerificationTokenPrefill: async (verifyEmailRequest?: VerifyEmailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/application/prefill/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyEmailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyEmailRequest !== undefined ? verifyEmailRequest : {})
                : (verifyEmailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new credit aplication
         * @summary Create a new credit aplication
         * @param {Application} application Create a new application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (application: Application, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'application' is not null or undefined
            if (application === null || application === undefined) {
                throw new RequiredError('application','Required parameter application was null or undefined when calling createApplication.');
            }
            const localVarPath = `/credit-card/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof application !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(application !== undefined ? application : {})
                : (application || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new credit application from prefill
         * @summary Create a new credit application from the given prefill
         * @param {ApplicationFromPrefill} applicationFromPrefill Create a new application from the given prefill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationFromPrefill: async (applicationFromPrefill: ApplicationFromPrefill, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationFromPrefill' is not null or undefined
            if (applicationFromPrefill === null || applicationFromPrefill === undefined) {
                throw new RequiredError('applicationFromPrefill','Required parameter applicationFromPrefill was null or undefined when calling createApplicationFromPrefill.');
            }
            const localVarPath = `/credit-card/application/prefill/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof applicationFromPrefill !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(applicationFromPrefill !== undefined ? applicationFromPrefill : {})
                : (applicationFromPrefill || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new credit application prefill
         * @summary Create a new credit application prefill
         * @param {ApplicationPrefill} applicationPrefill Create a new application prefill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationPrefill: async (applicationPrefill: ApplicationPrefill, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationPrefill' is not null or undefined
            if (applicationPrefill === null || applicationPrefill === undefined) {
                throw new RequiredError('applicationPrefill','Required parameter applicationPrefill was null or undefined when calling createApplicationPrefill.');
            }
            const localVarPath = `/credit-card/application/prefill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof applicationPrefill !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(applicationPrefill !== undefined ? applicationPrefill : {})
                : (applicationPrefill || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing application
         * @summary Get an existing application
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (applicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling getApplication.');
            }
            const localVarPath = `/credit-card/application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all relevant interest rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/application/interestRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the customer information of the logged in user from Prove
         * @summary Get the customer information of the logged in user from Prove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefillCustomerInformation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/application/prefill/customer-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing application
         * @summary Get an existing application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApplications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Application State to Kyc Denied and notify user
         * @param {MarkKycDeniedRequest} markKycDeniedRequest Mark Kyc Denied Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markKycDenied: async (markKycDeniedRequest: MarkKycDeniedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'markKycDeniedRequest' is not null or undefined
            if (markKycDeniedRequest === null || markKycDeniedRequest === undefined) {
                throw new RequiredError('markKycDeniedRequest','Required parameter markKycDeniedRequest was null or undefined when calling markKycDenied.');
            }
            const localVarPath = `/credit-card/application/markKycDenied`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof markKycDeniedRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(markKycDeniedRequest !== undefined ? markKycDeniedRequest : {})
                : (markKycDeniedRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend phone verification pin
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthyToken: async (applicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling sendAuthyToken.');
            }
            const localVarPath = `/credit-card/application/verify-phone/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email verification token
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerificationToken: async (applicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling sendEmailVerificationToken.');
            }
            const localVarPath = `/credit-card/application/verify-email/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email verification token for the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerificationTokenPrefill: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/application/prefill/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a phone ownership verification SMS via Prove
         * @summary Send a phone ownership verification SMS via Prove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPhoneVerification: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/application/prefill/verify-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing application by Id
         * @summary Update an existing application
         * @param {string} applicationId id of the application
         * @param {Application} application Update an existing application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (applicationId: string, application: Application, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling updateApplication.');
            }
            // verify required parameter 'application' is not null or undefined
            if (application === null || application === undefined) {
                throw new RequiredError('application','Required parameter application was null or undefined when calling updateApplication.');
            }
            const localVarPath = `/credit-card/application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof application !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(application !== undefined ? application : {})
                : (application || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check the authy token after phone verification
         * @param {string} applicationId id of the application
         * @param {VerifyPhoneRequest} [verifyPhoneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAuthyToken(applicationId: string, verifyPhoneRequest?: VerifyPhoneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).checkAuthyToken(applicationId, verifyPhoneRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check the token after sending email verification
         * @param {string} applicationId id of the application
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailVerificationToken(applicationId: string, verifyEmailRequest?: VerifyEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).checkEmailVerificationToken(applicationId, verifyEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check the token after sending email verification for the currently logged in user
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailVerificationTokenPrefill(verifyEmailRequest?: VerifyEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).checkEmailVerificationTokenPrefill(verifyEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new credit aplication
         * @summary Create a new credit aplication
         * @param {Application} application Create a new application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(application: Application, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).createApplication(application, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new credit application from prefill
         * @summary Create a new credit application from the given prefill
         * @param {ApplicationFromPrefill} applicationFromPrefill Create a new application from the given prefill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationFromPrefill(applicationFromPrefill: ApplicationFromPrefill, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).createApplicationFromPrefill(applicationFromPrefill, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new credit application prefill
         * @summary Create a new credit application prefill
         * @param {ApplicationPrefill} applicationPrefill Create a new application prefill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationPrefill(applicationPrefill: ApplicationPrefill, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).createApplicationPrefill(applicationPrefill, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get an existing application
         * @summary Get an existing application
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(applicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getApplication(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all relevant interest rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestRates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonInterestRatesResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getInterestRates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the customer information of the logged in user from Prove
         * @summary Get the customer information of the logged in user from Prove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrefillCustomerInformation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrefillDataResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getPrefillCustomerInformation(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get an existing application
         * @summary Get an existing application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApplications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).listApplications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Application State to Kyc Denied and notify user
         * @param {MarkKycDeniedRequest} markKycDeniedRequest Mark Kyc Denied Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markKycDenied(markKycDeniedRequest: MarkKycDeniedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).markKycDenied(markKycDeniedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Resend phone verification pin
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAuthyToken(applicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).sendAuthyToken(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send email verification token
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailVerificationToken(applicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).sendEmailVerificationToken(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send email verification token for the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailVerificationTokenPrefill(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).sendEmailVerificationTokenPrefill(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send a phone ownership verification SMS via Prove
         * @summary Send a phone ownership verification SMS via Prove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPhoneVerification(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneVerificationResponse>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).sendPhoneVerification(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update an existing application by Id
         * @summary Update an existing application
         * @param {string} applicationId id of the application
         * @param {Application} application Update an existing application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(applicationId: string, application: Application, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStatus>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).updateApplication(applicationId, application, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Check the authy token after phone verification
         * @param {string} applicationId id of the application
         * @param {VerifyPhoneRequest} [verifyPhoneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthyToken(applicationId: string, verifyPhoneRequest?: VerifyPhoneRequest, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).checkAuthyToken(applicationId, verifyPhoneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check the token after sending email verification
         * @param {string} applicationId id of the application
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailVerificationToken(applicationId: string, verifyEmailRequest?: VerifyEmailRequest, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).checkEmailVerificationToken(applicationId, verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check the token after sending email verification for the currently logged in user
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailVerificationTokenPrefill(verifyEmailRequest?: VerifyEmailRequest, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).checkEmailVerificationTokenPrefill(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new credit aplication
         * @summary Create a new credit aplication
         * @param {Application} application Create a new application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(application: Application, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).createApplication(application, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new credit application from prefill
         * @summary Create a new credit application from the given prefill
         * @param {ApplicationFromPrefill} applicationFromPrefill Create a new application from the given prefill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationFromPrefill(applicationFromPrefill: ApplicationFromPrefill, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).createApplicationFromPrefill(applicationFromPrefill, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new credit application prefill
         * @summary Create a new credit application prefill
         * @param {ApplicationPrefill} applicationPrefill Create a new application prefill
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationPrefill(applicationPrefill: ApplicationPrefill, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).createApplicationPrefill(applicationPrefill, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing application
         * @summary Get an existing application
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(applicationId: string, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).getApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all relevant interest rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRates(options?: any): AxiosPromise<JsonInterestRatesResponse> {
            return ApplicationApiFp(configuration).getInterestRates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the customer information of the logged in user from Prove
         * @summary Get the customer information of the logged in user from Prove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefillCustomerInformation(options?: any): AxiosPromise<UserPrefillDataResponse> {
            return ApplicationApiFp(configuration).getPrefillCustomerInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing application
         * @summary Get an existing application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApplications(options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).listApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Application State to Kyc Denied and notify user
         * @param {MarkKycDeniedRequest} markKycDeniedRequest Mark Kyc Denied Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markKycDenied(markKycDeniedRequest: MarkKycDeniedRequest, options?: any): AxiosPromise<void> {
            return ApplicationApiFp(configuration).markKycDenied(markKycDeniedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend phone verification pin
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthyToken(applicationId: string, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).sendAuthyToken(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email verification token
         * @param {string} applicationId id of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerificationToken(applicationId: string, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).sendEmailVerificationToken(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email verification token for the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerificationTokenPrefill(options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).sendEmailVerificationTokenPrefill(options).then((request) => request(axios, basePath));
        },
        /**
         * Send a phone ownership verification SMS via Prove
         * @summary Send a phone ownership verification SMS via Prove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPhoneVerification(options?: any): AxiosPromise<PhoneVerificationResponse> {
            return ApplicationApiFp(configuration).sendPhoneVerification(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing application by Id
         * @summary Update an existing application
         * @param {string} applicationId id of the application
         * @param {Application} application Update an existing application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(applicationId: string, application: Application, options?: any): AxiosPromise<ApplicationStatus> {
            return ApplicationApiFp(configuration).updateApplication(applicationId, application, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Check the authy token after phone verification
     * @param {string} applicationId id of the application
     * @param {VerifyPhoneRequest} [verifyPhoneRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public checkAuthyToken(applicationId: string, verifyPhoneRequest?: VerifyPhoneRequest, options?: any) {
        return ApplicationApiFp(this.configuration).checkAuthyToken(applicationId, verifyPhoneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check the token after sending email verification
     * @param {string} applicationId id of the application
     * @param {VerifyEmailRequest} [verifyEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public checkEmailVerificationToken(applicationId: string, verifyEmailRequest?: VerifyEmailRequest, options?: any) {
        return ApplicationApiFp(this.configuration).checkEmailVerificationToken(applicationId, verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check the token after sending email verification for the currently logged in user
     * @param {VerifyEmailRequest} [verifyEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public checkEmailVerificationTokenPrefill(verifyEmailRequest?: VerifyEmailRequest, options?: any) {
        return ApplicationApiFp(this.configuration).checkEmailVerificationTokenPrefill(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new credit aplication
     * @summary Create a new credit aplication
     * @param {Application} application Create a new application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public createApplication(application: Application, options?: any) {
        return ApplicationApiFp(this.configuration).createApplication(application, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new credit application from prefill
     * @summary Create a new credit application from the given prefill
     * @param {ApplicationFromPrefill} applicationFromPrefill Create a new application from the given prefill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public createApplicationFromPrefill(applicationFromPrefill: ApplicationFromPrefill, options?: any) {
        return ApplicationApiFp(this.configuration).createApplicationFromPrefill(applicationFromPrefill, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new credit application prefill
     * @summary Create a new credit application prefill
     * @param {ApplicationPrefill} applicationPrefill Create a new application prefill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public createApplicationPrefill(applicationPrefill: ApplicationPrefill, options?: any) {
        return ApplicationApiFp(this.configuration).createApplicationPrefill(applicationPrefill, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing application
     * @summary Get an existing application
     * @param {string} applicationId id of the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getApplication(applicationId: string, options?: any) {
        return ApplicationApiFp(this.configuration).getApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all relevant interest rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getInterestRates(options?: any) {
        return ApplicationApiFp(this.configuration).getInterestRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the customer information of the logged in user from Prove
     * @summary Get the customer information of the logged in user from Prove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getPrefillCustomerInformation(options?: any) {
        return ApplicationApiFp(this.configuration).getPrefillCustomerInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing application
     * @summary Get an existing application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public listApplications(options?: any) {
        return ApplicationApiFp(this.configuration).listApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Application State to Kyc Denied and notify user
     * @param {MarkKycDeniedRequest} markKycDeniedRequest Mark Kyc Denied Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public markKycDenied(markKycDeniedRequest: MarkKycDeniedRequest, options?: any) {
        return ApplicationApiFp(this.configuration).markKycDenied(markKycDeniedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend phone verification pin
     * @param {string} applicationId id of the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public sendAuthyToken(applicationId: string, options?: any) {
        return ApplicationApiFp(this.configuration).sendAuthyToken(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email verification token
     * @param {string} applicationId id of the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public sendEmailVerificationToken(applicationId: string, options?: any) {
        return ApplicationApiFp(this.configuration).sendEmailVerificationToken(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email verification token for the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public sendEmailVerificationTokenPrefill(options?: any) {
        return ApplicationApiFp(this.configuration).sendEmailVerificationTokenPrefill(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a phone ownership verification SMS via Prove
     * @summary Send a phone ownership verification SMS via Prove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public sendPhoneVerification(options?: any) {
        return ApplicationApiFp(this.configuration).sendPhoneVerification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing application by Id
     * @summary Update an existing application
     * @param {string} applicationId id of the application
     * @param {Application} application Update an existing application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public updateApplication(applicationId: string, application: Application, options?: any) {
        return ApplicationApiFp(this.configuration).updateApplication(applicationId, application, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to verify email confirmation code
         * @summary Used to verify email confirmation code
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAuthUserCodeForEmail: async (verifyEmailRequest: VerifyEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailRequest' is not null or undefined
            if (verifyEmailRequest === null || verifyEmailRequest === undefined) {
                throw new RequiredError('verifyEmailRequest','Required parameter verifyEmailRequest was null or undefined when calling confirmAuthUserCodeForEmail.');
            }
            const localVarPath = `/credit-card/auth-users/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyEmailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyEmailRequest !== undefined ? verifyEmailRequest : {})
                : (verifyEmailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Auth user accepts or declines the invite, updating its status
         * @summary Auth user accepts or declines the invite
         * @param {AuthUserInviteConfirmation} authUserInviteConfirmation New authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAuthUserInvitation: async (authUserInviteConfirmation: AuthUserInviteConfirmation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authUserInviteConfirmation' is not null or undefined
            if (authUserInviteConfirmation === null || authUserInviteConfirmation === undefined) {
                throw new RequiredError('authUserInviteConfirmation','Required parameter authUserInviteConfirmation was null or undefined when calling confirmAuthUserInvitation.');
            }
            const localVarPath = `/credit-card/auth-users/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authUserInviteConfirmation !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authUserInviteConfirmation !== undefined ? authUserInviteConfirmation : {})
                : (authUserInviteConfirmation || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to create a card for an authorized user
         * @summary Used to create a card for an authorized user
         * @param {CreateAuthUserCardRequest} createAuthUserCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthUserCard: async (createAuthUserCardRequest: CreateAuthUserCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthUserCardRequest' is not null or undefined
            if (createAuthUserCardRequest === null || createAuthUserCardRequest === undefined) {
                throw new RequiredError('createAuthUserCardRequest','Required parameter createAuthUserCardRequest was null or undefined when calling createAuthUserCard.');
            }
            const localVarPath = `/credit-card/auth-users/create-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAuthUserCardRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAuthUserCardRequest !== undefined ? createAuthUserCardRequest : {})
                : (createAuthUserCardRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine if current user has a valid auth user invite
         * @summary Determine if current user has an auth user invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthUserInvitation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/account/auth-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all current (pending and active) auth user invitations in primary experience
         * @summary Fetch all current (pending and active) auth user invitations in primary experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthUserInvitations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/auth-users/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all active auth users in primary experience
         * @summary Fetch all active auth users in primary experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/auth-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get authorized user by id
         * @summary Get authorized user by id
         * @param {string} hashedAuthUserId hashed credit user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUser: async (hashedAuthUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashedAuthUserId' is not null or undefined
            if (hashedAuthUserId === null || hashedAuthUserId === undefined) {
                throw new RequiredError('hashedAuthUserId','Required parameter hashedAuthUserId was null or undefined when calling getAuthUser.');
            }
            const localVarPath = `/credit-card/auth-users/{hashedAuthUserId}`
                .replace(`{${"hashedAuthUserId"}}`, encodeURIComponent(String(hashedAuthUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an authorized user invitation by id on behalf of the primary user if it exists
         * @summary Retrieve an authorized user invitation by id on behalf of the primary user if it exists
         * @param {string} uuid invitation uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserInvitation: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getAuthUserInvitation.');
            }
            const localVarPath = `/credit-card/auth-users/invite/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to fetch prefill data for new users
         * @summary Used to fetch prefill data for new users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserPrefillData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/auth-users/prefill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Primary user invites new potential authorized user
         * @summary Primary user invites new potential authorized user
         * @param {AuthUserInvite} authUserInvite New authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteAuthUsers: async (authUserInvite: AuthUserInvite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authUserInvite' is not null or undefined
            if (authUserInvite === null || authUserInvite === undefined) {
                throw new RequiredError('authUserInvite','Required parameter authUserInvite was null or undefined when calling inviteAuthUsers.');
            }
            const localVarPath = `/credit-card/auth-users/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authUserInvite !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authUserInvite !== undefined ? authUserInvite : {})
                : (authUserInvite || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove authorized user
         * @summary Remove authorized user
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuthUser: async (hashedAuthUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashedAuthUserId' is not null or undefined
            if (hashedAuthUserId === null || hashedAuthUserId === undefined) {
                throw new RequiredError('hashedAuthUserId','Required parameter hashedAuthUserId was null or undefined when calling removeAuthUser.');
            }
            const localVarPath = `/credit-card/auth-users/{hashedAuthUserId}`
                .replace(`{${"hashedAuthUserId"}}`, encodeURIComponent(String(hashedAuthUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to send confirmation code for email verification
         * @summary Used to send confirmation code for email verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthUserCodeForEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/auth-users/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to send SMS for phone verification
         * @summary Used to send SMS for phone verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthUserCodeForPhone: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/auth-users/verify-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to send prefill data for new users
         * @summary Used to send prefill data for new users
         * @param {AuthUserApplicationPrefill} authUserApplicationPrefill 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthUserPrefillData: async (authUserApplicationPrefill: AuthUserApplicationPrefill, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authUserApplicationPrefill' is not null or undefined
            if (authUserApplicationPrefill === null || authUserApplicationPrefill === undefined) {
                throw new RequiredError('authUserApplicationPrefill','Required parameter authUserApplicationPrefill was null or undefined when calling sendAuthUserPrefillData.');
            }
            const localVarPath = `/credit-card/auth-users/prefill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authUserApplicationPrefill !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authUserApplicationPrefill !== undefined ? authUserApplicationPrefill : {})
                : (authUserApplicationPrefill || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to update (or confirm) prefill data
         * @summary Used to update (or confirm) prefill data
         * @param {ApplicationFromPrefill} applicationFromPrefill 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAuthUserPrefillData: async (applicationFromPrefill: ApplicationFromPrefill, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationFromPrefill' is not null or undefined
            if (applicationFromPrefill === null || applicationFromPrefill === undefined) {
                throw new RequiredError('applicationFromPrefill','Required parameter applicationFromPrefill was null or undefined when calling submitAuthUserPrefillData.');
            }
            const localVarPath = `/credit-card/auth-users/prefill/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof applicationFromPrefill !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(applicationFromPrefill !== undefined ? applicationFromPrefill : {})
                : (applicationFromPrefill || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used only when (1) AU rejects the invite, or (2) PU deletes a pending invite
         * @summary update the status of a pending invitation
         * @param {string} uuid invitation uuid
         * @param {AuthUserInviteStatus} authUserInviteStatus Invitation status value to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitationStatus: async (uuid: string, authUserInviteStatus: AuthUserInviteStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateInvitationStatus.');
            }
            // verify required parameter 'authUserInviteStatus' is not null or undefined
            if (authUserInviteStatus === null || authUserInviteStatus === undefined) {
                throw new RequiredError('authUserInviteStatus','Required parameter authUserInviteStatus was null or undefined when calling updateInvitationStatus.');
            }
            const localVarPath = `/credit-card/auth-users/invite/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authUserInviteStatus !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authUserInviteStatus !== undefined ? authUserInviteStatus : {})
                : (authUserInviteStatus || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Primary User Settings
         * @summary Update Primary User Settings
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {NotificationsRequest | CashAdvanceRequest} notificationsRequestCashAdvanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (hashedAuthUserId: string, notificationsRequestCashAdvanceRequest: NotificationsRequest | CashAdvanceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashedAuthUserId' is not null or undefined
            if (hashedAuthUserId === null || hashedAuthUserId === undefined) {
                throw new RequiredError('hashedAuthUserId','Required parameter hashedAuthUserId was null or undefined when calling updateSettings.');
            }
            // verify required parameter 'notificationsRequestCashAdvanceRequest' is not null or undefined
            if (notificationsRequestCashAdvanceRequest === null || notificationsRequestCashAdvanceRequest === undefined) {
                throw new RequiredError('notificationsRequestCashAdvanceRequest','Required parameter notificationsRequestCashAdvanceRequest was null or undefined when calling updateSettings.');
            }
            const localVarPath = `/credit-card/auth-users/{hashedAuthUserId}`
                .replace(`{${"hashedAuthUserId"}}`, encodeURIComponent(String(hashedAuthUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationsRequestCashAdvanceRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationsRequestCashAdvanceRequest !== undefined ? notificationsRequestCashAdvanceRequest : {})
                : (notificationsRequestCashAdvanceRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update transaction limit for authorized user
         * @summary Update transaction limit for authorized user
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {TransactionLimitRequest} transactionLimitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionLimit: async (hashedAuthUserId: string, transactionLimitRequest: TransactionLimitRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashedAuthUserId' is not null or undefined
            if (hashedAuthUserId === null || hashedAuthUserId === undefined) {
                throw new RequiredError('hashedAuthUserId','Required parameter hashedAuthUserId was null or undefined when calling updateTransactionLimit.');
            }
            // verify required parameter 'transactionLimitRequest' is not null or undefined
            if (transactionLimitRequest === null || transactionLimitRequest === undefined) {
                throw new RequiredError('transactionLimitRequest','Required parameter transactionLimitRequest was null or undefined when calling updateTransactionLimit.');
            }
            const localVarPath = `/credit-card/auth-users/{hashedAuthUserId}/transaction-limit`
                .replace(`{${"hashedAuthUserId"}}`, encodeURIComponent(String(hashedAuthUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transactionLimitRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transactionLimitRequest !== undefined ? transactionLimitRequest : {})
                : (transactionLimitRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Used to verify email confirmation code
         * @summary Used to verify email confirmation code
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmAuthUserCodeForEmail(verifyEmailRequest: VerifyEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserInviteStatus>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).confirmAuthUserCodeForEmail(verifyEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Auth user accepts or declines the invite, updating its status
         * @summary Auth user accepts or declines the invite
         * @param {AuthUserInviteConfirmation} authUserInviteConfirmation New authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmAuthUserInvitation(authUserInviteConfirmation: AuthUserInviteConfirmation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserInviteStatus>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).confirmAuthUserInvitation(authUserInviteConfirmation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to create a card for an authorized user
         * @summary Used to create a card for an authorized user
         * @param {CreateAuthUserCardRequest} createAuthUserCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthUserCard(createAuthUserCardRequest: CreateAuthUserCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).createAuthUserCard(createAuthUserCardRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Determine if current user has a valid auth user invite
         * @summary Determine if current user has an auth user invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuthUserInvitation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAuthUserInvitationResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).findAuthUserInvitation(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Fetch all current (pending and active) auth user invitations in primary experience
         * @summary Fetch all current (pending and active) auth user invitations in primary experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuthUserInvitations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthUserInvitationResponse>>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).findAuthUserInvitations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Fetch all active auth users in primary experience
         * @summary Fetch all active auth users in primary experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuthUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthUserResponse>>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).findAuthUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get authorized user by id
         * @summary Get authorized user by id
         * @param {string} hashedAuthUserId hashed credit user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUser(hashedAuthUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getAuthUser(hashedAuthUserId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve an authorized user invitation by id on behalf of the primary user if it exists
         * @summary Retrieve an authorized user invitation by id on behalf of the primary user if it exists
         * @param {string} uuid invitation uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUserInvitation(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserInvitationResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getAuthUserInvitation(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to fetch prefill data for new users
         * @summary Used to fetch prefill data for new users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUserPrefillData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrefillDataResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getAuthUserPrefillData(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Primary user invites new potential authorized user
         * @summary Primary user invites new potential authorized user
         * @param {AuthUserInvite} authUserInvite New authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteAuthUsers(authUserInvite: AuthUserInvite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).inviteAuthUsers(authUserInvite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove authorized user
         * @summary Remove authorized user
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAuthUser(hashedAuthUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).removeAuthUser(hashedAuthUserId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to send confirmation code for email verification
         * @summary Used to send confirmation code for email verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAuthUserCodeForEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserInviteStatus>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).sendAuthUserCodeForEmail(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to send SMS for phone verification
         * @summary Used to send SMS for phone verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAuthUserCodeForPhone(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserInviteStatus>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).sendAuthUserCodeForPhone(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to send prefill data for new users
         * @summary Used to send prefill data for new users
         * @param {AuthUserApplicationPrefill} authUserApplicationPrefill 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAuthUserPrefillData(authUserApplicationPrefill: AuthUserApplicationPrefill, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendPrefillDataResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).sendAuthUserPrefillData(authUserApplicationPrefill, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to update (or confirm) prefill data
         * @summary Used to update (or confirm) prefill data
         * @param {ApplicationFromPrefill} applicationFromPrefill 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAuthUserPrefillData(applicationFromPrefill: ApplicationFromPrefill, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserInviteStatus>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).submitAuthUserPrefillData(applicationFromPrefill, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This API is used only when (1) AU rejects the invite, or (2) PU deletes a pending invite
         * @summary update the status of a pending invitation
         * @param {string} uuid invitation uuid
         * @param {AuthUserInviteStatus} authUserInviteStatus Invitation status value to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvitationStatus(uuid: string, authUserInviteStatus: AuthUserInviteStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).updateInvitationStatus(uuid, authUserInviteStatus, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update Primary User Settings
         * @summary Update Primary User Settings
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {NotificationsRequest | CashAdvanceRequest} notificationsRequestCashAdvanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(hashedAuthUserId: string, notificationsRequestCashAdvanceRequest: NotificationsRequest | CashAdvanceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).updateSettings(hashedAuthUserId, notificationsRequestCashAdvanceRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update transaction limit for authorized user
         * @summary Update transaction limit for authorized user
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {TransactionLimitRequest} transactionLimitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionLimit(hashedAuthUserId: string, transactionLimitRequest: TransactionLimitRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).updateTransactionLimit(hashedAuthUserId, transactionLimitRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Used to verify email confirmation code
         * @summary Used to verify email confirmation code
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAuthUserCodeForEmail(verifyEmailRequest: VerifyEmailRequest, options?: any): AxiosPromise<AuthUserInviteStatus> {
            return AuthApiFp(configuration).confirmAuthUserCodeForEmail(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Auth user accepts or declines the invite, updating its status
         * @summary Auth user accepts or declines the invite
         * @param {AuthUserInviteConfirmation} authUserInviteConfirmation New authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAuthUserInvitation(authUserInviteConfirmation: AuthUserInviteConfirmation, options?: any): AxiosPromise<AuthUserInviteStatus> {
            return AuthApiFp(configuration).confirmAuthUserInvitation(authUserInviteConfirmation, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to create a card for an authorized user
         * @summary Used to create a card for an authorized user
         * @param {CreateAuthUserCardRequest} createAuthUserCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthUserCard(createAuthUserCardRequest: CreateAuthUserCardRequest, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).createAuthUserCard(createAuthUserCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine if current user has a valid auth user invite
         * @summary Determine if current user has an auth user invite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthUserInvitation(options?: any): AxiosPromise<FindAuthUserInvitationResponse> {
            return AuthApiFp(configuration).findAuthUserInvitation(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all current (pending and active) auth user invitations in primary experience
         * @summary Fetch all current (pending and active) auth user invitations in primary experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthUserInvitations(options?: any): AxiosPromise<Array<AuthUserInvitationResponse>> {
            return AuthApiFp(configuration).findAuthUserInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all active auth users in primary experience
         * @summary Fetch all active auth users in primary experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthUsers(options?: any): AxiosPromise<Array<AuthUserResponse>> {
            return AuthApiFp(configuration).findAuthUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Get authorized user by id
         * @summary Get authorized user by id
         * @param {string} hashedAuthUserId hashed credit user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUser(hashedAuthUserId: string, options?: any): AxiosPromise<AuthUserResponse> {
            return AuthApiFp(configuration).getAuthUser(hashedAuthUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an authorized user invitation by id on behalf of the primary user if it exists
         * @summary Retrieve an authorized user invitation by id on behalf of the primary user if it exists
         * @param {string} uuid invitation uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserInvitation(uuid: string, options?: any): AxiosPromise<AuthUserInvitationResponse> {
            return AuthApiFp(configuration).getAuthUserInvitation(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to fetch prefill data for new users
         * @summary Used to fetch prefill data for new users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUserPrefillData(options?: any): AxiosPromise<UserPrefillDataResponse> {
            return AuthApiFp(configuration).getAuthUserPrefillData(options).then((request) => request(axios, basePath));
        },
        /**
         * Primary user invites new potential authorized user
         * @summary Primary user invites new potential authorized user
         * @param {AuthUserInvite} authUserInvite New authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteAuthUsers(authUserInvite: AuthUserInvite, options?: any): AxiosPromise<string> {
            return AuthApiFp(configuration).inviteAuthUsers(authUserInvite, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove authorized user
         * @summary Remove authorized user
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuthUser(hashedAuthUserId: string, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).removeAuthUser(hashedAuthUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to send confirmation code for email verification
         * @summary Used to send confirmation code for email verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthUserCodeForEmail(options?: any): AxiosPromise<AuthUserInviteStatus> {
            return AuthApiFp(configuration).sendAuthUserCodeForEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * Used to send SMS for phone verification
         * @summary Used to send SMS for phone verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthUserCodeForPhone(options?: any): AxiosPromise<AuthUserInviteStatus> {
            return AuthApiFp(configuration).sendAuthUserCodeForPhone(options).then((request) => request(axios, basePath));
        },
        /**
         * Used to send prefill data for new users
         * @summary Used to send prefill data for new users
         * @param {AuthUserApplicationPrefill} authUserApplicationPrefill 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAuthUserPrefillData(authUserApplicationPrefill: AuthUserApplicationPrefill, options?: any): AxiosPromise<SendPrefillDataResponse> {
            return AuthApiFp(configuration).sendAuthUserPrefillData(authUserApplicationPrefill, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to update (or confirm) prefill data
         * @summary Used to update (or confirm) prefill data
         * @param {ApplicationFromPrefill} applicationFromPrefill 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAuthUserPrefillData(applicationFromPrefill: ApplicationFromPrefill, options?: any): AxiosPromise<AuthUserInviteStatus> {
            return AuthApiFp(configuration).submitAuthUserPrefillData(applicationFromPrefill, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used only when (1) AU rejects the invite, or (2) PU deletes a pending invite
         * @summary update the status of a pending invitation
         * @param {string} uuid invitation uuid
         * @param {AuthUserInviteStatus} authUserInviteStatus Invitation status value to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitationStatus(uuid: string, authUserInviteStatus: AuthUserInviteStatus, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).updateInvitationStatus(uuid, authUserInviteStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Primary User Settings
         * @summary Update Primary User Settings
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {NotificationsRequest | CashAdvanceRequest} notificationsRequestCashAdvanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(hashedAuthUserId: string, notificationsRequestCashAdvanceRequest: NotificationsRequest | CashAdvanceRequest, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).updateSettings(hashedAuthUserId, notificationsRequestCashAdvanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update transaction limit for authorized user
         * @summary Update transaction limit for authorized user
         * @param {string} hashedAuthUserId hashed auth user id
         * @param {TransactionLimitRequest} transactionLimitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionLimit(hashedAuthUserId: string, transactionLimitRequest: TransactionLimitRequest, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).updateTransactionLimit(hashedAuthUserId, transactionLimitRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Used to verify email confirmation code
     * @summary Used to verify email confirmation code
     * @param {VerifyEmailRequest} verifyEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmAuthUserCodeForEmail(verifyEmailRequest: VerifyEmailRequest, options?: any) {
        return AuthApiFp(this.configuration).confirmAuthUserCodeForEmail(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Auth user accepts or declines the invite, updating its status
     * @summary Auth user accepts or declines the invite
     * @param {AuthUserInviteConfirmation} authUserInviteConfirmation New authorized user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmAuthUserInvitation(authUserInviteConfirmation: AuthUserInviteConfirmation, options?: any) {
        return AuthApiFp(this.configuration).confirmAuthUserInvitation(authUserInviteConfirmation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to create a card for an authorized user
     * @summary Used to create a card for an authorized user
     * @param {CreateAuthUserCardRequest} createAuthUserCardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthUserCard(createAuthUserCardRequest: CreateAuthUserCardRequest, options?: any) {
        return AuthApiFp(this.configuration).createAuthUserCard(createAuthUserCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine if current user has a valid auth user invite
     * @summary Determine if current user has an auth user invite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public findAuthUserInvitation(options?: any) {
        return AuthApiFp(this.configuration).findAuthUserInvitation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all current (pending and active) auth user invitations in primary experience
     * @summary Fetch all current (pending and active) auth user invitations in primary experience
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public findAuthUserInvitations(options?: any) {
        return AuthApiFp(this.configuration).findAuthUserInvitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all active auth users in primary experience
     * @summary Fetch all active auth users in primary experience
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public findAuthUsers(options?: any) {
        return AuthApiFp(this.configuration).findAuthUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get authorized user by id
     * @summary Get authorized user by id
     * @param {string} hashedAuthUserId hashed credit user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUser(hashedAuthUserId: string, options?: any) {
        return AuthApiFp(this.configuration).getAuthUser(hashedAuthUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an authorized user invitation by id on behalf of the primary user if it exists
     * @summary Retrieve an authorized user invitation by id on behalf of the primary user if it exists
     * @param {string} uuid invitation uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUserInvitation(uuid: string, options?: any) {
        return AuthApiFp(this.configuration).getAuthUserInvitation(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to fetch prefill data for new users
     * @summary Used to fetch prefill data for new users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthUserPrefillData(options?: any) {
        return AuthApiFp(this.configuration).getAuthUserPrefillData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Primary user invites new potential authorized user
     * @summary Primary user invites new potential authorized user
     * @param {AuthUserInvite} authUserInvite New authorized user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public inviteAuthUsers(authUserInvite: AuthUserInvite, options?: any) {
        return AuthApiFp(this.configuration).inviteAuthUsers(authUserInvite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove authorized user
     * @summary Remove authorized user
     * @param {string} hashedAuthUserId hashed auth user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public removeAuthUser(hashedAuthUserId: string, options?: any) {
        return AuthApiFp(this.configuration).removeAuthUser(hashedAuthUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to send confirmation code for email verification
     * @summary Used to send confirmation code for email verification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public sendAuthUserCodeForEmail(options?: any) {
        return AuthApiFp(this.configuration).sendAuthUserCodeForEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to send SMS for phone verification
     * @summary Used to send SMS for phone verification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public sendAuthUserCodeForPhone(options?: any) {
        return AuthApiFp(this.configuration).sendAuthUserCodeForPhone(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to send prefill data for new users
     * @summary Used to send prefill data for new users
     * @param {AuthUserApplicationPrefill} authUserApplicationPrefill 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public sendAuthUserPrefillData(authUserApplicationPrefill: AuthUserApplicationPrefill, options?: any) {
        return AuthApiFp(this.configuration).sendAuthUserPrefillData(authUserApplicationPrefill, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to update (or confirm) prefill data
     * @summary Used to update (or confirm) prefill data
     * @param {ApplicationFromPrefill} applicationFromPrefill 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public submitAuthUserPrefillData(applicationFromPrefill: ApplicationFromPrefill, options?: any) {
        return AuthApiFp(this.configuration).submitAuthUserPrefillData(applicationFromPrefill, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used only when (1) AU rejects the invite, or (2) PU deletes a pending invite
     * @summary update the status of a pending invitation
     * @param {string} uuid invitation uuid
     * @param {AuthUserInviteStatus} authUserInviteStatus Invitation status value to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateInvitationStatus(uuid: string, authUserInviteStatus: AuthUserInviteStatus, options?: any) {
        return AuthApiFp(this.configuration).updateInvitationStatus(uuid, authUserInviteStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Primary User Settings
     * @summary Update Primary User Settings
     * @param {string} hashedAuthUserId hashed auth user id
     * @param {NotificationsRequest | CashAdvanceRequest} notificationsRequestCashAdvanceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateSettings(hashedAuthUserId: string, notificationsRequestCashAdvanceRequest: NotificationsRequest | CashAdvanceRequest, options?: any) {
        return AuthApiFp(this.configuration).updateSettings(hashedAuthUserId, notificationsRequestCashAdvanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update transaction limit for authorized user
     * @summary Update transaction limit for authorized user
     * @param {string} hashedAuthUserId hashed auth user id
     * @param {TransactionLimitRequest} transactionLimitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateTransactionLimit(hashedAuthUserId: string, transactionLimitRequest: TransactionLimitRequest, options?: any) {
        return AuthApiFp(this.configuration).updateTransactionLimit(hashedAuthUserId, transactionLimitRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvailableYearlyStatementsApi - axios parameter creator
 * @export
 */
export const AvailableYearlyStatementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List the available years for which the yearly statements can be generated
         * @summary List the available years a user has been a credit card customer.
         * @param {string} creditAccountId Credit account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listYearsAsCreditCardHolder: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling listYearsAsCreditCardHolder.');
            }
            const localVarPath = `/credit-card/available-yearly-statements/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvailableYearlyStatementsApi - functional programming interface
 * @export
 */
export const AvailableYearlyStatementsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * List the available years for which the yearly statements can be generated
         * @summary List the available years a user has been a credit card customer.
         * @param {string} creditAccountId Credit account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listYearsAsCreditCardHolder(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await AvailableYearlyStatementsApiAxiosParamCreator(configuration).listYearsAsCreditCardHolder(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AvailableYearlyStatementsApi - factory interface
 * @export
 */
export const AvailableYearlyStatementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * List the available years for which the yearly statements can be generated
         * @summary List the available years a user has been a credit card customer.
         * @param {string} creditAccountId Credit account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listYearsAsCreditCardHolder(creditAccountId: string, options?: any): AxiosPromise<Array<number>> {
            return AvailableYearlyStatementsApiFp(configuration).listYearsAsCreditCardHolder(creditAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvailableYearlyStatementsApi - object-oriented interface
 * @export
 * @class AvailableYearlyStatementsApi
 * @extends {BaseAPI}
 */
export class AvailableYearlyStatementsApi extends BaseAPI {
    /**
     * List the available years for which the yearly statements can be generated
     * @summary List the available years a user has been a credit card customer.
     * @param {string} creditAccountId Credit account id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailableYearlyStatementsApi
     */
    public listYearsAsCreditCardHolder(creditAccountId: string, options?: any) {
        return AvailableYearlyStatementsApiFp(this.configuration).listYearsAsCreditCardHolder(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get balance by creditAccountId
         * @param {CreditAccountId} creditAccountId Create a new payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance: async (creditAccountId: CreditAccountId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getBalance.');
            }
            const localVarPath = `/credit-card/application/markKycDenied`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof creditAccountId !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(creditAccountId !== undefined ? creditAccountId : {})
                : (creditAccountId || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get balance by creditAccountId
         * @param {CreditAccountId} creditAccountId Create a new payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalance(creditAccountId: CreditAccountId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance>> {
            const localVarAxiosArgs = await BalanceApiAxiosParamCreator(configuration).getBalance(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get balance by creditAccountId
         * @param {CreditAccountId} creditAccountId Create a new payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(creditAccountId: CreditAccountId, options?: any): AxiosPromise<Balance> {
            return BalanceApiFp(configuration).getBalance(creditAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * 
     * @summary Get balance by creditAccountId
     * @param {CreditAccountId} creditAccountId Create a new payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public getBalance(creditAccountId: CreditAccountId, options?: any) {
        return BalanceApiFp(this.configuration).getBalance(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CardsApi - axios parameter creator
 * @export
 */
export const CardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activates a card
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCard: async (cardId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling activateCard.');
            }
            const localVarPath = `/credit-card/card/{cardId}/activate`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Block a card
         * @param {BlockCardRequest} blockCardRequest Block a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCard: async (blockCardRequest: BlockCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockCardRequest' is not null or undefined
            if (blockCardRequest === null || blockCardRequest === undefined) {
                throw new RequiredError('blockCardRequest','Required parameter blockCardRequest was null or undefined when calling blockCard.');
            }
            const localVarPath = `/credit-card/card/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof blockCardRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(blockCardRequest !== undefined ? blockCardRequest : {})
                : (blockCardRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List current card details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/card/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Card details by card cardId
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard: async (cardId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling getCard.');
            }
            const localVarPath = `/credit-card/card/{cardId}`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all current and past card details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provision a card for Google Pay
         * @param {string} cardId Hashed card ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provisionCardForGoogle: async (cardId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling provisionCardForGoogle.');
            }
            const localVarPath = `/credit-card/card/{cardId}/provision/google`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Records a successful push provisioning
         * @param {string} cardId Hashed card ID which maps to specific PAN
         * @param {'apple' | 'google' | 'samsung'} wallet Name of the wallet
         * @param {ProvisionSuccessRequest} [provisionSuccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provisionSuccess: async (cardId: string, wallet: 'apple' | 'google' | 'samsung', provisionSuccessRequest?: ProvisionSuccessRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling provisionSuccess.');
            }
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling provisionSuccess.');
            }
            const localVarPath = `/credit-card/card/{cardId}/provision/success/{wallet}`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof provisionSuccessRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(provisionSuccessRequest !== undefined ? provisionSuccessRequest : {})
                : (provisionSuccessRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a card
         * @param {ReplaceCardRequest} replaceCardRequest Replace a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceCard: async (replaceCardRequest: ReplaceCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'replaceCardRequest' is not null or undefined
            if (replaceCardRequest === null || replaceCardRequest === undefined) {
                throw new RequiredError('replaceCardRequest','Required parameter replaceCardRequest was null or undefined when calling replaceCard.');
            }
            const localVarPath = `/credit-card/card/replace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof replaceCardRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(replaceCardRequest !== undefined ? replaceCardRequest : {})
                : (replaceCardRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Samsung Pay card provisioning data
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        samsungPayPushProvisioningData: async (cardId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling samsungPayPushProvisioningData.');
            }
            const localVarPath = `/credit-card/card/{cardId}/provision/samsung`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the Cash Advance PIN
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {PINRequest} pINRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPIN: async (cardId: string, pINRequest: PINRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling setPIN.');
            }
            // verify required parameter 'pINRequest' is not null or undefined
            if (pINRequest === null || pINRequest === undefined) {
                throw new RequiredError('pINRequest','Required parameter pINRequest was null or undefined when calling setPIN.');
            }
            const localVarPath = `/credit-card/card/{cardId}/pin`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof pINRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(pINRequest !== undefined ? pINRequest : {})
                : (pINRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unmask credit card details
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {UnmaskCardRequest} unmaskCardRequest Replace a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskCard: async (cardId: string, unmaskCardRequest: UnmaskCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling unmaskCard.');
            }
            // verify required parameter 'unmaskCardRequest' is not null or undefined
            if (unmaskCardRequest === null || unmaskCardRequest === undefined) {
                throw new RequiredError('unmaskCardRequest','Required parameter unmaskCardRequest was null or undefined when calling unmaskCard.');
            }
            const localVarPath = `/credit-card/card/{cardId}/unmask`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof unmaskCardRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(unmaskCardRequest !== undefined ? unmaskCardRequest : {})
                : (unmaskCardRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardsApi - functional programming interface
 * @export
 */
export const CardsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activates a card
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCard(cardId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).activateCard(cardId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Block a card
         * @param {BlockCardRequest} blockCardRequest Block a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockCard(blockCardRequest: BlockCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).blockCard(blockCardRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List current card details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveCard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).getActiveCard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Card details by card cardId
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCard(cardId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).getCard(cardId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all current and past card details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCards(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Card>>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).getCards(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provision a card for Google Pay
         * @param {string} cardId Hashed card ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async provisionCardForGoogle(cardId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionCardForGoogleResponse>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).provisionCardForGoogle(cardId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Records a successful push provisioning
         * @param {string} cardId Hashed card ID which maps to specific PAN
         * @param {'apple' | 'google' | 'samsung'} wallet Name of the wallet
         * @param {ProvisionSuccessRequest} [provisionSuccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async provisionSuccess(cardId: string, wallet: 'apple' | 'google' | 'samsung', provisionSuccessRequest?: ProvisionSuccessRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).provisionSuccess(cardId, wallet, provisionSuccessRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a card
         * @param {ReplaceCardRequest} replaceCardRequest Replace a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceCard(replaceCardRequest: ReplaceCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OK>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).replaceCard(replaceCardRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get Samsung Pay card provisioning data
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async samsungPayPushProvisioningData(cardId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionCardForSamsungResponse>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).samsungPayPushProvisioningData(cardId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set the Cash Advance PIN
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {PINRequest} pINRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPIN(cardId: string, pINRequest: PINRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OK>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).setPIN(cardId, pINRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unmask credit card details
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {UnmaskCardRequest} unmaskCardRequest Replace a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmaskCard(cardId: string, unmaskCardRequest: UnmaskCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnmaskCardResponse>> {
            const localVarAxiosArgs = await CardsApiAxiosParamCreator(configuration).unmaskCard(cardId, unmaskCardRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CardsApi - factory interface
 * @export
 */
export const CardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Activates a card
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCard(cardId: string, options?: any): AxiosPromise<Card> {
            return CardsApiFp(configuration).activateCard(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Block a card
         * @param {BlockCardRequest} blockCardRequest Block a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCard(blockCardRequest: BlockCardRequest, options?: any): AxiosPromise<Card> {
            return CardsApiFp(configuration).blockCard(blockCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List current card details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCard(options?: any): AxiosPromise<Card> {
            return CardsApiFp(configuration).getActiveCard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Card details by card cardId
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard(cardId: string, options?: any): AxiosPromise<Card> {
            return CardsApiFp(configuration).getCard(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all current and past card details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(options?: any): AxiosPromise<Array<Card>> {
            return CardsApiFp(configuration).getCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provision a card for Google Pay
         * @param {string} cardId Hashed card ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provisionCardForGoogle(cardId: string, options?: any): AxiosPromise<ProvisionCardForGoogleResponse> {
            return CardsApiFp(configuration).provisionCardForGoogle(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Records a successful push provisioning
         * @param {string} cardId Hashed card ID which maps to specific PAN
         * @param {'apple' | 'google' | 'samsung'} wallet Name of the wallet
         * @param {ProvisionSuccessRequest} [provisionSuccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provisionSuccess(cardId: string, wallet: 'apple' | 'google' | 'samsung', provisionSuccessRequest?: ProvisionSuccessRequest, options?: any): AxiosPromise<void> {
            return CardsApiFp(configuration).provisionSuccess(cardId, wallet, provisionSuccessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a card
         * @param {ReplaceCardRequest} replaceCardRequest Replace a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceCard(replaceCardRequest: ReplaceCardRequest, options?: any): AxiosPromise<OK> {
            return CardsApiFp(configuration).replaceCard(replaceCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Samsung Pay card provisioning data
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        samsungPayPushProvisioningData(cardId: string, options?: any): AxiosPromise<ProvisionCardForSamsungResponse> {
            return CardsApiFp(configuration).samsungPayPushProvisioningData(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the Cash Advance PIN
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {PINRequest} pINRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPIN(cardId: string, pINRequest: PINRequest, options?: any): AxiosPromise<OK> {
            return CardsApiFp(configuration).setPIN(cardId, pINRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unmask credit card details
         * @param {string} cardId Alias ID which maps to specific PAN
         * @param {UnmaskCardRequest} unmaskCardRequest Replace a card
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskCard(cardId: string, unmaskCardRequest: UnmaskCardRequest, options?: any): AxiosPromise<UnmaskCardResponse> {
            return CardsApiFp(configuration).unmaskCard(cardId, unmaskCardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardsApi - object-oriented interface
 * @export
 * @class CardsApi
 * @extends {BaseAPI}
 */
export class CardsApi extends BaseAPI {
    /**
     * 
     * @summary Activates a card
     * @param {string} cardId Alias ID which maps to specific PAN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public activateCard(cardId: string, options?: any) {
        return CardsApiFp(this.configuration).activateCard(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Block a card
     * @param {BlockCardRequest} blockCardRequest Block a card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public blockCard(blockCardRequest: BlockCardRequest, options?: any) {
        return CardsApiFp(this.configuration).blockCard(blockCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List current card details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getActiveCard(options?: any) {
        return CardsApiFp(this.configuration).getActiveCard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Card details by card cardId
     * @param {string} cardId Alias ID which maps to specific PAN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getCard(cardId: string, options?: any) {
        return CardsApiFp(this.configuration).getCard(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all current and past card details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public getCards(options?: any) {
        return CardsApiFp(this.configuration).getCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provision a card for Google Pay
     * @param {string} cardId Hashed card ID which maps to specific PAN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public provisionCardForGoogle(cardId: string, options?: any) {
        return CardsApiFp(this.configuration).provisionCardForGoogle(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Records a successful push provisioning
     * @param {string} cardId Hashed card ID which maps to specific PAN
     * @param {'apple' | 'google' | 'samsung'} wallet Name of the wallet
     * @param {ProvisionSuccessRequest} [provisionSuccessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public provisionSuccess(cardId: string, wallet: 'apple' | 'google' | 'samsung', provisionSuccessRequest?: ProvisionSuccessRequest, options?: any) {
        return CardsApiFp(this.configuration).provisionSuccess(cardId, wallet, provisionSuccessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a card
     * @param {ReplaceCardRequest} replaceCardRequest Replace a card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public replaceCard(replaceCardRequest: ReplaceCardRequest, options?: any) {
        return CardsApiFp(this.configuration).replaceCard(replaceCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Samsung Pay card provisioning data
     * @param {string} cardId Alias ID which maps to specific PAN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public samsungPayPushProvisioningData(cardId: string, options?: any) {
        return CardsApiFp(this.configuration).samsungPayPushProvisioningData(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the Cash Advance PIN
     * @param {string} cardId Alias ID which maps to specific PAN
     * @param {PINRequest} pINRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public setPIN(cardId: string, pINRequest: PINRequest, options?: any) {
        return CardsApiFp(this.configuration).setPIN(cardId, pINRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unmask credit card details
     * @param {string} cardId Alias ID which maps to specific PAN
     * @param {UnmaskCardRequest} unmaskCardRequest Replace a card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public unmaskCard(cardId: string, unmaskCardRequest: UnmaskCardRequest, options?: any) {
        return CardsApiFp(this.configuration).unmaskCard(cardId, unmaskCardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CreditCardSystemStatusApi - axios parameter creator
 * @export
 */
export const CreditCardSystemStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets a response that describes credit card system status such as transaction processing is active
         * @summary Gets a response indicating credit card system status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardSystemStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/system-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditCardSystemStatusApi - functional programming interface
 * @export
 */
export const CreditCardSystemStatusApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Gets a response that describes credit card system status such as transaction processing is active
         * @summary Gets a response indicating credit card system status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditCardSystemStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await CreditCardSystemStatusApiAxiosParamCreator(configuration).getCreditCardSystemStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CreditCardSystemStatusApi - factory interface
 * @export
 */
export const CreditCardSystemStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Gets a response that describes credit card system status such as transaction processing is active
         * @summary Gets a response indicating credit card system status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardSystemStatus(options?: any): AxiosPromise<InlineResponse200> {
            return CreditCardSystemStatusApiFp(configuration).getCreditCardSystemStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreditCardSystemStatusApi - object-oriented interface
 * @export
 * @class CreditCardSystemStatusApi
 * @extends {BaseAPI}
 */
export class CreditCardSystemStatusApi extends BaseAPI {
    /**
     * Gets a response that describes credit card system status such as transaction processing is active
     * @summary Gets a response indicating credit card system status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardSystemStatusApi
     */
    public getCreditCardSystemStatus(options?: any) {
        return CreditCardSystemStatusApiFp(this.configuration).getCreditCardSystemStatus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoshApi - axios parameter creator
 * @export
 */
export const DoshApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generates a new Dosh JWT Token for the currently logged in user for use when calling the Dosh API
         * @summary Generates a new Dosh JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDoshJwtToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/dosh/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * For an authenticated request, it will return the applicationId for the Dosh/Gemini Integration
         * @summary Retrieve the Dosh ApplicationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoshApplicationInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/dosh/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Given a user request, it will generate a token and fetch offers for a user
         * @summary Retrieves Dosh offers for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoshOffers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/dosh/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoshApi - functional programming interface
 * @export
 */
export const DoshApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Generates a new Dosh JWT Token for the currently logged in user for use when calling the Dosh API
         * @summary Generates a new Dosh JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDoshJwtToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoshJwtTokenResponse>> {
            const localVarAxiosArgs = await DoshApiAxiosParamCreator(configuration).createDoshJwtToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * For an authenticated request, it will return the applicationId for the Dosh/Gemini Integration
         * @summary Retrieve the Dosh ApplicationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDoshApplicationInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoshApplicationInfoResponse>> {
            const localVarAxiosArgs = await DoshApiAxiosParamCreator(configuration).fetchDoshApplicationInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Given a user request, it will generate a token and fetch offers for a user
         * @summary Retrieves Dosh offers for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDoshOffers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DoshMarketingOfferResponse>>> {
            const localVarAxiosArgs = await DoshApiAxiosParamCreator(configuration).fetchDoshOffers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DoshApi - factory interface
 * @export
 */
export const DoshApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Generates a new Dosh JWT Token for the currently logged in user for use when calling the Dosh API
         * @summary Generates a new Dosh JWT Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDoshJwtToken(options?: any): AxiosPromise<DoshJwtTokenResponse> {
            return DoshApiFp(configuration).createDoshJwtToken(options).then((request) => request(axios, basePath));
        },
        /**
         * For an authenticated request, it will return the applicationId for the Dosh/Gemini Integration
         * @summary Retrieve the Dosh ApplicationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoshApplicationInfo(options?: any): AxiosPromise<DoshApplicationInfoResponse> {
            return DoshApiFp(configuration).fetchDoshApplicationInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Given a user request, it will generate a token and fetch offers for a user
         * @summary Retrieves Dosh offers for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoshOffers(options?: any): AxiosPromise<Array<DoshMarketingOfferResponse>> {
            return DoshApiFp(configuration).fetchDoshOffers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoshApi - object-oriented interface
 * @export
 * @class DoshApi
 * @extends {BaseAPI}
 */
export class DoshApi extends BaseAPI {
    /**
     * Generates a new Dosh JWT Token for the currently logged in user for use when calling the Dosh API
     * @summary Generates a new Dosh JWT Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoshApi
     */
    public createDoshJwtToken(options?: any) {
        return DoshApiFp(this.configuration).createDoshJwtToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For an authenticated request, it will return the applicationId for the Dosh/Gemini Integration
     * @summary Retrieve the Dosh ApplicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoshApi
     */
    public fetchDoshApplicationInfo(options?: any) {
        return DoshApiFp(this.configuration).fetchDoshApplicationInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given a user request, it will generate a token and fetch offers for a user
     * @summary Retrieves Dosh offers for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoshApi
     */
    public fetchDoshOffers(options?: any) {
        return DoshApiFp(this.configuration).fetchDoshOffers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets all notification settings for user
         * @summary Gets all notification settings for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets paperless setting for user
         * @summary Gets paperless setting for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaperless: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/notifications/paperless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification settings
         * @param {NotificationSettings} notificationSettings Update notification settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications: async (notificationSettings: NotificationSettings, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSettings' is not null or undefined
            if (notificationSettings === null || notificationSettings === undefined) {
                throw new RequiredError('notificationSettings','Required parameter notificationSettings was null or undefined when calling updateNotifications.');
            }
            const localVarPath = `/credit-card/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationSettings !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationSettings !== undefined ? notificationSettings : {})
                : (notificationSettings || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify paperless settings
         * @param {PaperlessSettingsRequest} paperlessSettingsRequest Paperless settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaperless: async (paperlessSettingsRequest: PaperlessSettingsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paperlessSettingsRequest' is not null or undefined
            if (paperlessSettingsRequest === null || paperlessSettingsRequest === undefined) {
                throw new RequiredError('paperlessSettingsRequest','Required parameter paperlessSettingsRequest was null or undefined when calling updatePaperless.');
            }
            const localVarPath = `/credit-card/notifications/paperless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof paperlessSettingsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(paperlessSettingsRequest !== undefined ? paperlessSettingsRequest : {})
                : (paperlessSettingsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Gets all notification settings for user
         * @summary Gets all notification settings for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettings>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).getNotifications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets paperless setting for user
         * @summary Gets paperless setting for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaperless(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaperlessSettingsResponse>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).getPaperless(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update notification settings
         * @param {NotificationSettings} notificationSettings Update notification settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(notificationSettings: NotificationSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettings>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).updateNotifications(notificationSettings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modify paperless settings
         * @param {PaperlessSettingsRequest} paperlessSettingsRequest Paperless settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaperless(paperlessSettingsRequest: PaperlessSettingsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).updatePaperless(paperlessSettingsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Gets all notification settings for user
         * @summary Gets all notification settings for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(options?: any): AxiosPromise<NotificationSettings> {
            return NotificationsApiFp(configuration).getNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets paperless setting for user
         * @summary Gets paperless setting for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaperless(options?: any): AxiosPromise<PaperlessSettingsResponse> {
            return NotificationsApiFp(configuration).getPaperless(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification settings
         * @param {NotificationSettings} notificationSettings Update notification settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(notificationSettings: NotificationSettings, options?: any): AxiosPromise<NotificationSettings> {
            return NotificationsApiFp(configuration).updateNotifications(notificationSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify paperless settings
         * @param {PaperlessSettingsRequest} paperlessSettingsRequest Paperless settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaperless(paperlessSettingsRequest: PaperlessSettingsRequest, options?: any): AxiosPromise<void> {
            return NotificationsApiFp(configuration).updatePaperless(paperlessSettingsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Gets all notification settings for user
     * @summary Gets all notification settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(options?: any) {
        return NotificationsApiFp(this.configuration).getNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets paperless setting for user
     * @summary Gets paperless setting for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getPaperless(options?: any) {
        return NotificationsApiFp(this.configuration).getPaperless(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification settings
     * @param {NotificationSettings} notificationSettings Update notification settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotifications(notificationSettings: NotificationSettings, options?: any) {
        return NotificationsApiFp(this.configuration).updateNotifications(notificationSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify paperless settings
     * @param {PaperlessSettingsRequest} paperlessSettingsRequest Paperless settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updatePaperless(paperlessSettingsRequest: PaperlessSettingsRequest, options?: any) {
        return NotificationsApiFp(this.configuration).updatePaperless(paperlessSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get offer details
         * @param {string} offerId Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            if (offerId === null || offerId === undefined) {
                throw new RequiredError('offerId','Required parameter offerId was null or undefined when calling getOffer.');
            }
            const localVarPath = `/credit-card/offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get offer details
         * @param {string} offerId Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffer(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await OfferApiAxiosParamCreator(configuration).getOffer(offerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get offer details
         * @param {string} offerId Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer(offerId: string, options?: any): AxiosPromise<Offer> {
            return OfferApiFp(configuration).getOffer(offerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Get offer details
     * @param {string} offerId Offer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOffer(offerId: string, options?: any) {
        return OfferApiFp(this.configuration).getOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds an unverified bank account and links it to constellation, for use as a payment method
         * @summary Add an unverified bank account and link
         * @param {AddBankAccountRequest} [addBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankAccount: async (addBankAccountRequest?: AddBankAccountRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/payment/add-bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addBankAccountRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addBankAccountRequest !== undefined ? addBankAccountRequest : {})
                : (addBankAccountRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel payment by scheduleId
         * @summary Cancel payment by scheduleId
         * @param {string} cardId Credit Card ID
         * @param {string} scheduleId schedule ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPayment: async (cardId: string, scheduleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling cancelPayment.');
            }
            // verify required parameter 'scheduleId' is not null or undefined
            if (scheduleId === null || scheduleId === undefined) {
                throw new RequiredError('scheduleId','Required parameter scheduleId was null or undefined when calling cancelPayment.');
            }
            const localVarPath = `/credit-card/payment/{cardId}/schedules/{scheduleId}/cancel`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or schedule payment
         * @param {PaymentCreate} paymentCreate Create a new payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment: async (paymentCreate: PaymentCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentCreate' is not null or undefined
            if (paymentCreate === null || paymentCreate === undefined) {
                throw new RequiredError('paymentCreate','Required parameter paymentCreate was null or undefined when calling createPayment.');
            }
            const localVarPath = `/credit-card/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof paymentCreate !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(paymentCreate !== undefined ? paymentCreate : {})
                : (paymentCreate || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List eligible bank accounts
         * @summary List eligible bank accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/payment/methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a scheduled payment for a given creditAccountId and scheduleId
         * @summary Gets a scheduled payment for a given creditAccountId and scheduleId
         * @param {string} creditAccountId Credit Card Account ID
         * @param {string} scheduleId payment schedule id. It can be activity.id if activity type is payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledPayment: async (creditAccountId: string, scheduleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getScheduledPayment.');
            }
            // verify required parameter 'scheduleId' is not null or undefined
            if (scheduleId === null || scheduleId === undefined) {
                throw new RequiredError('scheduleId','Required parameter scheduleId was null or undefined when calling getScheduledPayment.');
            }
            const localVarPath = `/credit-card/payment/{creditAccountId}/schedules/{scheduleId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)))
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List scheduled payments for a given creditAccountId
         * @summary List scheduled payments for a given creditAccountId
         * @param {string} creditAccountId Credit Card Account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledPayments: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getScheduledPayments.');
            }
            const localVarPath = `/credit-card/payment/{creditAccountId}/schedules`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List all previous payments for user
         * @summary List all previous payments
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {string} [quarter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments: async (begin?: string, end?: string, month?: string, year?: string, quarter?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (begin !== undefined) {
                localVarQueryParameter['begin'] = begin;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (quarter !== undefined) {
                localVarQueryParameter['quarter'] = quarter;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Adds an unverified bank account and links it to constellation, for use as a payment method
         * @summary Add an unverified bank account and link
         * @param {AddBankAccountRequest} [addBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBankAccount(addBankAccountRequest?: AddBankAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddBankAccountResponse>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).addBankAccount(addBankAccountRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Cancel payment by scheduleId
         * @summary Cancel payment by scheduleId
         * @param {string} cardId Credit Card ID
         * @param {string} scheduleId schedule ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPayment(cardId: string, scheduleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).cancelPayment(cardId, scheduleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or schedule payment
         * @param {PaymentCreate} paymentCreate Create a new payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayment(paymentCreate: PaymentCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).createPayment(paymentCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List eligible bank accounts
         * @summary List eligible bank accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankAccount>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).getPaymentMethods(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets a scheduled payment for a given creditAccountId and scheduleId
         * @summary Gets a scheduled payment for a given creditAccountId and scheduleId
         * @param {string} creditAccountId Credit Card Account ID
         * @param {string} scheduleId payment schedule id. It can be activity.id if activity type is payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduledPayment(creditAccountId: string, scheduleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledPayment>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).getScheduledPayment(creditAccountId, scheduleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List scheduled payments for a given creditAccountId
         * @summary List scheduled payments for a given creditAccountId
         * @param {string} creditAccountId Credit Card Account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduledPayments(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledPayment>>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).getScheduledPayments(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all previous payments for user
         * @summary List all previous payments
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {string} [quarter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPayments(begin?: string, end?: string, month?: string, year?: string, quarter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResponse>> {
            const localVarAxiosArgs = await PaymentApiAxiosParamCreator(configuration).listPayments(begin, end, month, year, quarter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Adds an unverified bank account and links it to constellation, for use as a payment method
         * @summary Add an unverified bank account and link
         * @param {AddBankAccountRequest} [addBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankAccount(addBankAccountRequest?: AddBankAccountRequest, options?: any): AxiosPromise<AddBankAccountResponse> {
            return PaymentApiFp(configuration).addBankAccount(addBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel payment by scheduleId
         * @summary Cancel payment by scheduleId
         * @param {string} cardId Credit Card ID
         * @param {string} scheduleId schedule ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPayment(cardId: string, scheduleId: string, options?: any): AxiosPromise<void> {
            return PaymentApiFp(configuration).cancelPayment(cardId, scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or schedule payment
         * @param {PaymentCreate} paymentCreate Create a new payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(paymentCreate: PaymentCreate, options?: any): AxiosPromise<void> {
            return PaymentApiFp(configuration).createPayment(paymentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * List eligible bank accounts
         * @summary List eligible bank accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(options?: any): AxiosPromise<Array<BankAccount>> {
            return PaymentApiFp(configuration).getPaymentMethods(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a scheduled payment for a given creditAccountId and scheduleId
         * @summary Gets a scheduled payment for a given creditAccountId and scheduleId
         * @param {string} creditAccountId Credit Card Account ID
         * @param {string} scheduleId payment schedule id. It can be activity.id if activity type is payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledPayment(creditAccountId: string, scheduleId: string, options?: any): AxiosPromise<Array<ScheduledPayment>> {
            return PaymentApiFp(configuration).getScheduledPayment(creditAccountId, scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * List scheduled payments for a given creditAccountId
         * @summary List scheduled payments for a given creditAccountId
         * @param {string} creditAccountId Credit Card Account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledPayments(creditAccountId: string, options?: any): AxiosPromise<Array<ScheduledPayment>> {
            return PaymentApiFp(configuration).getScheduledPayments(creditAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all previous payments for user
         * @summary List all previous payments
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {string} [quarter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(begin?: string, end?: string, month?: string, year?: string, quarter?: string, options?: any): AxiosPromise<PaymentResponse> {
            return PaymentApiFp(configuration).listPayments(begin, end, month, year, quarter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * Adds an unverified bank account and links it to constellation, for use as a payment method
     * @summary Add an unverified bank account and link
     * @param {AddBankAccountRequest} [addBankAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public addBankAccount(addBankAccountRequest?: AddBankAccountRequest, options?: any) {
        return PaymentApiFp(this.configuration).addBankAccount(addBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel payment by scheduleId
     * @summary Cancel payment by scheduleId
     * @param {string} cardId Credit Card ID
     * @param {string} scheduleId schedule ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public cancelPayment(cardId: string, scheduleId: string, options?: any) {
        return PaymentApiFp(this.configuration).cancelPayment(cardId, scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or schedule payment
     * @param {PaymentCreate} paymentCreate Create a new payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public createPayment(paymentCreate: PaymentCreate, options?: any) {
        return PaymentApiFp(this.configuration).createPayment(paymentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List eligible bank accounts
     * @summary List eligible bank accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getPaymentMethods(options?: any) {
        return PaymentApiFp(this.configuration).getPaymentMethods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a scheduled payment for a given creditAccountId and scheduleId
     * @summary Gets a scheduled payment for a given creditAccountId and scheduleId
     * @param {string} creditAccountId Credit Card Account ID
     * @param {string} scheduleId payment schedule id. It can be activity.id if activity type is payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getScheduledPayment(creditAccountId: string, scheduleId: string, options?: any) {
        return PaymentApiFp(this.configuration).getScheduledPayment(creditAccountId, scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List scheduled payments for a given creditAccountId
     * @summary List scheduled payments for a given creditAccountId
     * @param {string} creditAccountId Credit Card Account ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getScheduledPayments(creditAccountId: string, options?: any) {
        return PaymentApiFp(this.configuration).getScheduledPayments(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all previous payments for user
     * @summary List all previous payments
     * @param {string} [begin] 
     * @param {string} [end] 
     * @param {string} [month] 
     * @param {string} [year] 
     * @param {string} [quarter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public listPayments(begin?: string, end?: string, month?: string, year?: string, quarter?: string, options?: any) {
        return PaymentApiFp(this.configuration).listPayments(begin, end, month, year, quarter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionTypeApi - axios parameter creator
 * @export
 */
export const PromotionTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the promotion details for the specific promotion for the logged in user
         * @param {PromoParam} promoType The Promotion Type that you want details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPromo: async (promoType: PromoParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promoType' is not null or undefined
            if (promoType === null || promoType === undefined) {
                throw new RequiredError('promoType','Required parameter promoType was null or undefined when calling getAccountPromo.');
            }
            const localVarPath = `/credit-card/account/promo/{promoType}`
                .replace(`{${"promoType"}}`, encodeURIComponent(String(promoType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionTypeApi - functional programming interface
 * @export
 */
export const PromotionTypeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the promotion details for the specific promotion for the logged in user
         * @param {PromoParam} promoType The Promotion Type that you want details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountPromo(promoType: PromoParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardSpendPromotionResponse>> {
            const localVarAxiosArgs = await PromotionTypeApiAxiosParamCreator(configuration).getAccountPromo(promoType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PromotionTypeApi - factory interface
 * @export
 */
export const PromotionTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the promotion details for the specific promotion for the logged in user
         * @param {PromoParam} promoType The Promotion Type that you want details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPromo(promoType: PromoParam, options?: any): AxiosPromise<CardSpendPromotionResponse> {
            return PromotionTypeApiFp(configuration).getAccountPromo(promoType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionTypeApi - object-oriented interface
 * @export
 * @class PromotionTypeApi
 * @extends {BaseAPI}
 */
export class PromotionTypeApi extends BaseAPI {
    /**
     * 
     * @summary Get the promotion details for the specific promotion for the logged in user
     * @param {PromoParam} promoType The Promotion Type that you want details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionTypeApi
     */
    public getAccountPromo(promoType: PromoParam, options?: any) {
        return PromotionTypeApiFp(this.configuration).getAccountPromo(promoType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RewardApi - axios parameter creator
 * @export
 */
export const RewardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel disputed reward by Reward Id
         * @param {string} rewardId ID of the reward to be disputed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRewardDispute: async (rewardId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            if (rewardId === null || rewardId === undefined) {
                throw new RequiredError('rewardId','Required parameter rewardId was null or undefined when calling cancelRewardDispute.');
            }
            const localVarPath = `/credit-card/reward/{rewardId}/dispute/cancel`
                .replace(`{${"rewardId"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dispute a reward by Reward Id
         * @param {string} rewardId ID of the reward to be disputed
         * @param {RewardDispute} rewardDispute Create a new dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeReward: async (rewardId: string, rewardDispute: RewardDispute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rewardId' is not null or undefined
            if (rewardId === null || rewardId === undefined) {
                throw new RequiredError('rewardId','Required parameter rewardId was null or undefined when calling disputeReward.');
            }
            // verify required parameter 'rewardDispute' is not null or undefined
            if (rewardDispute === null || rewardDispute === undefined) {
                throw new RequiredError('rewardDispute','Required parameter rewardDispute was null or undefined when calling disputeReward.');
            }
            const localVarPath = `/credit-card/reward/{rewardId}/dispute`
                .replace(`{${"rewardId"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof rewardDispute !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(rewardDispute !== undefined ? rewardDispute : {})
                : (rewardDispute || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current reward currency
         * @summary Get current reward currency
         * @param {string} creditAccountId id of the credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRewardCurrency: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getCurrentRewardCurrency.');
            }
            const localVarPath = `/credit-card/reward/{creditAccountId}/currency`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of supported reward currencies
         * @summary Get list of supported reward currencies
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRewardSupportedCurrencies: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getCurrentRewardSupportedCurrencies.');
            }
            const localVarPath = `/credit-card/reward/{creditAccountId}/currency/supported`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current owed rewards balance
         * @summary Get current owed rewards balance
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwedRewardAmount: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getOwedRewardAmount.');
            }
            const localVarPath = `/credit-card/reward/{creditAccountId}/owedBalance`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get reward details by lifecycleId
         * @summary Get reward details by lifecycleId
         * @param {string} lifecycleId lifecycle id of reward
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardDetails: async (lifecycleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lifecycleId' is not null or undefined
            if (lifecycleId === null || lifecycleId === undefined) {
                throw new RequiredError('lifecycleId','Required parameter lifecycleId was null or undefined when calling getRewardDetails.');
            }
            const localVarPath = `/credit-card/reward/lifecycle/{lifecycleId}/details`
                .replace(`{${"lifecycleId"}}`, encodeURIComponent(String(lifecycleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get reward stats - totals and last 30 days
         * @summary Get reward stats - totals and last 30 days
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardStats: async (creditAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getRewardStats.');
            }
            const localVarPath = `/credit-card/reward/{creditAccountId}/stats`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List all rewards by creditAccountId, query filter by creditUserId
         * @summary List all rewards by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
         * @param {string} [creditUserId] Credit user id
         * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewards: async (creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getRewards.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling getRewards.');
            }
            const localVarPath = `/credit-card/reward/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (creditUserId !== undefined) {
                localVarQueryParameter['creditUserId'] = creditUserId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List all rewards by creditAccountId, query filter by timestamp
         * @summary List all rewards by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardsV2: async (creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling getRewardsV2.');
            }
            const localVarPath = `/credit-card/v2/reward/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (begin !== undefined) {
                localVarQueryParameter['begin'] = begin;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Change reward currency
         * @summary Change reward currency
         * @param {string} creditAccountId ID of credit account
         * @param {RewardCurrency} rewardCurrency Currency to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRewardCurrency: async (creditAccountId: string, rewardCurrency: RewardCurrency, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling setRewardCurrency.');
            }
            // verify required parameter 'rewardCurrency' is not null or undefined
            if (rewardCurrency === null || rewardCurrency === undefined) {
                throw new RequiredError('rewardCurrency','Required parameter rewardCurrency was null or undefined when calling setRewardCurrency.');
            }
            const localVarPath = `/credit-card/reward/{creditAccountId}/currency`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof rewardCurrency !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(rewardCurrency !== undefined ? rewardCurrency : {})
                : (rewardCurrency || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RewardApi - functional programming interface
 * @export
 */
export const RewardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel disputed reward by Reward Id
         * @param {string} rewardId ID of the reward to be disputed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRewardDispute(rewardId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardDisputeResponse>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).cancelRewardDispute(rewardId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dispute a reward by Reward Id
         * @param {string} rewardId ID of the reward to be disputed
         * @param {RewardDispute} rewardDispute Create a new dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disputeReward(rewardId: string, rewardDispute: RewardDispute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardDisputeResponse>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).disputeReward(rewardId, rewardDispute, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get current reward currency
         * @summary Get current reward currency
         * @param {string} creditAccountId id of the credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentRewardCurrency(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardCurrency>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getCurrentRewardCurrency(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of supported reward currencies
         * @summary Get list of supported reward currencies
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentRewardSupportedCurrencies(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardCurrencySupportedList>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getCurrentRewardSupportedCurrencies(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get current owed rewards balance
         * @summary Get current owed rewards balance
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwedRewardAmount(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwedRewardsBalanceResponse>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getOwedRewardAmount(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get reward details by lifecycleId
         * @summary Get reward details by lifecycleId
         * @param {string} lifecycleId lifecycle id of reward
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardDetails(lifecycleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRewardsDetails>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getRewardDetails(lifecycleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get reward stats - totals and last 30 days
         * @summary Get reward stats - totals and last 30 days
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardStats(creditAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRewardStatsResponse>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getRewardStats(creditAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all rewards by creditAccountId, query filter by creditUserId
         * @summary List all rewards by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
         * @param {string} [creditUserId] Credit user id
         * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewards(creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardLineItem>>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getRewards(creditAccountId, pageSize, creditUserId, pageNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all rewards by creditAccountId, query filter by timestamp
         * @summary List all rewards by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardsV2(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardLineItem>>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).getRewardsV2(creditAccountId, search, begin, end, month, year, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Change reward currency
         * @summary Change reward currency
         * @param {string} creditAccountId ID of credit account
         * @param {RewardCurrency} rewardCurrency Currency to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRewardCurrency(creditAccountId: string, rewardCurrency: RewardCurrency, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardCurrency>> {
            const localVarAxiosArgs = await RewardApiAxiosParamCreator(configuration).setRewardCurrency(creditAccountId, rewardCurrency, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RewardApi - factory interface
 * @export
 */
export const RewardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel disputed reward by Reward Id
         * @param {string} rewardId ID of the reward to be disputed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRewardDispute(rewardId: string, options?: any): AxiosPromise<RewardDisputeResponse> {
            return RewardApiFp(configuration).cancelRewardDispute(rewardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dispute a reward by Reward Id
         * @param {string} rewardId ID of the reward to be disputed
         * @param {RewardDispute} rewardDispute Create a new dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeReward(rewardId: string, rewardDispute: RewardDispute, options?: any): AxiosPromise<RewardDisputeResponse> {
            return RewardApiFp(configuration).disputeReward(rewardId, rewardDispute, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current reward currency
         * @summary Get current reward currency
         * @param {string} creditAccountId id of the credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRewardCurrency(creditAccountId: string, options?: any): AxiosPromise<RewardCurrency> {
            return RewardApiFp(configuration).getCurrentRewardCurrency(creditAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of supported reward currencies
         * @summary Get list of supported reward currencies
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRewardSupportedCurrencies(creditAccountId: string, options?: any): AxiosPromise<RewardCurrencySupportedList> {
            return RewardApiFp(configuration).getCurrentRewardSupportedCurrencies(creditAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current owed rewards balance
         * @summary Get current owed rewards balance
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwedRewardAmount(creditAccountId: string, options?: any): AxiosPromise<OwedRewardsBalanceResponse> {
            return RewardApiFp(configuration).getOwedRewardAmount(creditAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get reward details by lifecycleId
         * @summary Get reward details by lifecycleId
         * @param {string} lifecycleId lifecycle id of reward
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardDetails(lifecycleId: string, options?: any): AxiosPromise<TransactionRewardsDetails> {
            return RewardApiFp(configuration).getRewardDetails(lifecycleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get reward stats - totals and last 30 days
         * @summary Get reward stats - totals and last 30 days
         * @param {string} creditAccountId id of credit line
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardStats(creditAccountId: string, options?: any): AxiosPromise<GetRewardStatsResponse> {
            return RewardApiFp(configuration).getRewardStats(creditAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all rewards by creditAccountId, query filter by creditUserId
         * @summary List all rewards by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
         * @param {string} [creditUserId] Credit user id
         * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewards(creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options?: any): AxiosPromise<Array<RewardLineItem>> {
            return RewardApiFp(configuration).getRewards(creditAccountId, pageSize, creditUserId, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * List all rewards by creditAccountId, query filter by timestamp
         * @summary List all rewards by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardsV2(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<RewardLineItem>> {
            return RewardApiFp(configuration).getRewardsV2(creditAccountId, search, begin, end, month, year, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Change reward currency
         * @summary Change reward currency
         * @param {string} creditAccountId ID of credit account
         * @param {RewardCurrency} rewardCurrency Currency to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRewardCurrency(creditAccountId: string, rewardCurrency: RewardCurrency, options?: any): AxiosPromise<RewardCurrency> {
            return RewardApiFp(configuration).setRewardCurrency(creditAccountId, rewardCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RewardApi - object-oriented interface
 * @export
 * @class RewardApi
 * @extends {BaseAPI}
 */
export class RewardApi extends BaseAPI {
    /**
     * 
     * @summary Cancel disputed reward by Reward Id
     * @param {string} rewardId ID of the reward to be disputed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public cancelRewardDispute(rewardId: string, options?: any) {
        return RewardApiFp(this.configuration).cancelRewardDispute(rewardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dispute a reward by Reward Id
     * @param {string} rewardId ID of the reward to be disputed
     * @param {RewardDispute} rewardDispute Create a new dispute
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public disputeReward(rewardId: string, rewardDispute: RewardDispute, options?: any) {
        return RewardApiFp(this.configuration).disputeReward(rewardId, rewardDispute, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current reward currency
     * @summary Get current reward currency
     * @param {string} creditAccountId id of the credit line
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getCurrentRewardCurrency(creditAccountId: string, options?: any) {
        return RewardApiFp(this.configuration).getCurrentRewardCurrency(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of supported reward currencies
     * @summary Get list of supported reward currencies
     * @param {string} creditAccountId id of credit line
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getCurrentRewardSupportedCurrencies(creditAccountId: string, options?: any) {
        return RewardApiFp(this.configuration).getCurrentRewardSupportedCurrencies(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current owed rewards balance
     * @summary Get current owed rewards balance
     * @param {string} creditAccountId id of credit line
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getOwedRewardAmount(creditAccountId: string, options?: any) {
        return RewardApiFp(this.configuration).getOwedRewardAmount(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get reward details by lifecycleId
     * @summary Get reward details by lifecycleId
     * @param {string} lifecycleId lifecycle id of reward
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getRewardDetails(lifecycleId: string, options?: any) {
        return RewardApiFp(this.configuration).getRewardDetails(lifecycleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get reward stats - totals and last 30 days
     * @summary Get reward stats - totals and last 30 days
     * @param {string} creditAccountId id of credit line
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getRewardStats(creditAccountId: string, options?: any) {
        return RewardApiFp(this.configuration).getRewardStats(creditAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all rewards by creditAccountId, query filter by creditUserId
     * @summary List all rewards by creditAccountId
     * @param {string} creditAccountId Credit account id
     * @param {number} pageSize The pageSize parameter is the number of results to return in each page.
     * @param {string} [creditUserId] Credit user id
     * @param {number} [pageNumber] The pageNumber is the batch number where each batch has pageSize number of activity items. default is 1.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getRewards(creditAccountId: string, pageSize: number, creditUserId?: string, pageNumber?: number, options?: any) {
        return RewardApiFp(this.configuration).getRewards(creditAccountId, pageSize, creditUserId, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all rewards by creditAccountId, query filter by timestamp
     * @summary List all rewards by creditAccountId
     * @param {string} creditAccountId Credit account id
     * @param {string} [search] 
     * @param {string} [begin] 
     * @param {string} [end] 
     * @param {string} [month] 
     * @param {string} [year] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public getRewardsV2(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, options?: any) {
        return RewardApiFp(this.configuration).getRewardsV2(creditAccountId, search, begin, end, month, year, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change reward currency
     * @summary Change reward currency
     * @param {string} creditAccountId ID of credit account
     * @param {RewardCurrency} rewardCurrency Currency to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardApi
     */
    public setRewardCurrency(creditAccountId: string, rewardCurrency: RewardCurrency, options?: any) {
        return RewardApiFp(this.configuration).setRewardCurrency(creditAccountId, rewardCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatementApi - axios parameter creator
 * @export
 */
export const StatementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the Card Holder Agreement for the user
         * @summary Get the Card Holder Agreement for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCardCardHolderAgreementGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/cardHolderAgreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List all statements by creditAccountId, query filter by statement date
         * @summary List all statements by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatements: async (creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling listStatements.');
            }
            const localVarPath = `/credit-card/statement/list/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (begin !== undefined) {
                localVarQueryParameter['begin'] = begin;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatementApi - functional programming interface
 * @export
 */
export const StatementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get the Card Holder Agreement for the user
         * @summary Get the Card Holder Agreement for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCardCardHolderAgreementGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await StatementApiAxiosParamCreator(configuration).creditCardCardHolderAgreementGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all statements by creditAccountId, query filter by statement date
         * @summary List all statements by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStatements(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementResponse>> {
            const localVarAxiosArgs = await StatementApiAxiosParamCreator(configuration).listStatements(creditAccountId, search, begin, end, month, year, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatementApi - factory interface
 * @export
 */
export const StatementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get the Card Holder Agreement for the user
         * @summary Get the Card Holder Agreement for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCardCardHolderAgreementGet(options?: any): AxiosPromise<any> {
            return StatementApiFp(configuration).creditCardCardHolderAgreementGet(options).then((request) => request(axios, basePath));
        },
        /**
         * List all statements by creditAccountId, query filter by statement date
         * @summary List all statements by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatements(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, pageSize?: number, options?: any): AxiosPromise<StatementResponse> {
            return StatementApiFp(configuration).listStatements(creditAccountId, search, begin, end, month, year, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatementApi - object-oriented interface
 * @export
 * @class StatementApi
 * @extends {BaseAPI}
 */
export class StatementApi extends BaseAPI {
    /**
     * Get the Card Holder Agreement for the user
     * @summary Get the Card Holder Agreement for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public creditCardCardHolderAgreementGet(options?: any) {
        return StatementApiFp(this.configuration).creditCardCardHolderAgreementGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all statements by creditAccountId, query filter by statement date
     * @summary List all statements by creditAccountId
     * @param {string} creditAccountId Credit account id
     * @param {string} [search] 
     * @param {string} [begin] 
     * @param {string} [end] 
     * @param {string} [month] 
     * @param {string} [year] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public listStatements(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, pageSize?: number, options?: any) {
        return StatementApiFp(this.configuration).listStatements(creditAccountId, search, begin, end, month, year, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel disputed transaction by creditAccountId and transactionId
         * @param {string} transactionId ID of transaction
         * @param {DisputeCancel} disputeCancel Cancel transaction dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDisputeTransaction: async (transactionId: string, disputeCancel: DisputeCancel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling cancelDisputeTransaction.');
            }
            // verify required parameter 'disputeCancel' is not null or undefined
            if (disputeCancel === null || disputeCancel === undefined) {
                throw new RequiredError('disputeCancel','Required parameter disputeCancel was null or undefined when calling cancelDisputeTransaction.');
            }
            const localVarPath = `/credit-card/transaction/{transactionId}/dispute/cancel`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof disputeCancel !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(disputeCancel !== undefined ? disputeCancel : {})
                : (disputeCancel || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dispute transaction by creditAccountId and transactionId
         * @param {string} transactionId ID of transaction
         * @param {Dispute} dispute Create a new dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeTransaction: async (transactionId: string, dispute: Dispute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling disputeTransaction.');
            }
            // verify required parameter 'dispute' is not null or undefined
            if (dispute === null || dispute === undefined) {
                throw new RequiredError('dispute','Required parameter dispute was null or undefined when calling disputeTransaction.');
            }
            const localVarPath = `/credit-card/transaction/{transactionId}/dispute`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof dispute !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(dispute !== undefined ? dispute : {})
                : (dispute || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dispute transaction by creditAccountId and transactionId
         * @param {string} transactionId 
         * @param {string} reason Only values defined in DisputeReasonEnum should be used
         * @param {Array<any>} [files] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeTransactionV2: async (transactionId: string, reason: string, files?: Array<any>, description?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling disputeTransactionV2.');
            }
            // verify required parameter 'reason' is not null or undefined
            if (reason === null || reason === undefined) {
                throw new RequiredError('reason','Required parameter reason was null or undefined when calling disputeTransactionV2.');
            }
            const localVarPath = `/credit-card/v2/transaction/dispute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (transactionId !== undefined) { 
                localVarFormParams.append('transactionId', transactionId as any);
            }
    
            if (reason !== undefined) { 
                localVarFormParams.append('reason', reason as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get transaction details by lifecycleId
         * @summary Get transaction details by lifecycleId
         * @param {string} lifecycleId lifecycle id of transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails: async (lifecycleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lifecycleId' is not null or undefined
            if (lifecycleId === null || lifecycleId === undefined) {
                throw new RequiredError('lifecycleId','Required parameter lifecycleId was null or undefined when calling getTransactionDetails.');
            }
            const localVarPath = `/credit-card/transaction/lifecycle/{lifecycleId}/details`
                .replace(`{${"lifecycleId"}}`, encodeURIComponent(String(lifecycleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * List all transactions by creditAccountId, query filter by settlement timestamp
         * @summary List all transactions by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [selectAuths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions: async (creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, selectAuths?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditAccountId' is not null or undefined
            if (creditAccountId === null || creditAccountId === undefined) {
                throw new RequiredError('creditAccountId','Required parameter creditAccountId was null or undefined when calling listTransactions.');
            }
            const localVarPath = `/credit-card/transaction/list/{creditAccountId}`
                .replace(`{${"creditAccountId"}}`, encodeURIComponent(String(creditAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (begin !== undefined) {
                localVarQueryParameter['begin'] = begin;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (selectAuths !== undefined) {
                localVarQueryParameter['selectAuths'] = selectAuths;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel disputed transaction by creditAccountId and transactionId
         * @param {string} transactionId ID of transaction
         * @param {DisputeCancel} disputeCancel Cancel transaction dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDisputeTransaction(transactionId: string, disputeCancel: DisputeCancel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisputeResponse>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).cancelDisputeTransaction(transactionId, disputeCancel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dispute transaction by creditAccountId and transactionId
         * @param {string} transactionId ID of transaction
         * @param {Dispute} dispute Create a new dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disputeTransaction(transactionId: string, dispute: Dispute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisputeResponse>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).disputeTransaction(transactionId, dispute, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dispute transaction by creditAccountId and transactionId
         * @param {string} transactionId 
         * @param {string} reason Only values defined in DisputeReasonEnum should be used
         * @param {Array<any>} [files] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disputeTransactionV2(transactionId: string, reason: string, files?: Array<any>, description?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisputeResponse>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).disputeTransactionV2(transactionId, reason, files, description, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get transaction details by lifecycleId
         * @summary Get transaction details by lifecycleId
         * @param {string} lifecycleId lifecycle id of transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionDetails(lifecycleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRewardsDetails>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).getTransactionDetails(lifecycleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all transactions by creditAccountId, query filter by settlement timestamp
         * @summary List all transactions by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [selectAuths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactions(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, selectAuths?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).listTransactions(creditAccountId, search, begin, end, month, year, page, pageSize, selectAuths, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel disputed transaction by creditAccountId and transactionId
         * @param {string} transactionId ID of transaction
         * @param {DisputeCancel} disputeCancel Cancel transaction dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDisputeTransaction(transactionId: string, disputeCancel: DisputeCancel, options?: any): AxiosPromise<DisputeResponse> {
            return TransactionApiFp(configuration).cancelDisputeTransaction(transactionId, disputeCancel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dispute transaction by creditAccountId and transactionId
         * @param {string} transactionId ID of transaction
         * @param {Dispute} dispute Create a new dispute
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeTransaction(transactionId: string, dispute: Dispute, options?: any): AxiosPromise<DisputeResponse> {
            return TransactionApiFp(configuration).disputeTransaction(transactionId, dispute, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dispute transaction by creditAccountId and transactionId
         * @param {string} transactionId 
         * @param {string} reason Only values defined in DisputeReasonEnum should be used
         * @param {Array<any>} [files] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disputeTransactionV2(transactionId: string, reason: string, files?: Array<any>, description?: string, options?: any): AxiosPromise<DisputeResponse> {
            return TransactionApiFp(configuration).disputeTransactionV2(transactionId, reason, files, description, options).then((request) => request(axios, basePath));
        },
        /**
         * Get transaction details by lifecycleId
         * @summary Get transaction details by lifecycleId
         * @param {string} lifecycleId lifecycle id of transaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails(lifecycleId: string, options?: any): AxiosPromise<TransactionRewardsDetails> {
            return TransactionApiFp(configuration).getTransactionDetails(lifecycleId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all transactions by creditAccountId, query filter by settlement timestamp
         * @summary List all transactions by creditAccountId
         * @param {string} creditAccountId Credit account id
         * @param {string} [search] 
         * @param {string} [begin] 
         * @param {string} [end] 
         * @param {string} [month] 
         * @param {string} [year] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [selectAuths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, selectAuths?: boolean, options?: any): AxiosPromise<TransactionResponse> {
            return TransactionApiFp(configuration).listTransactions(creditAccountId, search, begin, end, month, year, page, pageSize, selectAuths, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @summary Cancel disputed transaction by creditAccountId and transactionId
     * @param {string} transactionId ID of transaction
     * @param {DisputeCancel} disputeCancel Cancel transaction dispute
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public cancelDisputeTransaction(transactionId: string, disputeCancel: DisputeCancel, options?: any) {
        return TransactionApiFp(this.configuration).cancelDisputeTransaction(transactionId, disputeCancel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dispute transaction by creditAccountId and transactionId
     * @param {string} transactionId ID of transaction
     * @param {Dispute} dispute Create a new dispute
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public disputeTransaction(transactionId: string, dispute: Dispute, options?: any) {
        return TransactionApiFp(this.configuration).disputeTransaction(transactionId, dispute, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dispute transaction by creditAccountId and transactionId
     * @param {string} transactionId 
     * @param {string} reason Only values defined in DisputeReasonEnum should be used
     * @param {Array<any>} [files] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public disputeTransactionV2(transactionId: string, reason: string, files?: Array<any>, description?: string, options?: any) {
        return TransactionApiFp(this.configuration).disputeTransactionV2(transactionId, reason, files, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get transaction details by lifecycleId
     * @summary Get transaction details by lifecycleId
     * @param {string} lifecycleId lifecycle id of transaction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransactionDetails(lifecycleId: string, options?: any) {
        return TransactionApiFp(this.configuration).getTransactionDetails(lifecycleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all transactions by creditAccountId, query filter by settlement timestamp
     * @summary List all transactions by creditAccountId
     * @param {string} creditAccountId Credit account id
     * @param {string} [search] 
     * @param {string} [begin] 
     * @param {string} [end] 
     * @param {string} [month] 
     * @param {string} [year] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [selectAuths] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public listTransactions(creditAccountId: string, search?: string, begin?: string, end?: string, month?: string, year?: string, page?: number, pageSize?: number, selectAuths?: boolean, options?: any) {
        return TransactionApiFp(this.configuration).listTransactions(creditAccountId, search, begin, end, month, year, page, pageSize, selectAuths, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VGSApi - axios parameter creator
 * @export
 */
export const VGSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List environment variables for Very Good Security
         * @summary List environment variables for Very Good Security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVGS: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/vgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VGSApi - functional programming interface
 * @export
 */
export const VGSApiFp = function(configuration?: Configuration) {
    return {
        /**
         * List environment variables for Very Good Security
         * @summary List environment variables for Very Good Security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVGS(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VGSEnvironment>> {
            const localVarAxiosArgs = await VGSApiAxiosParamCreator(configuration).listVGS(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VGSApi - factory interface
 * @export
 */
export const VGSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * List environment variables for Very Good Security
         * @summary List environment variables for Very Good Security
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVGS(options?: any): AxiosPromise<VGSEnvironment> {
            return VGSApiFp(configuration).listVGS(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VGSApi - object-oriented interface
 * @export
 * @class VGSApi
 * @extends {BaseAPI}
 */
export class VGSApi extends BaseAPI {
    /**
     * List environment variables for Very Good Security
     * @summary List environment variables for Very Good Security
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VGSApi
     */
    public listVGS(options?: any) {
        return VGSApiFp(this.configuration).listVGS(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarmWelcomeApi - axios parameter creator
 * @export
 */
export const WarmWelcomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all the warm welcome items and their states available.
         * @summary List all the warm welcome items and their states available
         * @param {string} creditUserId internal credit user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWarmWelcomeStates: async (creditUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditUserId' is not null or undefined
            if (creditUserId === null || creditUserId === undefined) {
                throw new RequiredError('creditUserId','Required parameter creditUserId was null or undefined when calling listWarmWelcomeStates.');
            }
            const localVarPath = `/credit-card/warm-welcome/{creditUserId}`
                .replace(`{${"creditUserId"}}`, encodeURIComponent(String(creditUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates warm welcome item state.
         * @summary Updates warm welcome item state
         * @param {WarmWelcomeItemUpdateRequest} [warmWelcomeItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarmWelcomeState: async (warmWelcomeItemUpdateRequest?: WarmWelcomeItemUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/credit-card/warm-welcome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof warmWelcomeItemUpdateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(warmWelcomeItemUpdateRequest !== undefined ? warmWelcomeItemUpdateRequest : {})
                : (warmWelcomeItemUpdateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarmWelcomeApi - functional programming interface
 * @export
 */
export const WarmWelcomeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * List all the warm welcome items and their states available.
         * @summary List all the warm welcome items and their states available
         * @param {string} creditUserId internal credit user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWarmWelcomeStates(creditUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarmWelcomeTodoItemsResponse>> {
            const localVarAxiosArgs = await WarmWelcomeApiAxiosParamCreator(configuration).listWarmWelcomeStates(creditUserId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates warm welcome item state.
         * @summary Updates warm welcome item state
         * @param {WarmWelcomeItemUpdateRequest} [warmWelcomeItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarmWelcomeState(warmWelcomeItemUpdateRequest?: WarmWelcomeItemUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WarmWelcomeApiAxiosParamCreator(configuration).updateWarmWelcomeState(warmWelcomeItemUpdateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WarmWelcomeApi - factory interface
 * @export
 */
export const WarmWelcomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * List all the warm welcome items and their states available.
         * @summary List all the warm welcome items and their states available
         * @param {string} creditUserId internal credit user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWarmWelcomeStates(creditUserId: string, options?: any): AxiosPromise<WarmWelcomeTodoItemsResponse> {
            return WarmWelcomeApiFp(configuration).listWarmWelcomeStates(creditUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates warm welcome item state.
         * @summary Updates warm welcome item state
         * @param {WarmWelcomeItemUpdateRequest} [warmWelcomeItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarmWelcomeState(warmWelcomeItemUpdateRequest?: WarmWelcomeItemUpdateRequest, options?: any): AxiosPromise<void> {
            return WarmWelcomeApiFp(configuration).updateWarmWelcomeState(warmWelcomeItemUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarmWelcomeApi - object-oriented interface
 * @export
 * @class WarmWelcomeApi
 * @extends {BaseAPI}
 */
export class WarmWelcomeApi extends BaseAPI {
    /**
     * List all the warm welcome items and their states available.
     * @summary List all the warm welcome items and their states available
     * @param {string} creditUserId internal credit user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarmWelcomeApi
     */
    public listWarmWelcomeStates(creditUserId: string, options?: any) {
        return WarmWelcomeApiFp(this.configuration).listWarmWelcomeStates(creditUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates warm welcome item state.
     * @summary Updates warm welcome item state
     * @param {WarmWelcomeItemUpdateRequest} [warmWelcomeItemUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarmWelcomeApi
     */
    public updateWarmWelcomeState(warmWelcomeItemUpdateRequest?: WarmWelcomeItemUpdateRequest, options?: any) {
        return WarmWelcomeApiFp(this.configuration).updateWarmWelcomeState(warmWelcomeItemUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


