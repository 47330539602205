import * as React from "react";
import { IconCheck } from "@hubble/icons";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { GeminiAccount } from "@gemini-ui/constants/templateProps/account";
import { Button, Colors, Flex, IconBadge, Menu, Spacer, Spacing, Text } from "@gemini-ui/design-system";
import CryptoIcon from "@gemini-ui/images/icons/cdn/CryptoIcon";
import { TransferAccount } from "@gemini-ui/pages/transfers/constants";
import { emptySelectedCurrency, getAccountBalance } from "@gemini-ui/pages/transfers/utils";
import { TRANSFER_DIRECTION } from "@gemini-ui/pages/transfers/utils/transferErrors";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

type Props = {
  currency: CurrencyShortNameFiat;
  supportedFiat: GeminiAccount["supportedFiat"];
  onChangeCurrencyClick: (currency: CurrencyShortNameFiat) => void;
  sourceAccount?: TransferAccount;
  transferDirection?: keyof typeof TRANSFER_DIRECTION;
};

const moveSelectedCurrencyToTop = (fiats: CurrencyShortNameFiat[], currency: CurrencyShortNameFiat) => {
  const currencies = [...fiats];
  const indexToRemove = currencies.indexOf(currency);
  currencies.splice(indexToRemove, 1);
  currencies.unshift(currency);
  return currencies;
};

const renderCurrencySubText = (
  sourceAccount: TransferAccount,
  currencyOption: CurrencyShortNameFiat,
  transferDirection: keyof typeof TRANSFER_DIRECTION,
  intl: IntlShape
) => {
  if (transferDirection !== TRANSFER_DIRECTION.withdrawal) {
    return <Text.Label>{CURRENCIES_DETAIL[currencyOption].name}</Text.Label>;
  }

  const currencyBalance = getAccountBalance(sourceAccount, currencyOption) || emptySelectedCurrency(currencyOption);
  const { availableForWithdrawal } = currencyBalance;

  return (
    <Text.Body size="xs" color={Colors.gray[600]}>
      {intl.formatMessage(
        defineMessage({
          defaultMessage: "<Balance></Balance>  available",
        }),
        {
          Balance: () => <Money {...availableForWithdrawal} hideTrailingSign />,
        }
      )}
    </Text.Body>
  );
};

export const ChangeCurrencyMenu = ({
  currency,
  onChangeCurrencyClick,
  supportedFiat,
  sourceAccount,
  transferDirection,
}: Props) => {
  const { intl } = useIntl();
  if (supportedFiat.length <= 1) return null;

  const renderCheckIconIfNeeded = (currencyOption: CurrencyShortNameFiat) => {
    if (currencyOption === currency) {
      return <IconBadge color="black" size="sm" backgroundColor="" icon={<IconCheck />} />;
    }
    return null;
  };

  return (
    <Spacer ml={2}>
      <Menu
        placement="bottom-start"
        data-testid="change-currency-menu"
        renderMenu={
          <Menu.UL>
            {moveSelectedCurrencyToTop(supportedFiat, currency).map(currencyOption => {
              return (
                <Menu.LI
                  data-testid={`${currencyOption}-item`}
                  key={`${currencyOption}-item`}
                  onClick={() => onChangeCurrencyClick(currencyOption)}
                  iconLeft={
                    <Flex width={Spacing.scale[4]}>
                      <CryptoIcon symbol={currencyOption} />
                    </Flex>
                  }
                >
                  <Flex alignItems="center" justify="space-between">
                    <Flex.Column align="flex-start">
                      <Text.Body size="sm" color="black">
                        {currencyOption}
                      </Text.Body>
                      {renderCurrencySubText(sourceAccount, currencyOption, transferDirection, intl)}
                    </Flex.Column>
                    {renderCheckIconIfNeeded(currencyOption)}
                  </Flex>
                </Menu.LI>
              );
            })}
          </Menu.UL>
        }
        MenuButton={Button.Secondary}
        menuText={currency}
        size="sm"
      />
    </Spacer>
  );
};
