import React, { createRef } from "react";
import { HubbleProvider } from "@hubble/web";
import { Transition, TransitionGroup } from "react-transition-group";
import {
  TOASTER_DURATIONS,
  ToasterContextType,
  ToasterProps,
  ToastProps,
} from "@gemini-ui/design-system/Toaster/constants";
import { ToasterContainer } from "@gemini-ui/design-system/Toaster/styles";
import { Toast } from "@gemini-ui/design-system/Toaster/Toast";

const ToasterContext = React.createContext<ToasterContextType>({
  showToast: (toast: ToastProps) => ({ ...toast }),
  hideToast: (uuid: string) => {},
});

export const useToaster = () => {
  const context = React.useContext(ToasterContext);
  if (context === undefined) {
    throw new Error("useToaster must be used within a ToasterProvider");
  }
  return context;
};

export const ToasterProvider = ({ children }) => {
  const [toasts, setToasts] = React.useState<ToastProps[]>([]);

  const hideToast = React.useCallback(
    (uuid: string) => setToasts(current => current.filter(toast => toast.uuid !== uuid)),
    []
  );

  const showToast = React.useCallback((toast: ToastProps): ToastProps => {
    const newToast = { uuid: String(new Date().valueOf()), ...toast };
    // limit Toasts to two at a time.
    setToasts(current => [...current, newToast].slice(-2));
    return newToast;
  }, []);

  return (
    <ToasterContext.Provider
      value={{
        showToast,
        hideToast,
      }}
    >
      {children}
      <Toaster toasts={toasts} hideToast={hideToast} />
    </ToasterContext.Provider>
  );
};

export const Toaster = ({ hideToast, toasts }: ToasterProps) => (
  <ToasterContainer>
    <HubbleProvider inverse>
      <TransitionGroup component={null}>
        {toasts.map(toast => {
          const nodeRef = createRef<HTMLDivElement>();
          return (
            <Transition key={`transition-${toast.uuid}`} nodeRef={nodeRef} timeout={TOASTER_DURATIONS.transition}>
              {transitionStatus => (
                <Toast
                  key={toast.uuid}
                  hideToast={hideToast}
                  transitionStatus={transitionStatus}
                  ref={nodeRef}
                  {...toast}
                />
              )}
            </Transition>
          );
        })}
      </TransitionGroup>
    </HubbleProvider>
  </ToasterContainer>
);
