import React, { useEffect } from "react";
import { IconCopy } from "@hubble/icons";
import Clipboard, { useClipboard } from "@gemini-ui/components/Clipboard";
import {
  IconWrapper,
  ListItemContent,
  ListItemData,
  ListItemWrapper,
} from "@gemini-ui/components/CopyListItemToClipboard/styles";
import { Flex, Text, useToaster } from "@gemini-ui/design-system";
import { ListItemHr } from "@gemini-ui/design-system/ListItem/styles";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type Props = {
  label: string;
  data: string | JSX.Element;
  textToCopy: string | string[];
  hasDivider?: boolean;
};

export const CopyListItemToClipboard: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  data,
  textToCopy,
  hasDivider = true,
}) => {
  const { copied, setCopied } = useClipboard();
  const { intl } = useIntl();
  const { showToast } = useToaster();

  const copyText = typeof textToCopy === "string" ? textToCopy : textToCopy.filter(i => Boolean(i)).join("\n");
  const formattedData = typeof data === "string" ? <Text.Body bold>{data}</Text.Body> : data;

  useEffect(() => {
    if (copied) {
      showToast({
        message: intl.formatMessage(
          defineMessage({
            defaultMessage: "{label} copied.",
          }),
          {
            label: label,
          }
        ),
      });
    }
  }, [copied, intl, label, showToast]);

  return (
    <ListItemWrapper pl={2} pr={2} flexDirection="column">
      <Clipboard
        getText={() => copyText}
        onSuccess={() => setCopied(true)}
        render={onCopyClick => (
          <ListItemContent pt={2} pb={2} onClick={onCopyClick}>
            <Flex justifyContent="space-between">
              <Text.Body>{label}</Text.Body>
              <ListItemData maxWidth="80%">
                <IconWrapper>
                  <IconCopy></IconCopy>
                </IconWrapper>
                <Flex flexDirection="column" textAlign="right" ml={1}>
                  {formattedData}
                </Flex>
              </ListItemData>
            </Flex>
          </ListItemContent>
        )}
      />
      {hasDivider && <ListItemHr as="hr" m={0} />}
    </ListItemWrapper>
  );
};
