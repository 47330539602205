import React from "react";
import { useTheme } from "@emotion/react";
import { AnimatedHeader } from "@gemini-ui/components/AnimatedHeader";
import GrowSuccessDark from "@gemini-ui/images/animations/Grow_Success_Dark.json";
import GrowSuccessLight from "@gemini-ui/images/animations/Grow_Success_Light.json";

// There is a dark mode asset for this animation, but the rest of the Grow page is not dark mode ready.
// Remove or toggle this variable to allow this animation to be used in both modes.
const allowDarkMode = false;

const SuccessAnimation = () => {
  const theme = useTheme();

  const file = allowDarkMode && theme.isDark ? GrowSuccessDark : GrowSuccessLight;
  const { ip: firstFrame, op: lastFrame } = file;

  return (
    <AnimatedHeader
      file={file}
      delay={50}
      height={256}
      // Play once all the way through, then repeat the "HandleSparkle" animation.
      segmentsToPlay={[
        [firstFrame, lastFrame],
        [120, lastFrame],
      ]}
      loop
    />
  );
};

export const GrowSuccessAnimation = React.memo(SuccessAnimation);
