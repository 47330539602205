import { DepositFlowModal, TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import {
  GrowBuyState,
  GrowTransactionType,
  ModalStep,
  SupportedStakingPaymentMethod,
} from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";

export enum GrowBuyActionType {
  CLOSE_MODAL = "CLOSE_MODAL",
  INSTANTIATE_GROW_TRANSACTION = "INSTANTIATE_GROW_TRANSACTION",
  OPEN_MODAL = "OPEN_MODAL",
  POP_MODAL_VIEW = "POP_MODAL_VIEW",
  RESET_QUOTE = "RESET_QUOTE",
  SEGUE_MODAL_VIEW = "SEGUE_MODAL_VIEW",
  SET_CURRENCY = "SET_CURRENCY",
  SET_DEPOSIT_ERROR = "SET_DEPOSIT_ERROR",
  SET_QUOTE = "SET_QUOTE",
  SET_TRANSACTION_TYPE = "SET_TRANSACTION_TYPE",
  UPDATE_DEPOSIT_STATUS = "UPDATE_DEPOSIT_STATUS",
  UPDATE_REDEEM_STATUS = "UPDATE_REDEEM_STATUS",
  SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
}

export type GrowBuyAction =
  | {
      // TODO: This can probably just be currency, direction, and providerType
      type: GrowBuyActionType.INSTANTIATE_GROW_TRANSACTION;
      value: Partial<GrowBuyState> & Pick<GrowBuyState, "currency" | "transactionType"> & { view?: ModalStep };
    }
  | {
      type: GrowBuyActionType.SET_TRANSACTION_TYPE;
      value: GrowTransactionType;
    }
  | {
      type: GrowBuyActionType.UPDATE_REDEEM_STATUS;
      value: Partial<GrowBuyState["redeemStatus"]> &
        Partial<Pick<GrowBuyState, "currency" | "providerType">> & {
          view?: RedeemStep;
        };
    }
  | {
      type: GrowBuyActionType.SET_CURRENCY;
      value: GrowBuyState["currency"];
    }
  | {
      type: GrowBuyActionType.OPEN_MODAL;
    }
  | {
      type: GrowBuyActionType.CLOSE_MODAL;
    }
  | {
      type: GrowBuyActionType.SEGUE_MODAL_VIEW;
      value: ModalStep;
    }
  | {
      type: GrowBuyActionType.POP_MODAL_VIEW;
    }
  | {
      type: GrowBuyActionType.UPDATE_DEPOSIT_STATUS;
      value: Partial<GrowBuyState["depositStatus"]> &
        Partial<Pick<GrowBuyState, "currency" | "providerType">> & {
          view?: DepositFlowModal;
        };
    }
  | {
      type: GrowBuyActionType.SET_QUOTE;
      value: OrderQuote | TransferQuote;
    }
  | {
      type: GrowBuyActionType.RESET_QUOTE;
    }
  | {
      type: GrowBuyActionType.SET_DEPOSIT_ERROR;
      value: string;
    }
  | {
      type: GrowBuyActionType.SET_PAYMENT_METHOD;
      value: SupportedStakingPaymentMethod;
    };
// TODO: If/when we rewrite Deposit, here's a head start!

//   | {
//       type: "SET_RECURRING_STATE";
//       value: RecurringDepositState;
//     }
//   | {
//       type: "SET_ACCOUNT_STATE";
//       value: DepositAccountState;
//     }
