import { FieldErrors } from "react-hook-form";

export const useFormErrors = <T,>(errors: Partial<Record<keyof T, string>> | FieldErrors<T> = {}) => {
  const hasErrors = Object.keys(errors).length !== 0;

  function getMessage(errorValue: string | Record<string, any>) {
    // This check is to support Formik's error message object
    if (typeof errorValue === "string") {
      return errorValue;
    }

    return errorValue.message;
  }

  const formErrors = Object.entries(errors).map(error => {
    return { href: `#${error[0]}`, text: getMessage(error[1]) };
  });

  return [hasErrors, formErrors] as const;
};
