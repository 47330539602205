import styled from "@emotion/styled";
import { Colors, Spacing, Text } from "@gemini-ui/design-system";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

export const TradingInterfaceSwitcherContainer = styled.div`
  border-bottom: 1px solid ${Colors.gray[700]};
  margin: 0 ${Spacing.scale[2]};
  padding: ${Spacing.scale[1.5]} 0;

  @media ${BREAKPOINTS.tabletDownLarge} {
    border-bottom: 0;
    border-top: 1px solid ${Colors.gray[700]};
    margin: ${Spacing.scale[1.5]} 0 0 3em;
    padding: ${Spacing.scale[1.5]} ${Spacing.scale[4]} 0 0;
  }
`;

export const SwitcherButtonContainer = styled.div<{ active?: boolean }>`
  background: ${Colors.gray[900]};
  border-radius: ${Spacing.scale[2.5]};
  box-shadow: ${p => (p.active ? "0px 0px 0px 2px " + Colors.white + " inset" : "0")};
  cursor: pointer;
  pointer-events: ${p => (p.active ? "none" : "auto")};
  margin-top: ${Spacing.scale[1.5]};
  padding: ${Spacing.scale[2]};
  transition: box-shadow 0.3s ease;
  position: relative;

  + div {
    margin-top: ${Spacing.scale[1]};
  }

  span {
    color: ${Colors.blue[300]};
  }
`;
export const SectionHeader = styled(Text.Body)`
  font-size: 12px;
  line-height: 20px;

  @media ${BREAKPOINTS.tabletUpLarge} {
    font-size: 14px;
  }
`;

export const LoadingScrim = styled.div<{ isLoading?: boolean }>`
  display: ${p => (p.isLoading ? "flex" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  justify-content: center;
  align-items: center;
`;
