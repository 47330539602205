import React, { useState } from "react";
import * as Sentry from "@sentry/browser";
import { useForm } from "react-hook-form";
import { CURRENCIES_DETAIL } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { ConfirmBankDetailsModal } from "@gemini-ui/components/ConfirmBankDetailsModal";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankDetailsFormType, InitialWireFormType } from "@gemini-ui/constants/giact";
import { transferMethods } from "@gemini-ui/constants/wireFunding";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import {
  LinkPaymentType,
  PaymentTypeScope,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import BankBlockedModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/BankBlockedModal";
import { DuplicateAccountModal } from "@gemini-ui/pages/settings/BankSettings/components/DuplicateAccountModal";
import { GiactError } from "@gemini-ui/pages/settings/BankSettings/components/GiactModal/components/GiactError";
import { GiactRegisterBankAccount } from "@gemini-ui/pages/settings/BankSettings/components/GiactModal/components/GiactRegisterSuccessModal";
import { PaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/GiactModal/components/PaymentMethodSuccessModal";
import { GiactModalState } from "@gemini-ui/pages/settings/BankSettings/components/GiactModal/constants";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getError } from "@gemini-ui/utils/error";
import { IntlShape, useIntl } from "@gemini-ui/utils/intl";

type GiactModalProps = {
  isOpen: boolean;
  onBack?: () => void;
  handleGiactSuccess: () => void;
  handleGiactClose: () => void;
  handleFallbackToWire: (values?: InitialWireFormType) => void;
  isSettingsOrOnboarding?: boolean;
  openSelectPaymentMethodModal?: () => void;
  scope?: PaymentTypeScope;
  subaccountHashid: string;
  onSuccessCallback?: () => void;
  isProtailUser?: boolean;
};

const ADD_GIACT_ROUTE = "/settings/bank/manual/add-ach";

const getDuplicateSubTitleCopy = (intl: IntlShape) => {
  return {
    [GiactModalState.DuplicateAccount]: intl.formatMessage({ defaultMessage: "Start trading now or make a deposit." }),
    [GiactModalState.DuplicateAccountAchEnabled]: intl.formatMessage({
      defaultMessage: "Now it is also eligible for ACH transfers. Start trading now or make a deposit.",
    }),
  };
};
export const GiactModal = ({
  isOpen,
  handleGiactClose,
  handleGiactSuccess,
  handleFallbackToWire,
  onBack,
  isSettingsOrOnboarding,
  scope,
  openSelectPaymentMethodModal,
  subaccountHashid,
  onSuccessCallback,
  isProtailUser,
}: GiactModalProps) => {
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const [modalState, setModalState] = useState(GiactModalState.Register);
  const { intl } = useIntl();

  const defaultValues = transferMethods.giact.schema.reduce((acc, item) => {
    acc[item] = "";
    return acc;
  }, {});
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm<BankDetailsFormType>({
    mode: "onBlur",
    defaultValues,
  });

  const handleBankBlocked = () => {
    setModalState(GiactModalState.BankBlock);
  };

  const onSubmit = async (values: BankDetailsFormType) => {
    try {
      const response = await axios.post(ADD_GIACT_ROUTE, values, {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      });
      if (response.status === 200) {
        // account exists, ach is now enabled
        setModalState(GiactModalState.DuplicateAccountAchEnabled);
      } else {
        // 201 new account was added
        track(EVENTS.FINISH_MANUAL_BANK_INFO_ENTRY_GIACT.name, {}, { braze: true });
        setModalState(GiactModalState.Success);
      }
    } catch (err) {
      const errorMessage = getError(err);
      const status = err?.response?.status;
      if (status === 409) {
        setModalState(GiactModalState.DuplicateAccount);
      } else if (status === 401) {
        trackPaymentRegistrationFailure(LinkPaymentType.GIACT, errorMessage);
        // block-listed error - displayed the same as wire does today
        handleBankBlocked();
        Sentry.captureMessage(
          `Giact: error adding bank account manually - account block-listed - ${errorMessage}`,
          Sentry.Severity.Error
        );
      } else {
        trackPaymentRegistrationFailure(LinkPaymentType.GIACT, errorMessage);
        Sentry.captureMessage(`Giact: error adding bank account manually - ${errorMessage}`, Sentry.Severity.Error);
        // Fast follow note: Eventually will want to render different error states based on response but initially will use a generic modal
        setModalState(GiactModalState.GiactError);
      }
    }
  };

  // close giact and populate wire modal with any form values
  const onFallbackToWire = () => {
    const { routingNumber: unused, ...values } = getValues();
    handleGiactClose();
    handleFallbackToWire({ ...values, currency: CURRENCIES_DETAIL.USD.symbol });
  };
  if (modalState === GiactModalState.GiactError) {
    return <GiactError isOpen={isOpen} onClose={onBack} handleFallbackToWire={onFallbackToWire} />;
  }
  if (modalState === GiactModalState.BankBlock) {
    return <BankBlockedModal isOpen={isOpen} onClose={handleGiactClose} />;
  }
  if (modalState === GiactModalState.Confirm) {
    return (
      <ConfirmBankDetailsModal
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        values={getValues()}
        onBack={() => setModalState(GiactModalState.Register)}
        onConfirmationSuccess={() => handleSubmit(onSubmit)()}
        onConfirmationClose={handleGiactClose}
      />
    );
  }
  if (modalState === GiactModalState.Register) {
    return (
      <GiactRegisterBankAccount
        onBack={onBack}
        control={control}
        register={register}
        isOpen={isOpen}
        errors={errors}
        trigger={trigger}
        handleGiactClose={handleGiactClose}
        handleFallbackToWire={onFallbackToWire}
        setModalState={setModalState}
      />
    );
  }
  if (modalState === GiactModalState.DuplicateAccount || modalState === GiactModalState.DuplicateAccountAchEnabled) {
    // refresh payment methods on close
    return (
      <DuplicateAccountModal onClose={handleGiactSuccess} subTitleText={getDuplicateSubTitleCopy(intl)[modalState]} />
    );
  }
  if (modalState === GiactModalState.Success || modalState === GiactModalState.DuplicateAccountAchEnabled) {
    // refresh payment methods on close
    return isNewAddPaymentsFlowEnabled ? (
      <AddPaymentMethodSuccessModal
        onClose={handleGiactSuccess}
        paymentMethodType={AddPaymentMethodType.GIACT}
        isOpen={isOpen}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
        onSuccessCallback={onSuccessCallback}
        isProtailUser={isProtailUser}
      />
    ) : (
      <PaymentMethodSuccessModal onClose={handleGiactSuccess} />
    );
  }
};
