import React, { Fragment } from "react";
import { useTheme } from "@emotion/react";
import Num from "@gemini-common/scripts/Num";
import { ListItem, Text } from "@gemini-ui/design-system";
import { FormattedRateTier } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/RateDetailsModal/RateDetailsList";
import { useRateDetailsFormatter } from "@gemini-ui/pages/Earn/hooks";
import { CurrencyOrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  quote: CurrencyOrderQuote;
}

export const ReviewRateTiers = ({ quote }: Props) => {
  const { colorScheme } = useTheme();
  const { intl } = useIntl();

  const { formattedInterestTiers, onlyHasOneRateTier, maxThreshold, showTieredRateDetails } = useRateDetailsFormatter(
    quote.annualInterestYieldTiers
  );

  const APY_TEXT = intl.formatMessage({ defaultMessage: "APR" });

  // Old behavior (used if the feature flag is off, or if there's only one rate tier)
  if (onlyHasOneRateTier || !showTieredRateDetails) {
    return (
      <ListItem
        padding="sm"
        size="dense"
        right={
          <Text.Body size="sm" data-testid={testIds.buySell.annualYield}>
            {Num.formatInterestRate(quote.annualPercentYield)}%
          </Text.Body>
        }
      >
        <Text.Body size="sm" color={colorScheme.content.secondary}>
          {APY_TEXT}
        </Text.Body>
      </ListItem>
    );
  }

  return (
    <Fragment>
      {formattedInterestTiers.map((_data, i) => (
        <ListItem
          key={i}
          padding="sm"
          size="dense"
          right={
            <Fragment>
              <Text.Body size="sm">{Num.formatInterestRate(_data.yield)}%</Text.Body>
              <Text.Body size="sm" color={colorScheme.content.secondary}>
                <FormattedRateTier
                  currency={quote.quantity.currency}
                  interestTier={_data}
                  maxThreshold={maxThreshold}
                />
              </Text.Body>
            </Fragment>
          }
        >
          <Text.Body size="sm" color={colorScheme.content.secondary}>
            {i === 0 ? APY_TEXT : null}
          </Text.Body>
        </ListItem>
      ))}
    </Fragment>
  );
};
