// These hexes come from color variables in this file web/src/main/assets/styles/colorVariables.js
import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface StyledMoneyProps {
  negative?: boolean;
  positive?: boolean;
}
const getColor = (p: StyledMoneyProps) => {
  if (p.negative) {
    return { color: "#CA3A3A" };
  } else if (p.positive) {
    return { color: "#11A642" };
  } else {
    return null;
  }
};
export const StyledMoney = styled("span")`
  font-feature-settings: "tnum" on, "lnum" on;
  svg {
    ${getColor};
    padding-right: 3px;
  }
`;

export const MoneyTail = styled.span``;

export const overlayStyles = css`
  .rc-tooltip--money {
    position: absolute;
    display: block;

    .rc-tooltip-inner {
      position: relative;
      border-radius: 5px;
      padding: 8px;
      background: #343536;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;
