import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { isGrowProviderType } from "@gemini-ui/constants/earn";

/**
 * TODO:
 * There should be a URL to routes mapper. This would allow us to get props from the URL,
 * but also make it easier to route to the correct location on "Back" button clicks.
 */

export const parseWindowLocationProps = () => {
  const { pathname } = window.location;
  const parts = pathname.split("/").filter(x => Boolean(x)); // sometimes there is an empty '', filter those out
  const propsParams = parts.slice(1); // i.e. /stake/ETH/validators/1234 will return ['ETH', 'validators', '1234']

  return {
    currency: propsParams?.[0]?.toLocaleUpperCase() as CurrencyShortNameSupportedCryptos,
    providerType: isGrowProviderType(propsParams?.[1]) ? propsParams[1] : undefined,
    validatorId: propsParams?.[2] ?? undefined,
  };
};

export const navigateToGrowLanding = () => {
  window.location.replace("/stake");
};
