/** @jsx jsx */
import React, { useCallback, useMemo } from "react";
import { jsx, useTheme } from "@emotion/react";
import { IconPlus } from "@hubble/icons";
// eslint-disable-next-line no-restricted-imports
import { FormikActions } from "formik";
import { CurrencyShortName, CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { MoneyProps } from "@gemini-ui/components/Money";
import { Flex, IconBadge, Select } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import CryptoIcon from "@gemini-ui/images/icons/cdn/CryptoIcon";
import { SourceDropdownItem } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/SourceDropdown/SourceDropdownItem";
import { styledSelectControl } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/SourceDropdown/styles";
import { PlaceDepositInfo, SourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

const SourceInputLabel = ({ label, disabled }: { label: string; disabled?: boolean }) => {
  return (
    <Flex
      justifyContent="space-between"
      data-testid={
        disabled
          ? testIds.deposit.placeDeposit.sourceDropdownLabelDisabled
          : testIds.deposit.placeDeposit.sourceDropdownLabelEnabled
      }
    >
      <span>{label}</span>
    </Flex>
  );
};

interface Props {
  source: SourceType;
  currency: CurrencyShortName;
  defaultFiat: CurrencyShortNameFiat;
  setFieldValue: FormikActions<PlaceDepositInfo>["setFieldValue"];
  error: string;
  availableForEarningInterest: MoneyProps;
  onChange?: (source: SourceType) => void;
}

const SourceDropdown = ({
  source,
  currency,
  defaultFiat,
  setFieldValue,
  error,
  availableForEarningInterest,
  onChange,
}: Props) => {
  const { intl } = useIntl();
  const theme = useTheme();

  const isTradingBalanceDisabled = availableForEarningInterest?.value === "0";

  const isBuyingGUSDWithNonUSDFiat = isCurrency.GUSD(currency) && !isCurrency.USD(defaultFiat);

  const sourceItems: Record<SourceType, SelectOptionProps> = useMemo(
    () => ({
      ...(!isTradingBalanceDisabled && {
        [SourceType.TRADING_BALANCE]: {
          value: SourceType.TRADING_BALANCE,
          label: intl.formatMessage({ defaultMessage: "Transfer trading balance" }),
          icon: <CryptoIcon symbol={currency} />,
          isDisabled: isTradingBalanceDisabled,
        },
      }),
      ...(!isBuyingGUSDWithNonUSDFiat && {
        [SourceType.BANK_OR_CARD]: {
          value: SourceType.BANK_OR_CARD,
          label: intl.formatMessage({ defaultMessage: "Buy new crypto" }),
          icon: <IconBadge icon={<IconPlus />} size="md" />,
          isDisabled: isBuyingGUSDWithNonUSDFiat,
        },
      }),

      // Add a fake source to fallback to if all deposit methods are disabled.
      ...(isBuyingGUSDWithNonUSDFiat &&
        isTradingBalanceDisabled && {
          [SourceType.BANK_OR_CARD]: {
            value: SourceType.BANK_OR_CARD,
            label: intl.formatMessage({ defaultMessage: "-" }),
            isDisabled: true,
          },
        }),
    }),
    [currency, isBuyingGUSDWithNonUSDFiat, isTradingBalanceDisabled, intl]
  );

  const transferOptions: SelectOptionProps[] = useMemo(() => Object.values(sourceItems), [sourceItems]);

  const isDropdownDisabled = useMemo(
    () => transferOptions.length === 1 || (isTradingBalanceDisabled && isBuyingGUSDWithNonUSDFiat),
    [transferOptions, isBuyingGUSDWithNonUSDFiat, isTradingBalanceDisabled]
  );

  const updateFormState = useCallback(
    _source => {
      // Don't do anything if the sources aren't changing
      // (otherwise we get infinite loops and crashes!)
      if (_source === source) return;

      setFieldValue("source", _source);
      if (Boolean(onChange)) onChange(_source);
    },
    [onChange, setFieldValue, source]
  );

  const sourceChangeHandler = useCallback(
    source => {
      setSelectedSourceItem(sourceItems[source]);
      updateFormState(source);
    },
    [updateFormState, sourceItems]
  );

  // Intercept the source passed from props so we can handle the scenario where the intended source type might be disabled.
  const initialSource = useMemo(() => {
    if (sourceItems[source]) return sourceItems[source];
    const fallbackSource = transferOptions[0];
    updateFormState(fallbackSource.value);
    return fallbackSource;
  }, [source, sourceItems, updateFormState, transferOptions]);

  const [selectedSourceItem, setSelectedSourceItem] = React.useState(initialSource);

  return (
    <Select
      data-testid={testIds.deposit.placeDeposit.sourceDropdown}
      label={
        <SourceInputLabel
          label={intl.formatMessage({ defaultMessage: "Transfer type", description: "Source of deposit funds" })}
          disabled={isDropdownDisabled}
        />
      }
      name="source"
      options={transferOptions}
      onChange={sourceChangeHandler}
      value={selectedSourceItem.value}
      disabled={isDropdownDisabled}
      error={error}
      message={
        isTradingBalanceDisabled &&
        isBuyingGUSDWithNonUSDFiat && (
          <HelpCenterLink article={Articles.TRANSFER_EARN}>
            {intl.formatMessage({ defaultMessage: "Learn about how to purchase GUSD and transfer to Earn." })}
          </HelpCenterLink>
        )
      }
      formatOptionLabel={SourceDropdownItem}
      customSelectStyles={styledSelectControl(theme)}
      isSearchable={false}
      size="lg"
    />
  );
};

export default SourceDropdown;
