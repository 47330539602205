/** @jsx jsx */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { jsx } from "@emotion/react";
import BigNumber from "bignumber.js";
// eslint-disable-next-line no-restricted-imports
import { Form, Formik, FormikActions } from "formik";
import { useEffectOnce } from "react-use";
import { bigNumberToDecimalString, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Money } from "@gemini-ui/components/Money";
import { TransferControlVariant } from "@gemini-ui/components/TransferControl/Alert";
import { GrowProviderRestriction, GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, Spacing, useToaster } from "@gemini-ui/design-system";
import { GrowBuyHeader } from "@gemini-ui/pages/Earn/components/GrowBuyHeader";
import { stakingAssetsWithDisclaimers } from "@gemini-ui/pages/Earn/Deposit/AssetDisclaimer/constants";
import { countryHasStakingTaxImplications } from "@gemini-ui/pages/Earn/Deposit/CountryTaxInformation/constants";
import { PooledStakingAmountInput } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PooledStakingAmountInput";
import { PrivateStakingInputs } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs";
import ProviderInput from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/ProviderInput";
import RecurringDropdowns from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/RecurringDropdowns";
import SourceDropdown from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/SourceDropdown";
import { TradingBalanceCard } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/TradingBalanceCard";
import { DepositModalView, PlaceDepositInfo, SourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import {
  balanceValueToCurrencyReceiptDecimals,
  calculateValue,
  getSource,
  schema,
} from "@gemini-ui/pages/Earn/Deposit/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { useGrowFeatureFlags, useGrowPageData } from "@gemini-ui/pages/Earn/hooks";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { ETH_PER_VALIDATOR } from "@gemini-ui/pages/Earn/utils";
import RetailAccountError from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/AccountError";
import { calculateValueIn } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { DepositAttestationAlert } from "@gemini-ui/pages/RetailTrade/Homepage/HomepageAlertsMessage/DepositAttestationAlert";
import { getAccountErrors } from "@gemini-ui/pages/RetailTrade/utils";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import axios from "@gemini-ui/services/axios";
import { useSinglePairDetails } from "@gemini-ui/services/trading/getSinglePairDetails";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export interface PlaceDepositProps {
  onSubmit?: () => void;
  requiresStakingAgreement?: boolean;
}

interface PlaceDepositFormState extends Partial<PlaceDepositInfo> {
  providerId: string;
}

const PlaceDeposit = ({ onSubmit, requiresStakingAgreement }: PlaceDepositProps) => {
  const { intl } = useIntl();
  const { showAlert } = useAlert();
  const { showToast } = useToaster();
  const {
    templateProps: { account },
  } = usePageData();

  const { isGrowSidebarModuleEnabled } = useGrowFeatureFlags();

  const { geminiEntity } = account;

  const accountErrors = getAccountErrors(intl);
  const isUkTravelRuleBlocked = getIsUkInboundEnabled(geminiEntity) && account?.hasUkDepositsAwaitingAttestation;

  const {
    asset,
    currency,
    provider,
    providerType,
    depositStatus: { amount, quote, error, schedule, recurringFrequency, source },
    updateDepositStatus,
    resetQuote,
  } = useGrowBuy();

  const lastTradePrice = useSinglePairDetails(currency + account.defaultFiat).data?.lastTradePrice;

  const { countryCode, defaultFiat, emailConfirmed } = useGrowPageData();

  const _isMounted = useRef(false);

  // Intercept the quote error so we can hide it when the form becomes dirty
  const [quoteError, setQuoteError] = useState(error);

  const isDepositBlocked =
    provider?.restriction === GrowProviderRestriction.BLOCK_DEPOSITS ||
    provider?.restriction === GrowProviderRestriction.BLOCK_ORDERS;

  const formState: PlaceDepositFormState = useMemo(
    () => ({
      source: source || getSource(asset, defaultFiat),
      schedule,
      recurringFrequency,
      amount: amount || "",
      providerId: provider?.id,
    }),
    [amount, asset, defaultFiat, provider?.id, recurringFrequency, schedule, source]
  );

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffectOnce(() => {
    track(EVENTS.VIEW_STAKE_AMOUNT_INPUT.name, {
      [EVENTS.VIEW_STAKE_AMOUNT_INPUT.properties.CURRENCY]: currency,
      [EVENTS.VIEW_STAKE_AMOUNT_INPUT.properties.PROVIDER_TYPE]: providerType,
    });
  });

  const handleSubmit = (formValues: PlaceDepositFormState, actions: FormikActions<PlaceDepositFormState>) => {
    // Fallback if there is no Provider ID
    if (!formValues.providerId) {
      showAlert({
        type: AlertTypes.ERROR,
        message: intl.formatMessage({
          defaultMessage: "Unable to process deposit at this time. Please try again later.",
        }),
      });

      actions.setSubmitting(false);
      return;
    }

    onSubmit?.();

    const nextView = requiresStakingAgreement
      ? DepositModalView.STAKING_AGREEMENT
      : countryHasStakingTaxImplications(countryCode)
      ? DepositModalView.TAX_INFORMATION
      : stakingAssetsWithDisclaimers.includes(currency)
      ? DepositModalView.ASSET_DISCLAIMER
      : formValues.source === SourceType.BANK_OR_CARD
      ? DepositModalView.REVIEW_BUY_DEPOSIT
      : DepositModalView.REVIEW_DEPOSIT;

    track(EVENTS.ENTER_STAKE_AMOUNT.name, {
      [EVENTS.ENTER_STAKE_AMOUNT.properties.CURRENCY]: currency,
      [EVENTS.ENTER_STAKE_AMOUNT.properties.PROVIDER_TYPE]: providerType,
      [EVENTS.ENTER_STAKE_AMOUNT.properties.AMOUNT]: amount,
      [EVENTS.ENTER_STAKE_AMOUNT.properties.FUNDING_SOURCE]: source,
      [EVENTS.ENTER_STAKE_AMOUNT.properties.NEXT_VIEW]: nextView,
    });

    updateDepositStatus({
      amount: formValues.amount,
      currency,
      source: formValues.source,
      schedule: formValues.schedule,
      recurringFrequency: formValues.recurringFrequency,
      view: nextView,
    });
  };

  const resendEmail = () => {
    axios
      .post(jsRoutes.controllers.register.ConfirmEmailController.resendEmail().url)
      .then(() => {
        showToast({
          message: intl.formatMessage({ defaultMessage: "Email sent successfully." }),
        });
      })
      .catch(() => {
        showAlert({
          type: AlertTypes.ERROR,
          message: intl.formatMessage({ defaultMessage: "Email not sent. Please try again." }),
        });
      });
  };

  const calculatedApproxValue = React.useCallback(
    (amount: string) =>
      calculateValueIn({
        value: amount,
        direction: "crypto",
        lastTradePrice,
        defaultFiat,
      }),
    [defaultFiat, lastTradePrice]
  );

  const [isPrivateStaking, isPooledStaking] = [
    providerType === GrowProviderType.PRIVATE_STAKING,
    providerType === GrowProviderType.POOLED_STAKING,
  ];

  const trackFundingSourceChange = (source: SourceType) => {
    track(EVENTS.SELECT_STAKING_DEPOSIT_SOURCE.name, {
      [EVENTS.SELECT_STAKING_DEPOSIT_SOURCE.properties.CURRENCY]: currency,
      [EVENTS.SELECT_STAKING_DEPOSIT_SOURCE.properties.PROVIDER_TYPE]: providerType,
      [EVENTS.SELECT_STAKING_DEPOSIT_SOURCE.properties.SOURCE]: source,
    });
  };

  const renderForm = ({ values: formValues, handleChange, setFieldValue, isSubmitting, touched, errors }) => {
    const depositApproxValue = calculateValue({
      value: formValues.amount,
      lastTradePrice,
    });

    const resetQuoteAndError = () => {
      if (quote) resetQuote();
      if (quoteError) setQuoteError(null);
    };

    const amountChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      resetQuoteAndError();
      setFieldValue("amount", String(e.target.value));
    };

    const isBuying = formValues.source === SourceType.BANK_OR_CARD;

    const showHelperText = () => {
      if (isCurrency.GUSD(currency) && !isCurrency.USD(defaultFiat)) return null;

      return intl.formatMessage(
        defineMessage({ defaultMessage: "{isGUSD, select, true {} other {approx. }}<Money></Money>" }),
        {
          isGUSD: isCurrency.GUSD(currency),
          Money: () =>
            !isBuying ? (
              <Money currency={defaultFiat} value={depositApproxValue} trailingSign={false} />
            ) : (
              <Money currency={currency} value={calculatedApproxValue(formValues.amount)} />
            ),
        }
      );
    };

    // If there isn't tier info, it likely means this asset or provider is in a bad state.
    const isSubmitDisabled =
      isSubmitting ||
      !emailConfirmed ||
      !formValues.source ||
      !Boolean(provider?.annualInterestYieldTiers) ||
      isDepositBlocked ||
      isUkTravelRuleBlocked;

    const isAmountInputDisabled = !isBuying && asset?.availableForEarningInterest.value === "0";

    const setAmountWithAvailableBalancePercent = (percent = 1) => {
      const sanitizedBalance = balanceValueToCurrencyReceiptDecimals(
        asset?.availableForEarningInterest?.value ?? 0,
        currency
      );
      const val = bigNumberToDecimalString(new BigNumber(Number(sanitizedBalance) * percent), currency);

      setFieldValue("amount", val);
      resetQuoteAndError();
    };

    const setAmountWithAvailableBalanceFiat = (value: number) => {
      const val = bigNumberToDecimalString(new BigNumber(value), currency);
      setFieldValue("amount", val);
      resetQuoteAndError();
    };

    return (
      <Form css={{ margin: 0 }}>
        <Flex flexDirection="column" gap={Spacing.scale[3]}>
          <ProviderInput value={formValues.providerId} setFieldValue={setFieldValue} handleChange={handleChange} />

          {isPooledStaking && (
            <React.Fragment>
              <SourceDropdown
                source={formValues.source}
                currency={currency}
                defaultFiat={defaultFiat}
                error={touched.source && errors.source}
                setFieldValue={setFieldValue}
                availableForEarningInterest={asset?.availableForEarningInterest}
                onChange={_source => {
                  // Reset the existing projection and request a new one
                  resetQuoteAndError();
                  setFieldValue("amount", "");
                  trackFundingSourceChange(_source);
                }}
              />

              {formValues.source === SourceType.TRADING_BALANCE && (
                <TradingBalanceCard
                  tradeBalance={asset?.availableForEarningInterest}
                  tradeBalanceNotional={asset?.availableForEarningInterestNotional}
                />
              )}

              {isBuying && (
                <RecurringDropdowns
                  initialSchedule={formValues.schedule}
                  initialRecurringFrequency={formValues.recurringFrequency}
                  setFieldValue={setFieldValue}
                  disabled={false}
                />
              )}
            </React.Fragment>
          )}

          {isPrivateStaking && (
            <PrivateStakingInputs
              setSource={_source => {
                setFieldValue("source", _source);
                resetQuoteAndError();
                trackFundingSourceChange(_source);
              }}
              setAmount={_amount => {
                setFieldValue("amount", _amount);
                resetQuoteAndError();
              }}
              source={formValues.source}
              validatorRatio={ETH_PER_VALIDATOR} // TODO: don't hardcode, what about MATIC?
              quoteError={quoteError}
            />
          )}

          {isPooledStaking && (
            <PooledStakingAmountInput
              isBuying={isBuying}
              isAmountInputDisabled={isAmountInputDisabled}
              quoteError={quoteError}
              values={formValues}
              touched={touched}
              errors={errors}
              setAmountWithAvailableBalanceFiat={setAmountWithAvailableBalanceFiat}
              setAmountWithAvailableBalancePercent={setAmountWithAvailableBalancePercent}
              amountChangeHandler={amountChangeHandler}
              showHelperText={showHelperText}
            />
          )}

          {!emailConfirmed && (
            <RetailAccountError
              errorCopy={accountErrors.emailUnconfirmed.error}
              linkText={accountErrors.emailUnconfirmed.buttonText}
              handleClick={resendEmail}
              earnStyle
            />
          )}

          {isUkTravelRuleBlocked && (
            <DepositAttestationAlert variantOverride={TransferControlVariant.Alert}>
              {intl.formatMessage({
                defaultMessage:
                  "For the UK Travel Rule, you must provide additional information for your crypto deposits before staking.",
              })}
            </DepositAttestationAlert>
          )}

          <Button.Group type="action" css={{ marginTop: 0 }}>
            <Button.Primary
              data-testid={testIds.deposit.placeDeposit.continueButton}
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitDisabled}
              size="md"
              cta={intl.formatMessage({ defaultMessage: "Review stake" })}
            />
          </Button.Group>
        </Flex>
      </Form>
    );
  };

  return (
    <React.Fragment>
      {!isGrowSidebarModuleEnabled && <GrowBuyHeader currency={currency} providerType={providerType} />}

      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        render={renderForm}
        validationSchema={schema({ assetInfo: asset, intl })}
      />
    </React.Fragment>
  );
};

export default PlaceDeposit;
