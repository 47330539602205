import uuidv4 from "uuid/v4";
import { mockLog } from "@gemini-ui/analytics/mockLog";

const mockTrack = eventType => (eventName, eventAttrs) => {
  mockLog(eventType, eventName, eventAttrs);
};
const mockProperty = propertyType => eventAttrs => {
  mockLog(propertyType, "", eventAttrs);
};

export const mixpanelMock = {
  init: (token, config) => null,
  alias: id => null,
  identify: id => null,
  opt_out_tracking: () => null,
  reset: () => null,
  people: {
    set: mockProperty("People Property"),
    set_once: mockProperty("People Property (Set Once)"),
    increment: mockProperty("People Property (Increment)"),
  },
  track: mockTrack("Event"),
  register: mockProperty("Super Property"),
  get_distinct_id: () => uuidv4(),
  get_property: mockProperty("Super Property"),
  track_links: mockTrack("Event"),
};
