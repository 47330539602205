import { IntlShape } from "react-intl";
import { TransferIds } from "@gemini-ui/components/Transfer/navigation/transferItems";

// action user is trying to take that will be blocked when pending
export enum VerificationPendingActionType {
  Stake = "Stake",
  SendCrypto = "SendCrypto",
  ReceiveCrypto = "ReceiveCrypto",
  DepositCash = "DepositCash",
  WithdrawCash = "WithdrawCash",
  AddPayment = "AddPayment",
}

// translating each sentence individually so the translator can factor in the entire sentence.
export const getActionCopy = (intl: IntlShape, actionType: VerificationPendingActionType) => {
  if (actionType === VerificationPendingActionType.Stake) {
    return intl.formatMessage({
      defaultMessage: "You’ll be able to stake crypto once your identity is verified and account setup is complete.",
    });
  } else if (actionType === VerificationPendingActionType.SendCrypto) {
    return intl.formatMessage({
      defaultMessage: "You’ll be able to send crypto once your identity is verified and account setup is complete.",
    });
  } else if (actionType === VerificationPendingActionType.ReceiveCrypto) {
    return intl.formatMessage({
      defaultMessage: "You’ll be able to receive crypto once your identity is verified and account setup is complete.",
    });
  } else if (actionType === VerificationPendingActionType.DepositCash) {
    return intl.formatMessage({
      defaultMessage: "You’ll be able to deposit cash once your identity is verified and account setup is complete.",
    });
  } else if (actionType === VerificationPendingActionType.WithdrawCash) {
    return intl.formatMessage({
      defaultMessage: "You’ll be able to withdraw cash once your identity is verified and account setup is complete.",
    });
  } else if (actionType === VerificationPendingActionType.AddPayment) {
    return intl.formatMessage({
      defaultMessage:
        "You’ll be able to add a payment method once your identity is verified and account setup is complete.",
    });
  }
};

// quick actions and nav menu
export const getTransferActionType = (id: TransferIds) => {
  if (id === "deposit-cash") {
    return VerificationPendingActionType.DepositCash;
  } else if (id === "deposit-crypto") {
    return VerificationPendingActionType.ReceiveCrypto;
  } else if (id === "withdraw-cash") {
    return VerificationPendingActionType.WithdrawCash;
  } else if (id === "withdraw-crypto") {
    return VerificationPendingActionType.SendCrypto;
  }
};
