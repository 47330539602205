import { AxiosError, AxiosResponse } from "axios";
import { CreditCardParams } from "@gemini-ui/pages/register/Verify/types";
import axios from "@gemini-ui/services/axios";

export const onfidoTokenUrl = "/register/onfido-key";

interface OnfidoConfigResponse {
  token: string;
  applicantId: string;
}

export const getOnfidoSdkToken = (creditCardParams?: CreditCardParams, existingApplicant?: boolean) => {
  return axios
    .get(onfidoTokenUrl, {
      withCredentials: true,
      params: { ...creditCardParams, existingApplicant: existingApplicant },
    })
    .then((response: AxiosResponse<OnfidoConfigResponse>) => {
      const { data } = response;
      return data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};
