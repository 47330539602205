import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";

export type SiteLockout =
  | LOCKOUT_TYPES.CONSENT_WITHDRAWALS_ONLY
  | LOCKOUT_TYPES.DEPOSIT_AND_CRYPTO_SALES_ONLY
  | LOCKOUT_TYPES.DIEM_OPERATING_AREA
  | LOCKOUT_TYPES.FIAT_WITHDRAWALS_ONLY
  | LOCKOUT_TYPES.FROZEN
  | LOCKOUT_TYPES.GUSD_OPERATING_AREA
  | LOCKOUT_TYPES.LOOKING
  | LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS
  | LOCKOUT_TYPES.PENDING
  | LOCKOUT_TYPES.SANDBOX
  | LOCKOUT_TYPES.SANDBOX_WITH_WALLET
  | LOCKOUT_TYPES.SCHEDULED_FRAUD_CLOSURE
  | LOCKOUT_TYPES.UNVERIFIED
  | LOCKOUT_TYPES.WIRE_DEPOSIT_FOR_UNFREEZE
  | LOCKOUT_TYPES.WIRE_DEPOSIT_FOR_VERIFICATION
  | LOCKOUT_TYPES.WITHDRAWALS_ONLY
  | LOCKOUT_TYPES.ATTESTATION_REQUIRED;

// TODO: This may not be an issue yet, but lockouts may have to take into consideration the types of buys and sells for crypto (market, limit, etc).
export enum LockoutTransactionAction {
  CryptoAccountTransfer,
  CryptoBuy,
  CryptoDeposit,
  CryptoPeerToPeer,
  CryptoSell,
  CryptoWithdrawal,
  ManageApprovedAddresses,
  FiatAccountTransfer,
  FiatAchDeposit,
  FiatPeerToPeer,
  FiatWireDeposit,
  FiatWithdrawal,
}

export interface LockoutTransactionOptions {
  action?: LockoutTransactionAction;
  authorized?: boolean;
  forceLockout?: boolean;
  lockout: SiteLockout | null;
  sandbox?: boolean;
}

/** Determines if a transaction is allowed based on a lockout and the action being taken */
export function lockoutTransactionAllowed({
  action,
  authorized,
  forceLockout,
  lockout,
  sandbox,
}: LockoutTransactionOptions) {
  if (!forceLockout && authorized) {
    if (!lockout) {
      return true;
    }
    const scenarios = {
      // Allows everything
      [LOCKOUT_TYPES.SANDBOX]: sandbox,
      // Allows everything
      [LOCKOUT_TYPES.SANDBOX_WITH_WALLET]: sandbox,
      // pending does not allow fiat wire deposits, or fiat/crypto withdrawals
      [LOCKOUT_TYPES.PENDING]: [
        LockoutTransactionAction.FiatWireDeposit,
        LockoutTransactionAction.CryptoWithdrawal,
        LockoutTransactionAction.FiatWithdrawal,
      ].includes(action),
      // withdrawalsOnly allows any kind of withdrawal
      [LOCKOUT_TYPES.WITHDRAWALS_ONLY]: [
        LockoutTransactionAction.CryptoWithdrawal,
        LockoutTransactionAction.FiatWithdrawal,
        LockoutTransactionAction.ManageApprovedAddresses,
      ].includes(action),
      // consentWithdrawalsOnly allows any kind of withdrawal
      [LOCKOUT_TYPES.CONSENT_WITHDRAWALS_ONLY]: [
        LockoutTransactionAction.CryptoWithdrawal,
        LockoutTransactionAction.FiatWithdrawal,
        LockoutTransactionAction.ManageApprovedAddresses,
      ].includes(action),
      // fiatWithdrawalsOnly allows fiat withdrawals and crypto sell
      [LOCKOUT_TYPES.FIAT_WITHDRAWALS_ONLY]: [
        LockoutTransactionAction.FiatWithdrawal,
        LockoutTransactionAction.CryptoSell,
      ].includes(action),
      // scheduledFraudClosure allows fiat withdrawals and crypto sell
      [LOCKOUT_TYPES.SCHEDULED_FRAUD_CLOSURE]: [
        LockoutTransactionAction.FiatWithdrawal,
        LockoutTransactionAction.CryptoSell,
      ].includes(action),
      // depositAndCryptoSalesOnly allows fiat wire deposits, fiat/crypto deposits, crypto sell, transfers between accounts
      [LOCKOUT_TYPES.DEPOSIT_AND_CRYPTO_SALES_ONLY]: [
        LockoutTransactionAction.CryptoAccountTransfer,
        LockoutTransactionAction.CryptoDeposit,
        LockoutTransactionAction.CryptoSell,
        LockoutTransactionAction.FiatAccountTransfer,
        LockoutTransactionAction.FiatAchDeposit,
        LockoutTransactionAction.FiatWireDeposit,
      ].includes(action),
      // wireDepositForVerification allows only fiat wire deposits
      [LOCKOUT_TYPES.WIRE_DEPOSIT_FOR_VERIFICATION]: action === LockoutTransactionAction.FiatWireDeposit,
      // wireDepositForUnfreeze allows only fiat wire deposits
      [LOCKOUT_TYPES.WIRE_DEPOSIT_FOR_UNFREEZE]: action === LockoutTransactionAction.FiatWireDeposit,
    };
    // Some lockouts do not have specific conditional checks and simply block any action which is why we return false if no match
    return scenarios[lockout] || false;
  }
  return false;
}
