import React from "react";
import { useTheme } from "@emotion/react";
import { IconBadge } from "@hubble/web";
import { FileIconProps, IconInfo } from "@gemini-ui/design-system/FileUploader/constants";
import { DEFAULT_ICON_INFO, ICONS_INFO } from "@gemini-ui/design-system/FileUploader/utils";

export const FileIcon = ({ extension }: FileIconProps) => {
  const theme = useTheme();
  const getIconInfo = (): IconInfo => {
    const iconInfo = ICONS_INFO({ theme }).find(({ extensions }) => extensions.includes(extension.toLowerCase()));
    return iconInfo || DEFAULT_ICON_INFO({ theme });
  };

  const { color, icon } = getIconInfo();
  return <IconBadge backgroundColor={color} size="lg" icon={icon} />;
};
