export enum FeatureFlag {
  AddDebitCards = "AddDebitCards",
  AddIntermediaryEnabled = "AddIntermediaryEnabled",
  AdvancedMarketsPageEnabled = "AdvancedMarketsPageEnabled",
  BcoloEnabled = "BcoloEnabled",
  Card3DSEnabled = "Card3DSEnabled",
  EuUkApplicationsEnabled = "EuUkApplicationsEnabled",
  EuropeAgreementsEnabled = "EuropeAgreementsEnabled",
  GeminiCreditCardEmailVerificationEnabled = "GeminiCreditCardEmailVerificationEnabled",
  GeminiCreditCardEnabled = "GeminiCreditCardEnabled",
  GeoRestrictBankRegistration = "GeoRestrictBankRegistration",
  HomePageWebFirstTimeGreetingEnabled = "HomePageWebFirstTimeGreetingEnabled",
  InternationalDebitCardEnabled = "InternationalDebitCardEnabled",
  IrelandAgreementsEnabled = "IrelandAgreementsEnabled",
  MarginEnabled = "MarginEnabled",
  MonetaryAuthorityOfSingaporeWithdrawalModalEnabled = "MonetaryAuthorityOfSingaporeWithdrawalModalEnabled",
  PaymentMethodSelectorEnabled = "PaymentMethodSelectorEnabled",
  RecurringOrderViaDebitCard = "RecurringOrderViaDebitCard",
  SardineDeviceIntelligenceEnabled = "SardineDeviceIntelligenceEnabled",
  SettlementEnabled = "SettlementEnabled",
  SettlementTicketCreationUI = "SettlementTicketCreationUI",
  SettlementSettings = "SettlementSettings",
  SettlementInstitutionalOnly = "SettlementInstitutionalOnly",
  SgdWireEnabled = "SgdWireEnabled",
  SingpassMyInfoEnabled = "SingpassMyInfoEnabled",
  TradePerpEnabled = "TradePerpEnabled",
  TravelRuleWithdrawalApprovedAddressAttestationEnabled = "TravelRuleWithdrawalApprovedAddressAttestationEnabled",
  XfersEnabled = "XfersEnabled",
  LimitPriceBound = "LimitPriceBound",
  PerpsUiEnabled = "PerpsUiEnabled",
  LeaderboardEnabled = "LeaderboardEnabled",
  LeaderboardOptInEnabled = "LeaderboardOptInEnabled",
  GUSDRegulatoryMessagingEnabled = "GUSDRegulatoryMessagingEnabled",
  EarnMasterClaimVotingEnabled = "EarnMasterClaimVotingEnabled",
  EarnFinalDistributionSummaryEnabled = "EarnFinalDistributionSummaryEnabled",
  SandboxWalletEnabled = "SandboxWalletEnabled",
  DebitCardFlowRevampEnabled = "DebitCardFlowRevampEnabled",
  WebGoogleAddressAutocompleteEnabled = "WebGoogleAddressAutocompleteEnabled",
  isPerpsEligible = "isPerpsEligible",
}

export type FeatureFlags = Record<keyof typeof FeatureFlag, boolean>;

export type SignedOutFeatureFlags = {
  [FeatureFlag.EuropeAgreementsEnabled]: boolean;
  [FeatureFlag.IrelandAgreementsEnabled]: boolean;
  [FeatureFlag.GeminiCreditCardEmailVerificationEnabled]: boolean;
  [FeatureFlag.GeminiCreditCardEnabled]: boolean;
  [FeatureFlag.GUSDRegulatoryMessagingEnabled]: boolean;
};
