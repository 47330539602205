import React from "react";
import { Theme } from "@emotion/react";
import { IconCardFilled } from "@hubble/icons";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Button, Colors, Feedback, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { ModalIconContainer } from "@gemini-ui/pages/settings/BankSettings/debit/styles";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios from "@gemini-ui/services/axios";
import { defineMessage, IntlShape, withIntl } from "@gemini-ui/utils/intl";
import { withTheme } from "@gemini-ui/utils/withTheme";

interface Props {
  onToggle: ToggleDebitCardModal;
  uuid?: string;
  tooManyAttempts?: boolean;
  maxMicroDeposit: MoneyProps<CurrencyShortNameFiat>;
  minMicroDeposit: MoneyProps<CurrencyShortNameFiat>;
  requestRefresh: (callback?: () => any | null) => any;
  isExistingCard: boolean;
  intl: IntlShape;
  theme?: Theme;
  openDebitCardErrorModal?: (debitCardErrorHeading: string, debitCardErrorMessage?: string) => void;
}
class VerifyYourCardModal extends React.Component<Props> {
  static contextType = AlertContext;
  declare context: React.ContextType<typeof AlertContext>;

  state = {
    submittingMicroDeposits: false,
  };
  isNewDebitCardFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DEBIT_CARD_FLOW_REVAMP);
  handleNextButton = () => {
    const { uuid, tooManyAttempts, onToggle, openDebitCardErrorModal } = this.props;
    track(EVENTS.START_DEBIT_CARD_VERIFICATION.name);
    this.setState({ submittingMicroDeposits: true }, () => {
      axios
        .post(jsRoutes.controllers.cards.CardsController.postMicroDeposit(uuid, tooManyAttempts).url)
        .then(res => {
          track(EVENTS.CARD_VERIFIED_SUCCESSFULLY.name);
          onToggle("transactionsSentVisible")();
        })
        .catch(err => {
          if (err.response.data.errorType && err.response.data.errorType.includes("AlreadyExists")) {
            onToggle("transactionsSentVisible")();
            if (this.isNewDebitCardFlowEnabled) {
              openDebitCardErrorModal(err.response.data.error);
            } else {
              this.context.showAlert({
                type: AlertTypes.WARNING,
                timeout: 5000,
                message: err.response.data.error,
              });
            }
          } else {
            if (this.isNewDebitCardFlowEnabled) {
              openDebitCardErrorModal("We were unable to verify your card.  Please double check your information.");
            } else {
              this.context.showAlert({
                type: AlertTypes.ERROR,
                timeout: 5000,
                message: "We were unable to verify your card.  Please double check your information.",
              });
            }
          }
          const UNKNOWN_ERROR = "Unknown Error";
          trackPaymentRegistrationFailure(LinkPaymentType.DEBIT, err?.response?.data?.errorType || UNKNOWN_ERROR);
        })
        .finally(() => {
          this.setState({ submittingMicroDeposits: false });
        });
    });
  };

  handleCancel = () => {
    const { isExistingCard, requestRefresh, onToggle } = this.props;

    track(EVENTS.SKIP_MICRO_DEPOSIT.name);
    onToggle("verifyCardStepOneVisible", true)();
    requestRefresh();

    if (!isExistingCard) {
      this.context.showAlert({
        type: AlertTypes.INFO,
        timeout: 5000,
        message: "Debit card added. Please verify card to use for crypto purchases.",
      });
    }
  };

  render() {
    const { onToggle, tooManyAttempts, maxMicroDeposit, minMicroDeposit, intl, requestRefresh, theme } = this.props;
    const { submittingMicroDeposits } = this.state;

    return (
      <Modal.MultiStep
        isOpen
        onClose={() => {
          onToggle("verifyCardStepOneVisible", true)();
          requestRefresh();
        }}
      >
        <ModalIconContainer justify="center" mb={2.5}>
          <IconCardFilled />
        </ModalIconContainer>
        <Text.Heading size="md" center mb={2.5}>
          {tooManyAttempts
            ? intl.formatMessage({
                defaultMessage: "Too many attempts",
              })
            : intl.formatMessage({
                defaultMessage: "Verify your card",
              })}
        </Text.Heading>
        <Spacer mb={2.5}>
          {tooManyAttempts ? (
            <Text.Body size="sm">
              {intl.formatMessage({
                defaultMessage:
                  "In order to verify your card we will re-send charges to your account. Previous charge amounts will be reversed and are no longer valid for verification.",
              })}
            </Text.Body>
          ) : (
            <Text.Body size="sm">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "<b>Step 1:</b> Gemini will send you 2 or 3 small, reversible transactions.",
                }),
                {
                  b: (v: React.ReactNode) => <b>{v}</b>,
                }
              )}
            </Text.Body>
          )}
        </Spacer>
        <Spacer mt={2} mb={2.5}>
          {tooManyAttempts ? (
            <Text.Body size="sm">
              {intl.formatMessage({
                defaultMessage: "Once your card is verified, we will reverse the new charges.",
              })}
            </Text.Body>
          ) : minMicroDeposit && maxMicroDeposit ? (
            <Text.Body size="sm">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "<b>Step 2:</b> Verify the amounts of the 2 transactions that are between <MoneyMinMicroDeposit></MoneyMinMicroDeposit> and <MoneyMaxMicroDeposit></MoneyMaxMicroDeposit>.",
                }),
                {
                  b: (v: React.ReactNode) => <b>{v}</b>,
                  MoneyMinMicroDeposit: () => <Money {...minMicroDeposit} />,
                  MoneyMaxMicroDeposit: () => <Money {...maxMicroDeposit} />,
                }
              )}
            </Text.Body>
          ) : (
            <Text.Body size="sm">
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "<b>Step 2:</b> Verify the amounts of the 2 transactions.",
                }),
                {
                  b: (v: React.ReactNode) => <b>{v}</b>,
                }
              )}
            </Text.Body>
          )}
          <Feedback
            style={{
              color: theme.isDark && Colors.white,
              backgroundColor: theme.isDark && Colors.black,
            }}
            status="info"
            mt={2.5}
          >
            <Feedback.Body>
              {intl.formatMessage({
                defaultMessage: "Micro-transactions are removed from your bank account within 14 days.",
              })}
            </Feedback.Body>
          </Feedback>
        </Spacer>

        <Button.Group type="stacked">
          <Button.Primary
            loading={submittingMicroDeposits}
            onClick={this.handleNextButton}
            data-testid="verify-card-modal-step-1-btn"
            type="submit"
            size="lg"
            cta={intl.formatMessage({
              defaultMessage: "Send transactions",
            })}
          />
          <Button.Tertiary size="lg" onClick={this.handleCancel} data-testid="use-different-card-modal-step-1-btn">
            {intl.formatMessage({
              defaultMessage: "I'll do this later",
            })}
          </Button.Tertiary>
        </Button.Group>
      </Modal.MultiStep>
    );
  }
}

export default withIntl(withTheme(VerifyYourCardModal));
