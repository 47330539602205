import { useMemo } from "react";
import { usePageData } from "@gemini-ui/contexts";
import { parseWindowLocationProps } from "@gemini-ui/pages/Earn/GrowRoutes/utils";
import { GrowLandingPageProps } from "@gemini-ui/pages/Earn/types";

/**
 * This houses a lot of nice Grow `templateProps` and `pageProps` helpers
 *
 * This is the same as just using `usePageData<GrowLandingPageProps>();`
 *
 * It's nice to aggregate the type of `pageData` here - source, me, having spent hours cleaning up different types
 *
 * @example
 * const { defaultFiat } = useGrowPageData();
 */
export const useGrowPageData = () => {
  const { templateProps, pageProps } = usePageData<GrowLandingPageProps>();
  const { currency, ...windowLocationProps } = parseWindowLocationProps();

  const values = useMemo(
    () => ({
      window: {
        currency: currency,
        providerType: windowLocationProps?.providerType,
        validatorId: windowLocationProps?.validatorId,
      },

      templateProps,
      pageProps,

      account: templateProps.account,
      countryCode: templateProps.user.countryCode,
      defaultFiat: templateProps.account.defaultFiat,
      emailConfirmed: templateProps.user.emailConfirmed,
      supportedFiat: templateProps.account.supportedFiat,

      // TODO: This should be renamed
      unfulfilledEarnRedeems: pageProps.summary?.unfulfilledEarnRedeems,
    }),
    [currency, windowLocationProps, pageProps, templateProps]
  );

  return values;
};
