import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";

export const useAchDisabledFlow = () => {
  const achDisabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_ACH_DISABLED);
  const { subaccountHashid, subaccounts } = usePageData().templateProps.user;

  if (!achDisabled) {
    return false;
  }

  const selectedAccount = subaccounts.find(acct => acct.hashid === subaccountHashid);
  const accountCreatedAt = selectedAccount?.createdAt;
  const achCutoffDate = optimizelyClient.getFeatureVariableInteger(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ACH_DISABLED,
    "achCutoffDate"
  );
  const accountCreatedAfterCutoff = achCutoffDate < accountCreatedAt;

  return accountCreatedAfterCutoff;
};
