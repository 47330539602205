import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { BADGE_VARIANT, BadgeProps, BadgeVariantType } from "@gemini-ui/design-system/Badge/constants";
import { border, shorthandSpacingCss, Spacing } from "@gemini-ui/design-system/primitives";

const STATUS_THEME_MAP = {
  info: "informational",
  success: "positive",
  warning: "warning",
  alert: "negative",
  neutral: "neutral",
  reward: "reward",
};

function getBackgroundColor({ backgroundColor, status, variant, theme }: BadgeProps & { theme: Theme }) {
  if (variant === BADGE_VARIANT["no-border"]) return "transparent";

  if (backgroundColor) {
    return backgroundColor;
  }

  if (status) {
    if (variant === BADGE_VARIANT.counter) {
      return theme.colorScheme.status.bold.background[STATUS_THEME_MAP[status]];
    }

    if (variant === BADGE_VARIANT.pip) {
      return theme.colorScheme.status.default.content[STATUS_THEME_MAP[status]];
    }

    return theme.colorScheme.status.default.background[STATUS_THEME_MAP[status]];
  }
}

function getContentColor({ status, variant, theme }: BadgeProps & { theme: Theme }) {
  if (status) {
    if (variant === "counter") {
      return theme.colorScheme.status.bold.content[STATUS_THEME_MAP[status]];
    }

    return theme.colorScheme.status.default.content[STATUS_THEME_MAP[status]];
  }
}

function getPadding({ isLegacy, size, variant }: BadgeProps & { isLegacy?: boolean }) {
  if (isLegacy) return `${Spacing.scale[0.25]} ${Spacing.scale[1]}`;

  if (variant === "pip") return 0;

  if (variant === "counter")
    return size === "xs" ? `0 ${Spacing.scale[0.5]}` : `${Spacing.scale[0.25]} ${Spacing.scale[1]}`;

  return size === "sm" ? `${Spacing.scale[0.25]} ${Spacing.scale[1]}` : `${Spacing.scale[0.5]} ${Spacing.scale[1]}`;
}

function getBorderRadius(variant: BadgeVariantType) {
  if (variant === "counter" || variant === "pip") return border.radius.full;

  return border.radius.sm;
}

export const BaseBadge = styled.span<BadgeProps & { isLegacy?: boolean; isSingleCounterDigit?: boolean }>`
  align-items: center;
  background-color: ${p => getBackgroundColor(p)};
  border-radius: ${({ variant }) => getBorderRadius(variant)};
  display: inline-flex;
  justify-content: center;
  padding: ${p => getPadding(p)};

  ${({ bordered, theme }) => bordered && `border: 2px solid ${theme.colorScheme.background.primary};`}

  ${({ variant }) =>
    variant === "pip" &&
    `
    padding: ${Spacing.scale[0.5]};
  `}

  ${({ variant }) => variant === "raised" && `box-shadow: 0px 0px 5px 1px rgba(16, 16, 26, 0.06);`}

  ${shorthandSpacingCss} 
  
  ${({ size, variant }) => variant === "counter" && size === "xs" && `min-width: 16px;`}
  ${({ isSingleCounterDigit, size, variant }) =>
    variant === "counter" && size !== "xs" && isSingleCounterDigit && `max-width: 20px;`}

  strong {
    color: ${p => getContentColor(p)};

    ${({ variant }) => variant === "counter" && `font-feature-settings: "tnum" on, "lnum" on;`}

    ${({ size, variant }) => variant === "counter" && size === "xs" && `font-size: 9px;`}
  }

  svg {
    fill: ${p => getContentColor(p)};
    height: ${Spacing.scale[2]};
    width: ${Spacing.scale[2]};
  }
`;
