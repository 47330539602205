import { InputProps } from "@gemini-ui/design-system/forms/Input/constants";

export interface PasswordInputProps extends InputProps {
  initialType?: "password" | "text";
  onToggleType?: (type: PasswordInputProps["initialType"]) => void;
}

export const INPUT_TYPES = {
  password: "password",
  text: "text",
} as const;
