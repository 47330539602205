import React from "react";
import { IconClockOutlined } from "@hubble/icons";
import { addBusinessDays, format } from "date-fns";
import { Money } from "@gemini-ui/components/Money";
import { Button, Flex, Text, Timeline } from "@gemini-ui/design-system";
import { PlaceRedeemInfo } from "@gemini-ui/pages/Earn/Redeem/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props extends Pick<PlaceRedeemInfo, "provider" | "redemptionStatus"> {
  onDone?: () => void;
}

export const PartialSuccess = ({ provider, redemptionStatus, onDone }: Props) => {
  const { intl } = useIntl();

  const isPartialRedeemed = !redemptionStatus.redeemedInFull && Number(redemptionStatus.amountRedeemed.value) > 0;

  const { initiatedDate, availableFundsDate } = React.useMemo(
    () => ({
      initiatedDate: format(new Date(), DateFormats.MonthAbbrevDayYear),
      // TODO: This won't always be business days, see ticket for BE updates
      // https://gemini-spaceship.atlassian.net/browse/GEM-49072?focusedCommentId=498543
      availableFundsDate: format(
        addBusinessDays(new Date(), provider.maxWithdrawDelay),
        DateFormats.MonthAbbrevDayYear
      ),
    }),
    [provider.maxWithdrawDelay]
  );

  const title = (
    <React.Fragment>
      <Flex justify="center" mb={1}>
        <IconClockOutlined size="xl" />
      </Flex>
      <Text.Heading size="md" data-testid={testIds.redeem.partialSuccess.title}>
        {isPartialRedeemed
          ? intl.formatMessage(
              defineMessage({
                defaultMessage: "<Money></Money> is available now. The rest is pending.",
              }),
              { Money: () => <Money {...redemptionStatus.amountRedeemed} /> }
            )
          : intl.formatMessage({
              defaultMessage: "Your unstake is pending.",
            })}
      </Text.Heading>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {title}
      <Timeline
        data-testid="partial-success-timeline"
        mt={3}
        mb={3}
        steps={[
          {
            label: intl.formatMessage({ defaultMessage: "Initiated" }),
            sublabel: initiatedDate,
          },
          {
            label: intl.formatMessage(
              defineMessage({
                defaultMessage: "{isPartialRedeemed, select, true {Partial unstake} other {Pending}}",
              }),
              { isPartialRedeemed }
            ),
            sublabel: intl.formatMessage({ defaultMessage: "Gemini requested funds from network." }),
            metadata: initiatedDate,
            isCurrentStep: true,
          },
          {
            label: intl.formatMessage({ defaultMessage: "Complete" }),
            sublabel: intl.formatMessage({ defaultMessage: "All funds arrive in your trading balance." }),
            metadata: intl.formatMessage(
              defineMessage({
                defaultMessage: "Estimated release date {availableFundsDate}",
              }),
              { availableFundsDate }
            ),
          },
        ]}
      />

      <Text.Legal>
        {intl.formatMessage({
          defaultMessage:
            "We will notify you when the transfer is complete. You can view the unstaking status in your Portfolio.",
        })}
      </Text.Legal>

      <Button.Group type="action">
        <Button.Tertiary
          size="md"
          data-testid={testIds.redeem.success.portfolioButton}
          onClick={() => window.location.assign(jsRoutes.controllers.dashboard.DashboardController.portfolio().url)}
          cta={intl.formatMessage({ defaultMessage: "Go to Portfolio" })}
        />
        <Button.Primary
          size="md"
          data-testid={testIds.redeem.success.doneButton}
          onClick={() => {
            onDone?.();
          }}
          cta={intl.formatMessage({ defaultMessage: "Done" })}
        />
      </Button.Group>
    </React.Fragment>
  );
};
