import styled from "@emotion/styled";
import { SHORTHAND_SPACER_MAP, SpacerOption } from "@gemini-ui/design-system/primitives/Spacer/constants";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";

/**
 * Adds shorthand spacing to any styled component
 *
 * EXAMPLE 1
 * Spacing.scale[4] margin:
 *   <Button.Primary m={4} ... />
 *
 * EXAMPLE 2
 * Spacing.component.lg padding-top:
 *  <Text.Body pt="lg" />
 */
export const shorthandSpacingCss = props =>
  Object.keys(props).map(
    prop => SHORTHAND_SPACER_MAP[prop] && `${SHORTHAND_SPACER_MAP[prop]}: ${Spacing.scale[props[prop]]};`
  );

/**
 * Provides two ways of filtering shorthand CSS props
 * By default, it reduces props object to just the SHORTHAND_SPACER_MAP props.
 * By passing a second boolean argument to the function via the shouldRemoveShorthand parameter
 * you can remove the CSS props instead.
 *
 * Useful for selectively passing props down to a <Spacer> component
 *
 * EXAMPLE
 *
 * function Widget(props) {
 *   return <Spacer {...cssShorthandPropsFilter(props)}>
 *     <WidgetWrapper />
 * }
 *
 * Or for keeping unwanted props out of a child element
 *
 * EXAMPLE
 *
 * function Widget(props) {
 *   return <WidgetWrapper>
 *     <WidgetChild {...cssShorthandPropsFilter(props, true)} />
 * }
 *
 */

export function cssShorthandPropsFilter<T extends Pick<T, keyof ShorthandSpacingCssProps>, B extends boolean = false>(
  props: T,
  shouldRemoveShorthand?: B
) {
  const filteredProps: Partial<T> = {};

  Object.keys(props).forEach(prop => {
    if (shouldRemoveShorthand ? !Boolean(SHORTHAND_SPACER_MAP[prop]) : Boolean(SHORTHAND_SPACER_MAP[prop])) {
      filteredProps[prop] = props[prop];
    }
  });

  return filteredProps as B extends true
    ? Omit<T, keyof ShorthandSpacingCssProps>
    : Pick<T, keyof ShorthandSpacingCssProps>;
}

export type ShorthandSpacingCssProps = { [key in keyof typeof SHORTHAND_SPACER_MAP]?: SpacerOption };

export type SpacerProps = ShorthandSpacingCssProps & {
  children?: React.ReactNode;
  as?: React.ElementType;
};

export const Spacer = styled("div")<SpacerProps>`
  ${shorthandSpacingCss}
`;
