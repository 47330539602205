import { IntlShape } from "@gemini-ui/utils/intl";

export const WORLDPAY_NETWORK_ERROR_MSG =
  "Something went wrong. Unable to complete this action at this time. Please try again.";

export const getCopy = (intl: IntlShape) => {
  return {
    noPaymentMethod: intl.formatMessage({
      defaultMessage: "You haven’t added a payment method",
    }),
    noPaymentMethodDescription: intl.formatMessage({
      defaultMessage: "Please add an eligible payment method.",
    }),
    transferFundMsg: intl.formatMessage({
      defaultMessage: "You can also transfer funds from another account.",
    }),
    addDebitCard: intl.formatMessage({
      defaultMessage: "Add debit card",
    }),
    debitCardExists: intl.formatMessage({
      defaultMessage:
        "There are eligible Debit Cards available in your Spot accounts. Toggle your account to view those debit cards",
    }),
  };
};
