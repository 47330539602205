import axios from "@gemini-ui/services/axios";
import {
  CompetitionInfo,
  CompetitionType,
  FeatureElectionResponse,
  HistoryInfo,
  Leaderboard,
  LeaderboardAccountRankStandingResponse,
  LeaderboardDateResponse,
  RankResponse,
} from "@gemini-ui/utils/constants";

export const getCompetitionsInfo = () =>
  axios.get<CompetitionInfo[]>(jsRoutes.controllers.leaderboard.LeaderboardController.getCompetitions().url);

export const getAccountRanks = (type: CompetitionType, date = null) =>
  axios.get<LeaderboardAccountRankStandingResponse[]>(
    jsRoutes.controllers.leaderboard.LeaderboardController.getRanksV1(date, type).url
  );

export const getAccountRanksV2 = (type: CompetitionType, date = null) =>
  axios.get<RankResponse>(jsRoutes.controllers.leaderboard.LeaderboardController.getRanksV2(date, type).url);

export const getFeatureElection = () =>
  axios.get<FeatureElectionResponse>(jsRoutes.controllers.social.FeatureElectionController.listFeatures().url, {
    withCredentials: true,
  });

export const optInAllCompetitions = () =>
  axios.post(jsRoutes.controllers.social.FeatureElectionController.optInAll().url);

export const optOutAllCompetitions = () =>
  axios.post(jsRoutes.controllers.social.FeatureElectionController.optOutAll().url);

export const getTopRank = (type: CompetitionType, standingLimit = 5, date = null, dateTime = null) =>
  axios.get<Leaderboard>(
    jsRoutes.controllers.leaderboard.LeaderboardController.getDailyLeaderboard(date, dateTime, type, standingLimit).url
  );

export const getHistoryInfo = () =>
  axios.get<HistoryInfo>(jsRoutes.controllers.leaderboard.LeaderboardController.getRanksHistory().url);

export const getDates = (type: CompetitionType) =>
  axios.get<LeaderboardDateResponse[]>(
    jsRoutes.controllers.leaderboard.LeaderboardController.getDailyLeaderboardList(type).url
  );

export const uploadAvatarImage = formData =>
  axios.post(jsRoutes.controllers.social.SocialProfileController.uploadAvatarImage().url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
