import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";

const getGraphQLUrl = () => {
  if (window.origin.includes("exchange.gemini.com")) {
    return "https://exchange.gemini.com/graphql";
  } else if (window.origin.includes("sandbox")) {
    return "https://exchange.sandbox.gemini.com/graphql";
  } else {
    return "https://exchange.qa100.aurora7.net/graphql";
  }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      const { message, path } = error;
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      Sentry.captureException(error);
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
  }
});

export const httpLink = new HttpLink({
  uri: getGraphQLUrl(),
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  assumeImmutableResults: false,
  link: from([errorLink, httpLink]),
  cache,
  name: "web-client",
  version: "1.0.0",
  credentials: window.location.host.includes("creditcard.") ? "include" : "same-origin",
});
