import React, { InvalidEvent } from "react";
import { CurrencyDetail } from "@gemini-common/scripts/constants/currencies";
import { Spacing } from "@gemini-ui/design-system";

const CDN_PATH = `https://www.gemini.com/images/currencies/icons`;
const FALLBACK_SYMBOL = "usd";

type ImageType = "svg" | "png";

type SizeModifier = "@3x" | "";

type ImageVariant = "default";

type CryptoIconSize = "sm" | "md" | "lg" | "default";

export interface Props {
  symbol: CurrencyDetail["symbol"];
  variant?: ImageVariant;
  imageType?: ImageType;
  sizeModifier?: SizeModifier;
  className?: string;
  size?: CryptoIconSize;
}

const WIDTH_SIZE_MAP = {
  sm: Spacing.scale[3],
  md: Spacing.scale[4],
  lg: Spacing.scale[5],
  default: undefined,
};

const errorHandler = (src: string) => (e: InvalidEvent<HTMLImageElement>) => {
  e.target.onerror = null;
  e.target.src = src;
};

const CryptoIcon = (props: Props) => {
  const { symbol, className, variant = "circle", imageType = "svg", sizeModifier = "", size = "default" } = props;
  const getSrc = (s: string) => `${CDN_PATH}/${variant}/${s}${sizeModifier}.${imageType}`;
  const src = getSrc(symbol);
  const fallbackSrc = getSrc(FALLBACK_SYMBOL);

  return (
    <img
      src={src}
      className={className}
      alt={symbol}
      onError={errorHandler(fallbackSrc)}
      width={WIDTH_SIZE_MAP[size]}
    />
  );
};

export default CryptoIcon;
