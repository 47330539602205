import * as React from "react";
import { SYSTEM_ALERT_VARIANTS, SystemAlertVariant } from "@gemini-ui/design-system/SystemAlert/constants";

export const GENERIC_FORM_ERROR_MESSAGE = "Error submitting. Please correct the fields below and try again.";

export const AlertTypes = {
  ERROR: "negative",
  WARNING: "warning",
  INFO: "informational",
} as const;

export type AlertType = (typeof AlertTypes)[keyof typeof AlertTypes];
export interface Alert {
  message: string | React.ReactNode;
  type: AlertType;
  timeout?: number;
  id?: string;
}

export const alertTypeToVariantMap: { [key in AlertType]: SystemAlertVariant } = {
  negative: SYSTEM_ALERT_VARIANTS.negative,
  warning: SYSTEM_ALERT_VARIANTS.warning,
  informational: SYSTEM_ALERT_VARIANTS.informational,
};

export interface GlobalAlertTypesProps {
  green?: React.ReactNode;
  red?: React.ReactNode;
  yellow?: React.ReactNode;
}

type GenericFormError = {
  "": string[];
};

export interface GenericFormErrorAlert {
  formErrors: GenericFormError;
  message: string;
}

export interface AlertContext {
  alert?: Alert;
  showAlert(alert: Alert): void;
  hideAlert(e?: React.MouseEvent<HTMLButtonElement>): void;
  showGenericFormErrorAlert(props: GenericFormErrorAlert): void;
}

export interface AlertProps {
  alert: Alert;
  hideAlert?: AlertContext["hideAlert"];
}

export const PAGE_PROP_ALERT_TIMEOUT = 5000;
