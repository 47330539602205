import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import queryString from "query-string";
import * as Yup from "yup";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { EVENTS, track } from "@gemini-ui/analytics";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { commonPws } from "@gemini-ui/components/Form/utils/passwordValidation";
import { GeminiEntities, GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { FormValues } from "@gemini-ui/pages/register/Register/components/RegisterForm/types";
import { UserLocation } from "@gemini-ui/pages/register/Register/constants";
import { PhoneFormState } from "@gemini-ui/pages/register/Register/registerState";
import axios from "@gemini-ui/services/axios";
import { USStateAbbreviation } from "@gemini-ui/US_States";
import { isCountryColombia } from "@gemini-ui/utils/countries";
import { IntlShape } from "@gemini-ui/utils/intl";

type CountryStatus = "country supported" | "country not supported";
type StateStatus = "state supported" | "state not supported";
export type LocationStatus = (CountryStatus | null) | StateStatus;

export const GeminiAgreementsEntities = {
  TRUST: "Trust",
  IRELAND_ENTITY: "IrelandEntity",
  IRELAND: "Ireland",
  EUROPE: "Europe",
} as const;
export type GeminiAgreementsEntity = (typeof GeminiAgreementsEntities)[keyof typeof GeminiAgreementsEntities];

interface TrackChangeLocation {
  locationStatus: LocationStatus;
  isOptIn: boolean;
  guessedLocation: (CountryAbbreviation | null) | "";
  changedLocation: (CountryAbbreviation | null) | "";
  state: (USStateAbbreviation | null) | "";
}

export const trackChangeLocation =
  ({ locationStatus, isOptIn, guessedLocation, changedLocation, state }: TrackChangeLocation) =>
  () => {
    const { LOCATION_STATUS, OPT_IN, GUESSED_LOCATION, CHANGED_LOCATION, STATE } = EVENTS.CHANGE_LOCATION.properties;
    track(EVENTS.CHANGE_LOCATION.name, {
      [`${LOCATION_STATUS}`]: locationStatus,
      [`${OPT_IN}`]: isOptIn,
      [`${GUESSED_LOCATION}`]: guessedLocation,
      [`${CHANGED_LOCATION}`]: changedLocation,
      [`${STATE}`]: state,
    });
  };

export const optInSchema = (intl: IntlShape) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ defaultMessage: "Please enter a valid email address, like user@gmail.com." }))
      .required(intl.formatMessage({ defaultMessage: "Please enter a valid email address, like user@gmail.com." })),
  });
};

export const passwordSchema = (intl: IntlShape) => {
  // rely on dynamic requirements for min and char classes (no msg)
  return Yup.string()
    .required(intl.formatMessage({ defaultMessage: "Please enter a valid password." }))
    .test(
      "is-uncommon",
      intl.formatMessage({
        defaultMessage: "The password you entered is a commonly-used password. Please enter a different password.",
      }),
      (val: string) => val && !(val.toLowerCase() in commonPws)
    )
    .max(72, intl.formatMessage({ defaultMessage: "Your password must be 72 characters or less." }))
    .trim(intl.formatMessage({ defaultMessage: "Your password must not start or end with a space." }))
    .strict(true);
};
const colombiaNationalIdSchema = (intl: IntlShape) => {
  return Yup.number()
    .test(
      "min-length",
      intl.formatMessage({ defaultMessage: "Please enter a valid national ID number, between 6-12 digits." }),
      val => val && val.toString().length >= 6
    )
    .test(
      "max-length",
      intl.formatMessage({ defaultMessage: "Please enter a valid national ID number, between 6-12 digits." }),
      val => val && val.toString().length <= 12
    )
    .required(intl.formatMessage({ defaultMessage: "Please enter a valid national ID number." }));
};

export const registerSchema = (
  entity: GeminiEntity,
  intl: IntlShape,
  derivativeAccountNeeded: boolean,
  institutionalDerivativeAccountNeeded: boolean
) => {
  const isDigitAssetSas = entity === GeminiEntities.GeminiDigitalAssetsSAS;
  const isGeminiPayments = entity === GeminiEntities.GeminiPayments;
  return Yup.object().shape({
    firstName: Yup.string()
      .required(intl.formatMessage({ defaultMessage: "Enter a first name." }))
      .min(2, intl.formatMessage({ defaultMessage: "First name must be at least 2 characters." }))
      .max(40, intl.formatMessage({ defaultMessage: "First name cannot be more than 40 characters." })),
    middleName: Yup.string(),
    lastName: Yup.string()
      .required(intl.formatMessage({ defaultMessage: "Enter a last name." }))
      .min(2, intl.formatMessage({ defaultMessage: "Last name must be at least 2 characters." }))
      .max(40, intl.formatMessage({ defaultMessage: "Last name cannot be more than 40 characters." })),
    email: Yup.string()
      .email(intl.formatMessage({ defaultMessage: "Please enter a valid email address, like user@gmail.com." }))
      .required(intl.formatMessage({ defaultMessage: "Please enter a valid email address, like user@gmail.com." }))
      .max(254, intl.formatMessage({ defaultMessage: "Email cannot be more than 254 characters." })),
    promoCode: Yup.string()
      .min(1, intl.formatMessage({ defaultMessage: "Promo code must be at least 1 character" }))
      .max(20, intl.formatMessage({ defaultMessage: "Promo code must be less than 20 characters." })),
    password: passwordSchema(intl),
    tos: isGeminiPayments ? Yup.bool().strip(true) : Yup.bool().oneOf([true]),
    europeUserAgreement: Yup.bool().strip(true),
    colombiaNationalId: isDigitAssetSas ? colombiaNationalIdSchema(intl) : Yup.number().strip(true),
    locationAgreement: getPerpsSchema(institutionalDerivativeAccountNeeded, derivativeAccountNeeded),
    truthAgreement: getPerpsSchema(institutionalDerivativeAccountNeeded, derivativeAccountNeeded),
    perpsConsent: getPerpsSchema(institutionalDerivativeAccountNeeded, derivativeAccountNeeded),
  });
};

const getPerpsSchema = (isInstitutional: boolean, isPerpsEligible: boolean): Yup.BooleanSchema => {
  return isPerpsEligible
    ? isInstitutional
      ? Yup.bool().when("createPerpsAccount", {
          is: true,
          then: Yup.bool().oneOf([true]),
          otherwise: Yup.bool().strip(true),
        })
      : Yup.bool().oneOf([true])
    : Yup.bool().strip(true);
};

export const objectToDropdownArray = (obj: Record<string, string>): Array<{ value: string; label: string }> => {
  const keys = Object.keys(obj);
  return keys.map(key => ({ value: key, label: obj[key] }));
};

export const findObject = <T,>(array: Array<{ value: T; label: string }>, value: T) =>
  array.find(val => val.value === value);

const promoValidate = jsRoutes.controllers.register.StreamlinedOnboardingRegistrationController.postPromoCode().url;
export const validatePromoCode = async (countryCode: string, promoCode?: string) => {
  if (promoCode) {
    try {
      const response = await axios.post(promoValidate, { promoCode, countryCode });
      return response.status === 204;
    } catch (e) {
      Sentry.captureMessage(
        `Validating Promo Code Request Failed - country: ${countryCode} -- code: ${promoCode}`,
        Sentry.Severity.Warning
      );
      return false;
    }
  } else {
    return true;
  }
};

export const getNewUserEndpointPayload = (
  values: FormValues,
  userLocation: UserLocation,
  phoneState: PhoneFormState,
  csrfToken: string,
  recaptchaV2: string,
  recaptchaV3: string,
  isPerpsEligible: boolean
) => {
  const query = queryString.parse(window.location.search);
  const clientId = query.clientId;
  const affiliateClickId = query["~click_id"];
  const partnerCode = query.partnerCode;
  const cjEvent = query.cjevent;
  const MIXPANEL_UNIQUE_ID = mixpanelClient.getMixpanelId();
  const {
    tos,
    referral,
    europeServicesAgreement,
    marketingOptIn,
    europeUserAgreement,
    createPerpsAccount: unusedcreatePerpsAccount,
    ...baseFormValues
  } = values;
  const newUserConsent = {
    termsOfService: userLocation.country === "us" || tos,
    europeUserAgreement,
    europeServicesAgreement: europeUserAgreement || europeServicesAgreement,
    marketingOptIn,
  };

  return {
    ...baseFormValues,
    ...(phoneState.phone && {
      phoneNumber: {
        number: phoneState.phone,
        intlPrefix: phoneState.intlPrefix,
      },
    }),
    newUserConsent,
    csrfToken,
    "validationIds.g-recaptcha-response-v2": recaptchaV2,
    "validationIds.g-recaptcha-response-v3": recaptchaV3,
    partnerCode,
    location: { countryCode: userLocation.country, state: userLocation.state },
    promoCode: isCountryColombia(userLocation.country) ? "" : referral,
    clientId,
    ...(affiliateClickId && { affiliateClickId }),
    cjEventId: cjEvent,
    analyticsId: MIXPANEL_UNIQUE_ID,
    ...(isPerpsEligible ? { createDerivative: true } : {}),
  };
};

export const getBusinessEndpointPayload = (
  values: FormValues,
  userLocation: UserLocation,
  phoneState: PhoneFormState,
  recaptchaV2: string,
  recaptchaV3: string,
  isPerpsEligible: boolean
) => {
  const { ...baseFormValues } = values;

  const payload: BusinessPayload = {
    firstName: baseFormValues.firstName,
    middleName: baseFormValues.middleName ? baseFormValues.middleName : "",
    lastName: baseFormValues.lastName,
    email: baseFormValues.email,
    password: baseFormValues.password,
    jurisdiction: { country: userLocation.country, state: userLocation.state },
    validationIds: {
      "g-recaptcha-response-v2": recaptchaV2,
      "g-recaptcha-response-v3": recaptchaV3,
    },
    phoneNumber: {
      number: phoneState.phone,
      intlPrefix: phoneState.intlPrefix,
    },
    ...(Boolean(values.createPerpsAccount) && isPerpsEligible ? { createDerivative: true } : {}),
  };

  return payload;
};

interface BusinessPayload {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  password: string;
  jurisdiction: { country: string; state: string };
  validationIds: {
    "g-recaptcha-response-v2": string;
    "g-recaptcha-response-v3": string;
  };
  phoneNumber?: {
    number: string;
    intlPrefix: number;
  };
  createDerivative?: boolean;
}

export const useGetIsPerpEligibility = (isInstitutionalAccount: boolean, countryCode: string, state?: string) => {
  const [isPerpsEligible, setIsPerpsEligible] = useState(false);

  useEffect(() => {
    const getIsPerpsEligible = async () => {
      try {
        const { data } = await axios.get(
          jsRoutes.controllers.register.GeminiEntityController.getIsPerpEligible(
            countryCode,
            state,
            isInstitutionalAccount
          ).url
        );
        setIsPerpsEligible(data.isPerpEligible);
      } catch (error) {
        Sentry.captureMessage("Error fetching perp eligibility");
      }
    };

    getIsPerpsEligible();
  }, [countryCode, state, isInstitutionalAccount]);

  return { isPerpsEligible };
};
