import React from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { Card, Colors, Flex, ListItem, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

export const TradingBalanceCard = ({
  tradeBalance,
  tradeBalanceNotional,
}: {
  tradeBalance: MoneyProps;
  tradeBalanceNotional: MoneyProps<CurrencyShortNameFiat>;
}) => {
  const { intl } = useIntl();
  return (
    <Card variant="filled" padding="none">
      <ListItem
        padding="sm"
        alignItems="center"
        hasDivider={false}
        right={
          <Flex flexDirection="column" align="end">
            <Text.Body size="sm" bold>
              <Money {...tradeBalance} />
            </Text.Body>
            <Text.Body size="xs" color={Colors.gray[600]}>
              {intl.formatMessage(
                { defaultMessage: "approx. <Money></Money>" },
                {
                  Money: () => <Money {...tradeBalanceNotional} trailingSign={false} />,
                }
              )}
            </Text.Body>
          </Flex>
        }
      >
        <Text.Body size="sm" bold>
          {intl.formatMessage({ defaultMessage: "Trade balance" })}
        </Text.Body>
      </ListItem>
    </Card>
  );
};
