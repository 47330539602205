import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { LinkPaymentType } from "@gemini-ui/components/ConfirmBankDetailsModal/constants";
import { BankDetails, EditButton } from "@gemini-ui/components/ConfirmBankDetailsModal/styles";
import { StyledForm } from "@gemini-ui/components/StyledForm";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankDetailsFormType } from "@gemini-ui/constants/giact";
import { usePageData } from "@gemini-ui/contexts";
import {
  Button,
  Card,
  Checkbox,
  Colors,
  List,
  ListItem,
  Modal,
  SectionMessage,
  Spacer,
  Text,
} from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";
import { getFieldData } from "@gemini-ui/utils/wireFunding";

type ConfirmBankDetailsModalProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  values: Partial<BankDetailsFormType>;
  onConfirmationSuccess: () => void;
  onBack: () => void;
  onConfirmationClose: () => void;
  title?: string;
  type?: string;
  currency?: CurrencyShortNameFiat;
};
export const ConfirmBankDetailsModal = ({
  isOpen,
  isSubmitting,
  values,
  onConfirmationSuccess,
  onBack,
  onConfirmationClose,
  title,
  type,
  currency,
}: ConfirmBankDetailsModalProps) => {
  const { intl } = useIntl();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const {
    templateProps: {
      user: { fullName, isInstitutional, countryCode },
    },
  } = usePageData();

  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const isBankFrickFlowEnabled =
    optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BANK_FRICK_ENABLED) && isCurrency.USD(currency);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (isNewAddPaymentsFlowEnabled || isBankFrickFlowEnabled) {
      const ACCOUNT_TYPE = {
        name: "",
        properties: {
          TYPE: "Type",
        },
      };
      if (type === LinkPaymentType.WIRE) {
        ACCOUNT_TYPE.name = EVENTS.WIRE_BANK_ACCOUNT_TYPE.name;
      } else {
        ACCOUNT_TYPE.name = EVENTS.GIACT_BANK_ACCOUNT_TYPE.name;
      }
      track(ACCOUNT_TYPE.name, { [ACCOUNT_TYPE.properties.TYPE]: isInstitutional ? "institutional" : "retail" });
    }
    e.preventDefault();
    onConfirmationSuccess();
  };

  const isUsUser = countryCode === "us";
  const theme = useTheme();
  const colorScheme = theme.colorScheme;
  const isWire = type === LinkPaymentType.WIRE;

  return isNewAddPaymentsFlowEnabled || isBankFrickFlowEnabled ? (
    <Modal.MultiStep
      isOpen={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Confirm bank account details",
      })}
      onClose={onConfirmationClose}
    >
      <EditButton size="sm" onClick={onBack}>
        {intl.formatMessage({ defaultMessage: "Edit" })}
      </EditButton>

      <StyledForm onSubmit={handleSubmit}>
        {!isWire ? (
          <Text.Body size="sm" mt={1} mb={3}>
            {intl.formatMessage({
              defaultMessage: "Please ensure the details here exactly match your bank account information.",
            })}
          </Text.Body>
        ) : null}
        {type === LinkPaymentType.WIRE ? (
          <SectionMessage
            mb={2}
            heading={intl.formatMessage({
              defaultMessage: "Please make sure the following is correct",
            })}
            statusType="warning"
          >
            <Text.Body size="sm">
              {intl.formatMessage({
                defaultMessage: "Otherwise deposits may be delayed or returned at your expense.",
              })}
            </Text.Body>
          </SectionMessage>
        ) : null}
        <Card variant="filled" mt={2} padding="none">
          <List>
            <ListItem
              key="nameOnAccount"
              left={
                <Text.Body size="sm" color={colorScheme.content.secondary}>
                  {intl.formatMessage({
                    defaultMessage: "Account holder",
                  })}
                </Text.Body>
              }
              right={<Text.Body size="sm">{values["nameOnAccount"] || fullName}</Text.Body>}
              padding="sm"
            ></ListItem>

            {Object.keys(values).map(fieldName => {
              const field = getFieldData(intl, isUsUser)[fieldName];
              if (fieldName === "nameOnAccount" || !values[fieldName]) {
                return null;
              }

              return (
                <ListItem
                  key={fieldName}
                  left={
                    <Text.Body size="sm" color={Colors.gray[600]}>
                      {field.label}
                    </Text.Body>
                  }
                  right={<Text.Body size="sm">{values[fieldName]}</Text.Body>}
                  padding="sm"
                ></ListItem>
              );
            })}
          </List>
        </Card>
        <Button.Group>
          <Button.Primary type="submit" loading={isSubmitting} data-testid="confirm-bank-details-submit">
            {intl.formatMessage({
              defaultMessage: "Submit",
            })}
          </Button.Primary>
        </Button.Group>
      </StyledForm>
    </Modal.MultiStep>
  ) : (
    <Modal.MultiStep
      isOpen={isOpen}
      title={
        title ||
        intl.formatMessage({
          defaultMessage: "Confirm bank account details",
        })
      }
      onBack={onBack}
      onClose={onConfirmationClose}
    >
      <form onSubmit={handleSubmit}>
        <Text.Body size="sm" mt={1} mb={3}>
          {intl.formatMessage({
            defaultMessage: "Please ensure the details entered here exactly match your bank account information.",
          })}
        </Text.Body>
        {Boolean(values["nameOnAccount"]) && (
          <BankDetails mb={3}>
            <Text.Body size="sm" mr={2}>
              {intl.formatMessage({
                defaultMessage: "Account holder's name",
              })}
            </Text.Body>
            <Text.Body>{values["nameOnAccount"]}</Text.Body>
          </BankDetails>
        )}
        {Object.keys(values).map(fieldName => {
          const field = getFieldData(intl, isUsUser)[fieldName];
          if (fieldName === "nameOnAccount" || !values[fieldName]) {
            return null;
          }
          return (
            <BankDetails mb={3} key={fieldName}>
              <Text.Body size="sm" mr={2}>
                {field.label}
              </Text.Body>
              <Text.Body>{values[fieldName]}</Text.Body>
            </BankDetails>
          );
        })}
        <Spacer mt={4}>
          <Checkbox
            name="hasConfirmedDetails"
            data-testid="has-confirmed-bank-details-checkbox"
            onChange={() => setIsConfirmed(v => !v)}
            checked={isConfirmed}
          >
            {intl.formatMessage({
              defaultMessage: "I have ensured that the details entered here exactly match my bank account.",
            })}
          </Checkbox>
        </Spacer>
        <Button.Group>
          <Button.Tertiary data-testid="confirm-bank-details-cancel" onClick={onConfirmationClose}>
            {intl.formatMessage({
              defaultMessage: "Cancel",
            })}
          </Button.Tertiary>
          <Button.Primary
            type="submit"
            loading={isSubmitting}
            disabled={!isConfirmed || isSubmitting}
            data-testid="confirm-bank-details-submit"
          >
            {intl.formatMessage({
              defaultMessage: "Confirm",
            })}
          </Button.Primary>
        </Button.Group>
      </form>
    </Modal.MultiStep>
  );
};
