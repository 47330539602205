import { isNaN } from "lodash";
import { GrowAsset } from "@gemini-ui/constants/earn";

export const getMaxNumValidators = (
  assetInfo: GrowAsset,
  /**
   * How many of the currency are considered to make up a validator
   * @example `ETH` = 32
   */
  validatorRatio: number
) => {
  if (!assetInfo?.availableForEarningInterest?.value) return 0;

  const available = parseInt(`${assetInfo?.availableForEarningInterest?.value}`);

  if (!available || isNaN(available) || available === 0) return 0;

  return Math.max(Math.floor(available / validatorRatio), 0);
};
