import * as React from "react";
import { IconClearFilled, IconSearch, IconSize } from "@hubble/icons";
import { InputProps } from "@gemini-ui/design-system/forms/Input/constants";
import { ClearButton, StyledInput, Variant } from "@gemini-ui/design-system/SearchInput/styles";
import { clearIcon, searchIcon } from "@gemini-ui/design-system/SearchInput/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

export interface SearchInputProps extends Omit<InputProps, "name"> {
  name?: string;
  onClear?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: Variant;
}

const ICON_SIZE_MAP: Record<SearchInputProps["inputSize"], IconSize> = {
  sm: "xs",
  md: "sm",
  lg: "md",
};

export const SearchInput = ({
  hideLabel = true,
  disabled,
  label,
  name = "search",
  value = "",
  autoComplete = "off",
  inputSize = "md",
  variant = "filled",
  onClear,
  ...props
}: SearchInputProps) => {
  const { intl } = useIntl();
  return (
    <StyledInput
      disabled={disabled}
      hideLabel={hideLabel}
      inputSize={inputSize}
      label={label || intl.formatMessage({ defaultMessage: "Search" })}
      name={name}
      value={value}
      variant={variant}
      {...props}
      leftElement={<IconSearch data-testid={searchIcon} size={ICON_SIZE_MAP[inputSize]} />}
      rightElement={
        Boolean(value) && Boolean(onClear) ? (
          <ClearButton
            onClick={onClear}
            disabled={disabled}
            aria-label={intl.formatMessage({ defaultMessage: "Remove value" })}
          >
            <IconClearFilled data-testid={clearIcon} size={ICON_SIZE_MAP[inputSize]} />
          </ClearButton>
        ) : null
      }
    />
  );
};
