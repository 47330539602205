import * as React from "react";
import { Button, SectionMessage } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import axios from "@gemini-ui/services/axios";
import { IntlShape } from "@gemini-ui/utils/intl";

interface State {
  linkText: string;
  linkEnabled: boolean;
}

const resendEmailUrl = jsRoutes.controllers.register.ConfirmEmailController.resendEmail().url;

const DEFAULT_LABEL = "Resend confirmation email";

const ConfirmEmailLockoutV2 = ({ intl }: { intl: IntlShape }) => {
  const [state, setState] = React.useState<State>({
    linkText: DEFAULT_LABEL,
    linkEnabled: true,
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleResend = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post(resendEmailUrl)
      .then(() => {
        setIsLoading(false);
        setState({ linkText: "Email sent", linkEnabled: false });
        setTimeout(() => setState({ linkText: DEFAULT_LABEL, linkEnabled: true }), 5000);
      })
      .catch(() => {
        setIsLoading(false);
        setState({ linkText: DEFAULT_LABEL, linkEnabled: true });
      });
  };

  return (
    <SectionMessage
      heading={intl.formatMessage({
        defaultMessage: "Confirm your email",
      })}
      renderButton={
        <Button.Primary
          data-testid="resend-email-confirmation-button"
          disabled={!state.linkEnabled}
          onClick={handleResend}
        >
          {isLoading ? <SpinnerAnimation color="white" /> : state.linkText}
        </Button.Primary>
      }
      mt={1}
      mb={1}
      statusType="alert"
    >
      {intl.formatMessage({
        defaultMessage: "You must confirm your email before transferring funds",
      })}
    </SectionMessage>
  );
};

export default ConfirmEmailLockoutV2;
