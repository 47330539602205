import { add, Duration } from "date-fns";
import _ from "lodash";
import { AlertType, AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { NotificationType } from "@gemini-ui/components/Notifications/constants";

// This isn't the greatest right now, but at least encapsulates our main concerns
// Calls to localStorage will fail in Safari Private Browsing so we have to protect against that
// Modified based on https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability

export type Storage = "localStorage" | "sessionStorage";

interface FlashNotification {
  type: NotificationType;
  message: string;
  key: string;
}

export const NotificationTypeToAlertType: Record<NotificationType, AlertType> = {
  Red: AlertTypes.ERROR,
  Green: AlertTypes.INFO,
  Blue: AlertTypes.INFO,
  Yellow: AlertTypes.WARNING,
};

export const FLASH_STORAGE_KEY = "flash-notification";

let storageIsSupported: boolean | undefined;

function isStorageSupported(type: Storage) {
  if (storageIsSupported) return true;

  const testKey = "test";
  let storage;

  try {
    storage = window[type];
    storage.setItem(testKey, "1");
    storage.removeItem(testKey);

    storageIsSupported = true;
  } catch (e) {
    storageIsSupported =
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === "QuotaExceededError" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      storage &&
      storage.length !== 0;
  } finally {
    return storageIsSupported;
  }
}

const storageSupported = (type: Storage) => isStorageSupported(type);

const getItem = (item: string, type: Storage = "localStorage") => {
  let value;
  if (storageSupported(type)) {
    value = window[type].getItem(item);
  }
  return value;
};

const removeItem = (item: string, type: Storage = "localStorage") => {
  storageSupported(type) ? window[type].removeItem(item) : true;
};

const setItem = (item: string, value: string | number | boolean, type: Storage = "localStorage") =>
  storageSupported(type) ? window[type].setItem(item, value.toString()) : true;

let subaccountHashidTempCache: string | undefined;

const formatSubaccountItem = (item: string) => {
  if (!subaccountHashidTempCache) {
    subaccountHashidTempCache = getItem("subaccountHashid");
  }
  return `${subaccountHashidTempCache}-${item}`;
};

const getItemForSubaccount = (item: string, type: Storage = "localStorage") => {
  return getItem(formatSubaccountItem(item), type);
};

const setItemForSubaccount = (item: string, value: string | number | boolean, type: Storage = "localStorage") =>
  setItem(formatSubaccountItem(item), value, type);

const removeItemForSubaccount = (item: string, type: Storage = "localStorage") => {
  return removeItem(formatSubaccountItem(item), type);
};

export const setFlashNotification = (type: string, message: string) => {
  return setItem(FLASH_STORAGE_KEY, JSON.stringify({ type, message, key: _.uniqueId(FLASH_STORAGE_KEY) }));
};

export const getFlashNotification = (): FlashNotification | null => {
  const flashData = getItem(FLASH_STORAGE_KEY);
  let flash = null;
  if (flashData) {
    try {
      flash = JSON.parse(flashData);
    } catch (e) {}
    removeItem(FLASH_STORAGE_KEY);
  }

  return flash;
};

export const setItemWithExperiationDate = (key: string, expiresIn: number, unit: keyof Duration) => {
  const expirationDate = add(new Date(), { [unit]: expiresIn });
  const item = {
    expiresAt: expirationDate.toString(),
  };
  setItem(key, JSON.stringify(item));
};

export const isItemExpired = (key: string) => {
  const itemStr = getItem(key);
  if (!itemStr) {
    return true;
  }
  const item = JSON.parse(itemStr);
  if (new Date() > new Date(item.expiresAt)) {
    removeItem(key);
    return true;
  }
  return false;
};

export default {
  get: getItem,
  set: setItem,
  setFlashNotification,
  getFlashNotification,
  remove: removeItem,
  getItemForSubaccount,
  setItemForSubaccount,
  removeItemForSubaccount,
  isItemExpired,
  setItemWithExperiationDate,
};
