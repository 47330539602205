import React from "react";
import { CurrencyShortNameFiat, orderCurrencies } from "@gemini-common/scripts/constants/currencies";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { handleChangeFiatSubmit } from "@gemini-ui/components/Header/utils";
import { HubbleMenu } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

interface FiatMenuProps {
  defaultFiat?: CurrencyShortNameFiat;
  supportedFiat: CurrencyShortNameFiat[];
}

export const FiatMenu = ({ supportedFiat, defaultFiat }: FiatMenuProps) => {
  const { intl } = useIntl();
  const { showAlert } = useAlert();
  return (
    <React.Fragment>
      <HubbleMenu.Title>
        {intl.formatMessage({
          defaultMessage: "Choose default currency",
        })}
      </HubbleMenu.Title>
      <HubbleMenu.Separator />
      <HubbleMenu.RadioGroup value={defaultFiat}>
        {orderCurrencies(supportedFiat, defaultFiat).map(item => (
          <HubbleMenu.RadioItem
            key={item}
            value={item}
            label={item}
            onSelect={handleChangeFiatSubmit(item, showAlert)}
          />
        ))}
      </HubbleMenu.RadioGroup>
    </React.Fragment>
  );
};
