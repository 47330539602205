import React from "react";
import { Button, Card, Flex, List, ListItem, SkeletonLoader, Spacing } from "@gemini-ui/design-system";

export const LoadingState = () => {
  return (
    <React.Fragment>
      <Flex flexDirection="column" gap={Spacing.scale[2]}>
        <Flex flexDirection="column" align="center" gap={Spacing.scale[1]} mb={2}>
          <SkeletonLoader width="50%" height={Spacing.scale[10]} radiusSize="sm" />
          <SkeletonLoader width="30%" height={Spacing.scale[2]} radiusSize="sm" />
        </Flex>

        <Card variant="filled" padding="none">
          <List>
            {new Array(5).map((row, idx) => {
              return (
                <ListItem
                  key={`${idx}-skeleton`}
                  size="dense"
                  padding="sm"
                  right={<SkeletonLoader width="50px" height={Spacing.scale[4]} radiusSize="sm" />}
                >
                  <SkeletonLoader width="200px" height={Spacing.scale[4]} radiusSize="sm" />
                </ListItem>
              );
            })}
          </List>
        </Card>
      </Flex>

      <Button.Group type="action">
        <SkeletonLoader width="100%" height={Spacing.scale[8]} radiusSize="full" />
      </Button.Group>
    </React.Fragment>
  );
};
