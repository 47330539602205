import { IntlShape } from "react-intl";
import { PerpetualPair } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { getFilledPercent, getFilledQuantity, isPartiallyFilled } from "@gemini-ui/components/StatusColumn/utils";
import { TransferType } from "@gemini-ui/constants/custody";
import { LimitOrder, MarketOrder, OpenPosition, Order, OrderUtils, StopLimitOrder } from "@gemini-ui/constants/orders";
import { PositionTransfer } from "@gemini-ui/constants/perpEvents";

export const isStopLimitOrder = (order: Order): order is StopLimitOrder =>
  (order as StopLimitOrder)?.stopPrice !== undefined;

export function getFilledDegrees(order: LimitOrder) {
  return (getFilledPercent(order) / 100) * 360;
}

export function getOrderTypeDisplay(order: Order | PositionTransfer, transactionTable?: boolean) {
  if ("positionTransfer" in order) {
    return "Transfer";
  } else if (order.type === "order") {
    if (OrderUtils.isRecurringOrder(order)) {
      return "Recurring";
    } else if (order.isBasket) {
      return "Cryptoverse";
    } else if (order.stopPrice || order.originalStopPrice) {
      return "Stop Limit";
    } else if (order.isInstant) {
      return transactionTable ? "Instant" : "One-Time";
    } else {
      return "Limit";
    }
  } else {
    return "Market";
  }
}

export function getStopOrderStatusDisplay(intl: IntlShape, order: StopLimitOrder, filterOpen: boolean) {
  if (isPartiallyFilled(order)) {
    return intl.formatMessage({
      defaultMessage: "Partially filled",
    });
  }

  return filterOpen && !order.isStopTriggered
    ? intl.formatMessage({
        defaultMessage: "Not triggered",
      })
    : intl.formatMessage({
        defaultMessage: "Triggered",
      });
}

export const getPrice = (d: Order): MoneyProps => {
  if (d.type === "order") {
    if (d.stopPrice) {
      return d.price;
    }
    const hasFills = getFilledQuantity(d) > 0;
    return hasFills ? d.avgPrice : d.price;
  } else {
    return d.avgPrice;
  }
};

export const getStopPrice = (d: Order): MoneyProps => {
  if (d.type === "order") {
    if (d.stopPrice) {
      return d.stopPrice;
    } else if (d.originalStopPrice) {
      return {
        value: d.originalStopPrice,
        currency: d.price.currency,
      };
    }
  }
};

export const getQuantity = (order: Order) => {
  return order?.type === "order" ? order.quantity : order?.originalQuantity;
};

export const getFilledQuantityMoney = (order: Order) => {
  return {
    currency: getQuantity(order)?.currency,
    value: getFilledQuantity(order).toString(),
  } as MoneyProps;
};

export const getTotal = (d: Order): MoneyProps => {
  const price = getPrice(d) ? getPrice(d) : getStopPrice(d);
  const quantity = getQuantity(d);
  return {
    currency: price.currency,
    value: String(Number(price.value) * Number(quantity.value)),
  };
};

export const getDate = (d: Order | PositionTransfer | TransferType): number => {
  let date;
  if ("positionTransfer" in d) {
    date = d.positionTransfer.timestamp;
  } else if ((d as Order)?.timestamp) {
    date = (d as Order).timestamp;
  } else if (d.type === "marketBuy" || d.type === "marketSell") {
    date = (d as MarketOrder).accepted;
  } else if ((d as LimitOrder)?.created) {
    date = (d as LimitOrder).created;
  }
  return date;
};

export const getPosition = (positions: OpenPosition[], symbol: PerpetualPair) => {
  return positions.find(position => position.pair === symbol);
};
