/*
  DEPRECATED
  This color palette is deprecated. Use design system colors instead.
  import { Colors } from "@gemini-ui/design-system";
*/

const colorVariables = {
  blue: {
    1: "#0d4b55",
    "1b": "#00dcfa", // drop down selction accent
    "1c": "#00e3ff", // calendar day selction
    2: "#12616d", // Primary Button Hover
    3: "#057586", // Primary Button, Link Hover Body Copy on White
    4: "#1190a3", // Link on white, body copy
    5: "#32adc0",
    6: "#63c7d6", // Field Focus
    7: "#9fe6f1",
    8: "#c9f6fd",
    9: "#e2fbff",
    10: "#effdff",
    11: "#1a1a27",
  },
};

export default colorVariables;
