/**
 * ### `toPercentFloat`
 * #### Returns the numerical value of a string or number divided by 100.
 * @example toPercentFloat("6.34") => 0.0634
 *
 * @param value - `string` | `number` | `bigint`
 * @returns number
 */
export function toPercentFloat(value: string | number | bigint) {
  const parsed = parseFloat(`${value}`);

  if (!Number.isNaN(parsed)) {
    return parsed / 100;
  } else {
    if (__DEV__) {
      throw new Error("toPercentFloat: `value` is invalid.");
    }
    return 0;
  }
}
/**
 * ### `formatPercentage`
 * #### Formats percentages according to Hubble guidelines.
 *
 * @param value - `string` | `number` | `bigint`
 * @param locale
 * @param {boolean} [parseToFloat=true] - A `boolean`. Parses value to percent float if `true`.
 * @returns string
 */
export function formatPercentage(
  value: string | number | bigint,
  locale: string,
  parseToFloat = true,
  isDelta?: boolean
) {
  try {
    return getPercentageNumberFormat(locale, isDelta).format(parseToFloat ? toPercentFloat(value) : Number(value));
  } catch (error) {
    return value?.toString();
  }
}

export function getPercentageNumberFormat(locale: string, isDelta?: boolean) {
  const PERCENT = "percent";
  const resolvedOptions = Intl.NumberFormat(locale).resolvedOptions();
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: PERCENT,
    ...(Boolean(resolvedOptions?.signDisplay) && { signDisplay: isDelta ? "exceptZero" : undefined }),
  });
}

export function getVolumeFormat(locale: string, currency?: string) {
  try {
    const NOTATION = "compact";
    return new Intl.NumberFormat(locale, {
      notation: NOTATION,
      currency: currency,
      minimumFractionDigits: 0,
    });
  } catch (e) {
    // fallback for Safari < 14.1 which doesn't support notation prop
    return new Intl.NumberFormat(locale, {
      currency: currency,
      minimumFractionDigits: 0,
    });
  }
}
