import React, { forwardRef } from "react";
import { IconCheckboxIndeterminate, IconCheckboxSelected, IconCheckboxUnselected } from "@hubble/icons";
import { BaseSwitch } from "@gemini-ui/design-system/forms/shared/components/BaseSwitch";
import { CheckboxOrRadioProps } from "@gemini-ui/design-system/forms/shared/constants";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxOrRadioProps>((props, ref) => (
  <BaseSwitch
    iconSelected={<IconCheckboxSelected />}
    iconIndeterminate={<IconCheckboxIndeterminate />}
    iconUnselected={<IconCheckboxUnselected />}
    inputType="checkbox"
    ref={ref}
    {...props}
  >
    {props.children}
  </BaseSwitch>
));
