import React, { JSXElementConstructor, ReactElement, useState } from "react";
import { optimizelyClient } from "@gemini-ui/analytics";
import { NavIcon, NavIconLabel, NavMenuContainer } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { HubbleButton, HubbleMenu } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  id: string;
  placement?: "start" | "center" | "end";
  width?: number;
  renderComponent: React.ReactElement;
  active?: boolean;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  iconLabel: string;
  badge?: ReactElement;
  isIconOnly?: boolean;
}

const NavMenu = ({
  id,
  placement,
  width,
  renderComponent,
  active,
  icon,
  iconLabel,
  badge,
  isIconOnly = false,
}: Props) => {
  const { intl } = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);

  const ariaLabel = intl.formatMessage(
    defineMessage({
      defaultMessage: "{iconLabel} Header Button",
    }),
    { iconLabel }
  );

  const handleOpenChange = () => {
    if (!isOpen && renderComponent) {
      setIsOpen(!isOpen);
    } else if (isOpen) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <NavMenuContainer id={`${id}Menu`}>
      <HubbleMenu onOpenChange={handleOpenChange} modal={false}>
        <HubbleMenu.Trigger>
          {isHeaderV2Enabled ? (
            isIconOnly ? (
              <HubbleButton.Secondary
                data-id={id}
                data-testid={`${id}NavIcon`}
                aria-label={ariaLabel}
                size="sm"
                icon={!badge && icon}
                leftElement={badge && icon}
                rightElement={badge && badge}
                mr={0.5}
              />
            ) : (
              <HubbleButton.Tertiary
                data-id={id}
                data-testid={`${id}NavIcon`}
                aria-label={ariaLabel}
                size="sm"
                cta={iconLabel}
                leftElement={icon}
                rightElement={badge}
                mr={0.5}
              />
            )
          ) : (
            <NavIcon active={active || isOpen} data-id={id} data-testid={`${id}NavIcon`} pl={1} pr={1} size="sm">
              {icon}
              {iconLabel && <NavIconLabel>{iconLabel}</NavIconLabel>}
              {badge}
            </NavIcon>
          )}
        </HubbleMenu.Trigger>
        <HubbleMenu.Content
          align={placement}
          width={width ? `${width}px` : "auto"}
          height="auto"
          style={{ top: "8px", maxHeight: "620px" }}
        >
          {renderComponent}
        </HubbleMenu.Content>
      </HubbleMenu>
    </NavMenuContainer>
  );
};

export default NavMenu;
