import * as React from "react";

export function RegionsBankIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#8B0" />
      <path
        d="m17.325 13.565-3.062 4.08 4.76 8.343-1.698-12.423ZM26 18l-.064-.087L22.242 26H32l-6-8ZM19.982 26l2.389-12.836L20 10l-2.412 3.216L19.965 26h.017ZM14 18l-6 8h9.653l-3.583-8.093L14 18ZM21.177 26l4.519-8.406-3.05-4.064L21.124 26h.053Z"
        fill="#fff"
      />
    </svg>
  );
}
