import React, { useState } from "react";
import { Control, Controller, Path, RegisterOptions } from "react-hook-form";
import MASKS, { MASKS_WITHOUT_DIVIDERS } from "@gemini-ui/constants/masks";
import { InputPassword } from "@gemini-ui/design-system";
import { PasswordInputProps } from "@gemini-ui/design-system/forms/Input/InputPassword/constants";

interface Props<TFormData> extends Omit<PasswordInputProps, "name"> {
  name: Path<TFormData>;
  control: Control<TFormData>;
  initialType?: "text" | "password";
  rules?: RegisterOptions;
  onToggleTypeCallback?: (type: "text" | "password") => void;
  isLastFour?: boolean;
}

export const SSNInput = <TFormValues extends Record<string, unknown>>(props: Props<TFormValues>) => {
  const { name, initialType, control, rules, onToggleTypeCallback, isLastFour, ...rest } = props;
  const [type, setType] = useState(initialType);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { name, onBlur, onChange, ref } }) => (
        <InputPassword
          ref={ref}
          name={name}
          initialType={initialType}
          onToggleType={type => {
            setType(type);
            onToggleTypeCallback && onToggleTypeCallback(type);
          }}
          mask={isLastFour ? MASKS.LAST4SSN : type === "password" ? MASKS_WITHOUT_DIVIDERS.SSN : MASKS.SSN}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  );
};
