import styled from "@emotion/styled";
import { shorthandSpacingCss, ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives";

export const List = styled.ul<ShorthandSpacingCssProps>`
  > li:last-child hr {
    display: none;
  }

  ${shorthandSpacingCss}
`;
