import React, { useState } from "react";
import FontAwesome from "@gemini-ui/components/FontAwesome";
import { ExpandableTableCell, ExpandableTableHeaderCell, TableRow } from "@gemini-ui/components/Table/styles";

interface Props {
  header: React.ReactNode;
  content: React.ReactNode;
  customHeader?: boolean;
  StyledRow?: typeof TableRow;
  data?: Record<string, any>;
}

function ExpandableRow(props: Props) {
  const { header, content, data, StyledRow, customHeader = false } = props;
  const [collapsed, setCollapsed] = useState(true);

  const toggle = e => {
    e.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <React.Fragment>
      <StyledRow data={data} style={!collapsed ? { borderBottom: "none" } : {}}>
        {Boolean(customHeader) ? (
          header
        ) : (
          <ExpandableTableHeaderCell className="expandable">{header}</ExpandableTableHeaderCell>
        )}
        <ExpandableTableCell className="expandable">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={toggle}>
            <FontAwesome size="lg" icon={collapsed ? "angle-down" : "angle-up"} />
          </a>
        </ExpandableTableCell>
      </StyledRow>
      {!collapsed && <tr>{content}</tr>}
    </React.Fragment>
  );
}

export default ExpandableRow;
