export type ToastProps = {
  message: React.ReactNode;
  onDismiss?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  uuid?: string;
  ["data-testid"]?: string;
};

export type ToasterProps = {
  toasts: ToastProps[];
  hideToast: (uuid: string) => void;
};

export type ToasterContextType = {
  showToast: (toast: ToastProps) => ToastProps;
  hideToast: (uuid: string) => void;
};

export const TOASTER_DURATIONS = {
  transition: 400,
  timeout: 6000,
};
