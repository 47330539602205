import React from "react";
import { useTheme } from "@emotion/react";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { Flex, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const ValidatorAmountHeader = ({
  validatorCount,
  cryptoValue,
}: {
  validatorCount: number;
  cryptoValue: MoneyProps;
}) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <Flex flexDirection="column" align="center">
      <Text.Display size="xs" numeric>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: `{numValidators} {numValidators,
                plural, 
                =1 {validator} 
                other {validators}}`,
          }),
          {
            numValidators: validatorCount,
          }
        )}
      </Text.Display>
      <Text.Body size="sm" color={colorScheme.content.secondary} bold>
        <Money {...cryptoValue} />
      </Text.Body>
    </Flex>
  );
};
