import { IntlShape } from "@gemini-ui/utils/intl";

export const GiactModalState = {
  Register: "REGISTER",
  Confirm: "CONFIRM",
  DuplicateAccount: "DUPLICATE_ACCOUNT",
  DuplicateAccountAchEnabled: "DUPLICATE_ACCOUNT_ACH_ENABLED",
  Success: "SUCCESS",
  GiactError: "GIACT_ERROR",
  BankBlock: "BANK_BLOCK",
};

export const getWhereDoIFindThisDetails = (intl: IntlShape) => ({
  LINE_ONE: intl.formatMessage({
    defaultMessage: "1. Check your online banking portal",
  }),
  LINE_TWO: intl.formatMessage({
    defaultMessage: "2. Check the bank's website",
  }),
  LINE_THREE: intl.formatMessage({
    defaultMessage: "3. Reach out to your bank representative",
  }),
  LINE_FOUR: intl.formatMessage({
    defaultMessage: "4. Look at the bottom left corner of a check for that bank account",
  }),
});

export const getInstantAccessMessage = (intl: IntlShape) => ({
  MESSAGE: intl.formatMessage({
    defaultMessage:
      "Your transfer may take 5 business days to complete, but eligible deposits are credited or made available to use on Gemini immediately. Please ensure your payment method has adequate funds to avoid delays, reversals, or suspensions of your account.",
  }),
});
