import React from "react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import {
  StakingFundingSourcesScreen,
  StakingFundingSourcesScreenProps,
} from "@gemini-ui/pages/Earn/GrowBuy/screens/StakingFundingSourcesScreen";
import { getPaymentMethodId } from "@gemini-ui/pages/Earn/GrowBuy/utils";

interface ControlledStakingSourceSelectionProps<T extends FieldValues>
  extends Omit<StakingFundingSourcesScreenProps, "onChange" | "value">,
    UseControllerProps<T> {}

/**
 * Wrapper for FundingSourceSelector that uses react-hook-form for value and onChange handling
 */
export const ControlledStakingFundingSourcesScreen = <T extends FieldValues = FieldValues>(
  props: ControlledStakingSourceSelectionProps<T>
) => {
  const {
    field: { value, onChange },
  } = useController(props);

  return (
    <StakingFundingSourcesScreen
      {...props}
      selected={value?.value}
      onDidSelectFundingSource={selected => {
        const newValue = getPaymentMethodId(selected.value);
        onChange(newValue);
        props?.onDidSelectFundingSource?.(selected);
      }}
    />
  );
};
