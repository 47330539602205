import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { LegalId } from "@gemini-ui/components/LegalIdEntry/constants";
import MASKS, { MASKS_WITHOUT_DIVIDERS } from "@gemini-ui/constants/masks";
import { LegalIdType } from "@gemini-ui/pages/register/Verify/types";

export const getLegalIdInputMask = function (
  idType: LegalIdType | LegalId,
  country?: CountryAbbreviation
): any[] | null {
  switch (idType) {
    case "ssn":
      return MASKS.SSN;
    case "last4ssn":
      return MASKS.LAST4SSN;
    case "sin":
      return MASKS.SIN;
    case "nino":
      return MASKS.NINO;
    case "passport":
      switch (country) {
        case "ca":
          return MASKS.CA_PASSPORT;
        case "gb":
          return MASKS.GB_PASSPORT;
        default:
          return null;
      }

    default:
      return null;
  }
};

export const getLegalIdInputMaskWithoutDividers = function (idType: (LegalId | null) | ""): any[] | null {
  switch (idType) {
    case "ssn":
      return MASKS_WITHOUT_DIVIDERS.SSN;
    case "sin":
      return MASKS_WITHOUT_DIVIDERS.SIN;
    default:
      return null;
  }
};

export const getPlaceHolder = (socialNumberType: LegalId) => {
  const placeholders = {
    ssn: "XXX-XX-XXXX",
    sin: "XXX-XXX-XXX",
  };

  return placeholders[socialNumberType] || "";
};
