import * as React from "react";

export function BBTOnlineBankingIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#981A37" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.945 18.182a1.71 1.71 0 0 0 .152-1.576l.006.115a1.74 1.74 0 0 0-1.127-.788 2.073 2.073 0 0 0-2.267.958c-.381.688-.085 1.343.194 1.962.085.188.168.372.23.553a4.17 4.17 0 0 0-1.854 1.818h-.067a3.746 3.746 0 0 0-1.715-1.212 2.424 2.424 0 0 0 1.533-1.242 1.721 1.721 0 0 0-.176-1.54c-.587-1.018-1.66-1.151-2.703-1.212a59.94 59.94 0 0 0-4.042 0v.164l.048.003c.397.03.867.064 1.025.512.006.801.02 1.563.035 2.312.03 1.494.057 2.94.013 4.555l-.176.394c-.184.16-.392.194-.6.228-.091.015-.183.03-.272.056.016.022.011.05.008.074-.005.03-.009.054.028.054h4.467a2.8 2.8 0 0 0 2.539-1.212h.079c.342.499.794.913 1.321 1.212.945.436 2.04.405 2.958-.085.254-.195.519-.375.794-.54.31.267.657.49 1.03.661.33.096.685.06.988-.103a2.097 2.097 0 0 0 1.097-1.382.12.12 0 0 0-.134-.127.897.897 0 0 1-.988.364 7.393 7.393 0 0 0-.173-.132c-.258-.192-.542-.405-.675-.705.219-.292.365-.631.51-.968.25-.577.495-1.146 1.096-1.456.08-.047.2-.074.303-.096a.633.633 0 0 0 .182-.055c.043-.032.03-.069.016-.11-.008-.02-.016-.043-.016-.066-.24.005-.48.007-.712.008-.505.004-.982.007-1.397.052-.031.125.045.137.129.15.047.008.097.016.131.044.312.274.177.59.045.9-.06.141-.12.282-.136.415-.07.09-.124.202-.177.312-.092.191-.182.378-.356.44l-.606-.71c-.533-.681-.99-1.419-1.364-2.2.291-.233.552-.502.776-.8Zm-8.815-.892.003-.769-.006-.03a1.546 1.546 0 0 1 1.679.521c.354.571.408 1.279.145 1.897a1.582 1.582 0 0 1-1.454.873h-.328c-.05-.814-.045-1.66-.04-2.492Zm7.512.776c-.216-.361-.436-.73-.236-1.175v-.006a.764.764 0 0 1 1.151-.073c.182.248.232.57.134.86-.156.37-.46.655-.837.789-.056-.133-.134-.264-.212-.395Zm-1.794 3.364a1.521 1.521 0 0 1 .576-1.545c.4.553.766 1.13 1.097 1.727.372.526.777 1.028 1.212 1.503-.302.3-.748.404-1.151.267a2.673 2.673 0 0 1-1.734-1.952Zm-5.701-.167c.005-.305.01-.61.01-.912a1.71 1.71 0 0 1 1.74.395c.522.78.566 1.787.115 2.612a1.51 1.51 0 0 1-1.133.557.74.74 0 0 1-.685-.333c-.074-.758-.06-1.54-.047-2.32Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.333 17.66a1.818 1.818 0 0 1-.557 1.692c-.321.267-.689.473-1.085.606.11.093.248.13.388.17.107.03.215.06.315.114a2.097 2.097 0 0 1 1.279 1.716 2.048 2.048 0 0 1-.716 1.775c-.948.709-2.203.66-3.439.612-.509-.02-1.014-.04-1.494-.006H6.448c-.02-.032-.013-.072-.006-.113a.394.394 0 0 0 .006-.062c.098-.03.207-.043.317-.055.272-.032.551-.064.69-.358l.078-.606-.06-6.478c-.091-.399-.448-.44-.786-.479-.088-.01-.176-.02-.257-.037a.121.121 0 0 1 0-.163l.049-.03c.637.022 1.26.005 1.874-.012 1.172-.032 2.311-.063 3.453.187.729.228 1.3.799 1.527 1.528Zm-3.94-1.212c0 1.104 0 2.352.037 3.297a1.612 1.612 0 0 0 1.734-.727 2.255 2.255 0 0 0-.097-2.012 1.66 1.66 0 0 0-1.68-.558h.007Zm0 3.849v2.776c0 .279 0 .52.231.715a1.485 1.485 0 0 0 1.412-.255A2.297 2.297 0 0 0 11.31 21c-.297-.776-1.212-.818-1.903-.703h-.012Z"
        fill="#fff"
      />
      <path
        d="M33.588 15.964c.065.707.065 1.42 0 2.127l-.097-.03a2.133 2.133 0 0 0-1.048-1.582 3.03 3.03 0 0 0-1.382-.073l-.08.146.116 7.127c.158.38.573.401.933.42l.158.01c.03.067-.03.26-.03.26l-4.14-.054c-.048-.03-.085-.097-.036-.145.086-.018.18-.028.277-.038.376-.041.785-.086.887-.568l-.055-7.146a2.424 2.424 0 0 0-1.782.273c-.38.356-.61.844-.642 1.364-.028.037-.065.032-.1.027-.028-.003-.055-.007-.076.009a15.099 15.099 0 0 1 0-2.097l1.364-.043 5.733.013Z"
        fill="#fff"
      />
    </svg>
  );
}
