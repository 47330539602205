import { merge } from "lodash";
import { GrowAsset, GrowProviderType } from "@gemini-ui/constants/earn";
import { SourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { defaultState } from "@gemini-ui/pages/Earn/GrowBuy/context/constants";
import { GrowBuyState } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

/**
 * Filters an array of GrowAsset objects to only include those that have a staking provider of any type.
 *
 * @param assets An array of GrowAsset objects to filter.
 * @returns An array of filtered GrowAsset objects.
 */
export const filterStakingAssets = (assets: GrowAsset[]): GrowAsset[] => {
  return assets.reduce((acc, cur) => {
    // If the asset has a staking provider of any type, we will consider it an eligible Staking asset
    if (
      cur.interestProviders.some(
        p => p.providerType === GrowProviderType.POOLED_STAKING || p.providerType === GrowProviderType.PRIVATE_STAKING
      )
    ) {
      return [...acc, cur];
    }

    return acc;
  }, []);
};

/**
 * Constructs an initial state for the GrowBuyContext.
 *
 * This utility is used to merge the static defauly state with dynamic overrides,
 * such as the default asset and payment method.
 *
 * @param asset Optional - Asset to be used for state construction
 * @param initialState Optional - Prop-level overrides
 * @returns
 */
export const constructInitialState = (params?: { asset: GrowAsset; initialState?: Partial<GrowBuyState> }) => {
  const constructedState = {
    ...defaultState,
    ...(Boolean(params?.asset) && {
      currency: params.asset.currency,
      depositStatus: {
        ...defaultState.depositStatus,

        // If there is an available trading balance, we can set the default payment type
        ...(Number(params.asset.availableForEarningInterest.value) > 0 && {
          paymentMethod: {
            type: SourceType.TRADING_BALANCE,
          },
        }),
      },
    }),
  };

  return merge(constructedState, params?.initialState || {});
};
