import styled from "@emotion/styled";

export const Backdrop = styled("div")`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: #000;
  opacity: 0.6;
  pointer-events: none;
`;
