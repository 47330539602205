import React, { ReactNode } from "react";
import { Theme, useTheme } from "@emotion/react";
import {
  IconRewardFilled,
  IconStaticStatusAlert,
  IconStaticStatusInfo,
  IconStaticStatusSuccess,
  IconStaticStatusWarning,
} from "@hubble/icons";
import { Flex } from "@gemini-ui/design-system/Flex";
import { cssShorthandPropsFilter, ShorthandSpacingCssProps, Spacer } from "@gemini-ui/design-system/primitives/Spacer";
import { SectionMessageWrapper } from "@gemini-ui/design-system/SectionMessage/styles";
import { Text } from "@gemini-ui/design-system/Text";

export interface SectionMessageProps extends ShorthandSpacingCssProps {
  ["data-testid"]?: string;
  children?: ReactNode;
  hasAriaLive?: boolean;
  heading?: ReactNode;
  renderButton?: ReactNode;
  renderSecondButton?: ReactNode; // will not render without renderButton
  statusType: "alert" | "info" | "success" | "warning" | "reward";
}

const getIconMap = (colorScheme: Theme["colorScheme"]) =>
  ({
    alert: <IconStaticStatusAlert />,
    info: <IconStaticStatusInfo />,
    success: <IconStaticStatusSuccess />,
    warning: <IconStaticStatusWarning />,
    reward: <IconRewardFilled color={colorScheme.status.default.content.reward} />,
  } as const);

const ARIA_LIVE_VALUES = {
  assertive: "assertive",
  polite: "polite",
} as const;

const ARIA_ROLE_VALUES = {
  alert: "alert",
  status: "status",
} as const;

export function SectionMessage({
  ["data-testid"]: testId,
  children,
  heading,
  renderButton,
  renderSecondButton,
  hasAriaLive = true,
  statusType = "alert",
  ...props
}: SectionMessageProps) {
  const { colorScheme } = useTheme();
  function ariaLiveAttrs() {
    if (!hasAriaLive) return {};
    if (statusType === "alert" || statusType === "warning")
      return {
        role: ARIA_ROLE_VALUES.alert,
        "aria-live": ARIA_LIVE_VALUES.assertive,
        "aria-atomic": true,
      };

    if (statusType === "success") {
      return {
        role: ARIA_ROLE_VALUES.status,
        "aria-live": ARIA_LIVE_VALUES.polite,
        "aria-atomic": true,
      };
    }
  }

  return (
    <SectionMessageWrapper
      data-testid={testId}
      statusType={statusType}
      {...ariaLiveAttrs()}
      {...cssShorthandPropsFilter(props)}
    >
      {getIconMap(colorScheme)[statusType]}
      <Flex justifyContent="center" flexDirection="column">
        {Boolean(heading) && <Text.Heading size="xs">{heading}</Text.Heading>}

        {Boolean(children) && (
          <Text.Body as="div" size="sm" mt={Boolean(heading) ? 0.5 : null}>
            {children}
          </Text.Body>
        )}

        {Boolean(renderButton) && (
          <Flex mt={1.5}>
            <Spacer mr={1}>{renderButton}</Spacer>
            {renderSecondButton}
          </Flex>
        )}
      </Flex>
    </SectionMessageWrapper>
  );
}
