export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const GEMINI_OPEN_DATE = new Date("2015/01/01");
export const GEMINI_OPEN_YEAR = 2015;
export const CURRENT_DATE = new Date();
export const CURRENT_YEAR = new Date().getFullYear(); // can't get transactions past the current date :)
export const SOVOS_START_YEAR = 2019;
export const TAX_ISSUE_DATE = new Date(CURRENT_DATE.getFullYear(), 2, 2); // March 2nd, current year

export type FocusedInputNames = typeof START_DATE | typeof END_DATE;
