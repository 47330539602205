import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";

export const useEarnDistributionsFlags = () => {
  const {
    templateProps: { featureFlags },
  } = usePageData();

  /**
   * BE Feature Flag: EarnMasterClaimVotingEnabled
   *
   * NOTE: The Kroll voting period has ended. This flag will likely be removed in the future.
   *
   * When enabled, this flag toggles the availability of the Kroll voting materials page for eligible
   * customers.
   */
  const isEarnMasterClaimVotingEnabled = featureFlags?.EarnMasterClaimVotingEnabled;

  /**
   * BE Feature Flag: EarnFinalDistributionSummaryEnabled
   *
   * When enabled, this flag toggles the availability of the Earn distributions screen for eligible
   * customers. This flag is returned for Earn customers that were eligible to vote in the Kroll voting
   * proceedings, and does not mean that the user has distributions available to claim.
   */
  const isEarnFinalDistributionSummaryEnabled = featureFlags?.EarnFinalDistributionSummaryEnabled;

  /**
   * ExP Feature Flag: web_enable_kroll_vote
   *
   * Toggles the master availability of the Kroll voting screen.
   */
  const isKrollVotingEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_ENABLE_KROLL_VOTE);

  /**
   * ExP Feature Flag: web_earn_distribution_materials
   *
   * Toggles the master availability of the Earn distributions screen and all related materials.
   */
  const isEarnDistributionsMaterialsScreenEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_EARN_DISTRIBUTION_MATERIALS
  );

  /**
   * ExP Feature Flag: web_earn_distributions_homepage_banner
   *
   * Toggles the banner on the app Home Page that notifies users of an available Earn distribution.
   */
  const isHomePageEarnDistributionsBannerEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_EARN_DISTRIBUTIONS_HOMEPAGE_BANNER
  );

  /**
   * ExP Feature Flag: web_earn_distributions_banner_navigation
   *
   * Toggles the visibility of the "View Earn distributions" button in the Pending Redemptions sheet.
   */
  const isEarnDistributionsNavigationEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_EARN_DISTRIBUTIONS_BANNER_NAVIGATION
  );

  /**
   * ExP Feature Flag: mobile_earn_distributions_claim_card_copy
   *
   * Toggles remote copy for the Earn distributions claim cards.
   * Copy should be set as JSON by claim id, then claim status, then the copy field. e.g.
   * { claimId1: { available: { title: "Added text..." }, completed: { subtitle: "More text..." }}}
   */
  const isEarnClaimCardDynamicCopyEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_EARN_DISTRIBUTIONS_CLAIM_CARD_COPY
  );

  // Make sure that:
  // - the voting materials are enabled on the BE
  // - the ExP flag is enabled
  const isEarnVotingMaterialsPageEnabled = isEarnMasterClaimVotingEnabled && isKrollVotingEnabled;

  // Make sure that:
  // - distributions are enabled on the BE
  // - the ExP flag is enabled
  const isEarnDistributionsPageEnabled =
    isEarnFinalDistributionSummaryEnabled && isEarnDistributionsMaterialsScreenEnabled;

  // Make sure that:
  // - distributions are enabled on the BE
  // - the ExP flag is enabled
  // - there are pending earn redemptions
  const shouldDisplayHomePageEarnDistributionsBanner =
    isEarnDistributionsPageEnabled && isHomePageEarnDistributionsBannerEnabled;

  // Make sure that:
  // - distributions are enabled on the BE
  // - the ExP flag is enabled
  const shouldDisplayViewEarnDistributionsButton =
    isEarnDistributionsPageEnabled && isEarnDistributionsNavigationEnabled;

  return {
    isEarnDistributionsFlagEnabled: isEarnFinalDistributionSummaryEnabled,
    isEarnVotingMaterialsPageEnabled,
    isEarnDistributionsPageEnabled,
    isEarnClaimCardDynamicCopyEnabled,
    shouldDisplayHomePageEarnDistributionsBanner,
    shouldDisplayViewEarnDistributionsButton,
  };
};
