import { SUPER_PROPERTIES } from "@gemini-ui/analytics/constants/events";
import { PEOPLE_PROPERTIES } from "@gemini-ui/analytics/constants/trade";
import {
  incrementPeopleProperty,
  incrementSuperProperty,
  setPeopleProperty,
  setPeoplePropertyOnce,
} from "@gemini-ui/analytics/index";

export function calculateNotionalTotal(quantity: number, lastTradePrice: string) {
  if (!quantity || !lastTradePrice) {
    return 0;
  }

  const total = Number(quantity) * Number(lastTradePrice);
  return total.toFixed(2);
}

export function setTradePeopleProperties() {
  const now = new Date().toISOString();
  setPeoplePropertyOnce({ [PEOPLE_PROPERTIES.FIRST_TRADE_DATE]: now });
  setPeopleProperty({ [PEOPLE_PROPERTIES.LAST_TRADE_DATE]: now });
  incrementPeopleProperty(PEOPLE_PROPERTIES.NUMBER_OF_TRADES_PLACED);
  incrementSuperProperty(SUPER_PROPERTIES.NUMBER_OF_TRADES_PREVIOUSLY_PLACED);
}
