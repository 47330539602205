import React from "react";
import { Button, Modal, Text } from "@gemini-ui/design-system";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/v2/styles";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/v2/testIds";
import { getCleanRoute } from "@gemini-ui/pages/Credit/CreditDashboard/utils";
import { useIntl } from "@gemini-ui/utils/intl";

interface ExitAppModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ExitAppModal = ({ isOpen, onClose }: ExitAppModalProps) => {
  const { intl } = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <ViewTitle>
        <Text.Heading size="md">
          {intl.formatMessage({ defaultMessage: "Are you sure you want to exit the application?" })}
        </Text.Heading>
        <Text.Body size="sm">
          {intl.formatMessage({
            defaultMessage:
              "You can come back and complete your application from the card section of the app within 30 days. ",
          })}
        </Text.Body>
      </ViewTitle>

      <Button.Group align="right">
        <Button.Secondary data-testid={testIds.button.cancel} onClick={onClose}>
          {intl.formatMessage({ defaultMessage: "Cancel" })}
        </Button.Secondary>
        <Button.Primary
          data-testid={testIds.button.exitApplication}
          href={getCleanRoute(jsRoutes.controllers.dashboard.DashboardController.main().url)}
        >
          {intl.formatMessage({ defaultMessage: "Exit application" })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};
