import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { PaddingType, paddingTypes, VariantType } from "@gemini-ui/design-system/cards/Card/constants";
import { border, motion, shorthandSpacingCss } from "@gemini-ui/design-system/primitives";

function isInteractive(as) {
  return as === "a" || as === "button" || (as && typeof as === "object" ? as.displayName === "Link" : false);
}

export const CardWrapper = styled("div", {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<{
  as?: keyof JSX.IntrinsicElements;
  customFilledBgColor?: string;
  isCollapsible?: boolean;
  padding: PaddingType;
  variant: VariantType;
  overflow?: CSSStyleDeclaration["overflow"];
}>`
  border-radius: ${border.radius.lg};
  display: block;
  overflow: ${({ overflow }) => overflow || "hidden"};
  padding: ${({ padding }) => paddingTypes[padding]};
  position: relative;

  ${({ isCollapsible }) => isCollapsible && `padding-top: ${paddingTypes.none}; padding-bottom: ${paddingTypes.none};`}

  ${({ theme, variant }) =>
    variant === "outlined" &&
    `
      background-color: ${theme.colorScheme.card.background.outlined.enabled};
      border: 1px solid ${theme.colorScheme.card.border.outlined.enabled};
    `}

  ${({ customFilledBgColor, theme, variant }) =>
    variant === "filled" &&
    `
      background-color: ${customFilledBgColor || theme.colorScheme.card.background.filled};
      border: 1px solid ${customFilledBgColor || theme.colorScheme.card.border.filled};
    `}

  ${({ theme, variant }) =>
    variant === "inverse" &&
    `
      background-color: ${theme.colorScheme.card.background.inverse};
      border: 0;
    `}

${({ as, theme }) =>
    isInteractive(as) &&
    `
      cursor: pointer;
      text-align: left;
      transition: transform ${motion.duration.fast} ${motion.timing.easeInOut},
        background-color ${motion.duration.fast} ${motion.timing.easeInOut},
        border-color ${motion.duration.fast} ${motion.timing.easeInOut};
      width: 100%;

      &:hover,
      &:focus {
        background-color: ${theme.colorScheme.card.background.outlined.hover};
        border-color: ${theme.colorScheme.card.border.outlined.hover};
      }

      &:active {
        transform: scale(0.98);
      }

      &&&[aria-checked="true"] {
        background-color: ${theme.colorScheme.card.background.outlined.selected};
        border-color: ${theme.colorScheme.card.border.outlined.selected};
        box-shadow: inset 0 0 0 1px ${theme.colorScheme.card.border.outlined.selected};
      }
    `}

  ${shorthandSpacingCss}
`;
