import React from "react";
import { CopyListItemToClipboard } from "@gemini-ui/components/CopyListItemToClipboard/CopyListItemToClipboard";
import { CbitTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { Card, Colors, List, ListItem, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

export const CbitDepositDetails = ({
  publicDescription,
  walletAddress,
  walletName,
  firmName,
}: CbitTransferInfoAPIPayload) => {
  const { intl } = useIntl();

  return (
    <React.Fragment>
      <Text.Body size="md">
        {intl.formatMessage({
          defaultMessage:
            "Issue a transfer from your CBIT account to Gemini. You will first need to add Gemini’s wallet address as a recipient. If you need help, contact TassatPay customer support.",
        })}
      </Text.Body>

      <Card variant="filled" mt={2} padding="none">
        <Text.Heading size="xs" m={2}>
          {intl.formatMessage({
            defaultMessage: "Recipient information",
          })}
        </Text.Heading>
        <List>
          <ListItem
            padding="sm"
            left={<Text.Body>{intl.formatMessage({ defaultMessage: "Firm name" })}</Text.Body>}
            right={<Text.Body bold>{firmName}</Text.Body>}
          ></ListItem>
          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Wallet name" })}
            data={walletName}
            textToCopy={walletName}
          ></CopyListItemToClipboard>

          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Wallet address" })}
            data={walletAddress}
            textToCopy={walletAddress}
            hasDivider={false}
          ></CopyListItemToClipboard>
        </List>
      </Card>

      {Boolean(publicDescription) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {intl.formatMessage({
              defaultMessage: "Reference code",
            })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Public description" })}
              data={publicDescription}
              textToCopy={publicDescription}
              hasDivider={false}
            ></CopyListItemToClipboard>
          </List>
          <Text.Body pt={0} pl={2} pr={2} pb={2} size="xs" color={Colors.gray[600]}>
            {intl.formatMessage({
              defaultMessage:
                "Include your account reference code in the memo, instruction, or description field. This code is used to match deposits to your Gemini account. Without it, deposits may be delayed or returned.",
            })}
          </Text.Body>
        </Card>
      )}
    </React.Fragment>
  );
};
