import {
  KeyOfTransferMechanismName,
  TRANSFER_MECHANISM,
  TransferMechanism,
  TransferMechanismName,
} from "@gemini-ui/pages/transfers/constants";

const { ach, wire, sen, card, blinc, xfers, bcolo, paypal, plaid, external, rtp, cbit } = TRANSFER_MECHANISM;

export const getMechanismName = (transferMechanism: TransferMechanism): KeyOfTransferMechanismName => {
  switch (transferMechanism) {
    case ach:
      return TransferMechanismName.AchTransfer;
    case wire:
      return TransferMechanismName.WireTransfer;
    case sen:
      return TransferMechanismName.SenTransfer;
    case card:
      return TransferMechanismName.CardTransfer;
    case blinc:
      return TransferMechanismName.BlincTransfer;
    case bcolo:
      return TransferMechanismName.BcoloTransfer;
    case xfers:
      return TransferMechanismName.XfersTransfer;
    case paypal:
      return TransferMechanismName.PayPalTransfer;
    case plaid:
      return TransferMechanismName.PlaidPaymentInitiation;
    case external:
      return TransferMechanismName.ExternalTransfer;
    case rtp:
      return TransferMechanismName.RtpTransfer;
    case cbit:
      return TransferMechanismName.CbitTransfer;
  }
};
