import { GrowProviderType } from "@gemini-ui/constants/earn";
import { DepositModalView, SourceType, UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { GrowBuyContextValues, GrowBuyState, GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import {
  BuyingFrequency,
  DAYS_ENUM,
  MONTHLY_NUM_MAP,
  TWICE_MONTHLY_NUM_MAP,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";

export const defaultState: GrowBuyState = {
  asset: null,
  assetProviders: [],
  tradingPair: null,
  currency: null,
  providerType: GrowProviderType.POOLED_STAKING,
  provider: null,
  transactionType: GrowTransactionType.STAKE,
  isOpen: false,
  depositStatus: {
    quote: null,
    error: null,
    source: SourceType.TRADING_BALANCE,
    schedule: BuyingFrequency.Once,
    recurringFrequency: {
      weekly: DAYS_ENUM.Monday,
      twiceMonthly: TWICE_MONTHLY_NUM_MAP.FirstAndFifteenth,
      monthly: MONTHLY_NUM_MAP.First,
    },
    paymentMethod: {
      type: null,
    },
  },
  redeemStatus: {
    amount: "",
    redemptionStatus: null,
    convertToFiat: false,
    unstakeSourceType: UnstakeSourceType.pooledBalance,
    validatorCount: 0,
  },
  router: {
    currentView: DepositModalView.PLACE_DEPOSIT,
    // TODO: QA this addition, I don't want it to affect the existing `goBack` behavior for the modal flow
    viewStack: [DepositModalView.PLACE_DEPOSIT],
  },
  stakingAssets: [],
};

export const defaultValues: GrowBuyContextValues = {
  ...defaultState,
  instantiateGrowTransaction: () => null,
  setTransactionType: () => null,
  openModal: () => null,
  closeModal: () => null,
  updateDepositStatus: () => null,
  updateRedeemStatus: () => null,
  setModalView: () => null,
  setCurrency: () => null,
  setQuote: () => null,
  setPaymentMethod: () => null,
  resetQuote: () => null,
  setDepositError: () => null,
  goBack: () => null,
};
