import { InterestProvider } from "@gemini-ui/constants/earn";
import { GridCardContainerProps } from "@gemini-ui/pages/Earn/StakingAssetDetailsPage/styles";

export interface RewardsBreakdownProps extends GridCardContainerProps, Partial<InterestProvider> {}

export enum StakingBalanceState {
  HAS_POOLED = "HAS_POOLED",
  HAS_PRIVATE = "HAS_PRIVATE",
  HAS_POOLED_AND_PRIVATE = "HAS_POOLED_AND_PRIVATE",
  NO_BALANCE = "NO_BALANCE",
}
