import { promiseTypes, successTypes } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { SubaccountWithBalances } from "@gemini-ui/services/subaccounts";
import { getError } from "@gemini-ui/utils/error";

export type SettingsPromiseResponse = {
  status: string;
  value?: {
    err: string | null;
    promise: "leverage" | "collateral" | "name";
    res: ResponseData;
  };
};

type ResponseData = {
  data: NameData | LeverageData | CollateralData;
  status: number;
  statusText: "";
};

type NameData = {
  success: boolean;
  name: string;
};

type LeverageData = any;

type CollateralData = {
  message: string;
  status: { status: "AllSuccess" | "UserNegativeBalanceOperation" };
  switchOffResponse: SwitchOffResponse;
};

type SwitchOffResponse = {
  accountId: number;
  initialMargin: string;
  marginAssetValue: string;
  successful: boolean;
};

// Check each setting (Name, Leverage, Collateral) for changes
// If changed, return a Promise w/ a request to change that setting

//  ---  NAME  ---
export function checkName(editAccountState: SubaccountWithBalances, editAccount: SubaccountWithBalances) {
  if (editAccountState.name !== editAccount.name) {
    return getNamePromise(editAccountState.name, editAccount.hashid);
  }
}

function getNamePromise(newName: string, hashid: string) {
  const newSettings = {
    account: hashid,
    name: newName,
  };
  const { ProfileSettingsController } = jsRoutes.controllers.settings;
  const namePromise = axios
    .post(ProfileSettingsController.updateAccountName().url, newSettings)
    .then(res => ({ res: res, promise: promiseTypes.NAME, err: null }))
    .catch(err => ({
      res: null,
      promise: promiseTypes.NAME,
      err: getError(err),
    }));
  return namePromise;
}

//  ---  LEVERAGE  ---
export function checkLeverage(editAccountState: SubaccountWithBalances, editAccount: SubaccountWithBalances) {
  const oldLeverage = editAccount.crossCollateralParams?.maxLeverage;
  const newLeverage = editAccountState.crossCollateralParams?.maxLeverage;
  if (newLeverage !== oldLeverage) {
    return getLeveragePromise(newLeverage, editAccount.hashid);
  }
}

function getLeveragePromise(newLeverage: string, hashid: string) {
  const { PerpetualSwapsController } = jsRoutes.controllers.settings;
  const changeLeverageUrl = PerpetualSwapsController.changeLeverage(newLeverage).url;
  const leveragePromise = axios
    .post(changeLeverageUrl, undefined, {
      headers: { [HEADERS.ACCOUNT_ID]: hashid },
    })
    .then(res => ({
      res: res,
      promise: promiseTypes.LEVERAGE,
      err: null,
    }))
    .catch(err => ({
      res: null,
      promise: promiseTypes.LEVERAGE,
      err: getError(err),
    }));
  return leveragePromise;
}

//  ---  COLLATERAL  ---
export function checkCollateral(editAccountState: SubaccountWithBalances, editAccount: SubaccountWithBalances) {
  const oldCrossCollateral = editAccount.crossCollateralParams?.enableCrossCollateral === "true";
  const newCrossCollateral = editAccountState.crossCollateralParams.enableCrossCollateral === "true";
  const oldNegativeBalances = editAccount.crossCollateralParams?.enableNegativeBalances === "true";
  const newNegativeBalances = editAccountState.crossCollateralParams.enableNegativeBalances === "true";

  if (!newNegativeBalances && oldNegativeBalances && !newCrossCollateral && oldCrossCollateral) {
    // Both changing - execute in sequence
    return getCollateralPromise(oldCrossCollateral, newNegativeBalances, editAccount.hashid).then(res => {
      return res.err ? res : getCollateralPromise(newCrossCollateral, newNegativeBalances, editAccount.hashid);
    });
  }
  return getCollateralPromise(newCrossCollateral, newNegativeBalances, editAccount.hashid);
}

// ** Failed collateral changes still return 200 and switchOffResponse.successful === true. Errors due to
// account state (negative balance / low MAV) must be determined through response.data.status.status
function getCollateralPromise(newCrossCollateral: boolean, newNegativeBalances: boolean, hashid: string) {
  const newSettings = {
    enableCrossCollateral: newCrossCollateral,
    enableNegativeBalances: newNegativeBalances,
  };
  const { PerpetualSwapsController } = jsRoutes.controllers.settings;
  const collateralPromise = axios
    .post(PerpetualSwapsController.processSettings().url, newSettings, {
      headers: { [HEADERS.ACCOUNT_ID]: hashid },
    })
    .then(res => ({
      res: res,
      promise: promiseTypes.COLLATERAL,
      err:
        res.data.status.status === "UserNegativeBalanceOperation" ||
        res.data.status.status === "UserMavBelowImOperation"
          ? res.data.message
          : res.data.status.status !== "AllSuccess"
          ? successTypes.DEFAULT
          : null,
    }))
    .catch(err => ({
      res: null,
      promise: promiseTypes.COLLATERAL,
      err: getError(err),
    }));
  return collateralPromise;
}
