import React, { useMemo } from "react";
import { IconWalletOutlined } from "@hubble/icons";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { Select } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { DropdownItem } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs/DropdownItem";
import { SourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  source: SourceType;
  error: string;
  availableForEarningInterest: MoneyProps;
  availableForEarningInterestNotional: MoneyProps;
  onChange?: (source: SourceType) => void;
}

export const FundingSourceSelect = ({
  source,
  onChange,
  error,
  availableForEarningInterest,
  availableForEarningInterestNotional,
}: Props) => {
  const { intl } = useIntl();

  const options = useMemo(
    () => [
      {
        value: SourceType.TRADING_BALANCE,
        label: intl.formatMessage({ defaultMessage: "Trading balance" }),
        icon: <IconWalletOutlined size="sm" />,
        subLabel: <Money {...availableForEarningInterest} />,
        isDisabled: availableForEarningInterest?.value === "0", // disable on zero balance
      },

      // TODO: Enable in future (pending business requirements)
      // {
      //   value: SourceType.BANK_OR_CARD,
      //   label: intl.formatMessage(defineMessage({ defaultMessage: "{defaultFiat} balance" }), { defaultFiat }),
      //   icon: <CryptoIcon symbol={defaultFiat} />,
      //   subLabel: <Money {...availableForEarningInterestNotional} />,
      // },
    ],
    [intl, availableForEarningInterest]
  ) satisfies SelectOptionProps<SourceType>[];

  return (
    <Select
      label=""
      name="funding-source"
      options={options}
      onChange={onChange}
      value={source}
      formatOptionLabel={DropdownItem}
      isSearchable={false}
      size="lg"
      error={error}
      disabled={!(options.length > 1)}
    />
  );
};
