import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system";

export const Title = styled.div`
  text-align: center;
  line-height: 1.3;
`;

export const SubTitle = styled.div<{ textAlign?: string }>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
  line-height: 1.5;
  color: ${Colors.gray[800]};
  margin-bottom: ${Spacing.scale[1.5]};
`;

export const ReviewTransactions = styled.div`
  text-align: center;
`;
