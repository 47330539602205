import React, { useImperativeHandle, useRef } from "react";
import {
  InputStyleProps,
  InputWithOptionalElementProps,
} from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { dynamicWidthInputElement } from "@gemini-ui/design-system/__deprecated__/Input/InputElements";
import { InputContainer, StyledInput } from "@gemini-ui/design-system/__deprecated__/Input/styles";
import { FormElementLabel, FormElementMessage, Label, SuccessIcon } from "@gemini-ui/design-system/formElements";
import { Colors } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";

const onWheelDefault = (event: React.WheelEvent<HTMLInputElement>) => {
  if (event.currentTarget.type === "number") {
    event.currentTarget.blur();
  }
};

// things have changed: removed id from input, removed id from message, focus, removed isFocus, error is string instead of message+error boolean
/** @deprecated please use the latest Hubble Input component instead */
export const Input = React.forwardRef<HTMLInputElement, InputWithOptionalElementProps>(
  (
    {
      alignText,
      autoFocus = false,
      className,
      ["data-testid"]: dataTestId,
      disabled,
      error,
      helperText,
      label,
      leftElement,
      message,
      name,
      readOnly,
      rightElement,
      variant = "lg",
      success,
      value,
      pl,
      pr,
      onWheel = onWheelDefault,
      ...htmlInputProps
    },
    forwardRef
  ) => {
    const inputRef = useRef(null);
    useImperativeHandle(forwardRef, () => inputRef.current);

    const styles: InputStyleProps = {
      error,
      success,
      alignText,
      variant,
    };

    return (
      <Label data-testid={`${dataTestId}-label`} className={className}>
        {Boolean(label) && (
          <FormElementLabel variant={variant} error={Boolean(error)} readOnly={readOnly} disabled={disabled}>
            {label}
          </FormElementLabel>
        )}
        <InputContainer>
          {success && !Boolean(error) && <SuccessIcon />}
          {leftElement && dynamicWidthInputElement(leftElement, pl)}
          <StyledInput
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            data-testid={dataTestId}
            disabled={disabled}
            name={name}
            key={name}
            styles={styles}
            pl={pl}
            pr={pr}
            readOnly={readOnly}
            ref={inputRef}
            value={value}
            onWheel={onWheel}
            {...htmlInputProps}
          />
          {rightElement && dynamicWidthInputElement(rightElement, pr)}
        </InputContainer>
        {helperText && (
          <Text.Body
            data-testid={`${dataTestId}-helper-text`}
            ml={0.5}
            size="xs"
            color={disabled ? Colors.gray[200] : Colors.gray[600]}
            mt={0.5}
            mb={variant === "md" ? 1 : 0.5}
            as={typeof helperText === "string" ? "p" : "div"}
          >
            {helperText}
          </Text.Body>
        )}
        {(Boolean(message) || (error && typeof error !== "boolean")) && (
          <FormElementMessage
            error={Boolean(error)}
            message={error || message}
            dataTestId={dataTestId}
            variant={variant}
          />
        )}
      </Label>
    );
  }
);
