import useScript from "@gemini-ui/utils/useScript";

const BRAINTREE_CLIENT_SCRIPT = "https://js.braintreegateway.com/web/3.86.0/js/client.min.js";
const PAYPAL_CHECKOUT_SCRIPT = "https://js.braintreegateway.com/web/3.86.0/js/paypal-checkout.min.js";
export const DATA_COLLECTOR_SCRIPT = "https://js.braintreegateway.com/web/3.86.0/js/data-collector.min.js";

export const usePayPalScripts = () => {
  const { ready: braintreeReady } = useScript(BRAINTREE_CLIENT_SCRIPT);
  const { ready: paypalReady } = useScript(PAYPAL_CHECKOUT_SCRIPT);
  const { ready: dataCollectorReady } = useScript(DATA_COLLECTOR_SCRIPT);

  return { braintreeReady, paypalReady, dataCollectorReady };
};
