import React from "react";
import { FeedbackAction, FeedbackStatus } from "@gemini-ui/design-system/Feedback/constants";
import { ReactComponent as ExpandIcon } from "@gemini-ui/images/dropdown_caret.svg";
import { ReactComponent as DismissIcon } from "@gemini-ui/images/icons/dismiss.svg";
import { ReactComponent as BlueInfoIcon } from "@gemini-ui/images/icons/info-blue.svg";
import { ReactComponent as InfoIconOutline } from "@gemini-ui/images/icons/info-icon-2x.svg";
import { ReactComponent as InfoIcon } from "@gemini-ui/images/icons/info-icon-filled.svg";
import { ReactComponent as SuccessIcon } from "@gemini-ui/images/icons/success-outline-icon.svg";
import { ReactComponent as ErrorTriangleIcon } from "@gemini-ui/images/icons/warning-triangle-filled.svg";

export const getStatusIcon = (status: FeedbackStatus, customStatusIcon: React.ReactNode) => {
  if (customStatusIcon) return customStatusIcon;

  switch (status) {
    case "error": {
      return <ErrorTriangleIcon />;
    }
    case "warning": {
      return <InfoIcon />;
    }
    case "success": {
      return <SuccessIcon />;
    }
    case "info": {
      return <BlueInfoIcon />;
    }
    case "tip": {
      return <InfoIconOutline />;
    }
  }
};

export const getActionIcon = (action: FeedbackAction) => {
  switch (action) {
    case "expand": {
      return <ExpandIcon />;
    }
    case "dismiss": {
      return <DismissIcon />;
    }
  }
};
