import { GlobalModalType } from "@gemini-ui/contexts/GlobalModal";

export enum ModalStates {
  Introduction = "Introduction",
  AccountActivateForm = "AccountActivateForm",
  AccountUpdateForm = "AccountUpdateForm",
  Confirmation = "Confirmation",
  TransferForm = "TransferForm",
  FinalizeTransfer = "FinalizeTransfer",
  TransferConfirmation = "TransferConfirmation",
  Ineligible = "Ineligible",
  JoinLeaderboard = "JoinLeaderboard",
  LeaderboardInfo = "LeaderboardInfo",
  LeaderboardOptedIn = "LeaderboardOptedIn",
  FundInstructions = "FundInstructions",
  FundInstructionsSettings = "FundInstructionsSettings",
  TransferFromAccount = "TransferFromAccount",
  TransferToAccount = "TransferToAccount",
  TransferCurrency = "TransferCurrency",
  FailedTransfer = "FailedTransfer",
  IncompleteTransferDetails = "IncompleteTransferDetails",
  ReceiveAsOptions = "ReceiveAsOptions",
}

export const testIds = {
  LEARN_MORE_BTN: "intro-learn-more-btn",
  CREATE_ACCT_BTN: "create-account-btn",
  INELIGIBLE_OK_BTN: "ineligible-ok-btn",
  accountForm: {
    ACCT_NAME_INPUT: "acct-name-input",
    REG_CANCEL_BTN: "reg-cancel-btn",
    REG_ACTIVATE_ACCOUNT_BTN: "reg-activate-account-btn",
    CHECKBOX_ONE: "checkbox-one",
    CHECKBOX_TWO: "checkbox-two",
    CHECKBOX_THREE: "checkbox-three",
  },
  accountUpdateForm: {
    LEVERAGE_INPUT: "leverage-input",
    CROSS_COLLATERAL_TOGGLE: "cross-collateral-toggle",
    NEGATIVE_BALANCES: "negative-balances",
    SAVE_BTN: "save-btn",
    SKIP_BTN: "skip-btn",
  },
  confirmation: {
    LEARN_MORE_BTN: "confirmation-learn-more",
    DEPOSIT: "confirmation-make-deposit",
    TRANSFER: "confirmation-transfer",
    LOADING_SPINNER: "confirmation-loading-spinner",
  },
  accountTransfer: {
    LOADING_SPINNER: "loading-spinner",
    DEPOSIT_BTN: "transfer-deposit-btn",
    CONTINUE_BTN: "transfer-continue-btn",
    CONFIRM_BTN: "transfer-confirm-btn",
    RETURN_BTN: "transfer-return-tbn",
    FROM_ACCOUNT: "transfer-from-acct",
    TO_ACCOUNT: "transfer-to-acct",
    ASSET: "transfer-asset-input",
    RECEIVE_AS_ASSET: "transfer-receive-as-asset-input",
    AMOUNT_INPUT: "transfer-amount-input",
    ACTIVETRADER: "transfer-active-trader-btn",
    RETRY_BTN: "transfer-retry-btn",
    VIEW_DETAILS_BTN: "transfer-view-details-btn",
  },
};

export const AUTO_OPEN_AT_PERP_MODAL_ON_LOAD = "autoOpenATPerpModalOnLoad";

export interface PerpsOnboardingProps {
  isOpen: boolean;
  onClose: () => void;
  initialModalState?: ModalStates;
}

export const GUSD_MODAL_KEY = "gusd-perp-modal";

export const getGlobalModalState = (globalModalType: GlobalModalType) => {
  switch (globalModalType) {
    case GlobalModalType.PerpsOnboardingIntroductionModal:
      return ModalStates.Introduction;
    case GlobalModalType.PerpsOnboardingAccountActivateFormModal:
      return ModalStates.AccountActivateForm;
    case GlobalModalType.PerpsOnboardingAccountUpdateFormModal:
      return ModalStates.AccountUpdateForm;
    case GlobalModalType.PerpsOnboardingTransferFormModal:
      return ModalStates.TransferForm;
    case GlobalModalType.PerpsOnboardingLeaderboardInfoModal:
      return ModalStates.LeaderboardInfo;
    case GlobalModalType.PerpsOnboardingLeaderboardOptedInModal:
      return ModalStates.LeaderboardOptedIn;
    case GlobalModalType.PerpsIneligibleModal:
      return ModalStates.Ineligible;
    case GlobalModalType.PerpsFundingInstructions:
      return ModalStates.FundInstructions;
    case GlobalModalType.PerpsFundingInstructionsSettings:
      return ModalStates.FundInstructionsSettings;
    case GlobalModalType.PerpsFundAccount:
      return ModalStates.Confirmation;
    case GlobalModalType.PerpsOnboardingTransferFromAccountModal:
      return ModalStates.TransferFromAccount;
    case GlobalModalType.PerpsOnboardingTransferToAccountModal:
      return ModalStates.TransferToAccount;
    case GlobalModalType.PerpsOnboardingTransferCurrencyModal:
      return ModalStates.TransferCurrency;
    case GlobalModalType.PerpsOnboardingFinalizeTransferModal:
      return ModalStates.FinalizeTransfer;
    default:
      return ModalStates.Introduction;
  }
};
