import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { Colors, Spacing } from "@gemini-ui/design-system";
import { ReactComponent as GeminiIcon } from "@gemini-ui/images/gemini-logo-cyan.svg";

export const fullScreenStyles = css`
  height: 100%;
  width: 100%;

  .ModalLayout {
    border-radius: 0;
    height: 100%;
    max-width: initial;

    .ModalHeader,
    .ModalContent {
      padding: 0;
    }

    .ModalContent {
      height: 100%;
    }
  }
`;

export const Container = styled("div")`
  position: relative;
  min-width: 175px;

  .header-enter {
    opacity: 0;
    transform: translateY(-10%);
  }

  .header-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms ease;
  }

  .header-exit {
    opacity: 0;
  }

  .header-exit-active {
    opacity: 1;
    transition: all 300ms ease;
  }

  .group-name-enter-active {
    transform: translateY(10%);
    transition: all 500ms ease;
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  .group-name-enter-done {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  .group-name-exit {
    transform: translateY(10%);
    transition: all 100ms ease;
  }

  .group-name-exit-active {
    transform: translateY(0);
    transition: all 100ms ease;
  }

  .item-enter {
    transform: translateY(-10%);
    visibility: hidden;
    opacity: 0;
  }

  .item-enter-active {
    transform: translateY(0%);
    transition: all 500ms ease;
    visibility: visible;
    opacity: 1;
  }

  .item-exit-active {
    transform: translateY(-10%);
    transition: all 300ms ease;
  }
`;

export const Header = styled("div")`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  color: ${Colors.white};

  @media ${BREAKPOINTS.desktop} {
    color: ${Colors.gray[400]};
    font-size: 12px;
  }
`;

export const SubHeader = styled(Header)`
  font-weight: 400;
  color: ${Colors.gray[400]};
`;

export const Form = styled("form")`
  margin-bottom: 0;
`;

export const ArrowContainer = styled("div")<{ expanded?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  > svg {
    width: ${Spacing.scale[2.5]};
    height: ${Spacing.scale[2.5]};
    transform: ${p => (p.expanded ? "rotate(-180deg)" : "rotate(0deg)")};
  }

  @media ${BREAKPOINTS.desktop} {
    border: 1px solid ${Colors.gray[600]};
    border-radius: ${Spacing.scale[0.5]};
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2px;

    > svg {
      transition: all 0.2s ease;
      width: ${Spacing.scale[2]};
      height: ${Spacing.scale[2]};
    }
  }
`;

export const GroupsList = styled("ul")<{ expanded?: boolean }>`
  margin-left: ${Spacing.scale[2]};
  color: ${Colors.white};

  @media ${BREAKPOINTS.desktop} {
    margin-left: 0;
    color: inherit;
  }
`;

export const ListItem = styled("li")<{ selected?: boolean; expanded?: boolean }>`
  cursor: pointer;
  line-height: 1.6;
  font-weight: 400;
  margin: ${Spacing.scale[1.5]} 0;
  position: relative;

  &:hover {
    color: ${Colors.white};
  }

  &:last-of-type {
    margin-bottom: ${Spacing.scale[0.5]};
  }

  ${p =>
    !p.expanded &&
    css`
      display: none;
    `}
  ${p =>
    p.selected &&
    css`
      color: ${Colors.white};
      &:before {
        content: "";
        width: 4px;
        height: 100%;
        position: absolute;
        background: ${Colors.brandCyan};
        left: -${Spacing.scale[2]};
      }
    `}
  @media ${BREAKPOINTS.desktop} {
    margin: ${Spacing.scale[1]} 0 0;
  }
`;

export const Loader = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.scale[5]};
`;

export const Logo = styled(GeminiIcon)`
  width: 92px;
  height: 92px;
  margin-bottom: ${Spacing.scale[5]};

  @media ${BREAKPOINTS.tabletUp} {
    width: 200px;
    height: 200px;
    margin-bottom: ${Spacing.scale[11]};
  }
`;

export const Title = styled("div")`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: ${Spacing.scale[3]};
  text-align: center;

  @media ${BREAKPOINTS.tabletUp} {
    font-size: 36px;
  }
`;

export const SubTitle = styled("div")`
  font-size: ${Spacing.scale[2]};
  line-height: 1.5;
  text-align: center;
`;
