import React from "react";
import { Money } from "@gemini-ui/components/Money";
import { GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { Flex, Spacing, Text } from "@gemini-ui/design-system";
import { ListItemProps } from "@gemini-ui/design-system/ListItem/constants";
import { TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import { QUOTE_LOADING_PLACEHOLDER_TEXT } from "@gemini-ui/pages/Earn/Deposit/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { formatApyText } from "@gemini-ui/pages/Earn/utils";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

enum SummaryRow {
  ESTIMATED_YIELD = "EstimatedYieldPct",
  TOTAL_CRYPTO = "TotalCrypto",
  TOTAL_NOTIONAL_CRYPTO = "TotalNotionalCrypto",
  TOTAL_VALIDATORS = "Validators",
  TRANSFER_DESTINATION = "TransferDestination",
  TRANSFER_SOURCE = "TransferSource",
}

export const buildTransferSummaryViews = ({
  intl,
  provider,
  transferQuote,
}: {
  intl: IntlShape;
  provider: InterestProvider;
  transferQuote: TransferQuote;
}): Record<SummaryRow, { override?: React.ReactNode } & ListItemProps> => ({
  [SummaryRow.TRANSFER_DESTINATION]: {
    children: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryTransferToLabel} size="sm">
        {intl.formatMessage({ defaultMessage: "To" })}
      </Text.Body>
    ),
    right: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryTransferToValue} size="sm">
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "{providerType, select, Staking {Staking} PrivateStaking {Staking} other {Grow}} balance",
          }),
          { providerType: provider?.providerType }
        )}
      </Text.Body>
    ),
  },
  [SummaryRow.TRANSFER_SOURCE]: {
    children: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryTransferFromLabel} size="sm">
        {intl.formatMessage({ defaultMessage: "From" })}
      </Text.Body>
    ),
    right: (
      <Flex flexDirection="row" alignItems="center" gap={Spacing.scale[1]}>
        <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryTransferFromValue} size="sm">
          {intl.formatMessage({
            defaultMessage: "Trading balance",
          })}
        </Text.Body>
      </Flex>
    ),
  },
  [SummaryRow.ESTIMATED_YIELD]: {
    // TODO: Uncomment to show tiered rates breakdown.
    // override: (
    //   <RateDetailsSummary
    //     currency={transferQuote?.amount?.currency}
    //     interestTiers={provider?.annualInterestYieldTiers}
    //     isAwaitingQuote={false}
    //     quoteInterestRate={provider?.annualInterestYieldTiers?.[0]?.yield}
    //   />
    // ),
    children: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryEstimatedYieldLabel} size="sm">
        {intl.formatMessage({ defaultMessage: "APR" })}
      </Text.Body>
    ),
    right: !Boolean(transferQuote) ? (
      QUOTE_LOADING_PLACEHOLDER_TEXT
    ) : (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryEstimatedYieldValue} size="sm">
        {formatApyText({
          intl,
          annualInterestYieldTiers: transferQuote?.annualInterestYieldTiers,
        })}
      </Text.Body>
    ),
  },
  [SummaryRow.TOTAL_VALIDATORS]: {
    children: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryValidatorsLabel} size="sm">
        {intl.formatMessage({ defaultMessage: "Validators" })}
      </Text.Body>
    ),
    right: !Boolean(transferQuote) ? (
      QUOTE_LOADING_PLACEHOLDER_TEXT
    ) : (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryValidatorsValue} size="sm">
        {transferQuote?.validatorsResponse?.numberOfValidators}
      </Text.Body>
    ),
  },
  [SummaryRow.TOTAL_CRYPTO]: {
    children: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryTotalLabel} size="sm" bold>
        {intl.formatMessage({ defaultMessage: "Total" })}
      </Text.Body>
    ),
    right: !Boolean(transferQuote) ? (
      QUOTE_LOADING_PLACEHOLDER_TEXT
    ) : (
      <Text.Body size="sm" bold>
        <Money data-testid={testIds.deposit.reviewDeposit.summaryTotalValueCrypto} {...transferQuote?.amount} />
      </Text.Body>
    ),
  },
  [SummaryRow.TOTAL_NOTIONAL_CRYPTO]: {
    children: (
      <Text.Body data-testid={testIds.deposit.reviewDeposit.summaryTotalLabel} size="sm" bold>
        {intl.formatMessage({ defaultMessage: "Total" })}
      </Text.Body>
    ),
    right: !Boolean(transferQuote) ? (
      QUOTE_LOADING_PLACEHOLDER_TEXT
    ) : (
      <Flex flexDirection="column" alignItems="end">
        <Text.Body size="sm" bold>
          <Money
            data-testid={testIds.deposit.reviewDeposit.summaryTotalValueFiat}
            {...transferQuote?.notionalAmount}
            hideTrailingSign
          />
        </Text.Body>
        <Text.Body size="xs" color="#676868">
          <Money data-testid={testIds.deposit.reviewDeposit.summaryTotalValueCrypto} {...transferQuote?.amount} />
        </Text.Body>
      </Flex>
    ),
  },
});

const sharedStakingSummaryRows = [
  SummaryRow.TRANSFER_DESTINATION,
  SummaryRow.TRANSFER_SOURCE,
  SummaryRow.ESTIMATED_YIELD,
];

export const renderMap = {
  [GrowProviderType.POOLED_STAKING]: [...sharedStakingSummaryRows, SummaryRow.TOTAL_NOTIONAL_CRYPTO],
  [GrowProviderType.PRIVATE_STAKING]: [
    ...sharedStakingSummaryRows,
    SummaryRow.TOTAL_VALIDATORS,
    SummaryRow.TOTAL_CRYPTO,
  ],
};
