import React from "react";
import { Text } from "@gemini-ui/design-system";
import { BcoloTransferState, TransferMechanismName } from "@gemini-ui/pages/transfers/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

enum TransmissionPermittedError {
  MissingAuthorization = "MissingAuthorization",
  Unknown = "Unknown",
}

enum TransferCreateReject {
  BankApiUnauthorized = "BankApiUnauthorized",
  BankApiInvalid2FA = "BankApiInvalid2FA",
  InvalidUserData = "InvalidUserData",
  BankApiUnavailable = "BankApiUnavailable",
}

type TransferError = TransmissionPermittedError | TransferCreateReject;

export const TRANSFER_DIRECTION = {
  deposit: "deposit",
  withdrawal: "withdrawal",
} as const;

export const mapGeneralTransferErrors = (
  errorMessage,
  direction: keyof typeof TRANSFER_DIRECTION
): [TransferError, string] => {
  switch (errorMessage) {
    case "TransmissionPermittedError.MissingAuthorization":
    case TransmissionPermittedError.MissingAuthorization:
      // There is no session token, the user needs to login.
      return [TransmissionPermittedError.MissingAuthorization, `The ${direction} could not be completed.`];
    case "TransferCreateReject.BankApiUnauthorized":
    case TransferCreateReject.BankApiUnauthorized:
      // The session token is invalid/expired, the user needs login again
      return [TransferCreateReject.BankApiUnauthorized, `The ${direction} could not be completed.`];
    case "TransferCreateReject.BankApiInvalid2FA":
    case TransferCreateReject.BankApiInvalid2FA:
      // The 2fa code is invalid (deposit only). The transaction was rejected but the user can retry if they like
      return [TransferCreateReject.BankApiInvalid2FA, `The ${direction} could not be completed. Please try again.`];
    default:
      return [TransmissionPermittedError.Unknown, errorMessage];
  }
};

const BcoloSupportLink = ({ direction }: { direction: keyof typeof TRANSFER_DIRECTION }) => {
  const { intl } = useIntl();

  return (
    <React.Fragment>
      {intl.formatMessage(
        defineMessage({
          defaultMessage:
            "The {direction} could not be completed. Please check {direction} details or try again. If you are encountering this error frequently, see <TextLink>FAQ for Bancolombia accounts.</TextLink>",
        }),
        {
          direction,
          TextLink: (v: React.ReactNode) => (
            <Text.Link href="https://www.bancolombia.com/centro-de-ayuda/preguntas-frecuentes/depositar-dinero-en-cuenta-gemini-con-bancolombia">
              {v}
            </Text.Link>
          ),
        }
      )}
    </React.Fragment>
  );
};

const mapBcoloErrors = (
  [transmissionError, errorMessage]: [TransferError, React.ReactNode],
  direction: keyof typeof TRANSFER_DIRECTION
): [TransferError, React.ReactNode] => {
  switch (errorMessage) {
    case "TransferCreateReject.InvalidUserData":
    case TransferCreateReject.InvalidUserData:
      return [TransferCreateReject.InvalidUserData, <BcoloSupportLink direction={direction} />];
    case "TransferCreateReject.BankApiUnavailable":
    case TransferCreateReject.BankApiUnavailable:
      // There was a problem communicating with Bancolombia. The transaction is pending support resolution
      return [TransferCreateReject.BankApiUnavailable, <BcoloSupportLink direction={direction} />];
    default:
      return [transmissionError, errorMessage];
  }
};

const handleBcoloError = (
  [transmissionError, errorMessage]: [TransferError, string],
  direction: keyof typeof TRANSFER_DIRECTION
) => {
  const [bcoloTransmissionError, bcoloErrorMessage] = mapBcoloErrors([transmissionError, errorMessage], direction);

  return {
    errorMessage: bcoloErrorMessage,
    bcoloTransferState:
      bcoloTransmissionError === TransferCreateReject.BankApiUnauthorized ||
      bcoloTransmissionError === TransmissionPermittedError.MissingAuthorization
        ? BcoloTransferState.AUTH_ERROR
        : BcoloTransferState.NONE,
  };
};

export const handleTransferError = (message, direction: keyof typeof TRANSFER_DIRECTION, transferMechanismName) => {
  const [transmissionError, errorMessage] = mapGeneralTransferErrors(message, direction);

  return {
    errorMessage,
    bcoloTransferState: BcoloTransferState.NONE,
    ...(transferMechanismName === TransferMechanismName.BcoloTransfer &&
      handleBcoloError([transmissionError, errorMessage], direction)),
  };
};
