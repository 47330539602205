import Cookies from "js-cookie";

type CookieSettings = {
  hasDismissed?: boolean;
  allowAnalytics?: boolean;
  allowAdvertising?: boolean;
  isSuppressed?: boolean;
  isCcpaEnabled?: boolean;
};

const COOKIE_SETTINGS_COOKIE_NAME = "cookieSettings";
const CCPA_ENABLED_CROSS_DOMAIN_COOKIE = "ccpa_enabled";

const defaultCookies = {
  hasDismissed: false,
  allowAnalytics: true,
  isCcpaEnabled: false,
};

export const getCcpaConfigForLoggedOut = () => {
  return Cookies.get(CCPA_ENABLED_CROSS_DOMAIN_COOKIE) === "true" ? true : false;
};

export const getCookieSettings = (): CookieSettings => {
  const cookie = Cookies.get(COOKIE_SETTINGS_COOKIE_NAME);
  return cookie ? JSON.parse(cookie) : defaultCookies;
};

export const saveCookieSettings = (newCookieSettings: CookieSettings) => {
  Cookies.set(COOKIE_SETTINGS_COOKIE_NAME, JSON.stringify(newCookieSettings), { domain: window.location.hostname });
};

export const saveGTMConsentKey = (cookieValue: Boolean) => {
  const GOOGLE_TAG_MANAGER_CONSENT_COOKIE_NAME = "gtm_consent";
  Cookies.set(GOOGLE_TAG_MANAGER_CONSENT_COOKIE_NAME, String(cookieValue), { domain: window.location.hostname });
};
