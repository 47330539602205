import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { OrderType } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/constants";
import {
  BuyingFrequency,
  OrderQuote,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { DepositLimits } from "@gemini-ui/services/transfer/types";
import { DebitCardType, PaymentMethodDataType } from "@gemini-ui/transformers/PaymentMethods";

export interface SelectPaymentMethodProps {
  onClose: () => void;
  onBack?: () => void;
  onAddPaymentMethod: () => void;
  onPaymentMethodUpdate: (paymentMethod?: TradePaymentMethodType) => void;
  balances: CurrencyBalances;
  defaultFiat: CurrencyShortNameFiat;
  recurringOrderViaDebitCardEnabled: boolean;
  orderQuote: OrderQuote;
  frequency?: BuyingFrequency;
  handleVerifyDebitLink: (cardProps: DebitCardType) => void;
  isNonAdvanced?: boolean;
  orderType?: OrderType | null;
  paymentMethods: PaymentMethodDataType[];
  limits: DepositLimits;
}

export const DAI_ERROR = "Debit cards cannot be used to purchase DAI.";
