/* tslint:disable */
/* eslint-disable */
/**
 * Commission Referrals API Specification
 * Commission Referrals API Specification  **Documents**: - [Technical Design Document](https://docs.google.com/document/d/10CxrjTvQOXdhWM4GSImSa5aGkSz1gIPzH55r7Z10XD8/edit) - [Figma Design](https://www.figma.com/file/8JwcQjDlWFqtUOFd6ErvZK/Commission-Referrals?type=design&node-id=361-37388&mode=design&t=tLUTJyFK5XCJrgdG-0)  **NOTE**: The following APIs from previous iteration remains. This API spec is for new APIs targetted for Commission Referrals. - `GET /eligible` - `PUT /payout/currency` - `GET /referral/code`
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

// Custom imports
import { MoneyProps } from '@gemini-common/scripts/Money/constants';
import { CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";

/**
 * 
 * @export
 * @interface ActiveOrCompletedOrExpiredReferrals
 */
export interface ActiveOrCompletedOrExpiredReferrals {
    /**
     * 
     * @type {Array<ActiveOrCompletedOrExpiredReferralsItem>}
     * @memberof ActiveOrCompletedOrExpiredReferrals
     */
    'results'?: Array<ActiveOrCompletedOrExpiredReferralsItem>;
}
/**
 * 
 * @export
 * @interface ActiveOrCompletedOrExpiredReferralsItem
 */
export interface ActiveOrCompletedOrExpiredReferralsItem {
    /**
     * 
     * @type {string}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'isDirect'?: boolean;
    /**
     * 
     * @type {ReferralStatus}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'status'?: ReferralStatus;
    /**
     * 
     * @type {number}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {ReferralTier}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'tier'?: ReferralTier;
    /**
     * 
     * @type {ActiveOrCompletedOrExpiredReferralsItemReward}
     * @memberof ActiveOrCompletedOrExpiredReferralsItem
     */
    'reward'?: ActiveOrCompletedOrExpiredReferralsItemReward;
}


/**
 * 
 * @export
 * @interface ActiveOrCompletedOrExpiredReferralsItemReward
 */
export interface ActiveOrCompletedOrExpiredReferralsItemReward {
    /**
     * 
     * @type {ActiveOrCompletedOrExpiredReferralsItemRewardPaid}
     * @memberof ActiveOrCompletedOrExpiredReferralsItemReward
     */
    'paid'?: ActiveOrCompletedOrExpiredReferralsItemRewardPaid | null;
    /**
     * 
     * @type {PayoutType}
     * @memberof ActiveOrCompletedOrExpiredReferralsItemReward
     */
    'payoutType'?: PayoutType;
}


/**
 * 
 * @export
 * @interface ActiveOrCompletedOrExpiredReferralsItemRewardPaid
 */
export interface ActiveOrCompletedOrExpiredReferralsItemRewardPaid {
    /**
     * 
     * @type {DetailedAmount}
     * @memberof ActiveOrCompletedOrExpiredReferralsItemRewardPaid
     */
    'amount'?: DetailedAmount | null;
}
/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {CurrencyShortNameCrypto}
     * @memberof Amount
     */
    'currency'?: CurrencyShortNameCrypto;
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommissionType = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type CommissionType = typeof CommissionType[keyof typeof CommissionType];


/**
 * 
 * @export
 * @interface DetailedAmount
 */
export interface DetailedAmount {
    /**
     * 
     * @type {CurrencyShortNameFiat}
     * @memberof DetailedAmount
     */
    'notionalCurrency'?: CurrencyShortNameFiat;
    /**
     * 
     * @type {string}
     * @memberof DetailedAmount
     */
    'notionalValue'?: string;
    /**
     * 
     * @type {CurrencyShortNameCrypto}
     * @memberof DetailedAmount
     */
    'rewardCurrency'?: CurrencyShortNameCrypto;
    /**
     * 
     * @type {string}
     * @memberof DetailedAmount
     */
    'rewardValue'?: string;
}
/**
 * 
 * @export
 * @interface EInternalServerError
 */
export interface EInternalServerError {
    /**
     * 
     * @type {string}
     * @memberof EInternalServerError
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof EInternalServerError
     */
    'data'?: string;
}
/**
 * @type GetRewardsHistoryV2200Response
 * @export
 */
export type GetRewardsHistoryV2200Response = ActiveOrCompletedOrExpiredReferrals | PendingReferralOrInProgressReferral;

/**
 * 
 * @export
 * @enum {string}
 */

export const PayoutType = {
    ReferralBonus: 'REFERRAL BONUS',
    ReferralCommission: 'REFERRAL COMMISSION',
    Commission: 'COMMISSION'
} as const;

export type PayoutType = typeof PayoutType[keyof typeof PayoutType];


/**
 * 
 * @export
 * @interface PendingReferralOrInProgressReferral
 */
export interface PendingReferralOrInProgressReferral {
    /**
     * 
     * @type {Array<PendingReferralOrInProgressReferralItem>}
     * @memberof PendingReferralOrInProgressReferral
     */
    'results'?: Array<PendingReferralOrInProgressReferralItem>;
}
/**
 * 
 * @export
 * @interface PendingReferralOrInProgressReferralItem
 */
export interface PendingReferralOrInProgressReferralItem {
    /**
     * 
     * @type {string}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'isDirect'?: boolean;
    /**
     * 
     * @type {ReferralStatus}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'status'?: ReferralStatus;
    /**
     * 
     * @type {number}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {ReferralTier}
     * @memberof PendingReferralOrInProgressReferralItem
     */
    'tier'?: ReferralTier;
}


/**
 * 
 * @export
 * @interface PutReferralCodeV2Request
 */
export interface PutReferralCodeV2Request {
    /**
     * 
     * @type {string}
     * @memberof PutReferralCodeV2Request
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutReferralCodeV2Request
     */
    'label': string;
    /**
     * 
     * @type {PutReferralCodeV2RequestPhoneNumber}
     * @memberof PutReferralCodeV2Request
     */
    'phoneNumber'?: PutReferralCodeV2RequestPhoneNumber;
}
/**
 * 
 * @export
 * @interface PutReferralCodeV2RequestPhoneNumber
 */
export interface PutReferralCodeV2RequestPhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PutReferralCodeV2RequestPhoneNumber
     */
    'intlPrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutReferralCodeV2RequestPhoneNumber
     */
    'number'?: string;
}
/**
 * 
 * @export
 * @interface ReferralCodeResponse
 */
export interface ReferralCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof ReferralCodeResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralCodeResponse
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @interface ReferralDetails
 */
export interface ReferralDetails {
    /**
     * 
     * @type {string}
     * @memberof ReferralDetails
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralDetails
     */
    'isDirect'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralDetails
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralDetails
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {ReferralStatus}
     * @memberof ReferralDetails
     */
    'status'?: ReferralStatus;
    /**
     * 
     * @type {ReferralTier}
     * @memberof ReferralDetails
     */
    'tier'?: ReferralTier | null;
    /**
     * 
     * @type {MoneyProps}
     * @memberof ReferralDetails
     */
    'tradeProgress'?: MoneyProps | null;
    /**
     * 
     * @type {ReferralDetailsTotalEarned}
     * @memberof ReferralDetails
     */
    'totalEarned'?: ReferralDetailsTotalEarned | null;
}


/**
 * 
 * @export
 * @interface ReferralDetailsTotalEarned
 */
export interface ReferralDetailsTotalEarned {
    /**
     * 
     * @type {MoneyProps}
     * @memberof ReferralDetailsTotalEarned
     */
    'amount'?: MoneyProps | null;
    /**
     * 
     * @type {ReferralDetailsTotalEarnedStandard}
     * @memberof ReferralDetailsTotalEarned
     */
    'standard'?: ReferralDetailsTotalEarnedStandard;
    /**
     * 
     * @type {ReferralDetailsTotalEarnedCommission}
     * @memberof ReferralDetailsTotalEarned
     */
    'commission'?: ReferralDetailsTotalEarnedCommission | null;
}
/**
 * 
 * @export
 * @interface ReferralDetailsTotalEarnedCommission
 */
export interface ReferralDetailsTotalEarnedCommission {
    /**
     * 
     * @type {MoneyProps}
     * @memberof ReferralDetailsTotalEarnedCommission
     */
    'amount'?: MoneyProps | null;
    /**
     * 
     * @type {Array<ReferralDetailsTotalEarnedCommissionItemsInner>}
     * @memberof ReferralDetailsTotalEarnedCommission
     */
    'items'?: Array<ReferralDetailsTotalEarnedCommissionItemsInner> | null;
}
/**
 * 
 * @export
 * @interface ReferralDetailsTotalEarnedCommissionItemsInner
 */
export interface ReferralDetailsTotalEarnedCommissionItemsInner {
    /**
     * 
     * @type {PayoutType}
     * @memberof ReferralDetailsTotalEarnedCommissionItemsInner
     */
    'payoutType'?: PayoutType;
    /**
     * 
     * @type {CurrencyShortNameFiat}
     * @memberof ReferralDetailsTotalEarnedCommissionItemsInner
     */
    'notionalCurrency'?: CurrencyShortNameFiat;
    /**
     * 
     * @type {string}
     * @memberof ReferralDetailsTotalEarnedCommissionItemsInner
     */
    'notionalValue'?: string;
    /**
     * 
     * @type {CurrencyShortNameCrypto}
     * @memberof ReferralDetailsTotalEarnedCommissionItemsInner
     */
    'rewardCurrency'?: CurrencyShortNameCrypto;
    /**
     * 
     * @type {string}
     * @memberof ReferralDetailsTotalEarnedCommissionItemsInner
     */
    'rewardValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReferralDetailsTotalEarnedCommissionItemsInner
     */
    'timestamp'?: number;
}


/**
 * 
 * @export
 * @interface ReferralDetailsTotalEarnedStandard
 */
export interface ReferralDetailsTotalEarnedStandard {
    /**
     * 
     * @type {CurrencyShortNameFiat}
     * @memberof ReferralDetailsTotalEarnedStandard
     */
    'notionalCurrency'?: CurrencyShortNameFiat;
    /**
     * 
     * @type {string}
     * @memberof ReferralDetailsTotalEarnedStandard
     */
    'notionalValue'?: string;
    /**
     * 
     * @type {CurrencyShortNameCrypto}
     * @memberof ReferralDetailsTotalEarnedStandard
     */
    'rewardCurrency'?: CurrencyShortNameCrypto;
    /**
     * 
     * @type {string}
     * @memberof ReferralDetailsTotalEarnedStandard
     */
    'rewardValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReferralDetailsTotalEarnedStandard
     */
    'timestamp'?: number;
}
/**
 * 
 * @export
 * @interface ReferralDetailsTotalEarnedStandardAllOf
 */
export interface ReferralDetailsTotalEarnedStandardAllOf {
    /**
     * 
     * @type {number}
     * @memberof ReferralDetailsTotalEarnedStandardAllOf
     */
    'timestamp'?: number;
}
/**
 * 
 * @export
 * @interface ReferralProgress
 */
export interface ReferralProgress {
    /**
     * 
     * @type {number}
     * @memberof ReferralProgress
     */
    'expiration'?: number;
    /**
     * 
     * @type {MoneyProps}
     * @memberof ReferralProgress
     */
    'tradeProgress'?: MoneyProps | null;
    /**
     * 
     * @type {ReferralTier}
     * @memberof ReferralProgress
     */
    'tier'?: ReferralTier | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReferralStatus = {
    PendingStart: 'PENDING_START',
    InProgress: 'IN_PROGRESS',
    Active: 'ACTIVE',
    Completed: 'COMPLETED',
    Expired: 'EXPIRED'
} as const;

export type ReferralStatus = typeof ReferralStatus[keyof typeof ReferralStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReferralTier = {
    Standard: 'standard',
    Tier1: 'tier1',
    Tier2: 'tier2'
} as const;

export type ReferralTier = typeof ReferralTier[keyof typeof ReferralTier];


/**
 * 
 * @export
 * @interface RewardTransaction
 */
export interface RewardTransaction {
    /**
     * 
     * @type {string}
     * @memberof RewardTransaction
     */
    'id'?: string;
    /**
     * 
     * @type {DetailedAmount}
     * @memberof RewardTransaction
     */
    'amount'?: DetailedAmount | null;
    /**
     * 
     * @type {number}
     * @memberof RewardTransaction
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof RewardTransaction
     */
    'type'?: RewardTransactionTypeEnum;
}

export const RewardTransactionTypeEnum = {
    Bonus: 'BONUS',
    Commission: 'COMMISSION'
} as const;

export type RewardTransactionTypeEnum = typeof RewardTransactionTypeEnum[keyof typeof RewardTransactionTypeEnum];

/**
 * 
 * @export
 * @interface RewardsSummary
 */
export interface RewardsSummary {
    /**
     * 
     * @type {MoneyProps}
     * @memberof RewardsSummary
     */
    'totalRewardsEarned'?: MoneyProps | null;
    /**
     * 
     * @type {RewardsSummaryActiveReferralsEarning}
     * @memberof RewardsSummary
     */
    'activeReferralsEarning'?: RewardsSummaryActiveReferralsEarning;
    /**
     * 
     * @type {RewardsSummaryReferrals}
     * @memberof RewardsSummary
     */
    'referrals'?: RewardsSummaryReferrals;
    /**
     * 
     * @type {CurrencyShortNameCrypto}
     * @memberof RewardsSummary
     */
    'currency'?: CurrencyShortNameCrypto;
    /**
     * 
     * @type {Array<string>}
     * @memberof RewardsSummary
     */
    'eligibleCurrencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RewardsSummaryActiveReferralsEarning
 */
export interface RewardsSummaryActiveReferralsEarning {
    /**
     * 
     * @type {MoneyProps}
     * @memberof RewardsSummaryActiveReferralsEarning
     */
    'amount'?: MoneyProps | null;
    /**
     * 
     * @type {number}
     * @memberof RewardsSummaryActiveReferralsEarning
     */
    'totalUsers'?: number;
}
/**
 * 
 * @export
 * @interface RewardsSummaryReferrals
 */
export interface RewardsSummaryReferrals {
    /**
     * 
     * @type {number}
     * @memberof RewardsSummaryReferrals
     */
    'completed'?: number;
    /**
     * 
     * @type {number}
     * @memberof RewardsSummaryReferrals
     */
    'inProgress'?: number;
}
/**
 * 
 * @export
 * @interface TierProperties
 */
export interface TierProperties {
    /**
     * 
     * @type {ReferralTier}
     * @memberof TierProperties
     */
    'name'?: ReferralTier;
    /**
     * 
     * @type {TierPropertiesThresholds}
     * @memberof TierProperties
     */
    'thresholds'?: TierPropertiesThresholds;
    /**
     * 
     * @type {TierPropertiesCommission}
     * @memberof TierProperties
     */
    'commission'?: TierPropertiesCommission;
}


/**
 * 
 * @export
 * @interface TierPropertiesCommission
 */
export interface TierPropertiesCommission {
    /**
     * 
     * @type {number}
     * @memberof TierPropertiesCommission
     */
    'value'?: number;
    /**
     * 
     * @type {CommissionType}
     * @memberof TierPropertiesCommission
     */
    'type'?: CommissionType;
}


/**
 * 
 * @export
 * @interface TierPropertiesThresholds
 */
export interface TierPropertiesThresholds {
    /**
     * 
     * @type {number}
     * @memberof TierPropertiesThresholds
     */
    'minimum'?: number;
    /**
     * 
     * @type {number}
     * @memberof TierPropertiesThresholds
     */
    'maximum'?: number | null;
}
/**
 * 
 * @export
 * @interface TiersResponse
 */
export interface TiersResponse {
    /**
     * 
     * @type {Array<TierProperties>}
     * @memberof TiersResponse
     */
    'tiers'?: Array<TierProperties>;
}

/**
 * CommissionReferralsApi - axios parameter creator
 * @export
 */
export const CommissionReferralsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves referral details for a specific referral ID.
         * @summary Get Referral Details
         * @param {string} id The ID of the referral.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReferralById', 'id', id)
            const localVarPath = `/rewards/referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get referral code
         * @param {string} [id] Tag of the referral.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCodeV2: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/rewards/referral/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves referral progress details of the referee
         * @summary Retrieves referral progress details of the referee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgressV2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/rewards/referral/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves rewards summary information
         * @summary Retrieves rewards summary information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralSummaryV2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/rewards/referral/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get referral tiers of the referee
         * @param {string} [id] Optional referral ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralTiers: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/referral/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves rewards transactions of the user
         * @summary Retrieves reward transactions of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A history of rewards for a user
         * @summary A history of rewards for a user
         * @param {Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>} [status] Filter by referral status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardsHistoryV2: async (status?: Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/rewards/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the visibility of a referral
         * @summary Updates the visibility of a referral
         * @param {string} id The ID of the referral.
         * @param {boolean} hidden Indicates whether the referral should be hidden or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideRewardV2: async (id: string, hidden: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hideRewardV2', 'id', id)
            // verify required parameter 'hidden' is not null or undefined
            assertParamExists('hideRewardV2', 'hidden', hidden)
            const localVarPath = `/v2/rewards/hide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update referral code
         * @param {PutReferralCodeV2Request} [putReferralCodeV2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReferralCodeV2: async (putReferralCodeV2Request?: PutReferralCodeV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/rewards/referral/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putReferralCodeV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommissionReferralsApi - functional programming interface
 * @export
 */
export const CommissionReferralsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommissionReferralsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves referral details for a specific referral ID.
         * @summary Get Referral Details
         * @param {string} id The ID of the referral.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get referral code
         * @param {string} [id] Tag of the referral.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralCodeV2(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralCodeV2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves referral progress details of the referee
         * @summary Retrieves referral progress details of the referee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralProgressV2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralProgress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralProgressV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves rewards summary information
         * @summary Retrieves rewards summary information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralSummaryV2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardsSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralSummaryV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get referral tiers of the referee
         * @param {string} [id] Optional referral ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralTiers(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralTiers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves rewards transactions of the user
         * @summary Retrieves reward transactions of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardTransaction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRewardTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A history of rewards for a user
         * @summary A history of rewards for a user
         * @param {Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>} [status] Filter by referral status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRewardsHistoryV2(status?: Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRewardsHistoryV2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRewardsHistoryV2(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the visibility of a referral
         * @summary Updates the visibility of a referral
         * @param {string} id The ID of the referral.
         * @param {boolean} hidden Indicates whether the referral should be hidden or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideRewardV2(id: string, hidden: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideRewardV2(id, hidden, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update referral code
         * @param {PutReferralCodeV2Request} [putReferralCodeV2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReferralCodeV2(putReferralCodeV2Request?: PutReferralCodeV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReferralCodeV2(putReferralCodeV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommissionReferralsApi - factory interface
 * @export
 */
export const CommissionReferralsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommissionReferralsApiFp(configuration)
    return {
        /**
         * Retrieves referral details for a specific referral ID.
         * @summary Get Referral Details
         * @param {string} id The ID of the referral.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralById(id: string, options?: any): AxiosPromise<ReferralDetails> {
            return localVarFp.getReferralById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get referral code
         * @param {string} [id] Tag of the referral.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCodeV2(id?: string, options?: any): AxiosPromise<ReferralCodeResponse> {
            return localVarFp.getReferralCodeV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves referral progress details of the referee
         * @summary Retrieves referral progress details of the referee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgressV2(options?: any): AxiosPromise<ReferralProgress> {
            return localVarFp.getReferralProgressV2(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves rewards summary information
         * @summary Retrieves rewards summary information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralSummaryV2(options?: any): AxiosPromise<RewardsSummary> {
            return localVarFp.getReferralSummaryV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get referral tiers of the referee
         * @param {string} [id] Optional referral ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralTiers(id?: string, options?: any): AxiosPromise<TiersResponse> {
            return localVarFp.getReferralTiers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves rewards transactions of the user
         * @summary Retrieves reward transactions of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardTransactions(options?: any): AxiosPromise<Array<RewardTransaction>> {
            return localVarFp.getRewardTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * A history of rewards for a user
         * @summary A history of rewards for a user
         * @param {Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>} [status] Filter by referral status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRewardsHistoryV2(status?: Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>, options?: any): AxiosPromise<GetRewardsHistoryV2200Response> {
            return localVarFp.getRewardsHistoryV2(status, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the visibility of a referral
         * @summary Updates the visibility of a referral
         * @param {string} id The ID of the referral.
         * @param {boolean} hidden Indicates whether the referral should be hidden or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideRewardV2(id: string, hidden: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.hideRewardV2(id, hidden, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update referral code
         * @param {PutReferralCodeV2Request} [putReferralCodeV2Request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReferralCodeV2(putReferralCodeV2Request?: PutReferralCodeV2Request, options?: any): AxiosPromise<ReferralCodeResponse> {
            return localVarFp.putReferralCodeV2(putReferralCodeV2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommissionReferralsApi - object-oriented interface
 * @export
 * @class CommissionReferralsApi
 * @extends {BaseAPI}
 */
export class CommissionReferralsApi extends BaseAPI {
    /**
     * Retrieves referral details for a specific referral ID.
     * @summary Get Referral Details
     * @param {string} id The ID of the referral.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getReferralById(id: string, options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getReferralById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get referral code
     * @param {string} [id] Tag of the referral.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getReferralCodeV2(id?: string, options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getReferralCodeV2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves referral progress details of the referee
     * @summary Retrieves referral progress details of the referee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getReferralProgressV2(options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getReferralProgressV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves rewards summary information
     * @summary Retrieves rewards summary information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getReferralSummaryV2(options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getReferralSummaryV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get referral tiers of the referee
     * @param {string} [id] Optional referral ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getReferralTiers(id?: string, options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getReferralTiers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves rewards transactions of the user
     * @summary Retrieves reward transactions of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getRewardTransactions(options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getRewardTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A history of rewards for a user
     * @summary A history of rewards for a user
     * @param {Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>} [status] Filter by referral status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public getRewardsHistoryV2(status?: Array<'PENDING_START' | 'IN_PROGRESS' | 'ACTIVE' | 'COMPLETED' | 'EXPIRED'>, options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).getRewardsHistoryV2(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the visibility of a referral
     * @summary Updates the visibility of a referral
     * @param {string} id The ID of the referral.
     * @param {boolean} hidden Indicates whether the referral should be hidden or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public hideRewardV2(id: string, hidden: boolean, options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).hideRewardV2(id, hidden, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update referral code
     * @param {PutReferralCodeV2Request} [putReferralCodeV2Request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommissionReferralsApi
     */
    public putReferralCodeV2(putReferralCodeV2Request?: PutReferralCodeV2Request, options?: AxiosRequestConfig) {
        return CommissionReferralsApiFp(this.configuration).putReferralCodeV2(putReferralCodeV2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


