import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { shorthandSpacingCss } from "@gemini-ui/design-system/primitives/Spacer";
import { Text } from "@gemini-ui/design-system/Text";
import { ReactComponent as ErrorBang } from "@gemini-ui/images/icons/error-outline.svg";
import { ReactComponent as SuccessCheck } from "@gemini-ui/images/icons/green-success-check.svg";

// For use with React-select, which only takes css objects
export const FORM_ELEMENT_TEXT_STYLES = {
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: "24px",
  color: Colors.gray[800],
  whiteSpace: "nowrap",
} as const;

/** @deprecated  please use the design-systen/Label component instead */
export const formElementTextStyles = css(FORM_ELEMENT_TEXT_STYLES);

/** @deprecated  please use the design-systen/Label component instead */
export const Label = styled.label`
  min-height: 112px;
  width: 100%;
  ${shorthandSpacingCss}
`;

/** @deprecated  please use the design-systen/Message component instead */
const ErrorIcon = styled(ErrorBang)`
  margin-top: 1px;
  margin-right: ${Spacing.scale[0.5]};
  align-self: flex-start;
  flex: none;
`;

export const SuccessIcon = styled(SuccessCheck)`
  width: ${Spacing.scale[2.5]};
  height: ${Spacing.scale[2.5]};
  position: absolute;
  top: ${Spacing.scale[2.5]};
  left: -${Spacing.scale[4]};
`;

/** @deprecated  please use the design-systen/Message component instead */
const Message = styled(Text.Body)`
  display: flex;
  align-items: center;
`;

interface FormElementMessageProps {
  dataTestId: string;
  error: boolean;
  message?: React.ReactNode;
  variant?: "md" | "lg";
  id?: string;
  hideIcon?: boolean;
}

/** @deprecated  please use the design-systen/Message component instead */
export const FormElementMessage = ({
  dataTestId,
  error,
  message,
  variant = "md",
  id,
  hideIcon,
}: FormElementMessageProps) => (
  <Message
    data-testid={`${dataTestId}-message`}
    ml={0.5}
    size="xs"
    color={Boolean(error) ? Colors.red[600] : Colors.gray[600]}
    mt={0.5}
    mb={variant === "md" ? 1 : 0.5}
    as="div"
    id={id}
  >
    {error && !hideIcon && <ErrorIcon />}
    {message}
  </Message>
);

interface FormElementLabelProps {
  disabled?: boolean;
  error?: boolean;
  readOnly?: boolean;
  variant?: "md" | "lg";
}

/** @deprecated  please use the design-systen/Label component instead */
export const FormElementLabel: React.FC<React.PropsWithChildren<FormElementLabelProps>> = ({
  variant = "md",
  error,
  disabled,
  readOnly,
  children,
}) => (
  <Text.Body
    size={variant === "md" ? "xs" : "sm"}
    color={(error && Colors.red[600]) || (disabled && Colors.gray[200]) || (readOnly && Colors.gray[400])}
    mb={variant === "md" ? 1 : 0.5}
    bold
    as="div"
  >
    {children}
  </Text.Body>
);
