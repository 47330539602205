import React from "react";
import { isValid } from "date-fns";
import DayPicker from "react-day-picker";
import YearMonthForm from "@gemini-ui/components/DatePicker/YearMonthForm";

interface Props {
  month?: Date;
  onYearMonthChange: (val: any) => void;
  onDayClick: (day, modifiers) => void;
  onMonthChange?: (month: Date) => void;
  startDate?: Date;
  endDate?: Date;
  selectedRange?: {
    after: Date;
    before: Date;
  };
  highlighted?: {
    start?: Date;
    end?: Date;
  };
  className?: string;
  yearRange?: string[];
}
class Calendar extends React.Component<Props> {
  shouldComponentUpdate(nextProps, nextState) {
    const { month } = nextProps;
    if (month === this.props.month) return false;
    return isValid(month);
  }

  render() {
    const {
      month,
      onYearMonthChange,
      onDayClick,
      onMonthChange,
      startDate,
      endDate,
      selectedRange,
      highlighted,
      className,
      yearRange,
    } = this.props;

    return (
      <DayPicker
        className={className}
        showOutsideDays
        showWeekDays={false}
        month={month}
        fromMonth={startDate}
        toMonth={endDate}
        selectedDays={selectedRange}
        modifiers={highlighted}
        disabledDays={[
          {
            before: startDate,
            after: endDate,
          },
        ]}
        onDayClick={onDayClick}
        onMonthChange={onMonthChange}
        captionElement={({ date, localeUtils }) => {
          return (
            <YearMonthForm date={date} localeUtils={localeUtils} onChange={onYearMonthChange} yearRange={yearRange} />
          );
        }}
      />
    );
  }
}

export default Calendar;
