/* tslint:disable */
/* eslint-disable */
/**
 * Legal Agreements API (Frontend)
 * Spec for generating frontend js clients
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface FinPromRequirements
 */
export interface FinPromRequirements {
    /**
     *
     * @type {boolean}
     * @memberof FinPromRequirements
     */
    'showLeaveJourney'?: boolean;
    /**
     *
     * @type {string}
     * @memberof FinPromRequirements
     */
    'coolingPeriodExpiration'?: string;
    /**
     *
     * @type {boolean}
     * @memberof FinPromRequirements
     */
    'requiresCategorization': boolean;
    /**
     *
     * @type {UkClientAppropriatenessIsRequiredResponse}
     * @memberof FinPromRequirements
     */
    'requiresAppropriateness': UkClientAppropriatenessIsRequiredResponse;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LeaveJourneyType = {
    RiskWarning: 'risk-warning',
    CoolingPeriod: 'cooling-period'
} as const;

export type LeaveJourneyType = typeof LeaveJourneyType[keyof typeof LeaveJourneyType];


/**
 *
 * @export
 * @interface UkClientLeaveJourneyTypeRequest
 */
export interface UkClientLeaveJourneyTypeRequest {
    /**
     *
     * @type {boolean}
     * @memberof UkClientLeaveJourneyTypeRequest
     */
    'proceed': boolean;
    /**
     *
     * @type {LeaveJourneyType}
     * @memberof UkClientLeaveJourneyTypeRequest
     */
    'journeyType': LeaveJourneyType;
}


/**
 *
 * @export
 * @interface UkClientAppropriatenessAnswer
 */
export interface UkClientAppropriatenessAnswer {
    /**
     *
     * @type {number}
     * @memberof UkClientAppropriatenessAnswer
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof UkClientAppropriatenessAnswer
     */
    'answer': string;
}
/**
 *
 * @export
 * @interface UkClientAppropriatenessIsRequiredResponse
 */
export interface UkClientAppropriatenessIsRequiredResponse {
    /**
     *
     * @type {boolean}
     * @memberof UkClientAppropriatenessIsRequiredResponse
     */
    'required': boolean;
    /**
     *
     * @type {string}
     * @memberof UkClientAppropriatenessIsRequiredResponse
     */
    'nextEligibleAt'?: string;
    /**
     *
     * @type {boolean}
     * @memberof UkClientAppropriatenessIsRequiredResponse
     */
    'requiresAttestation': boolean;
}
/**
 *
 * @export
 * @interface UkClientAppropriatenessQuestion
 */
export interface UkClientAppropriatenessQuestion {
    /**
     *
     * @type {number}
     * @memberof UkClientAppropriatenessQuestion
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof UkClientAppropriatenessQuestion
     */
    'question': string;
    /**
     *
     * @type {Array<UkClientAppropriatenessAnswer>}
     * @memberof UkClientAppropriatenessQuestion
     */
    'answers': Array<UkClientAppropriatenessAnswer>;
}
/**
 *
 * @export
 * @interface UkClientAppropriatenessRequest
 */
export interface UkClientAppropriatenessRequest {
    /**
     *
     * @type {number}
     * @memberof UkClientAppropriatenessRequest
     */
    'attemptId': number;
    /**
     * Json serialized object { [questionId]: answerId }
     * @type {string}
     * @memberof UkClientAppropriatenessRequest
     */
    'answers': string;
}
/**
 *
 * @export
 * @interface UkClientAppropriatenessResponse
 */
export interface UkClientAppropriatenessResponse {
    /**
     *
     * @type {boolean}
     * @memberof UkClientAppropriatenessResponse
     */
    'pass': boolean;
}
/**
 *
 * @export
 * @interface UkClientAppropriatenessTest
 */
export interface UkClientAppropriatenessTest {
    /**
     *
     * @type {number}
     * @memberof UkClientAppropriatenessTest
     */
    'attemptId': number;
    /**
     *
     * @type {Array<UkClientAppropriatenessQuestion>}
     * @memberof UkClientAppropriatenessTest
     */
    'questions': Array<UkClientAppropriatenessQuestion>;
}
/**
 *
 * @export
 * @interface UkClientCategorizationStatement
 */
export interface UkClientCategorizationStatement {
    /**
     *
     * @type {number}
     * @memberof UkClientCategorizationStatement
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof UkClientCategorizationStatement
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof UkClientCategorizationStatement
     */
    'createdAt'?: string;
    /**
     *
     * @type {UkClientCategory}
     * @memberof UkClientCategorizationStatement
     */
    'category'?: UkClientCategory;
    /**
     *
     * @type {string}
     * @memberof UkClientCategorizationStatement
     */
    'body'?: string;
}


/**
 *
 * @export
 * @interface UkClientCategorizationUserAttestation
 */
export interface UkClientCategorizationUserAttestation {
    /**
     *
     * @type {number}
     * @memberof UkClientCategorizationUserAttestation
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof UkClientCategorizationUserAttestation
     */
    'createdAt'?: string;
    /**
     *
     * @type {number}
     * @memberof UkClientCategorizationUserAttestation
     */
    'statementId'?: number;
    /**
     *
     * @type {string}
     * @memberof UkClientCategorizationUserAttestation
     */
    'signature'?: string;
    /**
     *
     * @type {object}
     * @memberof UkClientCategorizationUserAttestation
     */
    'response'?: object;
}
/**
 *
 * @export
 * @interface UkClientCategorizationUserAttestationRequest
 */
export interface UkClientCategorizationUserAttestationRequest {
    /**
     *
     * @type {number}
     * @memberof UkClientCategorizationUserAttestationRequest
     */
    'statementId'?: number;
    /**
     *
     * @type {string}
     * @memberof UkClientCategorizationUserAttestationRequest
     */
    'signature'?: string;
    /**
     * Json serialized object
     * @type {string}
     * @memberof UkClientCategorizationUserAttestationRequest
     */
    'response'?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UkClientCategory = {
    Restricted: 'restricted',
    HighNetWorth: 'high-net-worth',
    CertifiedSophisticated: 'certified-sophisticated'
} as const;

export type UkClientCategory = typeof UkClientCategory[keyof typeof UkClientCategory];



/**
 * LegalAgreementsApi - axios parameter creator
 * @export
 */
export const LegalAgreementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attestToAppropriateness: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legal-agreements/appropriateness/attestation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an attestation for a user
         * @param {UkClientCategorizationUserAttestationRequest} ukClientCategorizationUserAttestationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttestation: async (ukClientCategorizationUserAttestationRequest: UkClientCategorizationUserAttestationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ukClientCategorizationUserAttestationRequest' is not null or undefined
            assertParamExists('createAttestation', 'ukClientCategorizationUserAttestationRequest', ukClientCategorizationUserAttestationRequest)
            const localVarPath = `/legal-agreements/attestation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ukClientCategorizationUserAttestationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppropriatenessTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legal-agreements/appropriateness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the latest attestation for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttestation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legal-agreements/attestation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the requirements for UK financial promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinPromRequirements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legal-agreements/finprom-requirements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the latest statement for a given category
         * @param {UkClientCategory} category The name of the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatement: async (category: UkClientCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('getStatement', 'category', category)
            const localVarPath = `/legal-agreements/statement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UkClientAppropriatenessRequest} ukClientAppropriatenessRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAppropriatenessTest: async (ukClientAppropriatenessRequest: UkClientAppropriatenessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ukClientAppropriatenessRequest' is not null or undefined
            assertParamExists('submitAppropriatenessTest', 'ukClientAppropriatenessRequest', ukClientAppropriatenessRequest)
            const localVarPath = `/legal-agreements/appropriateness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ukClientAppropriatenessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UkClientLeaveJourneyTypeRequest} leaveJourneyTypeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJourneyType: async (leaveJourneyTypeRequest: UkClientLeaveJourneyTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaveJourneyTypeRequest' is not null or undefined
            assertParamExists('updateJourneyType', 'leaveJourneyTypeRequest', leaveJourneyTypeRequest)
            const localVarPath = `/legal-agreements/journey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leaveJourneyTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalAgreementsApi - functional programming interface
 * @export
 */
export const LegalAgreementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalAgreementsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attestToAppropriateness(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attestToAppropriateness(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an attestation for a user
         * @param {UkClientCategorizationUserAttestationRequest} ukClientCategorizationUserAttestationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAttestation(ukClientCategorizationUserAttestationRequest: UkClientCategorizationUserAttestationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAttestation(ukClientCategorizationUserAttestationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppropriatenessTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkClientAppropriatenessTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppropriatenessTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the latest attestation for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttestation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkClientCategorizationUserAttestation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttestation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the requirements for UK financial promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinPromRequirements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinPromRequirements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinPromRequirements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the latest statement for a given category
         * @param {UkClientCategory} category The name of the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatement(category: UkClientCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkClientCategorizationStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatement(category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UkClientAppropriatenessRequest} ukClientAppropriatenessRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAppropriatenessTest(ukClientAppropriatenessRequest: UkClientAppropriatenessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UkClientAppropriatenessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitAppropriatenessTest(ukClientAppropriatenessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UkClientLeaveJourneyTypeRequest} leaveJourneyTypeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJourneyType(leaveJourneyTypeRequest: UkClientLeaveJourneyTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJourneyType(leaveJourneyTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalAgreementsApi - factory interface
 * @export
 */
export const LegalAgreementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalAgreementsApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attestToAppropriateness(options?: any): AxiosPromise<void> {
            return localVarFp.attestToAppropriateness(options).then((request) => request(axios, basePath));
        },
        /**
         * Create an attestation for a user
         * @param {UkClientCategorizationUserAttestationRequest} ukClientCategorizationUserAttestationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttestation(ukClientCategorizationUserAttestationRequest: UkClientCategorizationUserAttestationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createAttestation(ukClientCategorizationUserAttestationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppropriatenessTest(options?: any): AxiosPromise<UkClientAppropriatenessTest> {
            return localVarFp.getAppropriatenessTest(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the latest attestation for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttestation(options?: any): AxiosPromise<UkClientCategorizationUserAttestation> {
            return localVarFp.getAttestation(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the requirements for UK financial promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinPromRequirements(options?: any): AxiosPromise<FinPromRequirements> {
            return localVarFp.getFinPromRequirements(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the latest statement for a given category
         * @param {UkClientCategory} category The name of the category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatement(category: UkClientCategory, options?: any): AxiosPromise<UkClientCategorizationStatement> {
            return localVarFp.getStatement(category, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UkClientAppropriatenessRequest} ukClientAppropriatenessRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAppropriatenessTest(ukClientAppropriatenessRequest: UkClientAppropriatenessRequest, options?: any): AxiosPromise<UkClientAppropriatenessResponse> {
            return localVarFp.submitAppropriatenessTest(ukClientAppropriatenessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UkClientLeaveJourneyTypeRequest} leaveJourneyTypeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJourneyType(leaveJourneyTypeRequest: UkClientLeaveJourneyTypeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateJourneyType(leaveJourneyTypeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LegalAgreementsApi - object-oriented interface
 * @export
 * @class LegalAgreementsApi
 * @extends {BaseAPI}
 */
export class LegalAgreementsApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public attestToAppropriateness(options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).attestToAppropriateness(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an attestation for a user
     * @param {UkClientCategorizationUserAttestationRequest} ukClientCategorizationUserAttestationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public createAttestation(ukClientCategorizationUserAttestationRequest: UkClientCategorizationUserAttestationRequest, options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).createAttestation(ukClientCategorizationUserAttestationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public getAppropriatenessTest(options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).getAppropriatenessTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the latest attestation for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public getAttestation(options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).getAttestation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the requirements for UK financial promotions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public getFinPromRequirements(options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).getFinPromRequirements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the latest statement for a given category
     * @param {UkClientCategory} category The name of the category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public getStatement(category: UkClientCategory, options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).getStatement(category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UkClientAppropriatenessRequest} ukClientAppropriatenessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public submitAppropriatenessTest(ukClientAppropriatenessRequest: UkClientAppropriatenessRequest, options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).submitAppropriatenessTest(ukClientAppropriatenessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UkClientLeaveJourneyTypeRequest} leaveJourneyTypeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalAgreementsApi
     */
    public updateJourneyType(leaveJourneyTypeRequest: UkClientLeaveJourneyTypeRequest, options?: AxiosRequestConfig) {
        return LegalAgreementsApiFp(this.configuration).updateJourneyType(leaveJourneyTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


