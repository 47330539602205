import React from "react";
import {
  FundingSourceCard,
  FundingSourceDisplayCardProps,
} from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/FundingSourceCard";
import { LoadingCard } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/LoadingCard";
import { NoFundingSourceCard } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/NoFundingSourceCard";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

export interface FundingSourceSelectorProps extends Partial<FundingSourceDisplayCardProps> {
  isLoading?: boolean;
  onClick?: () => void;
  transactionType: GrowTransactionType;
}

export const FundingSource = ({
  isLoading,
  onClick,
  transactionType,
  value,
  ...details
}: FundingSourceSelectorProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick?.();
  };

  if (isLoading) {
    return <LoadingCard />;
  }

  if (value) {
    return <FundingSourceCard onClick={handleClick} value={value} {...details} />;
  }

  return <NoFundingSourceCard transactionType={transactionType} onClick={handleClick} />;
};
