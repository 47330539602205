import * as React from "react";
import { IconLightningFilled } from "@hubble/icons";
import { useMedia } from "react-use";
import { Badge, Card, Flex, mediaQuery, Spacer, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import {
  AddPaymentMethodInnerContainer,
  AddPaymentMethodTitle,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/styles";
import { useIntl } from "@gemini-ui/utils/intl";

type AddPaymentMethodOptionProps = {
  icon: React.ReactNode;
  title: string;
  isRecommended?: boolean;
  description: React.ReactNode;
  cta: React.ReactNode;
};

export const AddPaymentMethodOption = ({
  icon,
  title,
  isRecommended = false,
  description,
  cta,
}: AddPaymentMethodOptionProps) => {
  const { intl } = useIntl();
  const isTablet = useMedia(mediaQuery.tabletMdUp);

  return (
    <Card mb={2}>
      <Flex>
        <Spacer mr={2}>{icon}</Spacer>
        <AddPaymentMethodInnerContainer
          alignItems="flex-start"
          flexDirection={isTablet ? "row" : "column"}
          justifyContent="space-between"
        >
          <AddPaymentMethodTitle>
            <Text.Body size="sm" bold ml={1.5}>
              {title}
            </Text.Body>
            {isRecommended && (
              <Badge
                status="info"
                icon={<IconLightningFilled />}
                mt={0.5}
                ml={1.5}
                data-testid={testIds.settings.recommendedBadge}
              >
                {intl.formatMessage({ defaultMessage: "Recommended" })}
              </Badge>
            )}
          </AddPaymentMethodTitle>
          {description}
          {cta}
        </AddPaymentMethodInnerContainer>
      </Flex>
    </Card>
  );
};
