export enum QueryParams {
  ADD_PAYMENT_METHOD = "add-payment-method",
  REDIRECT_URL = "redirect",
  CREDIT_PAYMENT_TYPE = "payment-type",
  CREDIT_UPDATE_ADDRESS = "update-address",
  // oauth_state_id query param from Plaid Oauth
  OAUTH_STATE_ID = "oauth_state_id",
  SHOW_INVITE_FLOW = "show-invite-flow",
  FROM = "from",
  SHOW_WIRE_INSTRUCTIONS = "show-wire-instructions",
}
