import styled from "@emotion/styled";
import { TransitionStatus } from "react-transition-group/Transition";
import { border, mediaQuery, motion, Spacing } from "@gemini-ui/design-system/primitives";
import { TOASTER_DURATIONS } from "@gemini-ui/design-system/Toaster/constants";

export const ToasterContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10001;
  position: fixed;
  bottom: ${Spacing.scale[2]};
  left: ${Spacing.scale[2]};

  @media ${mediaQuery.mobileXsDown} {
    right: ${Spacing.scale[2]};
  }
`;

const CONTAINER_TRANSITION_STYLES: { [K in TransitionStatus]?: string } = {
  entering: "max-height: 0;",
  entered: `max-height: ${Spacing.scale[19]};`, // double height of one liner ToastContent and double transition time to achieve safe max-height transition.
};

export const ToastContainer = styled.div<{ transitionStatus: TransitionStatus }>`
  transition: max-height ${TOASTER_DURATIONS.transition * 2}ms ${motion.timing.easeOut};
  ${({ transitionStatus }) => CONTAINER_TRANSITION_STYLES[transitionStatus]}
`;

const CONTENT_TRANSITION_STYLES: { [K in TransitionStatus]?: string } = {
  entering: "opacity: 0;",
  entered: "opacity: 1;",
  exiting: "opacity: 0; transform: translateY(-100%);",
};

export const ToastContent = styled.div<{ transitionStatus: TransitionStatus }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 427px;
  max-width: 100%;
  margin-top: ${Spacing.scale[1.5]};
  padding: ${Spacing.scale[2]};
  border-radius: ${border.radius.md};
  background-color: ${({ theme }) => theme.colorScheme.background.primary};

  transition-property: transform, opacity;
  transition-timing-function: ${motion.timing.easeOut};
  transition-duration: ${TOASTER_DURATIONS.transition}ms;
  ${({ transitionStatus }) => CONTENT_TRANSITION_STYLES[transitionStatus]}
`;
