import React, { forwardRef } from "react";
import { IconRadioSelected, IconRadioUnselected } from "@hubble/icons";
import { BaseSwitch } from "@gemini-ui/design-system/forms/shared/components/BaseSwitch";
import { CheckboxOrRadioProps } from "@gemini-ui/design-system/forms/shared/constants";

export const Radio = forwardRef<HTMLInputElement, CheckboxOrRadioProps>((props, ref) => (
  <BaseSwitch
    iconSelected={<IconRadioSelected />}
    iconUnselected={<IconRadioUnselected />}
    inputType="radio"
    ref={ref}
    {...props}
  >
    {props.children}
  </BaseSwitch>
));
