import React from "react";
import { IconChevronRightSmall } from "@hubble/icons";
import { PaymentMethodIcon } from "@gemini-ui/components/Icons/PaymentMethod";
import { PaymentMethodItem } from "@gemini-ui/components/PaymentMethodItem";
import { PaymentMethodName } from "@gemini-ui/components/PaymentMethodName";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { PaymentMethodButton } from "@gemini-ui/pages/RetailTrade/PaymentMethod/styles";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { PayPalAccountType } from "@gemini-ui/transformers/PaymentMethods";
import listify from "@gemini-ui/utils/listify";

interface PayPalMethodProps {
  onClick: () => void;
  selectedBank: PayPalAccountType;
}
const PayPalMethod: React.FC<React.PropsWithChildren<PayPalMethodProps>> = props => {
  const {
    onClick,
    selectedBank: { institutionName, displayName, lastFour, currencies },
  } = props;
  return (
    <PaymentMethodButton
      data-testid={testIds.buySell.paymentMethodBtn}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <PaymentMethodItem
        icon={<PaymentMethodIcon paymentMethodType={PaymentMethodType.PAYPAL} size="md" />}
        displayText={<PaymentMethodName lastFour={lastFour} rawName={displayName} institutionName={institutionName} />}
        infoText={listify(currencies, "", false)}
        rightIcon={<IconChevronRightSmall />}
      />
    </PaymentMethodButton>
  );
};

export default PayPalMethod;
