import _ from "lodash";

const PROPERTIES = {
  CURRENCY_PAIR: "Currency Pair",
  SIDE: "Side",
  ORDER_TYPE: "Order Type",
  LIMIT_PRICE: "Limit price",
  PRICE: "Price",
  QUANTITY: "Quantity",
  MINIMUM_FILL_QUANTITY: "Minimum Fill Quantity",
  TOTAL: "Total Order",
  NOTIONAL_USD_TOTAL: "Notional USD Total",
  FEE: "Fee",
  FREQUENCY: "Frequency",
  RECURRING_DAY_OF_WEEK: "Recurring Day of week",
  RECURRING_DAY_OF_MONTH: "Recurring Day of month",
  RECURRING_TWICE_MONTHLY: "Recurring Twice monthly",
  TIME_EXECUTED: "Time of execution",
  SPOT_PRICE: "Spot Price",
  USER_EDITED_LIMIT_PRICE: "User Edited Limit Price",
  ACTIVE_TRADER_INTERFACE: "ActiveTrader Interface",
  PAGE_NAME: "Page Name",
  QUICK_BUY: "Quick Buy",
  USED_SUGGESTED_AMOUNTS: "Used Suggested Amounts",
  PAYMENT_METHOD: "Payment Method",
} as const;

export const TRADE_EVENTS = {
  PLACE_TRADE: {
    name: "Place Trade",
    properties: {
      ...PROPERTIES,
      ASSET_NAME: "Asset Name",
      ASSET_EARN_APY: "Asset Earn APY",
      CURRENCY: "Currency",
      VALUE: "Value",
    },
  },
  CANCEL_ORDER: {
    name: "Cancel Order",
    properties: {
      ..._.pick(PROPERTIES, ["CURRENCY_PAIR", "SIDE", "QUANTITY", "ORDER_TYPE", "PRICE"]),
    },
  },
  CHANGE_CURRENCY_PAIR: {
    name: "Change Currency Pair",
    properties: {
      OLD_PAIR: "Old Pair",
      NEW_PAIR: "New Pair",
      SOURCE: "Source",
    },
  },
  FILTER_ORDER_TABLE: {
    name: "Filter Order Table",
    properties: {
      APPLIED_FILTERS: "Applied Filters",
    },
  },
  CHANGE_THEME: {
    name: "Change Theme",
    properties: {
      NEW_THEME: "New Theme",
    },
  },
  CHANGE_TRADING_CHART: {
    name: "Change Trading Chart",
    properties: {
      CHART_STYLE: "Chart Style",
      INTERVAL: "Interval",
    },
  },
  CHANGE_NOTIONAL_VALUE_GRAPH: {
    name: "Change Notional Value Graph",
    properties: {
      INTERVAL: "Interval",
    },
  },
  VIEW_TRANSACTION_HISTORY: {
    name: "View Transaction History",
  },
  VIEW_ALL_BALANCES: {
    name: "View All Balances",
  },
  CLICK_SUGGESTED_AMOUNT: {
    name: "Click Suggested Amount",
    properties: {
      AMOUNT: "Amount",
    },
  },
  VIEW_PAYMENT_METHODS: {
    name: "View Payment Methods",
  },
  SELECT_PAYMENT_METHOD: {
    name: "Select Payment Method",
    properties: {
      METHOD_ID: "Method ID",
      METHOD_TYPE: "Method Type",
    },
  },
  TRADE_BLOCKED: {
    name: "Trade Blocked - Verify Email",
  },
  UPDATE_TICKET_QUANTITY_ORDERBOOK: {
    name: "Update Ticket Quantity",
    properties: {
      SOURCE: "Source",
    },
  },
  UPDATE_TICKET_PRICE_ORDERBOOK: {
    name: "Update Ticket Price",
    properties: {
      SOURCE: "Source",
    },
  },
} as const;

export const PEOPLE_PROPERTIES = {
  NUMBER_OF_TRADES_PLACED: "# of Trades Placed",
  FIRST_TRADE_DATE: "First Trade Date",
  LAST_TRADE_DATE: "Last Trade Date",
} as const;
