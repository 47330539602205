import { GrowProviderType } from "@gemini-ui/constants/earn";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { UnstakeRedeemSource } from "@gemini-ui/pages/Earn/Redeem/types";

export const unstakeSourceProviderTypeMap = {
  [UnstakeSourceType.pooledBalance]: GrowProviderType.POOLED_STAKING,
  [UnstakeSourceType.validatorBalance]: GrowProviderType.PRIVATE_STAKING,
  [UnstakeSourceType.availableRewardsBalance]: GrowProviderType.PRIVATE_STAKING,
};

export const unstakeSourceTypeRedeemSourceMap = {
  [UnstakeSourceType.pooledBalance]: null,
  [UnstakeSourceType.validatorBalance]: UnstakeRedeemSource.DEPOSIT,
  [UnstakeSourceType.availableRewardsBalance]: UnstakeRedeemSource.REWARD,
};
