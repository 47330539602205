import { TimelineStepStatus } from "@gemini-ui/design-system/Timeline/types";

function animationStepStatus(
  step: number,
  status: TimelineStepStatus,
  animationStep: number,
  currentStepNumber: number,
  stepsCount: number
) {
  if (status) {
    return status;
  }
  if (animationStep === step && currentStepNumber === animationStep && currentStepNumber < stepsCount) {
    return TimelineStepStatus.InProgress;
  }

  if (animationStep >= step) {
    return TimelineStepStatus.Success;
  }
}

export default animationStepStatus;
