import React, { useImperativeHandle, useRef, useState } from "react";
import { IconEyeHide, IconEyeShow } from "@hubble/icons";
import { Input } from "@gemini-ui/design-system/forms/Input";
import { INPUT_TYPES, PasswordInputProps } from "@gemini-ui/design-system/forms/Input/InputPassword/constants";
import { EyeButton } from "@gemini-ui/design-system/forms/Input/InputPassword/styles";
import { getIconSize } from "@gemini-ui/design-system/forms/shared/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export const InputPassword = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ initialType = INPUT_TYPES.password, onToggleType, ...props }, forwardRef) => {
    const [type, setType] = useState(initialType);
    const inputRef = useRef(null);
    useImperativeHandle(forwardRef, () => inputRef.current);

    const { intl } = useIntl();
    const SHOW_PASSWORD = intl.formatMessage({ defaultMessage: "Show password text" });
    const HIDE_PASSWORD = intl.formatMessage({ defaultMessage: "Hide password text" });

    const handleTypeSwitch = (e: React.MouseEvent<HTMLElement>) => {
      const updatedType = type === INPUT_TYPES.password ? INPUT_TYPES.text : INPUT_TYPES.password;
      setType(updatedType);
      // Moving cursor to the end
      // Pause to account for text dashes
      setTimeout(() => {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }, 10);
      onToggleType && onToggleType(updatedType);
    };

    const isTextHidden = type === INPUT_TYPES.password;
    const Icon = isTextHidden ? IconEyeShow : IconEyeHide;

    return (
      <Input
        type={type}
        rightElement={
          <EyeButton
            type="button"
            aria-label={isTextHidden ? SHOW_PASSWORD : HIDE_PASSWORD}
            onClick={handleTypeSwitch}
            onMouseDown={e => e.preventDefault()}
          >
            <Icon size={getIconSize(props.inputSize || "md")} />
          </EyeButton>
        }
        ref={inputRef}
        {...props}
      />
    );
  }
);
