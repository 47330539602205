import React from "react";
import {
  IconDocumentFilled,
  IconFileAudio,
  IconFileDocument,
  IconFileImage,
  IconFilePDF,
  IconFilePresentation,
  IconFileSpreadsheet,
  IconFileVideo,
} from "@hubble/icons";
import {
  AUDIO_EXTENSIONS,
  DOCUMENT_EXTENSIONS,
  IconInfo,
  IMAGE_EXTENSIONS,
  PDF_EXTENSIONS,
  PRESENTATION_EXTENSIONS,
  StoredFile,
  STYLESHEET_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from "@gemini-ui/design-system/FileUploader/constants";
import { Colors } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils";

export const getReadableFileSize = (size: number) => {
  const MEGABYTE = 1000000;
  if (size < MEGABYTE) return `${size / 1000}KB`;
  else return `${size / MEGABYTE}MB`;
};
export const composeKey = (file: File | StoredFile): string => `${file.name}-${file.size}-${file.type}`;
export const isStoredFile = (file: File | StoredFile): boolean => Boolean((file as StoredFile)?.url);
export const needsToBeUploaded = (file: File | StoredFile, error: React.ReactNode): boolean =>
  !isStoredFile(file) && !Boolean(error);
export const ICONS_INFO = (theme): IconInfo[] => [
  {
    extensions: DOCUMENT_EXTENSIONS,
    icon: <IconFileDocument color={getColor(Colors.blue[600], Colors.blue[100])(theme)} />,
    color: getColor(Colors.blue[100], Colors.blue[800])(theme),
  },
  {
    extensions: PDF_EXTENSIONS,
    icon: <IconFilePDF color={getColor(Colors.red[600], Colors.red[100])(theme)} />,
    color: getColor(Colors.red[100], Colors.red[800])(theme),
  },
  {
    extensions: STYLESHEET_EXTENSIONS,
    icon: <IconFileSpreadsheet color={getColor(Colors.green[600], Colors.green[100])(theme)} />,
    color: getColor(Colors.green[100], Colors.green[800])(theme),
  },
  {
    extensions: PRESENTATION_EXTENSIONS,
    icon: <IconFilePresentation color={getColor(Colors.orange[600], Colors.orange[100])(theme)} />,
    color: getColor(Colors.orange[100], Colors.orange[800])(theme),
  },
  {
    extensions: IMAGE_EXTENSIONS,
    icon: <IconFileImage color={getColor(Colors.purple[600], Colors.purple[100])(theme)} />,
    color: getColor(Colors.purple[100], Colors.purple[800])(theme),
  },
  {
    extensions: AUDIO_EXTENSIONS,
    icon: <IconFileAudio color={getColor(Colors.purple[600], Colors.purple[100])(theme)} />,
    color: getColor(Colors.purple[100], Colors.purple[800])(theme),
  },
  {
    extensions: VIDEO_EXTENSIONS,
    icon: <IconFileVideo color={getColor(Colors.purple[600], Colors.purple[100])(theme)} />,
    color: getColor(Colors.purple[100], Colors.purple[800])(theme),
  },
];

export const DEFAULT_ICON_INFO = (theme): IconInfo => ({
  icon: <IconDocumentFilled color={getColor(Colors.black, Colors.white)(theme)} />,
  color: getColor(Colors.gray[100], Colors.gray[800])(theme),
});
