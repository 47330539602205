import React, { useState } from "react";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { FlowState, PayPalErrors } from "@gemini-ui/components/PayPalModal/constants";
import { getPayPalErrorModalTitleCopy } from "@gemini-ui/components/PayPalModal/utils";
import { usePageRefresh } from "@gemini-ui/contexts";
import { Button, Modal, Text, useToaster } from "@gemini-ui/design-system";
import axios from "@gemini-ui/services/axios";
import { PayPalAccountType } from "@gemini-ui/transformers/PaymentMethods";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  error: keyof typeof PayPalErrors;
  setFlowState: (flowState: keyof typeof FlowState) => void;
  payerEmail: string;
  paymentMethod: PayPalAccountType | undefined;
}

export const PayPalErrorModal = ({ onClose, isOpen, error, setFlowState, payerEmail, paymentMethod }: Props) => {
  const { requestRefresh } = usePageRefresh();
  const { showAlert } = useAlert();
  const { showToast } = useToaster();
  const { intl } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  if (error === PayPalErrors.NONE) return null;

  const titleCopy = getPayPalErrorModalTitleCopy(intl, paymentMethod, payerEmail);

  return (
    <Modal
      onClose={() => {
        onClose();
        setFlowState(FlowState.INIT);
      }}
      isOpen={isOpen}
      title={titleCopy[error]}
    >
      {error === PayPalErrors.NameMismatch && (
        <Text.Body size="sm">
          {intl.formatMessage({
            defaultMessage: "The PayPal account must be under the same name as your Gemini account.",
          })}
        </Text.Body>
      )}
      {error === PayPalErrors.CANNOT_ADD_ACCOUNT && (
        <React.Fragment>
          <Text.Body size="sm">
            {intl.formatMessage({
              defaultMessage: "There were too many attempts to add a new PayPal account.",
            })}
          </Text.Body>
          <Text.Body size="sm" mt={2}>
            {intl.formatMessage({
              defaultMessage: "If you think there is a mistake, please contact customer service.",
            })}
          </Text.Body>
        </React.Fragment>
      )}
      {error === PayPalErrors.NonUniquePayerId && (
        <React.Fragment>
          <Text.Body size="sm">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "A PayPal account can only be linked to 1 Gemini account. Use another PayPal account or remove {payerEmail} from the other Gemini account.",
              }),
              { payerEmail }
            )}
          </Text.Body>
        </React.Fragment>
      )}
      {error === PayPalErrors.OnePerAccount && (
        <React.Fragment>
          <Text.Body size="sm">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "Only 1 PayPal account can be linked to your Gemini account at a time. Do you want to remove {displayName} and add new account?",
              }),
              {
                displayName: paymentMethod?.displayName,
              }
            )}
          </Text.Body>
        </React.Fragment>
      )}
      {[PayPalErrors.GENERIC, PayPalErrors.BraintreeError, PayPalErrors.TokenGenerationFailed].includes(
        error as any
      ) && (
        <Text.Body size="sm">
          {intl.formatMessage({
            defaultMessage: "Please try again.",
          })}
        </Text.Body>
      )}
      <Button.Group align="right">
        {error === PayPalErrors.OnePerAccount ? (
          <React.Fragment>
            <Button.Tertiary
              size="lg"
              data-testid="paypal-error-modal-cancel"
              onClick={() => {
                onClose();
                setFlowState(FlowState.INIT);
              }}
            >
              {intl.formatMessage({
                defaultMessage: "Cancel",
              })}
            </Button.Tertiary>
            <Button.Primary
              size="lg"
              data-testid="paypal-error-modal-add-new-account"
              loading={isLoading}
              onClick={async () => {
                try {
                  setIsLoading(true);
                  await axios.delete(`/settings/paypal/payment-methods/${paymentMethod.id}`);

                  showToast({
                    message: intl.formatMessage({
                      defaultMessage: "Payment method removed.",
                    }),
                  });

                  setIsLoading(false);
                  requestRefresh();
                  setFlowState(FlowState.INIT);
                } catch (err) {
                  setIsLoading(false);
                  showAlert({
                    type: AlertTypes.ERROR,
                    message: getError(err),
                    timeout: 8000,
                  });
                }
              }}
            >
              {intl.formatMessage({
                defaultMessage: "Add new",
              })}
            </Button.Primary>
          </React.Fragment>
        ) : (
          <Button.Primary
            size="lg"
            data-testid="paypal-error-modal-accept"
            onClick={() => {
              onClose();
              setFlowState(FlowState.INIT);
            }}
          >
            {intl.formatMessage({
              defaultMessage: "Got it",
            })}
          </Button.Primary>
        )}
      </Button.Group>
    </Modal>
  );
};
