import { CurrencyShortName, SupportedCurrencyPairs } from "@gemini-common/scripts/constants/currencies";

export enum WatchlistTypes {
  MARKET_MOVEMENT_WATCH = "MarketMovementWatch",
  FAVORITES_WATCH = "FavoritesWatch",
}

export interface WatchlistResponseItem {
  userCurrencyWatchId: number;
  currency: CurrencyShortName;
  isEnabled: boolean;
  tradingPair: SupportedCurrencyPairs;
  watchType: WatchlistTypes;
}
