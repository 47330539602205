import { track } from "@gemini-ui/analytics";
import { CREDIT_CARD_EVENTS_V2, CreditTrackEventV2 } from "@gemini-ui/analytics/constants/events";
import { ApplicationStatusStateStateEnum, Currency, JsonInterestRatesResponse, Offer } from "@gemini-ui/client/credit";
import { useCardContext } from "@gemini-ui/pages/Credit/CreditApplication/v2/CardContext";
import { CREDIT_MAX, CREDIT_MIN, UserData } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { ClientState, ClientViews } from "@gemini-ui/pages/Credit/CreditApplication/v2/machines/creditAppMachine";
import { formatCurrency } from "@gemini-ui/pages/Credit/CreditApplication/v2/utils";

const stateChangeEvents: Partial<Record<ClientViews, CreditTrackEventV2>> = {
  [ClientState.NEW_APPLICATION_V3]: CreditTrackEventV2.CC_START_APPLICATION,
  [ClientState.SMS_VERIFY_V3]: CreditTrackEventV2.CC_PHONE_CONFIRMATION,
  [ClientState.EMAIL_VERIFY_V3]: CreditTrackEventV2.CC_EMAIL_CONFIRMATION,
  [ClientState.UPLOAD_DOCUMENTS]: CreditTrackEventV2.CC_ONFIDO,
  [ClientState.ENTER_INCOME]: CreditTrackEventV2.CC_INCOME,
  [ClientState.CARD_SELECTION]: CreditTrackEventV2.CC_CUSTOMIZE_CARD,
  [ClientState.RECENT_DENIED]: CreditTrackEventV2.CC_RECENT_APP_DENIED,
  [ClientState.INELIGIBLE_TO_APPLY]: CreditTrackEventV2.CC_INELIGIBLE,
  [ClientState.GENERIC_ERROR]: CreditTrackEventV2.CC_SERVICE_FAILURE,
  [ApplicationStatusStateStateEnum.SoftPullDenied]: CreditTrackEventV2.CC_SOFT_PULL_DENIED,
  [ApplicationStatusStateStateEnum.BiometricDenied]: CreditTrackEventV2.CC_BIOMETRIC_DENIED,
  [ApplicationStatusStateStateEnum.KycMismatch]: CreditTrackEventV2.CC_KYC_DENIED,
  [ApplicationStatusStateStateEnum.KycFailed]: CreditTrackEventV2.CC_KYC_DENIED,
  [ApplicationStatusStateStateEnum.SoftPullDeceased]: CreditTrackEventV2.CC_DECEASED,
  [ApplicationStatusStateStateEnum.HardPullDeceased]: CreditTrackEventV2.CC_DECEASED,
  [ApplicationStatusStateStateEnum.SoftPullFrozen]: CreditTrackEventV2.CC_SOFT_PULL_FROZEN,
  [ApplicationStatusStateStateEnum.HardPullFrozen]: CreditTrackEventV2.CC_HARD_PULL_FROZEN,
  [ApplicationStatusStateStateEnum.KycServiceFailure]: CreditTrackEventV2.CC_SERVICE_FAILURE,
  [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: CreditTrackEventV2.CC_SERVICE_FAILURE,
  [ApplicationStatusStateStateEnum.HardPullServiceFailure]: CreditTrackEventV2.CC_SERVICE_FAILURE,
  [ApplicationStatusStateStateEnum.KycManualReview]: CreditTrackEventV2.CC_MANUAL_REVIEW,
  [ApplicationStatusStateStateEnum.BiometricManual]: CreditTrackEventV2.CC_MANUAL_REVIEW,
};

export const useMixpanelEvents = (user: UserData) => {
  const { cardColor } = useCardContext();
  const fixedProperties = {
    v2: true,
    isNew: !user?.isLoggedIn || user?.isNewUser ? true : false,
    cardSelection: cardColor,
  };

  return {
    trackStateChange: (currentState: ClientViews) => {
      if (CREDIT_CARD_EVENTS_V2[stateChangeEvents?.[currentState]]) {
        track(CREDIT_CARD_EVENTS_V2[stateChangeEvents[currentState]].name, fixedProperties, { braze: true });
      }
    },
    trackEditAddress: () => {
      track(CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_EDIT_ADDRESS].name, fixedProperties, { braze: true });
    },
    trackResendEmailCode: () => {
      track(CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_RESEND_EMAIL_CODE].name, fixedProperties, { braze: true });
    },
    trackSoftPullApproved: (rates: JsonInterestRatesResponse) => {
      const { name, properties } = CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_SOFT_PULL_APPROVED];

      track(
        name,
        {
          ...fixedProperties,
          [properties.CREDIT_LIMIT_MIN]: formatCurrency(CREDIT_MIN),
          [properties.CREDIT_LIMIT_MAX]: formatCurrency(CREDIT_MAX),
          [properties.APR_MIN]: rates.primeRate + rates.minimumApr,
          [properties.APR_MAX]: rates.primeRate + rates.maximumApr,
        },
        { braze: true }
      );
    },
    trackTermsReview: (schumerBoxHTML: string) => {
      const { name, properties } = CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_TERMS_REVIEW];
      const rates = schumerBoxHTML?.match(/[\d.]+% ?/g);

      track(
        name,
        {
          ...fixedProperties,
          [properties.APR_MIN]: rates?.[0],
          [properties.APR_MAX]: rates?.[1],
        },
        { braze: true }
      );
    },
    trackTermsAgree: (schumerBoxHTML: string) => {
      const { name, properties } = CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_TERMS_AGREE];
      const rates = schumerBoxHTML?.match(/[\d.]+% ?/g);

      track(
        name,
        {
          ...fixedProperties,
          [properties.APR_MIN]: rates?.[0],
          [properties.APR_MAX]: rates?.[1],
        },
        { braze: true }
      );
    },
    trackHardPullApproved: (offer: Offer) => {
      const { name, properties } = CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_HARD_PULL_APPROVED];

      track(
        name,
        {
          ...fixedProperties,
          [properties.APR]: `${offer?.apr}%`,
          [properties.CREDIT_LIMIT]: formatCurrency(offer?.creditLimit),
          [properties.TEMPORARY_CREDIT_LIMIT]: formatCurrency(offer?.temporaryCreditLimit),
        },
        { braze: true }
      );
    },
    trackSearchCrypto: (searchTerm: string) => {
      const { name, properties } = CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_SEARCH_CRYPTO];

      track(
        name,
        {
          ...fixedProperties,
          [properties.SEARCH_TERM]: searchTerm,
        },
        { braze: true }
      );
    },
    trackChooseCrypto: (reward: Currency) => {
      const { name, properties } = CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_CHOOSE_CRYPTO];

      track(
        name,
        {
          ...fixedProperties,
          [properties.CRYPTO_REWARD]: reward,
        },
        { braze: true }
      );
    },
  };
};
