import styled from "@emotion/styled";
import ButtonBase from "@gemini-ui/design-system/Button/ButtonBase";
import { TertiaryButtonProps } from "@gemini-ui/design-system/Button/constants";
import { getStylesByVariant } from "@gemini-ui/design-system/Button/styles";

const TertiaryButton = styled(ButtonBase)<TertiaryButtonProps>(props => {
  const styles = getStylesByVariant(props, "tertiary");

  // style overrides specific to tertiary button
  if (props.flush) {
    styles.paddingLeft = 0;
    styles.paddingRight = 0;
  }

  return styles;
});

export default TertiaryButton;
