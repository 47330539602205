import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { ModalStep, OwnershipStep, OwnerType, SenderType } from "@gemini-ui/components/TransferControl/types";

export interface State {
  modalStep: ModalStep;
  confirmOwnershipStep: OwnershipStep | null;
  senderType: SenderType | null;
  ownerType: OwnerType | null;
  ownerName: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerCountryCode: CountryAbbreviation | null;
  ownerAddressLine1: string;
  ownerAddressLine2: string;
  ownerCity: string;
  ownerPostalCode: string;
  isConfirmOwnershipValid: boolean;
  isSubmitting: boolean;
}

export enum ActionType {
  MODAL_STEP = "modalStep",
  CONFIRM_OWNERSHIP_STEP = "confirmOwnershipStep",
  SENDER_TYPE = "senderType",
  OWNER_TYPE = "ownerType",
  OWNER_NAME = "ownerName",
  OWNER_FIRST_NAME = "ownerFirstName",
  OWNER_LAST_NAME = "ownerLastName",
  OWNER_COUNTRY_CODE = "ownerCountryCode",
  OWNER_ADDRESS_LINE_1 = "ownerAddressLine1",
  OWNER_ADDRESS_LINE_2 = "ownerAddressLine2",
  OWNER_CITY = "ownerCity",
  OWNER_POSTAL_CODE = "ownerPostalCode",
  IS_CONFIRM_OWNERSHIP_VALID = "isConfirmOwnershipValid",
  IS_SUBMITTING = "isSubmitting",
  RESET = "reset",
}

export interface Action {
  type: ActionType;
  payload?: any;
}

export const initState: State = {
  modalStep: ModalStep.SelectSender,
  confirmOwnershipStep: null,
  senderType: null,
  ownerType: OwnerType.INDIVIDUAL,
  ownerName: "",
  ownerFirstName: "",
  ownerLastName: "",
  ownerCountryCode: null,
  ownerAddressLine1: "",
  ownerAddressLine2: "",
  ownerCity: "",
  ownerPostalCode: "",
  isConfirmOwnershipValid: false,
  isSubmitting: false,
};

export function reducer(state: State, action: Action): State {
  if (action.type === ActionType.RESET) return initState;
  return { ...state, [action.type]: action.payload };
}
