/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Card, Flex, Modal, Spacer, Spacing } from "@gemini-ui/design-system";
import { CardProps } from "@gemini-ui/design-system/cards/Card/constants";
import { DepositFlow } from "@gemini-ui/pages/Earn/Deposit";
import { DepositModalView } from "@gemini-ui/pages/Earn/Deposit/types";
import { ProviderTypeSelector } from "@gemini-ui/pages/Earn/GrowBuy/components/ProviderTypeSelector";
import { createProviderTypeSelectionOptions } from "@gemini-ui/pages/Earn/GrowBuy/components/ProviderTypeSelector/utils";
import { StakeDirectionSelector } from "@gemini-ui/pages/Earn/GrowBuy/components/StakeDirectionSelector";
import { createStakingDirectionOptions } from "@gemini-ui/pages/Earn/GrowBuy/components/StakeDirectionSelector/utils";
import { StakingAssetSelector } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingAssetSelector";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { StakingDepositController } from "@gemini-ui/pages/Earn/GrowBuy/screens/StakingDepositController";
import { StakingWithdrawController } from "@gemini-ui/pages/Earn/GrowBuy/screens/StakingWithdrawController";
import { StakeUnstakeButtons } from "@gemini-ui/pages/Earn/GrowBuy/StakeUnstakeButtons";
import { DepositFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingDepositForm";
import { WithdrawalFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingWithdrawalForm";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { RedeemFlow } from "@gemini-ui/pages/Earn/Redeem";
import { RedeemStep } from "@gemini-ui/pages/Earn/Redeem/types";
import { useAccountBalances } from "@gemini-ui/services/account/balances";
import { PaymentMethodEligibility, usePaymentData } from "@gemini-ui/services/transfer/paymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

export const GrowBuyModule = () => {
  const {
    transactionType,
    router: { currentView },
  } = useGrowBuy();
  const { isGrowSidebarModuleEnabled } = useGrowFeatureFlags();

  // Show the segment picker if:
  // - The toggle isn't hidden AND
  // - The sidebar module is enabled AND
  // - EITHER we're in the STAKE flow and the modal view is `PLACE_DEPOSIT`
  // - OR we're in the UNSTAKE flow and the redemption step is `PLACE_REDEEM`
  const shouldDisplayTransactionTypePicker =
    isGrowSidebarModuleEnabled &&
    (transactionType === GrowTransactionType.STAKE
      ? currentView === DepositModalView.PLACE_DEPOSIT
      : currentView === RedeemStep.PlaceRedeem);

  return (
    <Flex flexDirection="column" gap={Spacing.scale[2]}>
      {shouldDisplayTransactionTypePicker && (
        <React.Fragment>
          <StakeUnstakeButtons />
          <Spacer pb={1.5} />
        </React.Fragment>
      )}

      {transactionType === GrowTransactionType.STAKE && <DepositFlow />}

      {transactionType === GrowTransactionType.UNSTAKE && <RedeemFlow />}
    </Flex>
  );
};

export const GrowBuyModal = (props: { onClose?: () => void; onBack?: () => void }) => {
  const {
    goBack,
    resetQuote,
    router: { currentView },
  } = useGrowBuy();

  const handleBack = React.useCallback(() => {
    // If we're in the review step, we need to reset the quote before going back a step
    if (currentView === DepositModalView.REVIEW_DEPOSIT || currentView === DepositModalView.REVIEW_BUY_DEPOSIT) {
      resetQuote();
    }

    goBack();
  }, [currentView, resetQuote, goBack]);

  return (
    <Modal.MultiStep isOpen={true} {...props} onBack={Boolean(goBack) ? handleBack : null} shouldCloseOnOverlayClick>
      <GrowBuyModule />
    </Modal.MultiStep>
  );
};

export const GrowBuyCard = ({
  initialFormValues,
}: {
  initialFormValues?: { direction?: GrowTransactionType } & Partial<DepositFormValues | WithdrawalFormValues>;
} & CardProps) => {
  const { intl } = useIntl();

  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  const {
    stakingAssets,
    setModalView,
    router: { currentView },
  } = useGrowBuy();
  const { eligibleForStaking, eligibleForPrivateStaking } = useGrowFeatureFlags();

  const paymentMethodData = usePaymentData(defaultFiat, PaymentMethodEligibility.BUY);
  const balancesData = useAccountBalances();

  // TODO: Get initial form values from window URL
  const [currency, setCurrency] = React.useState(initialFormValues?.currency || stakingAssets[0].currency);
  const [selectedProviderType, setSelectedProviderType] = React.useState(
    initialFormValues?.providerType || GrowProviderType.POOLED_STAKING
  );
  const [direction, setDirection] = React.useState(initialFormValues?.direction || GrowTransactionType.STAKE);

  // TODO: Check staking eligibility for the user and return null if ineligible

  const asset = stakingAssets?.find(a => a?.currency === currency);

  const stakingDirectionOptions = React.useMemo(() => createStakingDirectionOptions(asset, intl), [asset, intl]);

  const providerTypeOptions = React.useMemo(() => {
    const eligibleProviderTypes = [
      ...(eligibleForStaking ? [GrowProviderType.POOLED_STAKING] : []),
      ...(eligibleForPrivateStaking ? [GrowProviderType.PRIVATE_STAKING] : []),
    ];
    const stakingProviderTypesForAsset = [
      ...new Set(
        asset?.interestProviders.filter(p => eligibleProviderTypes.includes(p.providerType)).map(p => p.providerType)
      ),
    ];

    return createProviderTypeSelectionOptions(stakingProviderTypesForAsset, intl);
  }, [eligibleForStaking, eligibleForPrivateStaking, asset, intl]);

  // Some assets may contain only a subset of available provider types.
  // Check that the currently selected type is available for the asset, and reset if not.
  React.useEffect(() => {
    if (providerTypeOptions.length > 0 && !providerTypeOptions.some(o => o.value === selectedProviderType)) {
      setSelectedProviderType(providerTypeOptions[0].value);
    }
  }, [selectedProviderType, providerTypeOptions]);

  return (
    <Card padding="sm">
      {(currentView === DepositModalView.PLACE_DEPOSIT || currentView === RedeemStep.PlaceRedeem) && (
        <Flex flexDirection="column" mb={1}>
          <StakingAssetSelector
            availableStakingAssets={stakingAssets}
            selectedCurrency={currency}
            onDidSelectCurrency={setCurrency}
          />

          <Flex justifyContent="space-between" alignItems="center" mt={1}>
            <StakeDirectionSelector
              options={stakingDirectionOptions}
              selected={direction}
              onChange={direction => {
                setDirection(direction);

                // TODO: This should be removed, but is just needed for now to set the `currentView` that is consumed later
                switch (direction) {
                  case GrowTransactionType.STAKE:
                    setModalView(DepositModalView.PLACE_DEPOSIT);
                    break;
                  case GrowTransactionType.UNSTAKE:
                    setModalView(RedeemStep.PlaceRedeem);
                    break;
                }
              }}
            />
            <ProviderTypeSelector
              options={providerTypeOptions}
              selected={selectedProviderType}
              onChange={setSelectedProviderType}
            />
          </Flex>
        </Flex>
      )}

      {direction === GrowTransactionType.STAKE && (
        <StakingDepositController
          asset={asset}
          providerType={selectedProviderType}
          initialFormValues={initialFormValues}
          paymentMethodData={paymentMethodData}
          balancesData={balancesData}
        />
      )}

      {direction === GrowTransactionType.UNSTAKE && (
        <StakingWithdrawController
          asset={asset}
          providerType={selectedProviderType}
          initialFormValues={initialFormValues}
        />
      )}
    </Card>
  );
};
