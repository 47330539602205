import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { AnnualInterestYieldTier, GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { BuyingFrequency, RecurringFrequency } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { UserAddressType } from "@gemini-ui/transformers/PaymentMethods";

export interface PlaceDepositInfo {
  currency?: CurrencyShortName;
  amount?: string;
  provider?: InterestProvider;
  providerType?: GrowProviderType;
  source?: SourceType;
  schedule?: BuyingFrequency;
  recurringFrequency?: RecurringFrequency;
}

export const DepositModalView = {
  PLACE_DEPOSIT: "placeDepositVisible",
  AGREEMENT_SUMMARY: "agreementSummaryVisible",
  STAKING_AGREEMENT: "stakingAgreementVisible",
  EARN_AGREEMENT: "earnAgreementVisible",
  MASTER_LOAN_AGREEMENT: "masterLoanAgreementVisible",
  REVIEW_DEPOSIT: "reviewDepositVisible",
  REVIEW_BUY_DEPOSIT: "reviewBuyDepositVisible",
  SELECT_PAYMENT_METHOD: "selectPaymentMethodVisible",
  ADD_PAYMENT_METHOD: "addPaymentMethodVisible",
  SUCCESS: "successVisible",
  TAX_INFORMATION: "taxInformationVisible",
  PICK_ETH_STAKING_TYPE: "pickEthStakingType",
  ASSET_DISCLAIMER: "assetDisclaimerVisible",
} as const;

// Helper type to cast object to union type
type Values<T> = T[keyof T];
export type DepositFlowModal = Values<typeof DepositModalView>;

export interface RecurringDepositState {
  triggerHour: string;
  startOn: Date;
  utcTime: Date;
}

export interface DepositAccountState {
  earnAgreement?: string;
  masterLoanAgreement?: string;
  canManageBankAccounts?: boolean;
  isPlaidRuxEnabled?: boolean;
  isPlaidSupported?: boolean;
  userDetails?: UserAddressType;
}

export type ToggleDepositModal = (name: DepositFlowModal, close?: boolean) => () => void;

export interface DepositProps {
  initialAccountState?: DepositAccountState;
  initialRecurringDepositState?: RecurringDepositState;
  onClose?: () => void;
}

export interface TransferQuote {
  amount: MoneyProps;
  notionalAmount: MoneyProps;
  annualInterestYieldTiers: AnnualInterestYieldTier[];
  validatorsResponse?: ValidatorQuote;
}

export interface ValidatorQuote {
  numberOfValidators?: number;
}

export enum SourceType {
  TRADING_BALANCE = "tradingBalance",
  BANK_OR_CARD = "bankOrCard",
}

export enum UnstakeSourceType {
  pooledBalance = "pooledBalance",
  validatorBalance = "validatorBalance",
  availableRewardsBalance = "availableRewardsBalance",
}

export interface StakingQueueResponse {
  activationDays: number;
  exitDays: number;
}
