import { IntlShape } from "@gemini-ui/utils/intl";

export const getWhereDoIFindThisDetails = (intl: IntlShape) => ({
  LINE_ONE: intl.formatMessage({
    defaultMessage: "1. Checking your online banking portal",
  }),
  LINE_TWO: intl.formatMessage({
    defaultMessage: "2. Checking the bank’s website",
  }),
  LINE_THREE: intl.formatMessage({
    defaultMessage: "3. Reaching out to your bank representative",
  }),
});
