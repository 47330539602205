import { useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { Client, PayPal } from "braintree-web";
import { EVENTS, track } from "@gemini-ui/analytics";
import {
  AuthorizationData,
  FlowState,
  FlowType,
  LinkData,
  PayPalErrors,
} from "@gemini-ui/components/PayPalModal/constants";
import { DATA_COLLECTOR_SCRIPT } from "@gemini-ui/components/PayPalModal/usePayPalScripts";
import axios from "@gemini-ui/services/axios";
import { PaymentMethodDataType } from "@gemini-ui/transformers/PaymentMethods";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";
import useScript from "@gemini-ui/utils/useScript";

declare global {
  interface Window {
    braintree: Client;
    paypal: PayPal;
  }
}

const PAYPAL_SDK_CONFIG = {
  commit: false,
  components: "buttons",
  dataAttributes: {
    "data-page-type": "cart",
  },
  "disable-funding": "card",
  intent: "tokenize",
  vault: true,
} as const;

const PAYPAL_BUTTON_STYLE_CONFIG = {
  layout: "vertical",
  label: "paypal",
  size: "responsive",
  color: "black",
  shape: "pill",
  tagline: false,
  height: 40,
};

interface PayPalButtonProps {
  clientToken: string;
  onError: (err: any) => void;
  setFlowState: React.Dispatch<React.SetStateAction<keyof typeof FlowState>>;
  onTokenizedSuccess: (linkData: LinkData) => void;
  setPayPalLoaderVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export function renderPayPalButton({
  clientToken,
  setPayPalLoaderVisible,
  onTokenizedSuccess,
  onError,
  setFlowState,
}: PayPalButtonProps) {
  return window.braintree.client
    .create({ authorization: clientToken })
    .then(client =>
      window.braintree.paypalCheckout.create({
        client,
      })
    )
    .then(paypalCheckoutInstance => paypalCheckoutInstance.loadPayPalSDK(PAYPAL_SDK_CONFIG))
    .then(paypalCheckoutInstance =>
      window.paypal
        .Buttons({
          fundingSource: "paypal",
          // @ts-expect-error "style" key is missing from braintree-web Buttons TS interface
          style: PAYPAL_BUTTON_STYLE_CONFIG,
          createBillingAgreement: () =>
            paypalCheckoutInstance.createPayment({
              flow: FlowType.Vault,
            }),
          onApprove: (data: AuthorizationData, _actions) => {
            setFlowState(FlowState.LOADING);
            return paypalCheckoutInstance
              .tokenizePayment(data)
              .then(payload => {
                getPayPalDeviceData(clientToken)
                  .then(deviceData => {
                    onTokenizedSuccess({ payload, deviceData });
                  })
                  .catch(err => {
                    // send PayPal details to Gemini backend, even if deviceData capture failed, but log failed dataCollector call
                    const { PAYPAL_DEVICE_DATA_FAILURE } = EVENTS;
                    track(PAYPAL_DEVICE_DATA_FAILURE.name, {
                      [PAYPAL_DEVICE_DATA_FAILURE.properties.ERROR]: getError(err),
                    });
                    Sentry.captureException(err);
                    onTokenizedSuccess({ payload: payload, deviceData: undefined });
                  });
              })
              .catch(err => {
                onError(err);
                return err;
              });
          },
          onCancel: () => track(EVENTS.EXIT_PAYPAL.name),
          onError: onError,
        })
        .render("#paypal-button-container")
    )
    .then(() => {
      setPayPalLoaderVisible(false);
    })
    .catch(onError);
}

export const getPayPalModalComponentCopy = (intl: IntlShape) => ({
  BANKS: intl.formatMessage({ defaultMessage: "Bank Accounts" }),
  DEBIT: intl.formatMessage({ defaultMessage: "Debit cards" }),
  BALANCE: intl.formatMessage({ defaultMessage: "PayPal balance" }),
  TITLE: intl.formatMessage({
    defaultMessage: "Log in to PayPal",
  }),
  DESCRIPTION: intl.formatMessage({
    defaultMessage:
      "You will be redirected to PayPal to connect your account. The following payment methods can be used to fund your Gemini account:",
  }),
});

export const getPayPalErrorModalTitleCopy = (
  intl: IntlShape,
  paymentMethod: PaymentMethodDataType,
  payerEmail: string
): { [key in keyof Omit<typeof PayPalErrors, "NONE">]: string } => ({
  CANNOT_ADD_ACCOUNT: intl.formatMessage({
    defaultMessage: "Cannot add new PayPal account",
  }),
  OnePerAccount: intl.formatMessage(
    defineMessage({
      defaultMessage: "Remove {displayName} and add new account?",
    }),
    { displayName: paymentMethod?.displayName }
  ),
  NonUniquePayerId: intl.formatMessage(
    defineMessage({
      defaultMessage: "{payerEmail} is in use for another Gemini account",
    }),
    { payerEmail }
  ),
  NameMismatch: intl.formatMessage({
    defaultMessage: "Cannot add PayPal account",
  }),
  GENERIC: intl.formatMessage({
    defaultMessage: "Oops, something went wrong",
  }),
  BraintreeError: intl.formatMessage({
    defaultMessage: "Oops, something went wrong",
  }),
  TokenGenerationFailed: intl.formatMessage({
    defaultMessage: "Oops, something went wrong",
  }),
});

const getPayPalDeviceData = (clientToken: string) => {
  return (
    window.braintree.dataCollector
      // @ts-expect-error using authorization as noted https://braintree.github.io/braintree-web/current/module-braintree-web_data-collector.html#.create
      .create({ authorization: clientToken })
      .then(dataCollector => dataCollector.deviceData)
  );
};

export const usePayPalDataCollector = ({ shouldLoad }: { shouldLoad: boolean }) => {
  const { ready, failed } = useScript(DATA_COLLECTOR_SCRIPT, {
    shouldLoad,
  });
  const getPayPalClientTokenAndDeviceData = useCallback(async () => {
    if (!ready) return null;
    try {
      const { data } = await axios.get("/paypal/client-token");
      const deviceData = await getPayPalDeviceData(data.token);
      return deviceData;
    } catch (e) {
      const { PAYPAL_DEVICE_DATA_FAILURE } = EVENTS;
      track(PAYPAL_DEVICE_DATA_FAILURE.name, {
        [PAYPAL_DEVICE_DATA_FAILURE.properties.ERROR]: getError(e),
      });
      Sentry.captureException(e);
      return null;
    }
  }, [ready]);

  return {
    isLoading: !ready && !failed && shouldLoad,
    getDeviceData: getPayPalClientTokenAndDeviceData,
  };
};
