import styled from "@emotion/styled";
import { BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { Colors, Flex } from "@gemini-ui/design-system";

const BaseHeaderNavLink = styled("a")`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  transition: background-color 0.3s ease;

  &:focus {
    color: ${Colors.white};
  }

  &:hover {
    outline: none;
    color: ${Colors.white};
  }
`;

export const HeaderNavLink = styled(BaseHeaderNavLink)<{ hideMobile?: boolean; active?: boolean }>`
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  user-select: none;
  color: ${p => (p.active ? Colors.white : Colors.gray[200])};

  @media ${BREAKPOINTS.phoneOnly} {
    display: ${p => (p.hideMobile ? "none" : "inline-flex")};
  }
`;

export const HeaderNavButtonWrap = styled(Flex)<{ hideMobile?: boolean }>`
  @media ${BREAKPOINTS.phoneOnly} {
    display: ${p => (p.hideMobile ? "none" : "inline-flex")};
  }
`;

export const NameContainer = styled("div")`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const ActiveBar = styled("div")`
  position: absolute;
  height: 4px;
  width: 100%;
  background: ${Colors.brandCyan};
  bottom: 0;
`;
