import styled from "@emotion/styled";

export const TruncateTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  flex-shrink: 1;
`;

export const PaymentMethodNameContainer = styled.span`
  max-width: 100%;
  display: inline-flex;
`;
