import { DocumentTypes, RequestedVariant, SdkError } from "onfido-sdk-ui";
import { PhoneNumber } from "@gemini-ui/constants/phoneNumber";
import { Colors } from "@gemini-ui/design-system";
export interface DocumentUploaderProps {
  onfidoSDKToken: string;
  onComplete: (any) => void;
  // csrfToken: string;
  onError?: (error: SdkError) => void;
  onModalRequestClose?: () => void;
  omitDocument?: boolean;
  welcome?: {
    title?: string;
    descriptions?: string[];
    nextButton?: string;
  };
  documentTypes: Partial<Record<DocumentTypes, boolean>>;
  poa?: {
    country?: "GBR" | "other"; // GBR type automatically enables the document types marked below.
    documentTypes?: {
      bank_building_society_statement?: boolean;
      utility_bill?: boolean;
      council_tax?: boolean; // GBR only
      benefit_letters?: boolean; // GBR only
      government_letter?: boolean; // non-GBR only
    };
  };
  confirmation?: {
    message?: string;
    submessage?: string;
  };
  phoneNumber?: PhoneNumber;
  requestedLivenessVariant?: RequestedVariant;
  forceCrossDevice?: boolean;
  isModalOpen?: boolean;
}

export const ONFIDO_CUSTOM_UI_CONFIG = {
  // misc
  fontSizeBody: "14px",
  fontFamilyBody: "Inter",
  // primary buttons
  colorBackgroundButtonPrimary: Colors.black,
  colorBorderButtonPrimary: Colors.black,
  colorBackgroundButtonPrimaryHover: Colors.gray[900],
  colorBackgroundButtonPrimaryActive: Colors.gray[800],
  // secondaryButton
  colorContentButtonSecondaryText: Colors.black,
  colorBackgroundButtonSecondary: Colors.white,
  colorBorderButtonSecondary: Colors.white,
  colorBackgroundButtonSecondaryHover: Colors.gray[50],
  colorBackgroundButtonSecondaryActive: Colors.gray[100],
  // title
  colorContentTitle: Colors.black,
  fontFamilyTitle: "inherit",
  fontWeightTitle: 600,
  fontSizeTitle: "32px",
  // subtitle
  fontSizeSubtitle: "14px",
  fontFamilySubtitle: "inherit",
  fontWeightSubtitle: 400,
  colorContentSubtitle: Colors.black,

  //links
  colorContentLinkTextHover: Colors.black,
  colorBackgroundLinkHover: Colors.white,
  colorBorderLinkUnderline: Colors.black,
  colorBackgroundLinkActive: Colors.white,
  // icon buttons (back button, close modal)
  colorBackgroundButtonIconHover: Colors.gray[50],
  // doc type buttons
  colorBorderDocTypeButtonHover: Colors.gray[50],
  colorContentDocTypeButton: Colors.black,
  colorBorderDocTypeButton: Colors.gray[100],
  // doc type icons
  colorBackgroundIcon: Colors.gray[50],
  // pills
  colorContentInfoPill: Colors.white,
  colorBackgroundInfoPill: Colors.black,

  // modal
  colorBackgroundSurfaceModal: Colors.white,
};
