import React from "react";
import { ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives/Spacer";
import { ToggleProps } from "@gemini-ui/design-system/Toggle/constants";
import { ToggleInput, ToggleLabel } from "@gemini-ui/design-system/Toggle/styles";

export const Toggle = ({
  checked,
  disabled,
  onChange,
  name,
  "aria-label": ariaLabel,
  "data-testid": testId,
  ...spacingProps
}: ToggleProps) => (
  <ToggleLabel aria-label={ariaLabel} data-testid={testId} {...(spacingProps as ShorthandSpacingCssProps)}>
    <ToggleInput
      name={name}
      checked={checked}
      data-testid={`${testId}-input`}
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
    />
  </ToggleLabel>
);
