import React from "react";
import { ExtendedSubaccountWithBalances } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import { MenuItems, VirtualMenuItems } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/MenuItems";
import {
  DropdownContainer,
  FilterContentContainer,
  MenuItemName,
  MenuItemType,
  SearchInputContainer,
  StyledDivider,
  StyledListItem,
  TextContainer,
} from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/styles";
import useAccountFilter from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/useAccountFilter";
import { ItemLink } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input";
import { isMaxSubaccounts } from "@gemini-ui/services/subaccounts";
import { useIntl } from "@gemini-ui/utils/intl";

export interface SearchableAccountProps {
  subaccounts: ExtendedSubaccountWithBalances[];
  selectedAccount: Subaccount;
  onSelectAccount: (account: Subaccount) => void;
}

const AccountDropdown = (props: SearchableAccountProps) => {
  const { intl } = useIntl();
  const { subaccounts, selectedAccount, onSelectAccount } = props;
  const showSearch = isMaxSubaccounts(subaccounts);
  const hasCustodyAccount = Boolean(subaccounts.find(acct => !acct.trading));
  const needsActivateDerivativeAccount = subaccounts.some(acct => !acct.needsActivation);
  const { filteredAccounts, setFilter, filter } = useAccountFilter({
    ...props,
  });
  const Items = showSearch ? VirtualMenuItems : MenuItems;
  return (
    <DropdownContainer hasBadge={needsActivateDerivativeAccount} data-testid="account-dropdown">
      {showSearch && (
        <SearchInputContainer>
          <Input
            variant="md"
            value={filter || ""}
            type="string"
            name="filter"
            data-testid="account-dropdown-filter"
            autoComplete="off"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onChange={({ target }) => setFilter(target.value)}
            placeholder={intl.formatMessage({
              defaultMessage: "Search accounts...",
            })}
          />
        </SearchInputContainer>
      )}
      {filteredAccounts.length > 0 && (
        <Items
          selectedAccount={selectedAccount}
          subaccounts={filteredAccounts}
          onSelectAccount={onSelectAccount}
        ></Items>
      )}
      {filter && !filteredAccounts.length ? (
        <FilterContentContainer data-testid="account-dropdown-empty">
          {intl.formatMessage({
            defaultMessage: "None Found",
            description: "No accounts were found to display in account dropdown",
          })}
        </FilterContentContainer>
      ) : null}
      {!hasCustodyAccount && !filter && (
        <StyledListItem tabIndex={0}>
          <StyledDivider />
          <TextContainer>
            <ItemLink href={jsRoutes.controllers.settings.NewAccountSettingsController.get().url}>
              <MenuItemName size="sm" bold>
                {intl.formatMessage({
                  defaultMessage: "Manage Accounts",
                })}
              </MenuItemName>
              <MenuItemType size="sm">
                {intl.formatMessage({
                  defaultMessage: "Create and Edit Accounts",
                })}
              </MenuItemType>
            </ItemLink>
          </TextContainer>
        </StyledListItem>
      )}
    </DropdownContainer>
  );
};

export default AccountDropdown;
