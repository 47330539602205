import React from "react";
import { InputElementProps } from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { StyledLeftElement, StyledRightElement } from "@gemini-ui/design-system/__deprecated__/Input/styles";
import { SpacerOption } from "@gemini-ui/design-system/primitives/Spacer/constants";

export const dynamicWidthInputElement = (element: React.ReactElement<{ width: SpacerOption }>, width: SpacerOption) =>
  React.isValidElement(element) ? React.cloneElement(element, { width }) : element;

export const InputLeftElement = ({ children, width, ...props }: InputElementProps) => {
  return (
    <StyledLeftElement elementWidth={width} {...props}>
      {children}
    </StyledLeftElement>
  );
};

export const InputRightElement = ({ children, width, ...props }: InputElementProps) => {
  return (
    <StyledRightElement elementWidth={width} {...props}>
      {children}
    </StyledRightElement>
  );
};
