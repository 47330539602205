import styled from "@emotion/styled";
import { Colors, getColor, Text } from "@gemini-ui/design-system";

export const ScrollContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const TableHeader = styled(Text.Body)`
  color: ${props => getColor(Colors.gray[600], Colors.white)(props)};
`;

export const ScrollBody = styled("div")`
  overflow-y: auto;
  overflow-anchor: none;
  flex: 1;
`;

export const Table = styled("table")`
  width: 100%;
  table-layout: fixed;

  td,
  th {
    vertical-align: middle;

    &:not(:last-child) {
      padding-right: 1rem;
    }
  }
`;

export const ExpandableTableHeaderCell = styled.td`
  vertical-align: middle;
  padding: 10px 0;
`;

export const ExpandableTableCell = styled.td`
  width: 20px;
  max-width: 20px;
  vertical-align: middle;

  a {
    padding: 10px 10px 10px 0;
  }
`;

interface TableRowProps {
  data: Record<string, any>;
}
export const TableRow = styled("tr", {
  shouldForwardProp: prop => prop !== "data",
})<TableRowProps>``;
