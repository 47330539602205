import React, { useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { FormikActions } from "formik";
import { PlaceDepositInfo } from "@gemini-ui/pages/Earn/Deposit/types";
import { RecurringBuyDropdowns } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/RecurringBuyDropdowns";
import { BuyingFrequency, RecurringFrequency } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";

interface Props {
  initialSchedule: BuyingFrequency;
  initialRecurringFrequency: RecurringFrequency;
  setFieldValue: FormikActions<PlaceDepositInfo>["setFieldValue"];
  disabled: boolean;
}

const RecurringDropdowns = ({ initialSchedule, initialRecurringFrequency, setFieldValue, disabled }: Props) => {
  const [schedule, setSchedule] = useState(initialSchedule);

  const [recurringFrequency, setRecurringFrequency] = useState(initialRecurringFrequency);

  const scheduleChangeHandler = item => {
    setSchedule(item);
    setFieldValue("schedule", item);
  };

  const recurringFrequencyChangeHandler = item => {
    setRecurringFrequency(item);
    setFieldValue("recurringFrequency", item);
  };

  return (
    <RecurringBuyDropdowns
      schedule={schedule}
      recurringFrequency={recurringFrequency}
      setSchedule={scheduleChangeHandler}
      setRecurringFrequency={recurringFrequencyChangeHandler}
      disabled={disabled}
    />
  );
};

export default RecurringDropdowns;
