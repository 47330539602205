const height = {
  tabletUpLarge: "(min-height: 800px)",
  tabletDownLarge: "(max-height: 800px)",
};
/**
 * @deprecated Please use `mediaQuery` from `@gemini-ui/design-system` instead.
 * @example
 * import { mediaQuery } from "@gemini-ui/design-system";
 *
 */
export const BREAKPOINTS = {
  mobileDownSmall: "(max-width: 320px)",
  mobileDown: "(max-width: 543px)",
  mobileDownLarge: "(max-width: 650px)",
  mobileUpLarge: "(min-width: 650px)",
  tabletUp: "(min-width: 774px)",
  tabletDown: "(max-width: 774px)",
  tabletUpLarge: "(min-width: 800px)",
  tabletDownLarge: "(max-width: 900px)",
  tabletDownXLarge: "(max-width: 970px)",
  desktopUp: "(min-width: 900px)",
  desktopUpLarge: "(min-width: 1024px)",
  desktopDownLarge: "(max-width: 1024px)",
  desktopDownExtraLarge: "(max-width: 1200px)",
  desktopUpExtraLarge: "(min-width: 1200px)",
  laptopUp: "(min-width: 1366px)",
  laptopDown: "(max-width: 1365px)",
  height,
} as const;
