export const testIds = {
  paginationCounter: "hbl-table-pagination-counter",
  sortableHeaderToggle: "hbl-table-sortable-header-toggle",
  sortIcon: "hbl-table-sorting-icon-",
  previousPaginationButton: "hbl-table-previous-pagination-button",
  nextPaginationButton: "hbl-table-next-pagination-button",
  hiddenTableHeader: "hbl-table-hidden-tbl-header",
  hiddenColumnHeader: "hbl-table-hidden-col-header",
  singleLineLoader: "hbl-table-single-line-loader",
  doubleLineLoader: "hbl-table-double-line-loader",
  nameWithLogoLoader: "hbl-table-name-logo-loader",
};
