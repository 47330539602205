import { getTime, subDays, subHours, subMonths, subWeeks, subYears } from "date-fns";
import { ChartInterval, PairDetail, Point } from "@gemini-ui/pages/RetailTrade/constants";

const endOfRange = {
  x: Date.now(),
  y: 1,
};

const GUSD_LIFETIME_RANGE = [
  {
    x: 1536591600000,
    y: 1,
  },
  endOfRange,
];

const GUSD_STUBBED_TIME_FRAMES: { [key in ChartInterval]: Point } = {
  "1h": {
    x: getTime(subHours(new Date(), 1)),
    y: 1,
  },
  "1d": {
    x: getTime(subDays(new Date(), 1)),
    y: 1,
  },
  "1w": {
    x: getTime(subWeeks(new Date(), 1)),
    y: 1,
  },
  "1m": {
    x: getTime(subMonths(new Date(), 1)),
    y: 1,
  },
  "3m": {
    x: getTime(subMonths(new Date(), 3)),
    y: 1,
  },
  "1y": {
    x: getTime(subYears(new Date(), 1)),
    y: 1,
  },
  all: {
    x: getTime(subYears(new Date(), 1)),
    y: 1,
  },
};

export const getStubbedChartDataGUSD = (interval: ChartInterval) => {
  const startOfRange = GUSD_STUBBED_TIME_FRAMES[interval];
  return startOfRange ? [startOfRange, endOfRange] : GUSD_LIFETIME_RANGE;
};

export const GUSD_CHART_FIXTURE = {
  "24hrMin": "1",
  "24hrMax": "1",
  range: GUSD_LIFETIME_RANGE,
  bid: "1",
  ask: "1",
};

export const GUSD_PAIR_DETAIL_FIXTURE: PairDetail = {
  symbol: "GUSDUSD",
  "24hrDelta": "0",
  "24hrPercentDelta": "0",
  supply: null,
  volume: null,
  allTimeHigh: null,
  rangeDelta: "0",
  rangePercentDelta: "0",
  instantMarketOpen: false,
  limitOrderOpen: false,
  ...GUSD_CHART_FIXTURE,
};
