import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { shorthandSpacingCss } from "@gemini-ui/design-system/primitives/Spacer";
import { TabNavProps } from "@gemini-ui/design-system/TabNavigation/constants";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${shorthandSpacingCss}
`;

export const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${Spacing.scale[4]};
  align-items: center;

  @media ${BREAKPOINTS.tabletDownXLarge} {
    margin-bottom: ${Spacing.scale[3]};
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const tabSpaceBetween = {
  sm: Spacing.scale[1.5],
  md: Spacing.scale[3],
  lg: Spacing.scale[6],
};

export const SectionTitleLink = styled("a")<{ active: boolean; size: TabNavProps["size"]; disabled?: boolean }>`
  border-bottom: 2px solid transparent;
  padding-bottom: ${({ size }) => (size === "sm" ? 0 : Spacing.scale[0.5])};
  white-space: nowrap;
  margin-right: ${({ size }) => tabSpaceBetween[size]};

  &:last-of-type {
    margin-right: 0;
  }

  color: ${({ disabled = false }) => (disabled ? Colors.gray[400] : Colors.gray[600])};

  &:hover,
  &:focus {
    color: ${({ disabled = false }) => (!disabled ? Colors.gray[900] : "inherit")};
  }

  ${({ active }) =>
    active &&
    `
    color: ${Colors.gray[900]};
    border-bottom-color: ${Colors.brandCyan};
  `}
`;

export const TabContainer = styled("div")`
  position: relative;
`;
