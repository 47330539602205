import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { useEffectOnce } from "react-use";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import axios from "@gemini-ui/services/axios";

export const useGetAwaitingOwnershipAttestations = (shouldFetchAttestations = true) => {
  const {
    templateProps: { account },
  } = usePageData();
  const isSingaporeMasEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.SINGAPORE_MAS_TRANSFER_CONTROL_WEB
  );
  const isUkTravelRuleEnabled = getIsUkInboundEnabled(account.geminiEntity);
  const [canShowTransferControlAlert, setCanShowTransferControlAlert] = useState(
    (isSingaporeMasEnabled && account?.hasDepositsAwaitingAttestation) ||
      (isUkTravelRuleEnabled && account?.hasUkDepositsAwaitingAttestation)
  );
  const [hasMultipleAttestations, setHasMultipleAttestations] = useState(false);
  const [loadingHasMultipleAttestations, setLoadingHasMultipleAttestations] = useState(true);
  const [singleAttestation, setSingleAttestation] = useState(null);
  const ownershipAttestationUrl = isUkTravelRuleEnabled
    ? jsRoutes.controllers.order.HistoryController.getAwaitingUkOwnershipAttestations().url
    : jsRoutes.controllers.order.HistoryController.getAwaitingOwnershipAttestations().url;

  const getAwaitingOwnershipAttestations = async () => {
    if (!shouldFetchAttestations) {
      return;
    }
    try {
      const resp = await axios.get(ownershipAttestationUrl);

      if (resp?.data?.entries.length > 1) {
        setHasMultipleAttestations(true);
      } else if (resp?.data?.entries.length === 0 && canShowTransferControlAlert) {
        setCanShowTransferControlAlert(false);
      }

      if (resp?.data?.entries.length === 1) {
        setSingleAttestation(resp?.data?.entries[0]);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingHasMultipleAttestations(false);
    }
  };

  useEffectOnce(() => {
    getAwaitingOwnershipAttestations();
  });

  return {
    canShowTransferControlAlert,
    getAwaitingOwnershipAttestations,
    isSingaporeMasEnabled,
    isUkTravelRuleEnabled,
    hasMultipleAttestations,
    loadingHasMultipleAttestations,
    singleAttestation,
  };
};
