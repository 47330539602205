import styled from "@emotion/styled";
import { FocusedInputNames } from "@gemini-ui/components/DatePicker/constants";
import { Colors, Spacing } from "@gemini-ui/design-system";

export const DatePickerInputContainer = styled.div<{ focusedInput?: FocusedInputNames }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing.scale[3]};

  label {
    min-height: auto;
  }

  input {
    margin-bottom: ${Spacing.scale[2]};
  }

  ${({ focusedInput }) =>
    `input[name=${focusedInput}] {
        border-color: ${Colors.gray[900]};
        box-shadow: 0 0 0 1px ${Colors.gray[900]};
    }`}
`;

export const YearMonthDropdownContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: ${Spacing.scale[3]};
`;

export const CalendarContainer = styled.div<{ isOpen }>`
  ${p =>
    p.isOpen &&
    `
    padding-top: ${Spacing.scale[1]};
    border-top: 1px solid ${Colors.gray[50]};
  `}
`;

export const DayPickerStyles = styled.div`
  .DayPicker {
    padding-bottom: ${Spacing.scale[3]};
  }

  .DayPicker-Body {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 1px rgb(16 16 26 / 6%);
    border-radius: ${Spacing.scale[1]};
    padding: ${Spacing.scale[1]};
  }

  .DayPicker-Week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
  }

  .DayPicker-Day--outside,
  .DayPicker-Day--disabled {
    opacity: 0.8;
    pointer-events: none;
    cursor: default;
    color: ${Colors.gray[200]};
  }

  .DayPicker-Month {
    display: flex;
    flex-direction: column;
  }

  .DayPicker-Day {
    display: grid;
    place-content: center;
    font-size: ${Spacing.scale[2]};
    width: ${Spacing.scale[4]};
    height: ${Spacing.scale[4]};
    cursor: pointer;
    ${({ theme }) =>
      `
      color: ${theme.colorScheme.content.primary};
    `}
  }

  /* Highlight the day that is being selected by current date input */
  .calendar-to .DayPicker-Day--to,
  .calendar-from .DayPicker-Day--from,
  .calendar-to .DayPicker-Day--start,
  .calendar-from .DayPicker-Day--end {
    ${({ theme }) =>
      `
      border: 2px solid ${theme.colorScheme.content.primary};
    `}
    border-radius: 50%;
  }
`;
