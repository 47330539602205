import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { IconClockOutlined, IconClose } from "@hubble/icons";
import { useScroll } from "react-use";
import { CardColorEnum } from "@gemini-ui/client/credit";
import GlobalAlert from "@gemini-ui/components/GlobalAlert";
import { Badge, Button, Flex, Spacing, Text } from "@gemini-ui/design-system";
import { useCardContext } from "@gemini-ui/pages/Credit/CreditApplication/v2/CardContext";
import { CardColorSwatch, ExitAppModal } from "@gemini-ui/pages/Credit/CreditApplication/v2/components";
import { Lazy3DCard } from "@gemini-ui/pages/Credit/CreditApplication/v2/components/Card3D/Lazy3DCard";
import {
  Content,
  Footer,
  FooterCenter,
  Header,
  HeaderButtonContainer,
  LeftContent,
  Logo,
  ProgressBar,
  ProgressStep,
  ProgressWrapper,
  RightContent,
} from "@gemini-ui/pages/Credit/CreditApplication/v2/components/OnboardingLayout/styles";
import {
  ApplicationProgress,
  CREDIT_APP_LOGIN,
  UserData,
} from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { ClientState, ClientViews } from "@gemini-ui/pages/Credit/CreditApplication/v2/machines/creditAppMachine";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/v2/testIds";
import { useMixpanelEvents } from "@gemini-ui/pages/Credit/CreditApplication/v2/tracking/mixpanel";
import { APPLICATION_HELP_LINK } from "@gemini-ui/pages/Credit/utils/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export interface OnboardingLayoutProps {
  user?: UserData;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  progress?: ApplicationProgress;
  children?: React.ReactNode;
  helpUrl?: string;
  currentState?: ClientViews;
}

export const OnboardingLayout = ({
  user,
  progress,
  children,
  helpUrl,
  currentState,
  containerRef,
}: OnboardingLayoutProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();
  const { cardColor, cardName, setCardColor } = useCardContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { trackStateChange } = useMixpanelEvents(user);
  const scroll = useScroll(containerRef);
  const hasProgressValues = progress?.value && progress?.max;
  const hasScrollShadow = scroll.y < containerRef?.current?.scrollHeight - containerRef?.current?.offsetHeight;

  useEffect(() => {
    trackStateChange(currentState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);

  return (
    <React.Fragment>
      <GlobalAlert />
      <Header>
        <Logo />
        <HeaderButtonContainer>
          {!user?.isLoggedIn && currentState === ClientState.NEW_APPLICATION_V3 && (
            <Button.Secondary data-testid={testIds.button.signIn} size="sm" href={CREDIT_APP_LOGIN}>
              {intl.formatMessage({ defaultMessage: "Sign In" })}
            </Button.Secondary>
          )}
          <Button.Secondary
            data-testid={testIds.button.help}
            size="sm"
            href={helpUrl ?? APPLICATION_HELP_LINK}
            target="_blank"
          >
            {intl.formatMessage({ defaultMessage: "Help" })}
          </Button.Secondary>
          {user?.isLoggedIn && currentState !== ClientState.NEW_APPLICATION_V3 && (
            <Button.Secondary
              size="sm"
              aria-label={intl.formatMessage({ defaultMessage: "Close button" })}
              icon={<IconClose />}
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </HeaderButtonContainer>
      </Header>
      <Content>
        <LeftContent cardColor={cardColor} data-testid="left-content">
          <Lazy3DCard cardColor={cardColor} name={cardName} />
          <Flex
            gap={Spacing.scale["2"]}
            alignItems="center"
            justifyContent="center"
            position="relative"
            top={`-${Spacing.scale["8"]}`}
          >
            {currentState !== ClientState.REWARD_SELECTION && currentState !== ClientState.AUTH_USER_INVITE && (
              <React.Fragment>
                {Object.values(CardColorEnum).map(color => (
                  <CardColorSwatch
                    key={color}
                    color={color}
                    isChecked={color === cardColor}
                    onChange={() => setCardColor(color)}
                  />
                ))}
              </React.Fragment>
            )}
          </Flex>
        </LeftContent>
        <RightContent ref={containerRef} currentState={currentState}>
          {children}
        </RightContent>
      </Content>
      {Boolean(progress) && (
        <Footer hasSrollShadow={hasScrollShadow}>
          {hasProgressValues && (
            <ProgressWrapper>
              <ProgressBar
                data-testid={testIds.progressBar}
                role="progressbar"
                aria-valuenow={progress.value}
                aria-valuemin={1}
                aria-valuemax={progress.max}
                value={progress.value}
                max={progress.max}
              />
            </ProgressWrapper>
          )}
          <ProgressStep>
            <Text.Body size="xs">
              {intl.formatMessage(defineMessage({ defaultMessage: "{step} {stepCount}" }), {
                step: hasProgressValues ? `${progress.step}:` : progress.step,
                stepCount: hasProgressValues && (
                  <span style={{ color: colorScheme.content.secondary }}>
                    {progress.value} / {progress.max}
                  </span>
                ),
              })}
            </Text.Body>
          </ProgressStep>
          {currentState === ClientState.CARD_SELECTION && (
            <FooterCenter>
              <Badge icon={<IconClockOutlined />}>
                {intl.formatMessage({ defaultMessage: "5-7 days estimated card arrival" })}
              </Badge>
            </FooterCenter>
          )}
        </Footer>
      )}
      <ExitAppModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </React.Fragment>
  );
};
