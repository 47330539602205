import { HubbleProviderProps } from "@hubble/web";
import { ButtonProps } from "@gemini-ui/design-system/Button";

export interface SystemAlertProps {
  variant: SystemAlertVariant;
  onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  actionButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  "data-testid"?: string;
  children?: React.ReactNode;
}

export const SYSTEM_ALERT_VARIANTS = {
  negative: "negative",
  warning: "warning",
  informational: "informational",
} as const;

export type SystemAlertVariant = keyof typeof SYSTEM_ALERT_VARIANTS;

export const variantToThemeSchemeMap: { [key in SystemAlertVariant]: HubbleProviderProps["scheme"] } = {
  negative: "dark",
  warning: "light",
  informational: "dark",
};
