import React from "react";
import * as Sentry from "@sentry/browser";
import {
  CURRENCIES_DETAIL,
  CurrencyShortNameFiat,
  currencyToLower,
  isCurrency,
} from "@gemini-common/scripts/constants/currencies";
import { CopyListItemToClipboard } from "@gemini-ui/components/CopyListItemToClipboard/CopyListItemToClipboard";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { RegulatoryFooter } from "@gemini-ui/components/RegulatoryFooter";
import { formatCountryString, WireTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { formatTransferMechanismText } from "@gemini-ui/components/WireDepositDetails/utils";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { Card, Colors, List, ListItem, SectionMessage, Text } from "@gemini-ui/design-system";
import ConfirmEmailLockoutV2 from "@gemini-ui/pages/transfers/components/ConfirmEmailLockoutV2";
import VerificationLockoutV2 from "@gemini-ui/pages/transfers/components/VerificationLockoutV2";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface WireDepositDetailsProps extends WireTransferInfoAPIPayload {
  currency: CurrencyShortNameFiat;
  isXfers?: boolean;
  isRtp?: boolean;
  sendEmail?: boolean;
  subaccountHashid?: string;
  geminiEntity: GeminiEntity;
  emailConfirmed: boolean;
  isFullyVerified: boolean;
  isProtailFromOnboarding?: boolean;
}

interface InternationalDepositDetailsProps {
  internationalBankInfo: WireTransferInfoAPIPayload["internationalBankInfo"];
  isSGD: boolean;
  isGBPorEUR: boolean;
  geminiEntity: GeminiEntity;
  currency: CurrencyShortNameFiat;
}

const InternationalDepositDetails = ({
  internationalBankInfo,
  isSGD,
  isGBPorEUR,
  geminiEntity,
  currency,
}: InternationalDepositDetailsProps) => {
  const { intl } = useIntl();

  const isSGDCurrency = isCurrency.SGD(currency);

  return (
    <React.Fragment>
      {/* Corresponding Bank Section */}
      {Boolean(internationalBankInfo.intermediary) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {`${internationalBankInfo.intermediary.title} bank`}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Name" })}
              data={internationalBankInfo.intermediary.name}
              textToCopy={internationalBankInfo.intermediary.name}
            ></CopyListItemToClipboard>

            {Boolean(internationalBankInfo.intermediary.swift) && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "BIC/SWIFT" })}
                data={internationalBankInfo.intermediary.swift.id}
                textToCopy={internationalBankInfo.intermediary.swift.id}
              ></CopyListItemToClipboard>
            )}
          </List>
        </Card>
      )}

      {/* Receiving Bank Section */}
      {Boolean(internationalBankInfo.receivingBank) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {intl.formatMessage({ defaultMessage: "Receiving bank" })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Name" })}
              data={internationalBankInfo.receivingBank.name}
              textToCopy={internationalBankInfo.receivingBank.name}
            ></CopyListItemToClipboard>

            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Address" })}
              data={
                <React.Fragment>
                  <Text.Body bold>{internationalBankInfo.receivingBank.location.address}</Text.Body>
                  <Text.Body bold>
                    {internationalBankInfo.receivingBank.location.city},{" "}
                    {internationalBankInfo.receivingBank.location.state}{" "}
                    {internationalBankInfo.receivingBank.location.zip}
                  </Text.Body>
                  <Text.Body bold>
                    {formatCountryString(internationalBankInfo.receivingBank.location.country)}
                  </Text.Body>
                </React.Fragment>
              }
              textToCopy={[
                internationalBankInfo.receivingBank.location.address,
                internationalBankInfo.receivingBank.location.city,
                internationalBankInfo.receivingBank.location.state,
                internationalBankInfo.receivingBank.location.zip,
                internationalBankInfo.receivingBank.location.country,
              ]}
            ></CopyListItemToClipboard>

            {Boolean(internationalBankInfo.receivingBank.swift) && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "BIC/SWIFT" })}
                data={internationalBankInfo.receivingBank.swift.id}
                textToCopy={internationalBankInfo.receivingBank.swift.id}
              ></CopyListItemToClipboard>
            )}

            {Boolean(internationalBankInfo.beneficiary?.sortCode) && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "Sort code" })}
                data={internationalBankInfo.beneficiary.sortCode}
                textToCopy={internationalBankInfo.beneficiary.sortCode}
              ></CopyListItemToClipboard>
            )}
          </List>
        </Card>
      )}

      {/* Beneficiary/Recipient Section */}
      {Boolean(internationalBankInfo.beneficiary) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {intl.formatMessage({ defaultMessage: "Beneficiary/Recipient" })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Name" })}
              data={internationalBankInfo.beneficiary.name}
              textToCopy={internationalBankInfo.beneficiary.name}
            ></CopyListItemToClipboard>

            {internationalBankInfo.beneficiary.bankName && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "Bank name" })}
                data={internationalBankInfo.beneficiary.bankName}
                textToCopy={internationalBankInfo.beneficiary.bankName}
              ></CopyListItemToClipboard>
            )}
            {internationalBankInfo.beneficiary.location && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "Address" })}
                data={
                  <React.Fragment>
                    <Text.Body bold>{internationalBankInfo.beneficiary.location.address}</Text.Body>
                    <Text.Body bold>
                      {internationalBankInfo.beneficiary.location.city},{" "}
                      {internationalBankInfo.beneficiary.location.state}{" "}
                      {internationalBankInfo.beneficiary.location.zip}
                    </Text.Body>
                    <Text.Body bold>
                      {formatCountryString(internationalBankInfo.beneficiary.location.country)}
                    </Text.Body>
                  </React.Fragment>
                }
                textToCopy={[
                  internationalBankInfo.beneficiary.location.address,
                  internationalBankInfo.beneficiary.location.city,
                  internationalBankInfo.beneficiary.location.state,
                  internationalBankInfo.beneficiary.location.zip,
                  internationalBankInfo.beneficiary.location.country,
                ]}
              ></CopyListItemToClipboard>
            )}

            {!(isSGDCurrency && internationalBankInfo.beneficiary?.parsedIBAN) ? (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "Account number" })}
                data={internationalBankInfo.beneficiary.accountNumber}
                textToCopy={internationalBankInfo.beneficiary.accountNumber}
              ></CopyListItemToClipboard>
            ) : null}

            {internationalBankInfo.beneficiary?.parsedIBAN && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "IBAN" })}
                data={internationalBankInfo.beneficiary.parsedIBAN.iban}
                textToCopy={internationalBankInfo.beneficiary.parsedIBAN.iban}
              ></CopyListItemToClipboard>
            )}
            {isSGD && (
              <Text.Body pt={2} pl={2} pr={2} pb={2} size="xs" color={Colors.gray[600]}>
                {intl.formatMessage({
                  defaultMessage: "Gemini works with Xfers Pte Ltd to facilitate your SGD transfers.",
                })}
              </Text.Body>
            )}
          </List>
        </Card>
      )}
    </React.Fragment>
  );
};

interface DomesticDepositDetailsProps {
  domesticBankInfo: WireTransferInfoAPIPayload["domesticBankInfo"];
}

const DomesticDepositDetails = ({ domesticBankInfo }: DomesticDepositDetailsProps) => {
  const { receivingBank, beneficiary } = domesticBankInfo;
  const { intl } = useIntl();
  const routingNumber =
    typeof receivingBank.routingNumber === "string" ? receivingBank.routingNumber : receivingBank.routingNumber.id;

  return (
    <React.Fragment>
      {/* Receiving Bank Section */}
      {Boolean(receivingBank) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {intl.formatMessage({ defaultMessage: "Receiving bank" })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Routing number" })}
              data={routingNumber}
              textToCopy={routingNumber}
            ></CopyListItemToClipboard>

            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Name" })}
              data={receivingBank.name}
              textToCopy={receivingBank.name}
            ></CopyListItemToClipboard>

            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Address" })}
              data={
                <React.Fragment>
                  <Text.Body bold>{receivingBank.location.address}</Text.Body>
                  <Text.Body bold>
                    {receivingBank.location.city}, {receivingBank.location.state} {receivingBank.location.zip}
                  </Text.Body>
                  <Text.Body bold>{formatCountryString(receivingBank.location.country)}</Text.Body>
                </React.Fragment>
              }
              textToCopy={[
                receivingBank.location.address,
                receivingBank.location.city,
                receivingBank.location.state,
                receivingBank.location.zip,
                receivingBank.location.country,
              ]}
            ></CopyListItemToClipboard>

            {Boolean(receivingBank.swift) && (
              <CopyListItemToClipboard
                label={intl.formatMessage({ defaultMessage: "BIC/SWIFT" })}
                data={receivingBank.swift.id}
                textToCopy={receivingBank.swift.id}
              ></CopyListItemToClipboard>
            )}
          </List>
        </Card>
      )}

      {/* Beneficiary/Recipient Section */}
      {Boolean(beneficiary) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {intl.formatMessage({ defaultMessage: "Beneficiary/Recipient" })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Account number" })}
              data={beneficiary.accountNumber}
              textToCopy={beneficiary.accountNumber}
            ></CopyListItemToClipboard>

            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Name" })}
              data={beneficiary.name}
              textToCopy={beneficiary.name}
            ></CopyListItemToClipboard>

            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Address" })}
              data={
                <React.Fragment>
                  <Text.Body bold>{beneficiary.location.address}</Text.Body>
                  <Text.Body bold>
                    {beneficiary.location.city}, {beneficiary.location.state} {beneficiary.location.zip}
                  </Text.Body>
                  <Text.Body bold>{formatCountryString(beneficiary.location.country)}</Text.Body>
                </React.Fragment>
              }
              textToCopy={[
                beneficiary.location.address,
                beneficiary.location.city,
                beneficiary.location.state,
                beneficiary.location.zip,
                beneficiary.location.country,
              ]}
            ></CopyListItemToClipboard>
          </List>
        </Card>
      )}
    </React.Fragment>
  );
};

export const WireDepositDetails = ({
  currency,
  isXfers,
  isRtp,
  isInternational,
  domesticBankInfo,
  internationalBankInfo,
  reference,
  subaccountHashid,
  sendEmail = false,
  geminiEntity,
  emailConfirmed,
  isFullyVerified,
  isProtailFromOnboarding,
}: WireDepositDetailsProps) => {
  const { intl } = useIntl();
  const isSGD = isRtp || isXfers;
  const isGBPorEUR = ["GBP", "EUR"].includes(currency);
  const transferMechanismSupportedText = formatTransferMechanismText(currency, isSGD, geminiEntity, intl);
  const xfersLimit = "200,000";

  React.useEffect(() => {
    const sendWireInstructionsEmail = async () => {
      const request = jsRoutes.controllers.transfer.DepositWireController.sendWireDepositInstructionsEmail(
        currencyToLower(currency)
      ).url;
      const config = subaccountHashid
        ? {
            headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          }
        : undefined;
      try {
        await axios.post(request, undefined, config);
      } catch (e) {
        const sentryErrorMessage = `Failed to send wire instructions email for ${subaccountHashid} with ${e.toString()}`;
        Sentry.captureMessage(sentryErrorMessage, Sentry.Severity.Error);
      }
    };
    if (sendEmail) {
      sendWireInstructionsEmail();
    }
  }, [sendEmail, subaccountHashid, currency]);

  // protail from onboarding is only set for fully verified users, onboarding uses state machine so template props are not updated
  const isLockedOut = !isFullyVerified && !isProtailFromOnboarding;

  return (
    <React.Fragment>
      {!emailConfirmed && <ConfirmEmailLockoutV2 intl={intl} />}
      {emailConfirmed && isLockedOut && <VerificationLockoutV2 intl={intl} />}
      <Text.Body size="md">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Issue a {isSGD, select, true {FAST} other {{isGBPorEUR, select, true {bank} other {wire}}}} transfer from your bank account to your Gemini account using instructions below.",
          }),
          {
            isSGD,
            isGBPorEUR,
          }
        )}
      </Text.Body>
      {isSGD && (
        <Text.Body size="md" bold mt={1.5}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "Please note there is a {xfersLimit} SGD limit per deposit.",
            }),
            {
              xfersLimit,
            }
          )}
        </Text.Body>
      )}
      <Text.Body size="md" mt={1}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "If you have questions about how to make a {isSGD, select, true {deposit} other {transfer}}, contact your bank for assistance or <helplink>visit our FAQ.</helplink>",
          }),
          {
            isSGD,
            helplink: (str: React.ReactNode) => (
              <HelpCenterLink article={isSGD ? Articles.XFERS_INSTRUCTIONS : Articles.WIRE_INSTRUCTIONS}>
                {str}
              </HelpCenterLink>
            ),
          }
        )}
      </Text.Body>

      <SectionMessage statusType="warning" mt={3}>
        {intl.formatMessage({
          defaultMessage:
            "If the name on your bank account does not match the name on your Gemini account (including joint or business accounts) your transfer may be delayed or returned. Make sure the bank account is already added to your Gemini account.",
        })}
        {isSGD && (
          <Text.Body mt={1} size="sm">
            {intl.formatMessage({
              defaultMessage:
                "We do not support transfers from third party platforms like Grab, Aspire, Revolut, FOMO pay etc.",
            })}
          </Text.Body>
        )}
      </SectionMessage>

      <Card variant="filled" mt={2} padding="none">
        <Text.Heading size="xs" m={2}>
          {intl.formatMessage({
            defaultMessage: "Deposit information",
          })}
        </Text.Heading>
        <List>
          <ListItem
            padding="sm"
            left={
              <Text.Body>
                {intl.formatMessage({
                  defaultMessage: "Currency",
                })}
              </Text.Body>
            }
            right={<Text.Body bold>{`${CURRENCIES_DETAIL[currency].name} (${currency})`}</Text.Body>}
          ></ListItem>

          <ListItem
            padding="sm"
            left={
              <Text.Body>
                {intl.formatMessage({
                  defaultMessage: "Transfer type",
                  description: "Types of transfers eg. 'Same day wire', 'SEPA', 'Bank transfer (Faster Payments)'",
                })}
              </Text.Body>
            }
            right={<Text.Body bold>{transferMechanismSupportedText}</Text.Body>}
            hasDivider={false}
          ></ListItem>

          {!isSGD && (
            <Text.Body pt={0} pl={2} pr={2} pb={2} size="xs" color={Colors.gray[600]}>
              {intl.formatMessage({
                defaultMessage: "Other types of transfers may be delayed or returned.",
              })}
            </Text.Body>
          )}
        </List>
      </Card>

      {Boolean(reference) && (
        <Card variant="filled" mt={2} padding="none">
          <Text.Heading size="xs" m={2}>
            {intl.formatMessage({
              defaultMessage: "Reference code",
            })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Code" })}
              data={reference}
              textToCopy={reference}
              hasDivider={false}
            ></CopyListItemToClipboard>
          </List>
          <Text.Body pt={0} pl={2} pr={2} pb={2} size="xs" color={Colors.gray[600]}>
            {intl.formatMessage({
              defaultMessage:
                "Include your account reference code in the memo, instruction, or description field. This code is used to match deposits to your Gemini account. Without it, deposits may be delayed or returned.",
            })}
          </Text.Body>
        </Card>
      )}

      {isInternational || !domesticBankInfo ? (
        <InternationalDepositDetails
          internationalBankInfo={internationalBankInfo}
          isSGD={isSGD}
          isGBPorEUR={isGBPorEUR}
          geminiEntity={geminiEntity}
          currency={currency}
        />
      ) : (
        <DomesticDepositDetails domesticBankInfo={domesticBankInfo} />
      )}

      <RegulatoryFooter />
    </React.Fragment>
  );
};
