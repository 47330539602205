import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { border } from "@gemini-ui/design-system";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

type CardImageProps = {
  isBackface?: boolean;
};

type VideoActiveProps = {
  isActive?: boolean;
};

const revealAnimation = keyframes`
  0% {
    transform: scale(0.96);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const StyledFloatContainer = styled.div<{ loaded?: boolean }>`
  width: 420px;
  height: 265px;
  perspective: 1000px;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  animation: ${({ loaded }) =>
    !loaded
      ? css`
          ${revealAnimation} 1s ease
        `
      : "none"};

  @media ${BREAKPOINTS.mobileDown} {
    width: 256px;
    height: 161.5px;
  }
`;

const SheenAnimation = keyframes`
  0% {
    transform: translateX(-48%);
  }

  50% {
    transform: translateX(75%);
  }

  100% {
    transform: translateX(-48%);
  }
`;

export const StyledInnerFloatContainer = styled.div<{ isFlipped?: boolean; loading?: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s ease-in-out;
  transform-origin: 50% 50%;
  border-radius: 20px;
  box-shadow: -20px 14px 54px rgba(0, 0, 0, 0.15);
  transform: ${({ isFlipped }) => (isFlipped ? "rotateY(-180deg)" : "rotateY(0deg)")}
    scale(${({ loading }) => (loading ? "0.96" : "1")});
`;

export const StyledCardSheen = styled.div<{
  animating?: boolean;
  isBackface?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${border.radius.md};

  ${({ isBackface }) =>
    !isBackface &&
    `
    transform: rotateY(-180deg) translateZ(7px);
  `}

  &::after {
    content: "";
    top: 0;
    animation: ${({ animating }) =>
      animating
        ? css`
            ${SheenAnimation} 0.7s ease
          `
        : "none"};
    animation-delay: 0.1s;
    transform: translateX(-48%);
    width: 100%;
    transition: transform 1s ease-in-out;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0.32;
    background: linear-gradient(
      0.25turn,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.73) 50%,
      rgba(128, 186, 232, 0) 89%,
      rgba(125, 185, 232, 0) 100%
    );
  }
`;

export const StyledDepth = styled.div<{ isSafari?: boolean }>`
  width: 420px;
  height: 265px;
  border-radius: 20px;
  position: absolute;
  transform: translateZ(-2px);
  background: linear-gradient(145deg, #f3f3f3, #777777);
  &:nth-of-type(2) {
    transform: translateZ(-3px);
  }
  ${({ isSafari }) =>
    isSafari &&
    `
    backface-visibility: hidden;
  `}

  &:nth-of-type(3) {
    transform: translateZ(-4px);
  }

  @media ${BREAKPOINTS.mobileDown} {
    width: 256px;
    height: 161.5px;
  }
`;

export const StyledCardFace = styled.img`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  ${(props: CardImageProps) =>
    props.isBackface &&
    `
    transform: rotateY(180deg) translateZ(5px);
  `}
`;

export const StyledName = styled.div`
  backface-visibility: hidden;
  transform: rotateY(180deg) translateZ(9px);
  position: absolute;
  top: 105px;
  left: 0;
  width: 100%;
  text-align: center;

  p {
    background: linear-gradient(0.25turn, #dededf 10%, #c3c2c4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media ${BREAKPOINTS.mobileDown} {
    top: 66px;

    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

export const StyledVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s ease;
  opacity: ${(props: VideoActiveProps) => (props.isActive ? "1" : "0")};

  video {
    width: 100%;
  }
`;

export const StyledBackgroundFrame = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("${({ backgroundImage }) => backgroundImage}");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const StyledAnimationContainer = styled.div`
  position: absolute;
  width: 100%;
  margin: 0 auto;
  transition: opacity 0.3s ease;
  opacity: ${(props: VideoActiveProps) => (props.isActive ? "1" : "0")};
  visibility: ${(props: VideoActiveProps) => (props.isActive ? "visible" : "hidden")};
  z-index: -1;
`;
