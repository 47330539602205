import { createContext } from "react";
import * as Sentry from "@sentry/browser";
import {
  CurrencyShortName,
  CurrencyShortNameFiat,
  CurrencyShortNameSupportedCryptos,
  isCurrency,
} from "@gemini-common/scripts/constants/currencies";
import type { SiteLockout } from "@gemini-ui/components/Lockout/utils";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { ConvertEligible } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { RouteAction } from "@gemini-ui/pages/RetailTrade/constants";
import { getGeminiBalance } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { routeActionToAction } from "@gemini-ui/pages/RetailTrade/utils";
import axios from "@gemini-ui/services/axios";

export const getLockout = (lockout: SiteLockout): SiteLockout | null => {
  const shouldAllowBecauseUnrestricted = lockout === null;
  const shouldAllowBecauseSandbox = lockout === "sandbox";
  const shouldAllow = shouldAllowBecauseUnrestricted || shouldAllowBecauseSandbox;
  // Filter the lockout to override for allowed cases
  return !shouldAllow ? lockout : null;
};

export const WatchlistContext = createContext([]);

export const getTradingPair = (currency: CurrencyShortName, defaultFiat: CurrencyShortNameFiat) =>
  currency ? `${currency}${defaultFiat}` : null;

export const isConvertEnabled = (
  convertEligible: ConvertEligible,
  balances: CurrencyBalances,
  currency: CurrencyShortNameSupportedCryptos
): boolean => {
  if (!convertEligible || !balances) return false;
  const assetConvertEligible = Object.keys(convertEligible).includes(currency);
  if (!isCurrency.EFIL(currency) && !assetConvertEligible) return false;

  return getGeminiBalance(balances, currency) > 0;
};

export const getCoinGeckoData = async (coin: string, latestExchangeTimestamp: number, precision: number) => {
  try {
    const coinGeckoId = supportedCoinGeckoTokenMap[coin?.toLowerCase()]?.id ?? "";
    // coingecko uses unix ts, exchange uses ms - convert to unix
    const endTimestamp = latestExchangeTimestamp / 1000;
    if (coinGeckoId) {
      const response = await axios.get(
        `https://www.gemini.com/api/coins/${coinGeckoId}/market_chart/range?vs_currency=usd&from=0&to=${endTimestamp}&precision=${precision}`
      );
      // prevent duplicates with data from exchange, remove last data point as it may collide with latestExchangeTimestamp
      return response?.data?.prices
        ?.map(price => {
          return { x: price[0], y: price[1] };
        })
        .slice(0, -1);
    } else {
      // no coingecko mapping found so proceed with showing exchange data
      return [];
    }
  } catch (e) {
    // silently fail if we cannot get coingecko data and proceed with displaying 1 year of exchange data
    Sentry.captureException(e);
    throw e;
  }
};

// fetched from https://www.coingecko.com/api/documentation coins/list
// removes all duplicate token symbols to align with mapping in gemini
// @MultiCurrencyAware supported fiat currencies are managed with the following FF: exchange_coingecko_all_time_data
export const supportedCoinGeckoTokenMap = {
  btc: { id: "bitcoin" },
  eth: { id: "ethereum" },
  usdt: { id: "tether" },
  steth: { id: "staked-ether" },
  ada: { id: "cardano" },
  shib: { id: "shiba-inu" },
  xlm: { id: "stellar" },
  ldo: { id: "lido-dao" },
  fil: { id: "filecoin" },
  efil: { id: "filecoin" },
  imx: { id: "immutable-x" },
  arb: { id: "arbitrum" },
  frax: { id: "frax" },
  fxs: { id: "frax-share" },
  ape: { id: "apecoin" },
  chz: { id: "chiliz" },
  pepe: { id: "pepe" },
  gmt: { id: "stepn" },
  comp: { id: "compound-governance-token" },
  hnt: { id: "helium" },
  enj: { id: "enjincoin" },
  lusd: { id: "liquity-usd" },
  iotx: { id: "iotex" },
  sushi: { id: "sushi" },
  lpt: { id: "livepeer" },
  bico: { id: "biconomy" },
  rbn: { id: "ribbon-finance" },
  loom: { id: "loom-network-new" },
  amp: { id: "amp-token" },
  gal: { id: "project-galaxy" },
  knc: { id: "kyber-network-crystal" },
  pla: { id: "playdapp" },
  mpl: { id: "maple" },
  stg: { id: "stargate-finance" },
  bnt: { id: "bancor" },
  orca: { id: "orca" },
  nmr: { id: "numeraire" },
  ach: { id: "alchemy-pay" },
  elon: { id: "dogelon-mars" },
  metis: { id: "metis-token" },
  rad: { id: "radicle" },
  ali: { id: "alethea-artificial-liquid-intelligence-token" },
  keep: { id: "keep-network" },
  tbtc: { id: "tbtc" },
  eul: { id: "euler" },
  tru: { id: "truefi" },
  gfi: { id: "goldfinch" },
  oxt: { id: "orchid-protocol" },
  kp3r: { id: "keep3rv1" },
  zbc: { id: "zebec-protocol" },
  ern: { id: "ethernity-chain" },
  samo: { id: "samoyedcoin" },
  fida: { id: "bonfida" },
  cube: { id: "somnium-space-cubes" },
  moon: { id: "moon" },
  ctx: { id: "cryptex-finance" },
  revv: { id: "revv" },
  jam: { id: "geojam" },
  sbr: { id: "saber" },
  rfr: { id: "refereum" },
  weth: { id: "weth" },
  mco2: { id: "moss-carbon-credit" },
  ash: { id: "ash" },
  wbtc: { id: "wrapped-bitcoin" },
  yfi: { id: "yearn-finance" },
  paxg: { id: "pax-gold" },
  mkr: { id: "maker" },
  bch: { id: "bitcoin-cash" },
  qnt: { id: "quant-network" },
  ilv: { id: "illuvium" },
  aave: { id: "aave" },
  dpi: { id: "defipulse-index" },
  ltc: { id: "litecoin" },
  sol: { id: "solana" },
  wnxm: { id: "wrapped-nxm" },
  zec: { id: "zcash" },
  avax: { id: "avalanche-2" },
  alcx: { id: "alchemix" },
  inj: { id: "injective-protocol" },
  link: { id: "chainlink" },
  atom: { id: "cosmos" },
  ens: { id: "ethereum-name-service" },
  axs: { id: "axie-infinity" },
  uni: { id: "uniswap" },
  dot: { id: "polkadot" },
  bond: { id: "barnbridge" },
  bal: { id: "balancer" },
  snx: { id: "havven" },
  rndr: { id: "render-token" },
  mask: { id: "mask-network" },
  uma: { id: "uma" },
  index: { id: "index-cooperative" },
  api3: { id: "api3" },
  usdc: { id: "usd-coin" },
  busd: { id: "binance-usd" },
  gusd: { id: "gemini-dollar" },
  dai: { id: "dai" },
  mim: { id: "magic-internet-money" },
  xtz: { id: "tezos" },
  matic: { id: "matic-network" },
  storj: { id: "storj" },
  eos: { id: "eos" },
  omg: { id: "omisego" },
  xrp: { id: "ripple" },
  crv: { id: "curve-dao-token" },
  fet: { id: "fetch-ai" },
  ocean: { id: "ocean-protocol" },
  mana: { id: "decentraland" },
  zrx: { id: "0x" },
  sand: { id: "the-sandbox" },
  ray: { id: "raydium" },
  "1inch": { id: "1inch" },
  ftm: { id: "fantom" },
  bat: { id: "basic-attention-token" },
  lrc: { id: "loopring" },
  audio: { id: "audius" },
  grt: { id: "the-graph" },
  cvc: { id: "civic" },
  ddx: { id: "derivadao" },
  doge: { id: "dogecoin" },
  rare: { id: "superrare" },
  wton: { id: "megaton-finance-wrapped-toncoin" },
  skl: { id: "skale" },
  ren: { id: "republic-protocol" },
  qrdo: { id: "qredo" },
  gala: { id: "gala" },
  ankr: { id: "ankr" },
  mir: { id: "mirror-protocol" },
  brd: { id: "bread" },
  slp: { id: "smooth-love-potion" },
  spell: { id: "spell-token" },
  luna: { id: "terra-luna-2" },
  lqty: { id: "liquity" },
  rly: { id: "rally-2" },
  toke: { id: "tokemak" },
};

export const getRouteParams = params => {
  const { currency, action: routeAction } = params;
  const action = routeActionToAction(routeAction as RouteAction);
  return { currency: currency?.toUpperCase() as CurrencyShortNameSupportedCryptos, action };
};
