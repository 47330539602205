import styled from "@emotion/styled";
import { InputSize } from "@gemini-ui/design-system/forms/shared/constants";
import { FORM_ELEMENT_TRANSITION_VALUE } from "@gemini-ui/design-system/forms/shared/utils";
import { border, Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils";

function getSizeStyles(size?: InputSize) {
  if (size === "sm") {
    return `
      font-size: 12px;
      line-height: 20px;
      padding: ${Spacing.scale[1]} ${Spacing.scale[2]};
      height: 52px;
    `;
  }
  if (size === "md") {
    return `
      font-size: 14px;
      line-height: 20px;
      padding: ${Spacing.scale[1.5]} ${Spacing.scale[2]};
      height: 64px;
    `;
  }
  if (size === "lg") {
    return `
      font-size: 16px;
      line-height: 24px;
      padding: ${Spacing.scale[2]};
      height: 80px;
    `;
  }
}

export const StyledTextarea = styled.textarea<{
  hasError?: boolean;
  resize?: CSSStyleDeclaration["resize"];
  size?: InputSize;
}>`
  background: ${({ hasError }) =>
    hasError ? getColor(Colors.red[50], Colors.red[800]) : getColor(Colors.white, Colors.black)};
  border: 1px solid
    ${({ hasError }) =>
      hasError ? getColor(Colors.red[600], Colors.red[400]) : getColor(Colors.gray[200], Colors.gray[700])};
  border-radius: ${border.radius.md};
  color: ${getColor(Colors.black, Colors.white)};
  font-weight: normal;
  resize: ${({ resize }) => resize};
  transition: ${FORM_ELEMENT_TRANSITION_VALUE};
  width: 100%;

  ${({ size }) => getSizeStyles(size)};

  ::placeholder {
    color: ${Colors.gray[500]};
  }

  &:hover {
    border-color: ${({ hasError }) =>
      hasError ? getColor(Colors.red[600], Colors.red[400]) : getColor(Colors.gray[300], Colors.gray[600])};
  }

  &:active,
  &:focus {
    outline: none;
    border-color: ${({ hasError }) =>
      hasError ? getColor(Colors.red[600], Colors.red[400]) : getColor(Colors.black, Colors.white)};
    box-shadow: 0 0 0 1px
      ${({ hasError }) =>
        hasError ? getColor(Colors.red[600], Colors.red[400]) : getColor(Colors.black, Colors.white)};
  }

  &:disabled,
  &:read-only {
    background: ${getColor(Colors.gray[50], Colors.gray[900])};
    border-color: ${getColor(Colors.gray[100], Colors.gray[800])};
    box-shadow: none;
  }

  &:disabled {
    pointer-events: none;
  }

  &:disabled,
  &:disabled::placeholder {
    color: ${getColor(Colors.gray[300], Colors.gray[600])};
  }
`;
