import { IntlShape } from "react-intl";
import { countriesList, CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";

export const CountryStakingTaxInformation = {
  gb: countriesList.gb,
} as const;

export const taxInformation = (
  intl: IntlShape
): Record<keyof typeof CountryStakingTaxInformation, { title: string; badge: string; body: string }[]> => ({
  gb: [
    {
      title: intl.formatMessage({ defaultMessage: "Transfer trading balance" }),
      badge: intl.formatMessage({ defaultMessage: "Taxable event" }),
      body: intl.formatMessage({
        defaultMessage:
          "Transferring assets to Staking is considered a disposition of those assets which may lead to recognition of a capital gain or loss impacting tax liability.",
      }),
    },
    {
      title: intl.formatMessage({ defaultMessage: "Buy new crypto" }),
      badge: intl.formatMessage({ defaultMessage: "Non-taxable event" }),
      body: intl.formatMessage({
        defaultMessage:
          "Buying new crypto to immediately transfer to Staking will not incur a capital gain or loss event, as the transfer will be considered a same-day disposition or the asset at the same price at which the asset was acquired.",
      }),
    },
  ],
});

export const countryHasStakingTaxImplications = (countryCode: CountryAbbreviation) =>
  Boolean(CountryStakingTaxInformation?.[countryCode]);
