import { RegisterOptions } from "react-hook-form";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import MASKS from "@gemini-ui/constants/masks";
import {
  ACCOUNT_NUMBER,
  ACCOUNT_TYPE,
  BANK_NAME,
  BIC,
  IBAN,
  INSTITUTION_NUMBER,
  NAME_ON_ACCOUNT,
  ROUTING_NUMBER,
  SORT_CODE,
  TRANSIT_NUMBER,
} from "@gemini-ui/constants/wireFunding";
import { getRequiredFieldErrorString } from "@gemini-ui/utils/getRequiredFieldErrorString";
import { IntlShape } from "@gemini-ui/utils/intl";
import lengthErrorMessage from "@gemini-ui/utils/lengthErrorMessage";

interface FieldData {
  [key: string]: {
    apiField: string;
    helperText?: string;
    label: string;
    mask?: (string | RegExp)[];
    onChange?: (value: string) => string;
    placeholder?: string;
    validation: RegisterOptions;
  };
}

const isAddPaymentsRevampEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

export const getFieldData = (intl: IntlShape, isUsUser?: boolean, isBankFrickFlowEnabled?: boolean): FieldData => {
  return {
    nameOnAccount: {
      label:
        isAddPaymentsRevampEnabled && isUsUser
          ? intl.formatMessage({ defaultMessage: "Account holder" })
          : intl.formatMessage({ defaultMessage: "Account holder's full name" }),
      apiField: NAME_ON_ACCOUNT,
      validation: {
        required: getRequiredFieldErrorString(intl),
        validate: {
          min2: v => {
            const names = v.split(" ");
            const firstName = names[0];
            const lastName = names[names.length - 1];
            return (
              (names.length >= 2 && firstName.length >= 2 && lastName.length >= 2) ||
              intl.formatMessage({
                defaultMessage: "Account holder's first and last name must each be at least 2 characters",
              })
            );
          },
        },
      },
      helperText: intl.formatMessage({ defaultMessage: "Only bank accounts held under your name can be used" }),
    },
    accountNumber: {
      label: isBankFrickFlowEnabled
        ? intl.formatMessage({ defaultMessage: "IBAN or Account number" })
        : intl.formatMessage({ defaultMessage: "Account number" }),
      apiField: ACCOUNT_NUMBER,
      placeholder: intl.formatMessage({ defaultMessage: "Your bank account number" }),
      mask: MASKS.BANK_ACCOUNT_NUMBER,
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    usBankAccountNumber: {
      label: intl.formatMessage({ defaultMessage: "Account number" }),
      apiField: ACCOUNT_NUMBER,
      mask: MASKS.US_BANK_ACCOUNT_NUMBER,
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    bankAddress: {
      label: intl.formatMessage({ defaultMessage: "Bank address" }),
      apiField: intl.formatMessage({ defaultMessage: "bankAddress" }),
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    bankName: {
      label: intl.formatMessage({ defaultMessage: "Bank name" }),
      apiField: BANK_NAME,
      placeholder: intl.formatMessage({ defaultMessage: "Bank name" }),
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    bic: {
      label: isBankFrickFlowEnabled
        ? intl.formatMessage({ defaultMessage: "BIC or SWIFT code" })
        : intl.formatMessage({ defaultMessage: "Bank routing number (BIC/SWIFT)" }),
      apiField: BIC,
      mask: MASKS.SWIFT,
      placeholder: intl.formatMessage({ defaultMessage: "BIC/SWIFT routing number" }),
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
      onChange: value => value.toUpperCase(),
    },
    transitNumber: {
      label: intl.formatMessage({ defaultMessage: "Transit number", description: "For CAD Wire transfers" }),
      apiField: TRANSIT_NUMBER,
      mask: MASKS.CAD_TRANSIT_NUMBER,
      validation: {
        validate: {
          length: v =>
            v.length === MASKS.CAD_TRANSIT_NUMBER.length || lengthErrorMessage(intl, MASKS.CAD_TRANSIT_NUMBER.length),
        },
      },
    },
    iban: {
      label: intl.formatMessage({ defaultMessage: "IBAN/Bank account number" }),
      apiField: IBAN,
      placeholder: intl.formatMessage({ defaultMessage: "Your bank account number" }),
      mask: MASKS.BANK_ACCOUNT_NUMBER,
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    institutionNumber: {
      label: intl.formatMessage({ defaultMessage: "Institution number" }),
      apiField: INSTITUTION_NUMBER,
      mask: MASKS.CAD_INSTITUTION_NUMBER,
      validation: {
        validate: {
          length: v =>
            v.length === MASKS.CAD_INSTITUTION_NUMBER.length ||
            lengthErrorMessage(intl, MASKS.CAD_INSTITUTION_NUMBER.length),
        },
      },
    },
    swiftOrRoutingNumber: {
      label:
        isAddPaymentsRevampEnabled && isUsUser
          ? intl.formatMessage({ defaultMessage: "Wire routing number or SWIFT" })
          : intl.formatMessage({ defaultMessage: "Wire routing number or SWIFT/BIC" }),
      apiField: ROUTING_NUMBER,
      mask: MASKS.SWIFT_OR_WIRE_ROUTING,
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    sortCode: {
      label: intl.formatMessage({ defaultMessage: "Sort code", description: "For wire transfers" }),
      apiField: SORT_CODE,
      mask: MASKS.SORT_CODE,
      validation: {
        required: getRequiredFieldErrorString(intl),
      },
    },
    routingNumber: {
      label:
        isAddPaymentsRevampEnabled && isUsUser
          ? intl.formatMessage({ defaultMessage: "ACH/Electronic routing number" })
          : intl.formatMessage({ defaultMessage: "ACH routing number" }),
      apiField: ROUTING_NUMBER,
      mask: MASKS.WIRE_ROUTING,
      validation: {
        required: getRequiredFieldErrorString(intl),
        validate: {
          length: v => v.length === MASKS.WIRE_ROUTING.length || lengthErrorMessage(intl, MASKS.WIRE_ROUTING.length),
        },
      },
    },
    accountType: {
      label: intl.formatMessage({ defaultMessage: "Account type", description: "eg. Checkings vs Savings" }),
      apiField: ACCOUNT_TYPE,
      validation: {
        required: intl.formatMessage({ defaultMessage: "You must select an option." }),
      },
    },
  };
};

export const transformAddWireValues = (values, overrides = {}) => {
  const { swiftOrRoutingNumber, ...rest } = values;
  const transformedValues = {} as any;

  if (values.sortCode) {
    transformedValues.sortCode = values.sortCode.replace(/-/g, "");
  }
  const CHECKING = "Checking";
  return {
    accountType: CHECKING,
    routingNumber: swiftOrRoutingNumber,
    ...rest,
    ...transformedValues,
    ...overrides,
  };
};
