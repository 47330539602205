/* 
  Framer motion has a few different types of animations.
  I.e. "Transition", "Orchestration", "Tween", "Spring", "Inertia"

  https://www.framer.com/api/motion/types/

  This is to be a central location for default animation values 
  to influence some consistency
*/
const TYPE = "spring";

export const Animations = {
  transitions: {
    /**
     * Default animation value for transitions moving along the y axis/vertically
     */
    layoutY: {
      type: TYPE,
      bounce: 0.15,
      duration: 0.5,
    },

    /**
     * Default animation value for opacity changes
     */
    opacity: {
      type: TYPE,
      duration: 0.85,
    },
  },
  // orchestration: {},
  // tween: {},
  // spring: {},
  // etc.
};
