import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import { useInterval, useTimeoutFn } from "react-use";
import { applicationApi } from "@gemini-ui/client/api";
import { ApplicationStatusStateStateEnum } from "@gemini-ui/client/credit";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import { Flex, IllustrationOneMoreStep, Modal, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { UserData, XStateSend } from "@gemini-ui/pages/Credit/CreditApplication/v2/constants";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/v2/machines/creditAppMachine";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/v2/styles";
import { TIME } from "@gemini-ui/pages/Credit/utils";
import { useLockout } from "@gemini-ui/services/user/lockout";
import { useIntl } from "@gemini-ui/utils/intl";

interface CheckingDocumentsModalProps {
  user: UserData;
  send: XStateSend;
  onClose: () => void;
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckingDocumentsModal = ({ user, send, onClose, setIsComplete }: CheckingDocumentsModalProps) => {
  const [applicationState, setApplicationState] = useState<ApplicationStatusStateStateEnum>(null);
  const [isFullyVerified, setIsFullyVerified] = useState(window.initialData?.templateProps?.user?.isFullyVerified);
  const { lockout, isLoadingLockout, fetchLockout } = useLockout();
  const { showAlert } = useAlert();
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  const fetchApplicationData = useCallback(async () => {
    try {
      const { data } = await applicationApi.getApplication(user.appId);
      await fetchLockout();
      const { statusHistory } = data;
      const latestStatus = statusHistory[statusHistory.length - 1]?.state;
      setApplicationState(latestStatus);
      setIsFullyVerified(!isLoadingLockout && !lockout);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [lockout, fetchLockout, isLoadingLockout, user.appId]);

  useInterval(fetchApplicationData, !isLoadingLockout && lockout === LOCKOUT_TYPES.PENDING ? TIME.SECONDS[10] : null);

  useTimeoutFn(() => send(ClientState.MANUAL_ENTRY), TIME.MINUTES[2.5]);

  useEffect(() => {
    fetchApplicationData();
  }, [fetchApplicationData]);

  useEffect(() => {
    const latestLockoutStatus: LOCKOUT_TYPES = lockout;

    // Check if user has been removed from lockout state & fully verified
    if (
      (!latestLockoutStatus && isFullyVerified) ||
      applicationState === ApplicationStatusStateStateEnum.BiometricPassed
    ) {
      setIsComplete(true);
      onClose();
    }

    if (latestLockoutStatus === LOCKOUT_TYPES.UNVERIFIED) {
      onClose();
      showAlert({
        type: AlertTypes.INFO,
        message: intl.formatMessage({
          defaultMessage: "We couldn't verify your documents. Please try uploading them again.",
        }),
      });
    }

    if (
      latestLockoutStatus === LOCKOUT_TYPES.FROZEN ||
      applicationState === ApplicationStatusStateStateEnum.BiometricDenied ||
      applicationState === ApplicationStatusStateStateEnum.BiometricManual
    ) {
      send(ClientState.PREQUAL_DENIED);
    }
  }, [applicationState, send, lockout, isFullyVerified, showAlert, intl, setIsComplete, onClose]);

  return (
    <Modal isOpen hideCloseButton centered>
      <Flex flexDirection="column" alignItems="center" overflow="hidden">
        <IllustrationOneMoreStep />
        <ViewTitle>
          <Text.Heading size="md">
            {intl.formatMessage({ defaultMessage: "Hang tight. This should only take a moment." })}
          </Text.Heading>
          <Text.Body color={colorScheme.content.secondary} size="sm" mb={2}>
            {intl.formatMessage({ defaultMessage: "We’re checking your documents..." })}
          </Text.Body>
        </ViewTitle>
        <SpinnerAnimation center />
      </Flex>
    </Modal>
  );
};
