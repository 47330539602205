import React, { useCallback, useEffect, useRef } from "react";
import * as Sentry from "@sentry/browser";
import { useTimeoutFn } from "react-use";
import { CreditCardParams } from "@gemini-ui/pages/register/Verify/types";
import { getOnfidoSdkToken } from "@gemini-ui/services/onboarding/getOnfidoSdkToken";

export function useFetchOnfidoSDKToken(creditCardParams?: CreditCardParams, existingApplicant?: boolean) {
  const didCancel = useRef(false);
  const [onfidoSDKToken, setOnfidoSDKToken] = React.useState("");

  const fetchOnfidoToken = useCallback(async () => {
    try {
      const response = await getOnfidoSdkToken(creditCardParams, existingApplicant);
      if (!didCancel.current) {
        setOnfidoSDKToken(response.token);
      }
    } catch (err) {
      Sentry.captureMessage(
        `Failed to fetch onfido sdk token - error: ${err.message} -- creditCardParams: ${
          creditCardParams ? [Object.keys(creditCardParams)] : []
        }`,
        Sentry.Severity.Error
      );
    }
  }, [creditCardParams, existingApplicant]);

  const reinitializeOnfido = () => {
    Sentry.captureMessage(
      `Onfido token - refetching after reaching timeout creditCardParams: ${
        creditCardParams ? [Object.keys(creditCardParams)] : []
      }`,
      Sentry.Severity.Info
    );
    // force component to unmount and reinit with new token
    setOnfidoSDKToken("");
    fetchOnfidoToken();
  };
  // 60 minutes
  useTimeoutFn(reinitializeOnfido, 3600000);

  useEffect(() => {
    fetchOnfidoToken();
    return () => {
      didCancel.current = true;
    };
  }, [fetchOnfidoToken]);

  return onfidoSDKToken;
}
