import styled from "@emotion/styled";
import { InputSize } from "@gemini-ui/design-system/forms/shared/constants";
import { border, Colors } from "@gemini-ui/design-system/primitives";

export const StyledInput = styled.input<{ inputSize?: InputSize }>`
  + .selected {
    display: none;
  }

  :checked {
    ~ .unselected {
      display: none;
    }
    + .selected {
      display: inline-flex;
    }
  }

  :focus-visible ~ * {
    box-shadow: inset 0 0 0 2px ${Colors.blue[300]}, 0 0 0 1px ${Colors.blue[300]};
    border-radius: ${border.radius.sm};
  }

  ${({ inputSize }) =>
    inputSize === "sm" &&
    `
    ~ * {
      height: 10px;
      width: 10px;
    }
  `}
`;
