export const CONVERT_EVENTS = {
  FROM_ASSET_SELECTED: {
    name: "Convert from asset selected",
  },
  TO_ASSET_SELECTED: {
    name: "Convert to asset selected",
  },
  DONT_SEE_ASSET_MODAL_OPENED: {
    name: "Don't See Asset Modal Opened",
  },
};

export enum BuyReviewFlowModal {
  NONE = "NONE",
  SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD",
  ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD",
  STAKING_AGREEMENT = "STAKING_AGREEMENT",
  STAKING_COUNTRY_TAX_INFO = "STAKING_COUNTRY_TAX_INFO",
  STAKING_ASSET_DISCLAIMER = "STAKING_ASSET_DISCLAIMER",
}

export enum OrderType {
  Instant = "Instant",
  Limit = "Limit",
  Recurring = "Recurring",
}

export enum OrderStatus {
  CANCELED = "CANCELED",
  PARTIALLY_FILLED = "PARTIALLY_FILLED",
  FILLED = "FILLED",
  OPEN = "OPEN",
}

export enum QuoteErrorType {
  Form = "Form",
  Order = "Order",
}

export interface QuoteError {
  message: string;
  errorType: QuoteErrorType;
}
