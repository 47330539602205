export const snapEvents = {
  // Snapchat doesnt support custom event names so we'll map
  // the events we want to track to names snapchat does support
  pageView: "PAGE_VIEW",
  placeTrade: "PURCHASE",
  depositFunds: "ADD_BILLING",
  signUp: "SIGN_UP",
  addCart: "ADD_CART",
  confirmPhone: "RESERVE",
  ccApplicationComplete: "VIEW_CONTENT",
  ccApplicationStart: "START_CHECKOUT",
  ccConfirmNameAndAddress: "ADD_TO_WISHLIST",
};

export function snapchatTrack(eventName: string, eventProperties: any = {}) {
  if (!window.snaptr) {
    return;
  }
  window.snaptr("track", eventName, eventProperties);
}
