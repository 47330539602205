import React from "react";
import BaseButton from "@gemini-ui/design-system/Button/ButtonBase";
import ButtonGroup from "@gemini-ui/design-system/Button/ButtonGroup";
import { ButtonGroupProps, ButtonProps, TertiaryButtonProps } from "@gemini-ui/design-system/Button/constants";
import DangerButton from "@gemini-ui/design-system/Button/DangerButton";
import PositiveButton from "@gemini-ui/design-system/Button/PositiveButton";
import PrimaryButton from "@gemini-ui/design-system/Button/PrimaryButton";
import SecondaryButton from "@gemini-ui/design-system/Button/SecondaryButton";
import TertiaryButton from "@gemini-ui/design-system/Button/TertiaryButton";

export * from "./constants";

interface ButtonWithRefProps
  extends React.ForwardRefExoticComponent<
    (ButtonProps | ButtonGroupProps | TertiaryButtonProps) & React.RefAttributes<HTMLButtonElement>
  > {
  Primary: typeof PrimaryButton;
  Secondary: typeof SecondaryButton;
  Tertiary: typeof TertiaryButton;
  /**
   * @deprecated Please use the `tone` prop to specify the bg color of the button.
   * @example
   * <Button.Primary tone="negative" ... />
   */
  Danger: typeof DangerButton;
  /**
   * @deprecated Please use the `tone` prop to specify the bg color of the button.
   * @example
   * <Button.Primary tone="positive" ... />
   */
  Positive: typeof PositiveButton;
  Group: typeof ButtonGroup;
}

const Button = BaseButton as ButtonWithRefProps;

Button.Primary = PrimaryButton;

Button.Secondary = SecondaryButton;

Button.Tertiary = TertiaryButton;

Button.Danger = DangerButton;

Button.Positive = PositiveButton;

Button.Group = ButtonGroup;

export { BaseButton, Button };
