import React, { ReactNode, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Spacing } from "@gemini-ui/design-system/primitives/spacing";
import { SectionMessage } from "@gemini-ui/design-system/SectionMessage";
import { Text } from "@gemini-ui/design-system/Text";
import { useIntl } from "@gemini-ui/utils/intl";

interface FormErrorLinks {
  href: string;
  text: ReactNode;
}

const STATUS_TYPE = "alert";

const BulletedLI = styled.li`
  list-style: disc;
  margin-left: ${Spacing.scale[2]};
`;

const MIN_ERRORS_FOR_SUMMARY = 3;

export function SectionMessageFormErrors({ formErrorLinks }: { formErrorLinks: FormErrorLinks[] }) {
  const { intl } = useIntl();
  const HEADING_TEXT = intl.formatMessage({ defaultMessage: "Errors found" });
  const firstLinkRef = useRef<HTMLAnchorElement>(null);
  // Capturing initial error count to ensure the error summary persists until all errors are resolved
  const initialErrorCount = useRef(formErrorLinks.length);
  const hasMinimumErrorCount = initialErrorCount.current >= MIN_ERRORS_FOR_SUMMARY;

  useEffect(() => {
    if (!hasMinimumErrorCount) return;

    firstLinkRef?.current.focus();
  }, [hasMinimumErrorCount]);

  if (!hasMinimumErrorCount) return null;

  return (
    <SectionMessage heading={HEADING_TEXT} statusType={STATUS_TYPE}>
      <ul>
        {formErrorLinks.map((item, index) => {
          return (
            <BulletedLI key={item.href}>
              <Text.Link fontSize="14px" href={item.href} ref={index === 0 ? firstLinkRef : null}>
                {item.text}
              </Text.Link>
            </BulletedLI>
          );
        })}
      </ul>
    </SectionMessage>
  );
}
