import React, { Fragment, MouseEvent, ReactNode, useEffect, useState } from "react";
import { IconChevronDownSmall, IconChevronUpSmall } from "@hubble/icons";
import { useId } from "react-id-generator";
import { Card } from "@gemini-ui/design-system/cards/Card";
import { CardProps } from "@gemini-ui/design-system/cards/Card/constants";
import {
  CardCollapsibleButton,
  CardCollapsibleContent,
  CardCollapsibleHeader,
  CardCollapsibleHeading,
} from "@gemini-ui/design-system/cards/CardCollapsible/styles";
import { useIntl } from "@gemini-ui/utils/intl";

const COLLAPSIBLE_ID_PREFIX = "card-expanded-content-";

interface CardCollapsibleProps extends CardProps {
  heading: ReactNode;
  isOpen?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  subheading?: ReactNode;
}

export const CardCollapsible = ({
  children,
  heading,
  isOpen = undefined,
  onClick,
  padding = "md",
  subheading,
  variant,
  ...props
}: CardCollapsibleProps) => {
  const { intl } = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(typeof isOpen !== "undefined" ? isOpen : false);
  const [collapsibleId] = useId(1, COLLAPSIBLE_ID_PREFIX);

  const handleExpandedClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick(e); // allow parent to have control over click event

      // use internally managed state unless parent passes state in
      if (typeof isOpen === "undefined") setIsExpanded(previous => !previous);
    } else setIsExpanded(previous => !previous);
  };

  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]);

  return (
    <Card isCollapsible padding={padding} variant={variant} data-expanded={isExpanded} {...props}>
      <CardCollapsibleHeader padding={padding}>
        <CardCollapsibleHeading isExpanded={isExpanded} padding={padding}>
          <div>{heading}</div>
          {subheading && <div>{subheading}</div>}
        </CardCollapsibleHeading>
        <CardCollapsibleButton
          aria-controls={isExpanded ? collapsibleId : undefined}
          aria-expanded={isExpanded}
          aria-label={intl.formatMessage({ defaultMessage: "Toggle collapsed content" })}
          data-testid="card-collapsible"
          icon={
            <Fragment>
              <IconChevronDownSmall aria-hidden className="unselected" size="sm" />
              <IconChevronUpSmall aria-hidden className="selected" size="sm" />
            </Fragment>
          }
          onClick={handleExpandedClick}
          display="inline-flex"
        />
      </CardCollapsibleHeader>
      {isExpanded && (
        <CardCollapsibleContent padding={padding} id={collapsibleId}>
          {children}
        </CardCollapsibleContent>
      )}
    </Card>
  );
};
