import Cookies from "js-cookie";
import { EVENTS, resetAnalytics, track } from "@gemini-ui/analytics";
import { UNREAD_COUNT_COOKIE_NAME } from "@gemini-ui/components/Header/navigation/NotificationCenter/constants";
import { User } from "@gemini-ui/constants/templateProps/users";

export enum PopoverId {
  ACCOUNT_SWITCHER = "AccountSwitcher",
  FIAT = "Fiat",
  TRANSFER = "Transfer",
  ACCOUNT = "Account",
  NONE = "None",
}

export type PopIds = "AccountSwitcher" | "Fiat" | "Transfer" | "Account" | "None";

export const handleLogout = () => {
  Cookies.remove(UNREAD_COUNT_COOKIE_NAME);
  sessionStorage.clear();
  track(EVENTS.SIGN_OUT.name);
  resetAnalytics();
};

export function userHasCustodyAccount(user: User) {
  return Boolean(user?.subaccounts.find(acct => !acct.trading));
}
