import * as Sentry from "@sentry/browser";
import { differenceInDays, format, formatDistanceStrict, isSameDay, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { capitalize } from "lodash";
import { accountApi, authApi, cardsApi, paymentApi, rewardApi } from "@gemini-ui/client/api";
import {
  Card,
  CardStatusEnum,
  CreditAccount,
  InvitationStatus,
  OwedRewardsBalanceResponse,
} from "@gemini-ui/client/credit";
import { Alert, AlertContext, AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { TEXT_DATE_LIMIT } from "@gemini-ui/pages/Credit/CreditDashboard/constants";
import { SENTRY_TAGS, SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { getError } from "@gemini-ui/utils/error";

export const getOwedRewards = async (
  creditAccountId: string,
  showAlert?: (alert: Alert) => void
): Promise<OwedRewardsBalanceResponse> => {
  try {
    const { data } = await rewardApi.getOwedRewardAmount(creditAccountId);
    return data;
  } catch (err) {
    showAlert && showAlert({ type: AlertTypes.ERROR, message: getError(err) });
  }
};

export const getRewardStats = async (creditAccountId: string, callback?: (isSuccess: boolean) => void) => {
  try {
    const { data } = await rewardApi.getRewardStats(creditAccountId);
    return data;
  } catch (err) {
    Sentry.captureException(err);
    callback && callback(false);
  }
};

export const getLatestCard = async (showAlert?: (alert: Alert) => void): Promise<Card> => {
  try {
    const { data } = await cardsApi.getCards();
    return data[0];
  } catch (err) {
    showAlert ? showAlert({ type: AlertTypes.ERROR, message: getError(err) }) : Sentry.captureException(err);
  }
};

export const getActiveCard = async (showAlert?: (alert: Alert) => void): Promise<Card> => {
  try {
    const { data } = await cardsApi.getActiveCard();
    return data;
  } catch (err) {
    showAlert ? showAlert({ type: AlertTypes.ERROR, message: getError(err) }) : Sentry.captureException(err);
  }
};

export const getActivationStatus = (cardData: Card) => {
  return cardData?.canBeActivated && !cardData?.isActivated && cardData?.status === CardStatusEnum.Active;
};

export const onCancelPayment = async (
  cardId: string,
  scheduleId: string,
  showAlert?,
  showToast?,
  callback?: (isSuccess: boolean) => void
) => {
  try {
    await paymentApi.cancelPayment(cardId, scheduleId);
    showToast &&
      showToast({
        message: "Successfully canceled payment.",
      });
    callback && callback(true);
  } catch (err) {
    showAlert && showAlert({ type: AlertTypes.ERROR, message: getError(err) });
    callback && callback(false);
    return false;
  }
};

let getAccountCache = null;
export const getAccountData = async (showAlert?: AlertContext["showAlert"]): Promise<CreditAccount> => {
  if (!getAccountCache) {
    getAccountCache = accountApi.listAccounts();
  }

  try {
    const { data } = await getAccountCache;
    const [account]: CreditAccount[] = data;
    return account ?? null;
  } catch (e) {
    showAlert
      ? showAlert({ type: AlertTypes.ERROR, message: getError(e) })
      : Sentry.withScope(function (scope) {
          scope.setTag(SENTRY_TAGS.Team, SENTRY_TEAMS.CryptoBack);
          Sentry.captureException(e);
        });
    return null;
  }
};

export const getActiveAuthUser = async (hashedAuthUserId: string) => {
  try {
    const { data } = await authApi.getAuthUser(hashedAuthUserId);
    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const removeAuthUser = async (authUserId: string, showAlert?: AlertContext["showAlert"]): Promise<Boolean> => {
  try {
    await authApi.removeAuthUser(authUserId);
    return true;
  } catch (e) {
    showAlert ? showAlert({ type: AlertTypes.ERROR, message: getError(e) }) : Sentry.captureException(e);
    return false;
  }
};

export const updateInvitedAuthUser = async (
  authUserId: string,
  status: InvitationStatus,
  showAlert?: AlertContext["showAlert"]
): Promise<Boolean> => {
  try {
    await authApi.updateInvitationStatus(authUserId, { status });
    return true;
  } catch (e) {
    showAlert ? showAlert({ type: AlertTypes.ERROR, message: getError(e) }) : Sentry.captureException(e);
    return false;
  }
};

let getCurrentRewardCache = null;
export const resetCurrentRewardCache = () => (getCurrentRewardCache = null);
export const getCurrentReward = async (creditAccountId: string, callback?: (isSuccess: boolean) => void) => {
  if (!getCurrentRewardCache) {
    getCurrentRewardCache = rewardApi.getCurrentRewardCurrency(creditAccountId);
  }

  try {
    const { data } = await getCurrentRewardCache;
    return data;
  } catch (err) {
    Sentry.captureException(err);
    callback && callback(false);
  }
};

export enum TimeZone {
  EASTERN = "America/New_York",
  LOCAL = "local",
}

export const getRelativeTime = (date?: string | Date, timezone = TimeZone.EASTERN): Date => {
  const _date = !date ? new Date() : new Date(date);

  if (timezone === TimeZone.LOCAL) {
    return _date;
  } else {
    return utcToZonedTime(_date, timezone);
  }
};

export const formatDate = (date: string, timezone = TimeZone.EASTERN) => {
  const dateTZ = startOfDay(getRelativeTime(date, timezone));
  const now = startOfDay(getRelativeTime());
  const daysFromNow = differenceInDays(now, dateTZ);
  const isToday = isSameDay(now, dateTZ);
  const dateFromNow = formatDistanceStrict(dateTZ, now, { addSuffix: true, roundingMethod: "ceil" });
  const capitalizedDateFromNow = capitalize(dateFromNow);
  const dateText = format(dateTZ, DateFormats.MonthAbbrevDayYear);

  if (isToday) return "Today";
  return daysFromNow > -Math.abs(TEXT_DATE_LIMIT) && daysFromNow < TEXT_DATE_LIMIT ? capitalizedDateFromNow : dateText;
};

export const getCleanRoute = (route: string) => {
  if (window.location.hostname === "localhost") {
    // escape hatch for Cypress integration testing on localhost
    return route;
  }

  const host = window.location.host;
  const cleanHost = host.split("creditcard.")[1];

  return `https://${cleanHost}${route}`;
};

export const renderAvailableCredit = ({ availableBalance, remainingCycleSpendLimit }) => {
  if (availableBalance && remainingCycleSpendLimit) {
    return Math.min(Number(availableBalance.value), Number(remainingCycleSpendLimit.value));
  }

  return availableBalance.value;
};

export const renderCurrentSpend = ({ statementRunningBalance, availableBalance, creditLimit }) => {
  if (statementRunningBalance && availableBalance && creditLimit) {
    const currentSpend = Number(creditLimit.value) - Number(availableBalance) - Number(statementRunningBalance.value);
    if (currentSpend > 0) {
      return currentSpend;
    }
  }
  return 0;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
