import { IntlShape } from "@gemini-ui/utils/intl";

const passwordHasCharClasses = function (password) {
  let classes = 0;
  classes += /[A-Z]/.test(password) ? 1 : 0;
  classes += /[a-z]/.test(password) ? 1 : 0;
  classes += /[0-9]/.test(password) ? 1 : 0;
  classes += /[^A-Za-z0-9]/.test(password) ? 1 : 0;
  return classes >= 2;
};

// From zxcvbn.js, filtered to just passwords that meet our other requirements (don't read this if you're squeamish)
// @ts-expect-error
import commonPwFile from "raw-loader!@common-passwords";

export const commonPws = Object.create(null);
for (const pw of commonPwFile.split("\n")) {
  commonPws[pw] = true;
}

const validate = function (password) {
  password = password || "";
  const isLongEnough = password.length >= 8;
  const hasCharClasses = passwordHasCharClasses(password);
  const isUncommon = !(password.toLowerCase() in commonPws) && password.length >= 5; // the redundant length requirement...
  // makes the validation circle indicator start as a nice pleasing grey
  const isntTooLong = password.length <= 72;
  const doesntNeedTrimming = password === password.trim();
  const isValid = isLongEnough && hasCharClasses && isUncommon && isntTooLong && doesntNeedTrimming;
  return { isLongEnough, hasCharClasses, isUncommon, isntTooLong, doesntNeedTrimming, isValid };
};

const isDynamicRequirementsValid = function (password: string) {
  const pwValidation = validate(password);
  return pwValidation.hasCharClasses && pwValidation.isLongEnough;
};

const message = function (password, intl: IntlShape) {
  const validation = validate(password);
  const errors = [];
  if (!validation.isLongEnough) {
    errors.push(intl.formatMessage({ defaultMessage: "Your password must be at least 8 characters." }));
  }
  if (!validation.hasCharClasses) {
    errors.push(
      intl.formatMessage({ defaultMessage: "Your password must contain an upper-case letter, symbol, or number." })
    );
  }
  if (!validation.isUncommon) {
    errors.push(intl.formatMessage({ defaultMessage: "You may not choose a commonly-used password." }));
  }
  if (!validation.isntTooLong) {
    errors.push(intl.formatMessage({ defaultMessage: "Your password must be 72 characters or less." }));
  }
  if (!validation.doesntNeedTrimming) {
    // but not if it doesn't not need no trimming
    errors.push(intl.formatMessage({ defaultMessage: "Your password must not start or end with a space." }));
  }
  if (errors.length === 0 && !validation.isValid) {
    errors.push(intl.formatMessage({ defaultMessage: "You must enter a valid password." }));
  }
  return errors;
};

export default { message, validate, isDynamicRequirementsValid };
