import { countriesList, CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";

// countries should all have same ux as UK (gb)
const europeanEntityCountries = {
  gb: countriesList.gb,
  je: countriesList.je,
  im: countriesList.im,
  gg: countriesList.gg,
  gi: countriesList.gi,
};

const irelandEntityCountries = {
  at: countriesList.at,
  be: countriesList.be,
  bg: countriesList.bg,
  ch: countriesList.ch,
  cy: countriesList.cy,
  cz: countriesList.cz,
  de: countriesList.de,
  dk: countriesList.dk,
  ee: countriesList.ee,
  es: countriesList.es,
  fi: countriesList.fi,
  fr: countriesList.fr,
  gr: countriesList.gr,
  hr: countriesList.hr,
  hu: countriesList.hu,
  is: countriesList.is,
  it: countriesList.it,
  li: countriesList.li,
  lt: countriesList.lt,
  lu: countriesList.lu,
  lv: countriesList.lv,
  mt: countriesList.mt,
  nl: countriesList.nl,
  no: countriesList.no,
  pl: countriesList.pl,
  pt: countriesList.pt,
  ro: countriesList.ro,
  se: countriesList.se,
  si: countriesList.si,
  sk: countriesList.sk,
};

export const isCountryInEuropeEntity = (countryCode: CountryAbbreviation) =>
  Boolean(europeanEntityCountries[countryCode]);

export const isCountryInIrelandEntity = (countryCode: CountryAbbreviation) =>
  Boolean(irelandEntityCountries[countryCode]);

export const isCountryColombia = (countryCode: CountryAbbreviation) => countriesList[countryCode] === countriesList.co;

export const isCountryIreland = (countryCode: CountryAbbreviation) => countriesList[countryCode] === countriesList.ie;

export const isCountryUnitedKingdom = (countryCode: CountryAbbreviation) =>
  countriesList[countryCode] === countriesList.gb;

export const isFinePrintCountry = (countryCode: CountryAbbreviation): boolean =>
  isCountryColombia(countryCode) || isCountryIreland(countryCode) || isCountryUnitedKingdom(countryCode);
