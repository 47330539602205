import { IntlShape } from "react-intl";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";

export const createProviderTypeSelectionOptions = (
  providerTypes: GrowProviderType[],
  intl: IntlShape
): SelectOptionProps<GrowProviderType>[] => {
  const options = {
    [GrowProviderType.POOLED_STAKING]: {
      value: GrowProviderType.POOLED_STAKING,
      label: intl.formatMessage({ defaultMessage: "Shared" }),
      subLabel: intl.formatMessage({ defaultMessage: "Stake any amount of crypto on shared validators." }),
    },
    [GrowProviderType.PRIVATE_STAKING]: {
      value: GrowProviderType.PRIVATE_STAKING,
      label: intl.formatMessage({ defaultMessage: "Solo" }),
      subLabel: intl.formatMessage({ defaultMessage: "Private validators, requiring minimum 32 ETH." }),
    },
  };

  return providerTypes.map(providerType => options[providerType]);
};
