import { CurrencyShortName, CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { KeyOfTransferMechanismName } from "@gemini-ui/pages/transfers/constants";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";

const { TransferPageController } = jsRoutes.controllers.transfer.page;
const { DepositWireController } = jsRoutes.controllers.transfer;
const { XfersSettingsController } = jsRoutes.controllers.settings;

/*********************************
 *  Crypto
 **********************************/
export const getCryptoDepositDetails = (asset: CurrencyShortNameCrypto, accountHash: string) => {
  return axios.get(`/transfer/deposit/${asset.toLowerCase()}/details`, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
    retry: 3,
  });
};

export const getQRCodeUrl = (asset: CurrencyShortNameCrypto, address: string) => {
  // NOTE: Uses old url structure for now
  return `/deposit/${asset.toLowerCase()}/qr/${address}`;
};

interface DepositLabel {
  address: string;
  label: string;
  memo?: string;
}
export const updateDepositLabel = (asset: CurrencyShortName, accountHash: string, depositLabel: DepositLabel) => {
  return axios.post(`/transfer/deposit/${asset.toLowerCase()}/label`, depositLabel, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
  });
};

export const getNewCryptoAddress = (
  asset: CurrencyShortName,
  accountHash: string,
  {
    multiAddressFormat,
    useBackComp,
  }: {
    multiAddressFormat?: boolean;
    useBackComp?: boolean;
  }
) => {
  return axios.post(
    `/transfer/deposit/${asset.toLowerCase()}`,
    { ...(multiAddressFormat ? { useBackComp } : {}) },
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash, "Content-Type": "application/json" },
    }
  );
};

export const getNewMpcCustodyAddress = (currency: CurrencyShortName, accountHash: string) => {
  return axios.post(
    jsRoutes.controllers.custody.CustodyDashboardController.createMPCCustodyAddress(currency).url,
    {},
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash, "Content-Type": "application/json" },
    }
  );
};

/*********************************
 *  Fiat
 *********************************/
export const getFiatDepositDetails = (asset: CurrencyShortName, accountHash: string) => {
  return axios.get(TransferPageController.showDepositSection(asset.toLowerCase()).url, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
  });
};

export const getFiatDepositWireInstructions = (asset: CurrencyShortName, accountHash: string) => {
  return axios.get(DepositWireController.wireDepositDetails(asset.toLowerCase()).url, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
  });
};

export const getXfersVAInstructions = (asset: CurrencyShortName, accountHash: string) => {
  console.log("getXfersVAInstructions");
  return axios.get(XfersSettingsController.getDepositInstructions(asset.toLowerCase()).url, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
  });
};

export const getStraitsXDepositInstructions = (asset: CurrencyShortName, accountHash: string) => {
  return axios.get(XfersSettingsController.getStraitsxDepositInstructions(asset.toLowerCase()).url, {
    headers: { [HEADERS.ACCOUNT_ID]: accountHash },
  });
};

export const getFiatDepositRtpInstructions = (
  asset: CurrencyShortName,
  accountHash: string,
  { uuid }: { uuid: string },
  transferMechanismName: String,
  sendEmail
) => {
  return axios.post(
    `/payments/deposit/instructions?currency=${asset}&mechanism=${transferMechanismName}&sendEmail=${sendEmail}`,
    {},
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash },
    }
  );
};

export const postFiatDeposit = (
  asset: CurrencyShortName,
  accountHash: string,
  { uuid, amount }: { uuid: string; amount: number | string },
  transferMechanismName: KeyOfTransferMechanismName,
  bcoloDynamicKey?: string,
  payPalData?: { deviceData?: string }
) => {
  return axios.post(
    TransferPageController.deposit(asset.toLowerCase()).url,
    {
      amount: {
        currency: asset,
        value: amount,
      },
      paymentMethodId: uuid,
      mechanism: transferMechanismName,
      bcoloDynamicKey,
      payPalData,
    },
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash },
    }
  );
};

export const getFiatDepositCbitInstructions = (asset: CurrencyShortName, accountHash: string, sendEmail) => {
  return axios.post(
    `/payments/deposit/instructions/CBIT?currency=${asset}&sendEmail=${sendEmail}`,
    {},
    {
      headers: { [HEADERS.ACCOUNT_ID]: accountHash },
    }
  );
};
