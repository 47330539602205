import { useEffect } from "react";
import { enums } from "@optimizely/optimizely-sdk";
import { optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export enum DecisionType {
  Feature = "feature",
  AbTest = "ab-test",
  FeatureVariable = "feature-variable",
  FeatureTest = "feature-test",
  AllFeatureVariables = "all-feature-variables",
}

export enum Source {
  Rollout = "rollout",
  FeatureTest = "feature-test",
}

interface SourceInfo {
  experimentKey: string;
  variationKey: string;
}

interface DecisionInfo {
  variableType?: string;
  variableKey?: string;
  variableValue?: boolean | string;
  featureKey: string;
  featureEnabled: boolean;
  source?: Source;
  sourceInfo?: SourceInfo;
}

type Props = {
  type: DecisionType;
  decisionInfo: DecisionInfo;
};

let decisionCache: Record<string, boolean> = {};

export const resetDecisionCache = () => {
  decisionCache = {};
};

export const onDecision = ({ type, decisionInfo }: Props) => {
  const featureTestInfo = () => {
    const { featureKey, featureEnabled, source, sourceInfo } = decisionInfo;
    const { experimentKey, variationKey } = sourceInfo;

    // Short circuit if we've already sent event
    if (decisionCache[featureKey]) {
      return;
    }

    if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_EXPOSURE_LOGGING)) {
      // send request to gemini BE
      optimizelyClient.trackExposure(experimentKey, variationKey);
    }

    track("Feature Test Decided", {
      "Feature Type": type,
      "Feature Key": featureKey,
      Source: source,
      Variation: variationKey,
      Experiment: experimentKey,
      "Featured Enabled": featureEnabled,
    });

    // Store that we already sent this event for this session
    decisionCache[featureKey] = true;
  };

  switch (decisionInfo?.source) {
    case Source.FeatureTest:
      if (decisionInfo?.sourceInfo?.experimentKey && decisionInfo?.sourceInfo?.variationKey) return featureTestInfo();
  }
};

// Add a notification listener for the decision notification

export const ExperimentListener = () => {
  useEffect(() => {
    const listenerId = optimizelyClient.addNotificationListener(enums.NOTIFICATION_TYPES.DECISION, onDecision) ?? 0;

    // NOTE: We need to return the listener removal so that we don't have a memory leak
    return () => {
      optimizelyClient.removeNotificationListener(listenerId);
    };
  }, []);

  return null;
};
