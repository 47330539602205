/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import * as React from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationEarn = create(({ black, bgPrimary, contentPrimary, white }) => (
  <React.Fragment>
    <path
      d="M76.193 179.326C71.706 175.54 65.3262 175.049 60.0682 177.713C55.9668 179.816 52.5316 183.707 52.0759 190.578C51.55 198.185 56.1772 202.076 60.8744 204.004C66.0624 206.142 72.0916 205.231 76.228 201.41C78.7168 199.166 80.7149 195.731 80.8902 190.543C81.0304 185.11 78.857 181.569 76.193 179.326Z"
      fill={contentPrimary}
    />
    <path
      d="M66.5505 206.036C64.5173 206.036 62.4842 205.651 60.5562 204.845C55.9642 202.952 50.6359 198.956 51.1968 190.543C51.6175 184.268 54.562 179.571 59.6799 176.942C65.3586 174.032 72.089 174.698 76.7512 178.659C80.2215 181.569 81.9041 185.6 81.7639 190.613C81.6237 195.415 79.9411 199.271 76.8213 202.11C74.017 204.669 70.3012 206.036 66.5505 206.036ZM67.0412 176.907C64.8328 176.907 62.5894 177.432 60.4861 178.519C55.894 180.868 53.3351 184.969 52.9495 190.683C52.4588 198.079 57.156 201.585 61.2223 203.267C66.1649 205.3 71.8436 204.354 75.6645 200.848C78.4338 198.325 79.906 194.889 80.0462 190.578C80.1865 186.126 78.7142 182.55 75.6645 180.026C73.1757 177.958 70.161 176.907 67.0412 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M73.037 179.326C68.5501 175.54 62.1703 175.049 56.9122 177.713C52.8108 179.816 49.3756 183.707 48.9198 190.578C48.394 198.185 53.0212 202.076 57.7184 204.004C62.9064 206.142 68.9357 205.231 73.072 201.41C75.5258 199.166 77.559 195.731 77.7342 190.543C77.8744 185.11 75.7362 181.569 73.037 179.326Z"
      fill={bgPrimary}
    />
    <path
      d="M63.3945 206.036C61.3613 206.036 59.3282 205.651 57.4002 204.845C52.8082 202.952 47.4799 198.956 48.0408 190.543C48.4615 184.268 51.406 179.571 56.5239 176.942C62.2026 174.032 68.8979 174.698 73.5952 178.659C77.0655 181.569 78.7481 185.565 78.6079 190.613C78.4326 195.38 76.7851 199.271 73.6653 202.11C70.8609 204.669 67.1803 206.036 63.3945 206.036ZM63.8852 176.907C61.6768 176.907 59.4333 177.432 57.3301 178.519C52.738 180.868 50.1791 184.969 49.7935 190.683C49.3028 198.079 54 201.585 58.0662 203.267C63.0089 205.3 68.6876 204.354 72.5085 200.848C75.2427 198.325 76.715 194.854 76.8902 190.578C77.0304 186.091 75.5582 182.55 72.5085 180.026C70.0547 177.958 67.005 176.907 63.8852 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M118.259 179.326C113.772 175.54 107.392 175.049 102.134 177.713C98.033 179.816 94.5977 183.707 94.142 190.578C93.6162 198.185 98.2433 202.076 102.941 204.004C108.129 206.142 114.158 205.231 118.294 201.41C120.783 199.131 122.781 195.731 122.956 190.543C123.132 185.11 120.923 181.569 118.259 179.326Z"
      fill={contentPrimary}
    />
    <path
      d="M108.617 206.036C106.583 206.036 104.55 205.651 102.622 204.845C98.0303 202.952 92.7021 198.956 93.263 190.543C93.6836 184.268 96.6282 179.571 101.746 176.942C107.425 174.032 114.155 174.698 118.817 178.659C122.288 181.569 123.97 185.6 123.83 190.613C123.655 195.38 122.007 199.271 118.887 202.11C116.083 204.669 112.402 206.036 108.617 206.036ZM109.107 176.907C106.899 176.907 104.656 177.432 102.552 178.519C97.9602 180.868 95.4013 184.969 95.0157 190.683C94.5249 198.079 99.2222 201.585 103.288 203.267C108.231 205.3 113.91 204.354 117.731 200.848C120.465 198.325 121.937 194.854 122.112 190.578C122.253 186.126 120.78 182.585 117.731 180.026C115.277 177.958 112.227 176.907 109.107 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M115.107 179.326C110.62 175.54 104.24 175.049 98.9822 177.713C94.8809 179.816 91.4456 183.707 90.9899 190.578C90.4641 198.185 95.0912 202.076 99.7885 204.004C104.976 206.142 111.006 205.231 115.142 201.41C117.631 199.131 119.629 195.731 119.804 190.543C119.98 185.11 117.806 181.569 115.107 179.326Z"
      fill={bgPrimary}
    />
    <path
      d="M105.5 206.036C103.467 206.036 101.433 205.651 99.5054 204.845C94.9134 202.952 89.5852 198.956 90.146 190.543C90.5667 184.268 93.5112 179.571 98.6291 176.942C104.308 174.032 111.038 174.698 115.7 178.659C119.171 181.569 120.853 185.6 120.713 190.613C120.538 195.38 118.89 199.271 115.77 202.11C112.931 204.669 109.25 206.036 105.5 206.036ZM105.955 176.907C103.747 176.907 101.504 177.432 99.4003 178.519C94.8082 180.868 92.2493 184.969 91.8637 190.683C91.4781 196.607 94.2473 200.848 100.136 203.267C105.079 205.3 110.758 204.354 114.579 200.848C117.313 198.325 118.785 194.854 118.96 190.578C119.101 186.126 117.628 182.585 114.579 180.026C112.125 177.958 109.075 176.907 105.955 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M160.357 179.326C155.87 175.54 149.49 175.049 144.232 177.713C140.13 179.816 136.695 183.707 136.239 190.578C135.714 198.185 140.341 202.076 145.038 204.004C150.226 206.142 156.255 205.231 160.392 201.41C162.845 199.131 164.879 195.731 165.054 190.543C165.194 185.11 163.021 181.569 160.357 179.326Z"
      fill={contentPrimary}
    />
    <path
      d="M150.714 206.036C148.681 206.036 146.648 205.651 144.72 204.845C140.128 202.952 134.8 198.956 135.36 190.543C135.781 184.268 138.726 179.571 143.843 176.942C149.522 174.032 156.253 174.698 160.915 178.659C164.385 181.569 166.068 185.6 165.928 190.613C165.752 195.38 164.105 199.236 160.985 202.11C158.181 204.669 154.465 206.036 150.714 206.036ZM151.205 176.907C148.996 176.907 146.753 177.432 144.65 178.519C140.058 180.868 137.499 184.969 137.113 190.683C136.622 198.079 141.32 201.585 145.386 203.267C150.328 205.3 156.007 204.354 159.828 200.848C162.562 198.29 164.035 194.854 164.21 190.578C164.35 186.126 162.878 182.585 159.828 180.026C157.339 177.958 154.325 176.907 151.205 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M157.201 179.326C152.714 175.54 146.334 175.049 141.076 177.713C136.974 179.816 133.539 183.707 133.083 190.578C132.558 198.185 137.185 202.076 141.882 204.004C147.07 206.142 153.099 205.231 157.236 201.41C159.724 199.131 161.723 195.731 161.898 190.543C162.038 185.11 159.865 181.569 157.201 179.326Z"
      fill={bgPrimary}
    />
    <path
      d="M147.558 206.036C145.525 206.036 143.492 205.651 141.564 204.845C136.972 202.952 131.644 198.956 132.204 190.543C132.625 184.268 135.57 179.571 140.687 176.942C146.366 174.032 153.097 174.698 157.759 178.659C161.229 181.569 162.912 185.6 162.771 190.613C162.596 195.38 160.949 199.271 157.829 202.11C155.025 204.669 151.344 206.036 147.558 206.036ZM148.049 176.907C145.84 176.907 143.597 177.432 141.494 178.519C136.902 180.868 134.343 184.969 133.957 190.683C133.466 198.079 138.164 201.585 142.23 203.267C147.172 205.3 152.851 204.354 156.672 200.848C159.406 198.325 160.879 194.854 161.054 190.578C161.194 186.126 159.722 182.585 156.672 180.026C154.218 177.958 151.169 176.907 148.049 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M202.427 179.326C197.94 175.54 191.56 175.049 186.302 177.713C182.2 179.816 178.765 183.707 178.309 190.578C177.784 198.185 182.411 202.076 187.108 204.004C192.296 206.142 198.325 205.231 202.462 201.41C204.951 199.131 206.949 195.731 207.124 190.543C207.264 185.11 205.091 181.569 202.427 179.326Z"
      fill={contentPrimary}
    />
    <path
      d="M192.784 206.036C190.751 206.036 188.718 205.651 186.79 204.845C182.198 202.952 176.87 198.956 177.43 190.543C177.851 184.268 180.796 179.571 185.914 176.942C191.592 174.032 198.323 174.698 202.985 178.659C206.42 181.569 208.103 185.565 207.998 190.613C207.822 195.38 206.175 199.271 203.055 202.11C200.251 204.669 196.57 206.036 192.784 206.036ZM193.275 176.907C191.066 176.907 188.823 177.432 186.72 178.519C182.128 180.868 179.569 184.969 179.183 190.683C178.692 198.079 183.39 201.585 187.456 203.267C192.399 205.3 198.077 204.354 201.898 200.848C204.632 198.325 206.105 194.854 206.28 190.578C206.385 186.126 204.913 182.55 201.898 180.026C199.444 177.958 196.395 176.907 193.275 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M199.267 179.326C194.78 175.54 188.4 175.049 183.142 177.713C179.041 179.816 175.606 183.707 175.15 190.578C174.624 198.185 179.251 202.076 183.948 204.004C189.136 206.142 195.166 205.231 199.302 201.41C201.791 199.131 203.789 195.731 203.964 190.543C204.14 185.11 201.966 181.569 199.267 179.326Z"
      fill={bgPrimary}
    />
    <path
      d="M189.66 206.036C187.626 206.036 185.593 205.651 183.665 204.845C179.073 202.952 173.745 198.956 174.306 190.543C174.727 184.268 177.671 179.571 182.789 176.942C188.468 174.032 195.198 174.698 199.86 178.659C203.331 181.569 205.013 185.6 204.873 190.613C204.698 195.38 203.05 199.271 199.93 202.11C197.091 204.669 193.41 206.036 189.66 206.036ZM190.115 176.907C187.907 176.907 185.663 177.432 183.56 178.519C178.968 180.868 176.409 184.969 176.024 190.683C175.533 198.079 180.23 201.585 184.296 203.267C189.239 205.3 194.918 204.354 198.739 200.848C201.473 198.325 202.945 194.854 203.12 190.578C203.26 186.126 201.788 182.585 198.739 180.026C196.285 177.958 193.235 176.907 190.115 176.907Z"
      fill={contentPrimary}
    />
    <path
      d="M118.259 137.577C113.772 133.791 107.392 133.3 102.134 135.964C98.033 138.068 94.5977 141.959 94.142 148.829C93.6162 156.436 98.2433 160.327 102.941 162.255C108.129 164.393 114.158 163.482 118.294 159.661C120.783 157.382 122.781 153.982 122.956 148.794C123.132 143.361 120.923 139.855 118.259 137.577Z"
      fill={contentPrimary}
    />
    <path
      d="M108.617 164.322C106.583 164.322 104.55 163.936 102.622 163.13C98.0303 161.237 92.7021 157.241 93.263 148.828C93.6836 142.553 96.6282 137.856 101.746 135.227C107.425 132.317 114.155 132.984 118.817 136.945C122.288 139.854 123.97 143.885 123.83 148.898C123.655 153.665 122.007 157.556 118.887 160.396C116.083 162.955 112.402 164.322 108.617 164.322ZM109.107 135.192C106.899 135.192 104.656 135.718 102.552 136.804C97.9602 139.153 95.4013 143.254 95.0157 148.968C94.5249 156.365 99.2222 159.87 103.288 161.553C108.231 163.586 113.91 162.639 117.731 159.134C120.465 156.61 121.937 153.14 122.112 148.863C122.253 144.411 120.78 140.871 117.731 138.312C115.277 136.209 112.227 135.192 109.107 135.192Z"
      fill={contentPrimary}
    />
    <path
      d="M115.107 137.577C110.62 133.791 104.24 133.3 98.9822 135.964C94.8809 138.068 91.4456 141.959 90.9899 148.829C90.4641 156.436 95.0912 160.327 99.7885 162.255C104.976 164.393 111.006 163.482 115.142 159.661C117.631 157.382 119.629 153.982 119.804 148.794C119.98 143.361 117.806 139.855 115.107 137.577Z"
      fill={bgPrimary}
    />
    <path
      d="M105.5 164.322C103.467 164.322 101.433 163.936 99.5054 163.13C94.9134 161.237 89.5852 157.241 90.146 148.828C90.5667 142.553 93.5112 137.856 98.6291 135.227C104.308 132.317 111.038 132.984 115.7 136.945C119.171 139.854 120.853 143.885 120.713 148.898C120.538 153.665 118.89 157.556 115.77 160.396C112.931 162.955 109.25 164.322 105.5 164.322ZM105.955 135.192C103.747 135.192 101.504 135.718 99.4003 136.804C94.8082 139.153 92.2493 143.254 91.8637 148.968C91.3729 156.365 96.0702 159.87 100.136 161.553C105.079 163.586 110.758 162.639 114.579 159.134C117.313 156.61 118.785 153.14 118.96 148.863C119.101 144.411 117.628 140.871 114.579 138.312C112.125 136.209 109.075 135.192 105.955 135.192Z"
      fill={contentPrimary}
    />
    <path
      d="M160.357 137.577C155.87 133.791 149.49 133.3 144.232 135.964C140.13 138.068 136.695 141.959 136.239 148.829C135.714 156.436 140.341 160.327 145.038 162.255C150.226 164.393 156.255 163.482 160.392 159.661C162.845 157.382 164.879 153.982 165.054 148.794C165.194 143.361 163.021 139.855 160.357 137.577Z"
      fill={contentPrimary}
    />
    <path
      d="M150.714 164.322C148.681 164.322 146.648 163.936 144.72 163.13C140.128 161.237 134.8 157.241 135.36 148.828C135.781 142.553 138.726 137.856 143.843 135.227C149.522 132.317 156.253 132.984 160.915 136.945C164.385 139.854 166.068 143.885 165.928 148.898C165.752 153.665 164.105 157.521 160.985 160.396C158.181 162.955 154.465 164.322 150.714 164.322ZM151.205 135.192C148.996 135.192 146.753 135.718 144.65 136.804C140.058 139.153 137.499 143.254 137.113 148.968C136.622 156.365 141.32 159.87 145.386 161.553C150.328 163.586 156.007 162.639 159.828 159.134C162.562 156.575 164.035 153.14 164.21 148.863C164.35 144.411 162.878 140.871 159.828 138.312C157.339 136.209 154.325 135.192 151.205 135.192Z"
      fill={contentPrimary}
    />
    <path
      d="M157.201 137.577C152.714 133.791 146.334 133.3 141.076 135.964C136.974 138.068 133.539 141.959 133.083 148.829C132.558 156.436 137.185 160.327 141.882 162.255C147.07 164.393 153.099 163.482 157.236 159.661C159.724 157.382 161.723 153.982 161.898 148.794C162.038 143.361 159.865 139.855 157.201 137.577Z"
      fill={bgPrimary}
    />
    <path
      d="M147.558 164.322C145.525 164.322 143.492 163.936 141.564 163.13C136.972 161.237 131.644 157.241 132.204 148.828C132.625 142.553 135.57 137.856 140.687 135.227C146.366 132.317 153.062 132.984 157.759 136.945C161.229 139.854 162.912 143.885 162.771 148.898C162.596 153.665 160.949 157.556 157.829 160.396C155.025 162.955 151.344 164.322 147.558 164.322ZM148.049 135.192C145.84 135.192 143.597 135.718 141.494 136.804C136.902 139.153 134.343 143.254 133.957 148.968C133.466 156.365 138.164 159.87 142.23 161.553C147.172 163.586 152.851 162.639 156.672 159.134C159.406 156.61 160.879 153.14 161.054 148.863C161.194 144.411 159.722 140.871 156.672 138.312C154.218 136.209 151.169 135.192 148.049 135.192Z"
      fill={contentPrimary}
    />
    <path
      d="M202.427 137.577C197.94 133.791 191.56 133.3 186.302 135.964C182.2 138.068 178.765 141.959 178.309 148.829C177.784 156.436 182.411 160.327 187.108 162.255C192.296 164.393 198.325 163.482 202.462 159.661C204.951 157.382 206.949 153.982 207.124 148.794C207.264 143.361 205.091 139.855 202.427 137.577Z"
      fill={contentPrimary}
    />
    <path
      d="M192.784 164.322C190.751 164.322 188.718 163.936 186.79 163.13C182.198 161.237 176.87 157.241 177.43 148.828C177.851 142.553 180.796 137.856 185.914 135.227C191.592 132.317 198.323 132.984 202.985 136.945C206.42 139.854 208.103 143.85 207.998 148.898C207.822 153.665 206.175 157.556 203.055 160.396C200.251 162.955 196.57 164.322 192.784 164.322ZM193.275 135.192C191.066 135.192 188.823 135.718 186.72 136.804C182.128 139.153 179.569 143.254 179.183 148.968C178.692 156.365 183.39 159.87 187.456 161.553C192.399 163.586 198.077 162.639 201.898 159.134C204.632 156.61 206.105 153.14 206.28 148.863C206.385 144.411 204.913 140.836 201.898 138.312C199.444 136.209 196.395 135.192 193.275 135.192Z"
      fill={contentPrimary}
    />
    <path
      d="M199.267 137.577C194.78 133.791 188.4 133.3 183.142 135.964C179.041 138.068 175.606 141.959 175.15 148.829C174.624 156.436 179.251 160.327 183.948 162.255C189.136 164.393 195.166 163.482 199.302 159.661C201.791 157.382 203.789 153.982 203.964 148.794C204.14 143.361 201.966 139.855 199.267 137.577Z"
      fill={bgPrimary}
    />
    <path
      d="M189.66 164.322C187.626 164.322 185.593 163.936 183.665 163.13C179.073 161.237 173.745 157.241 174.306 148.828C174.727 142.553 177.671 137.856 182.789 135.227C188.468 132.317 195.163 132.984 199.86 136.945C203.331 139.854 205.013 143.885 204.873 148.898C204.698 153.665 203.05 157.556 199.93 160.396C197.091 162.955 193.41 164.322 189.66 164.322ZM190.115 135.192C187.907 135.192 185.663 135.718 183.56 136.804C178.968 139.153 176.409 143.254 176.024 148.968C175.533 156.365 180.23 159.87 184.296 161.553C189.239 163.586 194.918 162.639 198.739 159.134C201.473 156.61 202.945 153.14 203.12 148.863C203.26 144.411 201.788 140.871 198.739 138.312C196.285 136.209 193.235 135.192 190.115 135.192Z"
      fill={contentPrimary}
    />
    <path
      d="M160.357 95.8631C155.87 92.0772 149.49 91.5865 144.232 94.2506C140.13 96.3538 136.695 100.245 136.239 107.115C135.714 114.722 140.341 118.613 145.038 120.541C150.226 122.679 156.255 121.768 160.392 117.947C162.845 115.669 164.879 112.268 165.054 107.08C165.194 101.612 163.021 98.1065 160.357 95.8631Z"
      fill={contentPrimary}
    />
    <path
      d="M150.714 122.574C148.681 122.574 146.648 122.188 144.72 121.382C140.128 119.489 134.8 115.493 135.36 107.08C135.781 100.805 138.726 96.1082 143.843 93.4791C149.522 90.5696 156.253 91.2356 160.915 95.1967C164.385 98.1413 166.068 102.172 165.928 107.15C165.752 111.917 164.105 115.773 160.985 118.648C158.181 121.207 154.465 122.574 150.714 122.574ZM151.205 93.4441C148.996 93.4441 146.753 93.9699 144.65 95.0565C140.058 97.4052 137.499 101.506 137.113 107.22C136.622 114.617 141.32 118.122 145.386 119.805C150.328 121.838 156.007 120.891 159.828 117.386C162.562 114.827 164.035 111.392 164.21 107.115C164.35 102.698 162.878 99.1228 159.828 96.5639C157.339 94.4957 154.29 93.4441 151.205 93.4441Z"
      fill={contentPrimary}
    />
    <path
      d="M157.201 95.8631C152.714 92.0772 146.334 91.5865 141.076 94.2506C136.974 96.3538 133.539 100.245 133.083 107.115C132.558 114.722 137.185 118.613 141.882 120.541C147.07 122.679 153.099 121.768 157.236 117.947C159.724 115.669 161.723 112.268 161.898 107.08C162.038 101.612 159.865 98.1065 157.201 95.8631Z"
      fill={bgPrimary}
    />
    <path
      d="M147.558 122.574C145.525 122.574 143.492 122.188 141.564 121.382C136.972 119.489 131.644 115.493 132.204 107.08C132.625 100.805 135.57 96.1082 140.687 93.4791C146.366 90.5696 153.062 91.2356 157.759 95.1967C161.229 98.1413 162.912 102.172 162.771 107.15C162.596 111.917 160.949 115.808 157.829 118.648C155.025 121.207 151.344 122.574 147.558 122.574ZM148.049 93.4441C145.84 93.4441 143.597 93.9699 141.494 95.0565C136.902 97.4052 134.343 101.506 133.957 107.22C133.466 114.617 138.164 118.122 142.23 119.805C147.172 121.838 152.851 120.891 156.672 117.386C159.406 114.862 160.879 111.392 161.054 107.115C161.194 102.698 159.722 99.1228 156.672 96.5639C154.218 94.4957 151.169 93.4441 148.049 93.4441Z"
      fill={contentPrimary}
    />
    <path
      d="M202.427 95.8631C197.94 92.0772 191.56 91.5865 186.302 94.2506C182.2 96.3538 178.765 100.245 178.309 107.115C177.784 114.722 182.411 118.613 187.108 120.541C192.296 122.679 198.325 121.768 202.462 117.947C204.951 115.669 206.949 112.268 207.124 107.08C207.264 101.612 205.091 98.1065 202.427 95.8631Z"
      fill={contentPrimary}
    />
    <path
      d="M192.784 122.574C190.751 122.574 188.718 122.188 186.79 121.382C182.198 119.489 176.87 115.493 177.43 107.08C177.851 100.805 180.796 96.1082 185.914 93.4791C191.592 90.5696 198.323 91.2356 202.985 95.1967C206.42 98.1062 208.103 102.137 207.998 107.15C207.822 111.917 206.175 115.808 203.055 118.648C200.251 121.207 196.57 122.574 192.784 122.574ZM193.275 93.4441C191.066 93.4441 188.823 93.9699 186.72 95.0565C182.128 97.4052 179.569 101.506 179.183 107.22C178.692 114.617 183.39 118.122 187.456 119.805C192.399 121.838 198.077 120.891 201.898 117.386C204.632 114.862 206.105 111.392 206.28 107.115C206.385 102.663 204.913 99.1228 201.898 96.5639C199.444 94.4957 196.395 93.4441 193.275 93.4441Z"
      fill={contentPrimary}
    />
    <path
      d="M199.267 95.8631C194.78 92.0772 188.4 91.5865 183.142 94.2506C179.041 96.3538 175.606 100.245 175.15 107.115C174.624 114.722 179.251 118.613 183.948 120.541C189.136 122.679 195.166 121.768 199.302 117.947C201.791 115.669 203.789 112.268 203.964 107.08C204.14 101.612 201.966 98.1065 199.267 95.8631Z"
      fill={bgPrimary}
    />
    <path
      d="M189.66 122.574C187.626 122.574 185.593 122.188 183.665 121.382C179.073 119.489 173.745 115.493 174.306 107.08C174.727 100.805 177.671 96.1082 182.789 93.4791C188.468 90.5696 195.163 91.2356 199.86 95.1967C203.331 98.1413 205.013 102.172 204.873 107.15C204.698 111.917 203.05 115.808 199.93 118.648C197.091 121.207 193.41 122.574 189.66 122.574ZM190.115 93.4441C187.907 93.4441 185.663 93.9699 183.56 95.0565C178.968 97.4052 176.409 101.506 176.024 107.22C175.533 114.617 180.23 118.122 184.296 119.805C189.239 121.838 194.918 120.891 198.739 117.386C201.473 114.862 202.945 111.392 203.12 107.115C203.26 102.698 201.788 99.1228 198.739 96.5639C196.285 94.4957 193.235 93.4441 190.115 93.4441Z"
      fill={contentPrimary}
    />
    <path
      d="M202.427 54.1132C197.905 50.3274 191.525 49.8366 186.302 52.5007C182.2 54.604 178.765 58.495 178.309 65.3655C177.784 72.9722 182.411 76.8632 187.108 78.7912C192.296 80.8944 198.325 80.0181 202.462 76.1972C204.951 73.9187 206.949 70.5185 207.124 65.3305C207.264 59.8971 205.091 56.3567 202.427 54.1132Z"
      fill={contentPrimary}
    />
    <path
      d="M192.784 80.8241C190.751 80.8241 188.718 80.4385 186.79 79.6322C182.198 77.7393 176.87 73.7432 177.43 65.3302C177.851 59.0556 180.796 54.3583 185.914 51.7293C191.592 48.8198 198.288 49.4858 202.985 53.4469C206.42 56.3564 208.103 60.3525 207.998 65.4003C207.822 70.1677 206.175 74.0587 203.055 76.898C200.251 79.492 196.57 80.8241 192.784 80.8241ZM193.275 51.6942C191.066 51.6942 188.823 52.22 186.72 53.3067C182.128 55.6553 179.569 59.7566 179.183 65.4704C178.692 72.8668 183.39 76.3722 187.456 78.0548C192.434 80.0879 198.077 79.1415 201.898 75.6361C204.632 73.1122 206.105 69.6418 206.28 65.3653C206.385 60.9134 204.913 57.3379 201.898 54.814C199.409 52.7458 196.36 51.6942 193.275 51.6942Z"
      fill={contentPrimary}
    />
    <path
      d="M199.263 54.1132C194.776 50.3274 188.396 49.8366 183.138 52.5007C179.037 54.604 175.602 58.495 175.146 65.3655C174.62 72.9722 179.247 76.8632 183.944 78.7912C189.132 80.8944 195.162 80.0181 199.298 76.1972C201.787 73.9187 203.785 70.5185 203.96 65.3305C204.136 59.8971 201.962 56.3567 199.263 54.1132Z"
      fill={bgPrimary}
    />
    <path
      d="M189.621 80.8241C187.587 80.8241 185.554 80.4385 183.626 79.6322C179.034 77.7393 173.706 73.7432 174.267 65.3302C174.688 59.0556 177.632 54.3583 182.75 51.7293C188.429 48.8198 195.124 49.4858 199.821 53.4469C203.292 56.3564 204.974 60.3876 204.834 65.4003C204.659 70.1677 203.011 74.0587 199.891 76.898C197.122 79.492 193.406 80.8241 189.621 80.8241ZM190.111 51.6942C187.903 51.6942 185.659 52.22 183.556 53.3067C178.964 55.6553 176.405 59.7566 176.02 65.4704C175.529 72.8668 180.226 76.3722 184.292 78.0548C189.27 80.0879 194.914 79.1415 198.735 75.6361C201.469 73.1122 202.941 69.6418 203.116 65.3653C203.257 60.9134 201.784 57.373 198.735 54.814C196.281 52.7458 193.231 51.6942 190.111 51.6942Z"
      fill={contentPrimary}
    />
  </React.Fragment>
));
IllustrationEarn.displayName = "IllustrationEarn";
