import { ReactNode } from "react";
import { ForwardRefComponent } from "@gemini-ui/design-system/polymorphic";
import { ShorthandSpacingCssProps, Spacing } from "@gemini-ui/design-system/primitives";

export type VariantType = "outlined" | "filled" | "inverse";
export type PaddingType = "none" | "sm" | "md";

const ELEMENT = "div";
export const CardDefaultElement = ELEMENT;

export const paddingTypes = {
  none: 0,
  sm: Spacing.scale[2],
  md: Spacing.scale[3],
};

export interface CardProps extends ShorthandSpacingCssProps {
  "aria-checked"?: boolean | "true" | "false" | "mixed";
  children?: ReactNode;
  customFilledBgColor?: string;
  "data-testid"?: string;
  isCollapsible?: boolean;
  isRadio?: boolean;
  overflow?: CSSStyleDeclaration["overflow"];
  padding?: PaddingType;
  variant?: VariantType;
}

export type CardPolymorphic = ForwardRefComponent<typeof CardDefaultElement, CardProps>;
