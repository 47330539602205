/** @jsx jsx */
import React from "react";
import { jsx, useTheme } from "@emotion/react";
import { defineMessage } from "@formatjs/intl";
import { IconChevronLeftSmall } from "@hubble/icons";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, Spacing, Text } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import {
  FundingSourcesList,
  FundingSourcesListProps,
} from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/FundingSourcesList";
import {
  GrowTransactionType,
  SupportedStakingPaymentMethod,
  SupportedUnstakingMethod,
} from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { AddPaymentMethodsModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { usePaymentData } from "@gemini-ui/services/transfer/paymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

export interface StakingFundingSourcesScreenProps extends FundingSourcesListProps {
  paymentMethodData?: ReturnType<typeof usePaymentData>;
  providerType: GrowProviderType;
  transactionType: GrowTransactionType;
  onBack?: () => void;
}

export const StakingFundingSourcesScreen = ({
  options,
  paymentMethodData,
  providerType,
  selected,
  transactionType,
  onBack,
  onDidSelectFundingSource,
}: StakingFundingSourcesScreenProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  const {
    templateProps: {
      user: { subaccountHashid },
    },
  } = usePageData();

  const [showModal, setShowModal] = React.useState(false);

  const handleDidSelectFundingSource = (
    source: SelectOptionProps<SupportedStakingPaymentMethod | SupportedUnstakingMethod>
  ) => {
    onDidSelectFundingSource(source);
    onBack?.();
  };

  return (
    <React.Fragment>
      <Flex flexDirection="column" align="start" justify="space-between" css={{ height: "auto", minHeight: "500px" }}>
        <Flex flexDirection="column" gap={Spacing.scale[1]} width="100%" height="100%">
          {onBack && (
            <Flex mb={1}>
              <Button.Secondary
                data-testid="staking-funding-source-on-back-button"
                size="sm"
                aria-label={intl.formatMessage({ defaultMessage: "Go back a step" })}
                icon={<IconChevronLeftSmall />}
                onClick={onBack}
              />
            </Flex>
          )}
          <Text.Heading size="md">
            {intl.formatMessage(
              defineMessage({
                defaultMessage: "Choose {transactionType, select, unstake {unstake} other {payment}} method",
              }),
              { transactionType }
            )}
          </Text.Heading>
          {options.length ? (
            <FundingSourcesList
              onDidSelectFundingSource={handleDidSelectFundingSource}
              options={options}
              selected={selected}
            />
          ) : (
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {intl.formatMessage({
                defaultMessage: "You haven't added a payment method yet. Add one below to continue staking.",
              })}
            </Text.Body>
          )}
        </Flex>

        {transactionType === GrowTransactionType.STAKE && providerType === GrowProviderType.POOLED_STAKING && (
          <Button.Secondary data-testid="open-add-payment-modal-trigger" onClick={() => setShowModal(true)}>
            {intl.formatMessage({ defaultMessage: "Add payment method" })}
          </Button.Secondary>
        )}
      </Flex>

      {paymentMethodData && (
        <AddPaymentMethodsModal
          handleOpenBancolombiaModal={null}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          paymentMethods={[]}
          scope={PaymentTypeScope.STAKING}
          subaccountHashid={subaccountHashid}
          type="default"
          refreshPaymentMethodsData={() => paymentMethodData.refetchPaymentData()}
          pageName={PageName.Stake}
        />
      )}
    </React.Fragment>
  );
};
