/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { VerificationPendingActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { GlobalModalType, useGlobalModal, VerificationPendingProps } from "@gemini-ui/contexts/GlobalModal";
import { Flex, Text } from "@gemini-ui/design-system";
import { ReactComponent as AddPaymentIcon } from "@gemini-ui/images/icons/add-payment-method.svg";
import { OrderQuote, TradePaymentMethodType } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import AddOrSelectPaymentMethod from "@gemini-ui/pages/RetailTrade/PaymentMethod/AddOrSelectPaymentMethod";
import BalanceMethod from "@gemini-ui/pages/RetailTrade/PaymentMethod/BalanceMethod";
import BankMethod from "@gemini-ui/pages/RetailTrade/PaymentMethod/BankMethod";
import DebitCardMethod from "@gemini-ui/pages/RetailTrade/PaymentMethod/DebitCardMethod";
import PayPalMethod from "@gemini-ui/pages/RetailTrade/PaymentMethod/PayPalMethod";
import { DepositLimits } from "@gemini-ui/services/transfer/types";
import { getIsUserInOnboarding, useLockout } from "@gemini-ui/services/user/lockout";
import { PaymentMethodDataType } from "@gemini-ui/transformers/PaymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

interface PaymentMethodProps {
  onClick: () => void;
  selectedPaymentMethod?: TradePaymentMethodType;
  balances: CurrencyBalances;
  defaultFiat: CurrencyShortNameFiat;
  orderQuote: OrderQuote;
  onToggle: () => void;
  paymentMethods: PaymentMethodDataType[];
  limits: DepositLimits;
  quoteError?: string;
}

const EmptyMethod = ({ onClick }: { onClick: () => void }) => {
  const { intl } = useIntl();

  return (
    <AddOrSelectPaymentMethod onClick={onClick}>
      <Flex alignItems="center">
        <AddPaymentIcon />

        <Flex ml={1.5}>
          <Text.Body size="md" mt={0.25} as="span">
            {intl.formatMessage({
              defaultMessage: "Select payment method",
            })}
          </Text.Body>
        </Flex>
      </Flex>
    </AddOrSelectPaymentMethod>
  );
};

const PaymentMethod: React.FC<React.PropsWithChildren<PaymentMethodProps>> = ({
  onClick,
  selectedPaymentMethod,
  paymentMethods,
  balances,
  defaultFiat,
  orderQuote,
  onToggle,
  limits,
  quoteError,
}) => {
  const { toggleModal } = useGlobalModal();
  const { lockout } = useLockout();
  const noPaymentOptions = !selectedPaymentMethod && !paymentMethods?.length;

  const handleEmptyClick = (callback: () => void) => {
    if (getIsUserInOnboarding(lockout)) {
      toggleModal<VerificationPendingProps>(GlobalModalType.VerificationPending, {
        actionType: VerificationPendingActionType.AddPayment,
      });
    } else {
      callback();
    }
  };
  if (noPaymentOptions) {
    return <EmptyMethod onClick={() => handleEmptyClick(onClick)}></EmptyMethod>;
  }

  if (!selectedPaymentMethod) return <EmptyMethod onClick={() => handleEmptyClick(onToggle)}></EmptyMethod>;
  if (selectedPaymentMethod === AccountType.BALANCE)
    return <BalanceMethod onClick={onToggle} balances={balances} defaultFiat={defaultFiat} />;

  switch (selectedPaymentMethod.paymentMethodType) {
    case PaymentMethodType.BANK:
      return <BankMethod limits={limits} onClick={onToggle} selectedBank={selectedPaymentMethod} />;
    case PaymentMethodType.PAYPAL:
      return <PayPalMethod onClick={onToggle} selectedBank={selectedPaymentMethod} />;
    case PaymentMethodType.DEBIT_CARD:
      return (
        <DebitCardMethod
          onClick={onToggle}
          selectedDebitCard={selectedPaymentMethod}
          orderQuote={orderQuote}
          limit={limits.card}
          quoteError={quoteError}
        />
      );
    default:
      return null;
  }
};

export default PaymentMethod;
