import React from "react";
import { throttle } from "lodash";
import { ButtonProps } from "@gemini-ui/design-system/Button/constants";
import {
  BaseButtonElement,
  BaseStyleProps,
  ElementWrapper,
  iconSizeMap,
  LoadingIconWrapper,
} from "@gemini-ui/design-system/Button/styles";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";

const handleButtonClick = (onClickFn: () => void) => {
  return throttle(onClickFn, 300, { leading: true, trailing: false });
};

export default React.forwardRef<HTMLButtonElement, ButtonProps>(function BaseButton(
  {
    ["data-testid"]: dataTestId,
    autoFocus,
    children,
    cta,
    disabled,
    display,
    href,
    icon,
    leftElement,
    loading,
    onClick,
    rightElement,
    size = "md",
    target,
    to,
    tone = "neutral",
    type = "button",
    ...htmlAttributes
  },
  ref
) {
  const isIconOnly = Boolean(icon && !cta && !children);
  const buttonChildren = children || cta || icon;

  const styleProps: BaseStyleProps = {
    display,
    disabled,
    isIconOnly,
    loading,
    size,
  };

  const linkProps =
    href &&
    ({
      href,
      as: "a",
      role: "button",
      ...(target && { rel: "noopener noreferrer", target }),
    } as const);

  const clientSideLinkProps =
    to &&
    ({
      to,
      role: "button",
    } as const);

  return (
    <BaseButtonElement
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      type={type}
      disabled={disabled || loading}
      onClick={onClick && handleButtonClick(onClick)}
      data-testid={dataTestId}
      styleProps={styleProps}
      ref={ref}
      {...linkProps}
      {...clientSideLinkProps}
      {...htmlAttributes}
    >
      {leftElement && (
        <ElementWrapper align="center" as="span" justify="center" mr={1} size={size} loading={loading}>
          {leftElement}
        </ElementWrapper>
      )}
      {buttonChildren}
      {loading && (
        <LoadingIconWrapper align="center" as="span" className="notranslate" size={size} justify="center">
          <SpinnerAnimation size={iconSizeMap[size]} color="inherit" />
        </LoadingIconWrapper>
      )}
      {rightElement && (
        <ElementWrapper align="center" as="span" justify="center" ml={1} size={size} loading={loading}>
          {rightElement}
        </ElementWrapper>
      )}
    </BaseButtonElement>
  );
});
