/*
This utility function serves the url for AWS S3 resources based on the environment.
*/
import { isProdUrl } from "@gemini-ui/analytics";
export const getAssetsUrl = () => {
  if (isProdUrl()) {
    return `https://assets.gemini.com`;
  } else {
    return `https://assets.aurora7.net`;
  }
};
