import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { throttle } from "lodash";

export const useScroll = (element: MutableRefObject<HTMLElement | null>, throttleTime = 200) => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  const handleScroll = useMemo(
    () =>
      throttle(
        () => {
          if (element.current) {
            setScrollPosition({
              x: element.current.scrollLeft,
              y: element.current.scrollTop,
            });
          }
        },
        throttleTime,
        { leading: true, trailing: true }
      ),
    [element, throttleTime]
  );

  useEffect(() => {
    const currentElement = element.current;
    if (currentElement) {
      currentElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      currentElement?.removeEventListener("scroll", handleScroll);
      handleScroll.cancel();
    };
  }, [handleScroll, element]);

  return scrollPosition;
};
