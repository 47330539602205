import { border, size, Spacing } from "@gemini-ui/design-system";
import { FORM_ELEMENT_TRANSITION_VALUE, getFontSize, getSize } from "@gemini-ui/design-system/forms/shared/utils";

export const authCodeInputStyles = theme => `
appearance: none;
background-color: inherit;
border: 0;
color: ${theme.colorScheme.content.primary};
font-size: ${getFontSize("lg")};
grid-area: input;
height: 100%;
padding: 0;
width: 100%;

::placeholder {
  color: ${theme.colorScheme.content.secondary};
}

:focus {
  outline: none;
}

:disabled {
  color: ${theme.colorScheme.content.disabled};
  background-color: ${theme.colorScheme.action.background.secondary.neutral.disabled};

  ::placeholder {
    color: ${theme.colorScheme.input.content.placeholder};
  }
}

max-width: 46px;
width: 100%;

height: ${getSize("lg")};
border: 1px solid ${theme.colorScheme.input.border.enabled};
border-radius: ${border.radius.md};
text-align: center;
font-weight: 600;
transition: ${FORM_ELEMENT_TRANSITION_VALUE};

&.negative {
  background-color: ${theme.colorScheme.action.background.secondary.negative.active};
  border-color: ${theme.colorScheme.action.content.secondary.negative.enabled};
}
&.no-margin-top {
  margin-top: 0;
}

:hover {
  border-color: ${theme.colorScheme.input.border.hover};
  box-shadow: none;
}

:focus-within,
:focus-within:hover {
  outline: none;
  border-color: ${theme.colorScheme.content.primary};
  box-shadow: 0 0 0 1px ${theme.colorScheme.content.primary};
}

@media (max-width: 425px) {
  max-width: ${size[40]};
  height: ${size[48]};
}
@media (max-width: 360px) {
  margin: ${Spacing.scale[3]} ${Spacing.scale["0.5"]};
  height: ${size[40]};
}
`;

export const loadingWrapperStyle = `
position: absolute;
left: 0;
right: 0;
top: 25%;
`;

export const autoCodeWrapperStyle = `
position: relative;
margin-top: ${Spacing.scale[3]};
`;
