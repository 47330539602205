/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { ReactComponent as ArrowSide } from "@gemini-ui/images/RetailTrade/arrow-side.svg";
import { PaymentMethodButton } from "@gemini-ui/pages/RetailTrade/PaymentMethod/styles";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";

const AddOrSelectPaymentMethod: React.FC<
  React.PropsWithChildren<{
    onClick: () => void;
  }>
> = props => {
  return (
    <React.Fragment>
      <PaymentMethodButton
        data-testid={testIds.buySell.paymentMethodBtn}
        onClick={e => {
          e.preventDefault();
          props.onClick();
        }}
      >
        {props.children}
        <ArrowSide />
      </PaymentMethodButton>
    </React.Fragment>
  );
};

export default AddOrSelectPaymentMethod;
