import styled from "@emotion/styled";
import { Input } from "@gemini-ui/design-system/forms/Input";
import { InputWrapper } from "@gemini-ui/design-system/forms/Input/styles";
import { border, Spacing } from "@gemini-ui/design-system/primitives";

export type Variant = "filled" | "outlined";

export const StyledInput = styled(Input)<{ variant: Variant }>`
  width: ${({ width }) => (width ? width : "240px")};
  ${InputWrapper} {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colorScheme.content.disabled : theme.colorScheme.content.primary};
    border-radius: ${border.radius.full};
    overflow: hidden;
    padding-left: ${Spacing.scale[1]};
    padding-right: ${Spacing.scale[1]};
    svg {
      color: inherit !important;
    }
    ${({ theme, disabled, variant }) =>
      variant === "filled"
        ? `
    background: ${
      disabled ? theme.colorScheme.search.background.disabled : theme.colorScheme.search.background.enabled
    };
    border-color: transparent;
    &:hover { 
      background: ${theme.colorScheme.search.background.hover};
      border-color: transparent;
    };
    &:focus { 
      background: ${theme.colorScheme.search.background.focus};
    };
    input { background: none; };
    `
        : ""}
  }
`;
export const ClearButton = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  font: inherit;
  outline: none;
  cursor: pointer;
  line-height: 0;
  color: ${({ theme }) => theme.colorScheme.content.secondary};
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colorScheme.content.primary};
  }
  &:disabled {
    color: ${({ theme }) => theme.colorScheme.content.disabled};
  }

  svg {
    color: inherit !important;
  }
`;
