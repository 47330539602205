import { useCallback, useState } from "react";
import { OrderQuoteError, OrderResult } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { createInstantOrder, InstantOrderRouteQueryParams } from "@gemini-ui/services/retail/instantOrder";
import { StakingDepositMethod, StakingTransferQueryParams } from "@gemini-ui/services/staking/constants";
import { createStakingTransfer, StakingTransferReturn } from "@gemini-ui/services/staking/transfer";
import { getError } from "@gemini-ui/utils/error";

type QueryParams = StakingTransferQueryParams | InstantOrderRouteQueryParams;

type StakingDepositReturn = StakingTransferReturn | OrderResult;

const depositFnMap: Record<StakingDepositMethod, (args?: any) => Promise<any>> = {
  [StakingDepositMethod.TRANSFER]: (args: StakingTransferQueryParams) => createStakingTransfer(args),
  [StakingDepositMethod.BUY_AND_STAKE]: (args: InstantOrderRouteQueryParams) => createInstantOrder(args),
};

export const useStakingDeposit = <T extends StakingDepositReturn = StakingDepositReturn>() => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string | OrderQuoteError>(null);
  const [loading, setLoading] = useState(false);

  const submitDeposit = useCallback(async (depositType: StakingDepositMethod, args: QueryParams) => {
    const fetcher = depositFnMap[depositType];
    if (!fetcher) return;

    try {
      setLoading(true);
      const data = await fetcher(args);
      setData(data);
      setError(null);

      // Returning the data and setting the data to local state is redundant but may be helpful depending on how this function is called.
      return data;
    } catch (e) {
      const error = getError(e);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    loading,
    submitDeposit,
    error,
  };
};
