import React, { forwardRef } from "react";
import { uniqueId } from "lodash";
import { Label } from "@gemini-ui/design-system/forms/Label";
import { Message } from "@gemini-ui/design-system/forms/Message";
import { TextareaProps } from "@gemini-ui/design-system/forms/Textarea/constants";
import { StyledTextarea } from "@gemini-ui/design-system/forms/Textarea/styles";
import { cssShorthandPropsFilter, Spacer } from "@gemini-ui/design-system/primitives/Spacer";

const ID_PREFIX = "textarea-";

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { ["data-testid"]: dataTestId, error, disabled, id, label, message, resize = "vertical", size = "md", ...props },
    ref
  ) => {
    const hasError = Boolean(error);
    const textareaId = Boolean(id) ? id : uniqueId(ID_PREFIX);
    const textareaProps = cssShorthandPropsFilter(props, true);
    const hasMessage = Boolean(message) || Boolean(error);

    return (
      <Spacer data-testid={dataTestId} {...cssShorthandPropsFilter(props)}>
        <Label disabled={disabled} htmlFor={textareaId} mb={1}>
          {label}
        </Label>

        <StyledTextarea
          aria-describedby={hasMessage ? `${textareaId}-message` : null}
          hasError={hasError}
          id={textareaId}
          ref={ref}
          resize={resize}
          size={size}
          data-testid={ID_PREFIX + dataTestId}
          {...textareaProps}
        />

        {hasMessage && <Message disabled={disabled} error={error} id={`${textareaId}-message`} message={message} />}
      </Spacer>
    );
  }
);
