import isPropValid from "@emotion/is-prop-valid";
import { CSSObject, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "@gemini-ui/design-system/Button/constants";
import { Flex } from "@gemini-ui/design-system/Flex";
import { border, Colors, size as sizeTokens, Spacing, text } from "@gemini-ui/design-system/primitives";
import { shorthandSpacingCss } from "@gemini-ui/design-system/primitives/Spacer";

export type BaseStyleProps = {
  disabled?: boolean;
  display?: "block" | "inline" | "inline-flex";
  isIconOnly?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  loading?: boolean;
};

export const iconSizeMap: { [key in BaseStyleProps["size"]]: string } = {
  xs: sizeTokens.icon.xs,
  sm: sizeTokens.icon.xs,
  md: sizeTokens.icon.sm,
  lg: sizeTokens.icon.lg,
};

const iconOnlySizeMap: { [key in BaseStyleProps["size"]]: string } = {
  xs: sizeTokens.icon.xs,
  sm: sizeTokens.icon.xl,
  md: Spacing.scale[5],
  lg: Spacing.scale[7],
};

const iconStyles = (size: BaseStyleProps["size"]) => `
  svg {
    height: ${iconSizeMap[size]};
    width: ${iconSizeMap[size]};
    vertical-align: middle;

    /* temporary override inline color styles on icons. See createIconComponent.ts */
    color: inherit !important;
  }
`;

export const BaseButtonElement = styled("button", {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<{ styleProps: BaseStyleProps }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  outline: none;
  border: none;
  border-radius: ${border.radius.full};
  color: inherit;
  background: transparent;
  cursor: pointer;
  letter-spacing: 0;
  ${({ styleProps }) => styleProps.display && `display: ${styleProps.display};`}
  ${({ styleProps }) => (styleProps.disabled || styleProps.loading) && `pointer-events: none;`}
  ${({ styleProps }) => getButtonSizeStyles(styleProps.size)}
  ${({ styleProps }) =>
    !["inline", "inline-flex"].includes(styleProps.display) &&
    `@media (max-width: 543px) {
      width: 100%;
    }`}

${({ styleProps }) =>
    styleProps.isIconOnly &&
    `
    ${iconStyles(styleProps.size)}
    min-width: auto;
    width: ${iconOnlySizeMap[styleProps.size]};
    padding: 0;
  `}

  ${shorthandSpacingCss}
`;

export const ElementWrapper = styled(Flex, {
  shouldForwardProp: (prop: string) => prop !== "size",
})<{ size: BaseStyleProps["size"]; loading?: boolean }>`
  ${({ size }) => iconStyles(size)}
  ${({ loading }) => loading && `visibility: hidden;`}
`;

export const LoadingIconWrapper = styled(ElementWrapper)`
  position: absolute;
`;

const getButtonSizeStyles = (size: BaseStyleProps["size"]) => {
  if (size === "sm") {
    return `
      height: ${sizeTokens.control.sm};
      padding-left: ${Spacing.scale[2]};
      padding-right: ${Spacing.scale[2]};
      font-size: ${text.typesets.body.xsBold.fontSize};
      font-weight: ${text.typesets.body.xsBold.fontWeight};
      line-height: ${text.typesets.body.xsBold.lineHeight};
    `;
  }
  if (size === "md") {
    return `
      height: ${sizeTokens.control.md};
      padding-left: ${Spacing.scale[3]};
      padding-right: ${Spacing.scale[3]};
      font-size: ${text.typesets.body.smBold.fontSize};
      font-weight: ${text.typesets.body.smBold.fontWeight};
      line-height: ${text.typesets.body.smBold.lineHeight};
      text-decoration-thickness: 2px;
    `;
  }
  if (size === "lg") {
    return `
      height: ${sizeTokens.control.lg};
      padding-left: ${Spacing.scale[4]};
      padding-right: ${Spacing.scale[4]};
      font-size: ${text.typesets.body.mdBold.fontSize};
      font-weight: ${text.typesets.body.mdBold.fontWeight};
      line-height: ${text.typesets.body.mdBold.lineHeight};
      letter-spacing: 0;
      text-decoration-thickness: 2px;
    `;
  }
};

export function getStylesByVariant(
  { theme, disabled, loading, icon, tone = "neutral" }: ButtonProps & { theme: Theme },
  variant: keyof Theme["colorScheme"]["action"]["content"]
) {
  const styles: CSSObject = {
    background: theme.colorScheme.action.background[variant][tone].enabled,
    color: theme.colorScheme.action.content[variant][tone].enabled,
    ":hover": {
      color: theme.colorScheme.action.content[variant][tone].hover,
      background: theme.colorScheme.action.background[variant][tone].hover,
    },
    ":focus": {
      color: theme.colorScheme.action.content[variant][tone].focus,
      background: theme.colorScheme.action.background[variant][tone].focus,
    },
    ":focus[data-focus-visible-added]": {
      boxShadow: `0 0 0 2px ${Colors.blue["300"]}`,
    },
    ":active": {
      color: theme.colorScheme.action.content[variant][tone].active,
      background: theme.colorScheme.action.background[variant][tone].active,
    },
    '&[data-state="open"]': {
      color: theme.colorScheme.action.content[variant][tone].selected,
      background: theme.colorScheme.action.background[variant][tone].selected,
    },
  };

  if (disabled) {
    styles.color = theme.colorScheme.action.content[variant][tone].disabled;
    styles.background = theme.colorScheme.action.background[variant][tone].disabled;

    if (icon) {
      styles["svg"] = { fill: theme.colorScheme.action.content[variant][tone].disabled };
    }
  }

  if (loading) {
    styles.background = theme.colorScheme.action.background[variant][tone].enabled;
    styles.color = "transparent";
    // Hide elements other than text (ex: svgs)
    styles["& > *"] = { visibility: "hidden" };

    // babel magic turns component reference into a string
    // @ts-expect-errors
    styles[LoadingIconWrapper] = {
      // Restore visibility for Loading indicator
      visibility: "visible",
      stroke: theme.colorScheme.action.content[variant][tone].enabled,
    };
  }

  return styles;
}
