import * as React from "react";
import { CurrencyShortNameCrypto, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { VerificationPendingActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";

export type CashDepositModalProps = {
  currency?: CurrencyShortNameFiat;
  subaccountHashid?: string;
  isProtailFromOnboarding?: boolean;
};
export type VerificationPendingProps = {
  actionType: VerificationPendingActionType;
};
export type CryptoDepositModalProps = {
  preSelectedCrypto?: CurrencyShortNameCrypto;
};

export type CreatePasskeyGlobalProps = {
  showSkipForNow?: boolean;
};
export type GlobalModalProps = {
  [GlobalModalType.CashDepositModal]?: CashDepositModalProps;
  [GlobalModalType.VerificationPending]?: VerificationPendingProps;
  [GlobalModalType.CryptoDepositModal]?: CryptoDepositModalProps;
  [GlobalModalType.CreatePasskey]?: CreatePasskeyGlobalProps;
};

type GlobalModalContextType = {
  modalState: GlobalModalType;
  setModalState: (arg: GlobalModalType) => void;
  isModalOpen: (arg: GlobalModalType) => boolean;
  toggleModal: <T>(modal: GlobalModalType, props?: T) => void;
  modalProps: GlobalModalProps;
  setModalProps: (arg: GlobalModalProps) => void;
};

export enum GlobalModalType {
  CashDepositModal = "CashDepositModal",
  PerpsOnboardingIntroductionModal = "PerpsOnboardingIntroductionModal",
  PerpsOnboardingTransferFormModal = "PerpsOnboardingTransferFormModal",
  PerpsOnboardingTransferFromAccountModal = "PerpsOnboardingTransferFromAccountModal",
  PerpsOnboardingTransferToAccountModal = "PerpsOnboardingTransferToAccountModal",
  PerpsOnboardingTransferCurrencyModal = "PerpsOnboardingTransferCurrencyModal",
  PerpsOnboardingFinalizeTransferModal = "PerpsOnboardingFinalizeTransferModal",
  PerpsOnboardingAccountActivateFormModal = "PerpsOnboardingAccountActivateFormModal",
  PerpsOnboardingAccountUpdateFormModal = "PerpsOnboardingAccountUpdateFormModal",
  PerpsOnboardingLeaderboardInfoModal = "PerpsOnboardingLeaderboardInfoModal",
  PerpsOnboardingLeaderboardOptedInModal = "PerpsOnboardingLeaderboardOptedInModal",
  PerpsIneligibleModal = "PerpsIneligibleModal",
  PerpsFundingInstructions = "PerpsFundingInstructions",
  PerpsFundingInstructionsSettings = "PerpsFundingInstructionsSettings",
  None = "None",
  FeedbackModal = "FeedbackModal",
  PerpsFundAccount = "PerpsFundAccount",
  ATIFeedbackModal = "ATIFeedbackModal",
  PermanentFeedbackModal = "PermanentFeedbackModal",
  DepositCryptoModal = "DepositCryptoModal",
  VerificationPending = "VerificationPending",
  DerivativeDebitCardFundingModal = "DerivativeDebitCardFundingModal",
  CashWithdrawModal = "CashWithdrawModal",
  CryptoDepositModal = "CryptoDepositModal",
  CreatePasskey = "CreatePasskey",
}

export const GlobalModalContext = React.createContext<GlobalModalContextType | null>(null);

export const GlobalModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalState, setModalState] = React.useState<GlobalModalType>(GlobalModalType.None);
  const [modalProps, setModalProps] = React.useState({});

  const toggleModal = React.useCallback(<T,>(modal: GlobalModalType, props?: T) => {
    setModalState(state => (state === GlobalModalType.None ? modal : GlobalModalType.None));
    if (props) setModalProps(prevProps => ({ ...prevProps, [modal]: { ...prevProps[modal], ...props } }));
  }, []);

  const isModalOpen = React.useCallback(
    (modal: GlobalModalType) => {
      return modalState === modal;
    },
    [modalState]
  );

  return (
    <GlobalModalContext.Provider
      value={{
        toggleModal,
        isModalOpen,
        modalState,
        setModalState,
        modalProps,
        setModalProps,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  );
};

export function useGlobalModal() {
  const context = React.useContext(GlobalModalContext);

  if (!context) {
    throw new Error("Your component must be wrapped in GlobalModalProvider");
  }
  return context;
}
