// @MultiCurrencyAware
import BigNumber from "bignumber.js";
import { isNil } from "lodash";

import { CurrencyPairDetail, CurrencyShortName, SupportedCurrencyPairs } from "../constants/currencies";

export type RoundType = "down" | "up" | "none";

export enum Round {
  Down = "down",
  Up = "up",
  None = "none",
}

interface MoneyBaseProps {
  className?: string;
  decimalsOverride?: number; // optional override
  diff?: boolean;
  locale?: string;
  removeTrailingZeros?: boolean; // truncate trailing zeros for any amount
  truncate?: number; // cut off and make a tooltip after this number of string characters
  value: ValueType;
}

export interface PriceProps extends MoneyBaseProps {
  average?: boolean;
  tradingPair: SupportedCurrencyPairs;
  currencyPairDetail?: CurrencyPairDetail;
  /** Override the default trailing sign for the currency.
   *
   * Use `hideTrailingSign` if you want to disable the `trailingSign` altogether.
   * */
  trailingSign?: string | React.ReactNode;
  /** Hide the `trailingSign` */
  hideTrailingSign?: boolean;
  hideCurrency?: boolean;
  round?: RoundType | null;
}

type ValueType = number | string | BigNumber;

export interface MoneyProps<CurrencyType = CurrencyShortName> {
  currency: CurrencyType;
  value: number | string;
}
export interface MoneyComponentProps<CurrencyType = CurrencyShortName> extends MoneyBaseProps {
  "data-testid"?: string;
  currency: CurrencyType;
  id?: string;
  round?: RoundType;
  scale?: number; // fixed number of decimal places, will force trailing zeros
  /** Override the default trailing sign for the currency.
   *
   * Use `hideTrailingSign` if you want to disable the `trailingSign` altogether.
   * */
  trailingSign?: string | React.ReactNode;
  /** Hide the `trailingSign` */
  hideTrailingSign?: boolean;
}

export function hasValue(money: MoneyProps | undefined): boolean {
  return !isNil(money) && money.value !== "0";
}

export const DEFAULT_PRECISION = 8;
