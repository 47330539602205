import styled from "@emotion/styled";
import { Button, Spacing, Text } from "@gemini-ui/design-system";

export const DisclaimerText = styled(Text.Body)`
  color: ${({ theme }) => theme.colorScheme.content.secondary};
  margin-top: 0;
  margin-bottom: ${Spacing.scale[1]};
`;

export const ToggleDisclaimerButton = styled(Button.Tertiary)`
  min-width: unset;
`;

export const ToggleText = styled(Text.Link)`
  color: ${({ theme }) => theme.colorScheme.content.secondary};
  font-size: 12px;
`;
