import React from "react";
import { IconEur, IconGbp, IconProps, IconUsd } from "@hubble/icons";
import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { PaymentMethodIconBadgeProps } from "@gemini-ui/components/Icons/PaymentMethod/constants";
import { IconBadge } from "@gemini-ui/design-system";
import CryptoIcon from "@gemini-ui/images/icons/cdn/CryptoIcon";

export type BalanceIconProps = {
  defaultFiat: CurrencyShortNameFiat | CurrencyShortName;
  iconProps?: IconProps;
} & PaymentMethodIconBadgeProps;

export function BalanceIcon({ defaultFiat, size = "lg", iconProps, ...props }: BalanceIconProps) {
  if (defaultFiat === "USD") {
    return <IconBadge size={size} icon={<IconUsd {...iconProps} />} {...props} />;
  }
  if (defaultFiat === "GBP") {
    return <IconBadge size={size} icon={<IconGbp {...iconProps} />} {...props} />;
  }
  if (defaultFiat === "EUR") {
    return <IconBadge size={size} icon={<IconEur {...iconProps} />} {...props} />;
  }

  return <CryptoIcon symbol={defaultFiat} size={size} />;
}
