import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AccountEdit } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/AccountEdit";
import { AccountList } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/AccountList";
import { AdvancedAccountSelectorContainer } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/styles";
import { HubbleProvider } from "@gemini-ui/design-system";
import { clearATOrderFormPersistedState } from "@gemini-ui/pages/ActiveTrader/Spot/BuySell/utils";
import { fetchSubaccountsWithBalances, SubaccountWithBalances } from "@gemini-ui/services/subaccounts";
import { useGetUserPermissions } from "@gemini-ui/services/user/useGetUserPermissions";

export type AdvancedAccountSelectorProps = {
  subaccounts: SubaccountWithBalances[];
  selectedAccount: SubaccountWithBalances;
  loading: boolean;
  currentPage: number;
  displayMenu: boolean;
  handleMenuClick: () => void;
  fetchSubaccounts: () => void;
  onAddAccount: () => void;
  loadMore: () => void;
  onSelectAccount: (account: SubaccountWithBalances) => void;
  setParams: Dispatch<
    SetStateAction<{
      filter: string;
      offset: number;
    }>
  >;
};

export default function AdvancedAccountSelector(props: AdvancedAccountSelectorProps) {
  const {
    subaccounts,
    selectedAccount,
    loading,
    currentPage,
    displayMenu,
    handleMenuClick,
    loadMore,
    onAddAccount,
    onSelectAccount,
    fetchSubaccounts,
    setParams,
  } = props;

  const firstPageSubs = subaccounts.slice(0, 10);
  const hasSelectedAccount = firstPageSubs.some(sub => sub.hashid === selectedAccount.hashid);

  // Active account to display at the top of AccountList if it isn't present in the first page of subaccounts
  const [topSubaccount, setTopSubaccount] = useState(hasSelectedAccount ? null : selectedAccount);
  const [editMode, setEditMode] = useState(false);
  const [editAccount, setEditAccount] = useState<SubaccountWithBalances>();

  const userPermissions = useGetUserPermissions();

  const handleSelectAccount = e => {
    // clear persisted order form state when switching accounts
    clearATOrderFormPersistedState();
    onSelectAccount(subaccounts.find(s => s.hashid === e.currentTarget.id));
  };

  const handleCancelEdit = () => {
    fetchSubaccounts();
    setEditAccount(undefined);
    !hasSelectedAccount && fetchSelectedDetails();
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleEditAccount = e => {
    const fullSelectedAccount = subaccounts.find(sub => sub.hashid === e.currentTarget.id);
    fullSelectedAccount ? setEditAccount(fullSelectedAccount) : setEditAccount(topSubaccount);
  };

  // Fetches full selected account details by name
  // * If the selected account is not in the first page of subaccounts then only partial data is available from templateProps
  const fetchSelectedDetails = () => {
    fetchSubaccountsWithBalances({ name: selectedAccount.name }).then(response => {
      setTopSubaccount(response.results[0]);
    });
  };

  // Fetch active account's details if it's not in the first page of subaccounts
  useEffect(() => {
    !hasSelectedAccount && fetchSelectedDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMenu]);

  return (
    // Replace for theme-dependant coloring ("light")
    <HubbleProvider scheme="dark">
      <AdvancedAccountSelectorContainer data-testid="account-selector">
        {editAccount ? (
          <AccountEdit
            handleCancel={handleCancelEdit}
            editAccount={editAccount}
            setEditAccount={setEditAccount}
            fetchSubaccounts={fetchSubaccounts}
            handleMenuClick={handleMenuClick}
            userPermissions={userPermissions}
          />
        ) : (
          <AccountList
            subaccounts={subaccounts}
            topSubaccount={topSubaccount}
            editMode={editMode}
            selectedAccount={selectedAccount}
            handleSelectAccount={handleSelectAccount}
            handleEditMode={handleEditMode}
            handleEditAccount={handleEditAccount}
            onAddAccount={onAddAccount}
            setParams={setParams}
            loading={loading}
            currentPage={currentPage}
            loadMore={loadMore}
          />
        )}
      </AdvancedAccountSelectorContainer>
    </HubbleProvider>
  );
}
