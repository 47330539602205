import React from "react";
import { CardColorEnum } from "@gemini-ui/client/credit";
import { Card, Flex, Spacing, Text } from "@gemini-ui/design-system";
import { StyledSwatch } from "@gemini-ui/pages/Credit/CreditApplication/v2/components/CardColorSwatch/styles";

interface CardColorOptionsProps {
  color: CardColorEnum;
  isChecked: boolean;
  onChange: () => void;
}

export const CardColorOption = ({ color, isChecked, onChange }: CardColorOptionsProps) => {
  const cardColorText: Record<CardColorEnum, string> = {
    BLACK: "Black",
    SILVER: "Silver",
    ROSE: "Rose Gold",
  };

  return (
    <Card isRadio as="button" aria-checked={isChecked} data-testid={`card-color-option-${color}`} onClick={onChange}>
      <Flex justifyContent="center" alignItems="center" flexDirection="column" gap={Spacing.scale["1"]}>
        <StyledSwatch color={color} size="sm" />
        <Text.Body center>{cardColorText[color]}</Text.Body>
      </Flex>
    </Card>
  );
};
