import styled from "@emotion/styled";
import { BASE_ZINDEX } from "@gemini-ui/components/Header/styles";
import { Colors, Spacing } from "@gemini-ui/design-system";

const MenuContainer = styled("div")`
  background: ${Colors.black};
  text-align: left;
  position: relative;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  z-index: ${BASE_ZINDEX};
  letter-spacing: 0.3px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${Spacing.scale[12]};
`;

export default MenuContainer;
