/** @jsx jsx */
import React, { useEffect, useRef, useState } from "react";
import { jsx } from "@emotion/react";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import {
  ArrowContainer,
  Container,
  Form,
  fullScreenStyles,
  GroupsList,
  Header,
  ListItem,
  Loader,
  Logo,
  SubHeader,
  SubTitle,
  Title,
} from "@gemini-ui/components/Header/GroupDropdown/styles";
import { GroupsInfo } from "@gemini-ui/constants/templateProps/users";
import { Modal } from "@gemini-ui/design-system";
import { LimitTextLength } from "@gemini-ui/design-system/utils/LimitTextLength";
import { ReactComponent as AngleDown } from "@gemini-ui/images/icons/angle-down.svg";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const selectionUrl = (hashid: string) => jsRoutes.controllers.account.AccountGroupSelectionController.post(hashid).url;

// Organizes list with current group at the beginning
const sortGroups = (groups, hashid) => _.sortBy(groups, g => g.hashid !== hashid);

interface Props {
  groupsInfo: GroupsInfo;
  userName: string;
  institutionName?: string;
}

const GroupDropdown = ({ groupsInfo, userName, institutionName }: Props) => {
  const { intl } = useIntl();

  const form = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const currentGroup = groupsInfo.groups.find(g => g.hashid === groupsInfo.currentGroupHashId);
  const [selectedOption, setSelectedOption] = useState({
    hashid: currentGroup.hashid,
    name: currentGroup.name,
  });
  const [options, setOptions] = useState(sortGroups(groupsInfo.groups, groupsInfo.currentGroupHashId));

  useEffect(() => {
    if (submitting) form.current.submit();
  }, [submitting]);

  const handleExpand = e => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setOptions(sortGroups(options, value.hashid));

    const isDifferentAccount = selectedOption.hashid !== value.hashid;
    if (!isDifferentAccount) setExpanded(false);
    setSubmitting(isDifferentAccount);
  };

  const renderGroupName = () => {
    let groupName = "";
    if (institutionName) {
      groupName = institutionName;
    } else if (currentGroup.name !== userName) {
      groupName = currentGroup.name;
    }

    return (
      groupName && (
        <CSSTransition in={expanded} timeout={500} classNames="group-name">
          <SubHeader>
            <LimitTextLength text={groupName} addParentheses={false} finalTextLength={23} hasTooltip />
          </SubHeader>
        </CSSTransition>
      )
    );
  };

  return (
    <Container>
      {options.length === 1 ? (
        <React.Fragment>
          <Header>{userName}</Header>
          {institutionName && (
            <SubHeader>
              <LimitTextLength text={institutionName} addParentheses={false} finalTextLength={23} hasTooltip />
            </SubHeader>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={handleExpand} href="#">
            <CSSTransition in={expanded} classNames="header" timeout={500}>
              <Header>
                {expanded
                  ? intl.formatMessage({
                      defaultMessage: "Switch group",
                    })
                  : userName}
              </Header>
            </CSSTransition>
            <ArrowContainer expanded={expanded}>
              <AngleDown />
            </ArrowContainer>
            {renderGroupName()}
          </a>
          <Form action={selectionUrl(selectedOption.hashid)} method="POST" ref={form}>
            <CSSTransition in={expanded} timeout={500} classNames="item">
              <GroupsList>
                {options.map(o => (
                  <ListItem
                    key={o.hashid}
                    onClick={onOptionClicked(o)}
                    onKeyPress={onOptionClicked(o)}
                    selected={selectedOption.hashid === o.hashid}
                    expanded={expanded}
                    tabIndex={0}
                  >
                    <LimitTextLength text={o.name} addParentheses={false} finalTextLength={23} hasTooltip />
                  </ListItem>
                ))}
              </GroupsList>
            </CSSTransition>
            <input type="hidden" name="csrfToken" value={groupsInfo.csrfToken?.value} />
          </Form>
        </React.Fragment>
      )}
      <Modal isOpen={submitting} css={fullScreenStyles}>
        <Loader>
          <Logo />
          <Title>
            {intl.formatMessage(
              defineMessage({
                defaultMessage: "Switching to <strong>{selectedOptionName}</strong>",
              }),
              {
                strong: (v: React.ReactNode) => <strong>{v}</strong>,
                selectedOptionName: selectedOption.name,
              }
            )}
          </Title>
          <SubTitle>
            {intl.formatMessage({
              defaultMessage: "Please wait a few seconds while we switch your Group.",
            })}
          </SubTitle>
        </Loader>
      </Modal>
    </Container>
  );
};

export default GroupDropdown;
